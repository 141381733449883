import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Grid } from '@material-ui/core';

import styles from '../../styles.module.css';
import { isMobile } from '../../../../utils/breakpoints';

function FakeCards() {
  return (
    <Grid className={styles.cards}>
      {isMobile ? (
        <>
          <div className={styles.infoCard}>
            <Skeleton
              width={330}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              width={250}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              width={250}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
          </div>
          <div className={styles.amountCards}>
            <Skeleton
              width={330}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              width={250}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              width={250}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              width={250}
              height={20}
              color="#202020"
              highlightColor="#444"
              style={{ marginBottom: 5 }}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.widthInfoCardFake}>
            <div className={styles.infoCard}>
              <Skeleton
                width={330}
                height={15}
                color="#202020"
                highlightColor="#444"
              />

              <div className={styles.nameEnterpriseDate}>
                <div className={styles.fileName}>
                  <Skeleton width={90} color="#202020" highlightColor="#444" />
                  <Skeleton width={100} color="#202020" highlightColor="#444" />
                </div>

                <div className={styles.enterprise}>
                  <Skeleton width={80} color="#202020" highlightColor="#444" />
                  <Skeleton width={100} color="#202020" highlightColor="#444" />
                </div>

                <div className={styles.scheduleDate}>
                  <Skeleton width={100} color="#202020" highlightColor="#444" />
                  <Skeleton width={90} color="#202020" highlightColor="#444" />
                </div>
              </div>

              <div className={styles.paymentType}>
                <Skeleton width={50} color="#202020" highlightColor="#444" />
                <Skeleton width={110} color="#202020" highlightColor="#444" />
              </div>
            </div>
          </div>

          <div className={styles.widthAmountCardsFake}>
            <div className={styles.amountCards}>
              <Skeleton
                width={113}
                height={20}
                color="#202020"
                highlightColor="#444"
              />
              <div className={styles.totalAmountFake}>
                <Skeleton width={50} color="#202020" highlightColor="#444" />
                <Skeleton
                  width={150}
                  height={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div className={styles.payrollAndFee}>
                <div className={styles.fee}>
                  <Skeleton width={50} color="#202020" highlightColor="#444" />
                  <Skeleton width={80} color="#202020" highlightColor="#444" />
                </div>
                <div className={styles.fee}>
                  <Skeleton width={50} color="#202020" highlightColor="#444" />
                  <Skeleton width={80} color="#202020" highlightColor="#444" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Grid>
  );
}

export default FakeCards;
