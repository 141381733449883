import React from 'react';
import { Box, Divider, Paper } from '@material-ui/core';
import CardTools from '../../../../../../../CardTools';
import { CardContent } from '../CardContent';
import { useCardContainer } from './Hooks/useCardContainer';

export function CardContainer({ cardData = {} }) {
  const { currentCardOpen, handleOpenCard } = useCardContainer();

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        borderRadius: '0.5rem',
        boxShadow: '0px 3px 6px 3px #00000030',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {cardData?.cardHeader?.select && (
          <Box
            sx={{
              backgroundColor: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10rem',
            }}
          >
            {cardData?.cardHeader?.select}
          </Box>
        )}

        <CardTools.CardInfo
          title={cardData?.cardHeader?.title}
          description={cardData?.cardHeader?.description}
          orientation="column"
        />
      </Box>

      <Divider orientation="horizontal" />

      <CardContent
        cardData={cardData}
        cardStyle={{
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        }}
      />
    </Paper>
  );
}
