import React, { memo } from 'react';
import { DesktopMode } from './Components/DesktopMode';
import { useFilter } from './Hooks/useFilter';
import { MobileMode } from './Components/MobileMode';

function FilterComponent({
  defaultValues = {},
  handleRequests = () => {},
  validation = null,
  children,
}) {
  const { isMobile } = useFilter();

  return isMobile ? (
    <MobileMode
      defaultValues={defaultValues}
      validation={validation}
      handleRequests={handleRequests}
    >
      {children}
    </MobileMode>
  ) : (
    <DesktopMode
      defaultValues={defaultValues}
      validation={validation}
      handleRequests={handleRequests}
    >
      {children}
    </DesktopMode>
  );
}

export const Filter = memo(
  FilterComponent,
  (prev, next) => prev.defaultValues !== next.defaultValues,
);
