export const statusColor = status => {
  const typeStatus = {
    ACTIVE: '#19797F',
    DEFAULT: '#CA2317',
  };

  return typeStatus[status] || typeStatus.DEFAULT;
};

export const statusLabel = status => {
  const typeStatus = {
    ACTIVE: 'Ativa',
    INACTIVE: 'Inativa',
    BLOCKED: 'Bloqueada',
    CLOSED: 'Encerrada',
    DEFAULT: 'Não possui status',
  };

  return typeStatus[status] || typeStatus.DEFAULT;
};
