import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography, Menu, MenuItem } from '@material-ui/core';

import styles from './styles.module.css';

import { ReactComponent as SuccessAnimation } from '../../../../../assets/insuranceTableIlustration.svg';
import { ReactComponent as InsuranceDefault } from '../../../../../assets/insurance-default.svg';

import { isMobile } from '../../../../../utils/breakpoints';

function EmptyTableView({ hiddenButton }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  function addInsureds(event) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function goToSingleRegister() {
    history.push(`/insurance-management/insurance-single`);
  }

  function goToBatchRegister() {
    history.push(`/insurance-management/batch-registration/menu`);
  }

  return (
    <div className={styles.container}>
      {isMobile ? (
        <InsuranceDefault className={styles.image} />
      ) : (
        <SuccessAnimation className={styles.image} />
      )}
      <Typography className={styles.textMedium}>
        A lista de seus funcionários segurados aparecerá aqui.
      </Typography>
      <Typography className={styles.textSmall}>
        Para incluir um item novo, basta clicar no botão “Incluir”.
      </Typography>
      <Button
        className={hiddenButton ? styles.hiddenButton : styles.button}
        onClick={addInsureds}
      >
        Incluir segurados
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        className={styles.menuListArea}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={goToSingleRegister}>
          <span>Cadastrar individualmente</span>
        </MenuItem>
        <MenuItem onClick={goToBatchRegister}>
          <span>Cadastrar a partir de um arquivo</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default EmptyTableView;
