import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../services/api';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import convertBytesToBlobDowload from '../../../utils/convertBytesToBlobDowload';

const { error, success } = handleSnackbarStack();

const kebabCase = string =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

const initialState = {
  isLoading: false,
  isLoadingSubtopics: false,
  isLoadingFlowSteps: true,
  isDownloading: false,
  isSearching: false,
  hasSuccessfulSearch: false,
  searchResults: [],
  currentResult: '',
  topics: [],
  currentTopic: null,
  subtopics: [],
  currentSubtopic: null,
  currentFlow: {},
  error: {
    hasError: false,
    message: '',
  },
};

const searchTopicList = createAsyncThunk(
  'academy/searchTopicList',
  async (payload, { rejectWithValue }) => {
    return api
      .get(`academy/subtopic/search`, { params: payload })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getTopicList = createAsyncThunk(
  'academy/getTopicList',
  async (_, { rejectWithValue }) => {
    return api
      .get('/academy/topic')
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getSubtopicList = createAsyncThunk(
  'academy/getSubtopicList',
  async (_, { getState, rejectWithValue }) => {
    const { currentTopic } = getState().academy;

    return api
      .get(`/academy/subtopic/${currentTopic}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getFlowContent = createAsyncThunk(
  'academy/getFlowContent',
  async (payload, { rejectWithValue }) => {
    return api
      .get(`/academy/flow/${payload}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getArticleFile = createAsyncThunk(
  'academy/getArticleFile',
  async (payload, { getState, rejectWithValue }) => {
    const {
      currentFlow: { subtopic },
    } = getState().academy;

    return api
      .get(`/academy/subtopic/download`, {
        params: { id: payload },
        responseType: 'blob',
      })
      .then(({ data }) =>
        convertBytesToBlobDowload(data, `${kebabCase(subtopic)}-somapay`),
      )
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const AcademySlice = createSlice({
  name: 'academy',
  initialState,
  reducers: {
    setCurrentTopic: (state, { payload }) => {
      state.currentTopic = payload;
    },
    setCurrentSubtopic: (state, { payload }) => {
      state.currentSubtopic = payload;
    },
    setCurrentResult: (state, { payload }) => {
      state.currentResult = payload;
    },
  },
  extraReducers: {
    [searchTopicList.pending]: state => {
      state.isSearching = true;
      state.hasSuccessfulSearch = false;
      state.currentResult = '';
      state.searchResults = [];
    },
    [searchTopicList.fulfilled]: (state, { payload }) => {
      state.isSearching = false;
      state.hasSuccessfulSearch = true;
      state.searchResults = payload.data;
    },
    [searchTopicList.rejected]: (state, { payload }) => {
      state.isSearching = false;
      state.hasSuccessfulSearch = false;
      state.error = {
        hasError: true,
        message:
          payload?.message ||
          'Ocorreu um erro ao pesquisar nos tópicos de ajuda. Tente novamente em alguns instantes.',
      };

      error(state.error.message);
    },
    [getTopicList.pending]: state => {
      state.isLoading = true;
      state.hasSuccessfulSearch = false;
    },
    [getTopicList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.topics = payload.data;
    },
    [getTopicList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.message ||
          'Ocorreu um erro ao obter a listagem de tópicos. Tente novamente em alguns instantes.',
      };

      error(state.error.message);
    },
    [getSubtopicList.pending]: state => {
      state.isLoadingSubtopics = true;
    },
    [getSubtopicList.fulfilled]: (state, { payload }) => {
      state.isLoadingSubtopics = false;
      state.subtopics = payload.data;
    },
    [getSubtopicList.rejected]: (state, { payload }) => {
      state.isLoadingSubtopics = false;
      state.error = {
        hasError: true,
        message:
          payload?.message ||
          'Ocorreu um erro ao obter a listagem de subtópicos. Tente novamente em alguns instantes.',
      };

      error(state.error.message);
    },
    [getFlowContent.pending]: state => {
      state.currentFlow = {};
    },
    [getFlowContent.fulfilled]: (state, { payload }) => {
      state.isLoadingFlowSteps = false;
      state.currentFlow = payload.data;
    },
    [getFlowContent.rejected]: (state, { payload }) => {
      state.isLoadingFlowSteps = false;
      state.error = {
        hasError: true,
        message:
          payload?.message ||
          'Ocorreu um erro ao obter as informações sobre este fluxo. Tente novamente em alguns instantes.',
      };

      error(state.error.message);
    },
    [getArticleFile.pending]: state => {
      state.isDownloading = true;
    },
    [getArticleFile.fulfilled]: state => {
      state.isDownloading = false;
      state.error.hasError = false;

      success('Arquivo baixado com sucesso!');
    },
    [getArticleFile.rejected]: (state, { payload }) => {
      state.isDownloading = false;

      state.error = {
        hasError: true,
        message:
          payload?.message ||
          'Ocorreu um erro ao fazer o download do artigo. Tente novamente em alguns instantes.',
      };

      error(state.error.message);
    },
  },
});

const academyReducer = AcademySlice.reducer;
const AcademyActions = {
  ...AcademySlice.actions,
  getTopicList,
  getSubtopicList,
  getFlowContent,
  searchTopicList,
  getArticleFile,
};

export { academyReducer, AcademyActions };
