import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TablePagination,
  Paper
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from '../styles.module.css';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { api } from '../../../../../../services/api';

export default function InsuranceFileTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  useEffect(() => getTableData(), []);

  const getTableData = () => {
    api.get('https://run.mocky.io/v3/c93e5778-b3dc-424d-82ef-f93d8c97bfd4')
    .then(({ data }) => setTableData(data))
    .finally(() => setLoadingTable(false));
  }

  const tableHeadData = [
    'Identificador',
    'Competência',
    'Consolidação',
    'Seguro',
    'Segurados',
    ''
  ]

  const createData = (id, identificador, Competência, Consolidação, Seguro, Segurado) => {
    return { id, identificador, Competência, Consolidação, Seguro, Segurado };
  }

  const rows = tableData.map((item) => (
    createData(
      item.id,
      item.identificador,
      item.Competência,
      item.Consolidação,
      item.Seguro,
      item.Segurado
    )
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onClickedDetails = () => {
    history.push('/insurance-management/view-details');
  };

  return (
   <div className={styles.tableContainer}>
      <TableContainer className={styles.container} component={Paper}>
        <Table aria-label="simple table">
          <TableHead
            tableHeadData={tableHeadData}
          />
          <TableBody
            rows={rows}
            onClickedDetails={onClickedDetails}
            page={page}
            rowsPerPage={rowsPerPage}
            loadingTable={loadingTable}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
   </div>
  );
}
