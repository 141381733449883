import React from 'react';
import {
  Button,
  Dialog,
  Typography
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as InactiveIcon } from "../../../assets/inactive-icon.svg"
import FakeUpgradeModal from './FakeUpgradeModal';
import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';
import currencyFormatter from '../../../utils/currencyFormatter';
import convertBrazilianDate from '../../../utils/convertBrazilianDate';

function UpgradeModal({
  open,
  onCloseDialog,
  onConfirm,
  onCancel,
  mainInformations,
  loading,
  chargingActive,
  finalCurrencyDate
}) {

  const {
    active,
    feeByEmployee,
    feeExpressPayment,
    franchiseCount,
    planName,
    signatureAmount,
  } = mainInformations;

  function renderExpiredContainer() {
    return (
      <div className={styles.block}>
        <Typography className={styles.label}>Vencimento</Typography>
        <Typography className={styles.value}>
          {finalCurrencyDate ? convertBrazilianDate(finalCurrencyDate) : '-'}
        </Typography>
      </div>
    )
  }

  return (
    <Dialog
        open={open}
        onClose={onCloseDialog}
        fullWidth
        maxWidth='xs'
    >
      {loading ? (
        <FakeUpgradeModal open={open} onCloseDialog={onCloseDialog} />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <Typography className={styles.title}>{planName ? planName : '-'}</Typography>
              {active ? (
                <Typography className={styles.status}>
                  <CheckCircleIcon className={styles.statusIcon} />
                  Ativo
                </Typography>
              ) : (
                <Typography className={styles.containerInactive}>
                  <InactiveIcon className={styles.inactiveIcon} />
                  Inativo
                </Typography>
              )}
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Assinatura</Typography>
                <Typography className={isMobile ? styles.valueMobile : styles.value}>
                  {signatureAmount ? currencyFormatter(signatureAmount) : 'R$ 0,00'}
                </Typography>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Franquia</Typography>
                <Typography className={styles.value}>{franchiseCount ? franchiseCount : '0'}</Typography>
              </div>

              <div className={styles.block}>
                <Typography className={styles.label}>Tarifa p/ funcionário</Typography>
                <Typography className={styles.value}>
                  {feeByEmployee ? currencyFormatter(feeByEmployee) : 'R$ 0,00'}
                </Typography>
              </div>

              <div className={styles.block}>
                <Typography className={styles.label}>Tarifa de liberação imediata</Typography>
                <Typography className={styles.value}>
                  {feeExpressPayment ? currencyFormatter(feeExpressPayment) : 'R$ 0,00'}
                </Typography>
              </div>

              {isMobile && renderExpiredContainer()}
            </div>

            {!isMobile && renderExpiredContainer()}
          </div>

          {/* <div className={styles.content}>
            <Typography className={styles.biggerText}>Turbine seu plano!</Typography>

            <div className={styles.contentRow}>
              <Typography className={styles.text}>
                Faça upgrade para otimizar ainda mais o seu tempo.
              </Typography>
              <Button onClick={onConfirm} className={styles.upgradeButton}>Fazer Upgrade</Button>
            </div>
          </div> */}

          <div className={styles.footer}>
            {chargingActive && (
              <Button onClick={onCancel} className={styles.cancelButton}>
                cancelar plano
              </Button>
            )}
            <Button onClick={onCloseDialog} className={styles.closeButton}>
              <p>Fechar</p>
            </Button>
          </div>

        </div>
      )}
    </Dialog>
  );
}

export default UpgradeModal;
