import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import style from './styles.module.css';

import Title from '../../../../../components/Title';

export default function Header({

}) {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justify="space-between"
      className={style.header}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Title>Consultar propostas</Title>
        </Grid>
      </Grid>
    </Grid>
  );
}
