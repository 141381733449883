import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { useStyles, theme } from './styles';

import Table from './UI/Table/Table';
import Header from './UI/Header/Header';
import Filters from './UI/Filters/Filters';
import TablePaginationActions from './UI/Table/TablePaginationActions';

import NoList from '../../../components/NoList';
import LogoLoading from '../../../components/LogoLoading';
import ResultNotFound from '../../../components/ResultNotFound';
import CustomSnackbar from '../../../components/Snackbar';

import ApprovedProposeModal from './UI/Modal/Approve';
import ReprovedProposeModal from './UI/Modal/Generic';
import NewSimulateModal from './UI/Modal/Generic';
import EditModal from './UI/Modal/Edit';
import DetailModal from './UI/Modal/Detail';

import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';
import formatOnlyNumbers from '../../../utils/formatOnlyNumbers';
import unMask from '../../../utils/unmask';
import { editLoan, getLoans, updateLoan } from './utils/services';

const ConsultCosignedToApprove = () => {
  const { currentCompany } = useSelector(state => state.companies);

  const classes = useStyles();

  const [loadingLogo, setLoadingLogo] = useState(true);

  const [cpf, setCpf] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [approveComment, setApproveComment] = useState('');
  const [editComment, setEditComment] = useState('');

  const [proposesData, setProposesData] = useState([]);
  const [loadingProposes, setLoadingProposes] = useState(false);
  const [selectedPropose, setSelectedPropose] = useState(null);
  const [
    loadingSelectedProposeRequest,
    setLoadingSelectedProposeRequest,
  ] = useState(false);

  const [changedFilter, setChangedFilter] = useState(false);
  const [changedEditComment, setChangedEditComment] = useState(false);
  const validateStartDateInfo = useValidationForUnformattedDate(startDate);
  const validateEndDateInfo = useValidationForUnformattedDate(endDate);

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openReproveModal, setOpenReproveModal] = useState(false);
  const [openNewSimulateModal, setOpenNewSimulateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const [alert, setAlert] = useState({
    severity: '',
    message: '',
    open: false,
  });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleChangeCpf = event => {
    setCpf(event.target.value);
  };

  const handleChangeStatus = event => {
    setStatus(event.target.value);
  };

  const handleApproveComment = event => {
    setApproveComment(event.target.value);
  };

  const handleEditComment = event => {
    setEditComment(event.target.value);
    setChangedEditComment(true);
  };

  const handleSelectedProposeAmount = event => {
    let newSelectedPropose = { ...selectedPropose };
    newSelectedPropose.principalAmount =
      (event?.target?.value && formatOnlyNumbers(event?.target?.value) / 100) ||
      500;

    setSelectedPropose(newSelectedPropose);
  };

  const handleSelectedProposeInstallmentNumber = event => {
    let newSelectedPropose = { ...selectedPropose };
    newSelectedPropose.installmentsNumber =
      event?.target?.value > 0 ? event?.target?.value : 0;

    setSelectedPropose(newSelectedPropose);
  };

  const handleChangeStartDate = value => {
    setStartDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const handleChangeEndDate = value => {
    setEndDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const resetFilter = () => {
    setCpf('');
    setStatus('');
    setStartDate('');
    setEndDate('');
  };

  const handleOpenApproveModal = propose => {
    fillSelectedPropose(propose);
    setOpenApproveModal(true);
  };

  const handleCloseApproveModal = () => {
    clearSelectedPropose();
    clearApproveComment();
    setOpenApproveModal(false);
  };

  const handleOpenReproveModal = propose => {
    fillSelectedPropose(propose);
    setOpenReproveModal(true);
  };

  const handleCloseReproveModal = () => {
    clearSelectedPropose();
    setOpenReproveModal(false);
  };

  const handleOpenNewSimulateModal = propose => {
    fillSelectedPropose(propose);
    setOpenNewSimulateModal(true);
  };

  const handleCloseNewSimulateModal = () => {
    clearSelectedPropose();
    setOpenNewSimulateModal(false);
  };

  const handleOpenEditModal = propose => {
    fillSelectedPropose(propose);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    clearSelectedPropose();
    setOpenEditModal(false);
  };

  const handleOpenDetailModal = propose => {
    fillSelectedPropose(propose);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    clearSelectedPropose();
    setOpenDetailModal(false);
  };

  const clearSelectedPropose = () => setSelectedPropose(null);
  const fillSelectedPropose = propose => setSelectedPropose(propose);
  const clearApproveComment = () => setApproveComment('');

  const loadingSelectedProposeRequestOn = () =>
    setLoadingSelectedProposeRequest(true);

  const loadingSelectedProposeRequestOff = () =>
    setLoadingSelectedProposeRequest(false);

  const getProposes = useCallback(async () => {
    setProposesData([]);
    setLoadingProposes(true);

    try {
      const { data } = await getLoans({
        cnpj: currentCompany?.cnpj,
        cpf: unMask(cpf),
        status,
        initialDate: startDate.length > 0 ? startDate : undefined,
        finalDate: endDate.length > 0 ? endDate : undefined,
        page: currentPage,
        size: pageSize,
      });

      setProposesData(data.loans);
      setTotalSize(data.totalSize);
    } catch {
      // TODO: alert
    } finally {
      setLoadingLogo(false);
      setLoadingProposes(false);
    }
  }, [cpf, status, startDate, pageSize, currentPage, endDate]);

  const approvePropose = async () => {
    loadingSelectedProposeRequestOn();

    try {
      await updateLoan({
        id: selectedPropose.id,
        status: 'WAITING_CLIENT',
        reasonUpdate: approveComment,
      });

      setAlert({
        open: true,
        severity: 'success',
        message: 'Proposta aprovada com sucesso.',
      });
    } catch {
      setAlert({
        open: true,
        severity: 'error',
        message:
          'Ocorreu um erro ao aprovar a proposta. Por favor, tente novamente.',
      });
    }

    loadingSelectedProposeRequestOff();
    handleCloseApproveModal();
  };

  const editPropose = useCallback(
    async propose => {
      loadingSelectedProposeRequestOn();

      try {
        await editLoan(propose.id, {
          amount: propose.principalAmount,
          installments: propose.installmentsNumber,
          status: 'UNDER_ANALISYS_BO',
          reasonUpdate: editComment,
        });

        loadingSelectedProposeRequestOff();

        setAlert({
          open: true,
          severity: 'success',
          message: 'Proposta editada com sucesso.',
        });
      } catch {
        setAlert({
          open: true,
          severity: 'error',
          message:
            'Ocorreu um erro ao editar a proposta. Por favor, tente novamente.',
        });
      }
    },
    [editComment],
  );

  const generateNewSimulate = propose => {
    loadingSelectedProposeRequestOn();

    loadingSelectedProposeRequestOff();
  };

  const reprovePropose = async () => {
    loadingSelectedProposeRequestOn();

    try {
      await updateLoan({
        id: selectedPropose.id,
        status: 'DISAPPROVED_RH',
        reasonUpdate: approveComment,
      });

      setAlert({
        open: true,
        severity: 'success',
        message: 'Proposta reprovada com sucesso.',
      });
    } catch {
      setAlert({
        open: true,
        severity: 'error',
        message:
          'Ocorreu um erro ao editar a proposta. Por favor, tente novamente.',
      });
    }

    loadingSelectedProposeRequestOff();
    handleCloseReproveModal();
  };

  const downloadCCB = propose => {
    loadingSelectedProposeRequestOn();

    loadingSelectedProposeRequestOff();
  };

  // const onDetailsClicked = propose => {
  //   loadingSelectedProposeRequestOn();

  //   loadingSelectedProposeRequestOff();
  // };

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        setCurrentPage(0);
        getProposes();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [cpf]);

  useEffect(() => {
    setCurrentPage(0);
    getProposes();
  }, [status, startDate, endDate]);

  useEffect(() => {
    if (cpf || status || startDate || endDate) setChangedFilter(true);
  }, [cpf, status, startDate, endDate]);

  useEffect(() => {
    getProposes();
  }, [pageSize, currentPage]);

  useEffect(() => {
    getProposes();
  }, []);

  const renderHeader = () => {
    return <Header />;
  };

  const renderFilters = () => {
    return (
      <Filters
        cpf={cpf}
        status={status}
        startDate={startDate}
        endDate={endDate}
        changedFilter={changedFilter}
        handleChangeCpf={handleChangeCpf}
        handleChangeStatus={handleChangeStatus}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        validateStartDateInfo={validateStartDateInfo}
        validateEndDateInfo={validateEndDateInfo}
      />
    );
  };

  const renderTable = () => {
    return (
      <Table
        data={proposesData}
        theme={theme}
        classes={classes}
        totalSize={totalSize}
        pageSize={pageSize}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        TablePaginationActions={TablePaginationActions}
        loading={loadingProposes}
        handleApprovedPropose={handleOpenApproveModal}
        handleReprovePropose={handleOpenReproveModal}
        handleEditPropose={handleOpenEditModal}
        handleUpdatePropose={handleOpenNewSimulateModal}
        handleDetailPropose={handleOpenDetailModal}
        downloadCCB={downloadCCB}
      />
    );
  };

  const renderApproveModal = () => {
    return (
      <ApprovedProposeModal
        open={openApproveModal}
        title="Aprovar proposta?"
        text="Ao aprovar a proposta, esta operação não poderá ser desfeita."
        titleMarginBottom={3}
        onCloseDialog={handleCloseApproveModal}
        onNextButtonClicked={approvePropose}
        nextButton
        nextButtonText="Continuar"
        closeButtonText="Cancelar"
        loading={loadingSelectedProposeRequest}
        approveComment={approveComment}
        handleApproveComment={handleApproveComment}
      />
    );
  };

  const renderReproveModal = () => {
    return (
      <ReprovedProposeModal
        open={openReproveModal}
        title="Reprovar proposta?"
        text="Ao reprovar a proposta, esta operação não poderá ser desfeita."
        titleMarginBottom={3}
        onCloseDialog={handleCloseReproveModal}
        onNextButtonClicked={reprovePropose}
        nextButton
        nextButtonText="Continuar"
        closeButtonText="Cancelar"
        loading={loadingSelectedProposeRequest}
      />
    );
  };

  const renderNewSimulateModal = () => {
    return (
      <NewSimulateModal
        open={openNewSimulateModal}
        title="Gerar nova simulação?"
        text="Ao prosseguir com a operação, será gerada uma nova proposta considerando a data atual."
        titleMarginBottom={3}
        onCloseDialog={handleCloseNewSimulateModal}
        onNextButtonClicked={generateNewSimulate}
        nextButton
        nextButtonText="Continuar"
        closeButtonText="Cancelar"
        loading={loadingSelectedProposeRequest}
      />
    );
  };

  const renderEditModal = () => {
    return (
      <EditModal
        open={openEditModal}
        title="Editar proposta"
        text="Ao prosseguir com a operação, será gerada uma nova proposta considerando a data atual."
        titleMarginBottom={3}
        onCloseDialog={handleCloseEditModal}
        onNextButtonClicked={editPropose}
        nextButton
        nextButtonText="Continuar"
        closeButtonText="Cancelar"
        loading={loadingSelectedProposeRequest}
        handleEditComment={handleEditComment}
        handleSelectedProposeAmount={handleSelectedProposeAmount}
        handleSelectedProposeInstallmentNumber={
          handleSelectedProposeInstallmentNumber
        }
        editComment={editComment}
        disableNextButton={
          loadingSelectedProposeRequest ||
          !editComment ||
          !selectedPropose?.principalAmount ||
          !selectedPropose?.installmentsNumber
        }
        selectedPropose={selectedPropose}
        changedEditComment={changedEditComment}
      />
    );
  };

  const renderDetailModal = () => {
    return (
      <DetailModal
        open={openDetailModal}
        title="Detalhes da proposta"
        titleMarginBottom={3}
        onCloseDialog={handleCloseDetailModal}
        propose={selectedPropose}
      />
    );
  };

  const renderAlert = () => {
    if (alert.message && alert.severity) {
      return (
        <CustomSnackbar
          close={() => setAlert({ ...alert, open: false })}
          {...alert}
        />
      );
    }
  };

  if (loadingLogo) {
    return <LogoLoading />;
  }

  if (proposesData.length === 0) {
    return (
      <>
        {renderHeader()}
        {loadingProposes ? (
          <>
            {renderFilters()}
            {renderTable()}
          </>
        ) : changedFilter ? (
          <>
            {renderFilters()}

            <ResultNotFound
              text="Nenhum Resultado Encontrado"
              buttonText="Limpar Filtros"
              onClick={() => resetFilter()}
            />
          </>
        ) : (
          <>
            {renderFilters()}

            <NoList text="As propostas de Consignado aparecerão aqui" />
          </>
        )}
      </>
    );
  }

  return (
    <div>
      {renderHeader()}
      {renderFilters()}
      {renderTable()}
      {renderApproveModal()}
      {renderReproveModal()}
      {renderNewSimulateModal()}
      {renderEditModal()}
      {renderDetailModal()}
      {renderAlert()}
    </div>
  );
};

export default ConsultCosignedToApprove;
