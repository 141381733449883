import { makeStyles } from '@material-ui/core/styles';

const useDropDownStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: 4,
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(2.5),
    cursor: 'pointer',
    height: 56
  },
  searchField: {
    padding: 0,
    marginBottom: theme.spacing(1.5)
  },
  selectedOption: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    
    '& svg': {
      width: 20,
      height: 20,
    },
    '& p': {
      marginRight: theme.spacing(1),
    },
    '& p:nth-of-type(2)': {
      marginRight: theme.spacing(1),
      whiteSpace: 'nowrap',
      overflow: 'hidden', 
      textOverflow: 'ellipsis',
    },
    '& input': {
      window: 0,
    },
  },
  endAdornment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.5s ease',
  },
  arrowIcon: {
    width: 10,
    height: 10
  },
  optionBox: {
    maxHeight: 200,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: 10
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    }
  },
  newItemButton: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: theme.spacing(1.5),
    fontWeight: 500
  },
  noOptionsText: {
    color: '#808080',
    fontSize: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3.5)
  },
  menuItem: {
    paddingInline: theme.spacing(1.5),
  },
  selectedOptionBackground: {
    backgroundColor: "#F6F3F9",
  },
  doneIcon: {
    marginLeft: 'auto',
  },
  searchIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1.5)
  },
  textField: {
    '& input': {
      cursor: 'pointer',
    }
  },
}));

export default useDropDownStyles;
