import React from 'react';
import styles from '../styles.module.css';
import { Typography } from '@material-ui/core';

function Details() {
  return (
    <div className={styles.details}>
      <Typography className={styles.detailsTitle}>Detalhes da planilha:</Typography>

      <div className={styles.detailsGroup}>
        <Typography className={styles.detailsGroupTitle}>Competência</Typography>
        <Typography className={styles.detailsGroupDescription}>08/2020</Typography>
      </div>

      <div className={styles.detailsGroup}>
        <Typography className={styles.detailsGroupTitle}>Nome da Empresa</Typography>
        <Typography className={styles.detailsGroupDescription}>KLEBER GALIZA</Typography>
      </div>

      <div className={styles.detailsGroup}>
        <Typography className={styles.detailsGroupTitle}>Identificador</Typography>
        <Typography className={styles.detailsGroupDescription}>2332</Typography>
      </div>

      <div className={styles.detailsGroup}>
        <Typography className={styles.detailsGroupTitle}>Nº Contrato:</Typography>
        <Typography className={styles.detailsGroupDescription}>16934</Typography>
      </div>

      <div className={styles.detailsGroup}>
        <Typography className={styles.detailsGroupTitle}>Segurados</Typography>
        <Typography className={styles.detailsGroupDescription}>15</Typography>
      </div>

      <div className={styles.detailsGroup}>
        <Typography className={styles.detailsGroupTitle}>Valor IS:</Typography>
        <Typography className={styles.detailsGroupDescription}>R$ 38.316,00</Typography>
      </div>
    </div>
  );
}

export default Details;
