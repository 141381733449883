import React from 'react';

import {
  Grid,
  InputAdornment,
  IconButton,
  TextField
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

export default function Filter({
  cpfOrName,
  handleChangeCpfOrName,
  setCpfOrName,
  textFieldFocused,
  setTextFieldFocused
}) {
  return (
    <Grid container>
      <TextField
        value={cpfOrName}
        onChange={handleChangeCpfOrName}
        className={styles.searchInput}
        variant="outlined"
        placeholder="Pesquise por nome ou CPF"
        onFocus={() => setTimeout(() => setTextFieldFocused('cpfOrName'), 200)}
        onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.iconColor} />
            </InputAdornment>
          ),
          endAdornment: (cpfOrName?.length > 0 && textFieldFocused === 'cpfOrName') && (
            <IconButton
              className={styles.endAdornment}
              onClick={() => setCpfOrName('')}
            >
              <CloseRoundedIcon className={styles.endAdornmentIcon} />
            </IconButton>
          )
        }}
      />
    </Grid>
  )
}
