import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';

import moment from 'moment';

import {
  Button,
  CircularProgress,
  Hidden,
  Typography,
  TextField,
  FormHelperText,
} from '@material-ui/core';

import CustomDatePicker from '../../../components/DatePicker';
import ProgressToolBar from '../../../components/ProgressToolBar';
import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';
import brazilianCurrencyFormatter2 from '../../../utils/brazilianCurrencyFormatter2';
import formatOnlyNumbers from '../../../utils/formatOnlyNumbers';

import styles from './styles.module.css';
import { api } from '../../../services/api';
import { isMobile } from '../../../utils/breakpoints';

import { ReactComponent as InformIcon } from '../../../assets/informIcon.svg';

export default function TedSetData() {
  const [loading, setLoading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);
  const [date, setDate] = useState('');
  const [value, setValue] = useState('');
  const [digits, setDigits] = useState('');
  const [observation, setObservation] = useState('');
  const [tedId, setTedId] = useState('');
  const validateDateInfo = useValidationForUnformattedDate(date);

  const history = useHistory();

  const { accountSelected } = useSelector(state => state.account);

  const accountData = JSON.parse(sessionStorage.getItem('accountDataTed'));
  const editDepositInformData = JSON.parse(
    sessionStorage.getItem('editDepositInform'),
  );
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const originAccountId = accountData[0]?.id
    ? accountData[0]?.id
    : editDepositInformData.id;

  const bankCodeBradesco = 237;

  useEffect(() => {
    if (editDepositInformData) {
      const {
        id,
        amount,
        scheduleDate,
        receipt,
        notes,
      } = editDepositInformData;
      const digit =
        accountData[0]?.bankCode === bankCodeBradesco ? receipt : '';

      setValue(amount * 100);
      setDate(scheduleDate);
      setDigits(digit);
      setObservation(notes);
      setTedId(id);
    }
  }, []);

  const handleBack = () =>
    history.push('/cash-in/inform-transfer-Deposit', { byScreen: true });

  const onChangeValue = event =>
    setValue(formatOnlyNumbers(event.target.value));

  const onChangeDate = value => setDate(value);

  const onChangeDigits = event =>
    setDigits(event.target.value.replace(/\D/g, '').substring(0, 3));

  const onChangeObservation = event => setObservation(event.target.value);

  const handleNext = () => {
    setIsBlocking(false);
    setLoading(true);

    const amount = value / 100;

    const requestBody = {
      depositAccountOrigin: accountSelected?.origin,
      originAccountId,
      receipt: digits,
      scheduleDate: moment(date).format('YYYY-MM-DD'),
      amount,
      notes: observation,
    };

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
    };

    if (editDepositInformData) {
      api
        .put(`/cashIn/teds/${companyCode}/${tedId}`, requestBody)
        .then(({ data: { id } }) => {
          sessionStorage.setItem(
            'useTopBar',
            JSON.stringify({
              name: 'Edição concluída!',
              step: 'lastStep',
              route: '/cash-in',
            }),
          );

          history.push(`/cash-in/teds/${id}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .post(`/cashIn/teds/${companyCode}`, requestBody, requestOptions)
        .then(({ data: { id } }) => {
          sessionStorage.setItem(
            'useTopBar',
            JSON.stringify({
              name: 'Registro concluído!',
              step: 'lastStep',
              route: '/cash-in',
            }),
          );

          history.push(`/cash-in/teds/${id}`);
        })
        .catch(error => {})
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <Prompt
        when={isBlocking}
        message="Você tem certeza que deseja cancelar a criação desta transferência via TED?"
      />
      <Hidden xsDown>
        <Typography className={styles.title}>
          Novo registro de transferência (TED)
        </Typography>
        <div className={styles.alert}>
          <InformIcon className={styles.informIcon} />
          <Typography className={styles.alertText}>
            Transferências realizadas fora do horário comercial serão
            verificadas no próximo dia útil.
          </Typography>
        </div>
      </Hidden>

      <div className={styles.dataContainer}>
        <Hidden xsDown>
          <div className={styles.accountContainer}>
            <Typography className={styles.titleContainer}>
              Conta de Origem
            </Typography>
            <div style={{ display: 'flex' }}>
              {accountData[0]?.name && (
                <div className={styles.gridItem}>
                  <Typography className={styles.label}>Nome:</Typography>
                  <Typography className={styles.dataName}>
                    {accountData[0]?.name}
                  </Typography>
                </div>
              )}
              <div className={styles.gridItem}>
                <Typography className={styles.label}>Banco:</Typography>
                <Typography className={styles.dataName}>
                  {accountData[0]?.bankName}
                </Typography>
              </div>

              <div className={styles.gridItem}>
                <Typography className={styles.label}>Agência:</Typography>
                <Typography className={styles.dataName}>
                  {accountData[0]?.agency}
                </Typography>
              </div>

              <div className={styles.gridItem}>
                <Typography className={styles.label}>Conta:</Typography>
                <Typography className={styles.dataName}>
                  {accountData[0]?.account}
                </Typography>
              </div>
            </div>
          </div>

          <div className={styles.accountContainer}>
            <Typography className={styles.titleContainer}>
              Destino da TED
            </Typography>
            <div style={{ display: 'flex' }}>
              <div className={styles.gridItem}>
                <Typography className={styles.label}>Banco:</Typography>
                <Typography className={styles.dataName}>Bradesco</Typography>
              </div>

              <div className={styles.gridItem}>
                <Typography className={styles.label}>Ag.:</Typography>
                <Typography className={styles.dataName}>2367</Typography>
              </div>

              <div className={styles.gridItem}>
                <Typography className={styles.label}>Conta:</Typography>
                <Typography className={styles.dataName}>111-2</Typography>
              </div>

              <div className={styles.gridItem}>
                <Typography className={styles.label}>CNPJ:</Typography>
                <Typography className={styles.dataName}>
                  21.444.981/0001-36
                </Typography>
              </div>

              <div className={styles.gridItem}>
                <Typography className={styles.label}>Razão social:</Typography>
                <Typography className={styles.dataName}>
                  SOMA MEIOS ELETRONICOS DE PAGAMENTOS S/A
                </Typography>
              </div>
            </div>
          </div>
        </Hidden>

        <div className={styles.accountContainer}>
          <Hidden xsDown>
            <Typography className={styles.titleContainer}>
              Insira as informações abaixo e continue
            </Typography>
            <Typography className={styles.subtitleContainer}>
              Os <span className={styles.textPurple}>3 Últimos dígitos</span> do
              documento devem ser informado apenas quando a conta de origem for
              do Bradesco.
            </Typography>
          </Hidden>

          <div className={styles.containerInputs}>
            <TextField
              variant="outlined"
              placeholder="Valor da Ted*"
              value={brazilianCurrencyFormatter2(value)}
              onChange={onChangeValue}
              InputProps={{
                maxLength: 15,
                minLength: 8,
              }}
              disabled={loading}
              className={styles.inputItem}
              fullWidth
              label="Valor do Ted*"
              helperText={!isMobile && '*Obrigatório'}
            />

            <div className={styles.inputItem}>
              <CustomDatePicker
                value={date}
                onChange={onChangeDate}
                label={date && 'Data da TED*'}
                disabled={loading}
                placeholder="Data da TED*"
                error={validateDateInfo}
                disableCalendarIcon={validateDateInfo}
              />
              <Hidden smDown>
                {validateDateInfo ? (
                  <Typography className={styles.dateError}>
                    *Data inválida
                  </Typography>
                ) : (
                  <FormHelperText style={{ marginLeft: 14 }}>
                    *Obrigatório
                  </FormHelperText>
                )}
              </Hidden>
            </div>
            <TextField
              variant="outlined"
              label="3 últimos dígitos*"
              className={styles.inputItem}
              helperText={!isMobile && '*Obrigatório'}
              value={digits}
              onChange={onChangeDigits}
              disabled={
                accountData[0]?.bankCode !== bankCodeBradesco || loading
              }
              inputProps={{ maxLength: 3 }}
              type={isMobile && 'number'}
            />
            <TextField
              variant="outlined"
              label="Observações"
              className={styles.inputObservation}
              disabled={loading}
              value={observation}
              onChange={onChangeObservation}
              rows={3}
              multiline
            />
          </div>
        </div>
      </div>

      <Hidden smUp>
        <Button
          className={styles.confirmButton}
          onClick={handleNext}
          disabled={
            !value ||
            !date ||
            (digits.length !== 3 &&
              accountData[0]?.bankCode === bankCodeBradesco)
          }
        >
          {loading ? (
            <CircularProgress className={styles.circularProgress} />
          ) : (
            'Confirmar'
          )}
        </Button>
      </Hidden>

      <Hidden xsDown>
        <ProgressToolBar
          loading={loading}
          numberOfSteps={0}
          currentStep={0}
          onBackClicked={handleBack}
          onNextClicked={handleNext}
          nextButtonText="Confirmar"
          backButtonText="Voltar"
          disabled={
            !value ||
            !date ||
            (digits.length !== 3 &&
              accountData[0]?.bankCode === bankCodeBradesco)
          }
        />
      </Hidden>
    </div>
  );
}
