import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../../services/api';

export default function usePayrollDetailsData({ depositAccountEncodedKey }) {
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const [pinRequired, setPinRequired] = useState(false);

  const { accountSelected, accounts } = useSelector(state => state.account);

  const {
    status,
    fileName,
    scheduleDate,
    paymentDate,
    totalAmount,
    nsu,
    creationUserName,
    approvalUserName,
    paymentsAmount,
    feesAmount,
    expressPaymentAmount,
    importDate,
    approvalDate,
    signatureAmount,
    expressPaymentUserName,
    expressPaymentDate,
  } = JSON.parse(sessionStorage.getItem('payrollData'));

  const accountSelectedToPayrollDetails = accounts?.map(account => {
    if (account.encodedKey === depositAccountEncodedKey) {
      return account.id;
    }
    return null;
  });

  const checkPinRequired = () => {
    api.get(`/companies/${currentCompanyCode}/search`).then(({ data }) => {
      setPinRequired(data.confirmPayroll);
    });
  };

  useEffect(() => {
    checkPinRequired();
  }, []);

  return {
    accounts,
    accountSelected,
    accountSelectedToPayrollDetails,
    pinRequired,
    companyCode: currentCompanyCode,
    payrollData: {
      status,
      fileName,
      scheduleDate,
      paymentDate,
      totalAmount,
      nsu,
      creationUserName,
      approvalUserName,
      paymentsAmount,
      feesAmount,
      expressPaymentAmount,
      importDate,
      approvalDate,
      signatureAmount,
      expressPaymentUserName,
      expressPaymentDate,
    },
  };
}
