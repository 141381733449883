import React from 'react';

import { Box, useTheme } from '@material-ui/core';
import { AvatarIcon } from '../AvatarIcon';
import CardTools from '../CardTools';

export function InfoCard({
  hasBackgroundColor = false,
  color = 'primary',
  icon = null,
  title = '',
  data = '',
  isLoading = false,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: hasBackgroundColor
          ? theme.palette?.[color]?.main5
          : 'transparent',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        borderRadius: '0.5rem',
        paddingTop: hasBackgroundColor ? '0.5rem' : 0,
        paddingRight: hasBackgroundColor ? '0.75rem' : 0,
        paddingBottom: hasBackgroundColor ? '0.5rem' : 0,
        paddingLeft: hasBackgroundColor ? '0.75rem' : 0,
      }}
    >
      <AvatarIcon
        variant="rounded"
        style={{
          width: '53px',
          height: '53px',
          backgroundColor: theme.palette?.[color]?.main10,
        }}
      >
        {React.isValidElement &&
          React.cloneElement(icon, {
            style: {
              color: theme.palette?.[color]?.main,
            },
          })}
      </AvatarIcon>

      <CardTools.CardInfo
        isLoading={isLoading}
        title={title}
        description={data || '-'}
      />
    </Box>
  );
}
