/* eslint-disable no-restricted-properties */

import React from 'react';
import { Box, Card, LinearProgress, Typography } from '@material-ui/core';
import {
  CheckCircleOutlineRounded,
  DeleteRounded,
  InsertDriveFileRounded,
} from '@material-ui/icons';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from '../../styles';

export default function PayrollFilePreview({
  file,
  percentage,
  error,
  onDelete,
  deletable = true,
}) {
  const styles = useStyles();

  const formatBytes = () => {
    const decimals = 2;
    const bytes = file.size;
    if (!+bytes) return '-';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toPrecision(dm))} ${
      sizes[i]
    }`;
  };

  return (
    <Card className={styles.filePreview}>
      <Box className={styles.fileName}>
        <Box>
          <InsertDriveFileRounded color="black" className={styles.fileIcon} />
          <Box className={styles.fileDataContainer}>
            <Typography component="p" variant="body2">
              {file.name || file.path}
            </Typography>
            {error ? (
              <Typography variant="body1" color="error">
                {error}
              </Typography>
            ) : (
              <Typography>{formatBytes()}</Typography>
            )}
          </Box>
        </Box>
        {percentage === 100 && !error ? (
          <CheckCircleOutlineRounded
            color="success"
            className={styles.actionIcon}
          />
        ) : (
          <Buttons.IconButton
            icon={
              <DeleteRounded
                color="text.secondary"
                className={styles.actionIcon}
              />
            }
            onClick={() => onDelete(file)}
            disabled={deletable || !!error}
          />
        )}
      </Box>
      {!error && (
        <Box className={styles.fileProgress}>
          <Box>
            <LinearProgress
              className={styles.progressBar}
              value={percentage}
              variant="determinate"
              color="primary"
            />
          </Box>
          <Typography variant="body2" component="span" color="textSecondary">
            {Math.round(percentage)}%
          </Typography>
        </Box>
      )}
    </Card>
  );
}
