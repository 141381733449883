/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../services/api';
import { BaseConstructor } from '../../helpers/BaseConstructor';

const importFilePayrollErrorsBase = new BaseConstructor({
  extraStates: {
    fileErrorsGeneral: {
      fileErrorsData: {},
      isFileXls: true,
      bankOptions: [],
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
    },
    fileErrorsFixing: {
      employeeNewBankData: {},
      isLoading: false,
      successOn: {
        hasSuccess: false,
        message: '',
      },
      errorOn: {
        hasError: false,
        message: '',
      },
    },
  },
  extraMethods: {
    setIsFileXls: (state, { payload }) => {
      state.fileErrorsGeneral.isFileXls = payload;
    },
    setFileErrorsData: (state, { payload }) => {
      state.fileErrorsGeneral.fileErrorsData = payload;
    },
    setEmployeeNewBankData: (state, { payload }) => {
      state.fileErrorsFixing.employeeNewBankData = payload;
    },

    resetEmployeeNewBankData: state => {
      state.fileErrorsFixing.employeeNewBankData = {};
    },
    resetFileErrorsFixingErrorStatus: state => {
      state.fileErrorsFixing.errorOn = {
        hasError: false,
        message: '',
      };
    },
  },
});

const getBankOptions = createAsyncThunk(
  'importFilePayrollErrors/get/bankOptions',
  async (_, { getState, dispatch, rejectWithValue }) =>
    api
      .get('accountCashIn/bank/findAll')
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      ),
);

const patchEmployeeBankData = createAsyncThunk(
  'importFilePayrollErrors/patch/employeeBankData',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      fileErrorsFixing: { employeeNewBankData },
    } = getState().payroll.importFilePayrollErrors;

    const requestBody = {
      ...employeeNewBankData,
    };

    return api
      .patch('employees/bankData', requestBody)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const importFilePayrollErrorsSlice = createSlice({
  name: 'importFilePayrollErrors',
  initialState: importFilePayrollErrorsBase.initialState,
  reducers: importFilePayrollErrorsBase.methods,
  extraReducers: {
    // ------------------------------------------- GET BANKS LIST ----------------------------------------------------------
    [getBankOptions.pending]: state => {
      state.fileErrorsGeneral.isLoading = true;
      state.fileErrorsGeneral.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [getBankOptions.fulfilled]: (state, { payload }) => {
      state.fileErrorsGeneral.isLoading = false;
      state.fileErrorsGeneral.bankOptions = payload.data;
    },
    [getBankOptions.rejected]: (state, { payload }) => {
      state.fileErrorsGeneral.isLoading = false;
      state.fileErrorsGeneral.errorOn.hasError = true;
      if (!payload) {
        state.fileErrorsGeneral.errorOn.message =
          'Houve um erro ao buscar os bancos';
      } else state.fileErrorsGeneral.errorOn.message = payload;
    },

    // ------------------------------------------- PATCH EMPLOYEE BANK DATA ----------------------------------------------------------
    [patchEmployeeBankData.pending]: state => {
      state.fileErrorsFixing.isLoading = true;
      state.fileErrorsFixing.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [patchEmployeeBankData.fulfilled]: state => {
      state.fileErrorsFixing.isLoading = false;
      state.fileErrorsFixing.successOn = {
        hasSuccess: true,
        message: 'Os dados bancários foram cadastrados com sucesso!',
      };
      state.fileErrorsFixing.employeeNewBankData = {};
    },

    [patchEmployeeBankData.rejected]: (state, { payload }) => {
      state.fileErrorsFixing.isLoading = false;
      state.fileErrorsFixing.errorOn.hasError = true;
      if (!payload) {
        state.fileErrorsFixing.errorOn.message =
          'Houve um erro ao enviar os dados bancários do funcionário';
      } else state.fileErrorsFixing.errorOn.message = payload;
    },
  },
});

const ImportFilePayrollErrorsReducer = importFilePayrollErrorsSlice.reducer;
const ActionsImportFilePayrollErrors = {
  ...importFilePayrollErrorsSlice.actions,
  patchEmployeeBankData,
  getBankOptions,
};

export { ImportFilePayrollErrorsReducer, ActionsImportFilePayrollErrors };
