import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import Header from './Header';
import Form from './Form';

import Footer from '../../../components/AnimatedBottomBar';
import BottomButtons from '../../../components/BottomButtons';
import SuccessModal from '../../../components/SuccessModal';
import Snackbar from '../../../components/Snackbar';

import styles from './styles.module.css';

import { api } from '../../../services/api';

import { isMobile } from '../../../utils/breakpoints';

function RegisterUsers({
  match: {
    params: { id },
  },
}) {
  const history = useHistory();
  const location = useLocation();

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [textFieldFocused, setTextFieldFocused] = useState(false);

  const [errorsDetail, setErrorsDetail] = useState([]);

  const [userForm, setUserForm] = useState({
    name: '',
    cpf: '',
    email: '',
    phoneNumber: '',
    creationUser: '',
    creationDate: '',
    admin_access: false,
    personal_department_access: false,
    financial_access: false,
    authorizer_access: false,
  });

  const IS_EDITING_SCREEN = Boolean(id);
  const DISABLED_INPUTS = !editMode && IS_EDITING_SCREEN;
  const COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  const DISABLED_NEXT_BUTTON =
    loadingButton ||
    !userForm?.name ||
    !userForm?.cpf ||
    !userForm?.email ||
    !userForm?.phoneNumber ||
    (!userForm?.admin_access &&
      !userForm?.personal_department_access &&
      !userForm?.financial_access &&
      !userForm?.authorizer_access);

  useEffect(() => {
    if (IS_EDITING_SCREEN) {
      getUserData();
    }
  }, []);

  function getUserData() {
    setLoading(true);

    api
      .get(`/users/cpf/${id}/${COMPANY_CODE}`)
      .then(({ data }) => {
        setUserForm({
          name: data.name,
          cpf: data.cpf,
          email: data.email,
          phoneNumber: data.phoneNumber,
          creationUser: data.creationUser,
          creationDate: data.creationDate,
          admin_access: data.adminAccess,
          personal_department_access: data.personalDepartmentAccess,
          financial_access: data.financialAccess,
          authorizer_access: data.authorizerAccess,
        });
      })
      .finally(() => setLoading(false));
  }

  function goBack() {
    if (IS_EDITING_SCREEN && !DISABLED_INPUTS) {
      getUserData();
      setEditMode(false);
      return;
    }

    sessionStorage.removeItem('useTopBar');
    history.push('/manage-users');
  }

  function createOrEditUser() {
    setLoadingButton(true);
    setErrorsDetail([]);

    const phone = userForm?.phoneNumber
      .replace('(', '')
      .replace('(', '')
      .replace('-', '')
      .replaceAll(' ', '');

    const body = {
      firstName: userForm?.name?.split(' ')[0],
      lastName: userForm?.name
        ?.split(' ')
        .splice(1)
        .join(' '),
      email: userForm?.email,
      mobilePhone: phone.slice(3, phone.length),
      ddd: phone.slice(0, 2),
      cpf: userForm?.cpf,
      admin_access: userForm?.admin_access,
      personal_department_access: userForm?.personal_department_access,
      financial_access: userForm?.financial_access,
      authorizer_access: userForm?.authorizer_access,
    };

    if (IS_EDITING_SCREEN) {
      updateUser(body);
      return;
    }

    createUser(body);
  }

  function createUser(body) {
    api
      .post(`/users/register/${COMPANY_CODE}`, body)
      .then(() => {
        setOpenSuccessModal(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorsDetail(errors);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function updateUser(body) {
    api
      .put(`/users/update/${COMPANY_CODE}`, body)
      .then(() => {
        setEditMode(false);
        setOpenSnackbar(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorsDetail(errors);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function activeEditMode() {
    setEditMode(!editMode);
  }

  return (
    <div className={styles.container}>
      <Header
        title={
          IS_EDITING_SCREEN ? 'Visualizar dados do usuário' : 'Novo usuário'
        }
        description={!IS_EDITING_SCREEN && 'Informe os dados do usuário'}
        showEditButton={IS_EDITING_SCREEN}
        activeEditMode={activeEditMode}
        disabledButton={editMode || loading}
      />

      <Form
        editUser={IS_EDITING_SCREEN}
        editMode={editMode}
        DISABLED_INPUTS={DISABLED_INPUTS}
        userForm={userForm}
        setUserForm={setUserForm}
        textFieldFocused={textFieldFocused}
        setTextFieldFocused={setTextFieldFocused}
        loading={loading}
        errorsDetail={errorsDetail}
      />

      <Footer
        confirmButtonText={IS_EDITING_SCREEN ? 'Salvar' : 'Criar usuário'}
        onCancel={goBack}
        onConfirm={createOrEditUser}
        hidden={isMobile}
        hiddenNextButton={IS_EDITING_SCREEN && DISABLED_INPUTS}
        cancelButtonText={
          (IS_EDITING_SCREEN && DISABLED_INPUTS) || !IS_EDITING_SCREEN
            ? 'Voltar'
            : 'Cancelar'
        }
        disabledNextButton={DISABLED_NEXT_BUTTON}
        loading={loadingButton}
      />

      <BottomButtons
        isHidden={!isMobile || (IS_EDITING_SCREEN && !editMode)}
        cancelText="Cancelar"
        confirmText="Salvar"
        onCancel={goBack}
        onConfirm={createOrEditUser}
        loading={loadingButton}
        disabledConfirmButton={DISABLED_NEXT_BUTTON}
      />

      <SuccessModal
        title="Novo usuário criado com sucesso!"
        text="O Novo usuário receberá um e-mail para criação da senha de acesso à plataforma."
        onCloseDialog={goBack}
        open={openSuccessModal}
      />

      <Snackbar
        open={openSnackbar}
        close={() => setOpenSnackbar(false)}
        severity="success"
        message="Usuário editado com sucesso!"
      />
    </div>
  );
}

export default RegisterUsers;
