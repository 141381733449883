import React from 'react';

import styles from './styles.module.css';

export default function Header({ 
  title,
  description
}) {
  return (
    <div className={styles.header}>
      <h1>
        {title}
      </h1>
      <h3>
        {description}
      </h3>
    </div>
  )
}
