import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CurrencyInput from 'react-currency-input';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableFooter,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import textCapitalize from '../../../../utils/textCapitalize';

import { api } from '../../../../services/api';
import LimitModal from '../../../../components/PrimaryModal';

import FakeTable from './FakeTable';

import styles from './styles.module.css';

const headCells = [
  { id: 'cpf', numeric: true, disablePadding: false, label: 'CPF' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  {
    id: 'employeeStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  // {* TODO - "Habilitar quando for decidido como vai ficar"
  //   id: 'franchise',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Consume franquia',
  // },
  // { id: 'tariff', numeric: true, disablePadding: false, label: 'Tarifa' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead({ sortTable, order, orderBy }) {
  const createSortHandler = property => event => {
    sortTable(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            align={
              headCell.id === 'actions' || headCell.id === 'franchise'
                ? 'center'
                : 'left'
            }
            sortDirection={orderBy === headCell.id ? order : false}
            className={styles.tableCellHeader}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.tableCellHeader} />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  nsu,
  employees,
  setEmployees,
  totalAmount,
  paymentsAmount,
}) {
  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      marginTop: '1.563rem',
      marginBottom: '4.5rem',
    },
    paper: {
      width: '100%',
      marginBottom: 60,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    header: {
      background: '#ffffff',
    },
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLimitModal, setIsLimitModal] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const { fieldCurrentValue } = useSelector(
    state => state.applyValueTotalAmount.data,
  );

  useEffect(() => {
    getEmployees();
  }, []);

  function getEmployees() {
    const requestOptions = {
      params: {
        unpaged: true,
      },
    };

    setIsLoadingTable(true);

    api
      .get(`/employeesPayments/${nsu}`, requestOptions)
      .then(({ data }) => {
        if (data.totalSize >= 1000) {
          setIsLimitModal(true);
        }
        setEmployees(data);
      })
      .finally(() => setIsLoadingTable(false));
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleClosedLimitModal() {
    setIsLimitModal(false);
    history.push('/payroll');
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onChangeCurrentTextFild(event, row, value) {
    const auxList = [...employees];
    const amount = value;

    const index = auxList.findIndex(item => item.cpf === row.cpf);

    auxList[index].amount = amount;
    setEmployees(auxList.flat(Infinity));
  }

  function handleRemoveEmployee(row) {
    const auxArray = employees;
    const index = auxArray.findIndex(item => item.cpf === row.cpf);

    auxArray.splice(index, 1);
    setEmployees([...auxArray]);
    if (row.amount) {
      dispatch({
        type: 'SET_VALUE_TOTAL_AMOUNT',
        data: {
          totalAmount: totalAmount - row.amount,
          paymentsAmount: paymentsAmount - row.amount,
        },
      });
    }
  }

  function calculationOfTotalValue(rowAmount) {
    const amountValidated = rowAmount || 0;

    if (amountValidated > fieldCurrentValue) {
      const newValueAmount = amountValidated - fieldCurrentValue;
      addValueTotalEmployees(newValueAmount);
    } else if (amountValidated < fieldCurrentValue) {
      const newValueAmount = fieldCurrentValue - amountValidated;
      removeValueTotalEmployees(newValueAmount);
    }
  }

  function addValueTotalEmployees(rowAmount) {
    dispatch({
      type: 'SET_VALUE_TOTAL_AMOUNT',
      data: {
        totalAmount: totalAmount + rowAmount,
        paymentsAmount: paymentsAmount + rowAmount,
      },
    });
  }

  function removeValueTotalEmployees(rowAmount) {
    dispatch({
      type: 'SET_VALUE_TOTAL_AMOUNT',
      data: {
        totalAmount: totalAmount - rowAmount,
        paymentsAmount: paymentsAmount - rowAmount,
      },
    });
  }

  function addCurrentValue(rowAmount) {
    dispatch({
      type: 'SET_VALUE_TOTAL_AMOUNT',
      data: {
        totalAmount,
        paymentsAmount,
        fieldCurrentValue: rowAmount || 0,
      },
    });
  }

  if (isLoadingTable) {
    return <FakeTable />;
  }

  function descendingComparator(firstId, secondId, orderBy) {
    if (secondId[orderBy] < firstId[orderBy]) {
      return -1;
    }
    if (secondId[orderBy] > firstId[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (firstId, secondId) => descendingComparator(firstId, secondId, orderBy)
      : (firstId, secondId) =>
          -descendingComparator(firstId, secondId, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table
          className={classes.table}
          // aria-labelledby="tableTitle"
          // aria-label="enhanced table"
          // stickyHeader
        >
          <EnhancedTableHead
            sortTable={handleRequestSort}
            order={order}
            orderBy={orderBy}
            classes={classes}
          />

          <TableBody>
            {employees
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.cpf}>
                    <TableCell className={styles.tableCell}>
                      {row.cpf}
                    </TableCell>
                    <TableCell className={styles.tableCell}>
                      {textCapitalize(row.name)}
                    </TableCell>

                    <TableCell className={styles.tableCell}>
                      {row.employeeStatus}
                    </TableCell>

                    {/* TODO - "Habilitar quando for decidido como vai ficar"
                       <TableCell className={styles.tableCell} align="center">
                        {row.consumesFranchise === true ? 'Sim' : 'Não'}
                      </TableCell>

                      <TableCell className={styles.tableCell}>
                        {row.feeAmount.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell> */}
                    <TableCell className={styles.tableCell}>
                      <CurrencyInput
                        onChange={(event, value) =>
                          onChangeCurrentTextFild(event, row, value)
                        }
                        value={row.amount}
                        className={styles.inputValue}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix="R$ "
                        onBlur={() => calculationOfTotalValue(row.amount)}
                        onFocus={() => addCurrentValue(row.amount)}
                      />
                    </TableCell>
                    <TableCell align="center" className={styles.tableCell}>
                      <IconButton
                        className={styles.removeEmployeeIconButton}
                        onClick={() => handleRemoveEmployee(row)}
                      >
                        <CloseRoundedIcon
                          className={styles.removeEmployeeIcon}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={employees.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <LimitModal
        open={isLimitModal}
        onConfirmClicked={handleClosedLimitModal}
        confirmButtonText="Entendi"
        title="Atencão!"
        text="Esse pagamento não pode ser regerado. Selecione outro pagamento para seguir com o processo."
      />
    </div>
  );
}
