import React from 'react';
import { CheckOutlined, CloseOutlined } from '@material-ui/icons';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';
import { ChipStatus } from '../../../../../components/ChipStatus';

import formatCPF from '../../../../../utils/formatCPF';
import { statusMap } from '../../constants';

import { useStyles } from '../../styles';

export default function UserCard({ user, onOpenUserModal }) {
  const styles = useStyles();

  const isBlocked = user?.situation === 'BLOCKED';

  const onActionClick = () =>
    onOpenUserModal(user, isBlocked ? 'ACTIVE' : 'BLOCKED');

  const initials = user.name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();

  const getRole =
    user.accessProfile === 'FINANCIAL' ? 'Financeiro' : 'Departamento pessoal';

  const tooltip = `${isBlocked ? 'Desbloquear' : 'Bloquear'} utilizador`;

  const renderStatus = () => {
    const status = statusMap[user?.situation];

    return (
      <ChipStatus
        className={styles.userStatus}
        icon={status.icon}
        status={status.color}
        color={status.color}
        variant="outlined"
        label={status.label}
        size="small"
      />
    );
  };

  const renderIcon = isBlocked ? (
    <CheckOutlined color="success" />
  ) : (
    <CloseOutlined color="error" />
  );

  return (
    <Card className={styles.userCard}>
      <CardHeader
        className={styles.cardHeader}
        disableTypography
        title={renderStatus()}
        action={
          <Buttons.IconButton
            tooltipTitle={tooltip}
            icon={renderIcon}
            aria-label="Ações"
            color="primary"
            onClick={onActionClick}
          />
        }
      />
      <CardContent className={styles.cardBody}>
        <Avatar className={styles.cardAvatar}>{initials}</Avatar>
        <Box className={styles.cardText}>
          <Typography
            variant="h3"
            color="textPrimary"
            className={styles.cardTitle}
          >
            {user.name}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary">
            {getRole}
          </Typography> */}
        </Box>
        <Box className={styles.cardInfo}>
          <Box className={styles.cardData}>
            <Typography
              variant="label"
              component="h4"
              className={styles.cardDataTitle}
            >
              CPF
            </Typography>
            <Typography variant="body">{formatCPF(user.cpf)}</Typography>
          </Box>
          <Box>
            <Typography
              variant="label"
              component="h4"
              className={styles.cardDataTitle}
            >
              Telefone
            </Typography>
            <Typography variant="body">{user.phoneNumber}</Typography>
          </Box>
          <Box>
            <Typography
              variant="label"
              component="h4"
              className={styles.cardDataTitle}
            >
              E-mail
            </Typography>
            <Typography variant="body">{user.email}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
