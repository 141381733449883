import React from 'react';

import { Button, Typography, Box, IconButton } from '@material-ui/core';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import styles from './styles.module.css';

import PrimaryModal from '../../../components/PrimaryModal';
import CustomSnackbar from '../../../components/Snackbar';

import SituationEmployeesTabs from './UI/SituationEmployeesTabs';

import { useEmployeesList } from './UI/SituationEmployeesTabs/Hooks/useEmployeesList';
import { useConfirmIncludeEmployess } from './UI/SituationEmployeesTabs/Hooks/useConfirmIncludeEmployess';

export default function ResponsiveIncludeCollaborators() {
  const {
    selectedRowsEmployees,
    employeeBankData,
    handleCloseSuccessSnackbar,
    handleResetSelectedEmployees,
  } = useEmployeesList();

  const {
    openFiredEmployeesModal,
    setOpenFiredEmployeesModal,
    handleConfirmIncludeEmployees,
    handleIncludeEmployees,
  } = useConfirmIncludeEmployess();

  function TitleFiredEmployees() {
    return (
      <Typography className={styles.titleFiredEmployees}>
        Incluir funcionários{' '}
        <span className={styles.labelFired}>demitidos</span>?
      </Typography>
    );
  }

  return (
    <Box>
      <SituationEmployeesTabs />

      <Box className={styles.footer}>
        {selectedRowsEmployees?.length > 0 && (
          <span
            className={
              selectedRowsEmployees.length === 0 ? styles.hidden : styles.count
            }
          >
            <span className={styles.countNumber}>
              {selectedRowsEmployees?.length}
            </span>{' '}
            {selectedRowsEmployees?.length > 1 ? 'Selecionados' : 'Selecionado'}
            <IconButton
              size="small"
              onClick={() => handleResetSelectedEmployees()}
              style={{ color: 'var(--orangeDigital)', marginLeft: '0.5rem' }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </span>
        )}
        <Button
          className={styles.continueButton}
          onClick={handleIncludeEmployees}
          disabled={selectedRowsEmployees?.length === 0}
        >
          Continuar
        </Button>
      </Box>

      <CustomSnackbar
        severity="success"
        open={employeeBankData.successOn.hasSuccess}
        close={handleCloseSuccessSnackbar}
        message={employeeBankData.successOn.message}
      />

      <PrimaryModal
        title={TitleFiredEmployees()}
        text="Você selecionou funcionários que estão com status de demitido.
          Quer continuar com o pagamento?"
        open={openFiredEmployeesModal}
        confirmButtonText="Sim, continuar"
        cancelButtonText="Não"
        onCancelButtonClicked={() => setOpenFiredEmployeesModal(false)}
        onConfirmClicked={handleConfirmIncludeEmployees}
      />
    </Box>
  );
}
