import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import IpDetailsModal from './IpDetailsModal';
import IpDeletionModal from './DeletionModal';
import IpCreationModal from './CreationModal';

export default function IpDetailsModals() {
  return (
    <>
      <ModalDef id="ip-details-modal" component={IpDetailsModal} />
      <ModalDef id="ip-delete-modal" component={IpDeletionModal} />
      <ModalDef id="ip-create-modal" component={IpCreationModal} />
    </>
  );
}
