import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';

import FakeList from './UI/FakeList';
import colorStatus from './UI/ColorStatus';
import styles from './styles.module.css';

function AnticipationMobileList({
  data,
  loadingList,
  currentPageSize,
  totalSize,
  fetchData,
}) {
  return (
    <div className={styles.containerMobileList}>
      <List component="nav">
        {!fetchData && loadingList ? (
          <FakeList />
        ) : (
          <InfiniteScroll
            dataLength={data?.length}
            hasMore
            loader={
              fetchData &&
              currentPageSize < totalSize - 5 && (
                <div className={styles.circularProgressContainer}>
                  <CircularProgress
                    size={25}
                    className={styles.circularProgress}
                  />
                </div>
              )
            }
          >
            {data?.map(element => (
              <ListItem className={styles.listItemContainer}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    justify="space-between"
                    className={styles.statusContainer}
                  >
                    <Grid item container alignItems="center">
                      <Grid item className={styles.icon}>
                        {colorStatus(element.status)}
                      </Grid>
                      <Grid item>
                        <Typography className={styles.statusLabel}>
                          {' '}
                          {element.status}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {data && (
                    <Grid item className={styles.cpf}>
                      <Typography className={styles.cpfLabel}>
                        {element.cpf}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item className={styles.amount}>
                    <Typography className={styles.amountLabel}>
                      {element.amount_to_pay}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </InfiniteScroll>
        )}
      </List>
    </div>
  );
}

export default AnticipationMobileList;
