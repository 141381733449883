import React from 'react';
import { useHistory } from 'react-router-dom';

import Buttons from '../../../../components/Buttons';

import useAcademy from '../../hooks/useAcademy';

import { useStyles } from '../../styles';

export default function HelpCenterBottomBar({ id }) {
  const styles = useStyles();
  const history = useHistory();

  const { isDownloading, handleDownload } = useAcademy();

  const onBack = () => {
    history.goBack();
  };

  const onDownloadArticle = () => {
    handleDownload(id);
  };

  return (
    <footer className={styles.footer}>
      <Buttons.PrimaryButton
        variant="outlined"
        color="primary"
        onClick={onBack}
        title="Voltar"
      />

      <Buttons.PrimaryButton
        color="primary"
        loading={isDownloading}
        title="Baixar artigo"
        onClick={onDownloadArticle}
      />
    </footer>
  );
}
