import React, { useCallback, useState } from 'react';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import styles from './styles.module.css';
import { useConsignedMargins } from '../../utils/hooks';

function SearchBar() {
  const { search, setSearch } = useConsignedMargins();

  const [textFieldFocused, setTextFieldFocused] = useState('');

  const onSearchChange = useCallback(
    e => {
      const { value } = e.target;

      setSearch(value);
    },
    [setSearch],
  );

  return (
    <div>
      <TextField
        onFocus={() => setTimeout(() => setTextFieldFocused('search'), 200)}
        onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
        onChange={onSearchChange}
        className={styles.searchBackground}
        placeholder="Pesquisar por nome ou CPF"
        autoFocus
        fullWidth
        variant="outlined"
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: search?.length > 0 && textFieldFocused === 'search' && (
            <IconButton
              className={styles.endAdornment}
              onClick={() => setSearch('')}
            >
              <CloseRoundedIcon className={styles.endAdornmentIcon} />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default SearchBar;
