const initialState = {
  data: [],
};

export default function headerSuccess(state = initialState, action) {
  switch (action.type) {
    case 'SET_HEADER_SUCCESS':
      return { ...state, data: action.data };
    default:
      return state;
  }
}
