import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';

import PurpleCheckbox from '../../../../components/Checkbox/PurpleCheckbox';
import FakeTable from './FakeTable';

import textCapitalize from '../../../../utils/textCapitalize';

const headCells = [
  { id: 'register', numeric: true, disablePadding: false, label: 'Matrícula' },
  { id: 'cpf', numeric: false, disablePadding: false, label: 'CPF' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'situation', numeric: false, disablePadding: false, label: 'Situação' },
];

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
}) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}

        <TableCell padding="checkbox">
          {/*
          // TODO
          <PurpleCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    marginBottom: 5,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  employees,
  isLoading,
  totalSize,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  selected,
  setSelected,
  setIsSelectedAll,
  isSelectedAll,
  ignoredEmployees,
  setIgnoredEmployees,
}) {
  const classes = useStyles();

  function handleIgnoredEmployees(row) {
    const selectedIndexIgnoreds = ignoredEmployees
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    const ignoreds = [...ignoredEmployees];
    if (selectedIndexIgnoreds !== -1) {
      ignoreds.splice(selectedIndexIgnoreds, 1);
      setIgnoredEmployees([...ignoreds]);
    } else {
      setIgnoredEmployees([
        ...ignoreds,
        {
          cpf: row.cpf,
          encodedKey: row.encodedKey,
        },
      ]);
    }
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = employees.map(row => ({
        name: row.name,
        cpf: row.cpf,
        amount: row.amount,
        registration: row.companies[0].registration,
        employeeStatus: row.companies[0].status,
      }));

      setIgnoredEmployees([]);
      setSelected(newSelecteds.filter(item => item));
      setIsSelectedAll(true);
      return;
    }

    setSelected([]);
    setIsSelectedAll(false);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    let newSelected = [];

    if (isSelectedAll) {
      handleIgnoredEmployees(row);
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        name: row.name,
        cpf: row.cpf,
        amount: row.amount,
        registration: row.companies[0].registration,
        employeeStatus: row.companies[0].status,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = row =>
    selected
      .map(function(e) {
        return e?.cpf;
      })
      .indexOf(row?.cpf) !== -1;

  const isIgnored = row =>
    ignoredEmployees
      .map(function(e) {
        return e?.cpf;
      })
      .indexOf(row?.cpf) !== -1;

  if (isLoading) {
    return <FakeTable />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              numSelected={isSelectedAll ? totalSize : selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={isSelectedAll ? totalSize : employees.length}
            />

            <TableBody>
              {employees.map((row, index) => {
                const isItemSelected = isSelected(row);
                const isItemIgnored = isIgnored(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={
                      isSelectedAll ? isItemSelected : isItemSelected
                    }
                    tabIndex={-1}
                    key={row.registration}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {row.companies[0].registration}
                    </TableCell>
                    <TableCell>{row.cpf}</TableCell>
                    <TableCell>{textCapitalize(row.name)}</TableCell>
                    <TableCell>{row.companies[0].statusDescription}</TableCell>
                    <TableCell padding="checkbox">
                      <PurpleCheckbox
                        checked={
                          isSelectedAll ? !isItemIgnored : isItemSelected
                        }
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={event => handleClick(event, row)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
