import React from 'react';

import { IconButton, Typography } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import styles from './styles.module.css';

function MobileList({
  title,
  text,
  description,
  icon = <ArrowForwardIosRoundedIcon className={styles.icon} />,
  info,
  alignCenter,
  onClickCard,
  onClickIcon
}) {
  return (
    <div className={styles.card} onClick={onClickCard}>
      <div className={styles.cardBlock}>
        <Typography className={styles.cardTitle}>
          {title}
        </Typography>

        <Typography className={styles.cardText}>
          {text}
        </Typography>

        <Typography className={styles.cardText}>
          {description}
        </Typography>
      </div>

      <div className={alignCenter ? styles.cardBlockCenter : styles.cardBlock}>
        <IconButton onClick={onClickIcon}>
          {icon}
        </IconButton>

        {info && (
          <Typography className={styles.cardBlockInfo}>
            {info}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default MobileList;
