import { useEffect, useState } from 'react';

import { handleSnackbarStack } from '../utils/handleSnackbarStack';

const useGeolocation = () => {
  const { error } = handleSnackbarStack();

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const getLocationCoordinates = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;

        setLocation({ latitude, longitude });
      },
      _ => {
        error(
          'Ocorreu um erro ao obter a geolocalização do dispositivo. Por favor, recarregue a página e tente novamente em alguns instantes.',
        );
      },
    );
  };

  const getLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
        if (state === 'denied') return;
        // {
        //   error(
        //     'Para prosseguir com a operação, por favor permita o acesso à sua localização.',
        //   );
        // }
        if (state === 'granted' || state === 'prompt') {
          getLocationCoordinates();
        }
      });
    } else {
      error(
        'O acesso a geolocalização não é suportado pelo seu navegador. Por favor, tente novamente mais tarde.',
      );
    }
  };

  useEffect(() => {
    getLocationPermission();
  }, []);

  return location;
};

export default useGeolocation;
