import React from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import { Grid, Typography } from '@material-ui/core';

import styles from './styles.module.css';

import { isMobile } from '../../../utils/breakpoints';
import textCapitalize from '../../../utils/textCapitalize';

export default function GridComponent({ paycheckData, isLoading }) {
  const { 
    paycheckCount, 
    name, 
    installment, 
    paymentType, 
    competencyDate,
    creationUser,
    creationDate,
    id
  } = paycheckData;

  const FORMATED_COMPETENCE = `
    ${textCapitalize(moment(competencyDate).locale('pt-br').format('MMM'))} de
    ${moment(competencyDate).locale('pt-br').format('YYYY')}
  `;

  if (isMobile) {
    return (
      <Grid md={12} className={styles.gridContainer}>
        <Typography className={styles.gridTitle}>
          Detalhes da importação
        </Typography>
  
        <Grid 
          container
          spacing={5}
          className={styles.grid}
        >
          <Grid item>
            <Typography className={styles.gridLabel}>
              Identificador
            </Typography>
          
            {isLoading ? (
              <Skeleton
                height={22}
                width={90}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.gridInfo}>
                {id || '-'}
              </Typography>
            )}
          </Grid>
  
          <Grid item>
            <Typography className={styles.gridLabel}>
              Competência
            </Typography>
            <Typography className={styles.gridInfo}>
              {isLoading ? (
                <Skeleton
                  height={22}
                  width={90}
                  color="#202020"
                  highlightColor="#444"
                  className={styles.value}
                />
              ) : (
                <Typography className={styles.gridInfo}>
                  {FORMATED_COMPETENCE || '-'}
                </Typography>
              )}
            </Typography>
          </Grid>
  
          <Grid item>
            <Typography className={styles.gridLabel}>
              Nome do arquivo
            </Typography>

            {isLoading ? (
              <Skeleton
                height={22}
                width={90}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.gridInfo}>
                {name || '-'}
              </Typography>
            )}
          </Grid>
  
          <Grid item>
            <Typography className={styles.gridLabel}>
              Tipo
            </Typography>

            {isLoading ? (
              <Skeleton
                height={22}
                width={90}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.gridInfo}>
                {paymentType || '-'}
              </Typography>
            )}
          </Grid>
  
          <Grid item>
            <Typography className={styles.gridLabel}>
              Parcela
            </Typography>

            {isLoading ? (
              <Skeleton
                height={22}
                width={90}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.gridInfo}>
                {installment || '-'}
              </Typography>
            )}
          </Grid>
          
          <Grid item>
            <Typography className={styles.gridLabel}>
              Funcionários
            </Typography>

            {isLoading ? (
              <Skeleton
                height={22}
                width={90}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.gridInfo}>
                {paycheckCount || '-'}
              </Typography>
            )}
          </Grid>
  
          <Grid item>
            <Typography className={styles.gridLabel}>
              Criação
            </Typography>

            {isLoading ? (
              <Skeleton
                height={22}
                width={90}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <>
                <Typography className={styles.gridInfo}>
                  {creationUser || '-'}
                </Typography>
                <Typography className={styles.gridInfoGray}>
                  {creationDate ? `${creationDate.substring(0, 10)} 
                  às ${creationDate.substring(11, creationDate.length)}` : ''}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  
  return (
    <Grid md={12} className={styles.gridContainer}>
      <Typography className={styles.gridTitle}>
        Detalhes da importação
      </Typography>

      <Grid 
        container
        spacing={5}
        className={styles.grid}
      >
        <Grid item>
          <Typography className={styles.gridLabel}>
            Identificador
          </Typography>
          {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <Typography className={styles.gridInfo}>
              {id || '-'}
            </Typography>
          )}
        </Grid>

        <Grid item>
          <Typography className={styles.gridLabel}>
            Nome do arquivo
          </Typography>
          {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <Typography className={styles.gridInfo}>
              {name || '-'}
            </Typography>
          )}
        </Grid>

        <Grid item>
          <Typography className={styles.gridLabel}>
            Parcela
          </Typography>
          {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <Typography className={styles.gridInfo}>
              {installment || '-'}
            </Typography>
          )}
        </Grid>

        <Grid item>
          <Typography className={styles.gridLabel}>
            Tipo
          </Typography>
          {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <Typography className={styles.gridInfo}>
              {paymentType || '-'}
            </Typography>
          )}
        </Grid>

        <Grid item>
          <Typography className={styles.gridLabel}>
            Competência
          </Typography>
          <Typography className={styles.gridInfo}>
            {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <Typography className={styles.gridInfo}>
              {FORMATED_COMPETENCE || '-'}
            </Typography>
          )}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={styles.gridLabel}>
            Funcionários
          </Typography>
          <Typography className={styles.gridInfo}>
            {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <Typography className={styles.gridInfo}>
              {paycheckCount || '-'}
            </Typography>
          )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Typography className={styles.gridLabel}>
            Criação
          </Typography>
          {isLoading ? (
            <Skeleton
              height={22}
              width={120}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
            />
          ) : (
            <>
              <Typography className={styles.gridInfo}>
                {textCapitalize(creationUser) || '-'}
              </Typography>
              <Typography className={styles.gridInfoGray}>
                {creationDate ? `${moment(creationDate).format('DD/MM/YYYY').substring(0, 10)} 
                às ${creationDate.substring(11, 19)}` : '-'}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
