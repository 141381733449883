import React from 'react';

import {
  Button,
  CircularProgress,
  Typography
} from "@material-ui/core";
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import Chip from '@material-ui/core/Chip';

import Tab from '../../../components/Tab';
import HistoryTable from './HistoryTable';

import styles from './styles.module.css';
import { isMobile } from '../../../utils/breakpoints';

export default function History({
  setOpenAddInsuranceModal,
  chipData,
  setChipData,
  insuranceHistory,
  loading
}) {
  const historyData = insuranceHistory['Seguro de Vida'];
  const WITHOUT_HISTORY = 0;

  const handleDelete = (chipToDelete) => () => {
    // setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  function renderchips() {
    return (
      <div className={styles.chipsContainer}>
        {chipData.map((data) => {
          return (
            <div key={data.key}>
              <Chip
                label={data.label}
                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                className={styles.chip}
              />
            </div>
          );
        })}
      </div>
    )
  }

  function renderAddInsuranceButton() {
    return (
      <Button
        className={styles.historyButton}
        onClick={() => setOpenAddInsuranceModal(true)}
        disabled
      >
        <QueueOutlinedIcon className={styles.historyButtonIcon} />
        Adicionar seguro
      </Button>
    )
  }

  function renderDetails() {
    if (loading) {
      return (
        <div className={styles.loadingContainer}>
          <CircularProgress />
        </div>
      )
    }

    if (historyData?.length === WITHOUT_HISTORY || !historyData) {
      return (
        <div className={styles.withoutHistory}>
          <Typography className={styles.withoutHistoryText}>
            Não há histórico de ocorrências
          </Typography>
        </div>
      )
    }

    return (
      <div className={styles.historyDetailsContainer}>
        <Typography className={styles.biggerTitleTab}>
          Seguro de vida
        </Typography>
        <HistoryTable
          historyData={historyData}
        />
      </div>
    )
  }

  return (
    <div className={styles.historyContainer}>
      <div className={styles.historyHead}>
        <div>
          <Typography className={styles.historyTitle}>
            Seguros vigentes
          </Typography>

          {isMobile ? renderAddInsuranceButton() : renderchips()}
        </div>

        {isMobile ? renderchips() : renderAddInsuranceButton()}
      </div>

      <Tab
        summary={
          <Typography className={styles.tabTitle}>
            Histórico de ocorrências
          </Typography>
        }
        details={renderDetails()}
      />
    </div>
  )
}
