import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import styles from './styles.module.css';

export default function BottomBar({
  onNextClick,
  onBackClick,
  SHOW_BOTTOM_BAR,
  loading,
  date,
  paymentHour,
}) {
  return (
    <div
      className={SHOW_BOTTOM_BAR ? styles.bottomBarContainer : styles.hidden}
    >
      <Button
        className={styles.whiteButton}
        onClick={onBackClick}
        disabled={loading}
      >
        Voltar
      </Button>

      <Button
        className={styles.purpleButton}
        onClick={onNextClick}
        disabled={
          loading ||
          moment(date).format('DD/MM/YYYY') === 'Data inválida' ||
          !paymentHour
        }
      >
        {loading ? (
          <CircularProgress className={styles.circularProgress} />
        ) : (
          'Continuar'
        )}
      </Button>
    </div>
  );
}
