import React from 'react';

import { Button, Dialog, Grid, Typography } from '@material-ui/core';

import styles from './styles.module.css';

function ModalSkippedInvalidDeposits({
  confirmModal,
  onContinueProcess,
  onDontContinueProcess,
}) {
  return (
    <Dialog open={confirmModal} maxWidth="xs" fullWidth>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={styles.container}
      >
        <Grid item className={styles.titleContainer}>
          <Typography className={styles.title}>Atencão!</Typography>
        </Grid>

        <Grid item>
          <Typography className={styles.subtitle}>
            Alguns funcionários estão com os
            <span className={styles.subtitleBold}>
              {' '}
              valores zerados e não serão pagos
            </span>
            . Quer continuar mesmo assim com o pagamento?
          </Typography>
        </Grid>

        <Grid item container direction="row-reverse">
          <Grid item className={styles.buttonContainer}>
            <Button onClick={onContinueProcess} className={styles.button}>
              Sim, continuar
            </Button>
          </Grid>

          <Grid item className={styles.buttonContainer}>
            <Button
              onClick={onDontContinueProcess}
              className={styles.closeButton}
            >
              Não
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ModalSkippedInvalidDeposits;
