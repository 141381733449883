import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Dialog,
} from '@material-ui/core';

import styles from '../styles.module.css';

function FakeUpgradeModal({
  open,
  onCloseDialog,
  onConfirm,
  onCancel,
  mainInformations,
  loading,
}) {

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      fullWidth
      maxWidth='xs'
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <Skeleton
              className={styles.title}
              height={23}
              width={150}
              color="#202020"
              highlightColor="#444"
            />
            <Skeleton
              className={styles.title}
              height={23}
              width={65}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={styles.row}>
            <div className={styles.block}>
              <Skeleton
                className={styles.title}
                height={18}
                width={70}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                className={styles.title}
                height={23}
                width={65}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.block}>
              <Skeleton
                className={styles.title}
                height={23}
                width={65}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                className={styles.title}
                height={23}
                width={25}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={styles.block}>
              <Skeleton
                className={styles.title}
                height={23}
                width={100}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                className={styles.title}
                height={23}
                width={60}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={styles.block}>
              <Skeleton
                className={styles.title}
                height={23}
                width={150}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                className={styles.title}
                height={23}
                width={80}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>
        </div>

        {/* <div className={styles.content}>
          <Typography className={styles.biggerText}>Turbine seu plano!</Typography>

          <div className={styles.contentRow}>
            <Typography className={styles.text}>
              Faça upgrade para otimizar ainda mais o seu tempo.
            </Typography>
            <Button onClick={onConfirm} className={styles.upgradeButton}>Fazer Upgrade</Button>
          </div>
        </div> */}

        <div className={styles.footer}>
          <Skeleton
            className={styles.title}
            height={23}
            width={130}
            color="#202020"
            highlightColor="#444"
          />
        </div>

      </div>
    </Dialog>
  );
}

export default FakeUpgradeModal;
