import React from 'react';

import { Grid } from '@material-ui/core';

import style from './styles.module.css';

import Title from '../../../../../../../components/Title';

export default function Header() {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      className={style.header}
    >
      <Title>Confirme o cadastro via planilha do Excel</Title>
    </Grid>
  );
}
