import { useCallback, useState, useEffect, useContext } from 'react';

import { getProcessById } from './services';
import { ConsignedMarginsBatchResultContext } from '..';

export const useProcessResult = processId => {
  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await getProcessById(processId);

      setData(data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [processId]);

  useEffect(() => {
    getData();
  }, []);

  return {
    isLoading,
    isError,
    data,
    refresh: getData,
  };
};

export const useConsignedMarginBatchConfirm = () => {
  const context = useContext(ConsignedMarginsBatchResultContext);

  return context;
};
