/* eslint-disable no-param-reassign */
import { BaseConstructor } from '../BaseConstructor';

export function PaginationConstructor({
  extraStates = {},
  extraMethods = {},
  Signature = BaseConstructor,
}) {
  const signature = new Signature({ extraStates, extraMethods });

  this.initialState = {
    page: 0,
    perPage: 5,
    length: 0,
    ...signature.initialState,
  };

  this.methods = {
    handleChangePage: (state, { payload }) => {
      state.page = payload;
    },
    handleChangePerPage: (state, { payload }) => {
      state.perPage = payload;
    },

    ...signature.methods,
  };
}
