import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Header from './Header';
import History from './History';
import EditData from './EditData';
import BottomBar from './BottomBar';

import ProgressToolBar from '../../../components/ProgressToolBar';
import AddInsuranceModal from '../../../components/CheckModal';
import Snackbar from '../../../components/Snackbar';

import { api } from '../../../services/api';

import { isMobile } from '../../../utils/breakpoints';
import formatOnlyNumbers from '../../../utils/formatOnlyNumbers';

export default function Details({
  match: {
    params: { cpf },
  },
}) {
  const history = useHistory();

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const insuranceEncodedKey = sessionStorage.getItem('insuranceEncodedKey');

  const [editMode, setEditMode] = useState(false);
  const [openAddInsuranceModal, setOpenAddInsuranceModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [lifeInsurance, setLifeInsurance] = useState(false);
  const [healthInsurance, setHealthInsurance] = useState(false);
  const [dentalInsurance, setDentalInsurance] = useState(false);

  const [chipData, setChipData] = useState([{ key: 0, label: 'Vida' }]);

  const [insuranceHistory, setInsuranceHistory] = useState({});
  const [personalDataForm, setPersonalDataForm] = useState({});
  const [professionalDataForm, setProfessionalDataForm] = useState({});
  const [salary, setSalary] = useState('');
  const [addressDataForm, setAddressDataForm] = useState([]);
  const [contactDataForm, setContactDataForm] = useState({});
  const [insurancesDataForm, setInsurancesDataForm] = useState([]);
  const [headerData, setHeaderData] = useState({});
  const [expandedTabs, setExpandedTabs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [stockings, setStockings] = useState([]);
  const [contracts, setContracts] = useState([]);

  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [onSubmitForm, setOnSubmitForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  const [textFieldFocused, setTextFieldFocused] = useState('');

  const ALL_TABS_ARRAY = [
    'personalData',
    'professionalData',
    'addressData',
    'contactData',
  ];

  useEffect(() => {
    getInsuranceData();
  }, []);

  useEffect(() => {
    if (!editMode) {
      setExpandedTabs([]);
    }
  }, [editMode]);

  function getInsuranceData() {
    setLoading(true);

    api
      .get(`insurance/v2/${cpf}/${currentCompanyCode}`)
      .then(({ data }) => {
        const personalData = data?.personalData;
        const professionalData = data?.professionalData;
        const addressData = data?.addresses;
        const contactData = data?.contacts;
        const insurancesData = data?.insurances;

        const {
          name,
          cpf,
          birthdate,
          gender,
          rg,
          rgIssuingDate,
          rgIssuingState,
          rgIssuingAgency,
          maritalStatus,
          motherName,
        } = personalData;

        const {
          companyName,
          employeeRegistration,
          stocking,
          locatedAt,
          employeeStatus,
          admissionDate,
          resignationDate,
          typeContract,
          dateCreationEmployee,
        } = professionalData;

        const salary = professionalData.salary * 100;

        setHeaderData({
          name,
          locatedAt,
          stocking,
          dateCreationEmployee,
        });

        setPersonalDataForm({
          name,
          cpf,
          birthdate,
          gender,
          rg,
          rgIssuingDate,
          rgIssuingState,
          rgIssuingAgency,
          maritalStatus,
          motherName,
        });

        setProfessionalDataForm({
          companyName,
          employeeRegistration,
          salary,
          stocking,
          locatedAt,
          employeeStatus,
          admissionDate,
          resignationDate,
          typeContract,
          dateCreationEmployee,
        });

        setAddressDataForm(
          addressData.length > 0
            ? addressData.map(item => ({
                type: item.type,
                street: item.street,
                number: item.number,
                complement: item.complement,
                zipCode: item.zipCode,
                neighborhood: item.neighborhood,
                city: item.city,
                state: item.state,
              }))
            : [
                {
                  type: null,
                  street: null,
                  number: null,
                  complement: null,
                  zipCode: null,
                  neighborhood: null,
                  city: null,
                  state: null,
                },
              ],
        );

        setContactDataForm({
          email: contactData.email,
          phones: contactData?.phones?.map(item => ({
            type: item.type,
            number: item.number,
            isMain: item.isMain,
          })),
        });

        setInsurancesDataForm(
          insurancesData.map(item => {
            return {
              companyEncodedKey: item.companyEncodedKey,
              id: item.id,
              situation: item.situation,
              type: item.type,
              typeDescription: item.typeDescription,
            };
          }),
        );

        setInsuranceHistory(data?.insuranceOccurrenceHistory);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function validateFields() {
    const {
      name,
      cpf,
      birthdate,
      rg,
      rgIssuingDate,
      rgIssuingState,
      rgIssuingAgency,
      motherName,
    } = personalDataForm;

    const {
      companyName,
      employeeRegistration,
      employeeStatus,
      admissionDate,
    } = professionalDataForm;

    const { type, number } = contactDataForm?.phones;

    const testPersonalData = Boolean(
      name &&
        cpf &&
        birthdate &&
        rg &&
        rgIssuingDate &&
        rgIssuingState &&
        rgIssuingAgency &&
        motherName,
    );

    const testProfessionalData = Boolean(
      companyName && employeeRegistration && employeeStatus && admissionDate,
    );

    const testContactData = Boolean(type && number);

    if (!testPersonalData || !testProfessionalData || !testContactData) {
      alertForUnfiledFields();
      return;
    }

    updateData();
  }

  function handleSubmit() {
    setOnSubmitForm(true);
    validateFields();
  }

  function updateData() {
    const currentLocatedAt = locations.filter(
      item => item.description === professionalDataForm.locatedAt,
    );
    const currentStocking = stockings.filter(
      item => item.description === professionalDataForm.stocking,
    );

    setRequestLoading(true);

    const body = {
      personalData: {
        cpf: personalDataForm?.cpf,
        name: personalDataForm?.name,
        birthdate: personalDataForm?.birthdate
          ? moment(personalDataForm?.birthdate).format('YYYY-MM-DD')
          : null,
        gender: personalDataForm?.gender,
        rg: personalDataForm?.rg,
        rgIssuingDate: moment(personalDataForm?.rgIssuingDate).format(
          'YYYY-MM-DD',
        ),
        rgIssuingAgency: personalDataForm?.rgIssuingAgency,
        rgIssuingState: personalDataForm?.rgIssuingState,
        motherName: personalDataForm?.motherName,
        maritalStatus: personalDataForm?.maritalStatus,
      },

      professionalData: {
        companyName: professionalDataForm?.companyName,
        typeContract: professionalDataForm?.typeContract,
        employeeRegistration: professionalDataForm?.employeeRegistration,
        admissionDate: moment(professionalDataForm?.admissionDate).format(
          'YYYY-MM-DD',
        ),
        locatedAt: currentLocatedAt === [] ? null : currentLocatedAt[0]?.code,
        stocking: currentStocking === [] ? null : currentStocking[0]?.code,
        employeeStatus: professionalDataForm?.employeeStatus,
        salary: formatOnlyNumbers(salary) / 100,
        resignationDate: professionalDataForm?.resignationDate
          ? professionalDataForm?.resignationDate
          : null,
      },

      addresses: addressDataForm.map(item => ({
        street: item?.street,
        number: item?.number,
        complement: item?.complement,
        zipCode: item?.zipCode,
        neighborhood: item?.neighborhood,
        city: item?.city,
        state: item?.state,
        type: item?.type,
      })),

      contacts: {
        email: contactDataForm?.email,
        phones: contactDataForm?.phones,
      },

      insurances: insurancesDataForm,
    };

    api
      .put(
        `/insurance/v2/update/${insuranceEncodedKey}/${currentCompanyCode}`,
        body,
      )
      .then(() => {
        setEditMode(false);
        addInsurance();
        getInsuranceData();
      })
      .finally(() => setRequestLoading(false));
  }

  function onBackClicked() {
    setEditMode(false);
    history.push('/insurance-management');
  }

  function handleChangeLifeInsurance() {
    setLifeInsurance(!lifeInsurance);
  }

  function handleChangeHealthInsurance() {
    setHealthInsurance(!healthInsurance);
  }

  function handleChangeDentalInsurance() {
    setDentalInsurance(!dentalInsurance);
  }

  function addInsurance() {
    setOpenSnackbar(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Dados atualizados com sucesso.');
    setOpenAddInsuranceModal(false);
  }

  function alertForUnfiledFields() {
    setOpenSnackbar(true);
    setSnackbarSeverity('error');
    setSnackbarMessage('Existem campos não preenchidos');
  }

  function handleExpandedTab(name) {
    let auxArray = [...expandedTabs];
    const index = auxArray.indexOf(name);
    const findName = expandedTabs.find(item => item === name);

    if (findName) {
      auxArray.splice(index, 1);
      setExpandedTabs([...auxArray]);
    } else {
      setExpandedTabs([...expandedTabs, name]);
    }
  }

  function activeEditMode() {
    setExpandedTabs([...ALL_TABS_ARRAY]);
    setEditMode(!editMode);
  }

  return (
    <>
      <Header loading={loading} headerData={headerData} />

      <History
        setOpenAddInsuranceModal={setOpenAddInsuranceModal}
        chipData={chipData}
        setChipData={setChipData}
        insuranceHistory={insuranceHistory}
        loading={loading}
      />

      <EditData
        editMode={editMode}
        activeEditMode={activeEditMode}
        loading={loading}
        personalDataForm={personalDataForm}
        setPersonalDataForm={setPersonalDataForm}
        professionalDataForm={professionalDataForm}
        setProfessionalDataForm={setProfessionalDataForm}
        salary={salary}
        setSalary={setSalary}
        addressDataForm={addressDataForm}
        setAddressDataForm={setAddressDataForm}
        contactDataForm={contactDataForm}
        setContactDataForm={setContactDataForm}
        setOnSubmitForm={setOnSubmitForm}
        onSubmitForm={onSubmitForm}
        handleExpandedTab={handleExpandedTab}
        expandedTabs={expandedTabs}
        requestLoading={requestLoading}
        textFieldFocused={textFieldFocused}
        setTextFieldFocused={setTextFieldFocused}
        locations={locations}
        setLocations={setLocations}
        stockings={stockings}
        setStockings={setStockings}
        contracts={contracts}
        setContracts={setContracts}
      />

      <AddInsuranceModal
        open={openAddInsuranceModal}
        lifeInsurance={lifeInsurance}
        handleChangeLifeInsurance={handleChangeLifeInsurance}
        handleChangeHealthInsurance={handleChangeHealthInsurance}
        handleChangeDentalInsurance={handleChangeDentalInsurance}
        onCancel={() => setOpenAddInsuranceModal(false)}
      />

      <Snackbar
        open={openSnackbar}
        close={() => setOpenSnackbar(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />

      <BottomBar
        requestLoading={requestLoading}
        handleSubmit={handleSubmit}
        onBackClicked={onBackClicked}
        hidden={!editMode || !isMobile}
      />

      <ProgressToolBar
        onBackClicked={onBackClicked}
        onNextClicked={handleSubmit}
        nextButtonText="Salvar"
        backButtonText="Voltar"
        disableNext={!editMode}
        hidden={isMobile}
        loading={requestLoading}
      />
    </>
  );
}
