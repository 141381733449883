import React from 'react';

import {
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import styles from '../styles.module.css';

export default function InsurancesForm({
  insuranceTypes,
  loading,
  onChangeInsuranceType,
  currentInsuranceType,
}) {
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <FormControl
      variant="outlined"
      className={loading ? null : styles.formControl}
      disabled={loading}
      fullWidth
      size="small"
    >
      <InputLabel className={styles.labelStyle} id="select-insurance">
        Selecione um Seguro*
      </InputLabel>
      <Select
        labelId="select-insurance"
        label="Selecione um Seguro*"
        onChange={onChangeInsuranceType}
        value={currentInsuranceType}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {insuranceTypes.map(item => (
          <MenuItem key={item.code} value={item.enumCode}>
            {item.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
