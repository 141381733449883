import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Modals from '../../../../../components/Modals';
import CardTools from '../../../../../components/CardTools';
import { ChipStatus } from '../../../../../components/ChipStatus';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import Buttons from '../../../../../components/Buttons';

export const InsufficientFundsModal = NiceModal.create(
  ({ firstValue = 0, secondValue = 0, thirdValue = 0 }) => {
    const modal = useModal();

    const history = useHistory();

    const goToDeposits = () => {
      history.push('/cash-in');
      modal.hide();
    };

    return (
      <Modals.DefaultModal
        hasBottomBar={false}
        open={modal.visible}
        onClose={modal.hide}
        title="Saldo insuficiente"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <CardTools.CardInfo
                center
                title="Seu saldo"
                description={
                  <ChipStatus
                    variant="string"
                    status="secondary"
                    label={currencyFormatter(firstValue)}
                  />
                }
              />

              <ChipStatus
                variant="filled"
                status="secondary"
                size="small"
                label="-"
              />

              <CardTools.CardInfo
                center
                title="Tarifa"
                description={
                  <ChipStatus
                    variant="string"
                    status="secondary"
                    label={currencyFormatter(secondValue)}
                  />
                }
              />

              <ChipStatus
                variant="filled"
                status="secondary"
                size="small"
                label="="
              />

              <CardTools.CardInfo
                center
                title="Diferença"
                description={
                  <ChipStatus
                    variant="string"
                    status="secondary"
                    label={currencyFormatter(thirdValue)}
                  />
                }
              />
            </Box>

            <Alert severity="info">
              Deposite o valor pendente para conseguir realizar a liberação.
            </Alert>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '1rem',
            }}
          >
            <Buttons.SecondaryButton color="primary" title="Cancelar" />

            <Buttons.PrimaryButton
              color="secondary"
              title="Fazer depósito"
              onClick={goToDeposits}
            />
          </Box>
        </Box>
      </Modals.DefaultModal>
    );
  },
);
