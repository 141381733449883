import React, { useState } from 'react';
import moment from 'moment';

import {
  Button,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import styles from './styles.module.css';

import DatePicker from '../../../../../components/DatePicker';
import Drawer from './Drawer';

export default function FileList({
  openDrawer,
  setOpenDrawer,
  loadingTable
}) {
  const insuranceTypeStorage = sessionStorage.getItem('insuranceType');

  const [insuranceType, setInsuranceType] = useState(
    insuranceTypeStorage ? insuranceTypeStorage : 'Seguro de Vida'
  );
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  function onChangeInsuranceType(event) {
    setInsuranceType(event.target.value);
  }

  function onChangeInitialDate(value) {
    setInitialDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida' ?
      moment(value).format('YYYY-MM-DD') : ''
    )
  }

  function onChangeFinalDate(value) {
    setFinalDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida' ?
      moment(value).format('YYYY-MM-DD') : ''
    )
  }

  function handleFilter() {
    setOpenDrawer(false);
  }

  function renderContentDrawer() {
    return (
      <div className={styles.drawerContainer}>
        <div>
          <Typography 
            className={styles.drawerTitle}
          >
            Filtrar
          </Typography>

          <FormControl variant="outlined" className={styles.inputType}>
            <InputLabel id="outlined-type" className={styles.labelType}>
              Tipo
            </InputLabel>
            <Select
              labelId="outlined-type"
              id="outlined-type"
              value={insuranceType}
              onChange={onChangeInsuranceType}
              label="Tipo"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              fullWidth
            >
              <MenuItem value="Seguro de Vida">Vida</MenuItem>
              <MenuItem value="Seguro Saúde">Saúde</MenuItem>
              <MenuItem value="Seguro Odontológico">Odontológico</MenuItem>
            </Select>
          </FormControl>

          <Typography className={styles.drawerLabel}>
            Período
          </Typography>

          <FormControl className={styles.datePicker}>
            <DatePicker
              value={initialDate}
              onChange={onChangeInitialDate}
              label={initialDate ? 'Data inicial' : null}
              placeholder="Data inicial"
              
            />
          </FormControl>
            
          <FormControl>
            <DatePicker
              value={finalDate}
              onChange={onChangeFinalDate}
              label={finalDate ? 'Data final' : null}
              placeholder="Data final"
              className={styles.datePicker}
            />
          </FormControl>
        </div>

        <Button 
          className={styles.drawerButton}
          onClick={handleFilter}
        >
          Aplicar filtros
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardData}>        
          <p className={styles.cardName}>
            Identificador: 23456
          </p>
          <p className={styles.cardLabel}>
            Janeiro 2021
          </p>
          <p className={styles.cardLabel}>
            Saúde
          </p>
        </div>

        <div className={styles.cardArrow}>
          <IconButton 
            className={styles.iconButton}
          >
            <ArrowForwardIosRoundedIcon className={styles.arrowForward} />
          </IconButton>

          <div className={styles.insurancesCount}>
            15 segurados
          </div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.cardData}>        
          <p className={styles.cardName}>
            Identificador: 23456
          </p>
          <p className={styles.cardLabel}>
            Janeiro 2021
          </p>
          <p className={styles.cardLabel}>
            Saúde
          </p>
        </div>

        <div className={styles.cardArrow}>
          <IconButton 
            className={styles.iconButton}
          >
            <ArrowForwardIosRoundedIcon className={styles.arrowForward} />
          </IconButton>

          <div className={styles.insurancesCount}>
            15 segurados
          </div>
        </div>
      </div>

      <Drawer 
        openDrawer={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        content={renderContentDrawer()}
      />
    </>
  )
}
