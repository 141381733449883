import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';

import Form from '../../../../../components/Form';
import CashOutPixBottomBar from '../../components/BottomBar';
import { PixContext } from '../../components/Context';

import { CashOutActions as Actions } from '../../../../../redux/store/CashOut';

import validations from './validations';

export default function PixKeyTab() {
  const dispatch = useDispatch();

  const { onNext, isLoading, defaultValues, keyOptions } = useContext(
    PixContext,
  );

  const {
    control,
    getValues,
    resetField,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues,
  });

  const getMask = () => {
    if (getValues('keyType') === 'PHONE') {
      return '(00) 00000-0000';
    }

    if (getValues('keyType') === 'CPF_CNPJ') {
      return [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }];
    }

    return /^\S*@?\S*$/;
  };

  const [mask, setMask] = useState(getMask());

  const onSubmit = (form, event) => {
    event.preventDefault();
    onNext(form);
    dispatch(Actions.getDetailsPixKey(form));
  };

  return (
    <Form.FormBase id="pix-key-form" onSubmit={() => handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="keyType"
            render={({ field }) => (
              <Form.SelectField
                size="large"
                label="Opção de chave"
                options={keyOptions}
                error={Boolean(errors?.keyType)}
                helperText={errors?.keyType?.message}
                {...field}
                onChange={e => {
                  resetField('key', { defaultValue: '' });
                  field.onChange(e);
                  setMask(getMask());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="key"
            render={({ field }) => (
              <Form.MaskedField
                size="large"
                label="Chave*"
                error={Boolean(errors?.key)}
                helperText={errors?.key?.message}
                InputLabelProps={{ shrink: true }}
                mask={mask}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <Form.CurrencyField
                size="large"
                label="Valor do Pix*"
                error={Boolean(errors?.value)}
                helperText={errors?.value?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Form.TextField
                size="large"
                label="Descrição"
                error={Boolean(errors?.description)}
                helperText={errors?.description?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CashOutPixBottomBar disabled={!isValid} isLoading={isLoading} />
    </Form.FormBase>
  );
}
