import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AccessActions as Actions } from '../../../../../redux/store/AccessSettings';

import { SettingsContext } from '../../../contexts';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

import validations from '../validations';

const SUCCESS_MESSAGE =
  'Alterações salvas com sucesso! Em 48hs as alterações serão aplicadas.';

export default function useMinLimit() {
  const dispatch = useDispatch();
  const { error, success } = handleSnackbarStack();
  const { handleExpand, isAdmin } = useContext(SettingsContext);

  const [isReseted, setReset] = useState(false);

  const {
    isSending,
    currentConfig: { minAmountToNotify, minAmountToNotifyScheduled },
  } = useSelector(state => state.access);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: { minAmountToNotify },
    resolver: yupResolver(validations),
  });

  const onSubmit = async form => {
    const actionResult = await dispatch(Actions.setMinLimit(form));

    try {
      unwrapResult(actionResult);
      success(SUCCESS_MESSAGE);
      handleExpand(false)();
      reset();
    } catch (err) {
      error(err.message);
    }
  };

  useEffect(() => {
    reset({ minAmountToNotify }, { keepErrors: true });
    setReset(true);
  }, [minAmountToNotify]);

  useEffect(() => {
    isReseted && trigger();
    setReset(false);
  }, [trigger, isReseted]);

  return {
    isSending,
    control,
    errors,
    isValid: isDirty && isValid && isAdmin,
    onSubmit: () => handleSubmit(onSubmit),
    limit: minAmountToNotify,
    scheduled: minAmountToNotifyScheduled,
  };
}
