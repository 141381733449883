import React from 'react';
import moment from 'moment';

import { Box, Grid } from '@material-ui/core';

import styles from './styles.module.css';

import textCapitalize from '../../../utils/textCapitalize';

export default function Cards({ paycheckData }) {
  const { 
    competencyDate,
    installment,
    paymentType,
    paycheckCount,
    name,
    creationDate,
    creationUser,
    id
  } = paycheckData;

  const FORMATED_COMPETENCE = `
    ${textCapitalize(moment(competencyDate).locale('pt-br').format('MMM'))} de
    ${moment(competencyDate).locale('pt-br').format('YYYY')}
  `;

  return (
    <Grid 
      container 
      className={styles.detailsCard}
      direction="column"
    >
      <Grid item sm={12} md={12} lg={12}>
        <h3>
          Detalhes da importação
        </h3>
      </Grid>

      <Grid item>
        <Grid container spacing={5}>
          <Grid item className={styles.cardInfo}>
            <span>
              Identificador
            </span>
            <p>
              {id || '-'}
            </p>
          </Grid>

          <Grid item className={styles.cardInfo}>
            <span>
              Nome do arquivo
            </span>
            <p>
              {name || '-'}
            </p>
          </Grid>

          <Grid item className={styles.cardInfo}>
            <span>
              Parcela
            </span>
            <p>
              {installment || '-'}
            </p>
          </Grid>

          <Grid item className={styles.cardInfo}>
            <span>
              Tipo
            </span>
            <p>
              {paymentType || '-'}
            </p>
          </Grid>

          <Grid item className={styles.cardInfo}>
            <span>
              Competência
            </span>
            <p>
              {FORMATED_COMPETENCE || '-'}
            </p>
          </Grid>

          <Grid item className={styles.cardInfo}>
            <span>
              Funcionários
            </span>
            <p>
              {paycheckCount || '-'}
            </p>
          </Grid>
        </Grid>
      </Grid>
      

      <Grid item>
        <Box className={styles.cardInfo} mt={1}>
          <span>
            Criação
          </span>
          <p>
            {creationUser || '-'}
          </p>
          <span>
          {creationDate ? `${moment(creationDate).format('DD/MM/YYYY').substring(0, 10)} 
          às ${creationDate.substring(11, 19)}` : '-'}
          </span>
        </Box>
      </Grid>
    </Grid>
  )
}
