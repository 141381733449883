import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

import Buttons from '../../../../../components/Buttons';

import BeeValeImage from '../../../../../assets/bee-vale-popup.png';

const BeeValePopup = NiceModal.create(() => {
  const modal = useModal();

  return (
    <Dialog
      maxWidth="md"
      open={modal.visible}
      onClose={modal.remove}
      aria-label="Banner promocional do cartão de benefícios BeeVale"
      PaperProps={{
        style: {
          overflow: 'hidden',
          height: 'min(700px, 75%)',
        },
      }}
    >
      <a
        href="https://bit.ly/BeeVale"
        target="_blank"
        rel="noreferrer"
        style={{ height: '100%' }}
      >
        <img
          src={BeeValeImage}
          alt="Imagem promocional do cartão de benefícios BeeVale"
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      </a>
      <Buttons.IconButton
        icon={<CloseRounded />}
        onClick={modal.remove}
        tooltipTitle="Entendi"
        style={{
          color: 'white',
          position: 'absolute',
          top: '8px',
          right: '8px',
        }}
      />
    </Dialog>
  );
});

export default BeeValePopup;
