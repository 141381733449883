import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PeopleIcon from '@material-ui/icons/People';

import styles from './styles';

const isMobile = window.innerHeight <= 500 || window.innerWidth <= 500;

export default ({ newAccounts, boundAccounts }) => (
  <Grid container justify={isMobile ? 'space-evenly' : 'center'}>
    <Grid
      item
      xs={2}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Paper style={styles.centerText}>
        <Grid item xs>
          <CardGiftcardIcon style={styles.iconStyle} />
        </Grid>
        <Typography style={styles.textStyle}>{newAccounts}</Typography>
        <Grid item xs>
          <Typography style={styles.smallTextStyle}>
            Nova(s) Conta(s)
          </Typography>
        </Grid>
      </Paper>
    </Grid>
    <Grid
      item
      xs={2}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Paper style={styles.centerText}>
        <Grid item xs>
          <PeopleIcon style={styles.iconStyle} />
        </Grid>
        <Typography style={styles.textStyle}>{boundAccounts}</Typography>
        <Grid item xs>
          <Typography style={styles.smallTextStyle}>
            Conta(s) Vinculada(s)
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);
