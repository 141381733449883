/* eslint-disable no-useless-escape */
import * as yup from 'yup';

const MIN_LENGTH = 8;

const upperCaseRegex = new RegExp('.*[A-Z].*');
const lowerCaseRegex = new RegExp('.*[a-z].*');
const numberRegex = new RegExp('.*\\d.*');
const specialCharacterRegex = new RegExp(
  '.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*',
);

export const validationMessages = {
  required: 'Senha obrigatória',
  upperCase: 'Pelo menos 1 letra maiúscula',
  lowerCase: 'Pelo menos 1 letra minúscula',
  number: 'Pelo menos 1 número',
  special: 'Pelo menos 1 caractere especial',
  min: 'Mínimo de 8 caracteres',
};

export default yup.object().shape({
  password: yup
    .string()
    .min(MIN_LENGTH, validationMessages.min)
    .matches(specialCharacterRegex, validationMessages.special)
    .matches(upperCaseRegex, validationMessages.upperCase)
    .matches(lowerCaseRegex, validationMessages.lowerCase)
    .matches(numberRegex, validationMessages.number)
    .required(validationMessages.required),
  repeatPassword: yup
    .string()
    .min(MIN_LENGTH)
    .oneOf([yup.ref('password'), null], 'As senhas não correspondem')
    .required(),
});
