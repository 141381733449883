import React from 'react';
import { Box, Typography } from '@material-ui/core';

export function DefaultModalHeader({ title = '', subtitle = '' }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.75rem',
      }}
    >
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        style={{ color: '#545255', fontWeight: 'bold' }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}
