import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import formatBoleto from '@brazilian-utils/format-boleto';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, Hidden, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import currencyFormatter from '../../../utils/brazilianCurrencyFormatter';
import capitalize from '../../../utils/textCapitalize';

import { isMobile } from '../../../utils/breakpoints';
import style from './styles.module.css';

import { ReactComponent as SuccessIcon } from '../../../assets/successAnimation.svg';
import { ReactComponent as LinkNumberCodeBarIcon } from '../../../assets/LinkNumberCodeBarIcon.svg';
import brazilianCurrencyFormatter2 from '../../../utils/brazilianCurrencyFormatter2';
import convertBytesToBlobDowload from '../../../utils/convertBytesToBlobDowload';
import { api } from '../../../services/api';

export default () => {
  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const currentCompanyIndex = JSON.parse(
    sessionStorage.getItem('currentCompanyIndex'),
  );
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const companyName = companies[currentCompanyIndex]?.name
    ? companies[currentCompanyIndex].name
    : companies[0].name;
  const billetData = JSON.parse(sessionStorage.getItem('billetData'));
  const billetValue = JSON.parse(sessionStorage.getItem('billetValue'));

  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);

  const { bankTicket, pdfUrl, id } = billetData;

  const history = useHistory();

  const onBackClicked = () => history.push(`/cash-in`);

  const onCopy = () => setCopied(true);

  const handleClick = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleDowloadPdf = () => {
    api
      .get(`/cashIn/bankTickets/${currentCompanyCode}/${id}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const nameDocument = 'boleto';
        convertBytesToBlobDowload(data, nameDocument);
      });
  };

  return (
    <div className={style.container}>
      <Hidden xsDown>
        <SuccessIcon width={120} />
        <Typography className={style.title}>
          Boleto pronto para pagamento!
        </Typography>
        <Typography className={style.text}>
          Confira as informações, baixe o documento ou copie o código para
          pagar.
        </Typography>
      </Hidden>

      <div className={style.content}>
        <Hidden xsDown>
          <div className={style.contentHeader}>
            <Typography className={style.contentTitle}>
              Informações do Boleto
            </Typography>
            <Button
              className={style.buttonPdf}
              startIcon={<DescriptionIcon className={style.iconPdf} />}
              onClick={handleDowloadPdf}
            >
              Baixar PDF
            </Button>
          </div>
        </Hidden>

        <div>
          <Typography className={style.label}>Valor</Typography>
          <Typography className={style.descriptionValue}>
            {brazilianCurrencyFormatter2(billetValue)}
          </Typography>
        </div>

        <div className={style.detailsContainer}>
          <Hidden xsDown>
            <div className={style.details}>
              <Typography className={style.label}>Grupo Empresarial</Typography>
              <Typography className={style.description}>
                {capitalize(companyName)}
              </Typography>
            </div>
          </Hidden>

          <div className={style.details}>
            <Typography className={style.label}>Vencimento</Typography>
            <Typography className={style.description}>
              {moment()
                .add(30, 'day')
                .format('DD/MM/YYYY')}
            </Typography>
          </div>

          <div className={style.details}>
            {isMobile ? (
              <Typography className={style.compensationLabel}>
                Compensação em até 3 dias úteis.
              </Typography>
            ) : (
              <>
                <Typography className={style.label}>Compensação</Typography>
                <Typography className={style.description}>
                  Até 3 dias úteis
                </Typography>
              </>
            )}
          </div>
        </div>

        <div className={style.codeContainer}>
          <Hidden xsDown>
            <Typography className={style.contentTitle}>
              Pague com o código
            </Typography>
          </Hidden>
          <div className={style.barcode}>
            <Typography className={style.barcodeText}>
              {formatBoleto(bankTicket)}
            </Typography>
            <Hidden xsDown>
              <CopyToClipboard text={bankTicket} onCopy={onCopy}>
                <IconButton onClick={handleClick}>
                  <LinkNumberCodeBarIcon />
                </IconButton>
              </CopyToClipboard>
            </Hidden>
          </div>

          <Hidden smUp>
            <div
              className={style.copyButton}
              onClick={handleClick}
              onCopy={onCopy}
            >
              <CopyToClipboard text={bankTicket}>
                <IconButton>
                  <FileCopyOutlinedIcon className={style.copyCodeIcon} />
                </IconButton>
              </CopyToClipboard>
              <CopyToClipboard text={bankTicket}>
                <Typography className={style.copyCodeLabel}>
                  Copiar código
                </Typography>
              </CopyToClipboard>
            </div>
          </Hidden>
        </div>
      </div>

      <Hidden xsDown>
        <div className={style.footer}>
          <Button onClick={onBackClicked}>Voltar</Button>
        </div>
      </Hidden>

      <Hidden smUp>
        <div className={style.downloadContainerButton}>
          <Button
            className={style.downloadButton}
            onClick={() => window.open(pdfUrl, '_blank')}
          >
            Baixar PDF
          </Button>
          <Button className={style.shareButton}>Compartilhar boleto</Button>
        </div>
      </Hidden>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open && bankTicket}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Código copiado com sucesso"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};
