import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  CircularProgress,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import styles from './styles';
import CustomDatePicker from '../DatePicker';

export default ({
  open,
  onCloseDialog,
  title,
  text,
  confirmText,
  onConfirmClicked,
  cancelText,
  ignoreText,
  ignoreLink = false,
  onChangeDate,
  selectedAvailablePaymentHour,
  setSelectedAvailablePaymentHour,
  availablePaymentHours,
  circularLoading,
  errorMessage,
  date,
  paymentType,
  openModalSuccess,
  zeroProduct,
}) => (
  <Dialog
    open={open}
    onClose={onCloseDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      <Typography style={styles.title}>{title}</Typography>
    </DialogTitle>
    <DialogContent style={{ overflow: 'hidden' }}>
      <DialogContentText id="alert-dialog-description">
        <Typography style={styles.text}>{text}</Typography>
        <Typography style={styles.errroMessage}>{errorMessage}</Typography>
        {!openModalSuccess ? (
          <div>
            <InputLabel style={styles.inputLabelStyle}>
              Nova data e hora:
            </InputLabel>
            <Grid item container spacing={2} direction="row">
              <Grid item xs={6}>
                <CustomDatePicker
                  value={date}
                  onChange={onChangeDate}
                  minDate={
                    paymentType !== 'Rescisão' && !zeroProduct
                      ? moment().add(1, 'day')
                      : moment()
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.textFieldStyle}
                >
                  <InputLabel id="payment-hour-select-box-label">
                    Hora do pagamento*
                  </InputLabel>

                  <Select
                    fullWidth
                    labelId="payment-hour-select-box-label"
                    id="payment-hour-select-box"
                    label="Hora do Pagamento*"
                    value={selectedAvailablePaymentHour}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={({ target: { value } }) =>
                      setSelectedAvailablePaymentHour(value)
                    }
                  >
                    {moment(date).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD')
                      ? availablePaymentHours?.map(hour => {
                          if (
                            hour.value > moment().format('HH:00:00') &&
                            hour.value <= '21:00:00'
                          ) {
                            return (
                              <MenuItem
                                key={hour.description}
                                value={hour.value}
                              >
                                {hour.description}
                              </MenuItem>
                            );
                          }

                          return (
                            <MenuItem
                              disabled
                              key={hour.description}
                              value={hour.value}
                            >
                              {hour.description}
                            </MenuItem>
                          );
                        })
                      : availablePaymentHours?.map(hour => (
                          <MenuItem key={hour.description} value={hour.value}>
                            {hour.description}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCloseDialog}
        color="primary"
        autoFocus
        style={styles.cancelButtonStyle}
      >
        {cancelText}
      </Button>
      {ignoreLink ? (
        <Button onClick={onConfirmClicked} color="primary" autoFocus>
          {ignoreText}
        </Button>
      ) : (
        <Button
          onClick={onConfirmClicked}
          variant="contained"
          autoFocus
          style={
            errorMessage || !date || selectedAvailablePaymentHour === ''
              ? styles.disabledConfirmButtonStyle
              : styles.confirmButtonStyle
          }
          disabled={
            errorMessage || !date || selectedAvailablePaymentHour === ''
          }
        >
          {circularLoading ? (
            <CircularProgress style={styles.circularLoadingStyle} />
          ) : (
            confirmText
          )}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
