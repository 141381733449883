import React from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';

function PrimaryButton({
  outlined,
  disabled,
  title,
  rounded,
  onClick
}) {
  return (
    <Button
      outlined={outlined}
      disabled={disabled}
      onClick={onClick}
      className={
        outlined ? styles.buttonOutlined :
        rounded ? styles.buttonRounded :
        disabled ? styles.buttonDisabled :
        styles.button
      }
    >
      {title}
    </Button>
  );
}

export default PrimaryButton;
