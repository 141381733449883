import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from './UI/Header';
import Resume from './UI/Resume';
import Table from './UI/Table';
import UpgradeModal from './UI/UpgradeModal';

import ConfirmationModal from '../../components/PrimaryModal';
import SuccessModal from '../../components/SuccessModal';
import LogoLoading from '../../components/LogoLoading';
import ErrorModal from '../../components/PrimaryModal';

import styles from './styles.module.css';
import { isMobile } from '../../utils/breakpoints';
import { api } from '../../services/api';
import FakeRowTable from './UI/FakeRowTable';

import AccordionHistory from './UI/AccordionHistory';
import ExpiredModal from './UI/ExpiredModal';
import AccountDebitModal from './UI/AccountDebitModal';
import PlanSituation from './UI/PlanSituation';
import HirePlanModal from './UI/HirePlanModal';
import CompanyHoldBalanceReserveModal from './UI/CompanyHoldBalanceReserveModal';

function MyPlan() {
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataPlans, setDataPlans] = useState({});
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openExpiredModal, setOpenExpiredModal] = useState(false);
  const [openAccountDebitModal, setOpenAccountDebitModal] = useState(false);
  const [
    openSuccessRenewalPlanModal,
    setOpenSuccessRenewalPlanModal,
  ] = useState(false);
  const [openHirePlanModal, setOpenHirePlanModal] = useState(false);
  const [errorSignaturePayment, setErrorSignaturePayment] = useState(false);
  const [avaliableBalance, setAvaliableBalance] = useState(0);
  const [signatureAmount, setSignatureAmount] = useState(0);
  const [sufficientBalance, setSufficientBalance] = useState(false);
  const [total, setTotal] = useState(0);
  const [loadingValues, setLoadingValues] = useState(false);
  const [mainInformations, setMainInformations] = useState({});
  const [loadingInformations, setLoadingInformations] = useState(true);
  const [openModalReserved, setOpenModalReserved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const companyCodeValueReducer = useSelector(
    state => state.currentCompanyReducer.company,
  );
  const { accountSelected } = useSelector(state => state.account);

  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCodeValue = companyCodeValueStorage || companyCodeValueReducer;

  const {
    firstSignature,
    expired,
    zeroPlan,
    emptyPlan,
    chargingActive,
  } = dataPlans;
  const finalCurrencyDate = dataPlans?.history?.content[0]?.finalCurrencyDate;
  const { planHistory } = dataPlans;

  const history = useHistory();

  const handleCancelPlan = () => {
    setOpenModal(false);
    setOpenConfirmationModal(true);
  };

  const handleConfirmCancelPlan = () => {
    setLoadingButton(true);

    api
      .post(`/signature/cancel/${companyCodeValue}`)
      .then(() => {
        setOpenConfirmationModal(false);
        setOpenSuccessModal(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => errors,
      )
      .finally(() => {
        setLoadingButton(false);
      });
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  useEffect(() => {
    getPlanInformations();
  }, []);

  useEffect(() => {
    getPlanInformations();
  }, [currentPage, currentPageSize, totalSize, companyCodeValue, rowsPerPage]);

  useEffect(() => {
    if (expired === true && !openModalReserved) {
      setOpenExpiredModal(true);
      getValues();
    }
  }, [expired, openModalReserved]);

  useEffect(() => {
    getValues();
  }, []);

  const getPlanInformations = () => {
    setLoading(true);
    setLoadingData(true);

    const requestOptions = {
      params: {
        page: currentPage,
        size: currentPageSize,
        totalSize,
      },
    };

    api
      .get(`consumes/${companyCodeValue}`, requestOptions)
      .then(({ data }) => {
        const { reserved } = data;
        if (reserved && !openModalReserved) {
          setOpenModalReserved(reserved);
        }

        setDataPlans(data);
        setCurrentPage(data?.history?.currentPage);
        setCurrentPageSize(data?.history?.currentPageSize);
        setTotalSize(planHistory?.totalSize);
      })
      .finally(() => {
        setLoading(false);
        setLoadingData(false);
      });
  };

  function getValues() {
    setLoadingValues(true);

    const requestOptions = {
      avaliableBalance,
      signatureAmount,
      sufficientBalance,
      total,
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .get(`signature/balance/${companyCodeValue}`, requestOptions)
      .then(({ data }) => {
        setAvaliableBalance(data.avaliableBalance);
        setSignatureAmount(data.signatureAmount);
        setSufficientBalance(data.sufficientBalance);
        setTotal(data.total);
      })
      .finally(() => setLoadingValues(false));
  }

  function handleOpenAccountDebitModal() {
    setOpenExpiredModal(false);
    setOpenAccountDebitModal(true);
    getValues();
  }

  function handleOpenInactiveDebitPaymentModal() {
    setOpenAccountDebitModal(true);
  }

  function confirmDebitPaymentDebitThen() {
    setOpenAccountDebitModal(false);
    setOpenSuccessRenewalPlanModal(true);
  }

  function signaturePayment() {
    setLoadingButton(true);
    const requestBody = {
      paymentType: 1,
    };

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .post(
        `/signature/payment/${companyCodeValue}`,
        requestBody,
        requestOptions,
      )
      .then(confirmDebitPaymentDebitThen)
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setOpenAccountDebitModal(false);
          signaturePaymentCatch(errors);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function signaturePaymentCatch(errors) {
    setErrorMessage(errors[0]);
    setErrorSignaturePayment(true);
  }

  function handleGotODeposits() {
    setOpenAccountDebitModal(false);
    history.push('/cash-in');
  }

  function handleGoToPayments() {
    history.push('/payroll');
  }

  function onHireClicked() {
    setOpenHirePlanModal(false);
  }

  useEffect(() => {
    getMainInformations();
  }, [openModal]);

  function getMainInformations() {
    api
      .get(`/signature/info/${companyCodeValue}`)
      .then(({ data }) => {
        setMainInformations(data);
      })
      .finally(() => setLoadingInformations(false));
  }

  useEffect(() => {
    if (!openAccountDebitModal) getPlanInformations();
  }, [openAccountDebitModal]);

  function renderTable() {
    return (
      <Table
        dataPlans={dataPlans}
        totalSize={setTotalSize}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        loadingData={loadingData}
        setLoadingData={setLoadingData}
        page={page}
        setPage={setPage}
        stableSort={stableSort}
        getComparator={getComparator}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        expired={expired}
        emptyPlan={emptyPlan}
      />
    );
  }

  function renderPlanSituation() {
    return (
      <PlanSituation
        onConfirmButtonClicked={
          zeroPlan ? handleGoToPayments : handleOpenInactiveDebitPaymentModal
        }
        zeroPlan={zeroPlan}
        emptyPlan={emptyPlan}
        chargingActive={chargingActive}
      />
    );
  }

  function handleCancelModalReserved() {
    setOpenModalReserved(false);
  }

  function handleConfirmModalReserved() {
    if (sufficientBalance) {
      signaturePayment();
    } else {
      handleGotODeposits();
    }
    setOpenModalReserved(false);
  }
  return (
    <>
      {loading ? (
        <LogoLoading />
      ) : (
        <>
          <Header setOpenModal={setOpenModal} chargingActive={chargingActive} />
          <Resume
            dataPlans={dataPlans}
            loadingData={loadingData}
            expired={expired}
            handleOpenInactiveDebitPaymentModal={
              handleOpenInactiveDebitPaymentModal
            }
            handleGoToPayments={handleGoToPayments}
            zeroPlan={zeroPlan}
            mainInformations={mainInformations}
            openModal={openModal}
            setOpenModal={setOpenModal}
            setOpenHirePlanModal={setOpenHirePlanModal}
            emptyPlan={emptyPlan}
            finalCurrencyDate={finalCurrencyDate}
          />

          {((!firstSignature && !isMobile) || firstSignature) && (
            <div className={styles.divider} />
          )}

          {isMobile ? (
            <>
              {!firstSignature && (
                <>
                  <p className={styles.textBiggerPlan}>
                    Histórico de pagamento do plano
                  </p>
                  <AccordionHistory dataPlans={dataPlans} />
                </>
              )}
            </>
          ) : loadingData ? (
            <FakeRowTable />
          ) : (
            <>{firstSignature ? renderPlanSituation() : renderTable()}</>
          )}
          <UpgradeModal
            open={openModal}
            onCloseDialog={() => setOpenModal(false)}
            onCancel={handleCancelPlan}
            mainInformations={mainInformations}
            loading={loadingInformations}
            chargingActive={chargingActive}
            finalCurrencyDate={finalCurrencyDate}
          />
          <HirePlanModal
            open={openHirePlanModal}
            onCloseDialog={() => setOpenHirePlanModal(false)}
            onHireClicked={onHireClicked}
          />
          <ConfirmationModal
            open={openConfirmationModal}
            onCloseDialog={() => setOpenConfirmationModal(false)}
            title="Tem certeza que quer solicitar o cancelamento do seu plano?"
            cancelButtonText="Não"
            confirmButtonText="Sim, solicitar"
            onCancelButtonClicked={() => setOpenConfirmationModal(false)}
            onConfirmClicked={handleConfirmCancelPlan}
            loading={loadingButton}
          />
          <SuccessModal
            open={openSuccessModal}
            title="Solicitação enviada com sucesso!"
            text="Em breve, um dos nossos Gerentes de conta entrará em contato
            para concluir o cancelamento."
            closeButtonText="Fechar"
            onCloseDialog={() => setOpenSuccessModal(false)}
          />
          <ExpiredModal
            open={openExpiredModal}
            onClose={() => setOpenExpiredModal(false)}
            onCancelButtonClicked={() => setOpenExpiredModal(false)}
            title="Sua assinatura venceu"
            text="Para renovar seu plano, é necessário que você realize o pagamento da assinatura."
            cancelButtonText="Cancelar"
            confirmButtonText={isMobile ? 'Renovar' : 'Renovar agora'}
            onConfirmButtonClicked={handleOpenAccountDebitModal}
          />
          <CompanyHoldBalanceReserveModal
            open={openModalReserved}
            onCancelButtonClicked={handleCancelModalReserved}
            onConfirmButtonClicked={handleConfirmModalReserved}
          />
          <AccountDebitModal
            open={openAccountDebitModal}
            onClose={() => setOpenAccountDebitModal(false)}
            title={
              sufficientBalance
                ? 'Pagar via débito em conta'
                : 'Saldo insuficiente'
            }
            cancelButtonText="CANCELAR"
            onCancelButtonClicked={() => setOpenAccountDebitModal(false)}
            onConfirmButtonClicked={
              sufficientBalance ? signaturePayment : handleGotODeposits
            }
            loading={loadingButton}
            getValues={getValues}
            avaliableBalance={avaliableBalance}
            signatureAmount={signatureAmount}
            sufficientBalance={sufficientBalance}
            total={total}
            loadingValues={loadingValues}
          />
          <SuccessModal
            open={openSuccessRenewalPlanModal}
            onClose={() => setOpenSuccessRenewalPlanModal(false)}
            title={
              firstSignature
                ? 'Seu plano foi ativado com sucesso!'
                : 'Seu plano foi renovado com sucesso!'
            }
            closeButtonText="Fechar"
            onCloseDialog={() => {
              setOpenSuccessRenewalPlanModal(false);
              getPlanInformations();
            }}
          />
          <ErrorModal
            open={errorSignaturePayment}
            onConfirmClicked={() => setErrorSignaturePayment(false)}
            title={errorMessage?.errorDetail}
            confirmButtonText="Entendi"
            text={errorMessage?.errorsDescription}
          />
        </>
      )}
    </>
  );
}

export default MyPlan;
