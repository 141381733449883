import React from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export function Loading({ config = [], rowsPerPage = 5 }) {
  const skeletonList = [];

  for (let i = 0; i < rowsPerPage; i += 1) {
    skeletonList.push(
      <TableRow key={String(i)}>
        {config
          ?.filter(value => value?.type === 'field')
          ?.map((_, index) => (
            <TableCell key={String(i).concat(index)} align="left">
              <Skeleton
                style={{ height: '24px', margin: '12px 0 12px 0' }}
                animation="wave"
                variant="text"
              />
            </TableCell>
          ))}

        {config?.some(value => value?.type === 'action') && (
          <TableCell key={`actions${i}`} align="left">
            <Skeleton
              style={{ height: '24px', margin: '12px 0 12px 0' }}
              animation="wave"
              variant="text"
            />
          </TableCell>
        )}
      </TableRow>,
    );
  }

  return <>{skeletonList}</>;
}
