import React from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import styles from './styles.module.css';

export default function StatusColor({ status }) {
  switch(status) {
    case 'Vigente':
      return (
        <div className={styles.blueBadge}>
          <CheckCircleRoundedIcon className={styles.icon} />
          Vigente
        </div>
      )

    case 'Em cancelamento':
      return (
        <div className={styles.yellowBadge}>
          <QueryBuilderRoundedIcon className={styles.icon} />
          Em cancelamento
        </div>
      )

    case 'Cancelado':
      return (
        <div className={styles.redBadge}>
          <HighlightOffRoundedIcon className={styles.icon} />
          Cancelado
        </div>
      )
      // no default
  }
}
