import React, { useCallback } from 'react';
import { Fab, Typography } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';

import { Header } from '../../components/Header';
import Buttons from '../../components/Buttons';
import PixKeyItem from './components/PixKeyItem';
import PixKeysEmptyState from './components/EmptyState';
import PixKeyListSkeleton from './components/ListSkeleton';
import PixKeysModals from './components/Modals';

import { isMobile, isTablet } from '../../utils/breakpoints';
import usePixKeys from './hooks/usePixKeys';

import { useStyles } from './styles';

export default function PixKeysPage() {
  const styles = useStyles();

  const {
    data,
    isFetching,
    isDisabled,
    onDeleteKey,
    goToNewKeyPage,
  } = usePixKeys();

  const renderButton = useCallback(() => {
    return isMobile || isTablet ? (
      <Fab
        color="primary"
        aria-label="Nova chave Pix"
        className={styles.fab}
        onClick={goToNewKeyPage}
        disabled={isDisabled}
      >
        <AddRounded />
      </Fab>
    ) : (
      <Buttons.PrimaryButton
        className={styles.button}
        color="primary"
        title="Nova chave Pix"
        onClick={goToNewKeyPage}
        icon={<AddRounded />}
        disabled={isDisabled}
      />
    );
  }, [isMobile, isDisabled, isTablet]);

  const renderKeysSubtitle =
    data?.length > 0 ? (
      <>
        <strong>{data?.length}</strong> de 20 chaves cadastradas
      </>
    ) : null;

  const renderKeyList = () => {
    return data?.length === 0 ? (
      <PixKeysEmptyState goToNewKeyPage={goToNewKeyPage} />
    ) : (
      <ol className={styles.list}>
        {data?.map(entry => (
          <PixKeyItem entry={entry} key={entry?.key} onDelete={onDeleteKey} />
        ))}
      </ol>
    );
  };

  return (
    <>
      <Header title="Minhas chaves Pix" buttons={renderButton} />
      <Typography variant="h2" className={styles.subtitle}>
        {renderKeysSubtitle}
      </Typography>

      {isFetching ? <PixKeyListSkeleton /> : renderKeyList()}

      <PixKeysModals />
    </>
  );
}
