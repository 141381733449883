import React from 'react';
import { Box, Typography } from '@material-ui/core';

import ResultTopic from './ResultTopic';

import useAcademy from '../../hooks/useAcademy';

import { useStyles } from '../../styles';

export default function AcademySearchResults() {
  const styles = useStyles();

  const { searchResults } = useAcademy();

  return (
    <Box className={styles.root}>
      <Typography variant="h2" className={styles.title}>
        Resultados da pesquisa
      </Typography>
      {searchResults.length > 0 &&
        searchResults.map(result => (
          <ResultTopic key={result.topic} result={result} />
        ))}
    </Box>
  );
}
