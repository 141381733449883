import React from 'react';

import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import currencyFormatter from '../../../../../../utils/currencyFormatter';

import { ReactComponent as EditButton } from '../../../../../../assets/edit-button.svg';

import styles from '../styles.module.css';

export default ({ values, pageSize, currentPage, editEmployeeLimit }) => {
  return (
    <TableBody>
      {values.slice(0, pageSize).map(row => (
        <TableRow hover>
          <TableCell className={styles.cpfCell}>{row.cpf}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell className={styles.statusCell}>{row.status}</TableCell>
          <TableCell>
            {row.grantedValue !== 0
              ? currencyFormatter(row.grantedValue)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            {row.usedValue !== ''
              ? currencyFormatter(row.usedValue)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            {row.limitBalance !== 0
              ? currencyFormatter(row.limitBalance)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() =>
                editEmployeeLimit({
                  status: row.status,
                  name: row.name,
                  cpf: row.cpf,
                  actualLimit: row.grantedValue,
                })
              }
            >
              <Tooltip title="Visualizar detalhes">
                <EditButton />
              </Tooltip>
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
