export default {
  GENERAL_VISION: 0,
  CASH_IN: 1.1,
  FINANCES: 1.2,
  EXTRACT: 1.3,
  BANK_ACCOUNTS: 1.4,
  PIX: 1.5,
  EMPLOYEES: 2.1,
  PAYCHECK: 2.2,
  ANTICIPATION: 2.3,
  TED_VISION: 3,
  COAST_CENTER_AND_SECTOR: 3.1,
  MANAGE_USERS: 3.2,
  MY_PLAN: 3.3,
  SOMAPAY_ACADEMY: 3.4,
  MANUAL_PAYROLL: 6,
  FILE_PAYROLL: 7,
  INSURANCE: 10,
  ONLINE_POINT_EMPLOYEES: 11.1,
  ONLINE_POINT_PLAN: 11.2,
};
