import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useSearchPendingEmployeesQuery } from '../../../../../../../redux/store/Employees/services/pendingEmployees.api';

export function usePendingEmployees() {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [nameOrCpf, setNameOrCpf] = useState('');

  const companyCode = useSelector(state => state.companies.currentCompany.code);

  const {
    data,
    isFetching,
    isLoading,
    isError,
  } = useSearchPendingEmployeesQuery({
    params: {
      page,
      size,
    },
    filter: {
      nameOrCpf,
    },
    companyCode,
  });

  const handleFiltersChange = data => {
    setNameOrCpf(data.nameOrCpf);
  };

  const handlePagination = (currentPage, currentSize) => {
    setPage(currentPage);
    setSize(currentSize);
  };

  return {
    filter: {
      defaultValues: { nameOrCpf },
      handleRequests: handleFiltersChange,
    },
    table: {
      data: data?.content,
      length: data?.totalSize,
      isLoading: isLoading || isFetching,
      hasError: isError,
      requests: handlePagination,
    },
  };
}
