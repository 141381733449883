import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

import formatCPF from '../../../../utils/formatCPF';
import styles from './styles';

export default ({ employeeList, label, error = false }) => (

  <ExpansionPanel style={error ? styles.errorStyle : styles.successStyle}>
    <ExpansionPanelSummary
      expandIcon={<ExpandMore />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <FormControlLabel
        control={
          error ? (
            <ErrorIcon style={styles.errorIconStyle} />
          ) : (
            <CheckCircleIcon style={styles.sucessIconStyle} />
          )
        }
        label={label}
      />
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      {error ? (
        <List component="div" disablePadding>
          {employeeList.map(element => {
            return (
              <ListItem alignItems="flex-start" style={styles.listItemStyle}>
                <ListItemText
                  primary={error ? `Linha ${element.line}` : element.name}
                />
                  {element.errors.map(lineError => (
                      <ListItemText primary={` - ${lineError}`} />
                  ))}
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Paper style={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CPF</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Matrícula</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Data de admissão</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeList.map(row => (
                <TableRow key={row.line}>
                  <TableCell scope="row">{formatCPF(row.cpf)}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.registration}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{moment(row.admissionDate).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </ExpansionPanelDetails>
  </ExpansionPanel>
);
