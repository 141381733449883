import React from 'react';

import { Box, Collapse, Grid } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import styles from './styles.module.css';

export function RenderAuthorizerDetailsCollapse({ checked }) {
  return (
    <Box
      sx={{
        '& > :not(style)': {
          display: 'flex',
          justifyContent: 'space-around',
        },
      }}
    >
      <Collapse in={checked}>
        <Grid item className={styles.containerDetails}>
          <p className={styles.text}>• Folha de pagamento:</p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>◦ Autorizar pagamento de folha;</p>
            <p className={styles.fadedText}>◦ Liberação imediata (expresso);</p>
          </div>
        </Grid>
      </Collapse>
    </Box>
  );
}

export function RenderPersonalDepartmentDetailsCollapse({
  checkedPersonalDepartment,
}) {
  return (
    <Box
      sx={{
        '& > :not(style)': {
          display: 'flex',
          justifyContent: 'space-around',
        },
      }}
    >
      <Collapse in={checkedPersonalDepartment}>
        <Grid item className={styles.containerDetails}>
          <p className={styles.text}>• Folha de pagamento:</p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>◦ Gestão da folha de pagamento:</p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Alterar data;
            </p>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Desfazer agendamento:
            </p>
            <p className={styles.fadedText}>
              <CheckBoxOutlineBlankIcon className={styles.icon} />
              Aguardando autorização;
            </p>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Visualizar folha de pagamento;
            </p>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Baixar comprovante de pagamento;
            </p>
          </div>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              ◦ Cadastro/agendamento da folha de pagamento;
            </p>
          </div>
          <p className={styles.text}>• Dashboard:</p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>◦ Atalhos:</p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Pagamento Manual;
            </p>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Pagamento via Planilha;
            </p>
          </div>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>◦ Área de apoio;</p>
          </div>
          <p className={styles.text}>• Gerenciar funcionários:</p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>◦ Cadastro de funcionários;</p>
            <p className={styles.fadedText}>
              ◦ Visualizar cadastro de funcionário;
            </p>
            <p className={styles.fadedText}>
              ◦ Alterar cadastro de funcionário;
            </p>
            <p className={styles.fadedText}>◦ Dashboard:</p>

            <p className={styles.textMargin}>
              <StopIcon className={styles.icon} />
              Atalhos:
            </p>
            <div className={styles.containerShortcut}>
              <p className={styles.fadedText}>
                <CheckBoxOutlineBlankIcon className={styles.icon} />
                Cadastro em lote;
              </p>
              <p className={styles.fadedText}>
                <CheckBoxOutlineBlankIcon className={styles.icon} />
                Cadastro individual;
              </p>
            </div>
            <p className={styles.textMargin}>
              <StopIcon className={styles.icon} />
              Área de apoio;
            </p>
          </div>
        </Grid>
      </Collapse>
    </Box>
  );
}

export function RenderFinancialCollapse({ financialChecked }) {
  return (
    <Box
      sx={{
        '& > :not(style)': {
          display: 'flex',
          justifyContent: 'space-around',
        },
      }}
    >
      <Collapse in={financialChecked}>
        <Grid item className={styles.containerDetails}>
          <p className={styles.text}>
            <FiberManualRecordIcon className={styles.icon} />
            Folha de pagamento:
          </p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Gerenciar folhas de pagamento:
            </p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Visualizar folha de pagamento;
            </p>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Baixar comprovante de pagamento;
            </p>
          </div>
          <p className={styles.text}>
            <FiberManualRecordIcon className={styles.icon} />
            Depósito (cash in);
          </p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Gerenciar depósitos;
            </p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Via TED:
            </p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.fadedText}>
              <CheckBoxOutlineBlankIcon className={styles.icon} />
              Registrar informe de depósito;
            </p>
            <p className={styles.fadedText}>
              <CheckBoxOutlineBlankIcon className={styles.icon} />
              Visualizar detalhes;
            </p>
            <p className={styles.fadedText}>
              <CheckBoxOutlineBlankIcon className={styles.icon} />
              Editar transferência;
            </p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Via boleto:
            </p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.fadedText}>
              <CheckBoxOutlineBlankIcon className={styles.icon} />
              Gerar boleto;
            </p>
            <p className={styles.fadedText}>
              <CheckBoxOutlineBlankIcon className={styles.icon} />
              Baixar boleto “2ª via”;
            </p>
          </div>
          <p className={styles.text}>
            <FiberManualRecordIcon className={styles.icon} />
            Gerenciar contas:
          </p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Cadastrar nova conta;
            </p>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Editar conta;
            </p>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Excluir conta;
            </p>
          </div>
          <p className={styles.text} style={{ marginBottom: '8px' }}>
            <FiberManualRecordIcon className={styles.icon} />
            Extrato da conta;
          </p>
          <p className={styles.text} style={{ marginBottom: '8px' }}>
            <FiberManualRecordIcon className={styles.icon} />
            Saldos da empresa;
          </p>
          <p className={styles.text} style={{ marginBottom: '8px' }}>
            <FiberManualRecordIcon className={styles.icon} />
            Empresas e filiais:
          </p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Cadastrar novo Centro de Custo e Setor;
            </p>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Editar Centro de Custo e Setor;
            </p>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Excluir Centro de Custo e Setor;
            </p>
          </div>
          <p className={styles.text} style={{ marginBottom: '8px' }}>
            <FiberManualRecordIcon className={styles.icon} />
            Dashboard:
          </p>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Atalhos:
            </p>
          </div>
          <div className={styles.containerDetailsSpaced}>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Cadastro de conta;
            </p>
            <p className={styles.text}>
              <StopIcon className={styles.icon} />
              Registro de transferência;
            </p>
          </div>
          <div className={styles.containerDetailsFaded}>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Área de apoio;
            </p>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Saldos;
            </p>
            <p className={styles.fadedText}>
              <FiberManualRecordOutlinedIcon className={styles.icon} />
              Extrato;
            </p>
          </div>
        </Grid>
      </Collapse>
    </Box>
  );
}
