import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';

import styles from './styles.module.css';

export default function Balances({
  label,
  alternativeLabel,
  value,
  alternativeValue,
  isVisible,
  isLoading,
  onClick,
}) {

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={styles.balancesContainer}
    >
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography className={styles.label}>{label}</Typography>
          </Grid>

          <Grid item>
            {isLoading ? (
              <Skeleton
                height={18}
                width={105}
                color="#202020"
                highlightColor="#444"
                className={styles.marginSkeletons}
              />
            ) : isVisible ? (
              <Typography className={styles.value}>{value}</Typography>
            ) : (
              <div className={styles.block} />
            )}
          </Grid>
        </Grid>

        {alternativeValue && (
          <>
            <Box mt={1} />
            <Grid container direction="column">
              <Grid item>
                <Typography className={styles.alternativeLabel}>
                  {alternativeLabel}
                </Typography>
              </Grid>

              <Grid item>
                {isLoading ? (
                  <Skeleton
                    height={14}
                    width={105}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.marginSkeletons}
                  />
                ) : isVisible ? (
                  <Typography className={styles.alternativeValue}>
                    {alternativeValue}
                  </Typography>
                ) : (
                  <div className={styles.alternativeBlock} />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid item>
        <IconButton onClick={onClick}>
          {isVisible ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
        </IconButton>
      </Grid>
    </Grid>
  );
}
