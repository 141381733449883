import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Typography, Grid } from '@material-ui/core';

import Form from '../../../../../components/Form';
import CashOutTransferBottomBar from '../BottomBar';

import formatCnpj from '../../../../../utils/formatCnpj';
import currencyFormatter from '../../../../../utils/currencyFormatter';

import { useStyles } from './styles';

export default function ReviewStep({ isLoading, onBack, onNext }) {
  const styles = useStyles();

  const { currentTransfer, form } = useSelector(state => state.cashOut);

  const { handleSubmit } = useForm();

  const onSubmit = (_, event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <Form.FormBase onSubmit={() => handleSubmit(onSubmit)}>
      <Box>
        <Typography variant="h2" className={styles.title}>
          As informações abaixo estão corretas?
        </Typography>
        <Grid container className={styles.container}>
          <Grid item xs={3}>
            <Typography variant="label" className={styles.label}>
              Nome da empresa
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {currentTransfer?.companyName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="label" className={styles.label}>
              CNPJ
            </Typography>
            <Typography variant="body2" className={styles.keyText}>
              {formatCnpj(currentTransfer?.cnpj)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="label" className={styles.label}>
              Conta Somapay
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {currentTransfer?.account}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="label" className={styles.label}>
              Valor
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {currencyFormatter(form.amount)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="label" className={styles.label}>
              Descrição
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {form?.description || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <CashOutTransferBottomBar isFinal onBack={onBack} isLoading={isLoading} />
    </Form.FormBase>
  );
}
