import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from '../styles.module.css';
import {
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

function FakeTable() {
  return (
    <TableBody>
      <TableRow
        hover
        className={styles.tableRow}
      >
        <TableCell className={styles.tableCell}>
          <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell>
          <Skeleton circle={true} height={30} width={30} highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow
        hover
        className={styles.tableRow}
      >
        <TableCell className={styles.tableCell}>
          <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell>
          <Skeleton circle={true} height={30} width={30} highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow
        hover
        className={styles.tableRow}
      >
        <TableCell className={styles.tableCell}>
          <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell>
          <Skeleton circle={true} height={30} width={30} highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow
        hover
        className={styles.tableRow}
      >
        <TableCell className={styles.tableCell}>
          <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell>
          <Skeleton circle={true} height={30} width={30} highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow
        hover
        className={styles.tableRow}
      >
        <TableCell className={styles.tableCell}>
          <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell className={styles.tableCell}>
            <Skeleton height={25} width={120} highlightColor="#444" />
        </TableCell>
        <TableCell>
          <Skeleton circle={true} height={30} width={30} highlightColor="#444" />
        </TableCell>
      </TableRow>
    </TableBody>
  );

}

export default FakeTable;
