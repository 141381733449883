import React from 'react';

import MobileListCard from '../../../components/MobileListCard';

import ReceiptIcon from '@material-ui/icons/Receipt';

import styles from './styles.module.css';

import formatCPF from '../../../utils/formatCPF';
import textCapitalize from '../../../utils/textCapitalize';

export default function MobileList({ employees }) {
  function paycheckDownload(url) {
    window.open(url);
  };

  return (
    <>
      {employees?.map(item => (
        <MobileListCard 
          title={`Matrícula: ${item.employeeRegistration}`}
          text={textCapitalize(item.employeeName)}
          description={formatCPF(item.cpf)}
          alignCenter
          icon={<ReceiptIcon className={styles.iconReceipt} />}
          onClickIcon={() =>paycheckDownload(item.paycheckFile)}
        />
      ))}
    </>
  )
}
