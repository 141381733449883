import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import styles from './styles.module.css';

export default function FormHeader({
  title,
  alternativeTitle,
  firstButtonText,
  secondButtonText,
  onClickFirstButton,
  onClickSecondButton,
  disabledFirstButton,
  disabledSecondButton,
  hiddenButtons,
  hidden,
}) {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={hidden && styles.hidden}
    >
      <Grid item>
        <Typography className={styles.title}>{title}</Typography>

        {alternativeTitle && (
          <Grid container alignItems="center">
            <Grid item>
              <ErrorRoundedIcon className={styles.alternativeTitleIcon} />
            </Grid>

            <Grid item>
              <Typography className={styles.alternativeTitle}>
                {alternativeTitle}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {!hiddenButtons && (
        <Grid item>
          <Grid container>
            {firstButtonText && (
              <Grid item>
                <Button
                  className={styles.button}
                  onClick={onClickFirstButton}
                  disabled={disabledFirstButton}
                >
                  <EditOutlinedIcon className={styles.icon} />
                  {firstButtonText}
                </Button>
              </Grid>
            )}

            {secondButtonText && (
              <Grid item>
                <Button
                  className={styles.button}
                  onClick={onClickSecondButton}
                  disabled={disabledSecondButton}
                >
                  <EditOutlinedIcon className={styles.icon} />
                  {secondButtonText}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
