import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import Header from './UI/Header';
import Details from './UI/Details';
import Table from './UI/Table';
import { api } from '../../../services/api';
import ViewError from '../../../components/PageError';
import LogoLoading from '../../../components/LogoLoading';
import { useHistory } from 'react-router-dom';
import ProgressToolBar from '../../../components/ProgressToolBar';

function ViewDetails() {
  const [loadingData, setLoadingData] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => getInsuranceData(), []);

  const history = useHistory();

  const onBackClicked = () => {
    history.push('/insurance-management');
  };

  const getInsuranceData = () => {
    api.get('https://run.mocky.io/v3/556e8098-11c5-4a03-a46c-11b125f55021')
    .then(({ data }) => setTableData(data))
    .catch(() => setError(true))
    .finally(() => setLoadingData(false));
  }

  return (
    <div>
      {
        !loadingData ? (
          error ? (
            <ViewError
              title="Oops! Não conseguimos exibir as informações. "
              description="Em caso de dúvidas, entre em contato com nossa Central de Relacionamento."
              route="/insurance-management"
            />
          ) : (
            <div>
              <Header />
              <Details />
              <Table
                loadingData={loadingData}
                tableData={tableData}
              />
              <ProgressToolBar
                backButtonText="Voltar"
                disableNext
                onBackClicked={onBackClicked}
              />
            </div>
          )
        ) : (
          <div className={styles.logoJumping}><LogoLoading /></div>
        )
      }
    </div>
  );
}

export default ViewDetails;
