import { Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export const PrimaryButtonStyled = styled(Button)(({ theme, color }) => ({
  textTransform: 'none',
  borderRadius: '6px',
  ':hover': {
    backgroundColor:
      color === 'primary'
        ? theme.palette.primary.hover
        : theme.palette.secondary.hover,
  },
}));
