import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';
import { AlreadyUploadRegisterFile } from './components/AlreadyUploadRegisterFile';
import { HandleEmployeeBankData } from './components/HandleEmployeeBankData';
import { EmployeesSituationInfoDrawer } from './components/EmployeesSituationInfoDrawer';

export function EmployeesListModals() {
  return (
    <>
      <ModalDef
        id="already-upload-register-file"
        component={AlreadyUploadRegisterFile}
      />
      <ModalDef
        id="handle-employee-bank-data"
        component={HandleEmployeeBankData}
      />
      <ModalDef
        id="employee-situation-info-drawer"
        component={EmployeesSituationInfoDrawer}
      />
    </>
  );
}
