import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './styles.module.css';
import { ReactComponent as IconError } from '../../../../../../assets/ListIconError.svg';

function SimpleExpansionPanel({
    title,
    objectError
}) {
  return (
    <div className={styles.container}>
      <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={styles.containerHeader}
        >
            <div style={{ display: 'flex' }}>
              <IconError className={styles.iconError} />
              <Typography className={styles.number}>4</Typography>
              <Typography className={styles.title}>Registros com problemas</Typography>
            </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <List>
                {objectError.map((item) => (
                    <ListItem>
                    <ListItemText
                      primary={`- ${item}`}
                      className={styles.listItemText}
                    />
                  </ListItem>
                ))}
            </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SimpleExpansionPanel;
