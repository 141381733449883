import React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Search } from '@material-ui/icons';

import { useFilters } from './Hooks/useFilters';
import { useEmployeesAproved } from './Hooks/useEmployeesAproved';
import { useTableConfig } from './Hooks/useTableConfig';

import styles from '../../../../../styles.module.css';

import { situationOfEmployees } from '../../../../../../../../../mocks/situationOfEmployees';
import { Table } from '../../../../../../../../../components/Table';

export default function EmployeesAproved() {
  const { costAndCenter } = useSelector(state => state.payroll.manualPayroll);
  const { accountSelected } = useSelector(state => state.account);

  const {
    nameOrCpfFieldObject,
    textFieldFocusedObject,
    costAndCenterFieldObject,
    employeeSituationsObject,
  } = useFilters();

  const { table, handleSelectEmployees } = useEmployeesAproved({
    account: accountSelected,
  });
  const tableConfig = useTableConfig(accountSelected);

  return (
    <>
      <Box className={styles.headerFilters}>
        {situationOfEmployees.map(item => {
          return (
            <Button
              key={item.value}
              onClick={() =>
                employeeSituationsObject.handleFilterSituation(
                  item.value,
                  item.search,
                )
              }
              className={
                employeeSituationsObject.activeSituationButton === item.value
                  ? styles.buttonFilterActive
                  : styles.buttonFilter
              }
            >
              {item.text}
            </Button>
          );
        })}

        <FormControl
          variant="outlined"
          className={styles.textFieldStyle}
          size="small"
        >
          <InputLabel id="payment-type-select-box-label">
            Centro de custo
          </InputLabel>
          <Select
            labelId="payment-type-select-box-label"
            id="payment-type-select-box"
            label="Centro de custo"
            value={costAndCenterFieldObject.costAndCenterList}
            style={{ borderRadius: 6 }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            onChange={event =>
              costAndCenterFieldObject.setCostAndCenterList(event.target.value)
            }
          >
            {costAndCenter.data?.map((item, index) => (
              <MenuItem key={item.code} value={item.description}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TextField
        value={nameOrCpfFieldObject.nameOrCpf}
        onChange={event =>
          nameOrCpfFieldObject.setNameOrCpf(event.target.value)
        }
        className={styles.employeesListModalSearchInput}
        variant="outlined"
        size="small"
        placeholder="Pesquise por nome ou CPF"
        onFocus={() =>
          setTimeout(() =>
            textFieldFocusedObject.setTextFieldFocused('nameOrCpf', 200),
          )
        }
        onBlur={() =>
          setTimeout(() => textFieldFocusedObject.setTextFieldFocused(''), 200)
        }
        InputProps={{
          style: {
            borderRadius: 6,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.iconColor} />
            </InputAdornment>
          ),
          endAdornment: nameOrCpfFieldObject.nameOrCpf?.length > 0 &&
            textFieldFocusedObject.textFieldFocused === 'nameOrCpf' && (
              <IconButton
                className={styles.endAdornment}
                onClick={() => nameOrCpfFieldObject.setNameOrCpf('')}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            ),
        }}
      />

      <Box mt={3}>
        <Table
          {...table}
          tableConfig={tableConfig}
          selectable
          selectObject
          canSelectAll
          isHandleLocalData
          handleSelection={handleSelectEmployees}
          resetPageWhen={[
            employeeSituationsObject.activeSituationButton,
            costAndCenterFieldObject.costAndCenterList,
            nameOrCpfFieldObject.nameOrCpf,
          ]}
        />
      </Box>
    </>
  );
}
