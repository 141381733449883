import React from 'react';
import { useSelector } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Buttons from '../../../../../../components/Buttons';
import currencyFormatter from '../../../../../../utils/currencyFormatter';

import useStyles from '../styles';

const ApproveModal = NiceModal.create(({ onSubmit, selected, onClose }) => {
  const modal = useModal();
  const styles = useStyles();

  const {
    actionResults: { auth },
    isSending,
  } = useSelector(state => state.cashOut);

  const isLastApprover =
    selected.numberOfApprovals === selected.numberOfRequiredApprovals - 1;

  const handleClose = () => {
    onClose();
    modal.remove();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={modal.visible}
      onClose={handleClose}
      PaperProps={{ className: styles.root }}
    >
      <DialogTitle disableTypography className={styles.title}>
        Autorizar solicitação de{' '}
        <b className={styles.valueText}>{currencyFormatter(selected.amount)}</b>
        ?
      </DialogTitle>
      <DialogContent className={styles.content}>
        <DialogContentText className={styles.contentText}>
          Esta solicitação já possui <b>{selected.numberOfApprovals}</b> de{' '}
          <b>{selected.numberOfRequiredApprovals}</b> autorizações.
        </DialogContentText>
        {isLastApprover && (
          <DialogContentText className={styles.contentText}>
            Caso aprove essa solicitação, ela será processada na sequência.
          </DialogContentText>
        )}
        {auth.message && (
          <Alert className={styles.approvalError} severity="error">
            {auth.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Buttons.SecondaryButton
          variant="text"
          color="primary"
          title="CANCELAR"
          onClick={handleClose}
        />
        <Buttons.PrimaryButton
          color="secondary"
          title="AUTORIZAR"
          onClick={onSubmit}
          loading={isSending}
        />
      </DialogActions>
    </Dialog>
  );
});

export default ApproveModal;
