import React, { useState } from 'react';

import {
  Paper,
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  TablePagination,
  TableFooter,
  Tooltip,
  Chip,
  Grid,
  Avatar,
  Typography,
  MenuItem,
  Menu,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import MoreVert from '@material-ui/icons/MoreVert';

import styles from './styles';
import FakeRowTable from './FakeRowTable';

import { ReactComponent as EyesIcon } from '../../../../../assets/eyesIcon.svg';
import { ReactComponent as OptionIcon } from '../../../../../assets/optionIcon.svg';

import textCapitalize from '../../../../../utils/textCapitalize';
import convertBrazilianDate from '../../../../../utils/convertBrazilianDate';
import brazilianCurrencyFormatter from '../../../../../utils/brazilianCurrencyFormatter';

export default ({
  data,
  theme,
  classes,
  totalSize,
  pageSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
  loading,
  handleApprovedPropose,
  handleReprovePropose,
  handleEditPropose,
  handleUpdatePropose,
  handleDetailPropose,
  downloadCCB,
}) => {
  const [menuSelected, setMenuSelected] = useState();

  const STATUSES = {
    APPROVED: 'APPROVED',
    UNDER_ANALYSIS_RH: 'UNDER_ANALYSIS_RH',
  };

  const onClickMenu = (propose, event) => {
    setMenuSelected(renderMenuOptions(propose, event.currentTarget));
  };

  const headCells = [
    {
      id: 'employee',
      numeric: false,
      disablePadding: false,
      label: 'Funcionário',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Situação',
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Data da solicitação',
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: 'Valor Solicitado',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const renderEmployeeInfo = propose => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Avatar style={styles.nameAvatar}>
            {propose.employeeName ? propose.employeeName.split('')[0] : '-'}
          </Avatar>
        </Grid>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography style={styles.nameLabel}>
                {propose.employeeName
                  ? textCapitalize(propose.employeeName)
                  : '-'}
              </Typography>
            </Grid>

            <Grid item>
              <Typography style={styles.cpfLabel}>{propose.cpf}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderStatus = propose => {
    let chipStyle;

    switch (propose.status) {
      case STATUSES.APPROVED:
        chipStyle = styles.approvedPropose;
        break;

      case STATUSES.UNDER_ANALYSIS_RH:
        chipStyle = styles.underAnalysisRhPropose;
        break;

      default:
        break;
    }

    return <Chip style={chipStyle} label={propose.loanStatusDescription} />;
  };

  const renderAmountColumn = propose => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography style={styles.amountLabel}>
            {brazilianCurrencyFormatter(propose.principalAmount)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography style={styles.installmentLabel}>
            {`Em ${propose.installmentsNumber} parcelas`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderButtons = propose => {
    return (
      <div style={styles.displayStyle}>
        <IconButton
          onClick={() => {
            handleDetailPropose(propose);
            closeMenuWhenClicked();
          }}
        >
          <Tooltip title="Visualizar detalhes">
            <EyesIcon />
          </Tooltip>
        </IconButton>

        <IconButton
          disabled={handleDisableApprovalButton(propose)}
          className={classes.checkIcon}
          onClick={() => {
            handleApprovedPropose(propose);
            closeMenuWhenClicked();
          }}
        >
          <Tooltip title="Aprovar proposta">
            <CheckIcon />
          </Tooltip>
        </IconButton>

        <IconButton
          disabled={handleDisableMenuButton(propose)}
          onClick={event => onClickMenu(propose, event)}
        >
          <Tooltip title="Mais opções">
            <MoreVert />
          </Tooltip>
        </IconButton>

        {menuSelected}
      </div>
    );
  };

  const renderMenuOptions = (propose, anchor) => {
    function getOptions() {
      switch (propose.status) {
        // case STATUSES.APPROVED:
        //   return [
        //     <MenuItem
        //       onClick={() => {
        //         downloadCCB(propose);
        //         closeMenuWhenClicked();
        //       }}
        //     >
        //       <span className={classes.menuListItem}>Baixar CCB</span>
        //     </MenuItem>,
        //   ];

        case STATUSES.UNDER_ANALYSIS_RH:
          return [
            // <MenuItem
            //   onClick={() => {
            //     handleEditPropose(propose);
            //     closeMenuWhenClicked();
            //   }}
            // >
            //   <span className={classes.menuListItem}>Editar proposta</span>
            // </MenuItem>,

            // <MenuItem
            //   onClick={() => {
            //     handleUpdatePropose(propose);
            //     closeMenuWhenClicked();
            //   }}
            // >
            //   <span className={classes.menuListItem}>Nova simulação</span>
            // </MenuItem>,

            <MenuItem
              onClick={() => {
                handleReprovePropose(propose);
                closeMenuWhenClicked();
              }}
            >
              <span className={classes.menuListItem}>Reprovar proposta</span>
            </MenuItem>,
          ];
      }
    }

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open
        onClose={() => setMenuSelected(null)}
      >
        {getOptions()}
      </Menu>
    );
  };

  const handleDisableApprovalButton = propose => {
    return propose.status === STATUSES.APPROVED;
  };

  const handleDisableMenuButton = propose => {
    return propose.status === STATUSES.APPROVED;
  };

  function closeMenuWhenClicked() {
    setMenuSelected(null);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding="15px"
                  style={styles.tableHeadLabel}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <FakeRowTable />
            ) : (
              data?.map(propose => (
                <TableRow className={classes.tableRow}>
                  <TableCell align="left" className={classes.statusCell}>
                    {renderEmployeeInfo(propose)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {renderStatus(propose)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    <Typography style={styles.createdAtLabel}>
                      {convertBrazilianDate(propose.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {renderAmountColumn(propose)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {renderButtons(propose)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};
