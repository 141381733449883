import React from 'react';
import { Controller } from 'react-hook-form';
import { AccordionActions, Grid } from '@material-ui/core';

import Form from '../../../../components/Form';
import Buttons from '../../../../components/Buttons';
import SettingMenu from '../SettingMenu';

import useMinLimit from './hooks/useMinLimit';
import currencyFormatter from '../../../../utils/currencyFormatter';

import { useStyles } from '../../styles';

const ID = 'min-limit';

export default function MinLimitConfig() {
  const styles = useStyles();

  const {
    control,
    onSubmit,
    limit,
    isValid,
    isSending,
    errors,
    scheduled,
  } = useMinLimit();

  const subtitle = `O limite mínimo atual para notificação via e-mail é de ${currencyFormatter(
    limit || 0,
  )}`;

  const alert = `Alteração agendada: ${currencyFormatter(scheduled)}`;

  return (
    <SettingMenu
      id={ID}
      title="Limite mínimo de notificação de liberação de folha"
      subtitle={subtitle}
      alert={!!scheduled && alert}
    >
      <Form.FormBase onSubmit={onSubmit} className={styles.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="minAmountToNotify"
              render={({ field }) => (
                <Form.CurrencyField
                  label="Limite mínimo de notificações via e-mail"
                  size="medium"
                  fullWidth
                  {...field}
                  error={!!errors?.minAmountToNotify}
                  helperText={errors?.minAmountToNotify?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <AccordionActions className={styles.accordionActions}>
          <Buttons.PrimaryButton
            title="Salvar"
            size="medium"
            type="submit"
            disabled={!isValid}
            loading={isSending}
          />
        </AccordionActions>
      </Form.FormBase>
    </SettingMenu>
  );
}
