import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import { Search } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import FakeList from '../../../../../../../components/FakeList';
import ResultNotFound from '../../../../../../../components/ResultNotFound';

import { useFilters } from './Hooks/useFilters';
import { useEmployeesPending } from './Hooks/useEmployeesPending';

import styles from '../../../../styles.module.css';

import formatCPF from '../../../../../../../utils/formatCPF';
import textCapitalize from '../../../../../../../utils/textCapitalize';
import InsertBankDataDialogResponsive from '../InsertBankDataDialogResponsive';
import {
  concatAgencyWithDigit,
  concatAccountWithDigit,
  findBankName,
} from '../Helpers/formatBankData';

export default function EmployeesPending() {
  const { nameOrCpfFieldObject, handleClearFilters } = useFilters();
  const {
    banksList,
    cardListObject,
    isEmployeeSelected,
    handleSelectEmployee,
    handleInsertBankData,
    handleMoreSearchEmployeesPendingData,
  } = useEmployeesPending();

  return (
    <>
      <Box className={styles.filters}>
        <TextField
          className={styles.nameOrCpf}
          value={nameOrCpfFieldObject.nameOrCpf}
          onChange={event =>
            nameOrCpfFieldObject.setNameOrCpf(event.target.value)
          }
          placeholder="Pesquise por nome ou CPF"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: nameOrCpfFieldObject.nameOrCpf?.length > 0 && (
              <IconButton
                aria-label="toggle password visibility"
                className={styles.endAdornment}
                onClick={handleClearFilters}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            ),
          }}
        />
      </Box>

      <Box>
        {cardListObject.isLoading && !cardListObject.hasData ? (
          <FakeList />
        ) : cardListObject.length === 0 ? (
          <ResultNotFound text="Nenhum resultado encontrado" />
        ) : (
          <InfiniteScroll
            dataLength={cardListObject?.data?.length}
            next={handleMoreSearchEmployeesPendingData}
            hasMore={cardListObject.hasMoreData}
            height={570}
            loader={
              cardListObject.hasMoreData && (
                <Box className={styles.circularProgressContainer}>
                  <CircularProgress color="primary" size={24} />
                </Box>
              )
            }
            endMessage={
              <Box className={styles.containerEndMessage}>
                <Typography>Mostrando todos os colaboradores</Typography>
              </Box>
            }
          >
            {cardListObject?.data?.map((item, index) => {
              return (
                <Box className={styles.card} key={item?.encodedKey}>
                  <Box>
                    <Typography className={styles.text}>
                      {textCapitalize(item.name)}
                    </Typography>

                    <Typography className={styles.text}>
                      {formatCPF(item.cpf)}
                    </Typography>

                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ gap: '0.5rem' }}
                    >
                      <Box display="flex" flexDirection="row">
                        <Typography
                          className={styles.labelText}
                          variant="subtitle1"
                        >
                          Banco:
                        </Typography>
                        <Typography
                          className={styles.contentText}
                          variant="subtitle1"
                        >
                          {item?.bank
                            ? findBankName(item?.bank, banksList)
                            : '-'}
                        </Typography>
                      </Box>

                      <Box display="flex" flexDirection="row">
                        <Typography
                          className={styles.labelText}
                          variant="subtitle1"
                        >
                          Agência:
                        </Typography>
                        <Typography
                          className={styles.contentText}
                          variant="subtitle1"
                        >
                          {item?.agency && item?.agencyDigit
                            ? concatAgencyWithDigit(item)
                            : '-'}
                        </Typography>
                      </Box>

                      <Box display="flex" flexDirection="row">
                        <Typography
                          className={styles.labelText}
                          variant="subtitle1"
                        >
                          Conta:
                        </Typography>
                        <Typography
                          className={styles.contentText}
                          variant="subtitle1"
                        >
                          {item?.account && item?.accountDigit
                            ? concatAccountWithDigit(item)
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Checkbox
                      color="primary"
                      value={JSON.stringify(item)}
                      onChange={() => handleSelectEmployee(item)}
                      checked={isEmployeeSelected(item?.cpf)}
                      disabled={!item?.hasBankData}
                      style={{
                        background: '#52197F1A',
                        color: item?.hasBankData ? '#52197F' : '#52197F4D',
                      }}
                    />
                  </Box>

                  {!item?.hasBankData && (
                    <Box mt={2}>
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<EditOutlinedIcon />}
                        style={{ textTransform: 'initial' }}
                        onClick={() => handleInsertBankData(item)}
                      >
                        Inserir conta bancária
                      </Button>
                    </Box>
                  )}
                </Box>
              );
            })}
          </InfiniteScroll>
        )}
      </Box>

      <InsertBankDataDialogResponsive />
    </>
  );
}
