export function handleStatus(status) {
  switch (status) {
    case 'CREATE':
      return {
        label: 'Criado',
        color: '#5c2483',
      };
    case 'PENDING':
      return {
        label: 'Pendente',
        color: '#E54217',
      };
    case 'FAILED':
      return {
        label: 'Falha',
        color: 'gray',
      };
    case 'DRAFT':
      return {
        label: 'Rascunho',
        color: 'gray',
      };
    case 'PAID':
      return {
        label: 'Pago',
        color: '#53AF6D',
      };
    case 'FULFILLED':
      return {
        label: 'Pagamento realizado',
        color: 'green',
      };
    case 'ERROR':
      return {
        label: 'Erro',
        color: 'red',
      };
    default:
      return {
        label: 'Em branco',
        color: 'white',
      };
  }
}
