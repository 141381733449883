export const KEY_LABELS = {
  PHONE: 'Celular',
  EMAIL: 'E-mail',
  CNPJ: 'CNPJ',
  EVP: 'Chave aleatória',
};

export const KEY_STATUSES = {
  Ativo: {
    label: 'Ativo',
    status: 'success',
  },
  Pendente: {
    label: 'Pendente',
    status: 'warning',
  },
  Portabilidade: {
    label: 'Em processo de portabilidade',
    status: 'error',
  },
  Erro: {
    label: 'Erro',
    status: 'error',
  },
  Expirado: {
    label: 'Expirado',
    status: 'error',
  },
};
