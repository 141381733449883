import React from 'react';
import { CircularProgress, Typography, useTheme } from '@material-ui/core';
import { PrimaryButtonStyled } from './Styled';

export function PrimaryButton({
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  icon = () => {},
  title = 'PRIMARY BUTTON',
  loading = false,
  disabled = false,
  hasProgress = false,
  progress = 0,
  onClick = () => {},
  ...props
}) {
  const theme = useTheme();

  const gradientSecondPart = 100 - progress;

  return (
    <PrimaryButtonStyled
      variant={variant}
      size={size}
      color={color}
      startIcon={icon}
      onClick={onClick}
      disabled={disabled || loading}
      loading={loading}
      style={{
        background:
          hasProgress &&
          loading &&
          `linear-gradient(to right, ${theme.palette?.[color]?.main} ${progress}%, ${theme.palette?.[color]?.main10} ${gradientSecondPart}%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    >
      {hasProgress ? (
        <Typography
          style={{
            visibility: loading ? 'hidden' : 'visible',
          }}
        >
          {loading ? `${progress} %` : title}
        </Typography>
      ) : (
        <>
          {loading && (
            <CircularProgress size={16} style={{ position: 'absolute' }} />
          )}
          <Typography
            style={{
              visibility: loading ? 'hidden' : 'visible',
            }}
          >
            {title}
          </Typography>
        </>
      )}
    </PrimaryButtonStyled>
  );
}
