import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { ActionsEmployeesRegisterFilesGeneral } from '../../../../../redux/store/Employees/RegisterFiles';

export function useRegisterFilesGeneral() {
  const dispatch = useDispatch();
  const { registerFilesGeneral, length } = useSelector(
    state => state.employees.employeesRegisterFiles.general,
  );

  const filterInitialValues = {
    cpf: '',
    initialDate: moment().subtract(1, 'year'),
    finalDate: moment(),
  };

  const filterValidation = yup.object().shape({
    initialDate: yup
      .date()
      .max(moment(), `Deve ser menor que ${moment().format('DD/MM/YYYY')}`)
      .nullable('*Obrigatório')
      .required(),
    finalDate: yup
      .date()
      .max(moment(), `Deve ser menor que ${moment().format('DD/MM/YYYY')}`)
      .nullable('*Obrigatório')
      .required(),
  });

  function handleFilterChange(data) {
    dispatch(ActionsEmployeesRegisterFilesGeneral.setFilter({ ...data }));

    dispatch(
      ActionsEmployeesRegisterFilesGeneral.searchEmployeesRegisterFiles(),
    );
  }

  function paginationRequests(page, perPage) {
    dispatch(ActionsEmployeesRegisterFilesGeneral.handleChangePage(page));
    dispatch(ActionsEmployeesRegisterFilesGeneral.handleChangePerPage(perPage));
    dispatch(
      ActionsEmployeesRegisterFilesGeneral.searchEmployeesRegisterFiles(),
    );
  }

  useEffect(() => {
    dispatch(
      ActionsEmployeesRegisterFilesGeneral.searchEmployeesRegisterFiles(),
    );

    return () => {
      dispatch(ActionsEmployeesRegisterFilesGeneral.handleChangePage(0));
      dispatch(ActionsEmployeesRegisterFilesGeneral.handleChangePerPage(5));
    };
  }, []);

  return {
    filterObject: {
      filterInitialValues,
      filterValidation,
      handleFilterChange,
    },
    tableObject: {
      data: registerFilesGeneral?.filesList,
      length,
      isLoading: registerFilesGeneral?.isLoading,
      hasError: registerFilesGeneral?.errorOn?.hasError,
      paginationRequests,
    },
  };
}
