import React from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper
} from '@material-ui/core';

import styles from './styles.module.css';

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.labelHead}>
          CPF
        </TableCell>
        <TableCell className={styles.labelHead}>
          Nome
        </TableCell>
        <TableCell className={styles.labelHead}>
          Matrícula
        </TableCell>
        <TableCell className={styles.labelHead} />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  return (
    <Paper className={styles.tableContainer}>
      <div>
        <TableContainer>
          <Table>
            <EnhancedTableHead />

            <TableBody>
              <TableRow hover>
                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={28}
                    width={28}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                    circle
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={28}
                    width={28}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                    circle
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={28}
                    width={28}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                    circle
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={28}
                    width={28}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                    circle
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={22}
                    width={120}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Skeleton
                    height={28}
                    width={28}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                    circle
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={10}
          rowsPerPage={5}
          page={0}
        />
      </div>
    </Paper>
  );
}
