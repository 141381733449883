import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import NiceModal from '@ebay/nice-modal-react';
import { CashOutActions as Actions } from '../../../../redux/store/CashOut';

import validations from '../validations';

const BAR_CODE_MASK = '00000.00000 00000.000000 00000.000000 0 00000000000000';
const STEPS = ['Código de barras', 'Confirmação'];

export default function useCashoutBill() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isSending, isLoading, currentStep } = useSelector(
    state => state.cashOut,
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues: {
      digitable: '',
    },
  });

  const onConsultValues = (form, event) => {
    event.preventDefault();

    const digitable = form.digitable.replaceAll(/\s/g, '').replaceAll('.', '');
    dispatch(Actions.setDigitable(form.digitable));
    dispatch(Actions.getDetailsBill({ digitable }));
  };

  const onBack = () => {
    if (currentStep === 0) {
      history.goBack();
      return;
    }
    dispatch(Actions.changeStep(0));
  };

  const onDueDateEdit = ({ dueDate }) => {
    const newDueDate = dueDate.format('YYYY-MM-DD');
    dispatch(Actions.setBillDueDate(newDueDate));
    NiceModal.remove('edit-bill-due-date');
  };

  const onSubmit = () => {
    dispatch(Actions.setBillConfirmation());
  };

  useEffect(() => {
    reset();
    dispatch(Actions.changeStep(0));
    dispatch(Actions.resetBillSuccess());
  }, []);

  return {
    currentStep,
    isSending,
    isLoading,
    onConsult: () => handleSubmit(onConsultValues),
    onBack,
    onDueDateEdit,
    onSubmit,
    control,
    isValid,
    meta: {
      steps: STEPS,
      barCodeMask: BAR_CODE_MASK,
    },
  };
}
