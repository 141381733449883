import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Search from '@material-ui/icons/Search';
import {
  Box,
  Tab,
  Typography,
  Tabs,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';

import styles from './styles.module.css';

import { isMobile } from '../../../utils/breakpoints';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SimpleTabs({
  contentCostCenter,
  contentSector,
  onSearchChangeCostCenter,
  onSearchChangeLocationsCenter,
  setValue,
  value,
  handleClearFilters,
  filter,
  setPageSize,
  locationsCenterData,
  stockingCenterData,
  changedFilter
}) {
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleClearFilters();
    setPageSize(10);
  };

  return (
    <div className={styles.root}>
      <div
        style={isMobile ?
          {
            backgroundColor: '#fafafa',
            height: 30,
            position: 'relative',
            top: 10
           } : {}}
        />
      <AppBar position="static" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={styles.tabs}
          classes={{ indicator: styles.bigIndicator }}
        >
          <Tab
            label="Centro de Custo"
            {...a11yProps(0)}
            className={styles.tabName}
          />
          <Tab label="Setor" {...a11yProps(1)} className={styles.tabName} />
        </Tabs>
      </AppBar>

      <TabPanel
        value={value}
        index={0}
        className={styles.tabContent}
      >
        <div className={styles.body}>
          <div className={styles.areaButton}>
          </div>
          <TextField
            onChange={onSearchChangeCostCenter}
            placeholder="Pesquise por código ou nome"
            className={
              locationsCenterData?.content?.length === 0 && !changedFilter ?
              styles.hidden :
              styles.searchBackground
            }
            fullWidth
            variant="outlined"
            value={filter}
            onFocus={() => setTimeout(() => setTextFieldFocused('filter'), 200)}
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.iconColor} />
                </InputAdornment>
              ),
              endAdornment: (filter?.length > 0 && textFieldFocused === 'filter') && (
                <IconButton
                  className={styles.endAdornment}
                  onClick={() => handleClearFilters()}
                >
                  <CloseRoundedIcon className={styles.endAdornmentIcon} />
                </IconButton>
              )
            }}
          />
          {contentCostCenter && contentCostCenter}
        </div>
      </TabPanel>

      <TabPanel value={value} index={1} className={styles.tabContent}>
        <div className="body">
          <div className={styles.areaButton}>
          </div>
          <TextField
            onChange={onSearchChangeLocationsCenter}
            placeholder="Pesquise por código ou nome"
            className={
              stockingCenterData?.content?.length === 0 && !changedFilter ?
              styles.hidden :
              styles.searchBackground
            }
            value={filter}
            fullWidth
            variant="outlined"
            onFocus={() => setTimeout(() => setTextFieldFocused('filter'), 200)}
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.iconColor} />
                </InputAdornment>
              ),
              endAdornment: (filter?.length > 0 && textFieldFocused === 'filter') && (
                <IconButton
                  className={styles.endAdornment}
                  onClick={() => handleClearFilters()}
                >
                  <CloseRoundedIcon className={styles.endAdornmentIcon} />
                </IconButton>
              )
            }}
          />
          {contentSector && contentSector}
        </div>
      </TabPanel>
    </div>
  );
}
