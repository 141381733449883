import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

function FakeExtract() {
  return (
    <div className={styles.itens}>
      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={140}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.informations}>
          <div className={styles.skeletonStyle}>
            <div>
              <div style={{ position: 'relative', bottom: 1, marginRight: 10 }}>
                <Skeleton
                  circle
                  height={20}
                  width={20}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
            <div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={140}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
              <div style={{ position: 'relative', bottom: 1 }}>
                <Skeleton
                  height={18}
                  width={170}
                  color="#202020"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
          <div>
            <Skeleton
              height={30}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FakeExtract;
