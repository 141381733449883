import React from 'react';

import RegisterCardIcon from '@material-ui/icons/GroupAdd';

import styles from './styles.module.css';

export const actions = [
  {
    icon: <RegisterCardIcon className={styles.iconColor} />,
    name: 'Adicionar usuário',
    onClick: '/manage-users/register',
    value: 'USER_REGISTRATION',
  },
];
