import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(4),

      '& > div': {
        padding: 0,
      },
    },
    title: {
      fontWeight: 700,
      fontSize: '1.25rem',
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[900],
    },
    valueText: {
      color: theme.palette.primary.main,
    },
    content: {
      marginBottom: theme.spacing(4),
    },
    contentText: {
      color: theme.palette.grey[800],
    },
    textArea: {
      marginTop: theme.spacing(3),
    },
    approvalError: {
      margin: `${theme.spacing(4)}px 0`,
    },
    lockedIcon: {
      marginBottom: theme.spacing(4),
    },
    pinField: {
      marginBottom: theme.spacing(2),
    },
    resendButton: {
      '&.Mui-disabled': {
        color: theme.palette.primary.light,
      },
    },
    successIcon: {
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    successActions: {
      justifyContent: 'center',
    },
    alert: {
      marginBottom: theme.spacing(3),
    },
  }),
);
