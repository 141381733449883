import React from 'react';

import { ReactComponent as PaidOut } from '../../../../assets/paidOut.svg';
import { ReactComponent as Canceled } from '../../../../assets/canceledPayment.svg';
import { ReactComponent as AuthorizedIcon } from '../../../../assets/authorized.svg';
import { ReactComponent as WaitingPayment } from '../../../../assets/waiting.svg';
import { ReactComponent as InsufficientFunds } from '../../../../assets/insufficient.svg';
import { ReactComponent as ExpiredPayrollIcon } from '../../../../assets/icon_table_update.svg';
import { ReactComponent as PaymentIcon } from '../../../../assets/expressPayment3.svg';
import { ReactComponent as ExpressPaymentIcon } from '../../../../assets/expressPaymentIcon.svg';
import { ReactComponent as UncompletePayment } from '../../../../assets/uncompletePayment.svg';
import { ReactComponent as PaymentNotMade } from '../../../../assets/paymentNotMade.svg';

import style from '../styles.module.css';

function Status({ status }) {
  return (
    <div>
      {status === 'Paga' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#19797F1A' }}
        >
          <PaidOut />
          <p className={style.status} style={{ color: '#19797F' }}>
            {status}
          </p>
        </div>
      ) : status === 'Cancelada' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#CA23171A' }}
        >
          <Canceled />
          <p className={style.status} style={{ color: '#CA2317' }}>
            {status}
          </p>
        </div>
      ) : status === 'Autorizada' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: ' #2D54B91A' }}
        >
          <AuthorizedIcon />
          <p className={style.status} style={{ color: '#2D54B9' }}>
            {status}
          </p>
        </div>
      ) : status === 'Aguardando Autorização' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: ' #E39F171A' }}
        >
          <WaitingPayment />
          <p className={style.status} style={{ color: '#E39F17' }}>
            {status}
          </p>
        </div>
      ) : status === 'Saldo insuficiente' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#CA23171A' }}
        >
          <InsufficientFunds />
          <p className={style.status} style={{ color: '#CA2317' }}>
            {status}
          </p>
        </div>
      ) : status === 'Expirada' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#E39F171A' }}
        >
          <ExpiredPayrollIcon />
          <p className={style.status} style={{ color: '#E39F17' }}>
            {status}
          </p>
        </div>
      ) : status === 'Paga Imediata' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#1682431A' }}
        >
          <PaymentIcon />
          <p className={style.status} style={{ color: '#168243' }}>
            {status}
          </p>
        </div>
      ) : status === 'Autorizada Imediata' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#2D54B91A' }}
        >
          <ExpressPaymentIcon />
          <p className={style.status} style={{ color: '#2D54B9' }}>
            {status}
          </p>
        </div>
      ) : status === 'Pagamento Incompleto' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#E39F171A' }}
        >
          <UncompletePayment />
          <p className={style.status} style={{ color: '#E39F17' }}>
            {status}
          </p>
        </div>
      ) : status === 'Pagamento não Realizado' ? (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#CA23171A' }}
        >
          <PaymentNotMade />
          <p className={style.status} style={{ color: '#CA2317' }}>
            {status}
          </p>
        </div>
      ) : (
        <div
          className={style.containerStatus}
          style={{ backgroundColor: '#CA23171A' }}
        >
          <InsufficientFunds />
          <p className={style.status} style={{ color: '#CA2317' }}>
            {status}
          </p>
        </div>
      )}
    </div>
  );
}

export default Status;
