import React from 'react';
import { Box, FormControlLabel, Radio, Tooltip } from '@material-ui/core';

import { useStyles } from './styles';

export function RadioCardDefault({
  size = 'medium',
  disabled = false,
  disabledTooltipMessageGeneral = 'Não é possível escolher esta opção no momento.',
  value = null,
  onChange = () => {},
  item = {},
  getOptionLabel = item => item.label,
  getOptionValue = item => item.value,
  radioCardPaperStyleProps = {},
  ...otherProps
}) {
  const styles = useStyles();

  const isChecked = getOptionValue(item) === value;

  const handleChange = () => {
    if (disabled) return;

    onChange(item?.value);
  };

  return (
    <Tooltip
      arrow
      title={
        disabled
          ? item?.disabledTooltipMessage ?? disabledTooltipMessageGeneral
          : item?.tooltipMessage ?? ''
      }
    >
      <Box
        className={`${styles.root} ${styles.baselineCard} ${
          isChecked ? styles.selectedCard : ''
        } ${disabled ? styles.disabledCard : ''}`}
        style={radioCardPaperStyleProps}
        onClick={handleChange}
      >
        <FormControlLabel
          className={styles.control}
          checked={isChecked}
          disabled={disabled}
          value={getOptionValue(item)}
          onClick={handleChange}
          control={<Radio size={size} color="primary" />}
          {...otherProps}
        />
        {getOptionLabel(item)}
      </Box>
    </Tooltip>
  );
}
