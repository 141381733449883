import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { yupResolver } from '@hookform/resolvers/yup';
import Buttons from '../../../../../../components/Buttons';
import Form from '../../../../../../components/Form';

import { ReactComponent as Locked } from '../../../../../../assets/lockedIllustration.svg';

import validations from './validations';
import useStyles from '../styles';

const padTime = time => (String(time).length === 1 ? `0${time}` : `${time}`);

const format = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return `0${minutes}:${padTime(seconds)}`;
};

const INITIAL_TIMER = 80;

const MFAModal = NiceModal.create(({ onSubmit, onResend, onClose }) => {
  const modal = useModal();
  const styles = useStyles();

  const [counter, setCounter] = useState(INITIAL_TIMER);

  const {
    actionResults: { approval, reprocess },
    phoneNumber,
    isSending,
  } = useSelector(state => state.cashOut);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { pin: '' },
    resolver: yupResolver(validations),
  });

  const handlePinSubmit = (form, event) => {
    event.preventDefault();
    onSubmit(form);
  };

  const handleResend = () => {
    onResend();
    setCounter(INITIAL_TIMER);
  };

  const handleClose = () => {
    onClose();
    modal.remove();
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Dialog
      maxWidth="sm"
      open={modal.visible}
      onClose={handleClose}
      PaperProps={{ className: styles.root }}
    >
      <Form.FormBase onSubmit={() => handleSubmit(handlePinSubmit)}>
        <Locked className={styles.lockedIcon} />
        <DialogTitle className={styles.title}>
          Insira o código de confirmação
        </DialogTitle>
        <DialogContent className={styles.content}>
          <DialogContentText className={styles.contentText}>
            Enviamos um código de confirmação de 6 dígitos por SMS para o número{' '}
            {phoneNumber}.
          </DialogContentText>
          <Controller
            control={control}
            name="pin"
            render={({ field }) => (
              <Form.MaskedField
                mask="000000"
                className={styles.pinField}
                size="medium"
                label="Digite o código de confirmação"
                {...field}
              />
            )}
          />
          {approval.message && (
            <Alert className={styles.pinField} severity="error">
              {approval.message}
            </Alert>
          )}
          {reprocess.message && (
            <Alert className={styles.pinField} severity="error">
              {reprocess.message}
            </Alert>
          )}
          <Buttons.PrimaryButton
            variant="text"
            title={`Reenviar código${counter ? ` em ${format(counter)}` : ''}`}
            disabled={counter > 0}
            className={styles.resendButton}
            onClick={handleResend}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Buttons.PrimaryButton
            color="primary"
            variant="text"
            title="CANCELAR"
            onClick={handleClose}
          />
          <Buttons.PrimaryButton
            color="secondary"
            title="ENVIAR"
            type="submit"
            disabled={!isValid}
            loading={isSending}
          />
        </DialogActions>
      </Form.FormBase>
    </Dialog>
  );
});

export default MFAModal;
