const initialState = {
    openModal: false
  };
  
  export default function feedbackModal(state = initialState, action) {
    switch (action.type) {
        case 'SET_OPEN_FEEDBACK_MODAL':
        return { ...state, openModal: action.openModal };
        
        default:
            return state;
    }
  }
  