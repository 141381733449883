import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import styles from './styles.module.css';
import { useConsignedMarginBatchConfirm } from '../../utils/hooks';

function FileInfo() {
  const { data } = useConsignedMarginBatchConfirm();

  return (
    <Box className={styles.wrapper}>
      <Typography className={styles.title}>Detalhes do cadastro</Typography>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Typography className={styles.label}>Nome do arquivo</Typography>
          <Typography className={styles.value}>
            {data && data.fileName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography className={styles.label}>
            Total de colaboradores
          </Typography>
          <Typography className={styles.value}>
            {data && data.totalLines}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FileInfo;
