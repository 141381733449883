export default function useEmployeesConstants() {
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  const EMPLOYEE_DETAILS = JSON.parse(
    sessionStorage.getItem('employeeDetails'),
  );

  const EMPLOYEE_REGISTRATION_DATA = JSON.parse(
    sessionStorage.getItem('EMPLOYEE_REGISTRATION_DATA'),
  );

  const ONBOARDING_ENABLED = JSON.parse(
    sessionStorage.getItem('onboardEnabled'),
  );

  const CURRENT_COMPANY_INDEX = JSON.parse(
    sessionStorage.getItem('currentCompanyIndex'),
  );

  const COMPANIES = JSON.parse(sessionStorage.getItem('companies'));

  const ALL_TABS_ARRAY = [
    'personalData',
    'professionalData',
    'bankData',
    'addressData',
    'contactData',
    'historyData',
  ];

  return {
    CURRENT_COMPANY_CODE,
    CURRENT_COMPANY_INDEX,
    EMPLOYEE_DETAILS,
    EMPLOYEE_REGISTRATION_DATA,
    ONBOARDING_ENABLED,
    COMPANIES,
    ALL_TABS_ARRAY,
  };
}
