import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';

import { AcademyActions as Actions } from '../../../redux/store/Academy';

export default function useAcademy() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    topics,
    subtopics,
    isLoadingFlowSteps,
    isLoadingSubtopics,
    isLoading,
    isDownloading,
    isSearching,
    hasSuccessfulSearch,
    currentTopic,
    currentFlow,
    currentResult,
    searchResults,
  } = useSelector(state => state.academy);

  const { control, handleSubmit, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      keyWord: '',
    },
  });

  const onOpenHelpArticle = id => {
    dispatch(Actions.setCurrentSubtopic(id));
    onGetFlow(id);
    history.push(`/help-center/${id}`);
  };

  const onGetTopics = () => {
    dispatch(Actions.getTopicList());
  };

  const onGetSubtopics = id => {
    if (id !== currentTopic) {
      dispatch(Actions.setCurrentTopic(id));
      dispatch(Actions.getSubtopicList());
      return;
    }

    dispatch(Actions.setCurrentTopic(null));
  };

  const onGetFlow = id => {
    dispatch(Actions.getFlowContent(id));
  };

  const onSearch = useCallback(
    debounce(
      () =>
        handleSubmit(form => {
          if (form.keyWord) {
            dispatch(Actions.searchTopicList(form));
          }
        })(),
      500,
    ),
    [],
  );

  const onExpandResult = result => {
    if (result !== currentResult) {
      dispatch(Actions.setCurrentResult(result));
      return;
    }

    dispatch(Actions.setCurrentResult(null));
  };

  const handleDownload = id => {
    dispatch(Actions.getArticleFile(id));
  };

  return {
    topics,
    subtopics,
    searchResults,
    onOpenHelpArticle,
    onGetTopics,
    onGetSubtopics,
    onGetFlow,
    onSearch,
    onExpandResult,
    isLoading,
    isLoadingFlowSteps,
    isLoadingSubtopics,
    isDownloading,
    isSearching,
    hasSuccessfulSearch,
    handleDownload,
    currentTopic,
    currentFlow,
    currentResult,
    form: {
      control,
      getValues,
      setValue,
    },
  };
}
