import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      right: 0,
      zIndex: 2,
      marginLeft: '-2rem',
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: '#FFF',
      padding: '1rem 2rem',
      boxShadow: '0px -2px 6px 0px rgba(0, 0, 0, 0.12)',
    },
  }),
);
