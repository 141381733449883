import React from 'react';
import { VisibilityOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import formatCPF from '../../../../../../../utils/formatCPF';
import { ActionsEmployeeDetails } from '../../../../../../../redux/store/Employees/EmployeeDetails';

export function useTableConfig() {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleEmployeeDetails(employer) {
    dispatch(ActionsEmployeeDetails.setEmployeeBasicData(employer));

    sessionStorage.setItem('employeeDetails', JSON.stringify(employer));
    history.push(`employees/edit`);

    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Dados do funcionário',
        step: 0,
        route: '/employees',
      }),
    );
  }

  return [
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
      render: row => row?.companies?.[0]?.registration ?? '-',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      render: row => row?.name ?? '-',
    },
    {
      key: 'location',
      type: 'field',
      label: 'Centro de custo',
      render: row => row?.companies?.[0]?.location ?? '-',
    },
    {
      key: 'status',
      type: 'field',
      label: 'Situação',
      render: row => row?.companies?.[0]?.statusDescription ?? '-',
    },
    {
      key: 'employeeDetails',
      type: 'action',
      icon: <VisibilityOutlined color="primary" />,
      label: 'Visualizar detalhes',
      onClick: row => handleEmployeeDetails(row),
    },
  ];
}
