import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Box,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import styles from './styles.module.css';
import textCapitalize from '../../../../../utils/textCapitalize';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'cpf', numeric: true, disablePadding: false, label: 'CPF' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  {
    id: 'walletType',
    numeric: false,
    disablePadding: false,
    label: 'Tipo da recarga',
  },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.id === 'actions' ? 'center' : 'left'}
            className={styles.tableCellHeader}
          >
            {headCell.id === 'actions' ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        <TableCell className={styles.tableCellHeader} />
      </TableRow>
    </TableHead>
  );
}

export default function TableInputMoney({
  createRechargesBeevale,
  selectedEmployees,
  setSelectedEmployees,
  SHOW_BOTTOM_BAR,
  loading,
  walletTypes,
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: 25,
    },
    paper: {
      width: '100%',
      marginBottom: SHOW_BOTTOM_BAR ? 70 : -60,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    header: {
      background: '#ffffff',
    },
  }));

  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const inputValueModal = useSelector(
    state => state.applyValueInputs.inputValue,
  );

  const inputWalletType = useSelector(
    state => state.applyValueInputs.inputWalletType,
  );

  // const { selectedRowsEmployees } = useSelector(
  //   state => state?.recharge?.manualRecharge,
  // );

  useEffect(() => {
    if (inputWalletType) {
      createRechargesBeevale.map((item, index) => {
        const auxList = [...createRechargesBeevale];

        auxList[index].walletType = inputWalletType;

        sessionStorage.setItem(
          'createRechargesBeevale',
          JSON.stringify(auxList.flat(Infinity)),
        );

        setSelectedEmployees(auxList.flat(Infinity));
      });
    }
    if (inputValueModal) {
      createRechargesBeevale.map((item, index) => {
        const auxList = [...createRechargesBeevale];

        auxList[index].amount = inputValueModal;

        sessionStorage.setItem(
          'createRechargesBeevale',
          JSON.stringify(auxList.flat(Infinity)),
        );

        setSelectedEmployees(auxList.flat(Infinity));
      });
    }
  }, [inputValueModal, inputWalletType]);

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onChangeTextWalletType(event, row, value) {
    const auxList = [...createRechargesBeevale];
    const walletType = value;
    const index = auxList.findIndex(item => item.cpf === row.cpf);

    dispatch({
      type: 'SET_WALLET_TYPE_INPUTS',
      inputWalletType: '',
    });

    auxList[index].walletType = walletType.props.value;

    sessionStorage.setItem(
      'createRechargesBeevale',
      JSON.stringify(auxList.flat(Infinity)),
    );

    setSelectedEmployees(auxList.flat(Infinity));
  }

  function onChangeCurrentTextFild(event, row, value) {
    const auxList = [...createRechargesBeevale];
    const amount = value;
    const index = auxList.findIndex(item => item.cpf === row.cpf);

    dispatch({
      type: 'SET_VALUE_INPUTS',
      inputValue: '',
    });

    auxList[index].amount = amount;

    sessionStorage.setItem(
      'createRechargesBeevale',
      JSON.stringify(auxList.flat(Infinity)),
    );

    setSelectedEmployees(auxList.flat(Infinity));
  }

  function handleRemoveEmployee(row) {
    const auxArray = createRechargesBeevale;
    const index = auxArray.findIndex(item => item.cpf === row.cpf);

    auxArray.splice(index, 1);

    setSelectedEmployees([...auxArray]);
    sessionStorage.setItem('createRechargesBeevale', JSON.stringify(auxArray));
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={styles.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={selectedEmployees.length}
            />
            <TableBody>
              {stableSort(selectedEmployees, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.cpf}>
                      <TableCell className={styles.tableCell}>
                        {row.cpf}
                      </TableCell>

                      <TableCell className={styles.tableCell}>
                        {textCapitalize(row.name)}
                      </TableCell>

                      <TableCell className={styles.tableCell}>
                        <FormControl
                          variant="outlined"
                          className={styles.textFieldStyleSelect}
                          size="small"
                        >
                          <Select
                            value={row.walletType}
                            onChange={(event, value) =>
                              onChangeTextWalletType(event, row, value)
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {walletTypes?.map(type => (
                              <MenuItem key={type.id} value={type.name}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell className={styles.tableCell}>
                        <CurrencyInput
                          onChange={(event, value) =>
                            onChangeCurrentTextFild(event, row, value)
                          }
                          value={row.amount}
                          className={styles.inputValue}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix="R$ "
                          disabled={loading}
                        />
                      </TableCell>

                      <TableCell align="center" className={styles.tableCell}>
                        <IconButton
                          className={styles.removeEmployeeIconButton}
                          onClick={() => handleRemoveEmployee(row)}
                          disabled={loading}
                        >
                          <CloseRoundedIcon
                            className={styles.removeEmployeeIcon}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell />
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={selectedEmployees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
