import React, { useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'

import styles from './styles.module.css';

import textCapitalize from '../../../../utils/textCapitalize';
import formatCPF from '../../../../utils/formatCPF';
import FakeRowsTable from './FakeTable';

const headCells = [
  { id: '1', label: 'CPF' },
  { id: '2', label: 'Nome' },
  { id: '3', label: 'Matrícula' },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className={styles.labelHead}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ 
  data,
  isLoading,
	totalSize,
	pageSize,
	currentPage,
	handlePageChange,
	handleRowsPerPageChange,
	TablePaginationActions
 }) {

  return (
    <div className={styles.tableContainer}>
      <div>
        <TableContainer>
          <Table>
            <EnhancedTableHead />
            <TableBody>
              {isLoading ? (FakeRowsTable()):
                data?.map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.cpf}
                    >
                      <TableCell className={styles.tableCell}>
                        {formatCPF(row.cpf)}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {textCapitalize(row.employeeName)}
                      </TableCell>
                      <TableCell className={styles.tableCell}>{row.employeeRegistration}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalSize}
                  rowsPerPage={pageSize}
                  page={currentPage}
                  labelRowsPerPage="linhas por página"
                  labelDisplayedRows={
                    function defaultLabelDisplayedRows({ from, to, count }) {
                      return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
                    }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
