import React from 'react';

import { Button } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import Title from '../../components/Title';

import styles from './styles.module.css';

export default function Header({ handleAddAccount, isMobile }) {
  return (
    <div className={styles.subheader}>
      <Title>Gerenciar contas</Title>
      <Button className={styles.secondButton} onClick={handleAddAccount}>
        {isMobile ? (
          <PlaylistAddIcon />
        ) : (
          <AddRoundedIcon className={styles.addRoundedIcon} />
        )}
        Nova conta
      </Button>
    </div>
  );
}
