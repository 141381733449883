import React from 'react';

import ManualPaymentCardIcon from '@material-ui/icons/ListRounded';
import PaymentCardIcon from '@material-ui/icons/DescriptionRounded';

import styles from './styles.module.css';

export const actions = [
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'PDF',
    onClick: 'PDF',
    value: 'FILE_PDF',
  },
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'CSV',
    onClick: 'CSV',
    value: 'FILE_CSV',
  },
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'XLS',
    onClick: 'XLS_EXTRACT',
    value: 'FILE_XLS',
  },
];
