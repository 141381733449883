import React, { useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  Hidden,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import formatCPF from '../../utils/formatCPF';
import formatCnpj from '../../utils/formatCnpj';
import { isMobile } from '../../utils/breakpoints';
import formatAccountAndAgencyNumber from '../../utils/formatAccountAndAgencyNumber';
import formatDigitValue from '../../utils/formatDigitValue';
import { api } from '../../services/api';
import styles from './styles.module.css';

function ModalRemoveAccount({ open, onClose, handleConfirm }) {
  const [bank, setBank] = useState('');
  const [errorBankMessage, setErrorBankMessage] = useState('');
  const [errorAgencyMessage, setErrorAgencyMessage] = useState('');
  const [agency, setAgency] = useState('');
  const [account, setAccount] = useState('');
  const [errorAccountMessage, setErrorAccountMessage] = useState('');
  const [cpfCnpj, setCpfOrCnpnj] = useState('');
  const [errorCpfCnpjMessage, setErrorCpfCnpjMessage] = useState('');
  const [checkDigit, setCheckDigit] = useState('');
  const [errorCheckDigitMessage, setErrorCheckDigitMessage] = useState('');
  const [name, setName] = useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [banksList, setBanksList] = useState([]);

  const [errorBank, setErrorBank] = useState(false);
  const [errorAgency, setErrorAgency] = useState(false);
  const [errorAccount, setErrorAccount] = useState(false);
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [errorCheckDigit, setErrorCHeckDigit] = useState(false);
  const [loading, setLoading] = useState(false);

  const COMPAY_CODE = sessionStorage.getItem('currentCompanyCode');

  const DISABLED_BUTTON = Boolean(
    !bank || !agency || !account || !cpfCnpj || !checkDigit,
  );

  useEffect(() => {
    getBanksList();
  }, []);

  const cleanAllInputs = () => {
    setCpfOrCnpnj('');
    setAccount('');
    setAgency('');
    setName('');
    setBank('');
    setCheckDigit('');
    setErrorAgency(false);
    setErrorAccount(false);
    setErrorCpfCnpj(false);
    setErrorBank(false);
    setErrorCHeckDigit(false);
    setErrorAgencyMessage('');
    setErrorAccountMessage('');
    setErrorCpfCnpjMessage('');
    setErrorBankMessage('');
    setErrorCheckDigitMessage('');
  };

  const closeModal = () => {
    cleanAllInputs();
    onClose();
  };

  const getBanksList = () => {
    api
      .get('/accountCashIn/bank/findAll')
      .then(({ data }) => setBanksList(data));
  };

  const registerAccount = () => {
    setLoading(true);
    const requestBody = {
      bankCode: bank.code,
      agency,
      account,
      cpfCnpj,
      name,
      checkDigit,
    };
    api
      .post(`/accountCashIn/create/${COMPAY_CODE}`, requestBody)
      .then(registerAccountThen)
      .catch(({ response: { data: { errors } } }) =>
        registerAccountCatch(errors),
      )
      .finally(() => setLoading(false));
  };

  const registerAccountThen = () => {
    handleConfirm();
    cleanAllInputs();
  };

  const registerAccountCatch = errors => {
    const objectError = errors.shift();

    if (objectError.errorReason === 'INVALID_PARAMETER') {
      setErrorCpfCnpj(true);
      setErrorCpfCnpjMessage(objectError.errorDetail);
      setErrorAccount(false);
      setErrorAccountMessage('');
    } else {
      setErrorAccount(true);
      setErrorAccountMessage(objectError.errorDetail);
      setErrorCpfCnpj(false);
      setErrorCpfCnpjMessage('');
    }
  };

  const maskedAgencyNumber = value => {
    value = value.replace(/\D/g, '');
    return value;
  };

  const onChangeCpfCnpj = value => {
    const number = value.replace(/\D/g, '');
    const maskedCpfCnpj =
      number.length <= 11 ? formatCPF(number) : formatCnpj(number);
    setCpfOrCnpnj(maskedCpfCnpj);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <div className={styles.dialog}>
        <div>
          <Typography className={styles.biggerText}>
            Incluir nova conta bancária
          </Typography>
          <Typography className={styles.smallText}>
            Preencha os dados abaixo:
          </Typography>
        </div>

        <div>
          <form className={styles.form}>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Banco</Typography>
              </Hidden>
              <Autocomplete
                value={bank}
                onChange={(event, value) => setBank(value)}
                className={errorBank ? styles.selectError : styles.textField}
                options={banksList}
                closeIcon={false}
                autoHighlight
                getOptionLabel={option => option.name}
                renderOption={option => (
                  <>
                    {option.name} - {option.code}
                  </>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    label={isMobile && 'Banco'}
                    placeholder={!isMobile && 'Selecione um banco'}
                    disabled={loading}
                  />
                )}
              />
              {errorBank && (
                <FormHelperText className={styles.helperText}>
                  {errorBankMessage}
                </FormHelperText>
              )}
            </div>

            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Agência</Typography>
              </Hidden>

              <TextField
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                disabled={loading}
                label={isMobile && 'Agência'}
                className={styles.textField}
                error={errorAgency}
                helperText={errorAgencyMessage}
                value={maskedAgencyNumber(agency)}
                onChange={({ target: { value } }) => setAgency(value)}
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('agency'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{
                  maxLength: 4,
                  endAdornment: agency?.length > 0 &&
                    textFieldFocused === 'agency' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setAgency('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Conta corrente</Typography>
              </Hidden>

              <TextField
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                disabled={loading}
                label={isMobile && 'Conta corrente'}
                className={styles.textField}
                error={errorAccount}
                helperText={errorAccountMessage}
                value={formatAccountAndAgencyNumber(account)}
                onChange={({ target: { value } }) =>
                  setAccount(formatAccountAndAgencyNumber(value))
                }
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('account'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{
                  maxLength: 16,
                  endAdornment: account?.length > 0 &&
                    textFieldFocused === 'account' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setAccount('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>
                  Dígito verificador
                </Typography>
              </Hidden>

              <TextField
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                disabled={loading}
                label={isMobile && 'Dígito verificador'}
                className={styles.textField}
                error={errorCheckDigit}
                helperText={errorCheckDigitMessage}
                value={formatDigitValue(checkDigit)}
                onChange={({ target: { value } }) =>
                  setCheckDigit(formatDigitValue(value))
                }
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('checkDigit'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{
                  maxLength: 2,
                  endAdornment: checkDigit?.length > 0 &&
                    textFieldFocused === 'checkDigit' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setCheckDigit('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>

            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>CPF / CNPJ</Typography>
              </Hidden>

              <TextField
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                label={isMobile && 'CPF / CNPJ'}
                disabled={loading}
                className={styles.textField}
                error={errorCpfCnpj}
                helperText={errorCpfCnpjMessage}
                value={cpfCnpj}
                onChange={({ target: { value } }) => onChangeCpfCnpj(value)}
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('cpfCnpj'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{
                  maxLength: 18,
                  endAdornment: cpfCnpj?.length > 0 &&
                    textFieldFocused === 'cpfCnpj' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => onChangeCpfCnpj('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>

            <div className={styles.formName}>
              <Hidden smDown>
                <Typography className={styles.label}>
                  Nome (opcional)
                </Typography>
              </Hidden>
              <TextField
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                label={isMobile && 'Nome (opcional)'}
                className={styles.textField}
                disabled={loading}
                value={name}
                onChange={({ target: { value } }) => setName(value)}
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('name'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                nputProps={{
                  endAdornment: name?.length > 0 &&
                    textFieldFocused === 'name' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setName('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
          </form>
        </div>

        <div className={styles.buttonsGroup}>
          <Button className={styles.cancelButton} onClick={closeModal}>
            Cancelar
          </Button>

          <Button
            className={styles.confirmButton}
            onClick={registerAccount}
            disabled={DISABLED_BUTTON || loading}
          >
            {loading ? (
              <CircularProgress
                className={styles.spinner}
                size={styles.spinner}
              />
            ) : (
              'Incluir'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalRemoveAccount;
