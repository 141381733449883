import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';

import CustomDatePicker from '../../../../components/DatePicker';

import styles from './styles.module.css';

import { api } from '../../../../services/api';
import { useValidationForUnformattedDate } from '../../../../hooks/useValidationForUnformattedDate';

export default function InfoCard({
  currentPaymentType,
  fileName,
  paymentType,
  setPaymentType,
  date,
  setDate,
  selectedAvailablePaymentHour,
  setSelectedAvailablePaymentHour,
  availablePaymentHours,
  competence,
  setCompetence,
  setPaymentTypeDescription,
  accounts,
  hasMoreThanOneDepositAccount,
}) {
  const [types, setTypes] = useState([]);

  const validateDateInfo = useValidationForUnformattedDate(date);

  const PAYMENT_TYPE_IS_RECISION = '2';
  const zeroProduct = JSON.parse(sessionStorage.getItem('zeroProduct'));

  useEffect(() => {
    getTypes();
  }, []);

  function getTypes() {
    api.get('/employeesPayments/payments/types').then(({ data }) => {
      const filteredType = data.filter(
        item => item.description === currentPaymentType,
      );

      if (filteredType[0]?.code === PAYMENT_TYPE_IS_RECISION || zeroProduct) {
        setDate(moment());
      } else {
        setDate(moment().add(1, 'day'));
      }

      setSelectedAvailablePaymentHour(
        moment()
          .add(1, 'hour')
          .format('HH:00:00'),
      );

      setTypes(data);
      setPaymentType(filteredType[0]?.code);
    });
  }

  function onChangePaymentType(value) {
    setPaymentType(value);
    const filteredTypeDescription = types.filter(item => item.code === value);
    setPaymentTypeDescription(filteredTypeDescription[0]?.description);
  }

  function onChangeDate(value) {
    setDate(value);
  }

  function onChangeCompetence(value) {
    setCompetence(value);
  }

  const renderAccount = hasMoreThanOneDepositAccount
    ? accounts.find(
        account =>
          account.origin === 'FACIL_SCD' && account.status === 'ACTIVE',
      )?.id || '-'
    : accounts[0].id;

  return (
    <Grid container direction="column" className={styles.cardContainer}>
      <Grid item>
        <Typography className={styles.mediumText}>
          As informações abaixo estão corretas?
        </Typography>
      </Grid>

      <Grid container item spacing={3} alignItems="center">
        <Grid item>
          <Box mt={3} mb={3}>
            <Typography className={styles.label}>Nome do arquivo</Typography>
            <Typography className={styles.text}>{fileName}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mt={3} mb={3}>
            <Typography className={styles.label}>Conta</Typography>
            <Typography className={styles.text}>{renderAccount}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" className={styles.textFieldStyle}>
            <InputLabel id="payment-type-select-box-label">
              Tipo de pagamento*
            </InputLabel>

            <Select
              labelId="payment-type-select-box-label"
              id="payment-type-select-box"
              label="Tipo de pagamento*"
              value={paymentType}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              onChange={({ target: { value } }) => onChangePaymentType(value)}
            >
              {types.map((type, index) => (
                <MenuItem key={type.description} value={type.code}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl className={styles.textFieldStyle}>
            <InputLabel
              id="select-date-label"
              style={date ? { display: 'none' } : {}}
              className={date ? styles.disabledInputLabel : styles.inputLabel}
            >
              Data do pagamento*
            </InputLabel>

            <CustomDatePicker
              labelId="select-date-label"
              id="select-date"
              value={date}
              label={date && 'Data do pagamento*'}
              onChange={onChangeDate}
              minDate={
                paymentType !== PAYMENT_TYPE_IS_RECISION && !zeroProduct
                  ? moment().add(1, 'day')
                  : moment()
              }
              error={validateDateInfo}
              disableCalendarIcon={validateDateInfo}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" className={styles.textFieldStyle}>
            <InputLabel id="payment-hour-select-box-label">
              Hora do pagamento*
            </InputLabel>

            <Select
              labelId="payment-hour-select-box-label"
              label="Hora do Pagamento*"
              value={selectedAvailablePaymentHour}
              onChange={({ target: { value } }) =>
                setSelectedAvailablePaymentHour(value)
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {moment(date).format('YYYY-MM-DD') ===
              moment().format('YYYY-MM-DD')
                ? availablePaymentHours.map(hour => {
                    if (
                      hour.value > moment().format('HH:00:00') &&
                      hour.value <= '21:00:00'
                    ) {
                      return (
                        <MenuItem key={hour.description} value={hour.value}>
                          {hour.description}
                        </MenuItem>
                      );
                    }

                    return (
                      <MenuItem
                        disabled
                        key={hour.description}
                        value={hour.value}
                      >
                        {hour.description}
                      </MenuItem>
                    );
                  })
                : availablePaymentHours.map(hour => (
                    <MenuItem key={hour.description} value={hour.value}>
                      {hour.description}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" className={styles.textFieldStyle}>
            <InputLabel
              id="select-date-label"
              style={competence ? { display: 'none' } : {}}
              className={
                competence
                  ? styles.disabledInputLabel
                  : styles.competenceInputLabel
              }
            >
              Competência*
            </InputLabel>
            <MuiPickersUtilsProvider
              utils={(DateFnsUtils, MomentUtils)}
              locale="pt-br"
            >
              <KeyboardDatePicker
                openTo="year"
                views={['year', 'month']}
                label={competence ? 'Competência*' : null}
                value={competence || null}
                onChange={onChangeCompetence}
                inputVariant="outlined"
                keyboardIcon={<EventIcon className={styles.iconColor} />}
                InputAdornmentProps={{ position: 'end' }}
                helperText={false}
                error={false}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
