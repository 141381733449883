import React from 'react';
import { useSelector } from 'react-redux';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from './styles';

export default function CashOutBillBottomBar({
  isLoading,
  isFinal,
  disabled,
  onBack,
  onSubmit,
}) {
  const styles = useStyles();

  const {
    actionResults: { bill },
  } = useSelector(state => state.cashOut);

  const renderConfirmLabel = isFinal ? 'Solicitar' : 'Continuar';

  const renderBackLabel = bill.hasSuccess
    ? 'Nova solicitação via boleto'
    : 'Voltar';

  const getButtonColor = isFinal ? 'secondary' : 'primary';

  return (
    <footer className={styles.root}>
      <Buttons.PrimaryButton
        variant="outlined"
        color="primary"
        onClick={onBack}
        title={renderBackLabel}
      />

      {bill.hasSuccess ? (
        <Buttons.PrimaryButton
          color="primary"
          title="Gerenciar solicitações"
          onClick={onSubmit}
        />
      ) : (
        <Buttons.PrimaryButton
          type="submit"
          color={getButtonColor}
          disabled={disabled}
          loading={isLoading}
          title={renderConfirmLabel}
        />
      )}
    </footer>
  );
}
