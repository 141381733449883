import React from 'react';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

function FakeList() {

  function renderFakeList() {
    return (
      <div>
        <List component="nav">
          <ListItem button className={styles.listItemContainer}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                justify="space-between"
              >
                <Grid item container alignItems="center">
                  <Grid item className={styles.icon}>
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      color="#202020"
                      highlightColor="#444"
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={styles.statusLabel}>
                      <Skeleton
                        height={14}
                        width={140}
                        color="#202020"
                        highlightColor="#444"
                      />
                    </Typography>
                  </Grid>
                </Grid>
  
                <Grid item>
                  <Skeleton
                    circle={true}
                    height={30}
                    width={30}
                    color="#202020"
                    highlightColor="#444"
                  />
                </Grid>
              </Grid>
  
              <Grid item className={styles.type}>
                <Typography className={styles.typeLabel}>
                  <Skeleton
                    height={20}
                    width={100}
                    color="#202020"
                    highlightColor="#444"
                  />
                </Typography>
              </Grid>
  
              <Grid item className={styles.amount}>
                <Typography className={styles.amountLabel}>
                  <Skeleton
                    height={20}
                    width={100}
                    color="#202020"
                    highlightColor="#444"
                  />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </div>
    );
  }

  return(
    <div>
      {renderFakeList()}
      {renderFakeList()}
      {renderFakeList()}
      {renderFakeList()}
      {renderFakeList()}
    </div>
  )
}

export default FakeList;
