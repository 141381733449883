import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from '../../styles.module.css';

const MenuCardRegister = ({ title, icon, onClick }) => (
  <div className={styles.cardContainer} onClick={onClick}>
    <Button className={styles.cardStyle}>
      <Typography className={styles.cardTitleStyle}>{title}</Typography>
      <div className={styles.cardIconContainer}>{icon}</div>
    </Button>
  </div>
);

export default MenuCardRegister;
