import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { getCurrentCompanieData } from '../../../utils/getCurrentCompanieData';

import { uniqBy } from 'lodash-es';
import { Header, InfoCard, ValuesCard, Table } from './UI';

import styles from './UI/styles.module.css';
import EmployeesListModal from './UI/EmployeesListModal';

import ProgressToolBar from '../../../components/ProgressToolBar';
import FiredEmployeesModal from '../../../components/PrimaryModal';
import SkippedInvalidModal from '../../../components/ModalSkippedInvalidDeposits';
import ErrorModal from '../../../components/PrimaryModal';
import CommingSoon from '../../../components/CommingSoon';
import SuccessModal from '../../../components/SuccessModal';

import { isMobile } from '../../../utils/breakpoints';

import { api } from '../../../services/api';

export default function PayrollRegenerate() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { paymentType: currentPaymentType, fileName, nsu } = location.state;

  const { totalAmount, paymentsAmount } = useSelector(
    state => state.applyValueTotalAmount.data,
  );

  const { accounts, accountSelected } = useSelector(state => state.account);
  const {
    currentCompany:{cnpj},
    companies
  } = useSelector(state => state.companies); 

  const currentCompanyData = getCurrentCompanieData(cnpj, companies)

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const authToken = sessionStorage.getItem('@ContaSoma-Token');

  const history = useHistory();

  const [paymentType, setPaymentType] = useState('');
  const [paymentTypeDescription, setPaymentTypeDescription] = useState('');
  const [date, setDate] = useState('');
  const availablePaymentHours = [
    { value: '21:00:00', description: '21:00' },
    { value: '20:00:00', description: '20:00' },
    { value: '19:00:00', description: '19:00' },
    { value: '18:00:00', description: '18:00' },
    { value: '17:00:00', description: '17:00' },
    { value: '16:00:00', description: '16:00' },
    { value: '15:00:00', description: '15:00' },
    { value: '14:00:00', description: '14:00' },
    { value: '13:00:00', description: '13:00' },
    { value: '12:00:00', description: '12:00' },
    { value: '11:00:00', description: '11:00' },
    { value: '10:00:00', description: '10:00' },
    { value: '09:00:00', description: '09:00' },
    { value: '08:00:00', description: '08:00' },
    { value: '07:00:00', description: '07:00' },
    { value: '06:00:00', description: '06:00' },
    { value: '05:00:00', description: '05:00' },
    { value: '04:00:00', description: '04:00' },
  ];
  const [
    selectedAvailablePaymentHour,
    setSelectedAvailablePaymentHour,
  ] = useState('');
  const [competence, setCompetence] = useState('');
  const [cpfOrName, setCpfOrName] = useState('');
  const [feesAmount, setFeesAmount] = useState(0);
  const [loadingRegenerate, setLoadingRegenerate] = useState(true);
  const [depositAccount, setDepositAccount] = useState(accounts[0].encodedKey);

  const [employees, setEmployees] = useState([]);
  const [selected, setSelected] = useState([]);
  const [ignoredEmployees, setIgnoredEmployees] = useState([]);

  const [openEmployeesListModal, setOpenEmployeesListModal] = useState(false);
  const [openFiredEmployeesModal, setOpenFiredEmployeesModal] = useState(false);
  const [openSkippedInvalidModal, setOpenSkippedInvalidModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isLimitModal, setIsLimitModal] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const DISABLED_NEXT_BUTTON = !paymentType || !date || !competence;

  const hasMoreThanOneDepositAccount = accounts.length > 1;

  const depositAccountIdSelected = accounts?.map(account => {
    if (account.encodedKey === depositAccount) {
      return account.id;
    }
    return null;
  });

  useEffect(() => {
    getRegerateData();
  }, []);

  useEffect(() => {
    if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      if (moment().format('HH:00:00') < '21:00:00') {
        setSelectedAvailablePaymentHour(
          moment()
            .add(1, 'hour')
            .format('HH:00:00'),
        );
      } else {
        setSelectedAvailablePaymentHour('');
      }
    } else if (moment().format('HH:00:00') < '21:00:00') {
      setSelectedAvailablePaymentHour(moment().format('HH:00:00'));
    } else {
      setSelectedAvailablePaymentHour('04:00:00');
    }
  }, [date]);

  function getRegerateData() {
    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
      params: {
        nsu,
        isRegenerated: true,
      },
    };

    api
      .get(`/employeesPayments/search/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        localStorage.setItem(
          'valueStorageAmount',
          data?.paymentsFiles[0]?.totalAmount,
        );
        localStorage.setItem(
          'valueStoragePayments',
          data?.paymentsFiles[0]?.paymentsAmount,
        );

        dispatch({
          type: 'SET_VALUE_TOTAL_AMOUNT',
          data: {
            totalAmount: data?.paymentsFiles[0]?.totalAmount,
            paymentsAmount: data?.paymentsFiles[0]?.paymentsAmount,
          },
        });

        setFeesAmount(data?.paymentsFiles[0]?.feesAmount);
      })
      .finally(() => setLoadingRegenerate(false));
  }

  function includeEmployees(selected) {
    if (selected.find(item => item.employeeStatus === 'Demitido')) {
      setOpenFiredEmployeesModal(true);
      return;
    }

    confirmIncludeEmployees();
  }

  function confirmIncludeEmployees() {
    const arraysRegenerateAndSelected = [...employees, ...selected];
    const noRepeatRegenerateAndSelected = uniqBy(
      arraysRegenerateAndSelected,
      'cpf',
    );

    setEmployees(noRepeatRegenerateAndSelected);
    setOpenFiredEmployeesModal(false);
    setOpenEmployeesListModal(false);
  }

  function sendEmployeesForDraft(continueProcess = true) {
    setIsLoading(true);
    const fullEmployees = [...employees];

    const requestOptions = {
      headers: {
        depositAccountId: depositAccount,
      },
    };

    const body = {
      fileEncodedKey: null,
      scheduleDate: `${moment(date).format(
        'YYYY-MM-DD',
      )}T${selectedAvailablePaymentHour}`,
      paymentType,
      payments: fullEmployees,
      competencyDate: moment(competence).format('YYYY-MM-DD'),
      depositAccountOrigin: accountSelected?.origin,
    };

    api
      .post(
        `employeesPayments/drafts/${currentCompanyCode}`,
        body,
        requestOptions,
      )
      .then(({ data }) => {
        if (data.skippedInvalidDeposits && continueProcess) {
          setOpenSkippedInvalidModal(true);
          return;
        }
        confirmAndToSchedule(data.fileEncodedKey);
        sessionStorage.setItem('importFileData', JSON.stringify(data));
        sessionStorage.setItem(
          'paymentDescription',
          JSON.stringify({
            paymentTypeDescription:
              paymentTypeDescription || currentPaymentType,
          }),
        );
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorTitle(errors[0].errorDetail);
          setErrorMessage(errors[0].errorsDescription);
          setErrorModal(true);
          setIsLoading(false);
        },
      );
  }

  function confirmAndToSchedule(fileEncodedKey) {
    const requestOptions = {
      headers: {
        Authorization: authToken,
        pixProvider: currentCompanyData.pixProvider,
      },
    };
    api
      .post(`/files/employeespayments/confirmAppointment/${fileEncodedKey}`,
        '',
        requestOptions
      )
      .then(({ data }) => {
        sessionStorage.setItem('importFileNsu', data.nsu);
        setIsBlocking(false);

        if (
          hasMoreThanOneDepositAccount &&
          accountSelected?.encodedKey !== depositAccount
        ) {
          setShowSuccessModal(prevState => !prevState);
        } else {
          history.push('/payroll/success');
        }
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorTitle(errors[0].errorDetail);
          setErrorMessage(errors[0].errorsDescription);
          setErrorModal(true);
          setIsLoading(false);
        },
      )
      .finally(() => setIsLoading(false));
  }

  function handleChangeCpfOrName(event) {
    setCpfOrName(event.target.value);
  }

  function handleOpenModalEmployeesList() {
    setSelected([]);
    setOpenEmployeesListModal(true);
  }

  function onBackClicked() {
    history.push('/payroll');
  }

  function handleContinueProcessModal(continueValue) {
    if (continueValue) {
      sendEmployeesForDraft(false);
    } else {
      setOpenSkippedInvalidModal(false);
      setIsLoading(false);
    }
  }

  function onCloseSuccessModal() {
    setShowSuccessModal(prevState => !prevState);
    history.push('/payroll');
  }

  function textSuccessModal() {
    return (
      <Typography>
        Troque para conta{' '}
        <span className={styles.contrastText}>{depositAccountIdSelected} </span>
        referente ao seu novo pagamento para aprová-la.
      </Typography>
    );
  }

  if (isMobile) {
    return <CommingSoon />;
  }

  return (
    <>
      <Prompt
        when={employees.length >= 1000 ? false : isBlocking}
        message="Você tem certeza que deseja cancelar a criação desta folha?"
      />

      <Grid container direction="column">
        <Grid item>
          <Header />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <InfoCard
              currentPaymentType={currentPaymentType}
              fileName={fileName}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              setPaymentTypeDescription={setPaymentTypeDescription}
              date={date}
              setDate={setDate}
              selectedAvailablePaymentHour={selectedAvailablePaymentHour}
              setSelectedAvailablePaymentHour={setSelectedAvailablePaymentHour}
              availablePaymentHours={availablePaymentHours}
              competence={competence}
              setCompetence={setCompetence}
              accounts={accounts}
              hasMoreThanOneDepositAccount={hasMoreThanOneDepositAccount}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ValuesCard
              totalAmount={totalAmount}
              paymentsAmount={paymentsAmount}
              feesAmount={feesAmount}
              loadingRegenerate={loadingRegenerate}
              hasMoreThanOneDepositAccount={hasMoreThanOneDepositAccount}
            />
          </Grid>
        </Grid>

        <Grid container justify="flex-end">
          <Grid item>
            <Box mt={2} mb={-1}>
              <Button
                className={styles.addEmployeesButton}
                onClick={handleOpenModalEmployeesList}
              >
                <AddRoundedIcon className={styles.addEmployeesButtonIcon} />
                Incluir colaboradores
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid item>
          <Table
            nsu={nsu}
            employees={employees}
            setEmployees={setEmployees}
            totalAmount={totalAmount}
            paymentsAmount={paymentsAmount}
          />
        </Grid>

        <EmployeesListModal
          open={openEmployeesListModal}
          onCancelClicked={() => setOpenEmployeesListModal(false)}
          onConfirmClicked={includeEmployees}
          handleChangeCpfOrName={handleChangeCpfOrName}
          cpfOrName={cpfOrName}
          setCpfOrName={setCpfOrName}
          currentCompanyCode={currentCompanyCode}
          selected={selected}
          setSelected={setSelected}
          setIsSelectedAll={setIsSelectedAll}
          isSelectedAll={isSelectedAll}
          ignoredEmployees={ignoredEmployees}
          setIgnoredEmployees={setIgnoredEmployees}
          setOpenEmployeesListModal={setOpenEmployeesListModal}
          employees={employees}
          setEmployees={setEmployees}
          isLimitModal={isLimitModal}
          setIsLimitModal={setIsLimitModal}
        />

        <FiredEmployeesModal
          title="Atencão!"
          text="Você selecionou funcionários que estão com status de demitido.
          Quer continuar mesmo assim com o pagamento?"
          open={openFiredEmployeesModal}
          confirmButtonText="Sim, continuar"
          cancelButtonText="Não"
          onCancelButtonClicked={() => setOpenFiredEmployeesModal(false)}
          onConfirmClicked={confirmIncludeEmployees}
        />

        <SkippedInvalidModal
          confirmModal={openSkippedInvalidModal}
          onContinueProcess={() => handleContinueProcessModal(true)}
          onDontContinueProcess={() => handleContinueProcessModal(false)}
        />

        <ProgressToolBar
          orangeNextButton
          nextButtonText="Confirmar e agendar"
          onNextClicked={sendEmployeesForDraft}
          disabled={
            DISABLED_NEXT_BUTTON ||
            moment(date).format('DD/MM/YYYY HH:mm') === 'Data inválida'
          }
          onBackClicked={onBackClicked}
          loading={isLoading}
          placementTooltip="top"
          titleTooltip={
            moment(date).format('DD/MM/YYYY HH:mm') === 'Data inválida'
              ? 'Data consta inválida.'
              : 'Antes de confirmar, é preciso definir o mês de competência.'
          }
        />

        <SuccessModal
          open={showSuccessModal}
          onCloseDialog={onCloseSuccessModal}
          title="Pagamento regerado com sucesso!"
          text={textSuccessModal()}
        />

        <ErrorModal
          open={errorModal}
          onConfirmClicked={() => {
            setErrorModal(false);
          }}
          title={errorTitle}
          text={errorMessage}
          confirmButtonText="Entendi"
        />
      </Grid>
    </>
  );
}
