import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Paper, Button, ListItem, ListItemText } from '@material-ui/core';

import styles from './styles.module.css';

export default function FakeList() {
  return (
    <>
      <div className={styles.card}>
        <Paper className={styles.notBorderCard}>
          <Button className={styles.buttonStyle}>
            <ListItem>
              <ListItemText
                primary={
                  <Skeleton
                    width={400}
                    height={23}
                    color="#202020"
                    highlightColor="#fff"
                  />
                }
                secondary={
                  <Skeleton
                    width={900}
                    height={23}
                    color="#202020"
                    highlightColor="#fff"
                  />
                }
              />
            </ListItem>
          </Button>
        </Paper>
      </div>

      <div className={styles.card}>
        <Paper className={styles.notBorderCard}>
          <Button className={styles.buttonStyle}>
            <ListItem>
              <ListItemText
                primary={
                  <Skeleton
                    width={400}
                    height={23}
                    color="#202020"
                    highlightColor="#fff"
                  />
                }
                secondary={
                  <Skeleton
                    width={900}
                    height={23}
                    color="#202020"
                    highlightColor="#fff"
                  />
                }
              />
            </ListItem>
          </Button>
        </Paper>
      </div>
    </>
  );
}
