import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Header from './UI/Header/Header';
import { useProcessResult } from './utils/hooks';
import FileInfo from './UI/FileInfo';
import ProgressToolBar from '../../../../../components/ProgressToolBar';
import SuccessAccordion from './UI/SuccessAccordion';
import ErrorAccordion from './UI/ErrorAccordion';
import { confirmFileProccess } from './utils/services';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

export const ConsignedMarginsBatchConfirmContext = createContext({});

function ConsignedMarginsBatchConfirm() {
  const history = useHistory();

  const { processId } = useParams();

  const { data, isLoading } = useProcessResult(processId);

  const [isSubmitting, toggleSubmitting] = useReducer(dirt => !dirt, false);

  const nextButtonLabel = useMemo(() => {
    if (!data) return '';

    return data.validationErrorLines > 0 ? 'Reenviar arquivo' : 'Confirmar';
  });

  const goBack = useCallback(() => {
    history.replace('/consigned/margins/batch');
  }, [history]);

  const onNext = useCallback(async () => {
    if (!data) return;

    if (data.validationErrorLines > 0) {
      goBack();
      return;
    }

    toggleSubmitting();

    try {
      await confirmFileProccess(processId);

      history.replace(`/consigned/margins/batch/${processId}/result`);
    } catch (err) {
      const { errorDetail } = err.response.data?.errors[0];

      handleSnackbarStack().error(errorDetail);
    }

    toggleSubmitting();
  }, [data, processId, history, goBack]);

  return (
    <ConsignedMarginsBatchConfirmContext.Provider value={{ data, isLoading }}>
      <Header />
      <FileInfo />
      <SuccessAccordion />
      <ErrorAccordion />
      <ProgressToolBar
        nextButtonText={nextButtonLabel}
        backButtonText="Voltar"
        onBackClicked={goBack}
        onNextClicked={onNext}
        disabled={isSubmitting || isLoading}
        loading={isSubmitting || isLoading}
      />
      <Box sx={{ height: 56 }} />
    </ConsignedMarginsBatchConfirmContext.Provider>
  );
}

export default ConsignedMarginsBatchConfirm;
