import formatCpf from '../../utils/formatCPF.js';

const initialState = {
  count: 0,
  insuranceSituation: 'Vigente',
  insuranceType: 'Seguro de Vida',
  cpfOrName: '',
  singleInsurance: '',
  cpf: null,
};

export default function insuranceReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_INSURANCE_COUNT':
      return {
        ...state,
        count: action.count,
      };
    case 'SET_INSURANCE_SITUATION':
      return {
        ...state,
        insuranceSituation: action.insuranceSituation,
      };
    case 'SET_INSURANCE_TYPE':
      return {
        ...state,
        insuranceType: action.insuranceType,
      };
    case 'SET_SINGLE_INSURANCE_CPF':
      return {
        ...state,
        cpf: formatCpf(action.cpf),
      };
    case 'SET_CPF_OR_NAME':
      return {
        ...state,
        cpfOrName: action.cpfOrName,
      };
    case 'SET_SINGLE_INSURANCE':
      return {
        ...state,
        singleInsurance: action.singleInsurance,
      };
    default:
      return state;
  }
}
