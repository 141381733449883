import React from 'react';
import {
	Button,
	Box,
	Typography,
	Dialog,
	CircularProgress
} from '@material-ui/core';

import styles from './styles.module.css';

function Modal({
	open,
	onCloseDialog,
	title,
	text,
	closeButtonText,
	image,
	nextButton,
	onNextButtonClicked,
	nextButtonText,
	outlinedButton,
	outlinedButtonClicked,
	onClose,
	titleMarginTop,
	titleMarginBottom,
	loading
}) {
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<div className={styles.container}>
			<Box mt={titleMarginTop} mb={titleMarginBottom}>
				<Typography className={styles.title}>{title}</Typography>
			</Box>

			<Typography
				className={styles.text}
				style={{ display: text ? '' : 'none' }}
			>
				{text}
			</Typography>

			<div
				style={{ marginTop: text ? '' : '30px' }}
				className={styles.buttonsGroup}
			>
				<Button
					disabled={loading}
					onClick={outlinedButton ? outlinedButtonClicked : onCloseDialog}
					className={
						outlinedButton ? styles.outlinedButton : styles.closeButton
					}
					>
					{closeButtonText || 'Fechar'}
					</Button>

					{nextButton && (
					<Button onClick={onNextButtonClicked} className={styles.nextButton} disabled={loading}>
						{loading ? (
						<CircularProgress className={styles.loader} />
						) : (
						<Typography className={styles.confirmButtonText}>
							{nextButtonText}
						</Typography>
						)}
					</Button>
					)}
				</div>
			</div>
		</Dialog>
	);
}

export default Modal;
