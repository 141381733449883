import React from 'react';

import { Box, TextField, InputAdornment, IconButton } from '@material-ui/core';

import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { Table } from '../../../../../../../../../components/Table';
import InsertBankDataDrawer from '../InsertBankDataDrawer';

import { useFilters } from './Hooks/useFilters';
import { useEmployeesReproved } from './Hooks/useEmployeesReproved';
import { useTableConfig } from './Hooks/useTableConfig';

import styles from '../../../../../styles.module.css';

export default function EmployeesReproved() {
  const { nameOrCpfFieldObject, textFieldFocusedObject } = useFilters();
  const { table, disableSelection, handleSelection } = useEmployeesReproved();

  const { tableConfig } = useTableConfig();

  return (
    <>
      <Box mb={3}>
        <TextField
          fullWidth
          value={nameOrCpfFieldObject.nameOrCpf}
          onChange={event =>
            nameOrCpfFieldObject.setNameOrCpf(event.target.value)
          }
          className={styles.employeesListModalSearchInput}
          variant="outlined"
          size="small"
          placeholder="Pesquise por nome ou CPF"
          onFocus={() =>
            setTimeout(
              () => textFieldFocusedObject.setTextFieldFocused('nameOrCpf'),
              200,
            )
          }
          onBlur={() =>
            setTimeout(
              () => textFieldFocusedObject.setTextFieldFocused(''),
              200,
            )
          }
          InputProps={{
            style: {
              borderRadius: 6,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: nameOrCpfFieldObject.nameOrCpf?.length > 0 &&
              textFieldFocusedObject.textFieldFocused === 'nameOrCpf' && (
                <IconButton
                  className={styles.endAdornment}
                  onClick={() => nameOrCpfFieldObject.setNameOrCpf('')}
                >
                  <CloseRoundedIcon className={styles.endAdornmentIcon} />
                </IconButton>
              ),
          }}
        />
      </Box>

      <Table
        {...table}
        tableConfig={tableConfig}
        selectable
        selectObject
        canSelectAll
        isHandleLocalData
        handleSelection={handleSelection}
        disableSelection={disableSelection}
      />

      <InsertBankDataDrawer />
    </>
  );
}
