import React from 'react';
import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Title from '../../../../components/Title';

import styles from './styles.module.css';

function Header({ onClick }) {
  return (
    <div className={styles.header}>
      <Title>Colaboradores</Title>
      <div className={styles.buttonPadding}>
        <Button
          variant="contained"
          className={styles.buttonMoreEmployes}
          startIcon={<AddIcon />}
          onClick={onClick}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <Typography className={styles.buttonTitle} display="inline">
            Incluir funcionário(s)
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default Header;
