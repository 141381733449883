import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import List from './List';
import ProgressToolBar from '../../../../components/ProgressToolBar';

import styles from './styles.module.css';

export default () => {
  const history = useHistory();
  const [fileTypeSelected, setFileTypeSelected] = useState(0);
  const fileTypeList = ['xls'];

  const goToFileImportScreen = () => {
    history.push('/limit-query/menu/info-send-file');
  };

  const backToConsultLimit = () => {
    history.goBack('/limit-query');
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.pageTitle}>
        Alteração de limite via arquivo
      </Typography>
      <div className={styles.choicesContainer}>
        <Typography className={styles.pageSubtitle}>
          Escolha o tipo de arquivo
        </Typography>
        <List
          types={fileTypeList}
          selectedRadio={fileTypeSelected}
          setSelectedRadio={setFileTypeSelected}
        />
      </div>
      <ProgressToolBar
        nextButtonText="Continuar"
        onNextClicked={goToFileImportScreen}
        onBackClicked={backToConsultLimit}
      />
    </div>
  );
};
