import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CreateOutlined, InfoOutlined } from '@material-ui/icons';
import NiceModal from '@ebay/nice-modal-react';
import truncateString from '../../../../../utils/truncateString';
import formatCPF from '../../../../../utils/formatCPF';

export function useTableConfig() {
  const situationsEnum = {
    PENDING: 'Pendente',
    REJECTED: 'Rejeitado',
    DEFINITIVELY_REPROVED: 'Reprovado Definitivamente',
  };

  function handleOpenInfoDrawer() {
    NiceModal.show('employees-situation-info');
  }

  function handleOpenBankDataModal(row) {
    NiceModal.show('handle-employee-bank-data', {
      rowEmployeeData: {
        id: row?.id,
        employeeName: row?.name,
        employeeCpf: formatCPF(row?.cpf),
        employeeType: 'REPROVED',
        bank: row?.bank ? parseInt(row?.bank) : '',
        accountType: row?.accountType ?? '',
        agency: row?.agency ?? '',
        agencyDigit: row?.agencyDigit ?? '',
        account: row?.account ?? '',
        accountDigit: row?.accountDigit ?? '',
        hasBankData: row?.hasBankData,
      },
    });
  }

  return [
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      render: row => (
        <Tooltip arrow title={row?.name}>
          <span>{truncateString(row?.name, 40)}</span>
        </Tooltip>
      ),
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
    },
    {
      key: 'hasBankData',
      type: 'field',
      label: 'Dados bancários',
      render: row => (
        <Box
          sx={{
            color: row.hasBankData ? '#19797F' : '#E54217',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '0.2rem',
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: '0.6rem' }} />
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {row.hasBankData ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ),
    },
    {
      key: 'situationType',
      type: 'field',
      label: 'Situação',
      customLabel: () => (
        <IconButton
          color="primary"
          onClick={handleOpenInfoDrawer}
          style={{ width: '1rem', height: '1rem' }}
        >
          <InfoOutlined style={{ fontSize: '1.2rem' }} />
        </IconButton>
      ),
      render: row => situationsEnum[row?.situationType] || '-',
    },
    {
      key: 'actionDetails',
      type: 'action',
      align: 'center',
      render: row => (
        <IconButton
          color="primary"
          onClick={() => handleOpenBankDataModal(row)}
          disabled={row?.situationType === 'REJECTED'}
        >
          <CreateOutlined />
        </IconButton>
      ),
    },
  ];
}
