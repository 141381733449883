import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

export function EnhancedAccordion({
  title = '',
  header = null,
  isExpanded = false,
  isDisabled = false,
  handleExpand = () => {},
  accordionStyle = {},
  accordionSummaryStyle = {},
  accordionDetailsStyle = {},
  children,
}) {
  return (
    <Accordion
      disableGutters
      square
      expanded={isExpanded}
      disabled={isDisabled}
      onChange={handleExpand}
      style={{
        borderRadius: '0.5rem',
        boxShadow:
          '0px 3px 1px -2px rgba(59, 53, 65, 0.2), 0px 2px 2px rgba(59, 53, 65, 0.14), 0px 1px 5px rgba(59, 53, 65, 0.12)',
        ...accordionStyle,
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        style={{
          backgroundColor: 'var(--white)',
          height: 'fit-content',
          border: '1px solid #E5E5E5',
          borderRadius: '0.5rem',
          padding: '0 1.5rem',
          ...accordionSummaryStyle,
        }}
        classes={{
          root: makeStyles({ root: { margin: 0 } })().root,
        }}
      >
        {header || (
          <Typography
            variant="subtitle2"
            style={{
              color: 'var(--black)',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails style={{ padding: '1.5rem', ...accordionDetailsStyle }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
