import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Buttons from '../../../../../../components/Buttons';
import Form from '../../../../../../components/Form';

import currencyFormatter from '../../../../../../utils/currencyFormatter';

import validations from './validations';

import useStyles from '../styles';

const ReproveModal = NiceModal.create(({ selected, onSubmit }) => {
  const modal = useModal();
  const styles = useStyles();

  const { isSending } = useSelector(state => state.cashOut);

  const {
    control,
    formState: { isValid },
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues: {
      reason: '',
    },
  });

  const handleFormSubmit = (form, event) => {
    event.preventDefault();
    onSubmit(form);
    reset();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <Form.FormBase onSubmit={() => handleSubmit(handleFormSubmit)}>
        <DialogTitle className={styles.title}>
          Reprovar solicitação de{' '}
          <b className={styles.valueText}>
            {currencyFormatter(selected.amount)}
          </b>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <DialogContentText className={styles.contentText}>
            Informe o motivo para reprovar essa solicitação
          </DialogContentText>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <Form.TextField
                className={styles.textArea}
                label="Motivo"
                multiline
                minRows={4}
                maxRows={4}
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Buttons.SecondaryButton
            variant="text"
            color="primary"
            title="CANCELAR"
            onClick={modal.remove}
          />
          <Buttons.PrimaryButton
            color="secondary"
            title="REPROVAR SOLICITAÇÃO"
            type="submit"
            loading={isSending}
            disabled={!isValid}
          />
        </DialogActions>
      </Form.FormBase>
    </Dialog>
  );
});

export default ReproveModal;
