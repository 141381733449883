import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import Modals from '../../../../../../../components/Modals';
import Form from '../../../../../../../components/Form';
import CardTools from '../../../../../../../components/CardTools';
import Buttons from '../../../../../../../components/Buttons';

import formatCPF from '../../../../../../../utils/formatCPF';
import { useHandleEmployeeBankData } from './hooks/useHandleEmployeeBankData';

export const HandleEmployeeBankData = NiceModal.create(
  ({ employee = {}, bankList = [], employeeType = '' }) => {
    const { modal, handleCloseModal, form } = useHandleEmployeeBankData({
      employee,
      employeeType,
    });

    return (
      <Modals.DefaultModal
        hasBottomBar={false}
        open={modal.visible}
        onClose={handleCloseModal}
        title="Dados bancários do funcionário"
      >
        <Form.FormBase onSubmit={form.handleSubmitBankData}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <CardTools.CardInfo title="Nome" description={employee?.name} />
              <CardTools.CardInfo
                title="CPF"
                description={formatCPF(employee?.cpf)}
              />
            </Box>

            <Divider orientation="horizontal" />

            <Grid container direction={{ sm: 'column', md: 'row' }} spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="bank"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.AutocompleteField
                      label="Banco*"
                      options={bankList}
                      getOptionLabel={e => e?.name}
                      getOptionValue={e => e?.code}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="accountType"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.SelectField
                      label="Tipo de conta*"
                      options={form.accountTypeOptions}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="agency"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.NumericField
                      label="Agência*"
                      precision={5}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="agencyDigit"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.NumericField
                      label="Dígito da agência*"
                      precision={2}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="account"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.NumericField
                      label="Número da conta*"
                      precision={12}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="accountDigit"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.NumericField
                      label="Dígito da conta*"
                      precision={2}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: '1rem',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              }}
            >
              <Buttons.SecondaryButton
                variant={
                  useMediaQuery(useTheme().breakpoints.down('sm'))
                    ? 'outlined'
                    : 'text'
                }
                title="Cancelar"
                onClick={handleCloseModal}
              />

              <Buttons.PrimaryButton
                disabled={!form.canSubmit}
                type="submit"
                title="Confirmar"
                loading={form.isLoading}
              />
            </Box>
          </Box>
        </Form.FormBase>
      </Modals.DefaultModal>
    );
  },
);
