import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BusinessRounded,
  MailOutlineRounded,
  SmartphoneRounded,
  VpnKeyOutlined,
} from '@material-ui/icons';

import {
  useGetKeyValidationMutation,
  useCreateNewPixKeyMutation,
  useGetKeysListQuery,
} from '../../../../redux/store/Pix/pix.api';
import { PixStatusActions as Actions } from '../../../../redux/store/Pix';

import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

import validations from '../validations';

export default function useNewPixKey({ styles }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, success } = handleSnackbarStack();

  const companyCnpj = useSelector(state => state.companies.currentCompany.cnpj);
  const isValidating = useSelector(state => state.pixStatus.isValidating);

  const [keyFieldState, setKeyFieldState] = useState({
    disabled: false,
    mask: '00.000.000/0000-00',
  });

  const { data: keysList } = useGetKeysListQuery({ companyCnpj });
  const [handleKeyValidation] = useGetKeyValidationMutation();
  const [handleCreateKey] = useCreateNewPixKeyMutation();

  const KEYS = [
    {
      label: 'CNPJ',
      value: 'CNPJ',
      icon: <BusinessRounded color="primary" className={styles.icon} />,
    },
    {
      label: 'Celular',
      value: 'PHONE',
      icon: <SmartphoneRounded color="primary" className={styles.icon} />,
    },
    {
      label: 'E-mail',
      value: 'EMAIL',
      icon: <MailOutlineRounded color="primary" className={styles.icon} />,
    },
    {
      label: 'Aleatória',
      value: 'EVP',
      icon: <VpnKeyOutlined color="primary" className={styles.icon} />,
    },
  ];

  const {
    control,
    handleSubmit,
    getFieldState,
    register,
    formState,
    setValue,
    getValues,
    resetField,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validations),
    defaultValues: {
      keyType: '',
      key: '',
    },
  });

  register('keyType');
  register('key');

  const { isDirty: hasValidKeyType } = getFieldState('keyType', formState);

  const handleCloseModal = () => {
    NiceModal.remove('confirm-unique-key-modal');
    NiceModal.remove('confirm-common-key-modal');
    dispatch(Actions.clearError());
  };

  const onSubmit = async form => {
    await handleCreateKey(form)
      .unwrap()
      .then(() => {
        success('Chave cadastrada com sucesso!');
        handleCloseModal();
        setTimeout(() => {
          history.push('/pix/keys');
        }, 2000);
      });
  };

  const onChangeKeyType = value => {
    switch (value) {
      case 'CNPJ':
        setKeyFieldState({
          disabled: true,
          mask: '00.000.000/0000-00',
        });

        setValue('key', companyCnpj);
        break;

      case 'PHONE':
        setKeyFieldState({
          disabled: false,
          mask: '(00) 00000-0000',
        });
        resetField('key');
        break;

      case 'EMAIL':
        setKeyFieldState({
          disabled: false,
          mask: /^\S*@?\S*$/,
        });
        resetField('key');
        break;

      case 'EVP':
        setKeyFieldState({
          disabled: false,
          mask: () => null,
        });
        resetField('key');
        break;
    }
  };

  const onValidate = async () => {
    const { key, keyType } = getValues();

    const form = {
      key,
      type: keyType,
      companyCnpj,
    };

    await handleKeyValidation(form)
      .unwrap()
      .then(({ messageId }) => {
        if (['CNPJ', 'EVP'].includes(keyType)) {
          const submitKey = keyType === 'CNPJ' ? companyCnpj : '';

          NiceModal.show('confirm-common-key-modal', {
            onSubmit: () => onSubmit({ ...form, key: submitKey, messageId }),
          });
          return;
        }

        NiceModal.show('confirm-unique-key-modal', {
          keyValue: key,
          keyType,
          onSubmit: modalForm => onSubmit({ ...form, ...modalForm, messageId }),
          onResendPin: () => handleKeyValidation(form),
        });
      })
      .catch(err => {
        error(err);
      });
  };

  const onBack = () => {
    history.push('/pix/keys');
  };

  const existentTypes = useMemo(() => {
    if (keysList) {
      return [
        ...new Set(
          keysList?.map(({ type }) => {
            return type !== 'EVP' ? type : null;
          }),
        ),
      ];
    }

    return [];
  }, [keysList]);

  return {
    form: {
      control,
      errors: formState.errors,
      isValid: formState.isValid,
      isValidating,
      isRandomKey: getValues('keyType') === 'EVP',
      hasValidKeyType,
      onBack,
      onChangeKeyType,
      onSubmit: () => handleSubmit(onSubmit),
      onValidate,
    },
    keyFieldState,
    keys: KEYS,
    existentTypes,
  };
}
