export default {
  EXTRA_SMALL_SCROLL: 0,
  SMALL_SCROLL: 54,
  MEDIUM_SCROLL: 143,
  LARGE_SCROLL: 329,
  EXTRA_LARGE_SCROLL: 415,
  EXTRA_SMALL_OFFSET: 986,
  SMALL_OFFSET: 1028,
  MEDIUM_OFFSET: 1117,
  SCROLL_PERCENTAGE: 19,
  PERCENTAGE: 100,
};
