import { api } from '../../../services/api';

export default class CostAndSectorCenterServices {
  static getStockingCenterData({
    companyCodeValue,
    requestOptions,
    setStates,
  }) {
    api
      .get(`companies/${companyCodeValue}/stockings/search`, requestOptions)
      .then(({ data }) => {
        setStates(data);
      })
      .catch(error => {
        return error;
      });
  }

  static getLocationsCenterData({
    companyCodeValue,
    requestOptions,
    setStates,
  }) {
    api
      .get(`companies/${companyCodeValue}/locations/search`, requestOptions)
      .then(({ data }) => {
        setStates(data);
      })
      .catch(error => {
        return error;
      });
  }

  static registerNewSector({
    companyCodeValue,
    requestOptions,
    setStates,
    body,
    setError,
  }) {
    api
      .post(`companies/${companyCodeValue}/stockings`, body, requestOptions)
      .then(({ data }) => {
        setStates(data);
      })
      .catch(err => setError(err));
  }

  static registerLocationCenter({
    companyCodeValue,
    setStates,
    body,
    setError,
  }) {
    api
      .post(`companies/${companyCodeValue}/locations`, body)
      .then(({ data }) => {
        setStates(data);
      })
      .catch(err => setError(err));
  }

  static changeLocationCenter({
    companyCodeValue,
    setStates,
    body,
    codeCenter,
    setError,
  }) {
    api
      .put(`companies/${companyCodeValue}/locations/${codeCenter}`, body)
      .then(({ data }) => {
        setStates(data);
      })
      .catch(err => setError(err));
  }

  static changeStockingCenter({
    companyCodeValue,
    setStates,
    body,
    codeCenter,
    setError,
  }) {
    api
      .put(`companies/${companyCodeValue}/stockings/${codeCenter}`, body)
      .then(({ data }) => {
        setStates(data);
      })
      .catch(err => setError(err));
  }
}
