import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import LottiesScreen from '../../../../../LottiesScreen';

export function Error({ config = [] }) {
  return (
    <TableRow tabIndex={-1}>
      <TableCell colSpan={config.length} align="center">
        <Box sx={{ p: '2rem 1rem' }}>
          <LottiesScreen.TableError />
          <Typography variant="h5" style={{ mt: '1.5rem', fontWeight: '700' }}>
            {' '}
            Houve um erro inesperado
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
