const initialState = {
  data: [],
  paymentType: '',
  uploadCanceled: false
};

export default function importFiles(state = initialState, action) {
  switch (action.type) {
    case 'SET_IMPORT_FILE':
      return { ...state, data: action.data };
    case 'SET_IMPORT_FILE_PAYMENT':
      return { ...state, paymentType: action.paymentTypeDescription };
    case 'UPLOAD_CANCELED':
      return { ...state, uploadCanceled: action.uploadCanceled };
    default:
      return state;
  }
}
