import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      display: 'grid',
      gap: theme.spacing(1),
      gridTemplate: '1fr auto 1fr auto / 1fr',
      minHeight: 0,
      minWidth: 0,

      '& > *': {
        minWidth: 0,
      },

      [theme.breakpoints.up('sm')]: {
        gridTemplate: '1fr auto / minmax(0, 1fr) auto minmax(0, 1fr)',

        '& > *': {
          overflow: 'hidden',
        },
      },
    },
    introContainer: {
      alignSelf: 'center',
    },
    title: {
      fontSize: '1.125rem',
      color: theme.palette.text.primary,
      fontWeight: 700,
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
    },
    introText: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '90%',
        fontSize: '1rem',
        margin: `${theme.spacing(2)}px 0`,
      },
    },
    codeContainer: {
      backgroundColor: '#F6F3F9',
      padding: theme.spacing(2),
      borderRadius: '6px',

      '& .MuiCollapse-root': {
        textWrap: 'wrap',
        wordWrap: 'break-word',
        transitionProperty: 'all',
      },
    },
    codeIntro: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    codeIntroTexts: {
      width: '80%',
    },
    qrLabel: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    codeText: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.primary.main,
      textWrap: 'wrap',
    },

    codeTitle: {
      fontSize: '1.125rem',
      color: theme.palette.primary.main,
      fontWeight: 500,
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
    },
    actions: {
      [theme.breakpoints.up('sm')]: {
        gridColumn: '1 / span 3',
      },
    },
  }),
);
