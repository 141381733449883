import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

function FakeRowTable() {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={100} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            style={{ marginRight: 10 }}
            highlightColor="#444"
          />
          <Skeleton
            circle
            height={30}
            width={30}
            color="#202020"
            highlightColor="#444"
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default FakeRowTable;
