import React from 'react';

import RegisterCardIcon from '@material-ui/icons/GroupAdd';
import PersonalRegisterCardIcon from '@material-ui/icons/PersonAddRounded';
import TedCardIcon from '@material-ui/icons/Receipt';
import ManualPaymentCardIcon from '@material-ui/icons/ListRounded';
import PaymentCardIcon from '@material-ui/icons/DescriptionRounded';

import styles from './styles.module.css';

export const actions = [
  {
    icon: <RegisterCardIcon className={styles.iconColor} />,
    name: 'Cadastro via arquivo',
    onClick: '/employees/register-batch/menu',
    value: 'FILE_REGISTRATION',
  },
  {
    icon: <PersonalRegisterCardIcon className={styles.iconColor} />,
    name: 'Cadastro individual',
    onClick: '/employees/register-single',
    value: 'INDIVIDUAL_REGISTRATION',
  },
  {
    icon: <TedCardIcon className={styles.iconColor} />,
    name: 'Registro de transferência',
    onClick: [
      '/cash-in/inform-transfer-Deposit',
      {
        byScreen: true,
      },
    ],
    value: 'TRANSFER',
  },
  {
    icon: <ManualPaymentCardIcon className={styles.iconColor} />,
    name: 'Pagamento manual',
    onClick: '/payroll/manual-payroll',
    value: 'MANUAL_PAYMENT',
  },
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'Pagamento via arquivo',
    onClick: '/payroll/menu',
    value: 'FILE_PAYMENT',
  },
];
