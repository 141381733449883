import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsManualPayroll as Actions } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

export function useEmployeesAproved({ account = {} }) {
  const dispatch = useDispatch();

  const { employeesAproved, previewSelected } = useSelector(
    state => state.payroll.manualPayroll,
  );

  const handlePaginationRequests = (page, perPage) => {
    dispatch(Actions.setApprovedPagination({ page, perPage }));
  };

  const handleSelectEmployees = useCallback(
    employees => {
      dispatch(Actions.setPreviewSelected(employees));
    },
    [dispatch],
  );

  const disableSelection = row =>
    account.origin === 'FACIL_SCD' ? !row.existScdAccount : !row.existIpAccount;

  return {
    handleSelectEmployees,
    table: {
      data: employeesAproved?.data,
      selectedItems: previewSelected,
      length: employeesAproved?.currentLength,
      isLoading: employeesAproved.isLoading,
      hasError: employeesAproved.errorOn.hasError,
      requests: handlePaginationRequests,
      disableSelection,
    },
  };
}
