import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    container: {
      gap: '1rem',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: '1.25rem',
    },
    item: {
      fontWeight: 600,
      fontSize: '1rem',
      color: '#000',
    },
    keyText: {
      fontWeight: 600,
      fontSize: '1rem',
      color: '#000',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineBreak: 'anywhere',
    },
    label: {
      fontSize: '1rem',
      fontWeight: 500,
      marginBottom: '0.5rem',
      color: '#808080',
    },
    data: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#000',
    },
  }),
);
