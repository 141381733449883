import React from 'react';

import { Typography, Button, Radio } from '@material-ui/core';

import styles from './styles.module.css';

export default ({ types, selectedRadio, setSelectedRadio }) => {
  return (
    <div className={styles.choices}>
      {types.map((type, index) => (
        <div
          className={selectedRadio === index ? styles.cardChecked : styles.card}
        >
          <Button
            className={styles.choiceButton}
            onClick={() => setSelectedRadio(index)}
          >
            <Radio className={styles.radio} checked={selectedRadio === index} />
            <div className={styles.cardDataContainer}>
              <Typography className={styles.cardTitle}>
                Alterar via planilha do Excel (.xls)
              </Typography>
              <Typography className={styles.cardDescription}>
                Preencha os dados em uma planilha e envie o arquivo na
                plataforma.
              </Typography>
            </div>
          </Button>
        </div>
      ))}
    </div>
  );
};
