import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    successContainer: {
      margin: '2.5rem auto',
      width: '60%',
      flexFlow: 'row nowrap',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      gap: '2.5rem',
    },
    successIcon: {
      width: '100% !important',
      height: '100% !important',
    },
    successTitle: {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: '#1A1A1A',
    },
    successDescription: {
      fontSize: '1.125rem',
      color: '#4D4D4D',
      fontWeight: 500,
    },
    successWarning: {
      display: 'inline-block',
      marginTop: '1rem',
      fontSize: '1.125rem',
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    root: {
      padding: '1.5rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
      marginBottom: '5rem',
    },
    container: {
      gap: '1rem',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    keyText: {
      fontWeight: 700,
      fontSize: '1rem',
      color: '#000',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineBreak: 'anywhere',
    },
    finalValueText: {
      fontWeight: 700,
      fontSize: '1.5rem',
      color: '#000',
    },
    label: {
      display: 'inline-block',
      width: '100%',
      fontSize: '1rem',
      fontWeight: 500,
      color: '#808080',
    },
    codeBox: {
      padding: '1.25rem 2.5rem',
      backgroundColor: '#F6F3F9',
      borderRadius: '6px',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    codeText: {
      display: 'flex',
      flexFlow: 'column nowrap',
      maxWidth: '50%',
    },
    codeNumber: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    copyButton: {
      height: 'fit-content',
    },
    editDateText: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '6px',
    },
    addButton: {
      padding: 0,
      fontSize: '1rem',
    },
  }),
);
