import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsManualPayroll } from '../../../../../../redux/store/Payroll/ManualPayroll';

export function useEmployeesList() {
  const dispatch = useDispatch();
  const {
    selectedRowsEmployees,
    employeeBankData,
    isOpenInsertBankDataDialog,
    selectedEmployeeToInsertBankData,
  } = useSelector(state => state.payroll.manualPayroll);

  function handleCloseSuccessSnackbar() {
    dispatch(ActionsManualPayroll.resetSuccessStates());
  }

  function handleResetSelectedEmployees() {
    dispatch(ActionsManualPayroll.setSelectedRowsEmployees([]));
    sessionStorage.setItem('selectedEmployees', JSON.stringify([]));
  }

  useEffect(() => {
    if (isOpenInsertBankDataDialog) {
      dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDialog(false));
    }

    if (selectedEmployeeToInsertBankData) {
      dispatch(ActionsManualPayroll.setSelectedEmployeeToInsertBankData({}));
    }

    dispatch(ActionsManualPayroll.searchEmployees());
    dispatch(ActionsManualPayroll.searchEmployeesPending());
    dispatch(ActionsManualPayroll.searchEmployeesReproved());
    dispatch(ActionsManualPayroll.getCostAndCenter());
    dispatch(ActionsManualPayroll.getBanksList());
  }, []);

  return {
    selectedRowsEmployees,
    employeeBankData,
    handleCloseSuccessSnackbar,
    handleResetSelectedEmployees,
  };
}
