import React, { useState } from 'react';
import styles from '../styles.module.css';
import {
  Table,
  TableContainer,
  TablePagination,
  Paper
} from '@material-ui/core';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TableSearch from './TableSearch';

function TableDetails({ tableData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableHeadData = [
    'Nome do segurado',
    'CPF',
    'Situação',
  ]

  const createData = (id, name, cpf, situation) => {
    return { id, name, cpf, situation };
  }

  const rows = tableData.map((item) => (
    createData(
      item.id,
      item.name,
      item.cpf,
      item.situation
    )
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={styles.table}>
      <TableSearch />
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead
            tableHeadData={tableHeadData}
          />
          <TableBody
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default TableDetails;
