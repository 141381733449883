import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useDebounce from '../../../../../../../../../../hooks/useDebounce';

import { ActionsManualPayroll as Actions } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

export function useFilters() {
  const dispatch = useDispatch();

  const [nameOrCpf, setNameOrCpf] = useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const nameOrCpfDebounced = useDebounce(nameOrCpf, 500);

  useEffect(() => {
    dispatch(Actions.setFilterEmployeesReproved({ nameOrCpf }));
    dispatch(Actions.searchSimpleReprovedList());
  }, [nameOrCpfDebounced]);

  return {
    nameOrCpfFieldObject: {
      nameOrCpf,
      setNameOrCpf,
    },
    textFieldFocusedObject: {
      textFieldFocused,
      setTextFieldFocused,
    },
  };
}
