import React from 'react';
import { Grid } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

import Form from '../../../../../../components/Form';

export default function ActivitiesTab() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Form.TextField
          size="medium"
          placeholder="Atividades"
          InputProps={{
            startAdornment: <SearchOutlined style={{ marginRight: '8px' }} />,
          }}
        />
      </Grid>
    </Grid>
  );
}
