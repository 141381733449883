import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
} from '@material-ui/core';

import { Header } from '../../../components/Header';
import Form from '../../../components/Form';
import ReviewStep from './components/ReviewStep';
import CashOutTransferBottomBar from './components/BottomBar';

import useCashoutTransfer from './hooks/useCashoutTransfer';
import { useStyles } from './styles';

export default function CashOutTransfer() {
  const styles = useStyles();

  const {
    control,
    currentStep,
    isValid,
    isSending,
    onConsult,
    onSubmit,
    onBack,
    errors,
    meta: { steps, mask },
  } = useCashoutTransfer();

  const getStepContent = () =>
    currentStep === 0 ? (
      <>
        <Box>
          <Form.FormBase onSubmit={onConsult}>
            <Typography variant="h2" className={styles.title}>
              Insira os dados para a transferência abaixo:
            </Typography>
            <Grid container spacing={2} className={styles.form}>
              <Grid item xs={6}>
                <Controller
                  name="cnpj"
                  control={control}
                  render={({ field }) => (
                    <Form.MaskedField
                      size="medium"
                      className={styles.codeField}
                      label="CNPJ*"
                      mask={mask}
                      error={Boolean(errors?.cnpj)}
                      helperText={errors?.cnpj?.message}
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Form.CurrencyField
                      size="medium"
                      label="Valor*"
                      error={Boolean(errors?.amount)}
                      helperText={errors?.amount?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Form.TextField
                      size="medium"
                      label="Descrição"
                      error={Boolean(errors?.description)}
                      helperText={errors?.description?.message}
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography variant="label" className={styles.label}>
              Obrigatório*
            </Typography>
            <CashOutTransferBottomBar
              disabled={!isValid}
              isLoading={isSending}
              onBack={onBack}
            />
          </Form.FormBase>
        </Box>
      </>
    ) : (
      <ReviewStep isLoading={isSending} onBack={onBack} onNext={onSubmit} />
    );

  return (
    <>
      <Header
        title={
          currentStep === 0
            ? 'Nova solicitação de transferência'
            : 'Confirme os dados da solicitação de transferência'
        }
      />
      <Box>
        <Stepper activeStep={currentStep} className={styles.stepper}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box className={styles.root}>{getStepContent()}</Box>
    </>
  );
}
