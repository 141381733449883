import React, { createContext, useState, useContext } from 'react';

const SnackbarContext = createContext();
const DEFAULT_DURATION = 6000;
const defaultSnackbar = {
  open: false,
  message: '',
  severity: '',
  onClose: () => null,
  autoHideDuration: DEFAULT_DURATION,
  width: 300,
  background: '',
};

export default function SnackbarProvider({ children }) {
  const [snackbar, setSnackbarProps] = useState(defaultSnackbar);

  function applyConfig(config) {
    setSnackbarProps(config);
    setTimeout(() => {
      setSnackbarProps({ ...config, open: false });
    }, config.autoHideDuration | DEFAULT_DURATION);
  }

  return (
    <SnackbarContext.Provider value={{ snackbar, applyConfig }}>
      {children}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (!context)
    throw new Error('useSnackbar must be used within a SnackbarContext');

  const { snackbar, applyConfig } = context;
  return { snackbar, applyConfig };
}
