import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';

import animation from '../../lotties/comingSoon.json';
import styles from './styles.module.css';

const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

export default function ComingSoon({ animation, label, text, onClick }) {
    const history = useHistory();

    function goBack() {
        sessionStorage.removeItem('useTopBar');
        history.push(onClick || '/');
    }

    return (
        <div className={styles.container}>
            <div>
                {animation || (
                    <Lottie
                        options={animationOptions}
                        width={150}
                        height={150}
                    />
                )}
                <Typography className={styles.label}>
                    {label || 'Tá chegando...'}
                </Typography>
                <Typography className={styles.text}>
                    {text || 'Em breve teremos essa funcionalidade também no celular.'}
                </Typography>
            </div>

            <Button 
                className={styles.button} 
                onClick={goBack}
            >
                Ir para o início
            </Button>
        </div>
    )
}
