import React from 'react';
import { Box } from '@material-ui/core';

export default function TabLabel({
  title,
  countEmployees,
  color,
  backgroundColor,
}) {
  return (
    <Box display="flex" alignItems="center">
      {title}
      <Box
        sx={{
          ml: 2,
          padding: '0 0.375rem',
          color,
          backgroundColor,
          borderRadius: '0.375rem',
          transition: 'all 200ms ease-in-out',
          fontWeight: 700,
        }}
      >
        {countEmployees}
      </Box>
    </Box>
  );
}
