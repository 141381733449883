import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: '#FFF',
    },
    filters: {
      marginBottom: '0.5rem',
    },
    menu: {
      boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.1)',
    },
    menuItem: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconButton: {
      padding: '0.5rem',
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    approvalChip: {
      backgroundColor: 'rgba(227, 159, 23, 0.1) !important',
      color: '#E39F17 !important',
      border: 'none !important',
    },
  }),
);

export default useStyles;
