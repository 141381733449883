import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  useGetKeysListQuery,
  useDeletePixKeyMutation,
} from '../../../redux/store/Pix/pix.api';
import { PixStatusActions as Actions } from '../../../redux/store/Pix';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

export default function usePixKeys() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { success, error } = handleSnackbarStack();

  const companyCnpj = useSelector(state => state.companies.currentCompany.cnpj);
  const { hasError, message } = useSelector(state => {
    const { hasError, message } = state.pixStatus;
    return { hasError, message };
  });

  const { data, isFetching } = useGetKeysListQuery({
    companyCnpj,
  });

  const [
    handleDeletePixKey,
    { isLoading: isLoadingKeyDeletion },
  ] = useDeletePixKeyMutation();

  const handleCloseModal = () => {
    NiceModal.remove('delete-key-modal');
    dispatch(Actions.clearError());
  };

  const handleDeletion = async ({ key, type }) => {
    await handleDeletePixKey({ key, type, companyCnpj })
      .unwrap()
      .then(() => {
        success('Chave excluída com sucesso!');
        setTimeout(() => handleCloseModal(), 2000);
      });
  };

  const onDeleteKey = ({ key, type }) => {
    NiceModal.show('delete-key-modal', {
      onClose: handleCloseModal,
      onDelete: () => handleDeletion({ key, type }),
      isLoading: isLoadingKeyDeletion,
    });
  };

  const goToNewKeyPage = () => {
    if (data?.length === 20) return;

    history.push('/pix/keys/new');

    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Nova chave Pix',
        step: 0,
        route: '/pix/keys',
      }),
    );
  };

  useEffect(() => {
    if (hasError) error(message);
  }, [hasError, message]);

  return {
    data,
    isDisabled: data?.length === 20,
    isFetching,
    onDeleteKey,
    goToNewKeyPage,
  };
}
