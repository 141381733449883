import React from 'react';
import moment from 'moment';

import { Typography } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import ResponsiveFake from './ResponsiveFakeCards';

import Status from '../UI/Status';

import styles from './styles.module.css';
import CustomSnackbar from '../../../../components/Snackbar';

function ResponsiveCard({
  status,
  fileName,
  paymentDate,
  nsu,
  creationUserName,
  approvalUserName,
  paymentType,
  totalValue,
  paymentValidate,
  totalPayroll,
  fees,
  expressPayment,
  importDate,
  criationDate,
  criationTime,
  authorizationDate,
  authorizationTime,
  loading,
  scheduleDate,
  expressPaymentUserName,
  expressPaymentDate,
  depositAccount,
  isMoreThanOneAccount,
  snackbarSeverity,
  isSnackbarActive,
  snackbarMessage,
}) {
  return (
    <>
      {loading ? (
        <ResponsiveFake isMoreThanOneAccount={isMoreThanOneAccount} />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.row}>
              <Status status={status} />
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>NSU</Typography>
                <Typography className={styles.textSmall}>{nsu}</Typography>
              </div>

              {isMoreThanOneAccount && (
                <div className={styles.block}>
                  <Typography className={styles.label}>Conta</Typography>
                  <Typography className={styles.textSmall}>
                    {depositAccount}
                  </Typography>
                </div>
              )}

              <div className={styles.block}>
                <Typography className={styles.label}>Tipo</Typography>
                <Typography className={styles.textSmall}>
                  {paymentType}
                </Typography>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Agendada para</Typography>
                <Typography className={styles.textSmall}>
                  {moment(scheduleDate)?.format('DD/MM/YYYY')} às{' '}
                  {moment(scheduleDate).format('LT')}
                </Typography>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Arquivo</Typography>
                <Typography className={styles.textSmall}>{fileName}</Typography>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Criação</Typography>
                <Typography className={styles.textSmall}>
                  {creationUserName}
                </Typography>
              </div>

              <div className={styles.block}>
                <Typography className={styles.label}>Autorização</Typography>
                <Typography className={styles.textSmall}>
                  {authorizationDate}
                </Typography>
              </div>

              {expressPaymentUserName && expressPaymentDate && (
                <div className={styles.block}>
                  <Typography className={styles.label}>
                    Liberação imediata
                  </Typography>
                  <Typography className={styles.textSmall}>
                    {moment(expressPaymentDate).format('DD/MM/YYYY')} às{' '}
                    {moment(expressPaymentDate).format('LT')}
                  </Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.rowTitle}>
              <Typography className={styles.title}>Pagamento</Typography>

              {paymentDate ? (
                <Typography className={styles.status}>
                  <CheckCircleOutlineRoundedIcon
                    className={styles.paymentDateIcon}
                  />
                  {moment(paymentDate).format('DD/MM/YYYY')}
                </Typography>
              ) : (
                <Typography className={styles.waitingStatus}>
                  <QueryBuilderRoundedIcon
                    className={styles.paymentDateIconwaitingStatus}
                  />
                  Aguardando
                </Typography>
              )}
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Total</Typography>
                <Typography className={styles.textBigger}>
                  {totalValue}
                </Typography>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.block}>
                <Typography className={styles.label}>Folha</Typography>
                <Typography className={styles.textSmall}>
                  {totalPayroll}
                </Typography>
              </div>

              <div className={styles.block}>
                <Typography className={styles.label}>Tarifa</Typography>
                <Typography className={styles.textSmall}>{fees}</Typography>
              </div>

              <div className={styles.block}>
                <Typography className={styles.label}>L. imediata</Typography>
                <Typography className={styles.textSmall}>
                  {expressPayment}
                </Typography>
              </div>
            </div>
            <CustomSnackbar
              open={isSnackbarActive}
              severity={snackbarSeverity}
              message={snackbarMessage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ResponsiveCard;
