import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Tooltip,
  CircularProgress,
  TableFooter
} from '@material-ui/core'

import { ReactComponent as EyesIcon } from '../../../../assets/eyesIcon.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/removeItem.svg';
import { ReactComponent as FileReviewIcon } from '../../../../assets/file-search-outlined.svg';

import styles from './styles.module.css';
import FakeRowsTable from './FakeRowsTable';

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.labelHead}>
          Identificador
        </TableCell>
        <TableCell className={styles.labelHead}>
          Competência
        </TableCell>
        <TableCell className={styles.labelHead}>
          Tipo
        </TableCell>
        <TableCell className={styles.labelHead}>
          Situação
        </TableCell>
        <TableCell className={styles.labelHead} align="center">
          Funcionários
        </TableCell>
        <TableCell className={styles.labelHead} align="center" />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  data,
	loading,
	totalSize,
	pageSize,
	currentPage,
	handlePageChange,
	handleRowsPerPageChange,
	TablePaginationActions,
  goToDetails,
  goToReview,
  handleRemovePaycheck,
  loadingRemovePaycheck,
  selectedPaycheck,
}) {

  return (
    <Paper className={styles.tableContainer}>
      <div>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              rowCount={data.length}
            />
            <TableBody>
              {loading ? (FakeRowsTable()):
                data?.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.cpf}
                    >
                      <TableCell className={styles.tableCell}>{row.id}</TableCell>
                      <TableCell className={styles.tableCell}>{row.competency}</TableCell>
                      <TableCell className={styles.tableCell}>{row.paymentType}</TableCell>
                      <TableCell className={styles.tableCell}>{row.statusDescription}</TableCell>
                      <TableCell align="center" className={styles.tableCell}>{row.paycheckCount}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Visualizar detalhes">
                          <IconButton
                              style={row.canToDetail ? {} : {display: 'none'}}
                              disabled={!row.canToDetail}
                              onClick={() => goToDetails(row)}>
                            <EyesIcon className={styles.fileIcon} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Revisar arquivo">
                          <IconButton
                              style={row.canToReview ? {} : {display: 'none'}}
                              disabled={!row.canToReview}
                              onClick={() => goToReview(row)}>
                            <FileReviewIcon/>
                          </IconButton>
                        </Tooltip>

                        {selectedPaycheck?.id !== row.id &&
                          <Tooltip title="Remover arquivo">
                            <IconButton
                                style={row.canToRemove ? {} : {visibility: 'hidden'}}
                                disabled={!row.canToRemove || loadingRemovePaycheck}
                                onClick={() => handleRemovePaycheck(row)}>
                              <RemoveIcon/>
                            </IconButton>
                          </Tooltip>
                        }

                        {loadingRemovePaycheck && selectedPaycheck?.id === row.id &&
                          <CircularProgress className={styles.circularLoading} />
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalSize}
                  rowsPerPage={pageSize}
                  page={currentPage}
                  labelRowsPerPage="linhas por página"
                  labelDisplayedRows={
                    function defaultLabelDisplayedRows({ from, to, count }) {
                      return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
                    }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
}
