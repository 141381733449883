import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: '1.25rem',
      padding: '1.25rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
    },
    accordion: {
      margin: '1rem 0',
    },
    accordionSummary: {
      height: 'fit-content !important',
    },
    accordionSummaryWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: '1rem',
    },
    accordionTitle: {
      color: 'black',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    accordionSubtitle: {
      color: 'rgba(27, 24, 28, 0.5)',
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    accordionAlert: {
      color: theme.palette.error.main,
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    accordionActions: {
      padding: '1rem 0',
    },
    details: {
      '&:.MuiAccordionDetails-root': {
        padding: '0.75rem',
      },
    },
    form: {
      padding: '0.75rem',
      width: '100%',
    },
    deletePermission: {
      color: theme.palette.error.main,
    },
    ipTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
    ipModalTitle: {
      color: 'black',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    ipModalBody: {
      color: 'rgba(27, 24, 28, 0.5)',
      fontSize: '1rem',
      fontWeight: 400,
    },
    ipModalActions: {
      padding: '1rem',
    },
  }),
);
