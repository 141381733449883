import React from 'react';
import {
  Checkbox as CheckboxControlled,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export function Checkbox({
  name = '',
  label = '',
  disabled = false,
  checked = false,
  indeterminate = false,
  onChange = () => {},
  defaultChecked = false,
  size = 'medium',
  color = 'primary',
  ...otherProps
}) {
  return (
    <FormControlLabel
      name={name}
      label={label}
      disabled={disabled}
      control={
        <CheckboxControlled
          name={name}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          defaultChecked={defaultChecked}
          size={size}
          color={color}
          classes={{
            indeterminate: makeStyles({ indeterminate: { color: '#52197F' } })()
              .indeterminate,
          }}
          {...otherProps}
        />
      }
      style={{ margin: !label ? 0 : 'default' }}
    />
  );
}
