import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import styles from './styles.module.css';

import FakeRowTable from './FakeRowTable';

import { ReactComponent as Icon } from '../../../assets/locations-empty.svg';

import ResultNotFound from '../../../components/ResultNotFound';
import EmptyList from '../../../components/EmptyList';

export default function SelectableTable({
  loading,
  data,
  changedFilter,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
  keyName,
  titleOfItem,
  handleEditCoastCenterAndSectors,
  handleCostCenterAndSectorDeleteModal,
  value,
}) {
  if (data?.content.length === 0 && !changedFilter) {
    return (
      <EmptyList
        icon={<Icon />}
        title="Seus itens de Centro de Custo e Setor serão mostrados aqui!"
        description="Para incluir um item novo, basta clicar no botão “Incluir”."
      />
    );
  }

  return (
    <>
      {data?.content?.length === 0 && changedFilter ? (
        <ResultNotFound
          text="Nenhum Resultado Encontrado"
          alternativeText="Verifique os dados pesquisados e tente novamente."
        />
      ) : (
        <Paper className={styles.gridStyle}>
          <Table>
            <TableHead>
              <TableRow hover className={styles.tableStyle}>
                <TableCell className={styles.titleTable}>Código</TableCell>
                <TableCell className={styles.titleTable}>
                  {titleOfItem}
                </TableCell>
                <TableCell className={styles.titleTable} />
              </TableRow>
            </TableHead>

            <TableBody>
              {!loading && data ? (
                data[keyName].map((item, index) => (
                  <TableRow hover>
                    <TableCell className={styles.itemsTable}>
                      {item.code.toUpperCase()}
                    </TableCell>
                    <TableCell className={styles.itemsTable}>
                      {item.description.toUpperCase()}
                    </TableCell>
                    <TableCell className={styles.iconTable} align="right">
                      <IconButton
                        onClick={() => handleEditCoastCenterAndSectors(item)}
                      >
                        <Tooltip title="Editar">
                          <EditOutlinedIcon className={styles.colorIconTable} />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleCostCenterAndSectorDeleteModal(
                            item,
                            titleOfItem,
                          )
                        }
                      >
                        <Tooltip title="Remover">
                          <DeleteOutlinedIcon
                            className={styles.colorIconTable}
                          />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <FakeRowTable />
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  count={data?.totalSize}
                  rowsPerPage={data?.currentPageSize}
                  page={data?.currentPage}
                  labelRowsPerPage="Linhas por página"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      )}
    </>
  );
}
