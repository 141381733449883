import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import { Search } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import OrangeCheckbox from '../../../../../../../components/Checkbox/OrangeCheckbox';
import FakeList from '../../../../../../../components/FakeList';
import ResultNotFound from '../../../../../../../components/ResultNotFound';

import { useFilters } from './Hooks/useFilters';
import { useEmployeesAproved } from './Hooks/useEmployeesAproved';
import { useEmployeesAprovedSituations } from './Hooks/useEmployeesAprovedSituations';

import textCapitalize from '../../../../../../../utils/textCapitalize';
import styles from '../../../../styles.module.css';
import { ChipStatus } from '../../../../../../../components/ChipStatus';

export default function EmployeesAproved() {
  const { nameOrCpfFieldObject, handleClearFilters } = useFilters();
  const {
    cardListObject,
    selectedRowsEmployees,
    isEmployeeSelected,
    handleSelectEmployee,
    handleSelectAllEmployeesAproved,
    handleDeselectAllEmployeesAproved,
    handleMoreSearchEmployeesAprovedData,
  } = useEmployeesAproved();

  const {
    handleEmployeeSituation,
    handleEmployeeSituationIcon,
  } = useEmployeesAprovedSituations();

  return (
    <>
      <Box className={styles.filters}>
        <TextField
          className={styles.nameOrCpf}
          value={nameOrCpfFieldObject.nameOrCpf}
          onChange={event =>
            nameOrCpfFieldObject.setNameOrCpf(event.target.value)
          }
          placeholder="Pesquise por nome ou CPF"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: nameOrCpfFieldObject.nameOrCpf?.length > 0 && (
              <IconButton
                aria-label="toggle password visibility"
                className={styles.endAdornment}
                onClick={handleClearFilters}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            ),
          }}
        />
      </Box>

      <Box>
        {cardListObject.isLoading && !cardListObject.hasData ? (
          <FakeList />
        ) : cardListObject.length === 0 ? (
          <ResultNotFound text="Nenhum resultado encontrado" />
        ) : (
          <InfiniteScroll
            dataLength={cardListObject.data.length}
            next={handleMoreSearchEmployeesAprovedData}
            hasMore={cardListObject.hasMoreData}
            height={570}
            loader={
              cardListObject.hasMoreData && (
                <Box className={styles.circularProgressContainer}>
                  <CircularProgress color="primary" size={24} />
                </Box>
              )
            }
            endMessage={
              <Box className={styles.containerEndMessage}>
                <Typography>Mostrando todos os colaboradores</Typography>
              </Box>
            }
          >
            {cardListObject?.data?.map((item, index) => {
              return (
                <Box className={styles.card} key={item.encodedKey}>
                  <Box>
                    <Typography className={styles.text}>
                      {textCapitalize(item.name)}
                    </Typography>

                    <Typography className={styles.text}>{item.cpf}</Typography>

                    <ChipStatus
                      icon={handleEmployeeSituationIcon(
                        item?.companies[0]?.status,
                      )}
                      status={handleEmployeeSituation(
                        item?.companies[0]?.status,
                      )}
                      label={item?.companies[0]?.statusDescription}
                      size="medium"
                    />
                  </Box>

                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => handleSelectEmployee(item)}
                            value={JSON.stringify(item)}
                            className={styles.checkbox}
                            checked={isEmployeeSelected(item?.cpf)}
                          />
                        }
                      />
                    </FormGroup>
                  </Box>
                </Box>
              );
            })}
          </InfiniteScroll>
        )}
      </Box>
    </>
  );
}
