import React from 'react';
import Lottie from 'react-lottie';

import animation from '../../../lotties/table-error.json';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function TableError(props) {
  return <Lottie options={animationOptions} width={214} height={127} />;
}

export default TableError;
