import React, { useEffect, useState } from 'react';

import { Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import Header from './Header';
import ModalAddAccount from './AddAccount';
import ModalEditAccount from './EditAccount';
import Filters from './Filters';
import Cards from './Cards';

import RemoveAccountModal from '../../components/PrimaryModal';
import ResultNotFound from '../../components/ResultNotFound';
import LogoLoading from '../../components/LogoLoading';

import { api } from '../../services/api';

import { ReactComponent as DollarSign } from '../../assets/dollar-sign.svg';

import { isMobile } from '../../utils/breakpoints';

function BankAccounts() {
  const [accountsList, setAccountsList] = useState([]);

  const [openModalRemoveAccount, setOpenModalRemoveAccount] = useState(false);
  const [openModalAddAccount, setOpenModalAddAccount] = useState(false);
  const [openModalEditAccount, setOpenModalEditAccount] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [changedFilter, setChangedFilter] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [accountValue, setAccountValue] = useState('');
  const [agencyValue, setAgencyValue] = useState('');
  const [bankValue, setBankValue] = useState('');
  const [bankCodeValue, setBankCodeValue] = useState('');
  const [cpfCnpjValue, setCpfCnpjValue] = useState('');
  const [optionalName, setOptionalName] = useState('');
  const [checkDigit, setCheckDigit] = useState('');
  const [bank, setBank] = useState('');
  const [search, setSearch] = useState('');

  const [idAccount, setIdAccount] = useState(0);

  const COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  function Alert(props) {
    return (
      <MuiAlert
        autoHideDuration={5000}
        elevation={6}
        variant="filled"
        {...props}
      />
    );
  }

  useEffect(() => {
    getAccountsList();
  }, [bank]);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        getAccountsList();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [search]);

  useEffect(() => {
    if (search) {
      setChangedFilter(true);
    }
  }, [search]);

  function handleAddAccount() {
    setOpenModalAddAccount(true);
  }

  function handleConfirm() {
    setSearch('');
    setBank('');
    setOpenModalAddAccount(false);
    setShowAlert(true);
    setAlertMessage('Conta incluída com sucesso.');
    getAccountsList();
    setChangedFilter(true);
  }

  function confirmRemoveAccount(item) {
    setOpenModalRemoveAccount(true);
    setIdAccount(item.id);
  }

  function handleRemove() {
    setLoadingButton(true);
    api
      .delete(`/accountCashIn/bank/${idAccount}`)
      .then(handleRemoveThen)
      .finally(() => setLoadingButton(false));
  }

  function handleRemoveThen() {
    setOpenModalRemoveAccount(false);
    setAlertMessage('Conta excluída com sucesso.');
    setShowAlert(true);
    getAccountsList();
    setChangedFilter(true);
  }

  function confirmEditAccount(item) {
    setOpenModalEditAccount(true);
    setIdAccount(item.id);
    setAccountValue(item.account);
    setAgencyValue(item.agency);
    setBankValue(item.bankName);
    setBankCodeValue(item.bankCode);
    setCpfCnpjValue(item.cpfCnpj);
    setOptionalName(item.name);
    setCheckDigit(item.checkDigit);
  }

  function handleEdit() {
    setOpenModalEditAccount(false);
    setAlertMessage('Conta editada com sucesso.');
    setShowAlert(true);
    getAccountsList();
    setChangedFilter(true);
  }

  function getAccountsList() {
    setLoading(true);

    const requestOptions = {
      params: {
        nameOrAccount: search,
        bankCode: bank,
      },
    };
    api
      .get(`/accountCashIn/${COMPANY_CODE}`, requestOptions)
      .then(({ data }) => {
        setAccountsList(data.content);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading && !changedFilter) {
    return <LogoLoading />;
  }

  return (
    <Grid>
      <Header handleAddAccount={handleAddAccount} isMobile={isMobile} />

      {accountsList.length === 0 ? (
        changedFilter ? (
          <>
            <Filters
              getAccountsList={getAccountsList}
              accountsList={accountsList}
              bank={bank}
              setBank={setBank}
              search={search}
              setSearch={setSearch}
              changedFilter={changedFilter}
              setChangedFilter={setChangedFilter}
            />
            <ResultNotFound
              text="Nenhum Resultado Encontrado"
              alternativeText="Verifique o nome ou número da conta pesquisados e tente novamente."
              buttonText="Adicionar Nova Conta"
              onClick={handleAddAccount}
            />
          </>
        ) : (
          <ResultNotFound
            text="A lista de suas contas aparecerá aqui."
            alternativeText="Cadastre agora suas contas e agilize seus depósitos por transferência."
            buttonText="Adicionar Nova Conta"
            onClick={handleAddAccount}
            icon={<DollarSign />}
          />
        )
      ) : (
        <>
          <Filters
            getAccountsList={getAccountsList}
            accountsList={accountsList}
            bank={bank}
            setBank={setBank}
            search={search}
            setSearch={setSearch}
            changedFilter={changedFilter}
            setChangedFilter={setChangedFilter}
          />

          <Cards
            accountsList={accountsList}
            confirmEditAccount={confirmEditAccount}
            confirmRemoveAccount={confirmRemoveAccount}
            loading={loading && changedFilter}
          />
        </>
      )}

      <RemoveAccountModal
        open={openModalRemoveAccount}
        onCancelButtonClicked={() => setOpenModalRemoveAccount(false)}
        onConfirmClicked={handleRemove}
        loading={loadingButton}
        cancelButtonText="Cancelar"
        confirmButtonText="Remover"
        title="Remover esta conta?"
        text="Esta ação não poderá ser desfeita."
      />

      <ModalAddAccount
        open={openModalAddAccount}
        onClose={() => setOpenModalAddAccount(false)}
        handleConfirm={handleConfirm}
      />

      <ModalEditAccount
        open={openModalEditAccount}
        onClose={() => setOpenModalEditAccount(false)}
        handleEdit={handleEdit}
        loading={loadingButton}
        idAccount={idAccount}
        accountValue={() => accountValue.slice(0, accountValue.indexOf('-'))}
        agencyValue={agencyValue}
        bankValue={bankValue}
        bankCodeValue={bankCodeValue}
        cpfCnpjValue={cpfCnpjValue}
        optionalName={optionalName}
        checkDigitValue={checkDigit}
      />

      <Snackbar open={showAlert}>
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default BankAccounts;
