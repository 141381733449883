import { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import NiceModal from '@ebay/nice-modal-react';
import { unwrapResult } from '@reduxjs/toolkit';

import { UserManagementActions as Actions } from '../../../../../../../redux/store/UserManagement';

import { handleSnackbarStack } from '../../../../../../../utils/handleSnackbarStack';
import { pusher } from '../../../../../../../utils/pusher';

const CPF = sessionStorage.getItem('cpf');

export default function useUsersTab() {
  const dispatch = useDispatch();
  const { error, success, info } = handleSnackbarStack();

  const { users, isFetchingUsers } = useSelector(state => state.userManagement);

  const [currentView, setCurrentView] = useState('table');

  const handleViewChange = (_, value) => setCurrentView(value);

  const { control, handleSubmit } = useForm({
    mode: `onChange`,
    initialValues: {
      nameOrCpf: '',
    },
  });

  const onSubmitSearch = form => {
    dispatch(Actions.setFilters(form));
    dispatch(Actions.getUsersList());
  };

  const onChangePage = (page, perPage) => {
    dispatch(
      Actions.changePage({
        page,
        perPage,
      }),
    );
    dispatch(Actions.getUsersList());
  };

  const onOpenUserModal = (user, situation) => {
    dispatch(Actions.setSelectedUser({ ...user, situation }));
    sessionStorage.setItem(
      'selectedUser',
      JSON.stringify({ ...user, situation }),
    );

    try {
      unwrapResult(dispatch(Actions.getFaceAuth({ cpf: CPF })));
      NiceModal.show('block-modal');
    } catch (err) {
      error(err.error);
    }
  };

  const onSearch = useCallback(
    debounce(() => handleSubmit(onSubmitSearch)(), 500),
    [],
  );

  const onChangeSituation = () => {
    const selectedUser = JSON.parse(sessionStorage.getItem('selectedUser'));

    const hasPendingOnboarding =
      selectedUser?.situation === 'ACTIVE' &&
      selectedUser?.cafStatus === 'SENT_TO_CAF';

    const actionResult = dispatch(Actions.setUserSituation());

    try {
      unwrapResult(actionResult);
      success('Situação do usuário alterada com sucesso!');

      if (hasPendingOnboarding) {
        info(
          'Este usuário ainda não foi aprovado pela CAF. O onboarding será solicitado para acesso a esse usuário.',
        );
      }
    } catch (err) {
      error(err.error);
    } finally {
      onSubmitSearch();
    }
  };

  useEffect(() => {
    if (!CPF) return;

    const channel = pusher.subscribe(CPF);

    channel.bind('CAF_WEBHOOK_RESPONSE', ({ data }) => {
      if (data.isMatch) {
        success('Tudo certo! Agradecemos por confirmar sua identidade.');
        NiceModal.hide('block-modal');
        onChangeSituation();
      } else {
        error(
          'Ocorreu um erro na sua autenticação por foto. Por favor, tente novamente em alguns instantes.',
        );
      }
    });

    return () => {
      pusher.unsubscribe(CPF);
    };
  }, [CPF]);

  return {
    control,
    onSearch,
    onChangePage,
    isFetchingUsers,
    users,
    currentView,
    handleViewChange,
    onOpenUserModal,
  };
}
