import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from '../../styles.module.css';

export function GeneralDataContent({ label, content, loading }) {
  return (
    <Box
      display="flex"
      justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{ gap: '0.5rem' }}
    >
      <Typography variant="subtitle2" className={styles.label}>
        {label}
      </Typography>
      <Typography variant="subtitle2" className={styles.content}>
        {loading ? (
          <Skeleton variant="text" animantion="wave" width={150} />
        ) : (
          content ?? '-'
        )}
      </Typography>
    </Box>
  );
}
