import React, { useCallback, useReducer, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Dialog,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';

import styles from './styles.module.css';
import brazilianCurrencyFormatter from '../../../../../../../utils/brazilianCurrencyFormatter';
import formatOnlyNumbers from '../../../../../../../utils/formatOnlyNumbers';
import { handleSnackbarStack } from '../../../../../../../utils/handleSnackbarStack';
import {
  calculateDefaultMargin,
  createSingleEmployeeMargin,
} from '../../../utils/services';
import formatCPF from '../../../../../../../utils/formatCPF';

function ManualInsertionModal({ open, onClose, onUpdate }) {
  const [isLoading, toggleLoading] = useReducer(old => !old, false);

  const [step, setStep] = useState(0);

  const [cpf, setCpf] = useState('');
  const [salary, setSalary] = useState();
  const [margin, setMargin] = useState(0);

  const [maxMargin, setMaxMargin] = useState();

  const errorCurrencyValue = useCallback(
    currency => {
      if (!maxMargin) return;

      if (currency > parseFloat(maxMargin)) {
        return (
          <Typography className={styles.currencyError}>
            {`Digite um valor no máximo até ${brazilianCurrencyFormatter(
              maxMargin,
            )}`}
          </Typography>
        );
      }
    },
    [maxMargin],
  );

  const handleInputChange = useCallback(
    input => e => {
      const { value } = e.target;

      switch (input) {
        case 'salary':
          setSalary(formatOnlyNumbers(value) / 100);
          return;
        case 'margin':
          setMargin(formatOnlyNumbers(value) / 100);
          return;
        case 'cpf':
          setCpf(formatOnlyNumbers(value));
      }
    },
    [],
  );

  const handleMarginCalculate = useCallback(async () => {
    toggleLoading();

    try {
      const { data } = await calculateDefaultMargin({
        salary,
      });

      setMaxMargin(data);
      setMargin(data);

      setStep(1);
    } catch {
      handleSnackbarStack().error('Não foi possível retornar a margem');
    }

    toggleLoading();
  }, [salary]);

  const handleInclude = useCallback(async () => {
    toggleLoading();

    try {
      await createSingleEmployeeMargin({
        cpf,
        salary,
        assignableMargin: margin,
      });

      setMaxMargin(undefined);
      setMargin(0);
      setSalary(0);
      setStep(0);
      setCpf('');

      handleSnackbarStack().success('Margem incluída com sucesso');

      onClose();
      onUpdate();
    } catch (err) {
      const { errorDetail } = err.response.data?.errors[0];

      handleSnackbarStack().error(errorDetail);
    }

    toggleLoading();
  }, [salary, margin, cpf]);

  const handleSubmit = useCallback(() => {
    switch (step) {
      case 0:
        handleMarginCalculate();
        break;
      case 1:
        handleInclude();
        break;
    }
  }, [step, handleMarginCalculate, handleInclude]);

  const resetMargin = () => {
    setStep(0);
    setCpf('');
    setSalary(undefined);
    setMaxMargin(undefined);
  };

  const handleClose = () => {
    resetMargin();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <div className={styles.container}>
        <Box mt={0}>
          <Typography className={styles.title}>
            Incluir margem consignável
          </Typography>
        </Box>

        <Stepper nonLinear activeStep={step} className={styles.stepper}>
          <Step completed={!!maxMargin}>
            <StepButton color="inherit" onClick={resetMargin}>
              CPF e Salário
            </StepButton>
          </Step>
          <Step completed={false}>
            <StepButton color="inherit">Margem</StepButton>
          </Step>
        </Stepper>

        {step === 0 && (
          <FormControl>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <TextField
                  variant="outlined"
                  placeholder="CPF"
                  value={formatCPF(cpf)}
                  onChange={handleInputChange('cpf')}
                  inputProps={{
                    maxLength: 14,
                    minLength: 9,
                  }}
                  disabled={isLoading}
                  required
                  fullWidth
                  label="CPF"
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  variant="outlined"
                  placeholder="Salário"
                  value={brazilianCurrencyFormatter(salary || 0)}
                  onChange={handleInputChange('salary')}
                  inputProps={{
                    maxLength: 13,
                    minLength: 9,
                  }}
                  disabled={isLoading}
                  required
                  fullWidth
                  label="Salário"
                />
              </Grid>
            </Grid>
          </FormControl>
        )}

        {step === 1 && (
          <FormControl>
            <TextField
              variant="outlined"
              placeholder="R$ 0,00"
              value={brazilianCurrencyFormatter(margin)}
              onChange={handleInputChange('margin')}
              inputProps={{
                maxLength: 13,
                minLength: 9,
              }}
              disabled={isLoading}
              required
              fullWidth
              label="Margem consignável"
            />
            {errorCurrencyValue(margin)}
          </FormControl>
        )}

        <div className={styles.buttonsGroup}>
          <Button
            disabled={isLoading}
            onClick={handleClose}
            className={styles.closeButton}
          >
            Cancelar
          </Button>

          <Button
            onClick={handleSubmit}
            className={styles.nextButton}
            disabled={isLoading || (!!maxMargin && margin > maxMargin)}
          >
            {isLoading ? (
              <CircularProgress className={styles.loader} />
            ) : (
              <Typography className={styles.confirmButtonText}>
                {step === 0 ? 'Calcular' : 'Incluir'}
              </Typography>
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ManualInsertionModal;
