import { reduxApi } from '../../../../services/reduxApi';

const pendingEmployeesTags = {
  tag: 'PENDING_EMPLOYEES',
  listAbstractTag: { type: 'PENDING_EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [pendingEmployeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      searchPendingEmployees: builder.query({
        query: ({ params = {}, filter = {}, companyCode = '' }) => ({
          url: `/employees/pending/${companyCode}`,
          method: 'POST',
          params,
          body: filter,
        }),
        providesTags: [pendingEmployeesTags.listAbstractTag],
      }),

      updateEmployeeBankData: builder.mutation({
        query: body => ({
          url: '/employees/bankData',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [pendingEmployeesTags.tag],
      }),
    }),
  });

export const {
  useSearchPendingEmployeesQuery,
  useGetBankListQuery,
  useUpdateEmployeeBankDataMutation,
} = slice;
