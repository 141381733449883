import { Button, Grid, Hidden } from '@material-ui/core';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.css';

import { isMobile } from '../../utils/breakpoints';

function FakeCards() {
  return (
    <Grid className={styles.cards}>
      <div className={styles.cardAccount}>
        <div className={styles.containerBankAndEditRemove}>
          <Skeleton
            width={isMobile ? 280 : 400}
            height={17}
            color="#202020"
            highlightColor="#444"
          />
          <Skeleton
            style={{ marginLeft: '2px' }}
            width={isMobile ? 280 : 400}
            height={17}
            color="#202020"
            highlightColor="#444"
          />

          <div className={styles.agency}>
            <Skeleton
              style={{ marginLeft: '2px' }}
              width={180}
              height={17}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <Hidden smDown>
          <div className={styles.containerEditRemove}>
            <Button className={styles.editButton}>
              <Skeleton
                className={styles.editIcon}
                circle
                width={16}
                height={17}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                width={60}
                height={17}
                color="#202020"
                highlightColor="#444"
              />
            </Button>

            <Button className={styles.removeButton}>
              <Skeleton
                className={styles.editIcon}
                circle
                width={16}
                height={16}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                width={60}
                height={16}
                color="#202020"
                highlightColor="#444"
              />
            </Button>
          </div>
        </Hidden>
      </div>

      <div className={styles.cardAccount}>
        <div className={styles.containerBankAndEditRemove}>
          <Skeleton
            width={isMobile ? 280 : 400}
            height={17}
            color="#202020"
            highlightColor="#444"
          />
          <Skeleton
            style={{ marginLeft: '2px' }}
            width={isMobile ? 280 : 400}
            height={17}
            color="#202020"
            highlightColor="#444"
          />

          <div className={styles.agency}>
            <Skeleton
              style={{ marginLeft: '2px' }}
              width={180}
              height={17}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <Hidden smDown>
          <div className={styles.containerEditRemove}>
            <Button className={styles.editButton}>
              <Skeleton
                className={styles.editIcon}
                circle
                width={16}
                height={17}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                width={60}
                height={17}
                color="#202020"
                highlightColor="#444"
              />
            </Button>

            <Button className={styles.removeButton}>
              <Skeleton
                className={styles.editIcon}
                circle
                width={16}
                height={16}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                width={60}
                height={16}
                color="#202020"
                highlightColor="#444"
              />
            </Button>
          </div>
        </Hidden>
      </div>

      <div className={styles.cardAccount}>
        <div className={styles.containerBankAndEditRemove}>
          <Skeleton
            width={isMobile ? 280 : 400}
            height={17}
            color="#202020"
            highlightColor="#444"
          />
          <Skeleton
            style={{ marginLeft: '2px' }}
            width={isMobile ? 280 : 400}
            height={17}
            color="#202020"
            highlightColor="#444"
          />

          <div className={styles.agency}>
            <Skeleton
              style={{ marginLeft: '2px' }}
              width={180}
              height={17}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <Hidden smDown>
          <div className={styles.containerEditRemove}>
            <Button className={styles.editButton}>
              <Skeleton
                className={styles.editIcon}
                circle
                width={16}
                height={17}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                width={60}
                height={17}
                color="#202020"
                highlightColor="#444"
              />
            </Button>

            <Button className={styles.removeButton}>
              <Skeleton
                className={styles.editIcon}
                circle
                width={16}
                height={16}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                width={60}
                height={16}
                color="#202020"
                highlightColor="#444"
              />
            </Button>
          </div>
        </Hidden>
      </div>
    </Grid>
  );
}

export default FakeCards;
