import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../services/api';

const initialState = {
  isFetching: false,
  isProcessing: false,
  isSubmitting: false,
  progress: 0,
  files: [],
  nsuList: [],
  nsuListToAuthorize: [],
  error: {
    hasError: false,
    message: '',
  },
};

const setProcessFiles = createAsyncThunk(
  'payrollFilesUpload/setProcessFiles',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const { origin } = getState().account.accountSelected;
    const { files } = getState().payroll.payrollFilesUpload;

    const config = {
      headers: {
        accountOrigin: origin,
        accept: 'application/json',
      },
    };

    const formData = new FormData();
    files.forEach(data => formData.append('file', data.file));

    return api
      .post(
        `files/employeespayments/validate/confirm/${currentCompany.code}`,
        formData,
        config,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setPayrollAuthorized = createAsyncThunk(
  'payrollFilesUpload/setPayrollAuthorized',
  async (_, { getState, rejectWithValue }) => {
    const body = {
      nsuList: getState().payroll.payrollFilesUpload.nsuListToAuthorize,
    };

    return api
      .post(
        `employeesPayments/batch/authorize/${
          getState().companies.currentCompany.code
        }`,
        body,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const PayrollFilesUploadSlice = createSlice({
  name: 'payrollFilesUpload',
  initialState,
  reducers: {
    addFiles: (state, { payload }) => {
      state.files = state.files.concat(payload);
      state.progress = 0;
    },
    removeFile: (state, { payload }) => {
      state.progress = 0;
      state.files = state.files.filter(
        existingFile => existingFile.file.path !== payload.path,
      );
    },
    removeAllFiles: state => {
      state.progress = 0;
      state.files = initialState.files;
      state.nsuListToAuthorize = initialState.nsuListToAuthorize;
      state.nsuList = initialState.nsuList;
      state.isProcessing = false;
    },
    setFileProgress: (state, { payload }) => {
      const { percentage, fileName, status } = payload;

      const newFiles = state.files.map(data => {
        return [data.file?.name, data.file?.path].includes(fileName)
          ? { ...data, percentage, status }
          : data;
      });

      const currentFile = state.files.find(data =>
        [data.file?.name, data.file?.path].includes(fileName),
      );

      if (
        percentage === 100 &&
        currentFile.percentage < 100 &&
        state.progress < 100
      ) {
        const partialProgress = 100 / state.files.length;
        state.progress += partialProgress;
      }

      state.files = newFiles;
    },
    addNsu: (state, { payload }) => {
      state.nsuList = state.nsuList.concat(payload);
    },
    addNsuToAuthorize: (state, { payload }) => {
      if (Array.isArray(payload)) {
        payload.forEach(value => {
          if (!state.nsuListToAuthorize.includes(value)) {
            state.nsuListToAuthorize.push(value);
          } else {
            state.nsuListToAuthorize = state.nsuListToAuthorize.filter(
              nsu => nsu !== value,
            );
          }
        });
      } else if (!state.nsuListToAuthorize.includes(payload)) {
        state.nsuListToAuthorize.push(payload);
      } else {
        state.nsuListToAuthorize = state.nsuListToAuthorize.filter(
          nsu => nsu !== payload,
        );
      }
    },

    setErrorOnFile: (state, { payload }) => {
      const { error, fileName, status } = payload;

      const newFiles = state.files.map(data =>
        data.file.name === fileName ? { ...data, error, status } : data,
      );

      state.files = newFiles;
    },

    removeAllNsuToAuthorize: state => {
      state.nsuListToAuthorize = [];
    },
  },
  extraReducers: {
    [setProcessFiles.pending]: state => {
      state.isProcessing = true;
      state.progress = 0;
    },
    [setProcessFiles.fulfilled]: state => {
      state.isProcessing = false;
    },
    [setProcessFiles.rejected]: (state, { payload }) => {
      state.isProcessing = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Ocorreu um erro ao processar o arquivo. Por favor, tente novamente em alguns instantes.',
      };
    },
    [setPayrollAuthorized.pending]: state => {
      state.isSubmitting = true;
    },
    [setPayrollAuthorized.fulfilled]: state => {
      state.isSubmitting = false;
    },
    [setPayrollAuthorized.rejected]: (state, { payload }) => {
      state.isSubmitting = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Ocorreu um erro ao autorizar o pagamento. Por favor, tente novamente em alguns instantes.',
      };
    },
  },
});

const payrollFileUploadReducer = PayrollFilesUploadSlice.reducer;
const PayrollFileUploadActions = {
  ...PayrollFilesUploadSlice.actions,
  setProcessFiles,
  setPayrollAuthorized,
};

export { payrollFileUploadReducer, PayrollFileUploadActions };
