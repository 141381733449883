import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles.module.css';

const useStyles = makeStyles({
  accordionStyle: {
    border: '1px solid #F2F2F2',
    boxShadow: '0px 3.85px 7.7px rgba(114, 114, 114, 0.1)',
  },
});

export default function SimpleAccordion({ title, content, style, hidden }) {
  const classes = useStyles();

  return (
    <div
      className={hidden ? styles.hidden : styles.accordionContainer}
      style={style}
    >
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {title}
        </AccordionSummary>

        <AccordionDetails className={styles.accordionDetails}>
          {content}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
