import React from 'react';

import {
  Button,
  CircularProgress
} from '@material-ui/core';

import styles from './styles.module.css';

export default function BottomBar({
  handleSubmit,
  onBackClicked,
  requestLoading,
  hidden
}) {
  return (
    <div className={hidden ? styles.hidden : styles.responsiveFooter}>
      <Button
        className={styles.responsiveFooterCancel}
        onClick={onBackClicked}
      >
        Cancelar
      </Button>

      <Button
        className={styles.responsiveFooterConfirm}
        onClick={handleSubmit}
      >
        {requestLoading ? (
          <CircularProgress className={styles.loader} />
        ) : 'Salvar'}
      </Button>
    </div>
  )
}
