export const keyOptions = [
  { label: 'CPF/CNPJ', value: 'CPF_CNPJ' },
  { label: 'Celular', value: 'PHONE' },
  { label: 'E-mail', value: 'EMAIL' },
];

export const accountTypeOptions = [
  {
    label: 'Conta corrente',
    value: 'CC',
  },
  {
    label: 'Conta poupança',
    value: 'CP',
  },
];
