import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import { unwrapResult } from '@reduxjs/toolkit';
import { UserManagementActions } from '../../../../redux/store/UserManagement';

import { pusher } from '../../../../utils/pusher';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

export default function useFaceAuth({ validationToken }) {
  const dispatch = useDispatch();
  const { success, error } = handleSnackbarStack();

  const { authLink } = useSelector(state => state.userManagement);

  const getAuthLink = useCallback(async () => {
    const actionResult = await dispatch(
      UserManagementActions.getFaceAuth({ validationToken }),
    );

    try {
      unwrapResult(actionResult);
    } catch (_) {
      error(
        'Ocorreu um erro ao obter um link de autenticação por foto. Por favor, recarregue a página para tentar novamente.',
      );
    }
  }, []);

  useEffect(() => {
    getAuthLink();
  }, [getAuthLink]);

  useEffect(() => {
    const channel = pusher.subscribe('first-access-auth');

    channel.bind('CAF_WEBHOOK_RESPONSE', ({ data }) => {
      if (data.isMatch) {
        success('Tudo certo! Agradecemos por confirmar sua identidade.');
        NiceModal.hide('first-access-auth');
      } else {
        error(
          'Ocorreu um erro na sua autenticação por foto. Por favor, tente novamente em alguns instantes.',
        );
      }
    });

    return () => {
      pusher.unsubscribe('first-access-auth');
    };
  }, []);

  return {
    authLink,
  };
}
