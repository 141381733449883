import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Box,
} from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { ActionsAccount } from '../../../../redux/store/Accounts';

import styles from './styles.module.css';
import { statusColor, statusLabel } from './helpers/status';

export default function SwitchAccount({
  label,
  content,
  loading,
  accountSelected,
}) {
  const dispatch = useDispatch();

  const hasMultipleAccounts = content.length > 1;

  const [anchorElAccount, setAnchorElAccount] = useState(null);

  const accountsOrderedByOrigin = orderAccountsToOrigin(content);

  const renderButton = () => {
    if (hasMultipleAccounts) {
      return anchorElAccount ? (
        <ExpandLess className={styles.accountMenuIcon} />
      ) : (
        <ExpandMore className={styles.accountMenuIcon} />
      );
    }
  };

  const handleClickListItem = event => {
    if (hasMultipleAccounts) setAnchorElAccount(event.currentTarget);
  };

  const handleMenuItemClick = (option, index) => {
    if (option.encodedKey === accountSelected.encodedKey) {
      handleClose();
    } else {
      dispatch(ActionsAccount.setAccountSelectedToConfirm(option));
      dispatch(ActionsAccount.setAccountIndexSelectedToConfirm(index));
      dispatch(ActionsAccount.setIsOpenModalToSwitchAccount(true));
      setAnchorElAccount(null);
    }
  };

  const handleClose = () => {
    setAnchorElAccount(null);
  };

  function orderAccountsToOrigin(array) {
    const accountToCompare = 'FACIL_SCD';
    function compareToOrigin(a, b) {
      if (a.origin === accountToCompare && b.origin !== accountToCompare) {
        return -1;
      }
      if (a.origin !== accountToCompare && b.origin === accountToCompare) {
        return 1;
      }
      return 0;
    }

    const auxAccountsArray = [...array];

    auxAccountsArray.sort(compareToOrigin);

    return auxAccountsArray;
  }

  function getAccountOriginAfterUnderline(accountOrigin) {
    const accountOriginSeparate = accountOrigin?.split('_');

    if (accountOriginSeparate?.length > 1) {
      return accountOriginSeparate[1];
    }
    return accountOrigin;
  }

  return (
    <>
      <List className={styles.accountContainer}>
        <ListItem
          button={!!hasMultipleAccounts}
          className={styles.listButton}
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="switch account"
          onClick={handleClickListItem}
        >
          <Box className={styles.listItemAvatar}>
            <Avatar className={styles.avatarAccount}>
              <AccountBalanceRoundedIcon fontSize="small" color="primary" />
            </Avatar>
          </Box>
          <ListItemText
            primary={
              loading ? (
                <Skeleton height={12} width={80} />
              ) : (
                <Box display="flex" alignItems="center">
                  <Typography className={styles.titleAccount}>
                    {label}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <FiberManualRecordIcon
                      style={{
                        fontSize: '0.5rem',
                        color: statusColor(accountSelected.status),
                        marginRight: '0.25rem',
                      }}
                    />
                    <Typography
                      style={{
                        color: statusColor(accountSelected.status),
                        fontSize: '0.75rem',
                      }}
                    >
                      {statusLabel(accountSelected?.status)}
                    </Typography>
                  </Box>
                </Box>
              )
            }
            secondary={
              loading ? (
                <Skeleton height={12} width={100} />
              ) : (
                <Typography className={styles.contentAccount}>
                  {`${getAccountOriginAfterUnderline(
                    accountSelected?.origin,
                  )}-${accountSelected?.id}`}
                </Typography>
              )
            }
          />
          {renderButton()}
        </ListItem>
        <Divider className={styles.dividerAccount} />
      </List>
      {hasMultipleAccounts && (
        <Menu
          id="account-menu"
          anchorEl={anchorElAccount}
          keepMounted
          open={Boolean(anchorElAccount)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Box px={2} pt={1}>
            <Typography className={styles.accountLabel}>Contas</Typography>

            {accountsOrderedByOrigin.map((option, index) => (
              <MenuItem
                key={option.encodedKey}
                onClick={() => handleMenuItemClick(option, index)}
                className={styles.accountMenuItem}
                style={{
                  backgroundColor:
                    option.encodedKey === accountSelected.encodedKey &&
                    '#52197F0D',
                }}
              >
                <Box>
                  <Typography className={styles.account}>
                    {`${getAccountOriginAfterUnderline(option?.origin)} - ${
                      option?.id
                    }`}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <FiberManualRecordIcon
                      style={{
                        fontSize: '0.5rem',
                        color: statusColor(option.status),
                        marginRight: '0.25rem',
                      }}
                    />
                    <Typography
                      style={{
                        color: statusColor(option.status),
                        fontSize: '0.75rem',
                      }}
                    >
                      {statusLabel(option?.status)}
                    </Typography>
                  </Box>
                </Box>
                {option.encodedKey === accountSelected.encodedKey && (
                  <CheckOutlinedIcon color="primary" fontSize="small" />
                )}
              </MenuItem>
            ))}
          </Box>
        </Menu>
      )}
    </>
  );
}
