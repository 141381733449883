import React from 'react';

import { Skeleton } from '@material-ui/lab';

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { headFake, bodyFake } from '../mocks';

import { ReactComponent as EditButton } from '../../../../../assets/edit-button.svg';

import styles from './styles.module.css';

export default ({ currentPage, pageSize, count }) => {
  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headFake.map(item => (
              <TableCell key={item.id}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyFake.map(item => (
            <TableRow>
              <TableCell>
                <Skeleton className={styles.cpf} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.name} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.status} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.limitGranted} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.limitUsed} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.limitBalance} />
              </TableCell>
              <TableCell>
                <IconButton>
                  <Tooltip title="Visualizar detalhes">
                    <EditButton />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              count={count}
              rowsPerPage={pageSize}
              page={currentPage}
              labelRowsPerPage="Linhas por página"
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
