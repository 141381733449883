import React from 'react';
import moment from 'moment';

import { Grid, Chip } from '@material-ui/core';

import style from '../styles.module.css';

export default function ChipsFilter({
  depositType,
  isFilteredType,
  setIsFilteredType,
  setDepositType,
  depositSituation,
  isFilteredSituation,
  deposityStatusToWord,
  setIsFilteredSituation,
  setDepositSituation,
  finalDate,
  isFilteredFinalDate,
  initialDate,
  setInitialDate,
  setFinalDate,
  setIsFilteredFinalDate,
}) {
  return (
    <Grid
      container
      direction="row"
      wrap="wrap"
      spacing={1}
      className={style.chips}
    >
      <Grid item>
        {depositType && isFilteredType && (
          <Chip
            className={style.chipItem}
            label={depositType === 1 ? 'TED' : 'Boleto'}
            onDelete={() => {
              setIsFilteredType(false);
              setDepositType('');
            }}
          />
        )}
      </Grid>

      <Grid item>
        {depositSituation && isFilteredSituation && (
          <Chip
            className={style.chipItem}
            label={deposityStatusToWord(depositSituation)}
            onDelete={() => {
              setIsFilteredSituation(false);
              setDepositSituation('');
            }}
          />
        )}
      </Grid>

      <Grid item>
        {finalDate && isFilteredFinalDate && (
          <Chip
            className={style.chipItem}
            label={`${moment(initialDate).format('DD/MM/YYYY')} a ${moment(
              finalDate,
            ).format('DD/MM/YYYY')}`}
            onDelete={() => {
              setInitialDate('');
              setFinalDate('');
              setIsFilteredFinalDate(false);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
