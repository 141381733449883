export default function convertBytesToBlobDowload(data, nameDocument) {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));

  const nameDocumentFormated = `${nameDocument}.pdf`;

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', nameDocumentFormated);

  document.body.appendChild(link);
  link.click();
  link.remove();
}
