import React, { useState } from 'react';
import { Box, Grid, TextField, Menu, MenuItem, Typography, Divider, Button } from '@material-ui/core';
import { ReactComponent as ArrowDropDown } from '../../assets/arrowDropDown.svg';
import { ReactComponent as SearchIcon } from '../../assets/searchIcon.svg';
import { ReactComponent as Done } from '../../assets/done.svg';
import { ReactComponent as PlusPurpleIcon } from '../../assets/plusPurpleIcon.svg';
import useDropDownStyles from './styles';

export default function DropDown({
  options, 
  onSelectOption,
  onRegisterNewItem,
  renderOption,
  disabled = false,
  placeholder = "Selecione uma opção",
  noOptionsText = "Nenhuma opção disponível",
  newOptionLabel = "Cadastrar nova opção",
  placeholderSearchInput = "Pesquisar",
  helperText = ''
}) {
  const classes = useDropDownStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelectOption(option);
    setInputValue(option.key);
    setInputValue("");
    handleClose();
  };

  const filteredOptions = options.filter(option =>
    option.key.toLowerCase().includes(inputValue.toLowerCase())
  );

  const renderOptions = () => {
    if (!!filteredOptions?.length) {
      return filteredOptions.map(option => {
        const isSelectedOption = option?.key === selectedOption?.key;

        return (
          <MenuItem 
            key={option.key || option.id} 
            onClick={() => handleSelectOption(option)}
            className={isSelectedOption ? classes.selectedOptionBackground : null} 
            classes={{ root: classes.menuItem }}
          >
            {renderOption(option)}
            {isSelectedOption && <Done className={classes.doneIcon} />}
          </MenuItem>
        );
      });
    }

    return (
      <Typography className={classes.noOptionsText}>
        {noOptionsText}
      </Typography>
    );
  };

  return (
    <Box>
      <TextField
        role="button"
        aria-label={selectedOption ? `Selected ${selectedOption.key}` : placeholder}
        onClick={handleClick}
        fullWidth
        label={placeholder}
        variant="outlined"
        focused={!!anchorEl}
        disabled={disabled}
        helperText={helperText}
        className={classes.textField}
        sx={{ cursor: 'pointer' }}
        InputProps={{
          startAdornment: (
            selectedOption && (
              <Box className={classes.selectedOption}>
                {renderOption(selectedOption)}
              </Box>
            )
          ),
          endAdornment: (
            <Box className={classes.endAdornment}>
              <ArrowDropDown className={classes.arrowIcon} style={{ transform: anchorEl ? 'rotate(-180deg)' : 'rotate(0deg)' }} />
            </Box>
          )
        }}
      />
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.offsetWidth : 'auto',
            padding: 12,
            paddingTop: 10,
            paddingBottom: 4
          },
        }}
      >
        <Grid container>
          <TextField
            autoFocus
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholderSearchInput}
            fullWidth
            size="small"
            variant="outlined"
            margin="none"
            InputProps={{
              startAdornment: (
                <SearchIcon className={classes.searchIcon} />
              ),
            }}
            className={classes.searchField}
          />
        </Grid>

        <Box className={classes.optionBox}>
          {renderOptions()}
        </Box>

        <Divider />

        <Button
          className={classes.newItemButton}
          onClick={onRegisterNewItem}
          aria-label={newOptionLabel}
          startIcon={<PlusPurpleIcon />}
        >
          {newOptionLabel}
        </Button>
      </Menu>
    </Box>
  );
}
