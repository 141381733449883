import React from 'react';
import { Controller } from 'react-hook-form';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Box, Dialog, Grid, Typography } from '@material-ui/core';

import Form from '../../../../../components/Form';
import Buttons from '../../../../../components/Buttons';

import { ReactComponent as IncomeIllustration } from '../../../../../assets/income-update.svg';

import useRevenueModal from './Hooks/useRevenueModal';

import { useStyles } from './styles';

const IncomeModal = NiceModal.create(() => {
  const modal = useModal();
  const styles = useStyles();

  const closeModal = () => {
    modal.remove();
  };

  const {
    form: { control, isDirty, isValid, handleSubmit, isLoading },
    FORMATTED_USER_NAME,
  } = useRevenueModal({ onClose: closeModal });

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{ className: styles.dialog }}
      open={modal.visible}
      onClose={closeModal}
    >
      <Box className={styles.rootContainer}>
        <Grid className={styles.textContainer} container spacing={2}>
          <Grid item>
            <Typography className={styles.title} variant="h3">
              Olá <span className={styles.span}>{FORMATTED_USER_NAME}!</span>
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.subtitle} variant="h4">
              Precisamos que você atualize alguns dados!
            </Typography>
            <Typography variant="body">
              Utilizaremos esses dados para melhorar a nossa segurança. Basta
              preencher os campos ao lado.
            </Typography>
          </Grid>

          <Grid item className={styles.image}>
            <IncomeIllustration />
          </Grid>
        </Grid>
        <Grid container item>
          <Form.FormBase
            id="monthly-income-form"
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} className={styles.fields}>
              <Typography variant="body2" className={styles.action}>
                Você pode informar o faturamento mensal e o patrimônio
                aproximado da empresa?
              </Typography>
              <Controller
                name="revenue"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.MaskedField
                    label="Faturamento mensal*"
                    {...field}
                    mask={[
                      {
                        mask: 'R$ num',
                        blocks: {
                          num: {
                            mask: Number,
                            padFractionalZeros: true,
                            normalizeZeros: true,
                            scale: 2,
                            thousandsSeparator: '.',
                            min: 0,
                            radix: ',',
                            mapToRadix: ['.'],
                          },
                        },
                      },
                    ]}
                  />
                )}
              />
              <Controller
                name="patrimony"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.MaskedField
                    label="Patrimônio aproximado*"
                    {...field}
                    mask={[
                      {
                        mask: 'R$ num',
                        blocks: {
                          num: {
                            mask: Number,
                            padFractionalZeros: true,
                            normalizeZeros: true,
                            scale: 2,
                            thousandsSeparator: '.',
                            min: 0,
                            radix: ',',
                            mapToRadix: ['.'],
                          },
                        },
                      },
                    ]}
                  />
                )}
              />
              {/* <Typography
                variant="label"
                className={styles.reasonText}
                component="a"
                href="/"
              >
                Por que estamos pedindo isso?
              </Typography> */}
            </Grid>
            <Grid item xs={12} className={styles.buttonsContainer} spacing={1}>
              <Buttons.SecondaryButton
                title="Responder depois"
                variant="text"
                onClick={closeModal}
                color="primary"
              />
              <Buttons.PrimaryButton
                type="submit"
                title="Salvar"
                variant="contained"
                color="secondary"
                disabled={!isDirty || !isValid}
                loading={isLoading}
              />
            </Grid>
          </Form.FormBase>
        </Grid>
      </Box>
    </Dialog>
  );
});

export default IncomeModal;
