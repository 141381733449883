import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import ContentDrawer from '../Drawer';

import Drawer from '../../../components/Drawer';
import Title from '../../../components/Title';

import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';

export default function Header({
  title,
  description,
  showEditButton,
  activeEditMode,
  disabledButton,
}) {
  return (
    <>
      {!isMobile && (
        <Drawer title="Perfis de acesso" content={<ContentDrawer />} />
      )}

      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Grid item>{!isMobile && <Title>{title}</Title>}</Grid>

          <Grid item>
            <Typography className={styles.mediumText}>{description}</Typography>
          </Grid>
        </Grid>

        {showEditButton && (
          <Grid item>
            <Button
              className={styles.editButton}
              onClick={activeEditMode}
              disabled={disabledButton}
            >
              <CreateOutlinedIcon className={styles.editButtonIcon} /> Editar
              dados
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
