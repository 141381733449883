import React from 'react';

import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';

import styles from '../styles.module.css';

import TablePaginationActions from './TablePaginationActions';
import currencyFormatter from '../../../utils/currencyFormatter';
import convertBrazilianDate from '../../../utils/convertBrazilianDate';
import FakeRowTable from './FakeRowTable';

function TablePlains({
  dataPlans,
  loadingData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) {
  const dataPlansArray = dataPlans?.history?.content;

  function handleChangePage(event, newPage) {
    setPage(newPage);
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleInvoceDownload(url) {
    window.open(url);
  };

  return (
    <div>
      <Typography className={styles.textBigger}>
        Histórico de pagamento do plano
      </Typography>
      <Typography className={styles.labeHistory}>
        A competência é referente ao <strong>período de apuração (21 a 20)</strong> da nota fiscal. O total é composto pelo custo da assinatura somado as tarifas conforme seu contrato.
      </Typography>

      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>Data</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Competência</TableCell>
              <TableCell>Forma de Pagamento</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>NF-e</TableCell>
            </TableRow>
          </TableHead>

          {loadingData ? (
            <FakeRowTable />
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? dataPlansArray?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : dataPlansArray
              )?.map(row => (
                <TableRow key={row.name} className={styles.tableRow} hover>
                  <TableCell
                    className={styles.tableCell}
                    component="th"
                    scope="row"
                  >
                    {convertBrazilianDate(row.date)}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.description === null ? (
                      '-'
                    ) : (
                      <>
                        <span>
                          {row.description.slice(0, 10) === 'Assinatura'
                            ? row.description.slice(0, 10)
                            : row.description}
                        </span>
                        <p style={{ color: '#000000', fontSize: '14px' }}>
                          {row.description.slice(-9) === '+ Tarifas' &&
                            row.description.slice(-9)}
                        </p>
                      </>
                    )}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.compentency}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.transactionType}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {!row.feeAmount
                      ? row.signatureAmount.toLocaleString('pt-br', {
                          minimumFractionDigits: 1,
                          style: 'currency',
                          currency: 'BRL',
                        })
                      : currencyFormatter(row.signatureAmount)}
                    {row.feeAmount && (
                      <p>(+ {currencyFormatter(row.feeAmount)} de tarifas.)</p>
                    )}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {currencyFormatter(row.total)}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.nf === null ? (
                      <span className={styles.emptyInvoiceNumber}>-</span>
                    ) : (
                      <span className={styles.invoiceNumber} onClick={() => handleInvoceDownload(row.nf)}>
                        {row.invoiceNumber}
                        <LaunchRoundedIcon className={styles.launchIcon}/>
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={dataPlansArray?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TablePlains;
