import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import Title from '../Title';

export function Header({ title, subtitle = '', buttons }) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <Box>
        <Title>{title}</Title>
        {subtitle && (
          <Typography
            component="h2"
            style={{
              fontSize: '1rem',
              fontWeight: 500,
              color: theme.palette.text.secondary,
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="stretch" sx={{ gap: theme.spacing(2) }}>
        {buttons}
      </Box>
    </Box>
  );
}
