import React from 'react';
import styles from './styles.module.css';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { Typography } from '@material-ui/core';

function ConnectionError({ getBalancesAgain }) {
  return (
    <div className={styles.connectionErrorContainer} onClick={getBalancesAgain}>
      <div className={styles.connectionErrorIcon}>
        <WifiOffIcon className={styles.wifiIcon} />
      </div>
      <Typography className={styles.connectionErrorTitle}>Problema de Conexão</Typography>
      <Typography className={styles.connectionErrorDescription}>Tentar novamente</Typography>
    </div>
  );
}

export default ConnectionError;
