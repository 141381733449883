import React from 'react';

import { Search } from '@material-ui/icons';

import {
  Typography,
  Hidden,
  TextField,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import style from './styles.module.css';
import styles from './styles';

import CustomDatePicker from '../../../../../components/DatePicker';
import { useGetLoanStatus } from '../../utils/services';

export default function Filters({
  cpf,
  status,
  startDate,
  endDate,
  changedFilter,
  handleChangeCpf,
  handleChangeStatus,
  handleChangeStartDate,
  handleChangeEndDate,
  validateStartDateInfo,
  validateEndDateInfo,
}) {
  const { data: statusList } = useGetLoanStatus();

  return (
    <div className={style.filterContainer}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={3}>
          <TextField
            className={styles.cpf}
            onChange={handleChangeCpf}
            value={cpf}
            style={{ backgroundColor: '#FFF' }}
            placeholder="CPF"
            variant="outlined"
            autoFocus={changedFilter && cpf}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl
            variant="outlined"
            style={{ width: '100%', backgroundColor: '#FFF' }}
          >
            <InputLabel id="demo-simple-select-outlined-label-situation">
              Situação
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label-situation"
              id="demo-simple-select-outlined"
              value={status}
              onChange={handleChangeStatus}
              label="Situação"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="">Todas</MenuItem>
              {statusList &&
                statusList.map(status => (
                  <MenuItem key={status.status} value={status.status}>
                    {status.description}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: 'flex' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl variant="outlined" style={{ width: '45%' }} fullWidth>
            <CustomDatePicker
              value={startDate}
              onChange={handleChangeStartDate}
              label={startDate ? 'Data inicial' : null}
              placeholder="Data inicial"
              error={validateStartDateInfo}
              disableCalendarIcon={validateStartDateInfo}
              maxDate={endDate}
            />
            <Hidden smDown>
              {validateStartDateInfo && (
                <Typography className="dateError">*Data inválida</Typography>
              )}
            </Hidden>
          </FormControl>

          <span className="dateLabel">até</span>

          <FormControl variant="outlined" style={{ width: '45%' }}>
            <CustomDatePicker
              value={endDate}
              onChange={handleChangeEndDate}
              label={endDate ? 'Data final' : null}
              placeholder="Data final"
              error={validateEndDateInfo}
              disableCalendarIcon={validateEndDateInfo}
              minDate={startDate || ''}
              maxDate={Date.now()}
            />
            <Hidden smDown>
              {validateEndDateInfo && (
                <Typography Typography className="dateError">
                  *Data inválida
                </Typography>
              )}
            </Hidden>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
