export default function convertErrorName(item) {
  const type = item?.type || ''
  
  switch (type) {
    case 'number':
      return 'Telefone';
    case 'email':
      return 'Email';
    case 'rg':
      return 'RG';
    case 'employeeRegistration':
      return 'Matrícula';
    default:
      return item.error;
  }
}
