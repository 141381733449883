import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';

import { useTabs } from './Hooks/useTabs';
import TabLabel from './Components/TabsElements/TabLabel';
import TabPanel from './Components/TabsElements/TabPanel';
import EmployeesAproved from './Components/EmployeesAproved';
import EmployeesPending from './Components/EmployeesPending';
import EmployeesReproved from './Components/EmployeesReproved';

export default function SituationEmployeesTabs() {
  const { tabs, employeesCount } = useTabs();

  return (
    <>
      <Box mb={4}>
        <Tabs
          value={tabs.valueTab}
          onChange={tabs.handleChangeValueTab}
          indicatorColor="primary"
          style={{ borderBottom: '1px solid #c4c4c4' }}
        >
          <Tab
            label={
              <TabLabel
                title="Aprovados"
                countEmployees={employeesCount.aproved}
                color={tabs.valueTab === 0 ? '#fff' : '#19797F'}
                backgroundColor={tabs.valueTab === 0 ? '#19797F' : '#19797F1A'}
              />
            }
          />
          <Tab
            label={
              <TabLabel
                title="Em análise"
                countEmployees={employeesCount.pending}
                color={tabs.valueTab === 1 ? '#1A1A1A' : '#E39F17'}
                backgroundColor={tabs.valueTab === 1 ? '#E39F17' : '#E39F171A'}
              />
            }
          />
          <Tab
            label={
              <TabLabel
                title="Reprovados"
                countEmployees={employeesCount.reproved}
                color={tabs.valueTab === 2 ? '#fff' : '#CA2317'}
                backgroundColor={tabs.valueTab === 2 ? '#CA2317' : '#CA23171A'}
              />
            }
          />
        </Tabs>
      </Box>

      <TabPanel value={tabs.valueTab} index={0}>
        <EmployeesAproved />
      </TabPanel>
      <TabPanel value={tabs.valueTab} index={1}>
        <EmployeesPending />
      </TabPanel>
      <TabPanel value={tabs.valueTab} index={2}>
        <EmployeesReproved />
      </TabPanel>
    </>
  );
}
