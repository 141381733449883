import React from 'react';
import { Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import styles from './styles.module.css';

export default function Indicator({ children, error }) {
  return (
    <Typography
      className={error ? styles.validationError : styles.validationPassed}
    >
      <CheckCircle className={styles.checkCircle} />
      {children}
    </Typography>
  );
}
