import React from 'react';
import { useSelector } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Buttons from '../../../../../../components/Buttons';

import { ReactComponent as Success } from '../../../../../../assets/successAnimation.svg';

import useStyles from '../styles';

const ConfirmationModal = NiceModal.create(() => {
  const modal = useModal();
  const styles = useStyles();

  const { selectedResult } = useSelector(state => state.cashOut);

  return (
    <Dialog
      maxWidth="md"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <Success className={styles.successIcon} />
      <DialogTitle style={{ textAlign: 'center' }} className={styles.title}>
        Autorização registrada com sucesso!
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }} className={styles.content}>
        <DialogContentText className={styles.contentText}>
          Esta solicitação possui <b>{selectedResult.numberOfApprovals + 1}</b>{' '}
          de <b>{selectedResult.numberOfRequiredApprovals}</b> autorizações para
          ser efetuada.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.successActions}>
        <Buttons.PrimaryButton
          color="primary"
          variant="outlined"
          title="Entendi"
          onClick={modal.remove}
        />
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmationModal;
