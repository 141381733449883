/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Box } from '@material-ui/core';
import { Controller } from 'react-hook-form';

export function FilterFields({ children, control = {} }) {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        padding: '2rem 1rem',
      }}
    >
      {children?.map(filterField => (
        <Box sx={{ width: '100%' }}>
          <Controller
            name={filterField?.props?.name}
            control={control}
            defaultValue={control?._defaultValues?.[filterField?.props?.name]}
            render={({ field, fieldState }) =>
              React.Children.map(filterField, child =>
                React.isValidElement(child)
                  ? React.cloneElement(child, {
                      error: fieldState.invalid,
                      helperText: fieldState.error?.message,
                      size: 'medium',
                      ...field,
                    })
                  : child,
              )
            }
          />
        </Box>
      ))}
    </Box>
  );
}
