import { api } from '../../../../../services/api';

export const getConsignedMargins = async (params = {}, cnpj) => {
  return api.get('/consigned/employee/margin', {
    params,
    headers: {
      cnpj,
    },
  });
};

export const getConsignedMargin = async (id, cnpj) => {
  return api.get(`/consigned/employee/details/${id}`, {
    headers: {
      cnpj,
    },
  });
};

export const calculateDefaultMargin = async (params = {}) => {
  return api.get('/consigned/employee/margin/calculate', {
    params,
  });
};

export const createSingleEmployeeMargin = async body => {
  return api.post('/consigned/employee', body);
};

export const removeEmployeeMargin = async (body = {}) => {
  return api.put('/consigned/employee/margin/remove', body);
};

export const changeEmployeeMargin = async (body = {}) => {
  return api.put('/consigned/employee/margin/change', body);
};
