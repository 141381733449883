import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    marginBottom: 5,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 53,
  },
}));

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>CPF</TableCell>
        <TableCell>Nome</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Valor</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const classes = useStyles();

  const SKELETON_HEIGHT = 22;
  const SKELETON_WIDTH = 120;
  const CIRCLE_DIMENSION = 30;

  const COLOR = '#202020';
  const HIGHLIGHT_COLOR = '#444444';

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead />

            <TableBody>
              <TableRow hover>
                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    circle
                    height={CIRCLE_DIMENSION}
                    width={CIRCLE_DIMENSION}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    circle
                    height={CIRCLE_DIMENSION}
                    width={CIRCLE_DIMENSION}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    circle
                    height={CIRCLE_DIMENSION}
                    width={CIRCLE_DIMENSION}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    circle
                    height={CIRCLE_DIMENSION}
                    width={CIRCLE_DIMENSION}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    height={SKELETON_HEIGHT}
                    width={SKELETON_WIDTH}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>

                <TableCell>
                  <Skeleton
                    circle
                    height={CIRCLE_DIMENSION}
                    width={CIRCLE_DIMENSION}
                    color={COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={5}
          rowsPerPage={5}
          page={0}
        />
      </Paper>
    </div>
  );
}
