import React from 'react';
import NiceModal, { ModalDef, useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { useStyles } from '../../../../../styles';

const BlockModal = NiceModal.create(() => {
  const modal = useModal();
  const styles = useStyles();

  const { authLink } = useSelector(state => state.userManagement);

  return (
    <Dialog
      maxWidth="xl"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.authModal }}
    >
      <iframe
        className={styles.video}
        src={authLink}
        title="Autenticação por selfie na CAF"
        allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
      />
    </Dialog>
  );
});

export default function BlockModalDefs() {
  return <ModalDef id="block-modal" component={BlockModal} />;
}
