import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      gap: theme.spacing(4),
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,

      '& div[role="radiogroup"]': {
        width: '100%',
      },
    },

    borderedContainer: {
      border: `2px solid ${theme.palette.grey[300]}`,
      borderRadius: '0.5rem',
      padding: theme.spacing(2),
    },

    labelRowContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(2),
    },

    label: {
      color: theme.palette.text.secondary,
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.00938rem',
      lineHeight: 1,
    },

    cardContainer: {
      width: '100%',
      display: 'flex',
      gap: theme.spacing(2),
      flexFlow: 'row nowrap',

      [theme.breakpoints.down('md')]: {
        flexFlow: 'row wrap',
      },
    },
  }),
);
