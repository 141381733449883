import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import {
  AttachMoneyOutlined,
  MeetingRoomOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@material-ui/icons';

import { ChipStatus } from '../../../../../../../components/ChipStatus';
import Buttons from '../../../../../../../components/Buttons';

import { statusMap } from '../../../../constants';
import formatCPF from '../../../../../../../utils/formatCPF';

import { useStyles } from '../../../../styles';

export default function useTableConfig({ onOpenUserModal }) {
  const styles = useStyles();

  const renderUser = row => {
    const initials = row.name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();

    return (
      <Box display="flex">
        <Avatar className={styles.userAvatar}>{initials}</Avatar>
        <Box>
          <Typography
            className={styles.userName}
            component="h3"
            variant="body1"
          >
            {row.name}
          </Typography>
          <Typography
            className={styles.userEmail}
            component="label"
            variant="body2"
          >
            {row.email}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderProfile = row => {
    return (
      <Box className={styles.userProfile}>
        {row?.accessProfile === 'FINANCIAL' ? (
          <>
            <MeetingRoomOutlined className={styles.userHrProfile} />
            <Typography variant="body2">Departamento pessoal</Typography>
          </>
        ) : (
          <>
            <AttachMoneyOutlined className={styles.userFinancialProfile} />
            <Typography variant="body2">Financeiro</Typography>
          </>
        )}
      </Box>
    );
  };

  const renderStatus = row => {
    const status = statusMap[row.situation];

    if (!status) return;

    return (
      <ChipStatus
        className={styles.userStatus}
        icon={status.icon}
        status={status.color}
        size="small"
        color={status.color}
        variant="outlined"
        label={status.label}
      />
    );
  };

  const renderOptions = row => {
    return (
      <Box>
        {row?.situation === 'BLOCKED' ? (
          <Buttons.IconButton
            onClick={() => onOpenUserModal(row, 'ACTIVE')}
            icon={<CheckOutlined color="success" />}
            tooltipTitle="Desbloquear utilizador"
          />
        ) : (
          <Buttons.IconButton
            onClick={() => onOpenUserModal(row, 'BLOCKED')}
            icon={<CloseOutlined color="error" />}
            tooltipTitle="Bloquear utilizador"
          />
        )}
      </Box>
    );
  };

  return [
    {
      key: 'name',
      type: 'field',
      align: 'left',
      label: 'Utilizador',
      render: row => renderUser(row),
    },
    {
      key: 'cpf',
      type: 'field',
      align: 'left',
      label: 'CPF',
      render: row => formatCPF(row.cpf),
    },
    {
      key: 'phoneNumber',
      type: 'field',
      align: 'left',
      label: 'Telefone',
    },
    {
      key: 'situation',
      type: 'field',
      align: 'left',
      label: 'Status',
      render: row => renderStatus(row),
    },
    {
      key: 'actions',
      type: 'field',
      align: 'center',
      label: 'Ações',
      render: row => renderOptions(row),
    },
  ];
}
