import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  Typography
} from '@material-ui/core';
import style from './style.module.css';
import { useHistory } from 'react-router-dom';

function DepositAccountModal({ open, onCloseDialog }) {
  const history = useHistory();

  const onContinueClicked = () => {
    history.push('/cash-in/new/billing');
  }

  return (
    <Dialog open={open} onClose={onCloseDialog} maxWidth="77vw">
      <Grid className={style.dialog}>
        <Grid>
          <Typography className={style.biggerText}>Depositar valor na conta</Typography>
          <Typography className={style.smallText}>Você será direcionado para a criação de um boleto de depósito(cash-in).</Typography>
          <Typography className={style.smallText}>Ao continuar, você sairá dessa página.</Typography>
        </Grid>
        <Grid className={style.modalFooter}>
          <Button className={style.cancelButton} onClick={onCloseDialog}>
            Cancelar
          </Button>
          <Button className={style.continueButton} onClick={onContinueClicked}>
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DepositAccountModal;
