import React from 'react';

import { Box } from '@material-ui/core';

import Infos from './Infos';
import Form from './Form';

import styles from './styles.module.css';

export default function Card({
  pixValue,
  notes,
  handleChangePixValue,
  handleChangeNotes,
  INPUT_PIX_VALUE_VALIDATE,
  loading,
  pixKeys,
  isCelcoin,
  goToRegisterNewPix,
  handleSelectPix
}) {
  return (
    <Box mt={4} className={styles.card}>
      {isCelcoin && <Infos />}
      <Form
        pixValue={pixValue}
        pixKeys={pixKeys}
        notes={notes}
        handleChangePixValue={handleChangePixValue}
        handleChangeNotes={handleChangeNotes}
        INPUT_PIX_VALUE_VALIDATE={INPUT_PIX_VALUE_VALIDATE}
        loading={loading}
        isCelcoin={isCelcoin}
        goToRegisterNewPix={goToRegisterNewPix}
        handleSelectPix={handleSelectPix}
      />
    </Box>
  );
}
