import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsImportFilePayrollErrors } from '../../../../../../redux/store/Payroll/FilePayrollErrors';

export function useAddBankDataModal({
  defaultValues = {},
  handleCloseModal = () => {},
}) {
  const dispatch = useDispatch();
  const { fileErrorsGeneral, fileErrorsFixing } = useSelector(
    state => state.payroll.importFilePayrollErrors,
  );
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { dirtyFields },
    reset,
  } = useForm({ defaultValues });

  const accountTypeOptions = [
    {
      label: 'Conta Corrente',
      value: 'CC',
    },
    {
      label: 'Conta Poupança',
      value: 'CP',
    },
  ];

  watch();

  const isAbleToSubmit =
    Object.keys(dirtyFields).length === Object.keys(getValues()).length;

  function handleClose() {
    handleCloseModal();
    reset();
    dispatch(ActionsImportFilePayrollErrors.resetFileErrorsFixingErrorStatus());
  }

  return {
    formObject: {
      control,
      handleSubmit,
      isAbleToSubmit,
      handleClose,
    },
    selectOptionsObject: {
      bankOptions: fileErrorsGeneral?.bankOptions,
      accountTypeOptions,
    },
    requestStatusObject: {
      isLoading: fileErrorsFixing?.isLoading,
      errorOn: fileErrorsFixing?.errorOn,
    },
  };
}
