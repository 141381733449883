import React from 'react';
import { useHistory } from 'react-router-dom';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';

import { ReactComponent as Success } from '../../../../../assets/successAnimation.svg';

import { useStyles } from './styles';

const PayrollPixConfirmationModal = NiceModal.create(() => {
  const modal = useModal();
  const styles = useStyles();
  const history = useHistory();

  const onBack = () => {
    modal.remove();
    history.push('/payroll');
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={modal.visible}
      keepMounted
      PaperProps={{ className: styles.root }}
    >
      <DialogContent className={styles.content}>
        <Success />
        <Typography variant="h3" className={styles.title}>
          Folha paga com sucesso!
        </Typography>
        <Typography variant="body2" className={styles.subtitle}>
          A folha de pagamento está paga para seus colaboradores.
        </Typography>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Buttons.PrimaryButton
          size="large"
          color="primary"
          title="Gerenciar Pagamentos"
          onClick={onBack}
        />
      </DialogActions>
    </Dialog>
  );
});

export default PayrollPixConfirmationModal;
