import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FakeInformationsModal from './FakeInformationsModal';
import { isMobile } from '../../../utils/breakpoints';
import { dataCityAndUF } from '../../../mocks/cityAndUF';
import formatCEP from '../../../utils/formatCEP';

import styles from './styles.module.css';

export default function ModalInclude({
  value,
  itemTitle,
  itemData,
  registerLocation,
  registerStocking,
  alreadyExistsError,
  setAlreadyExistsError,
  name,
  setName,
  code,
  setCode,
  zipCode,
  setZipCode,
  publicPlace,
  setPublicPlace,
  numberAddress,
  setNumberAddress,
  district,
  setDistrict,
  complement,
  setComplement,
  UF,
  setUF,
  city,
  setCity,
  mapCity,
  blockedCity,
  loadingZipCode,
  textFieldFocused,
  setTextFieldFocused,
  zipCodeInvalid,
  onFocusAutoComplete,
  cardSendingEnabled,
  handleCardSendingEnabled,
  handleClosedModal,
  loadingSkeleton,
  changeLocation,
  changeStocking,
  country,
  loadingButton,
}) {
  return (
    <Dialog open onClose={() => handleClosedModal()}>
      {value === 0 ? (
        <div className={styles.dialog}>
          <Typography className={styles.titleDialog}>
            {itemData ? `Editar ${itemTitle}` : `Incluir ${itemTitle}`}
          </Typography>
          <Typography className={styles.subtitleDialog}>
            {itemData
              ? `Preencha os campos abaixo e edite o ${itemTitle}.`
              : `Preencha os campos abaixo e inclua um novo ${itemTitle}.`}
          </Typography>

          <form className={styles.form}>
            {loadingSkeleton ? (
              <FakeInformationsModal />
            ) : (
              <>
                <div className={styles.formGroup}>
                  <div
                    className={styles.formField}
                    style={{
                      width: isMobile ? 248 : 294,
                      marginTop: isMobile && 16,
                    }}
                  >
                    <TextField
                      size="small"
                      className={
                        !isMobile
                          ? styles.textFieldMedium
                          : styles.textFieldMediumMobile
                      }
                      label={`Nome do ${itemTitle}`}
                      variant="outlined"
                      fullWidth
                      value={name}
                      onChange={event => setName(event.target.value)}
                      onFocus={() =>
                        setTimeout(
                          () => setTextFieldFocused('description'),
                          200,
                        )
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(
                          item => item.type === 'description',
                        ).length > 0
                      }
                      InputProps={{
                        tabIndex: '1',
                        maxLength: 50,
                        endAdornment: name?.length > 0 &&
                          textFieldFocused === 'description' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setName('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          ),
                      }}
                    />
                    {alreadyExistsError &&
                      alreadyExistsError.filter(
                        item => item.type === 'description',
                      ).length > 0 && (
                        <Typography className={styles.textFieldErrorLabel}>
                          Este nome já existe.
                        </Typography>
                      )}
                  </div>
                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 248 : 164 }}
                  >
                    <TextField
                      size="small"
                      className={
                        !isMobile
                          ? styles.textFieldSmall
                          : styles.textFieldSmallMobile
                      }
                      label="Código"
                      variant="outlined"
                      fullWidth
                      value={code}
                      onChange={event => setCode(event.target.value)}
                      onFocus={() =>
                        setTimeout(() => setTextFieldFocused('code'), 200)
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(item => item.type === 'code')
                          .length > 0
                      }
                      InputProps={{
                        tabIndex: '2',
                        maxLength: 50,
                        endAdornment: code?.length > 0 &&
                          textFieldFocused === 'code' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setCode('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          ),
                      }}
                    />
                    {alreadyExistsError &&
                      alreadyExistsError.filter(item => item.type === 'code')
                        .length > 0 && (
                        <Typography className={styles.textFieldErrorLabel}>
                          Este código já está sendo utilizado.
                        </Typography>
                      )}
                  </div>
                </div>

                <Typography className={styles.titleAddress}>
                  Endereço
                </Typography>

                <div className={styles.formGroup} style={{ marginTop: 13 }}>
                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 248 : 164 }}
                  >
                    <TextField
                      size="small"
                      className={
                        !isMobile
                          ? styles.textFieldSmall
                          : styles.textFieldSmallMobile
                      }
                      label="CEP"
                      variant="outlined"
                      fullWidth
                      value={zipCode}
                      onChange={event =>
                        setZipCode(formatCEP(event.target.value))
                      }
                      onFocus={() =>
                        setTimeout(() => setTextFieldFocused('zipCode'), 200)
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(
                          item => item.type === 'zipCode',
                        ).length > 0
                      }
                      InputProps={{
                        tabIndex: '3',
                        maxLength: 9,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        endAdornment: loadingZipCode ? (
                          <CircularProgress
                            thickness="5"
                            className={styles.endAdornmentLoading}
                          />
                        ) : (
                          zipCode?.length > 0 &&
                          textFieldFocused === 'zipCode' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setZipCode('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          )
                        ),
                      }}
                    />
                    {zipCodeInvalid && zipCode.length >= 9 && (
                      <Typography className={styles.textFieldErrorLabel}>
                        CEP inválido.
                      </Typography>
                    )}
                  </div>
                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 248 : 294 }}
                  >
                    <TextField
                      disabled={loadingZipCode}
                      size="small"
                      className={
                        loadingZipCode
                          ? styles.blockedTextFieldLoadingZipCode
                          : !isMobile
                          ? styles.textFieldMedium
                          : styles.textFieldMediumMobile
                      }
                      label="Logradouro"
                      variant="outlined"
                      fullWidth
                      value={publicPlace}
                      onChange={event => setPublicPlace(event.target.value)}
                      onFocus={() =>
                        setTimeout(
                          () => setTextFieldFocused('publicPlace'),
                          200,
                        )
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(
                          item => item.type === 'publicPlace',
                        ).length > 0
                      }
                      InputProps={{
                        tabIndex: '4',
                        maxLength: 50,
                        endAdornment: publicPlace?.length > 0 &&
                          textFieldFocused === 'publicPlace' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setPublicPlace('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          ),
                      }}
                    />
                  </div>
                </div>

                <div className={styles.formGroup} style={{ marginTop: 13 }}>
                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 248 : 164 }}
                  >
                    <TextField
                      disabled={loadingZipCode}
                      size="small"
                      className={
                        loadingZipCode
                          ? styles.blockedTextFieldLoadingZipCode
                          : !isMobile
                          ? styles.textFieldSmall
                          : styles.textFieldSmallMobile
                      }
                      label="Número"
                      variant="outlined"
                      fullWidth
                      value={numberAddress}
                      onChange={event => setNumberAddress(event.target.value)}
                      onFocus={() =>
                        setTimeout(
                          () => setTextFieldFocused('numberAddress'),
                          200,
                        )
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(
                          item => item.type === 'numberAddress',
                        ).length > 0
                      }
                      InputProps={{
                        tabIndex: '5',
                        maxLength: 15,
                        endAdornment: numberAddress?.length > 0 &&
                          textFieldFocused === 'numberAddress' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setNumberAddress('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          ),
                      }}
                    />
                  </div>

                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 248 : 294 }}
                  >
                    <TextField
                      disabled={loadingZipCode}
                      size="small"
                      className={
                        loadingZipCode
                          ? styles.blockedTextFieldLoadingZipCode
                          : !isMobile
                          ? styles.textFieldMedium
                          : styles.textFieldMediumMobile
                      }
                      label="Bairro"
                      variant="outlined"
                      fullWidth
                      value={district}
                      onChange={event => setDistrict(event.target.value)}
                      onFocus={() =>
                        setTimeout(() => setTextFieldFocused('district'), 200)
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(
                          item => item.type === 'district',
                        ).length > 0
                      }
                      InputProps={{
                        tabIndex: '6',
                        endAdornment: district?.length > 0 &&
                          textFieldFocused === 'district' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setDistrict('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          ),
                      }}
                    />
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 248 : 478 }}
                  >
                    <TextField
                      disabled={loadingZipCode}
                      size="small"
                      className={
                        loadingZipCode
                          ? styles.blockedTextFieldLoadingZipCode
                          : !isMobile
                          ? styles.textFieldBig
                          : styles.textFieldBigMobile
                      }
                      label="Complemento"
                      variant="outlined"
                      fullWidth
                      value={complement}
                      onChange={event => setComplement(event.target.value)}
                      onFocus={() =>
                        setTimeout(() => setTextFieldFocused('complement'), 200)
                      }
                      onBlur={() =>
                        setTimeout(() => setTextFieldFocused(''), 200)
                      }
                      error={
                        alreadyExistsError &&
                        alreadyExistsError.filter(
                          item => item.type === 'complement',
                        ).length > 0
                      }
                      InputProps={{
                        tabIndex: '7',
                        endAdornment: complement?.length > 0 &&
                          textFieldFocused === 'complement' && (
                            <IconButton
                              className={styles.endAdornment}
                              onClick={() => setComplement('')}
                            >
                              <CloseRoundedIcon
                                className={styles.endAdornmentIcon}
                              />
                            </IconButton>
                          ),
                      }}
                    />
                  </div>
                </div>

                <div
                  className={styles.formGroupAutoComplete}
                  style={{ marginTop: 13 }}
                >
                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 84 : 164 }}
                  >
                    <Autocomplete
                      clearOnBlur={false}
                      disabled={loadingZipCode}
                      size="small"
                      value={UF}
                      onChange={(event, value) => setUF(value)}
                      className={
                        loadingZipCode
                          ? styles.blockedTextFieldLoadingZipCode
                          : styles.textFieldAutoCompleteUF
                      }
                      options={dataCityAndUF}
                      closeIcon={false}
                      autoHighlight
                      getOptionLabel={option =>
                        (loadingZipCode || itemData) && !option.initials
                          ? UF
                          : option.initials
                      }
                      renderOption={option => <>{option.initials}</>}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            tabIndex: '8',
                          }}
                          label={isMobile && 'UF'}
                          placeholder={!isMobile && 'UF'}
                        />
                      )}
                    />
                  </div>

                  <div
                    className={styles.formField}
                    style={{ width: isMobile ? 156 : 294 }}
                  >
                    <Autocomplete
                      onFocus={() =>
                        mapCity.length === 0 && onFocusAutoComplete()
                      }
                      disabled={blockedCity || loadingZipCode}
                      size="small"
                      value={city}
                      onChange={(event, value) => setCity(value)}
                      className={
                        loadingZipCode
                          ? styles.blockedTextFieldLoadingZipCode
                          : styles.textFieldAutoCompleteCity
                      }
                      options={mapCity}
                      closeIcon={false}
                      autoHighlight
                      getOptionLabel={option =>
                        (loadingZipCode || itemData) && !option.nome
                          ? city
                          : option.nome
                      }
                      renderOption={option => <>{option.nome}</>}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            tabIndex: '9',
                          }}
                          label={isMobile && 'Cidade'}
                          placeholder={!isMobile && 'Cidade'}
                        />
                      )}
                    />
                  </div>
                </div>
                {
                  // Todo (Colocar quando for feito os filtros e o envio de centro de custo)
                  /* <hr className={styles.separator} />

                <div className={styles.formFieldSwitch}>
                  <Typography className={styles.subtitleDialog}>
                    Enviar cartão para o Centro de custo
                  </Typography>
                  <Switch
                    checked={cardSendingEnabled}
                    onChange={handleCardSendingEnabled}
                  />
                </div> */
                }
              </>
            )}
          </form>
        </div>
      ) : (
        <div className={styles.dialogSector}>
          <Typography className={styles.titleDialog}>
            {itemData ? `Editar ${itemTitle}` : `Incluir ${itemTitle}`}
          </Typography>
          <Typography className={styles.subtitleDialog}>
            {itemData
              ? `Preencha os campos abaixo e edite o ${itemTitle}.`
              : `Preencha os campos abaixo e inclua um novo ${itemTitle}.`}
          </Typography>

          <form className={styles.form}>
            <>
              <div className={styles.formGroupSector}>
                <div className={styles.formField}>
                  <TextField
                    size="small"
                    className={
                      !isMobile
                        ? styles.textFieldMedium
                        : styles.textFieldMediumMobile
                    }
                    label={`Nome do ${itemTitle}`}
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={event => setName(event.target.value)}
                    onFocus={() =>
                      setTimeout(() => setTextFieldFocused('description'), 200)
                    }
                    onBlur={() =>
                      setTimeout(() => setTextFieldFocused(''), 200)
                    }
                    error={
                      alreadyExistsError &&
                      alreadyExistsError.filter(
                        item => item.type === 'description',
                      ).length > 0
                    }
                    InputProps={{
                      tabIndex: '1',
                      maxLength: 50,
                      endAdornment: name?.length > 0 &&
                        textFieldFocused === 'description' && (
                          <IconButton
                            className={styles.endAdornment}
                            onClick={() => setName('')}
                          >
                            <CloseRoundedIcon
                              className={styles.endAdornmentIcon}
                            />
                          </IconButton>
                        ),
                    }}
                  />
                  {alreadyExistsError &&
                    alreadyExistsError.filter(
                      item => item.type === 'description',
                    ).length > 0 && (
                      <Typography className={styles.textFieldErrorLabel}>
                        Este nome já existe.
                      </Typography>
                    )}
                </div>
                <div className={styles.formField}>
                  <TextField
                    size="small"
                    className={
                      !isMobile
                        ? styles.textFieldSmall
                        : styles.textFieldSmallMobile
                    }
                    label="Código"
                    variant="outlined"
                    fullWidth
                    value={code}
                    onChange={event => setCode(event.target.value)}
                    onFocus={() =>
                      setTimeout(() => setTextFieldFocused('code'), 200)
                    }
                    onBlur={() =>
                      setTimeout(() => setTextFieldFocused(''), 200)
                    }
                    error={
                      alreadyExistsError &&
                      alreadyExistsError.filter(item => item.type === 'code')
                        .length > 0
                    }
                    InputProps={{
                      tabIndex: '2',
                      maxLength: 50,
                      endAdornment: code?.length > 0 &&
                        textFieldFocused === 'code' && (
                          <IconButton
                            className={styles.endAdornment}
                            onClick={() => setCode('')}
                          >
                            <CloseRoundedIcon
                              className={styles.endAdornmentIcon}
                            />
                          </IconButton>
                        ),
                    }}
                  />
                  {alreadyExistsError &&
                    alreadyExistsError.filter(item => item.type === 'code')
                      .length > 0 && (
                      <Typography className={styles.textFieldErrorLabel}>
                        Este código já está sendo utilizado.
                      </Typography>
                    )}
                </div>
              </div>
            </>
          </form>
        </div>
      )}

      <DialogActions className={styles.buttonAreaDialog}>
        <Button
          className={styles.cancelButtonDialog}
          onClick={() => {
            handleClosedModal();
            setAlreadyExistsError(false);
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          disabled={
            !!(
              !(code && name) ||
              ((zipCode ||
                publicPlace ||
                numberAddress ||
                city ||
                district ||
                UF) &&
                (!zipCode ||
                  !publicPlace ||
                  !numberAddress ||
                  !city ||
                  !district ||
                  !UF ||
                  zipCodeInvalid))
            )
          }
          className={
            !code ||
            !name ||
            ((zipCode ||
              publicPlace ||
              numberAddress ||
              city ||
              district ||
              UF) &&
              (!zipCode ||
                !publicPlace ||
                !numberAddress ||
                !city ||
                !district ||
                !UF ||
                zipCodeInvalid))
              ? styles.blockedButton
              : styles.saveButtonDialog
          }
          onClick={() => {
            switch (itemTitle) {
              case 'Centro de Custo':
                if (itemData) {
                  changeLocation(
                    zipCode
                      ? {
                          code,
                          description: name,
                          address: {
                            zipCode,
                            street: publicPlace,
                            number: numberAddress,
                            city: city.nome ? city.nome : city,
                            district,
                            state: UF.initials ? UF.initials : UF,
                            country,
                            details: complement,
                            cardSendingEnabled,
                          },
                        }
                      : {
                          code,
                          description: name,
                        },
                    itemData.code,
                  );
                } else {
                  registerLocation(
                    zipCode
                      ? {
                          code,
                          description: name,
                          address: {
                            zipCode,
                            street: publicPlace,
                            number: numberAddress,
                            city: city.nome ? city.nome : city,
                            district,
                            state: UF.initials ? UF.initials : UF,
                            country,
                            details: complement,
                            cardSendingEnabled,
                          },
                        }
                      : {
                          code,
                          description: name,
                        },
                  );
                }

                break;
              case 'Setor':
                if (itemData) {
                  changeStocking({ code, description: name }, itemData.code);
                } else {
                  registerStocking({ code, description: name });
                }
                break;
              // no default
            }
          }}
          color="primary"
          autoFocus
        >
          {loadingButton ? (
            <CircularProgress className={styles.loader} />
          ) : itemData ? (
            'Salvar'
          ) : (
            'Incluir'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
