export default function processErrorMessage(message) {
    if (message?.errorDTO?.errorDetail)
            return message.errorDTO.errorDetail
    else if (message?.errors?.length && message?.errors[0]?.errorDetail)
        return message.errors[0].errorDetail
    else if (message?.errors?.length && message?.errors[0]?.errorReason)
        return message.errors[0].errorReason
    else if (message?.message)
        return message.message
    else if (message)
        return message

    return "Ocorreu um erro inesperado."
}