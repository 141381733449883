import truncateString from '../../../../../../../utils/truncateString';

const concatAgencyWithDigit = data => {
  return `${data.agency}-${data.agencyDigit}`;
};

const concatAccountWithDigit = data => {
  return `${data?.account}-${data?.accountDigit}`;
};

const findBankName = (bankCode, banksList) => {
  const convertedBankCodeToInteger = parseInt(bankCode);

  const bankName = banksList?.data?.find(
    bank => bank?.code === convertedBankCodeToInteger,
  )?.name;

  return truncateString(bankName, 20);
};

export { concatAgencyWithDigit, concatAccountWithDigit, findBankName };
