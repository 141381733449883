import React from 'react';
import Lottie from 'react-lottie';

import { Box, Typography } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ReactComponent as PendingActionsIcon } from '../../../../../assets/pending_actions_icon.svg';

import successEmployees from '../../../../../lotties/successEmployees.json';
import { isMobile } from '../../../../../utils/breakpoints';

import styles from '../../styles.module.css';

export default function RegisterNewEmployeeSuccessful({ employeeName }) {
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: successEmployees,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box>
      <Box className={styles.header}>
        <Typography className={styles.titleStyle}>Quase lá...</Typography>
      </Box>

      <Box mt={3} className={styles.rowArea}>
        <Box className={styles.block}>
          <Box className={styles.informationBlock}>
            <Box className={styles.iconStyles}>
              <PendingActionsIcon />
            </Box>

            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                className={styles.subtitleStyle}
              >
                O funcionário
                <span className={styles.subtitleBoldStyle}>
                  {' '}
                  {employeeName ? employeeName[0] : ''}{' '}
                </span>
                foi pré-cadastrado com sucesso.
              </Typography>

              <Typography className={styles.fieldsSubTitle}>
                Estamos conferindo os dados informados para garantir que estejam
                de acordo com os critérios do Sistema Financeiro Nacional.
              </Typography>
              <Typography className={styles.fieldsSubTitle}>
                Assim que concluirmos, a conta será criada e todos os benefícios
                estarão disponíveis e acessíveis através do aplicativo Somapay
                que pode ser baixado nas lojas das plataformas Android e IOS.
              </Typography>
            </Box>
          </Box>

          <Box className={styles.inforContainer}>
            <Box className={styles.iconStyles}>
              <InfoOutlinedIcon className={styles.iconInfor} />
            </Box>
            <Box>
              <Typography className={styles.fieldsSubTitle}>
                Caso o funcionário não seja aprovado, você pode consultar a tela
                de Funcionários reprovados para realizar as ações necessários.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.ilustrationArea}>
          <Lottie
            options={animationOptions}
            width={isMobile ? 250 : 300}
            height={isMobile ? 190 : 250}
          />
        </Box>
      </Box>
    </Box>
  );
}
