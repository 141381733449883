import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import ProcessModal from './ProcessModal';

export default function MultiplePayrollFileModals() {
  return (
    <>
      <ModalDef component={ProcessModal} id="process-confirm-modal" />
    </>
  );
}
