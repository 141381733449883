const initialState = {
  payrollFile: '',
};

export default function payrollReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PAYROLL_DATA':
      return {
        ...state,
        payrollData: action.payrollData,
      };
    default:
      return state;
  }
}
