import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '@material-ui/core';
import { isMobile } from '../../../utils/breakpoints';
import styles from './styles.module.css';

export default function FakeInformationsModal() {
  return (
    <>
      <div className={styles.formGroup}>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 294, marginTop: isMobile && 16 }}
        >
          <Skeleton
            width={isMobile ? 192 : 280}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 164 }}
        >
          <Skeleton
            width={isMobile ? 192 : 164}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
      </div>
      <Typography className={styles.titleAddress}>Endereço</Typography>
      <div className={styles.formGroupAutoComplete} style={{ marginTop: 13 }}>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 164 }}
        >
          <Skeleton
            width={isMobile ? 192 : 160}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 294 }}
        >
          <Skeleton
            width={isMobile ? 192 : 280}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
      </div>

      <div className={styles.formGroupAutoComplete} style={{ marginTop: 13 }}>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 164 }}
        >
          <Skeleton
            width={isMobile ? 192 : 160}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 294 }}
        >
          <Skeleton
            width={isMobile ? 192 : 280}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
      </div>

      <div className={styles.formGroup}>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 248 : 478 }}
        >
          <Skeleton
            width={isMobile ? 192 : 465}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
      </div>

      <div className={styles.formGroupAutoComplete} style={{ marginTop: 13 }}>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 84 : 164 }}
        >
          <Skeleton
            width={isMobile ? 192 : 160}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
        <div
          className={styles.formField}
          style={{ width: isMobile ? 156 : 294 }}
        >
          <Skeleton
            width={isMobile ? 192 : 280}
            height={36}
            color="#202020"
            highlightColor="#444"
          />
        </div>
      </div>

      {/*
       // Todo (Colocar quando for feito os filtros e o envio de centro de custo)
      <div className={styles.formFieldSwitch}>
        <Typography className={styles.subtitleDialog}>
          Enviar cartão para o Centro de custo
        </Typography>
        <Skeleton width={56} height={36} color="#202020" highlightColor="#444" />
      </div> */}
    </>
  );
}
