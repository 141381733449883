import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

import {
  Button,
  IconButton,
  Typography,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import FakeExtract from './FakeExtract';
import Activities from '../Activities';

import styles from './styles.module.css';

import { ReactComponent as ChevronRight } from '../../../../assets/chevron-right.svg';
import { ReactComponent as Calendar } from '../../../../assets/calendar.svg';

import { api } from '../../../../services/api';

import currencyFormatter from '../../../../utils/currencyFormatter';
import { hasAuthorization, profiles } from '../../../../utils/hasAuthorization';
import { isMobile } from '../../../../utils/breakpoints';

function ExtractCard() {
  const [activeButton, setActiveButton] = useState(3);

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const { currentCompany } = useSelector(state => state.companies);
  const { accountSelected } = useSelector(state => state.account);

  const [transactions, setTransactions] = useState([]);
  const [errorTransactions, setErrorTransactions] = useState(false);
  const [emptyList, setEmptyList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(!isMobile ? 0 : 1);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (accountSelected?.encodedKey) {
      getExtract();
    }
  }, [activeButton, accountSelected?.encodedKey]);

  async function getExtract() {
    setLoading(true);

    const getAccountSelectedStore = Promise.resolve(
      accountSelected?.encodedKey,
    ).then(value => {
      return value;
    });

    const requestOptions = {
      headers: {
        depositAccountId: await getAccountSelectedStore,
        accountOrigin: accountSelected?.origin,
      },
      params: {
        lastDays: activeButton,
      },
    };
    api
      .get(`/transactions/bankStatement/${currentCompany.code}`, requestOptions)
      .then(({ data }) => {
        setTransactions(data);
        setErrorTransactions(false);
        setEmptyList(false);

        if (data.length === 0) {
          setEmptyList(true);
          setErrorTransactions(false);
          setFirstLoading(false);
        }
      })
      .catch(() => {
        setErrorTransactions(true);
        setEmptyList(false);
      })
      .finally(() => {
        setLoading(false);
        setFirstLoading(false);
      });
  }

  const onButtonFilterClicked = e => {
    switch (e.currentTarget.id) {
      case 'threeDays':
        setActiveButton(3);
        break;
      case 'sevenDays':
        setActiveButton(7);
        break;
      case 'fifteenDays':
        setActiveButton(15);
        break;
      case 'thirtyDays':
        setActiveButton(30);
        break;
    }
  };

  const handleChangeTab = (event, newValue) => {
    switch (newValue) {
      case 0:
        setActiveTab(0);
        break;
      case 1:
        setActiveTab(1);
        break;
    }
  };

  return (
    <div className={styles.card}>
      {!isMobile && (
        <AppBar elevation={0} position="static" className={styles.appBar}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            className={styles.tabs}
          >
            <Tab className={styles.tab} label="Atividades" />
            <Tab className={styles.tab} label="Extrato" />
          </Tabs>
        </AppBar>
      )}

      {activeTab === 0 ? (
        <>
          <Activities />
          <div className={styles.containerInfo}>
            <div>
              <IconButton className={styles.buttonInfo}>
                <InfoIcon className={styles.infoOutlinedIcon} />
              </IconButton>
            </div>

            <div className={styles.containerTitleInfo}>
              <Typography className={styles.titleInfo}>
                Fique atento as suas
              </Typography>
              <Typography className={styles.subTitleInfo}>
                atividades pendentes.
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.header}
            style={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.FINANCIAL,
              ])
                ? { cursor: 'initial' }
                : {}
            }
          >
            <Typography className={styles.headerTitle}>
              Extrato da conta
            </Typography>
            <IconButton
              className={styles.headerButton}
              onClick={() => history.push('/extract')}
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ])
              }
            >
              <ChevronRight style={{ width: 24, height: 24 }} />
            </IconButton>
          </div>
          <div className={styles.filters}>
            <Calendar className={styles.calendarIcon} />
            <Button
              className={
                activeButton === 3
                  ? styles.buttonFilterActive
                  : styles.buttonFilter
              }
              onClick={onButtonFilterClicked}
              id="threeDays"
            >
              3 DIAS
            </Button>
            <Button
              className={
                activeButton === 7
                  ? styles.buttonFilterActive
                  : styles.buttonFilter
              }
              onClick={onButtonFilterClicked}
              id="sevenDays"
            >
              7 DIAS
            </Button>
            <Button
              className={
                activeButton === 15
                  ? styles.buttonFilterActive
                  : styles.buttonFilter
              }
              onClick={onButtonFilterClicked}
              id="fifteenDays"
            >
              15 DIAS
            </Button>
            <Button
              className={
                activeButton === 30
                  ? styles.buttonFilterActive
                  : styles.buttonFilter
              }
              onClick={onButtonFilterClicked}
              id="thirtyDays"
            >
              30 DIAS
            </Button>
          </div>

          <Scrollbars className={styles.itens}>
            {firstLoading || ACCESS_PROFILES.length === 0 ? (
              <FakeExtract />
            ) : errorTransactions ? (
              <div className={styles.errorTransactionsContainer}>
                <Typography className={styles.errorTransactionsContainerText}>
                  Tivemos um problema ao carregar as movimentações. Por favor,
                  tente novamente.
                </Typography>
              </div>
            ) : emptyList ? (
              <div className={styles.emptyListContainer}>
                <Typography className={styles.emptyListContainerText}>
                  Não há registro de movimentações neste período.
                </Typography>
              </div>
            ) : (
              transactions.map(item => (
                <div className={styles.extractItem}>
                  <div className={styles.extractItemHeader}>
                    {loading ? (
                      <div style={{ margin: 10 }}>
                        <Skeleton
                          height={18}
                          width={80}
                          color="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    ) : (
                      <Typography className={styles.extractItemHeaderLabel}>
                        {moment(item.moveDate).format('DD/MM/YYYY')}
                      </Typography>
                    )}
                    {loading ? (
                      <div style={{ margin: 10 }}>
                        <Skeleton
                          height={18}
                          width={110}
                          color="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    ) : (
                      <Typography className={styles.extractItemHeaderLabel}>
                        Saldo:
                        <Typography
                          className={styles.extractItemHeaderLabelBlack}
                        >
                          {currencyFormatter(item.balanceAfter)}
                        </Typography>
                      </Typography>
                    )}
                  </div>
                  {item.transactionsDay.map(item => (
                    <div>
                      <div className={styles.informations}>
                        {loading ? (
                          <div>
                            <div style={{ position: 'relative', bottom: 1 }}>
                              <Skeleton
                                height={18}
                                width={80}
                                color="#202020"
                                highlightColor="#444"
                              />
                            </div>
                            <div style={{ position: 'relative', bottom: 1 }}>
                              <Skeleton
                                height={18}
                                width={110}
                                color="#202020"
                                highlightColor="#444"
                              />
                            </div>
                          </div>
                        ) : (
                          <div style={{ width: 200 }}>
                            <Typography className={styles.informationsTitle}>
                              {item.category}
                            </Typography>
                            <Typography
                              className={styles.informationsDescription}
                            >
                              {item.transactionChannel === 'migrations'
                                ? item.notes
                                : item.transactionType
                                ? item.transactionType
                                : 'Transação processada'}
                            </Typography>
                          </div>
                        )}
                        {loading ? (
                          <div>
                            <Skeleton
                              height={30}
                              width={90}
                              color="#202020"
                              highlightColor="#444"
                            />
                          </div>
                        ) : (
                          <div>
                            <Typography
                              className={styles.informationsValueLabel}
                            >
                              {currencyFormatter(item.amount)}
                            </Typography>
                          </div>
                        )}
                      </div>
                      <div className={styles.divider} />
                    </div>
                  ))}
                </div>
              ))
            )}
          </Scrollbars>
        </>
      )}
    </div>
  );
}

export default ExtractCard;
