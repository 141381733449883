import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';

import styles from './styles.module.css';

function DrawerContent({
  competence,
  paycheckType,
  setCompetence,
  setPaycheckType
}) {
  function onChangeCompetence(value) {
    setCompetence(value);
  };

  function onChangePayCheckType(event) {
    setPaycheckType(event.target.value);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={styles.textField2}
      >
        <InputLabel id="payment-type-select">
          Tipo
        </InputLabel>
        <Select
          labelId="payment-type-select"
          label="Tipo"
          onChange={onChangePayCheckType}
          value={paycheckType}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value='Todos'>
            Todos
          </MenuItem>
          <MenuItem value="SALARY">
            Salário
          </MenuItem>
          <MenuItem value="VACATION">
            Férias
          </MenuItem>
          <MenuItem value="THIRTEENTH_SALARY">
            13º Salário
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        className={styles.textField2}
      >
        <InputLabel
          id="select-date-label"
          style={competence ? { display: 'none' } : {}}
          className={
            competence ? styles.disabledInputLabel : styles.inputLabel
          }
        >
          Competência
        </InputLabel>
        <MuiPickersUtilsProvider
          utils={(DateFnsUtils, MomentUtils)}
          locale={'pt-br'}
        >
          <KeyboardDatePicker
            openTo="year"
            views={['year', 'month']}
            label={competence ? 'Competência' : null}
            value={competence ? competence : null}
            onChange={onChangeCompetence}
            inputVariant="outlined"
            keyboardIcon={<EventIcon />}
            InputAdornmentProps={{ position: 'end' }}
            helperText={false}
            error={false}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </>
  );
}

export default DrawerContent;
