export function removeInvalidObjects(obj) {
  const response = {};
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  values.forEach((item, index) => {
    if (
      item !== null &&
      item !== undefined &&
      item !== '' &&
      item !== ' ' &&
      !Number.isNaN(item)
    ) {
      Object.assign(response, { [keys[index]]: item });
    }
  });
  return response;
}
