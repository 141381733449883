import { reduxApi } from '../../../../services/reduxApi';

const approvedEmployeesTags = {
  tag: 'APPROVED_EMPLOYEES',
  listAbstractTag: { type: 'APPROVED_EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [approvedEmployeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      searchApprovedEmployees: builder.query({
        query: ({ params = {}, companyCode = '' }) => ({
          url: `/employee/search/${companyCode}`,
          params,
        }),
        providesTags: [approvedEmployeesTags.listAbstractTag],
      }),

      getLocations: builder.query({
        query: (companyCode = '') => `companies/${companyCode}/locations`,
      }),
    }),
  });

export const {
  useSearchApprovedEmployeesQuery,
  useLazyGetLocationsQuery,
} = slice;
