import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

function FakePaymentsTopFive() {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.contentDashboard}>
          <Skeleton
            color="#202020"
            highlightColor="#444"
            className={styles.skeletonTwo}
          />
        </div>
        <div className={styles.contentType}>
          <div className={styles.listPayment}>
            <Skeleton
              color="#202020"
              highlightColor="#444"
              className={styles.skeletonTwo}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FakePaymentsTopFive;
