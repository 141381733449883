import React from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Alert } from '@material-ui/lab';
import { ReactComponent as EqualsIcon } from '../../assets/equalsIcon.svg';

import styles from './styles.module.css';

import formatOnlyNumbers from '../../utils/formatOnlyNumbers';

function PrimaryModal({
  open,
  onCloseDialog,
  title,
  cancelButtonText,
  onCancelButtonClicked,
  onConfirmClicked,
  confirmButtonText,
  loading,
  loadingButton,
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  thirdLabel,
  thirdValue,
  feeAmount,
  signatureCharge,
  alertText,
  icon,
  disabledConfirmButton,
  codeVerification,
  codeValue,
  handleChangeCodeValue,
  errorMessage,
  resetError = () => {},
  emailLabel,
  pinOnConfirmPayroll,
  sendPinStatus,
  isImmediateLiberation = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      fullWidth
      maxWidth={isImmediateLiberation ? 'xs' : 'md'}
    >
      <div className={styles.container}>
        <div>
          {loading ? (
            <Skeleton
              height={24}
              width={310}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
              style={{ marginBottom: 5, display: 'block' }}
            />
          ) : (
            <Typography className={styles.title}>{title}</Typography>
          )}
        </div>

        <div className={styles.valuesGroup}>
          <div className={styles.block}>
            {loading ? (
              <Skeleton
                height={24}
                width={110}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.label}>{firstLabel}</Typography>
            )}
            {loading ? (
              <Skeleton
                height={24}
                width={110}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <div className={styles.valueContainer}>
                <Typography className={styles.value}>{firstValue}</Typography>
              </div>
            )}
          </div>

          <div className={styles.icon}>{icon}</div>

          <div className={styles.block}>
            {loading ? (
              <Skeleton
                height={24}
                width={110}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.label}>{secondLabel}</Typography>
            )}
            {loading ? (
              <Skeleton
                height={24}
                width={110}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <div className={styles.valueContainer}>
                <Typography className={styles.value}>{secondValue}</Typography>
              </div>
            )}
          </div>

          {!isImmediateLiberation && (
            <>
              <div className={styles.icon}>{icon}</div>

              <div className={styles.block}>
                {loading ? (
                  <Skeleton
                    height={24}
                    width={110}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                ) : (
                  <Typography className={styles.label}>
                    Valor da taxa
                  </Typography>
                )}
                {loading ? (
                  <Skeleton
                    height={24}
                    width={110}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                ) : (
                  <div className={styles.valueContainer}>
                    <Typography className={styles.value}>
                      {feeAmount}
                    </Typography>
                  </div>
                )}
              </div>

              <div className={styles.icon}>{icon}</div>

              <div className={styles.block}>
                {loading ? (
                  <Skeleton
                    height={24}
                    width={110}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                ) : (
                  <Typography className={styles.label}>
                    Valor da assinatura
                  </Typography>
                )}
                {loading ? (
                  <Skeleton
                    height={24}
                    width={110}
                    color="#202020"
                    highlightColor="#444"
                    className={styles.value}
                  />
                ) : (
                  <div className={styles.valueContainer}>
                    <Typography className={styles.value}>
                      {signatureCharge}
                    </Typography>
                  </div>
                )}
              </div>
            </>
          )}

          <div className={styles.icon}>
            <EqualsIcon />
          </div>

          <div className={styles.block}>
            {loading ? (
              <Skeleton
                height={24}
                width={110}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <Typography className={styles.label}>{thirdLabel}</Typography>
            )}
            {loading ? (
              <Skeleton
                height={24}
                width={110}
                color="#202020"
                highlightColor="#444"
                className={styles.value}
              />
            ) : (
              <div className={styles.valueContainer}>
                <Typography className={styles.valueDifference}>
                  {thirdValue}
                </Typography>
              </div>
            )}
          </div>
        </div>

        <Alert className={styles.alert} severity="info">
          {loading ? (
            <Skeleton
              height={24}
              width={240}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
              style={{ marginBottom: 10 }}
            />
          ) : (
            alertText
          )}
        </Alert>
        {/*
        <div className={styles.alert}>
          <ErrorOutlineIcon className={styles.errorIcon} />
          {loading ? (
            <Skeleton
              height={24}
              width={240}
              color="#202020"
              highlightColor="#444"
              className={styles.value}
              style={{ marginBottom: 10 }}
            />
          ) : (
            <Typography className={styles.alertText}>{alertText}</Typography>
          )}
        </div> */}

        {codeVerification && pinOnConfirmPayroll && (
          <Box mb={4}>
            <Typography className={styles.bigText}>
              {sendPinStatus === 'SMS_SENT'
                ? 'Digite o código enviado por SMS.'
                : false}
              {sendPinStatus === 'EMAIL_SENT'
                ? 'Digite o código enviado para seu e-mail.'
                : false}
            </Typography>

            <Typography className={styles.emailText}>
              {sendPinStatus === 'EMAIL_SENT' ? emailLabel : false}
              {!sendPinStatus ? (
                <Skeleton
                  height={21}
                  width={150}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                false
              )}
            </Typography>

            <TextField
              value={formatOnlyNumbers(codeValue)}
              placeholder="Código"
              variant="outlined"
              fullWidth
              onChange={handleChangeCodeValue}
              inputProps={{ maxLength: 6 }}
              disabled={loadingButton}
            />

            <Typography className={styles.helperText}>
              {sendPinStatus === 'SMS_SENT'
                ? 'Não recebeu o SMS? Tente realizar novamente a autorização.'
                : false}
              {sendPinStatus === 'EMAIL_SENT'
                ? 'Não recebeu o e-mail? Verifique as pastas Spam e Lixeira'
                : false}
            </Typography>

            {errorMessage && (
              <Alert
                className={styles.alert}
                severity="error"
                onClose={resetError}
              >
                {errorMessage}
              </Alert>
            )}
          </Box>
        )}

        <div className={styles.buttonsGroup}>
          {cancelButtonText && (
            <Button
              onClick={onCancelButtonClicked}
              className={styles.closeButton}
            >
              {cancelButtonText}
            </Button>
          )}

          <Button
            onClick={onConfirmClicked}
            className={styles.confirmButton}
            disabled={
              !!errorMessage ||
              disabledConfirmButton ||
              loadingButton ||
              (pinOnConfirmPayroll &&
                (!codeValue || (codeVerification && codeValue?.length < 4)))
            }
          >
            {loadingButton ? (
              <CircularProgress className={styles.loader} />
            ) : (
              confirmButtonText
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default PrimaryModal;
