import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Typography } from '@material-ui/core';

import Header from '../../../components/FormHeader';
import Footer from '../../../components/AnimatedBottomBar';
import ErrorModal from '../../../components/PrimaryModal';
import ResponsiveBottomBar from '../../../components/ResponsiveBottomBar';
import SuccessModal from '../../../components/SuccessModal';

import EditData from './EditData';

import { api } from '../../../services/api';

import { isMobile } from '../../../utils/breakpoints';
import convertFormErrorName from '../../../utils/convertFormErrorName';
import formatOnlyNumbers from '../../../utils/formatOnlyNumbers';

import styles from './styles.module.css';

export default function EmployeesRegister() {
  const history = useHistory();

  const [onSubmitForm, setOnSubmitForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [currentInsuranceType, setCurrentInsuranceType] = useState('');
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [personalDataForm, setPersonalDataForm] = useState({});
  const [professionalDataForm, setProfessionalDataForm] = useState({});
  const [salary, setSalary] = useState('');
  const [addressDataForm, setAddressDataForm] = useState([]);
  const [contactDataForm, setContactDataForm] = useState({});
  const [expandedTabs, setExpandedTabs] = useState([]);
  const [errors, setErrors] = useState([]);
  const [checkedTabs, setCheckedTabs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [stockings, setStockings] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);

  const [textFieldFocused, setTextFieldFocused] = useState('');
  const [category, setCategory] = useState(null);

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const currentCompanyIndex = JSON.parse(
    sessionStorage.getItem('currentCompanyIndex'),
  );
  const EMPLOYEE_REGISTRATION_DATA = JSON.parse(
    sessionStorage.getItem('EMPLOYEE_REGISTRATION_DATA'),
  );
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const COMPANY_NAME = companies[currentCompanyIndex || 0]?.name;

  const HIDDEN_FOOTER = checkedTabs.includes(
    'insurancesData' && 'personalData' && 'professionalData' && 'addressData',
  );

  useEffect(() => {
    setExpandedTabs(['insurancesData']);
  }, []);

  useEffect(() => {
    if (EMPLOYEE_REGISTRATION_DATA) {
      getInsuredDetails();
    }

    getLocations();
    getStockings();
    getInsuranceTypes();
  }, []);

  function handleCancel() {
    history.push('/insurance-management/');
  }

  function handleRegister() {
    const body = {
      category: category || null,

      insurances: [
        {
          type: currentInsuranceType,
        },
      ],

      personalData: {
        name: personalDataForm?.name,
        birthdate: moment(personalDataForm?.birthdate).format('YYYY-MM-DD'),
        gender: personalDataForm?.gender || null,
        rg: personalDataForm?.rg,
        rgIssuingDate: moment(personalDataForm?.rgIssuingDate).format(
          'YYYY-MM-DD',
        ),
        rgIssuingAgency: personalDataForm?.rgIssuingAgency,
        rgIssuingState: personalDataForm?.rgIssuingState || null,
        motherName: personalDataForm?.motherName,
        maritalStatus: personalDataForm?.maritalStatus || null,
        cpf: personalDataForm?.cpf || EMPLOYEE_REGISTRATION_DATA?.cpf,
      },

      professionalData: {
        companyName: professionalDataForm?.companyName || COMPANY_NAME,
        typeContract: professionalDataForm?.typeContract,
        employeeRegistration: professionalDataForm?.employeeRegistration,
        salary: formatOnlyNumbers(salary) / 100,
        admissionDate: moment(professionalDataForm?.admissionDate).format(
          'YYYY-MM-DD',
        ),
        stocking:
          stockings.filter(
            item => item.description === professionalDataForm.stocking,
          )[0]?.code || null,
        locatedAt:
          locations.filter(
            item => item.description === professionalDataForm.locatedAt,
          )[0]?.code || null,
        resignationDate: professionalDataForm?.resignationDate
          ? professionalDataForm?.resignationDate
          : null,
      },

      addresses: addressDataForm.map(item => ({
        street: item?.street,
        number: item?.number,
        complement: item?.complement,
        zipCode: item?.zipCode,
        neighborhood: item?.neighborhood,
        city: item?.city,
        state: item?.state,
        type: item?.type,
      })),

      contacts: {
        email: contactDataForm?.email,
        phones: contactDataForm?.phones,
      },
    };

    setRequestLoading(true);
    requestPost(body);
  }

  function requestPost(body) {
    api
      .post(`/insurance/v2/create/${CURRENT_COMPANY_CODE}`, body)
      .then(() => {
        setOpenSuccessModal(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrors(errors);
          setIsOpenErrorModal(true);
        },
      )
      .finally(() => {
        setRequestLoading(false);
      });
  }

  function handleExpandedTab(name) {
    const auxArray = [...expandedTabs];
    const index = auxArray.indexOf(name);
    const findName = expandedTabs.find(item => item === name);

    if (findName) {
      auxArray.splice(index, 1);
      setExpandedTabs([...auxArray]);
    } else {
      setExpandedTabs([...expandedTabs, name]);
    }
  }

  function getInsuredDetails() {
    const encodedKey = EMPLOYEE_REGISTRATION_DATA?.cpf;

    setLoading(true);

    api
      .get(`insurance/v2/${encodedKey}/${CURRENT_COMPANY_CODE}`)
      .then(({ data }) => {
        const personalData = data?.personalData;
        const professionalData = data?.professionalData;
        const addressData = data?.addresses;
        const contactData = data?.contacts;

        const {
          name,
          cpf,
          birthdate,
          gender,
          rg,
          rgIssuingDate,
          rgIssuingState,
          rgIssuingAgency,
          maritalStatus,
          motherName,
        } = personalData;

        const {
          companyName,
          employeeRegistration,
          stocking,
          locatedAt,
          employeeStatus,
          admissionDate,
          resignationDate,
          typeContract,
          dateCreationEmployee,
        } = professionalData;

        setCategory(data.category);

        setPersonalDataForm({
          name,
          cpf,
          birthdate,
          gender,
          rg,
          rgIssuingDate,
          rgIssuingState,
          rgIssuingAgency,
          maritalStatus,
          motherName,
        });

        setProfessionalDataForm({
          companyName,
          employeeRegistration,
          stocking,
          locatedAt,
          employeeStatus,
          admissionDate,
          resignationDate,
          typeContract,
          dateCreationEmployee,
        });

        setAddressDataForm(
          addressData.length > 0
            ? addressData.map(item => ({
                type: item.type,
                street: item.street,
                number: item.number,
                complement: item.complement,
                zipCode: item.zipCode,
                neighborhood: item.neighborhood,
                city: item.city,
                state: item.state,
              }))
            : [
                {
                  type: null,
                  street: null,
                  number: null,
                  complement: null,
                  zipCode: null,
                  neighborhood: null,
                  city: null,
                  state: null,
                },
              ],
        );

        setContactDataForm({
          email: contactData.email,
          phones: contactData?.phones?.map(item => ({
            type: item.type,
            number: item.number,
            isMain: item.isMain,
          })),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getLocations() {
    api
      .get(`companies/${CURRENT_COMPANY_CODE}/locations`)
      .then(({ data }) => setLocations(data));
  }

  function getStockings() {
    api
      .get(`companies/${CURRENT_COMPANY_CODE}/stockings`)
      .then(({ data }) => setStockings(data));
  }

  function getInsuranceTypes() {
    api.get('/insurance/v2/searchInsuranceTypes').then(({ data }) => {
      setInsuranceTypes(data);
    });
  }

  function validateInsurancesFormProgress() {
    if (currentInsuranceType) {
      return true;
    }

    return false;
  }

  function validatePersonalFormProgress() {
    const {
      name,
      birthdate,
      rg,
      rgIssuingAgency,
      rgIssuingDate,
      rgIssuingState,
      motherName,
    } = personalDataForm;

    if (
      name &&
      birthdate &&
      rg &&
      rgIssuingAgency &&
      rgIssuingDate &&
      rgIssuingState &&
      motherName
    ) {
      return true;
    }
    return false;
  }

  function validateProfessionalFormProgress() {
    const {
      typeContract,
      employeeRegistration,
      admissionDate,
    } = professionalDataForm;

    if (typeContract && employeeRegistration && admissionDate) return true;
    return false;
  }

  function validateAddressFormProgress() {
    if (addressDataForm[0]) {
      const {
        city,
        neighborhood,
        number,
        state,
        street,
        type,
        zipCode,
      } = addressDataForm[0];

      if (
        city &&
        neighborhood &&
        number &&
        state &&
        street &&
        type &&
        zipCode
      ) {
        return true;
      }
      return false;
    }
  }

  function validateContactFormProgress() {
    if (contactDataForm?.phones && contactDataForm?.phones.length > 0) {
      const { number, type } = contactDataForm?.phones[0];

      if (number && type) {
        return true;
      }
      return false;
    }
  }

  function handleSubmit() {
    setOnSubmitForm(true);
    validateFields();
  }

  function validateFields() {
    handleRegister();
  }

  function onChangeInsuranceType(event) {
    setCurrentInsuranceType(event.target.value);
  }

  function finishProcess() {
    history.push('/insurance-management');
  }

  function addNewInsured() {
    history.push('/insurance-management/insurance-single');
  }

  const CHECK_INSURANCES_FORM = validateInsurancesFormProgress();
  const CHECK_PERSONAL_FORM = validatePersonalFormProgress();
  const CHECK_PROFESSIONAL_FORM = validateProfessionalFormProgress();
  const CHECK_ADDRESS_FORM = validateAddressFormProgress();
  const CHECK_CONTACT_FORM = validateContactFormProgress();

  return (
    <div>
      <Header
        title="Cadastro individual de segurado(a)"
        alternativeTitle="Campos com (*) são de preenchimento obrigatório."
        hidden={isMobile}
      />

      <EditData
        loading={loading}
        requestLoading={requestLoading}
        personalDataForm={personalDataForm}
        setPersonalDataForm={setPersonalDataForm}
        professionalDataForm={professionalDataForm}
        setProfessionalDataForm={setProfessionalDataForm}
        salary={salary}
        setSalary={setSalary}
        addressDataForm={addressDataForm}
        setAddressDataForm={setAddressDataForm}
        contactDataForm={contactDataForm}
        setContactDataForm={setContactDataForm}
        setOnSubmitForm={setOnSubmitForm}
        onSubmitForm={onSubmitForm}
        handleExpandedTab={handleExpandedTab}
        expandedTabs={expandedTabs}
        textFieldFocused={textFieldFocused}
        setTextFieldFocused={setTextFieldFocused}
        CHECK_INSURANCES_FORM={CHECK_INSURANCES_FORM}
        CHECK_PERSONAL_FORM={CHECK_PERSONAL_FORM}
        CHECK_PROFESSIONAL_FORM={CHECK_PROFESSIONAL_FORM}
        CHECK_ADDRESS_FORM={CHECK_ADDRESS_FORM}
        CHECK_CONTACT_FORM={CHECK_CONTACT_FORM}
        setExpandedTabs={setExpandedTabs}
        COMPANY_NAME={COMPANY_NAME}
        setCheckedTabs={setCheckedTabs}
        checkedTabs={checkedTabs}
        locations={locations}
        stockings={stockings}
        insuranceTypes={insuranceTypes}
        onChangeInsuranceType={onChangeInsuranceType}
        currentInsuranceType={currentInsuranceType}
      />

      <ErrorModal
        title="Tivemos um problema no cadastro"
        text={
          errors[0]?.type === null ? (
            errors[0]?.error
          ) : (
            <span>
              Para seguir com o cadastro é necessário que os itens abaixo sejam
              revistos:
              <br />
              <br />
              {errors.map(item => (
                <Typography className={styles.errorListLabel}>
                  • {item?.error}
                </Typography>
              ))}
            </span>
          )
        }
        onConfirmClicked={() => setIsOpenErrorModal(false)}
        open={isOpenErrorModal}
        confirmButtonText="Entendi"
      />

      <SuccessModal
        open={openSuccessModal}
        title="Segurado adicionado!"
        text="Agora você pode editar a situação dos seus segurados e adicionar outros."
        titleMarginTop={3}
        onCloseDialog={addNewInsured}
        onNextButtonClicked={finishProcess}
        nextButton
        nextButtonText="Continuar"
        closeButtonText="Adicionar outro"
      />

      <ResponsiveBottomBar
        cancelButtonText="Cancelar"
        confirmButtonText="Salvar"
        hidden={!isMobile}
        onCancel={handleCancel}
        onConfirm={handleSubmit}
        loading={requestLoading}
      />

      <Footer
        confirmButtonText="Salvar"
        cancelButtonText="Voltar"
        hidden={!HIDDEN_FOOTER || isMobile}
        oragenNextButton
        onCancel={handleCancel}
        onConfirm={handleSubmit}
        loading={requestLoading}
      />
    </div>
  );
}
