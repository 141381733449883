import React from 'react';
import { Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import { useStyles } from './style';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0, outline: 'none' },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default function SpringModal({ modal, closeModal, showBalance }) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={modal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <p className={classes.modalTitle}>
              Trocar visualização de empresa agora?
            </p>
            <p id="spring-modal-description" className={classes.modalText}>
              Por favor, verifique se você salvou todas as alterações antes de
              trocar de visualização.
            </p>
            <div className={classes.linkModal}>
              <div>
                <Button
                  className={classes.cancelButton}
                  onClick={closeModal}
                >
                  Cancelar
                </Button>
              </div>
              <div>
                <Button
                  className={classes.confirmButton}
                  onClick={showBalance}
                >
                  Trocar Visualização
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
