import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as DollarIcon } from "../../assets/dollar-icon.svg";

import styles from "./styles.module.css";

function NoList({ text, icon, secondText, thirdText }) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.notFoundContainer}
    >
      <Grid item className={styles.notFoundIcon}>
        <DollarIcon />
      </Grid>
      <Grid item>
        <Typography className={styles.text}>{text}</Typography>
      </Grid>
      <Grid item className={styles.secondTextContainer}>
        <Typography className={styles.secondText}>{secondText}</Typography>
        <Typography className={styles.secondText}>{thirdText}</Typography>
      </Grid>
    </Grid>
  );
}

export default NoList;
