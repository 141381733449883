import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import moment from 'moment';

import styles from './styles.module.css';

export default function HistoryTable({ historyData }) {
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Tipo</TableCell>
            <TableCell>Usuário</TableCell>
            <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {historyData?.map((row) => (
            <TableRow key={row?.cpf} className={styles.tableRow}>
              <TableCell>{row?.occurrenceType}</TableCell>
              <TableCell>{row?.inclusionUserName}</TableCell>
              <TableCell>{moment(row?.occurrenceDate).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
