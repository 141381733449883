/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: 'Não é válido',
    required: '*Obrigatório',
    min: 'Deve ser maior que ${min} caracteres',
    max: 'Excede ${max} caracteres',
    notType: function notType(_ref) {
      switch (_ref.type) {
        case 'number':
          return 'O valor deste campo deve ser um número.';
        case 'string':
          return 'Valor inválido.';
        case 'date':
          return 'Data inválida.';
        default:
          return 'Valor inválido.';
      }
    },
  },
  string: {
    default: 'Não é válido',
    required: '*Obrigatório',
    positive: 'Por favor insira um valor valido',
    length: 'Deve possuir ${length} caracteres',
    min: 'Deve ser maior que ${min} caracteres',
    max: 'Deve ser menor que ${max} caracteres',
    email: 'Email deve ser válido',
    url: 'Por favor insira https:// antes da url',
  },
  number: {
    default: 'Não é válido',
    positive: 'Não deve ser um número negativo',
    length: 'Deve possuir no minimo ${length} caracteres',
    required: '*Obrigatório',
    min: 'Deve ser maior que ${min}',
    max: 'Deve ser menor que ${max}',
  },
  date: {
    default: 'Data inválida',
    required: '*Obrigatório',
    min: 'Deve ser maior que ${min}',
    max: 'Deve ser menor que ${max}',
  },
  array: {
    default: 'Não é válido',
    required: '*Obrigatório',
    min: 'Minimo de ${min} itens',
    max: 'Máximo ${max} itens',
  },
});
