import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';

import styles from './styles.module.css';

export default function AnimetedBottomBar({
  onCancel,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  hidden,
  oragenNextButton,
  loading,
  hiddenNextButton,
  animeted,
  disabledNextButton,
}) {
  return (
    <Grid
      className={
        hidden
          ? styles.hidden
          : animeted
          ? styles.container
          : styles.defaultContainer
      }
      container
      justify="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Button className={styles.outlinedButton} onClick={onCancel}>
          {cancelButtonText || 'Voltar'}
        </Button>
      </Grid>

      <Grid item>
        {!hiddenNextButton && (
          <Button
            className={
              oragenNextButton ? styles.orangeButton : styles.purpleButton
            }
            onClick={onConfirm}
            disabled={disabledNextButton}
          >
            {loading ? (
              <CircularProgress className={styles.loader} />
            ) : (
              confirmButtonText
            )}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
