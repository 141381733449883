import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function FakeRowsTable() {

	function renderFakeRow() {
		return (
			<>
				<TableRow>
					<TableCell component="th" scope="row">
						<Skeleton animation="wave" variant="text" width={200} />
					</TableCell>
					<TableCell>
						<Skeleton animation="wave" variant="text" width={200} />
					</TableCell>
					<TableCell>
						<Skeleton animation="wave" variant="text" width={200} />
					</TableCell>
					<TableCell>
						<Skeleton animation="wave" variant="text" width={200} />
					</TableCell>
					<TableCell>
						<Skeleton animation="wave" variant="text" width={200} />
					</TableCell>
					<TableCell>
						<Skeleton animation="wave" variant="text" width={200} />
					</TableCell>
				</TableRow>
			</>
		);
	}

	return (
		<>
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
		</>
	)
}