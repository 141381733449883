import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../../../../../components/Form';
import CashOutPixBottomBar from '../../components/BottomBar';
import { PixContext } from '../../components/Context';

import validations from './validations';
import { CashOutActions as Actions } from '../../../../../redux/store/CashOut';

export default function CopyPastePixTab() {
  const dispatch = useDispatch();

  const { onNext, isLoading, defaultValues } = useContext(PixContext);

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues,
  });

  const onSubmit = (form, event) => {
    event.preventDefault();
    dispatch(Actions.getDetailsPixCopyPaste(form));
    onNext({ ...form, keyType: 'RANDOM_KEY' });
  };

  return (
    <Form.FormBase onSubmit={() => handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Controller
            control={control}
            name="emv"
            render={({ field }) => (
              <Form.TextField
                size="large"
                label="Chave de pagamento*"
                error={Boolean(errors?.emv)}
                helperText={errors?.emv?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <Form.CurrencyField
                size="large"
                label="Valor do Pix*"
                error={Boolean(errors?.value)}
                helperText={errors?.value?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Form.TextField
                size="large"
                label="Descrição"
                error={Boolean(errors?.description)}
                helperText={errors?.description?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CashOutPixBottomBar disabled={!isValid} isLoading={isLoading} />
    </Form.FormBase>
  );
}
