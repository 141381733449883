import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export default function useCreateModal({ onCreate }) {
  const validations = yup.object().shape({
    ip: yup
      .string()
      .required()
      .matches(/^(\d{1,3}\.){3}\d{1,3}$/, 'Insira um endereço de IP válido.'),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues: {
      ip: '',
    },
  });

  const onSubmit = () =>
    handleSubmit((form, event) => {
      event.preventDefault();
      onCreate(form);
    });

  return {
    control,
    onSubmit,
    isValid,
    isSubmitting,
    errors,
  };
}
