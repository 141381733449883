const initialState = {
  date: '',
  paymentDescription: '',
  paymentType: '',
  loadingPayment: true
};

export default function manualPayroll(state = initialState, action) {
  switch (action.type) {
    case 'SET_MANUALPAYROLL_DATE':
      return {
        ...state,
        date: action.date,
      };
    case 'SET_MANUALPAYROLL_PAYMENT_DESCRIPTION':
      return {
        ...state,
        paymentDescription: action.paymentDescription,
      };
    case 'SET_MANUALPAYROLL_PAYMENT_TYPE':
      return {
        ...state,
        paymentType: action.paymentType,
      };
    case 'SET_LOADING_PAYMENT':
      return {
        ...state,
        loadingPayment: action.loadingPayment,
      };
    default:
      return state;
  }
}
