import somapayLogoAnimation from '../../../../lotties/somapayLogo.json';

export const COUNTDOWN_TIME = 60;

export const ANIMATION_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: somapayLogoAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const AVAILABLE_PAYMENT_HOURS = [
  { value: '21:00:00', description: '21:00' },
  { value: '20:00:00', description: '20:00' },
  { value: '19:00:00', description: '19:00' },
  { value: '18:00:00', description: '18:00' },
  { value: '17:00:00', description: '17:00' },
  { value: '16:00:00', description: '16:00' },
  { value: '15:00:00', description: '15:00' },
  { value: '14:00:00', description: '14:00' },
  { value: '13:00:00', description: '13:00' },
  { value: '12:00:00', description: '12:00' },
  { value: '11:00:00', description: '11:00' },
  { value: '10:00:00', description: '10:00' },
  { value: '09:00:00', description: '09:00' },
  { value: '08:00:00', description: '08:00' },
  { value: '07:00:00', description: '07:00' },
  { value: '06:00:00', description: '06:00' },
  { value: '05:00:00', description: '05:00' },
  { value: '04:00:00', description: '04:00' },
];
