import React, { useEffect } from 'react';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import PrintIcon from '@material-ui/icons/Print';
import TodayIcon from '@material-ui/icons/Today';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { IconButton, Typography } from '@material-ui/core';

import styles from './styles.module.css';

import { ReactComponent as ExpressPaymentIcon } from '../../../../assets/release-payment.svg';

export default function BottomSheet({
    setOpenBottomSheet,
    openBottomSheet,
    status,
    setOpenImmediateReleaseModal,
    setOpenRescheduleModal,
    setOpenCancelModal,
    getRates,
    onPaymentClick,
    handleDownload
}) {

    const handleAuthorizePayroll = () => {
        onPaymentClick();
        setOpenBottomSheet(false);
    }

    const handleImmediateRelease = () => {
        setOpenImmediateReleaseModal(true);
        setOpenBottomSheet(false);
        getRates();
    }

    const handleChangeDate = () => {
        setOpenRescheduleModal(true);
        setOpenBottomSheet(false);
    }

    const handleUndoSchedule = () => {
        setOpenCancelModal(true);
        setOpenBottomSheet(false);
    }

    const renderOptions = () => {
        switch(status) {
            case 'Aguardando Autorização':
              return (
                <div className={styles.centered}>
                  <div
                    onClick={handleAuthorizePayroll}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButton}>
                        <CheckRoundedIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                        Autorizar pagamento
                    </Typography>
                  </div>

                  <div
                    onClick={handleChangeDate}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButton}>
                        <TodayIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                        Alterar<br /> data
                    </Typography>
                  </div>

                  <div
                    onClick={handleUndoSchedule}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButton}>
                        <EventBusyIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                        Desfazer agendamento
                    </Typography>
                  </div>

                  <div
                    onClick={handleDownload}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButtonPrint}>
                      <PrintIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                      Imprimir
                    </Typography>
                  </div>
                </div>
              )
            case 'Autorizada':
              return (
                <div className={styles.centered}>
                  <div
                      onClick={handleImmediateRelease}
                      className={styles.bottomSheetBlock}
                  >
                      <IconButton className={styles.bottomSheetBlockIconButton}>
                          <ExpressPaymentIcon />
                      </IconButton>
                      <Typography className={styles.bottomSheetBlockTitle}>
                          Liberação imediata
                      </Typography>
                  </div>

                  <div
                      onClick={handleUndoSchedule}
                      className={styles.bottomSheetBlock}
                  >
                      <IconButton className={styles.bottomSheetBlockIconButton}>
                          <EventBusyIcon />
                      </IconButton>
                      <Typography className={styles.bottomSheetBlockTitle}>
                          Desfazer agendamento
                      </Typography>
                  </div>

                  <div
                    onClick={handleDownload}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButtonPrint}>
                      <PrintIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                      Imprimir
                    </Typography>
                  </div>
                </div>
              )
            case 'Expirada':
              return (
                <div className={styles.centered}>
                  <div
                    onClick={handleAuthorizePayroll}
                    className={styles.bottomSheetBlock}
                  >
                      <IconButton className={styles.bottomSheetBlockIconButton}>
                          <CheckRoundedIcon />
                      </IconButton>
                      <Typography className={styles.bottomSheetBlockTitle}>
                          Autorizar pagamento
                      </Typography>
                  </div>

                  <div
                    onClick={handleChangeDate}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButton}>
                        <TodayIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                        Alterar<br /> data
                    </Typography>
                  </div>

                  <div
                    onClick={handleDownload}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButtonPrint}>
                      <PrintIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                      Imprimir
                    </Typography>
                  </div>
              </div>
            )
            default:
              return (
                <div className={styles.centered}>
                  <div
                    onClick={handleDownload}
                    className={styles.bottomSheetBlock}
                  >
                    <IconButton className={styles.bottomSheetBlockIconButtonPrint}>
                      <PrintIcon />
                    </IconButton>
                    <Typography className={styles.bottomSheetBlockTitle}>
                      Imprimir
                    </Typography>
                  </div>
                </div>
              )
        }
    }

    return (
        <SwipeableBottomSheet
          overflowHeight={41}
          open={openBottomSheet}
          bodyStyle={{
            borderRadius: '12px 12px 0px 0px',
            background: '#FFFFFF',
            boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.12)',
            height: '150px',
          }}
          onChange={() => setOpenBottomSheet(!openBottomSheet)}
        >
          <div
            className={!openBottomSheet && styles.bottomSheetInactiveContainer}
          >
            {openBottomSheet ? (
              <div className={styles.bottomSheetContainer}>
                  {renderOptions()}
              </div>
            ) : (
              <div className={styles.bottomSheetDivider}/>
            )}
          </div>
        </SwipeableBottomSheet>
    )
}
