import React from 'react';
import { Box, Typography } from '@material-ui/core';
import LottiesScreen from '../../../../../LottiesScreen';

export function Empty() {
  return (
    <Box sx={{ p: '2rem 1rem' }}>
      <LottiesScreen.TableEmpty />
      <Typography
        variant="h5"
        style={{ mt: '1.5rem', fontWeight: '700', textAlign: 'center' }}
      >
        Nada encontrado
      </Typography>
    </Box>
  );
}
