/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { api } from '../../../../services/api';
import { PaginationConstructor } from '../../helpers/PaginationConstructor';

const onboardingReprovedBase = new PaginationConstructor({
  extraStates: {
    onboardingReproved: {
      employeesReprovedList: [],
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
    },
    bankListHandling: {
      bankList: [],
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
    },
    employeeData: {
      employeeBankData: {},
      isLoading: false,
      successOn: {
        hasSuccess: false,
        message: '',
      },
      errorOn: {
        hasError: false,
        message: '',
      },
    },
    filter: {
      nameOrCpf: null,
      register: null,
      bankData: null,
      onboardingSituation: null,
    },
  },
});

const searchOnboardingReprovedEmployees = createAsyncThunk(
  'onboardingReproved/search/onboardingReprovedEmployees',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const {
      filter: { nameOrCpf, registration, onboardingSituation, bankData },
      page,
      perPage,
    } = getState().employees.onboardingReprovedGeneral;
    const { currentCompany } = getState().companies;
    const requestConfig = {
      params: {
        page,
        size: perPage,
      },
    };

    const requestBody = {
      nameOrCpf: nameOrCpf ?? null,
      registration: registration ?? null,
      hasBankData: bankData ?? null,
      situationType: onboardingSituation ?? null,
    };

    return api
      .post(
        `/employees/reproved/${currentCompany.code}`,
        requestBody,
        requestConfig,
      )
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getBankList = createAsyncThunk(
  'onboardingReproved/get/bankList',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    return api
      .get('accountCashIn/bank/findAll')
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const updateEmployeeBankData = createAsyncThunk(
  'onboardingReproved/update/employeeBankData',
  async (payload, { rejectWithValue }) => {
    return api
      .patch('/employees/bankData', payload)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const onboardingReprovedSlice = createSlice({
  name: 'onboardingReprovedGeneral',
  initialState: onboardingReprovedBase.initialState,
  reducers: {
    ...onboardingReprovedBase.methods,
    setEmployeeBankData: (state, { payload }) => {
      state.employeeData.employeeBankData = {
        ...state.employeeData.employeeBankData,
        ...payload,
      };
    },

    resetEmployeeBankDataErrorStatus: state => {
      state.employeeData.errorOn = {
        hasError: false,
        message: '',
      };
    },

    resetEmployeeData: state => {
      state.employeeData.employeeBankData = {};
    },
  },
  extraReducers: {
    // SEARCH ONBOARDING REPROVED EMPLOYEES

    [searchOnboardingReprovedEmployees.pending]: state => {
      state.onboardingReproved.isLoading = true;
      state.onboardingReproved.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [searchOnboardingReprovedEmployees.fulfilled]: (state, { payload }) => {
      state.onboardingReproved.isLoading = false;
      state.onboardingReproved.employeesReprovedList = payload.data.content;
      state.perPage = payload.data.currentPageSize;
      state.length = payload.data.totalSize;
    },

    [searchOnboardingReprovedEmployees.rejected]: (state, { payload }) => {
      state.onboardingReproved.isLoading = false;
      state.onboardingReproved.errorOn.hasError = true;

      if (!payload) {
        state.onboardingReproved.errorOn.message =
          'Houve um erro ao buscar os arquivos de cadastro';
      } else state.onboardingReproved.errorOn.message = payload;
    },
    // GET BANK LIST

    [getBankList.pending]: state => {
      state.bankListHandling.isLoading = true;
      state.bankListHandling.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [getBankList.fulfilled]: (state, { payload }) => {
      state.bankListHandling.isLoading = false;
      state.bankListHandling.bankList = payload.data;
    },
    [getBankList.rejected]: (state, { payload }) => {
      state.bankListHandling.isLoading = false;
      state.bankListHandling.errorOn.hasError = true;

      if (!payload) {
        state.bankListHandling.errorOn.message =
          'Houve um erro ao bustar lista de bancos';
      } else state.bankListHandling.errorOn.message = payload;
    },
    // UPDATE EMPLOYEE BANK DATA

    [updateEmployeeBankData.pending]: state => {
      state.employeeData.isLoading = true;
      state.employeeData.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [updateEmployeeBankData.fulfilled]: (state, { payload }) => {
      state.employeeData.isLoading = false;
      state.employeeData.successOn = {
        hasSuccess: true,
        message: 'Seus dados foram editados com sucesso!',
      };
    },
    [updateEmployeeBankData.rejected]: (state, { payload }) => {
      state.employeeData.isLoading = false;
      state.employeeData.errorOn.hasError = true;

      if (!payload) {
        state.employeeData.errorOn.message =
          'Houve um erro ao registrar seus dados bancários';
      } else state.employeeData.errorOn.message = payload;
    },
  },
});

const OnboardingReprovedGeneralReducer = onboardingReprovedSlice.reducer;
const ActionsOnboardingReprovedGeneral = {
  ...onboardingReprovedSlice.actions,
  searchOnboardingReprovedEmployees,
  updateEmployeeBankData,
  getBankList,
};

export { OnboardingReprovedGeneralReducer, ActionsOnboardingReprovedGeneral };
