export const hasAuthorization = (userRoles, appPermissions) => {
    const permission = userRoles?.filter(function(e) {
      return appPermissions?.indexOf(e) > -1;
    });
    
    if (permission?.length > 0 || userRoles?.length === 0) {
      return true;
    }

    return false
}

export const profiles = {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER'
}