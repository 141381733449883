import { api } from '../../../../services/api';

export default class CashInServices {
  static cashInSearch(object) {
    api
      .get(`/cashIn/search/${object.currentCompanyCode}`, object.requestOptions)
      .then(({ data }) => {
        const dataResult = JSON.stringify(data.cashIns, function(key, value) {
          if (value === 'null') {
            return '( Em branco )';
          }
          return value;
        });

        object.setStates({
          dataResult: JSON.parse(dataResult),
          totalSize: data.totalSize,
          currentPageSize: data.currentPageSize,
          loading: false,
          loadingLogo: false,
          loadingList: false,
        });
      });
  }
}
