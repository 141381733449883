import React from 'react';

import { Button, Grid } from '@material-ui/core';

import styles from './styles.module.css';

export default ({ handleGoBack, hidden }) => {
  return (
    <Grid
      className={hidden ? styles.hidden : styles.bottomBarContainer}
      container
    >
      <Grid item>
        <Button className={styles.goBackButton} onClick={handleGoBack}>
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
};
