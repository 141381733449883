import React from 'react';
import {
  Checkbox,
  withStyles
} from '@material-ui/core';

const GrayCheckbox = withStyles({
  root: {
    color: '#4F4F4F',
    '&$checked': {
      color: '#4F4F4F',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export default GrayCheckbox;
