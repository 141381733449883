import React from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Controller } from 'react-hook-form';
import { AccordionActions, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Form from '../../../../components/Form';
import Buttons from '../../../../components/Buttons';
import SettingMenu from '../SettingMenu';

import useAccessSettings from '../../hooks/useAccessSettings';
import useAccessHours from './hooks/useAccessHours';

import { useStyles } from '../../styles';

const ID = 'access-hours';

export default function AccessHourConfig() {
  const styles = useStyles();

  const {
    control,
    onSubmit,
    isValid,
    isLoading,
    errors,
    hours: { initial, final, initialScheduled, finalScheduled },
    alert,
    selectOptions,
  } = useAccessHours();

  const formatTime = time => (time ? moment(time, 'HH').format('HH:mm') : '-');

  const hoursSubtitle = `O horário atual de liberação de folha é de ${formatTime(
    initial,
  )} às ${formatTime(final)}`;

  const scheduleAlert = `Alteração agendada: ${formatTime(
    initialScheduled || initial,
  )} - ${formatTime(finalScheduled || final)}`;

  return (
    <SettingMenu
      id={ID}
      title="Horários de liberação de folha"
      subtitle={hoursSubtitle}
      alert={!!alert && scheduleAlert}
    >
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale="pt-br"
      >
        <Form.FormBase className={styles.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="initialMovementHour"
                render={({ field }) => (
                  <Form.SelectField
                    type="time"
                    size="medium"
                    label="Horário inicial"
                    options={selectOptions}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors?.initialMovementHour}
                    helperText={errors?.initialMovementHour?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="finalMovementHour"
                render={({ field }) => (
                  <Form.SelectField
                    type="time"
                    size="medium"
                    label="Horário final"
                    options={selectOptions}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors?.finalMovementHour}
                    helperText={errors?.finalMovementHour?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <AccordionActions className={styles.accordionActions}>
            <Buttons.PrimaryButton
              title="Salvar"
              size="medium"
              type="submit"
              disabled={!isValid}
              loading={isLoading}
            />
          </AccordionActions>
        </Form.FormBase>
      </MuiPickersUtilsProvider>
    </SettingMenu>
  );
}
