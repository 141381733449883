import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(_ =>
  createStyles({
    root: {
      padding: '1rem',
    },
    title: {
      padding: 0,
      fontWeight: 700,
      fontSize: '1.25rem',
      marginBottom: '0.75rem',
      color: '#1A1A1A',
    },
    content: {
      padding: '1rem 0',
    },
  }),
);

export default useStyles;
