import { createSlice } from '@reduxjs/toolkit';

const PixStatusSlice = createSlice({
  name: 'pixStatus',
  initialState: {
    isValidating: false,
    isSending: false,
    hasError: false,
    message: '',
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isSending = payload;
    },
    setValidating: (state, { payload }) => {
      state.isValidating = payload;
    },
    setError: (state, { payload }) => {
      state.hasError = true;
      state.message = payload;
    },
    clearError: state => {
      state.hasError = false;
      state.message = '';
    },
  },
});

const pixStatusReducer = PixStatusSlice.reducer;
const PixStatusActions = {
  ...PixStatusSlice.actions,
};

export { pixStatusReducer, PixStatusActions };
