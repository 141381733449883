import React from 'react';
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as HelpIcon } from '../../assets/helpIcon.svg';
import { ReactComponent as ChevronRightCircle } from '../../assets/chevronRightCircle.svg';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    zIndex: 10,
    background: 'transparent',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
      height: '100px',
    }),
    marginRight: 100,
    boxShadow: 'none',
    background: 'transparent',
  },
  title: {
    flexGrow: 1,
    position: 'relative',
    color: '#1B181C',
    fontStyle: 'normal',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '100%',
    letterSpacing: '-0.5px',
    top: 27,
    marginBottom: 22,
  },
  content: {
    marginTop: 30,
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'inherit',
    position: 'scroll',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display: 'flex',
    position: 'absolute',
  },
  drawerPaper: {
    height: 'calc(100% - 128px)',
    marginTop: '64px',
    width: drawerWidth,
    overflow: 'visible',
    overflowY: 'scroll',
    borderWidth: '0.5px  rgba(0,0,0,.3)',
    boxShadow: '-5px 0px 6px -5px rgba(0,0,0,0.4)',
    padding: theme.spacing(0, 3),
    ...theme.mixins.toolbar,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  borderRightDrawer: {
    border: '3px solid #ffffff',
    height: '100vh',
    position: 'absolute',
    right: 0,
    bottom: 0,
    boxShadow: '-2px 0px 6px rgb(0 0 0 / 12%)'
  }
}));

export default function PersistentDrawerRight(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} style={props.hidden ? { display: 'none' } : {}}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            className={classes.title}
          ></Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.show)}
            style={{ position: 'relative', top: 62, left: 10 }}
          >
            <Tooltip title="Painel de Ajuda">
              <HelpIcon />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className={!open && classes.borderRightDrawer} />

      <Drawer
        onClick={props.onClick}
        variant="persistent"
        BackdropProps={{ invisible: true }}
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Tooltip title="Esconder Painel">
            <IconButton
              onClick={handleDrawerClose}
              className={clsx(open && classes.show)}
              style={{
                position: 'fixed',
                right: 270,
                top: 70,
              }}
            >
              <ChevronRightCircle />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" noWrap className={classes.title}>
            {props.title}
          </Typography>
        </div>
        <div className={classes.content}>{props.content}</div>
      </Drawer>
    </div>
  );
}
