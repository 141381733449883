import React from 'react';

import ProgressToolBar from '../../../components/ProgressToolBar';
import { Header } from '../../../components/Header';
import GenericModal from '../../../components/PrimaryModal';
import { ErrorsCard } from './Components/ErrorsCard';
import { usePayrollErrors } from './Hooks/usePayrollErrors';
import { AddBankDataModal } from './Components/AddBankDataModal';
import CustomSnackbar from '../../../components/Snackbar';

const PayrollRegisterBatchList = () => {
  const {
    errorsObject,
    warningModalObject,
    addBankDataModalObject,
    bottomBarObject,
    snackbarObject,
  } = usePayrollErrors();

  return (
    <>
      <Header title="Erros na folha de pagamento" />

      <ErrorsCard
        generalData={errorsObject.generalData}
        errorsList={errorsObject.errorsList}
        isFileXls={errorsObject.isFileXls}
        handleEditEmployeeBankData={errorsObject.handleEditEmployeeBankData}
      />

      <AddBankDataModal
        employeeName={addBankDataModalObject.employeeToEditBankData?.name}
        employeeCpfOrRegistrationTitle={
          addBankDataModalObject.employeeToEditBankData?.title
        }
        employeeCpfOrRegistration={
          addBankDataModalObject.employeeToEditBankData?.cpfOrRegistration
        }
        defaultValues={addBankDataModalObject.addBankDataFormDefaultValues}
        open={addBankDataModalObject.isOpenAddBankDataModal}
        handleCloseModal={addBankDataModalObject.handleCloseModal}
        handleBankFormSubmit={addBankDataModalObject.handleAddBankDataSubmit}
        title="Adicionar dados bancários"
        text="Preencha todos os campos para concluir o registros dos dados bancários."
      />

      <ProgressToolBar
        loading={false}
        disableBack
        onNextClicked={bottomBarObject.handleGoBackToImportFileScreen}
        nextButtonText="Reenviar arquivo"
      />

      <CustomSnackbar
        open={snackbarObject?.open}
        message={snackbarObject?.message}
        severity="success"
      />
    </>
  );
};

export default PayrollRegisterBatchList;
