import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { FileCopyOutlined, AddCircle } from '@material-ui/icons';

import CashOutBillBottomBar from '../BottomBar';
import BillModal from '../Modals/EditDateModal';
import Buttons from '../../../../../components/Buttons';
import Form from '../../../../../components/Form';

import currencyFormatter from '../../../../../utils/currencyFormatter';

import { ReactComponent as Success } from '../../../../../assets/successAnimation.svg';

import { useStyles } from './styles';

const INITIAL_TOOLTIP = 'Copiar para a área de transferência';

export default function CashOutBillReviewStep({
  onBack,
  onEdit,
  isLoading,
  onNext,
}) {
  const styles = useStyles();
  const history = useHistory();

  const { handleSubmit } = useForm();

  const [tooltipText, setTooltipText] = useState(INITIAL_TOOLTIP);

  const {
    digitable,
    currentBill,
    actionResults: { bill },
  } = useSelector(state => state.cashOut);

  const hasDifferenceFromInitialValue =
    currentBill?.discountAmount > 0 || currentBill?.interestAmount > 0;

  const copyCode = () => {
    navigator.clipboard.writeText(digitable);

    setTooltipText('Código copiado!');

    setTimeout(() => setTooltipText(INITIAL_TOOLTIP), 1000);
  };

  const onOpenDateModal = () => {
    NiceModal.show('edit-bill-due-date', {
      onEdit,
    });
  };

  const onSubmit = (_, event) => {
    event.preventDefault();
    onNext();
  };

  const handleBack = () => {
    if (bill.hasSuccess) {
      window.location.reload();
      return;
    }

    onBack();
  };

  const goToManageScreen = () => {
    history.push('/cash-out/manage');
  };

  const renderValue = () => {
    return (
      <>
        <Grid item xs={4}>
          <Typography variant="label" className={styles.label}>
            {hasDifferenceFromInitialValue ? 'Valor atualizado' : 'Valor'}
          </Typography>
          <Typography variant="body2" className={styles.finalValueText}>
            {currencyFormatter(
              hasDifferenceFromInitialValue
                ? currentBill?.finalAmount
                : currentBill?.initialAmount,
            ) || '-'}
          </Typography>
        </Grid>

        {hasDifferenceFromInitialValue && (
          <Grid item xs={4}>
            <Typography variant="label" className={styles.label}>
              Valor inicial
            </Typography>
            <Typography variant="body2" className={styles.keyText}>
              {currencyFormatter(currentBill?.initialAmount) || '-'}
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  const renderDueDate = () => (
    <>
      <Typography variant="label" className={styles.label}>
        Data do vencimento
      </Typography>
      <Box className={styles.editDateText}>
        {currentBill?.dueDate ? (
          <Typography variant="body2" className={styles.keyText}>
            {moment(currentBill.dueDate).format('DD/MM/YYYY')}
          </Typography>
        ) : (
          <>
            <Buttons.IconButton
              tooltipTitle="Alterar data de vencimento"
              className={styles.addButton}
              onClick={onOpenDateModal}
              icon={<AddCircle color="primary" className={styles.addIcon} />}
            />
            <Typography variant="body2" className={styles.keyText}>
              Adicionar data
            </Typography>
          </>
        )}
      </Box>
    </>
  );

  return (
    <Form.FormBase onSubmit={() => handleSubmit(onSubmit)}>
      {bill.hasSuccess && (
        <Grid container className={styles.successContainer}>
          <Grid item xs={3}>
            <Success className={styles.successIcon} />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h3" className={styles.successTitle}>
              Solicitação realizada com sucesso!
            </Typography>
            <Typography variant="body" className={styles.successDescription}>
              A sua operação de pagamento de conta via boleto foi solicitada com
              sucesso.
            </Typography>

            <Typography variant="body" className={styles.successWarning}>
              Solicitações de pagamento de boleto devem ser autorizadas no dia
              corrente até as 20h. Caso essa solicitação não seja aprovada no
              dia corrente, será cancelada.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box className={styles.root}>
        <Grid container className={styles.container}>
          <Grid item xs={7} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={styles.title}>
                As informações abaixo estão corretas?
              </Typography>
            </Grid>

            {renderValue()}

            <Grid item xs={4}>
              <Typography variant="label" className={styles.label}>
                Banco
              </Typography>
              <Typography variant="body2" className={styles.keyText}>
                {currentBill?.bank || '-'}
              </Typography>
            </Grid>

            {currentBill?.interestAmount > 0 && (
              <Grid item xs={4}>
                <Typography variant="label" className={styles.label}>
                  Juros
                </Typography>
                <Typography variant="body2" className={styles.keyText}>
                  {currencyFormatter(currentBill.interestAmount)}
                </Typography>
              </Grid>
            )}
            {currentBill?.fineAmount > 0 && (
              <Grid item xs={4}>
                <Typography variant="label" className={styles.label}>
                  Multa
                </Typography>
                <Typography variant="body2" className={styles.keyText}>
                  {currencyFormatter(currentBill.fineAmount)}
                </Typography>
              </Grid>
            )}
            {currentBill?.discountAmount > 0 && (
              <Grid item xs={4}>
                <Typography variant="label" className={styles.label}>
                  Descontos
                </Typography>
                <Typography variant="body2" className={styles.keyText}>
                  {currencyFormatter(currentBill.discountAmount)}
                </Typography>
              </Grid>
            )}

            <Grid item xs={4}>
              <Typography variant="label" className={styles.label}>
                Recebedor / Empresa
              </Typography>
              <Typography variant="body2" className={styles.keyText}>
                {currentBill?.recipientName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="label" className={styles.label}>
                Data da solicitação
              </Typography>
              <Typography variant="body2" className={styles.keyText}>
                {moment().format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {renderDueDate()}
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={5}>
            <Box className={styles.codeBox}>
              <Box className={styles.codeText}>
                <Typography variant="label" className={styles.label}>
                  Código de barras
                </Typography>
                <Typography
                  variant="body"
                  color="primary"
                  className={styles.codeNumber}
                >
                  {digitable}
                </Typography>
              </Box>
              <Buttons.IconButton
                tooltipTitle={tooltipText}
                icon={<FileCopyOutlined color="primary" />}
                onClick={copyCode}
                className={styles.copyButton}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <BillModal />
      <CashOutBillBottomBar
        isFinal
        disabled={!currentBill?.dueDate}
        onBack={handleBack}
        isLoading={isLoading}
        onSubmit={goToManageScreen}
      />
    </Form.FormBase>
  );
}
