import { Box, CircularProgress, Typography } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import React from 'react';

export function CardStatistics({
  isLoading = false,
  icon = (
    <Description
      style={{
        color: '#4D4D4D',
        fontSize: '2rem',
        position: 'absolute',
      }}
    />
  ),
  title = 'Label',
  circularProgressValue = 0,
  circularProgressColor = '#E5E5E5',
  numberValue = null,
  numberColor = '#FFFFFF',
  numberBoxColor = '#4D4D4D',
  numberLabel = 'Label 2',
}) {
  return (
    <Box style={{ display: 'flex', gap: '1rem' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          thickness={2}
          style={{
            width: '72px',
            height: '72px',
            color: '#E5E5E5',
            position: 'absolute',
          }}
        />
        <CircularProgress
          variant="determinate"
          value={circularProgressValue || 0}
          thickness={2}
          style={{
            width: '72px',
            height: '72px',
            color: circularProgressColor,
          }}
        />

        {icon}
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography
          style={{ color: '#41334C', fontSize: '16px', fontWeight: 'bold' }}
        >
          {title}
        </Typography>

        <Box style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Box
            style={{
              backgroundColor: numberBoxColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              padding: '2px 8px',
            }}
          >
            <Typography
              style={{
                color: numberColor,
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              {isLoading || numberValue === null || numberValue === undefined
                ? '-'
                : numberValue}
            </Typography>
          </Box>

          <Typography
            style={{ color: '#6F6676', fontSize: '16px', fontWeight: '500' }}
          >
            {numberLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
