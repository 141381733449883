import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import List from './List';
import Header from './Header';
import ProgressToolBar from '../../../components/ProgressToolBar';

import { api } from '../../../services/api';

export default function PaycheckMenu() {
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [option, setOption] = useState('');

  const history = useHistory();

  useEffect(() => {
    getFileTypes();
  }, []);

  function getFileTypes() {
    setLoading(true);

    api.get('/files/paychecks/import/types')
    .then(({ data }) => {
      setTypes(data);
    })
    .finally(() => {
      setLoading(false);
    })
  }

  function onClickOption(item, index) {
    setOption(index);
    setDisabledStatus(false);
    setLoading(false);
    
    sessionStorage.setItem('fileType', item.fileType);
  };

  function handleNext() {
    history.push('/paycheck/paycheck-import');
  }

  function handleBack() {
    history.push('/paycheck');
  }

  return (
    <>
      <Header />

      <List
        option={option}
        onClickOption={onClickOption}
        loading={loading}
        types={types}
      />

      <ProgressToolBar
        onBackClicked={handleBack}
        onNextClicked={handleNext}
        nextButtonText="Continuar"
        backButtonText="Voltar"
        disabled={disabledStatus}
      />
    </>
  )
}
