import React from 'react';

import { ChipStatus } from '../../../../../components/ChipStatus';
import formatCPF from '../../../../../utils/formatCPF';
import truncateString from '../../../../../utils/truncateString';

export function useTableConfig() {
  function handleEmployeeStatus(situation) {
    const situationEnums = {
      Aprovado: 'success',
      Pendente: 'warning',
      Reprovado: 'error',
    };

    return situationEnums[situation];
  }

  return [
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      align: 'left',
      render: row => truncateString(row?.name, 40),
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      align: 'left',
      render: row => formatCPF(row?.cpf),
    },
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
      align: 'left',
    },
    {
      key: 'situation',
      type: 'field',
      label: 'Situação',
      align: 'left',
      render: row => (
        <ChipStatus
          status={handleEmployeeStatus(row?.situation)}
          label={row?.situation}
        />
      ),
    },
  ];
}
