/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: [],
  currentCompany: {
    index: 0,
    code: '',
    name: '',
    pixKey: '',
  },
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    cleanup: state => {
      Object.keys(initialState).forEach(item => {
        state[item] = initialState[item];
      });
    },
    setCompanies: (state, { payload }) => {
      state.companies = payload;
    },
    setCurrentCompany: (state, { payload }) => {
      state.currentCompany = {
        ...state.currentCompany,
        index: payload.index,
        code: payload.code,
        name: payload.name,
        cnpj: payload.cnpj,
      };
    },
    setCompanyPixKey: (state, { payload }) => {
      state.currentCompany = {
        ...state.currentCompany,
        pixKey: payload,
      };
    },
  },
  extraReducers: {},
});

const companiesReducer = companiesSlice.reducer;
const ActionsCompanies = { ...companiesSlice.actions };

export { companiesReducer, ActionsCompanies };
