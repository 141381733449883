import React, { useState, useEffect, useRef } from 'react';

import {
  Button,
  Drawer,
  Grid,
  Hidden,
  Typography,
  Menu,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ContentDrawer from './Drawer';

import { api } from '../../services/api';
import Title from '../../components/Title';
import { style, useStyles, theme } from './styles';
import styles from './styles.module.css';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-icon.svg';

export default function Header({
  openDrawer,
  setOpenDrawer,
  getExtract,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  drawerFirstDate,
  drawerSecondDate,
  setDrawerFirstDate,
  setDrawerSecondDate,
  transactions,
  setIsFilteredFinalDate,
  pdfDownloadExtract,
  csvDownloadExtract,
  ofxDownloadExtract,
  loadingDownload,
  loadingDownloadCsv,
  loadingDownloadOfx,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.headerContainer}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        className={styles.headerExtracts}
      >
        <Grid item container direction="column">
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Title className={styles.titleStyle}>Extrato da conta</Title>
            </Grid>
          </Grid>
        </Grid>
        {transactions.length > 0 && (
          <Hidden only="xs">
            <div className={style.buttonPadding}>
              <Button
                variant="contained"
                style={style.buttonStyle}
                ref={anchorRef}
                startIcon={<DescriptionOutlinedIcon />}
                onClick={handleClick}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <Typography className={style.buttonTextStyle} display="inline">
                  Baixar extrato
                </Typography>
              </Button>
            </div>

            <div className={style.menuList}>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem onClick={() => pdfDownloadExtract()}>
                  <span className={styles.menuListItem}>PDF</span>
                  {loadingDownload && <CircularProgress size={18} />}
                </MenuItem>

                <MenuItem onClick={() => csvDownloadExtract()}>
                  <span className={styles.menuListItem}>XLS</span>
                  {loadingDownloadCsv && <CircularProgress size={18} />}
                </MenuItem>

                <MenuItem onClick={() => ofxDownloadExtract()}>
                  <span className={styles.menuListItem}>OFX</span>
                  {loadingDownloadOfx && <CircularProgress size={18} />}
                </MenuItem>
              </Menu>
            </div>
          </Hidden>
        )}
      </Grid>
      <Hidden smUp>
        <Grid item>
          <Button
            startIcon={<CalendarIcon />}
            className={styles.periodContainer}
            onClick={() => setOpenDrawer(true)}
          >
            <Typography className={styles.periodFilterText}>Período</Typography>
          </Button>

          <Drawer
            anchor="right"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <ContentDrawer
              getExtract={getExtract}
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
              drawerFirstDate={drawerFirstDate}
              drawerSecondDate={drawerSecondDate}
              setDrawerFirstDate={setDrawerFirstDate}
              setDrawerSecondDate={setDrawerSecondDate}
              setIsFilteredFinalDate={setIsFilteredFinalDate}
              setOpenDrawer={setOpenDrawer}
            />
          </Drawer>
        </Grid>
      </Hidden>
    </div>
  );
}
