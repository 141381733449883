import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { TableCell, TableRow } from '@material-ui/core';

import styles from './styles.module.css';

export default function FakeRowTable() {
  return (
    <>
      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Skeleton className={styles.skeletonCodeFakeRowTable} />
        </TableCell>
        <TableCell>
          <Skeleton
            className={styles.skeletonCostAndSectorCenterFakeRowTable}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
          <Skeleton className={styles.skeletonOptionsFakeRowTable} />
        </TableCell>
      </TableRow>
    </>
  );
}
