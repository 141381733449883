import React from 'react';
import { Button, Dialog } from '@material-ui/core';
import useStyles from './styles';

import Lottie from 'react-lottie';
import checkAnimation from '../../lotties/check.json';
import { useModalContext } from '../../context/SuccessModalContext';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function ModalStandardSuccess() {
  const classes = useStyles();

  const {
    modalContext: {
      open,
      onClose,
      image,
      title,
      text,
      closeButtonText,
    },
  } = useModalContext();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <div className={classes.container}>
        <div className={classes.image}>
          {image || <Lottie options={animationOptions} />}
        </div>

        <p className={classes.title}>{title}</p>
        <p className={classes.text}>{text}</p>

        <div className={classes.buttonsGroup}>
          <Button
            onClick={onClose}
            className={classes.closeButton}
          >
            <span className={classes.closeButtonText}>{closeButtonText || 'Fechar'}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalStandardSuccess;
