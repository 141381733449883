import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import currencyFormatter from '../../../../../utils/currencyFormatter';
import { ReactComponent as PdfIcon } from '../../../../../assets/pdfIcon.svg';
import { ActionsEmployeeDetails } from '../../../../../redux/store/Employees/EmployeeDetails';

export function useTableConfig() {
  const dispatch = useDispatch();

  const {
    paymentHistoryTable,
    downloadSingleReport,
    downloadMultipleReport,
  } = useSelector(state => state.employees.employeeDetails.paymentHistory);

  function handleTablePagination(page, perPage) {
    dispatch(ActionsEmployeeDetails.setPaymentHistoryPage(page));
    dispatch(ActionsEmployeeDetails.setPaymentHistoryPerPage(perPage));
    dispatch(ActionsEmployeeDetails.searchPaymentHistory());
  }

  function handleDownloadSingleReceipt(row) {
    dispatch(ActionsEmployeeDetails.setPaymentHistorySelectedNsu(row?.nsu));
    dispatch(ActionsEmployeeDetails.getDownloadSinglePaymentReport(row?.paymentLineId));
  }

  const handleSelection = selection => {
    dispatch(
      ActionsEmployeeDetails.setPaymentHistorySelectedReports(selection),
    );
  };

  useEffect(() => {
    if (downloadMultipleReport?.successOnSendList) {
      dispatch(
        ActionsEmployeeDetails.resetDownloadMultipleReportSuccessOnSendList(),
      );
    }
  }, [downloadMultipleReport?.successOnSendList]);

  return {
    data: paymentHistoryTable?.paymentList,
    length: paymentHistoryTable?.length,
    isLoading: paymentHistoryTable?.isLoading,
    hasError: paymentHistoryTable?.errorOn?.hasError,
    handleTablePagination,
    handleSelection,
    config: [
      {
        key: 'nsu',
        type: 'field',
        label: 'NSU',
      },
      {
        key: 'date',
        type: 'field',
        label: 'Data de pagamento',
        render: row =>
          row?.date ? moment(row?.date).format('DD/MM/YYYY') : '-',
      },
      {
        key: 'type',
        type: 'field',
        label: 'Tipo',
      },
      {
        key: 'value',
        type: 'field',
        label: 'Valor',
        render: row => currencyFormatter(row?.value),
      },
      {
        key: 'receipt',
        type: 'action',
        icon: <PdfIcon color="primary" />,
        label: 'Baixar comprovante',
        isLoading: row => row?.nsu === downloadSingleReport?.selectedNsu,
        disabled: row => !row?.canGenerateReceipt,
        onClick: row => handleDownloadSingleReceipt(row),
      },
    ],
  };
}
