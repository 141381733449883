import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../services/api';

const initialState = {
  isLoadingBalance: false,
  balances: {},
  isShowBalance: true,
  errorOnBalances: {
    hasError: false,
    message: '',
  },
};

const getBalances = createAsyncThunk(
  'balances/get',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { accountSelected } = getState().account;
    const { currentCompany } = getState().companies;
    const requestOptions = {
      headers: {
        depositAccountId: await accountSelected?.encodedKey,
        accountOrigin: await accountSelected?.origin,
      },
    };
    return api
      .get(`/home/balance/${currentCompany.code}`, requestOptions)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    cleanup: (state, { payload }) => {
      Object.keys(initialState).forEach(item => {
        if (state[item] !== (item === state.isShowBalance))
          state[item] = initialState[item];
      });
    },
    setIsLoadingBalance: (state, { payload }) => {
      state.isLoadingBalance = payload;
    },
    setIsShowBalance: (state, { payload }) => {
      state.isShowBalance = payload;
    },
  },
  extraReducers: {
    [getBalances.pending]: state => {
      state.isLoadingBalance = true;
      state.errorOnBalances = initialState.errorOnBalances;
    },
    [getBalances.fulfilled]: (state, { payload }) => {
      state.isLoadingBalance = false;
      state.balances = payload.data[0];
    },
    [getBalances.rejected]: (state, { payload }) => {
      state.isLoadingBalance = false;
      if (!payload) {
        state.errorOnBalances = {
          hasError: true,
          message: 'Houve um erro ao buscar os saldos da conta',
        };
      } else {
        state.errorOnBalances = {
          hasError: true,
          message: payload.error,
        };
      }
    },
  },
});

const balanceReducer = balanceSlice.reducer;
const ActionsBalance = { ...balanceSlice.actions, getBalances };

export { balanceReducer, ActionsBalance };
