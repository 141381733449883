import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { Typography, IconButton } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';

import FakeActivities from './UI/FakeActivities';

import { api } from '../../../../services/api';

import { ReactComponent as ChevronRight } from '../../../../assets/chevron-right.svg';
import styles from './styles.module.css';

export default function Activities() {
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const history = useHistory();

  const [activities, setActivities] = useState([]);
  const [errorActivities, setErrorActivities] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [noContentActivities, setNoContentActivities] = useState(false);

  const { accountSelected } = useSelector(state => state.account);

  const handlePushPaymentsFilter = activitiesInfo => {
    history.push('/payroll', activitiesInfo);
  };

  const getActivities = useCallback(() => {
    setLoadingActivities(true);

    const requestOptions = {
      headers: {
        depositAccount: accountSelected?.encodedKey,
      },
    };

    api
      .get(`dashboard/activities/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        setActivities(data);
        setErrorActivities(false);
        setNoContentActivities(false);

        if (data.length === 0) {
          setNoContentActivities(true);
          setErrorActivities(false);
        }
      })
      .catch(() => {
        setErrorActivities(true);
        setNoContentActivities(false);
      })
      .finally(() => {
        setLoadingActivities(false);
      });
  }, [accountSelected?.encodedKey]);

  useEffect(() => {
    if (currentCompanyCode && accountSelected?.encodedKey) {
      getActivities();
    }
  }, [getActivities]);

  return (
    <Scrollbars className={styles.itens}>
      {loadingActivities ? (
        <FakeActivities />
      ) : errorActivities ? (
        <div className={styles.errorActivitiesContainer}>
          <Typography className={styles.errorActivitiesContainerText}>
            Tivemos um problema ao carregar as atividades. Por favor, tente
            novamente.
          </Typography>
        </div>
      ) : noContentActivities ? (
        <div className={styles.emptyListContainer}>
          <Typography className={styles.emptyListContainerText}>
            Não há nenhuma atividade pendente.
          </Typography>
        </div>
      ) : (
        <>
          {activities?.map(item => (
            <div
              className={
                item.status === 'Expirada'
                  ? styles.activitiesItemExpired
                  : styles.activitiesItemAuthorization
              }
            >
              <div className={styles.contentIconSchedule}>
                <ScheduleIcon className={styles.scheduleIcon} />
              </div>
              <div className={styles.content}>
                <Typography className={styles.brokenSubTitle}>
                  {item.status === 'Expirada'
                    ? item.status
                    : `Aguardando \n autorização`}
                </Typography>
              </div>
              <div className={styles.content}>
                <Typography className={styles.title}>NSU</Typography>
                <Typography className={styles.subTitle}>{item.nsu}</Typography>
              </div>
              <div className={styles.contentSchedule}>
                <Typography className={styles.title}>Agendado para</Typography>
                <Typography className={styles.subTitle}>
                  {moment(item.scheduleDate).format('DD/MM/YYYY')} às{' '}
                  {moment(item.scheduleDate).format('LT')}
                </Typography>
              </div>
              <div className={styles.contentIcon}>
                <IconButton
                  className={styles.headerButton}
                  onClick={() =>
                    handlePushPaymentsFilter({
                      nsu: item?.nsu,
                      status: item?.status,
                      date: item?.scheduleDate,
                    })
                  }
                >
                  <ChevronRight className={styles.chevronRightIcon} />
                </IconButton>
              </div>
            </div>
          ))}
        </>
      )}
    </Scrollbars>
  );
}
