import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

export const useStyles = makeStyles(_ =>
  createStyles(
    {
      root: {
        padding: '2rem',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        gap: '1rem',
      },
      title: {
        fontSize: '1.25rem',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: '.5rem',
      },
      subtitle: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#666',
      },
      button: {
        margin: '2rem auto 0 auto',
      },
    },
    ptBR,
  ),
);
