import React from 'react';
import { Box } from '@material-ui/core';

import { Header } from '../../components/Header';
import AccessHourConfig from './components/AccessHours';
import MinLimitConfig from './components/MinLimit';
import AllowedIpConfig from './components/AllowedIp';

import useAccessSettings from './hooks/useAccessSettings';
import { SettingsContext } from './contexts';

import { useStyles } from './styles';

export default function AccessSettings() {
  const styles = useStyles();

  return (
    <>
      <Header title="Configuração de acesso" />
      <Box className={styles.root}>
        <SettingsContext.Provider value={{ ...useAccessSettings() }}>
          <AccessHourConfig />
          <MinLimitConfig />
          <AllowedIpConfig />
        </SettingsContext.Provider>
      </Box>
    </>
  );
}
