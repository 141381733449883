const initialState = {
  employer: null,
};

export default function employerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_EMPLOYER':
      return {
        ...state,
        employer: action.employer,
      };
    default:
      return state;
  }
}
