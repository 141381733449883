import React from 'react';
import { Box, Collapse, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { ChipStatus } from '../../../../../../../../../../components/ChipStatus';
import Buttons from '../../../../../../../../../../components/Buttons';

export function ReprovedWithPendencies({
  sectionOpen = '',
  handleOpenSection = () => {},
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <ChipStatus status="primary" label="Reprovado com pendência" />

      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '13px',
        }}
      >
        Há uma pendência que nos impede de criar a conta para este colaborador
      </Typography>

      <Buttons.SecondaryButton
        color="primary"
        icon={
          sectionOpen === 'section1' ? (
            <RemoveCircleOutlineIcon color="primary" />
          ) : (
            <AddCircleOutlineIcon color="primary" />
          )
        }
        title={sectionOpen === 'section1' ? 'Ver menos' : 'Ver mais'}
        onClick={() =>
          handleOpenSection(sectionOpen === 'section1' ? '' : 'section1')
        }
        style={{ width: 'fit-content' }}
      />

      <Collapse in={sectionOpen === 'section1'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography
              style={{
                fontSize: '13px',
              }}
            >
              Por favor, orientar que o mesmo entre em contato com nossa central
              de atendimento para receber todas as orientações de como resolver
              a pendência e ter a conta criada com sucesso.
            </Typography>

            <Typography style={{ fontSize: '13px' }}>
              Nossos canais são:
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <Typography
              style={{
                color: '#828282',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              ◦ Telefone: 4007-2699
            </Typography>
            <Typography
              style={{
                color: '#828282',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              ◦ E-mail: atendimento@somapay.com.br
            </Typography>
            <Typography
              style={{
                color: '#828282',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              ◦ WhatsApp: 4007-2699
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: '#F6F3F9',
              display: 'flex',
              padding: '0.750rem',
              borderRadius: '0.5rem',
            }}
          >
            <Typography
              style={{
                color: '#52197F',
                fontSize: '13px',
                fontWeight: 'normal',
              }}
            >
              Enquanto isso, você pode informar os dados bancários deste
              colaborador que{' '}
              {
                <Box sx={{ display: 'inline', fontWeight: 'bold' }}>
                  realizaremos o pagamento
                </Box>
              }{' '}
              diretamente na conta informada.
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
