export const headFake = [
  {
    id: 'loanKey',
    numeric: true,
    label: 'Chave',
  },
  {
    id: 'contractDate',
    numeric: false,
    label: 'Data contratação',
  },
  {
    id: 'dueDate',
    numeric: false,
    label: 'Data vencimento',
  },
  {
    id: 'loanAmountRequested',
    numeric: true,
    label: 'Valor contratado',
  },
  {
    id: 'loanAmountRequested',
    numeric: true,
    label: 'Valor líquido',
  },
  {
    id: 'loanAmountPaid',
    numeric: true,
    label: 'Valor pago',
  },
  {
    id: 'situation',
    numeric: false,
    label: 'Situação',
  },
];

export const bodyFake = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

export const body = [
  {
    name: 'ABELARDO OLIVEIRA CUNHA FILHO',
    cpf: '018.826.373-05',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 400.0,
    limitBalance: 25.0,
  },
  {
    name: 'ABRAAO DE OLIVEIRA CIPRIANO',
    cpf: '054.226.033-62',
    status: 'ACTIVE',
    grantedValue: 639,
    usedValue: 0,
    limitBalance: 639,
  },
  {
    name: 'ABRAAO LINCOLN LEMOS DOS SANTOS',
    cpf: '874.931.103-44',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 0,
    limitBalance: 425,
  },
  {
    name: 'ABRAAO VICENTE PEREIRA DA SILVA',
    cpf: '040.852.824-92',
    status: 'ACTIVE',
    grantedValue: 639,
    usedValue: 0,
    limitBalance: 639,
  },
  {
    name: 'ADALBERTO MUNIZ DOS SANTOS',
    cpf: '635.297.503-87',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 424.72,
    limitBalance: 0.28,
  },
  {
    name: 'ADERSON MARQUES DE SOUZA NETO',
    cpf: '021.617.403-18',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 0,
    limitBalance: 425,
  },
  {
    name: 'ADILIO AMORIM BEZERRA MARTINS',
    cpf: '622.217.363-20',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 0,
    limitBalance: 425,
  },
  {
    name: 'ADLER NOBRE CAVALCANTE',
    cpf: '053.570.783-51',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 0,
    limitBalance: 425,
  },
  {
    name: 'ADRIANO ALVES DA SILVA',
    cpf: '600.307.223-73',
    status: 'ACTIVE',
    grantedValue: 425,
    usedValue: 0,
    limitBalance: 425,
  },
];
