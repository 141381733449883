import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Typography } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import Header from './Header';
import InfoGrid from './InfoGrid';
import Accordion from './Accordion';
import EmployeesTable from './Table/EmployeesTable';

import ProgressToolBar from '../../../components/ProgressToolBar';
import ProgressModal from '../../../components/ProgressModal';
import SuccessModal from '../../../components/SuccessModal';
import CustomSnackbar from '../../../components/Snackbar';
import LogoLoading from '../../../components/LogoLoading';

import { api } from '../../../services/api';
import { pusher } from '../../../utils/pusher';
import { pusherStatus, pusherChannels } from '../../../enums/pusher';

import styles from './styles.module.css';
import TablePaginationActions from './Table/TablePaginationActions';

export default function PaycheckDataReview({
  match: {
    params: { fileId },
  },
}) {
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  const history = useHistory();

  const initialPagination = { totalSize: 0, pageSize: 10, currentPage: 0 };

  const [competence, setCompetence] = useState('');
  const [paycheckType, setPaycheckType] = useState('');
  const [paycheckPortion, setPaycheckPortion] = useState('');

  const [successList, setSuccessList] = useState([]);
  const [errorsList, setErrorsList] = useState([]);
  const [paycheckInfo, setPaycheckInfo] = useState({});
  const [paycheckProcessData, setPaycheckProcessData] = useState({});

  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarDescription, setSnackbarDescription] = useState("");
  
  const [loading, setLoading] = useState(true);
  const [resultLoading, setResultLoading] = useState(true);
  const [paychecksLoading, setPaychecksLoading] = useState(true);
  const [paychecksErrorsLoading, setPaychecksErrorsLoading] = useState(true);

  const [totalSize, setTotalSize] = useState(initialPagination.totalSize);
	const [pageSize, setPageSize] = useState(initialPagination.pageSize);
	const [currentPage, setCurrentPage] = useState(initialPagination.currentPage);

  const IS_ERROR = errorsList?.length > 0;
  const IS_SUCCESS = successList?.length > 0;
  const DISABLED_NEXT_BUTTON =
    (!competence || !paycheckType || !paycheckPortion) && IS_SUCCESS;

  const { PROCESSED_PAYCHECK_SUCCESS } = pusherStatus;
  const { PROCESSED_PAYCHECK_REPORT } = pusherChannels;

  useEffect(() => {
    const channel = pusher.subscribe(CURRENT_COMPANY_CODE);

    channel.bind(PROCESSED_PAYCHECK_REPORT, function(data) {
      const { status } = data;

      setPaycheckProcessData(data);

      if (status === PROCESSED_PAYCHECK_SUCCESS) {
        setOpenProgressModal(false);
        setOpenSuccessModal(true);
      }
    });
  }, []);

  function handleNext() {
    IS_ERROR && !IS_SUCCESS
      ? history.push('/paycheck/paycheck-import')
      : processPaycheck();
  }

  function handleBack() {
    history.goBack();
  }

  function onChangeCompetence(value) {
    return setCompetence(value);
  }

  function onChangePayCheckType(event) {
    return setPaycheckType(event.target.value);
  }

  function onChangePayCheckPortion(event) {
    return setPaycheckPortion(event.target.value);
  }

  const openSnackbar = () => setShowSnackbar(true);
  const closeSnackbar = () => setShowSnackbar(false);

  const resultLoadingOn = () => setResultLoading(true);
  const resultLoadingOff = () => setResultLoading(false);

  const paychecksLoadingOn = () => setPaychecksLoading(true);
  const paychecksLoadingOff = () => setPaychecksLoading(false);

  const paychecksErrorsLoadingOn = () => setPaychecksErrorsLoading(true);
  const paychecksErrorsLoadingOff = () => setPaychecksErrorsLoading(false);

	const handlePageChange = (_, newPage) => setCurrentPage(newPage);

	const handleRowsPerPageChange = event => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	}

  const openErrorSnackbar = description => {
    openSnackbar();
    setSnackBarSeverity("error");
    setSnackbarDescription(description);
  }

  function processPaycheck() {
    const body = {
      paymentType: paycheckType,
      installment: paycheckPortion,
      competency: `${moment(competence).format('YYYY-MM')}-01`,
    };

    setOpenProgressModal(true);

    api.post(`/files/paychecks/process/${fileId}`, body);
  }

  function getResult() {
    resultLoadingOn();

    api
      .get(`files/paychecks/result/${fileId}`)
      .then(({ data }) => {
        const {
          competencyDate,
          installment,
          paymentType,
          paycheckCount,
          name,
          creationDate,
          creationUser,
          id,
          status
        } = data;

        const paycheckInfo = {
          paycheckCount,
          name,
          creationDate,
          creationUser,
          id,
        };

        if(status === 'PROCESSING') {
          openErrorSnackbar("Arquivo não pode ser revisado, pois está em processamento.");
          history.push('/paycheck');
        }

        setPaycheckInfo(paycheckInfo);
        setCompetence(competencyDate);
        setPaycheckPortion(installment);
        setPaycheckType(paymentType);
      })
      .finally(() => resultLoadingOff());
  }

  function getPaychecks() {
    paychecksLoadingOn();

    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize
      },
    };

    api.get(`/paychecks/file/${fileId}/search`, requestOptions)
      .then(({ data }) => {
        setSuccessList(data.content);
        setTotalSize(data.totalSize);
      })
      .finally(() => paychecksLoadingOff());
  }

  function getPaychecksErrors() {
    paychecksErrorsLoadingOn();

    api.get(`/files/paychecks/errors/${fileId}`)
      .then(({ data }) => {
        if(data)
          setErrorsList(data);
      })
      .finally(() => paychecksErrorsLoadingOff());
  }

  function finishProcess() {
    setOpenSuccessModal(false);

    const {
      paycheckCount,
      name,
      creationDate,
      creationUser,
      id,
    } = paycheckInfo;

    const paycheckData = {
      competencyDate: competence,
      installment: paycheckPortion,
      paycheckType,
      paycheckCount,
      name,
      creationDate,
      creationUser,
      employees: successList,
      id,
    };

    history.push(`/paycheck/paycheck-success/${paycheckData.id}`);
  }

  useEffect(() => {
    if(!resultLoading && !paychecksLoading && !paychecksErrorsLoading)
      setLoading(false);

  }, [resultLoading, paychecksLoading, paychecksErrorsLoading]);

	useEffect(() => {
		getPaychecks();
	}, [currentPage, pageSize]);

  useEffect(() => {
    getResult();
    getPaychecks();
    getPaychecksErrors();
  }, []);

  if(loading)
    return <LogoLoading />

  return (
    <div>
      <Header
        title={
          IS_ERROR
            ? 'Indentificamos os seguintes problemas'
            : 'Confirme e dispare os contra cheques'
        }
        description={
          IS_ERROR && 'Por favor, corrija os erros baixo antes de continuar.'
        }
      />

      <InfoGrid
        hidden={IS_ERROR && !IS_SUCCESS}
        competence={competence}
        onChangeCompetence={onChangeCompetence}
        paycheckType={paycheckType}
        onChangePayCheckType={onChangePayCheckType}
        onChangePayCheckPortion={onChangePayCheckPortion}
        paycheckPortion={paycheckPortion}
        resultLoading={resultLoading}
        paycheckInfo={paycheckInfo}
      />

      <Accordion
        title={
          <Typography className={styles.accordionErrorHeading}>
            <ErrorRoundedIcon className={styles.accordionHeadingIcon} />
            {`${errorsList?.length} registros com problemas`}
          </Typography>
        }
        content={errorsList?.map(item => (
          <Typography className={styles.accordionErrorDetails}>
            Linha {item.lineNumber}
            <br />- {item.description}
            <br />
            <br />
          </Typography>
        ))}
        hidden={!IS_ERROR}
        style={IS_ERROR && !IS_SUCCESS ? { marginBottom: 70 } : {}}
      />

      <Accordion
        title={
          <Typography className={styles.accordionSuccessHeading}>
            <CheckCircleRoundedIcon className={styles.accordionHeadingIcon} />
            {`${totalSize} funcionários Identificados com sucesso`}
          </Typography>
        }
        content={
          <EmployeesTable
            data={successList}
            isLoading={paychecksLoading}
            totalSize={totalSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            TablePaginationActions={TablePaginationActions}
            />
        }
        style={{ marginBottom: 70 }}
        hidden={!IS_SUCCESS}
      />

      <ProgressModal
        open={openProgressModal}
        title="Disponibilizando contra cheques..."
        text="Esta ação pode levar alguns minutos. Após a conclusão, 
        você pode voltar a usar a plataforma normalmente."
        progress={
          paycheckProcessData?.percentage ? paycheckProcessData?.percentage : 0
        }
        confirmButtonText={
          paycheckProcessData?.percentage
            ? `${paycheckProcessData?.percentage} %`
            : '0%'
        }
        progressLoading
        textMarginBottom={3}
      />

      <SuccessModal
        open={openSuccessModal}
        title="Importação e disponibilização realizados com sucesso!"
        titleMarginTop={3}
        onCloseDialog={finishProcess}
      />

      <ProgressToolBar
        onBackClicked={handleBack}
        onNextClicked={handleNext}
        nextButtonText={
          IS_ERROR && !IS_SUCCESS
            ? 'Reenviar arquivo'
            : IS_SUCCESS && !IS_ERROR
            ? 'Confirmar e disponibilizar'
            : 'Ignorar erros e confirmar'
        }
        backButtonText="Voltar"
        orangeNextButton={!IS_ERROR}
        disableBack={IS_ERROR}
        disabled={DISABLED_NEXT_BUTTON}
        hidden={resultLoading}
      />

      <CustomSnackbar
        open={showSnackbar}
        severity={snackBarSeverity}
        message={snackBarDescription}
        close={() => closeSnackbar()}
      />
    </div>
  );
}
