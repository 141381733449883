import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },

    content: {
      margin: `${theme.spacing(2)}px 0`,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      gap: theme.spacing(2),
    },

    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h4.fontSize,
      textAlign: 'center',
    },

    subtitle: {
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',

      [theme.breakpoints.up('sm')]: {
        maxWidth: '50%',
      },
    },

    actions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
