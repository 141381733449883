import React from 'react';
import Filters from './UI/Filters';
import Table from './UI/Table';
import FileList from '../FileList';

function InsuranceFile({
  openDrawer,
  setOpenDrawer,
  loadingTable
}) {
  return (
    <div>
      <Filters />

      <FileList 
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        loadingTable={loadingTable}
      />
      
      <Table />
    </div>
  );
}

export default InsuranceFile;
