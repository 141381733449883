export function handleDownloadFile(
  data = null,
  nameDocument = '',
  extension = 'pdf',
) {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));

  const nameDocumentFormatted = `${nameDocument}.${extension}`;

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', nameDocumentFormatted);

  document.body.appendChild(link);
  link.click();
  link.remove();
}
