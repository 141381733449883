import React, { useEffect, useState } from 'react';

import axios from 'axios';
import cep from 'cep-promise';

import { Button, Grid, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import styles from './UI/styles.module.css';
import SimpleTabs from './UI/Tabs';
import SelectableTable from './UI/SelectableTable';
import ModalErase from './UI/ModalErase';
import ModalInclude from './UI/ModalInclude';
import TablePaginationActions from './UI/TablePaginationActions';
import MobileList from './UI/MobileList';

import LogoLoading from '../../components/LogoLoading';
import EmptyList from '../../components/EmptyList';
import ErrorModal from '../../components/ErrorDialog';
import PrimaryModal from '../../components/PrimaryModal';
import CustomSnackbar from '../../components/Snackbar';
import { api } from '../../services/api';

import CostAndSectorCenterServices from './utils/services';
import { isMobile } from '../../utils/breakpoints';

import { ReactComponent as Icon } from '../../assets/locations-empty.svg';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CostAndSectorCenter() {
  const companyCodeValue = sessionStorage.getItem('currentCompanyCode');
  const [stockingCenterData, setStockingCenterData] = useState();
  const [locationsCenterData, setLocationsCenterData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alreadyExistsError, setAlreadyExistsError] = useState();
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState('');
  const [changedFilter, setChangedFilter] = useState(false);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [publicPlace, setPublicPlace] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [complement, setComplement] = useState('');
  const [country, setCountry] = useState('Brasil');
  const [UF, setUF] = useState('');
  const [city, setCity] = useState('');
  const [mapCity, setMapCity] = useState([]);
  const [blockedCity, setBlockedCity] = useState(true);
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [zipCodeInvalid, setZipCodeInvalid] = useState(false);
  const [textFieldFocused, setTextFieldFocused] = useState('');
  const [cardSendingEnabled, setCardSendingEnabled] = useState(false);
  const [enabledErrorModal, setEnabledErrorModal] = useState(false);
  const [handleDeleteModal, setHandleDeleteModal] = useState(false);
  const [centerType, setCenterType] = useState('');
  const [itemCode, setItemCode] = useState('');
  const [saveAndDeleteLoading, setSaveAndDeleteLoading] = useState(false);
  const [handleSnackBar, setHandleSnackBar] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('');

  useEffect(() => {
    getCoastCenterAndSectors();
  }, [companyCodeValue, currentPage, pageSize]);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        getCoastCenterAndSectors();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [filter]);

  useEffect(() => {
    if (UF && !loadingSkeleton && !loadingZipCode) {
      getCityDataByUF();
    }
  }, [UF]);

  useEffect(() => {
    if (zipCode.length >= 9 && !loadingSkeleton) {
      setUF('');
      setCity('');
      setPublicPlace('');
      setDistrict('');
      setLoadingZipCode(true);
      setZipCodeInvalid(false);
      getDataFromValidatedZipCode(zipCode);
    }
  }, [zipCode]);

  function onFocusAutoComplete() {
    getCityDataByUF();
  }

  function handleCardSendingEnabled() {
    setCardSendingEnabled(!cardSendingEnabled);
  }

  function handleClosedModal() {
    setSelectedItem(null);
    setName('');
    setCode('');
    setZipCode('');
    setPublicPlace('');
    setNumberAddress('');
    setDistrict('');
    setComplement('');
    setUF('');
    setCity('');
    setMapCity([]);
    setBlockedCity(true);
    setCardSendingEnabled(false);
    setEnabledErrorModal(false);
  }

  function getCityDataByUF() {
    const validadeSearchUf = UF?.initials || UF;

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${validadeSearchUf}/municipios`,
      )
      .then(response => {
        setMapCity([]);
        setCity('');
        setMapCity(response.data);
        setBlockedCity(false);
      })
      .catch(error => error);
  }

  function getDataFromValidatedZipCode(zipCodeToValidate) {
    cep(zipCodeToValidate)
      .then(response => {
        const { state, city: cityApi, street, neighborhood } = response;
        setUF(state);
        setCity(cityApi);
        setPublicPlace(street);
        setDistrict(neighborhood);
        setLoadingZipCode(false);
        setZipCodeInvalid(false);
        setBlockedCity(false);
      })
      .catch(error => {
        setZipCodeInvalid(true);
        setLoadingZipCode(false);
      });
  }

  function getLocationsData(item) {
    setLoadingSkeleton(true);
    api
      .get(`companies/${companyCodeValue}/locations/${item.code}`)
      .then(({ data }) => {
        if (data?.address) {
          const {
            cardSendingEnabled: cardSendingEnabledEdit,
            city: cityCodeEdit,
            district: districtEdit,
            details,
            country: countryEdit,
            number,
            state,
            street,
            zipCode: zipCodeEdit,
          } = data?.address;
          setZipCode(zipCodeEdit);
          setCardSendingEnabled(cardSendingEnabledEdit);
          setUF(state);
          setDistrict(districtEdit);
          setCountry(countryEdit);
          setCity(cityCodeEdit);
          setComplement(details);
          setPublicPlace(street);
          setNumberAddress(number);
          setBlockedCity(false);
        }
        setLoadingSkeleton(false);
      })
      .catch(error => {
        setLoadingSkeleton(false);
        setEnabledErrorModal(true);
      });
  }

  function handleEditCoastCenterAndSectors(item) {
    setName(item.description);
    setCode(item.code);

    if (value === 0) {
      getLocationsData(item);
    }

    setSelectedItem({
      type: 'include',
      itemData: item,
      itemTitle: value === 0 ? 'Centro de Custo' : 'Setor',
    });
  }

  function handleCostCenterAndSectorDeleteModal(item, type) {
    setHandleDeleteModal(prevState => !prevState);
    setCenterType(type);
    setItemCode(item.code);
  }

  function onConfirmDeletionButtonClicked() {
    setSaveAndDeleteLoading(prevState => !prevState);
    api
      .delete(
        centerType === 'Centro de custo'
          ? `companies/${companyCodeValue}/locations/${itemCode}`
          : `companies/${companyCodeValue}/stockings/${itemCode}`,
      )
      .then(() => {
        setSnackbar(true);
        setSnackbarOptions({
          type: centerType,
          severity: 'success',
          message: `${centerType} excluído com sucesso!`,
        });
      })
      .finally(() => {
        setSaveAndDeleteLoading(prevState => !prevState);
        setHandleDeleteModal(prevState => !prevState);
        getCoastCenterAndSectors();
      });
  }

  function getCoastCenterAndSectors() {
    setLoadingTable(true);

    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize,
        codeOrDescription: filter,
      },
    };

    CostAndSectorCenterServices.getStockingCenterData({
      companyCodeValue,
      requestOptions,
      setStates: data => {
        setStockingCenterData(data);
        setLoadingTable(false);
        setLoading(false);
      },
    });

    CostAndSectorCenterServices.getLocationsCenterData({
      companyCodeValue,
      requestOptions,
      setStates: data => {
        setLocationsCenterData(data);
        setLoadingTable(false);
        setLoading(false);
      },
    });
  }

  function registerStocking(body) {
    setSaveAndDeleteLoading(prevState => !prevState);
    CostAndSectorCenterServices.registerNewSector({
      companyCodeValue,
      setStates: () => {
        setSaveAndDeleteLoading(prevState => !prevState);
        setSnackbar(true);
        setSnackbarOptions({
          type: centerType,
          severity: 'success',
          message: `Setor incluído com sucesso!`,
        });
        setAlreadyExistsError(null);
        setSelectedItem(null);
        handleClosedModal();
        getCoastCenterAndSectors();
      },
      body,
      setError: err => {
        setSaveAndDeleteLoading(prevState => !prevState);
        if (err.response.status === 422) {
          setAlreadyExistsError(err.response.data);
        } else {
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: `Desculpe! Ocorreu um problema ao incluir este setor`,
          });
        }
      },
    });
  }

  function registerLocation(body) {
    setSaveAndDeleteLoading(prevState => !prevState);
    CostAndSectorCenterServices.registerLocationCenter({
      companyCodeValue,
      setStates: () => {
        setSaveAndDeleteLoading(prevState => !prevState);
        setSnackbar(true);
        setSnackbarOptions({
          type: centerType,
          severity: 'success',
          message: `Centro de custo incluído com sucesso!`,
        });
        setAlreadyExistsError(false);
        handleClosedModal();
        getCoastCenterAndSectors();
      },
      body,
      setError: err => {
        setSaveAndDeleteLoading(prevState => !prevState);
        if (err.response.status === 400) {
          const { errorDetail } = err.response.data?.errors[0];
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: errorDetail,
          });
        } else {
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: `Desculpe! Ocorreu um problema ao incluir este centro de custo`,
          });
        }
      },
    });
  }

  function changeLocation(body, codeCenter) {
    setSaveAndDeleteLoading(prevState => !prevState);
    CostAndSectorCenterServices.changeLocationCenter({
      companyCodeValue,
      codeCenter,
      setStates: () => {
        setSaveAndDeleteLoading(prevState => !prevState);
        setSnackbar(true);
        setSnackbarOptions({
          type: centerType,
          severity: 'success',
          message: `Centro de custo editado com sucesso!`,
        });
        setAlreadyExistsError(false);
        handleClosedModal();
        getCoastCenterAndSectors();
      },
      body,
      setError: err => {
        setSaveAndDeleteLoading(prevState => !prevState);
        if (err.response.status === 400) {
          const { errorDetail } = err.response.data?.errors[0];
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: errorDetail,
          });
        } else {
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: `Desculpe! Ocorreu um problema ao editar este centro de custo`,
          });
        }
      },
    });
  }

  function changeStocking(body, codeCenter) {
    setSaveAndDeleteLoading(prevState => !prevState);
    CostAndSectorCenterServices.changeStockingCenter({
      companyCodeValue,
      codeCenter,
      setStates: () => {
        setSaveAndDeleteLoading(prevState => !prevState);
        setSnackbar(true);
        setSnackbarOptions({
          type: centerType,
          severity: 'success',
          message: `Setor editado com sucesso!`,
        });
        setAlreadyExistsError(false);
        handleClosedModal();
        getCoastCenterAndSectors();
      },
      body,
      setError: err => {
        setSaveAndDeleteLoading(prevState => !prevState);
        if (err.response.status === 400) {
          const { errorDetail } = err.response.data?.errors[0];
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: errorDetail,
          });
        } else {
          setSnackbar(true);
          setSnackbarOptions({
            type: '',
            severity: 'error',
            message: `Desculpe! Ocorreu um problema ao editar este setor`,
          });
        }
      },
    });
  }

  const onSearchChangeCostCenter = event => {
    setFilter(event.target.value);
    setChangedFilter(true);
  };

  const onSearchChangeLocationsCenter = event => {
    setFilter(event.target.value);
    setChangedFilter(true);
  };

  function getModal(type, itemData, itemTitle) {
    switch (type) {
      case 'erase':
        return (
          <ModalErase
            itemData={itemData}
            setSelectedItem={setSelectedItem}
            itemTitle={itemTitle}
            setSnackbar={setSnackbar}
          />
        );
      case 'include':
        return (
          <ModalInclude
            value={value}
            itemTitle={itemTitle}
            itemData={itemData}
            setSelectedItem={setSelectedItem}
            registerStocking={registerStocking}
            registerLocation={registerLocation}
            changeLocation={changeLocation}
            changeStocking={changeStocking}
            setSnackbar={setSnackbar}
            alreadyExistsError={alreadyExistsError}
            setAlreadyExistsError={setAlreadyExistsError}
            name={name}
            setName={setName}
            code={code}
            setCode={setCode}
            zipCode={zipCode}
            setZipCode={setZipCode}
            publicPlace={publicPlace}
            setPublicPlace={setPublicPlace}
            numberAddress={numberAddress}
            setNumberAddress={setNumberAddress}
            district={district}
            setDistrict={setDistrict}
            complement={complement}
            setComplement={setComplement}
            country={country}
            UF={UF}
            setUF={setUF}
            city={city}
            setCity={setCity}
            mapCity={mapCity}
            blockedCity={blockedCity}
            loadingZipCode={loadingZipCode}
            textFieldFocused={textFieldFocused}
            setTextFieldFocused={setTextFieldFocused}
            zipCodeInvalid={zipCodeInvalid}
            onFocusAutoComplete={onFocusAutoComplete}
            cardSendingEnabled={cardSendingEnabled}
            handleCardSendingEnabled={handleCardSendingEnabled}
            handleClosedModal={handleClosedModal}
            loadingSkeleton={loadingSkeleton}
            loadingButton={saveAndDeleteLoading}
          />
        );
      default:
        return '';
    }
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setLoadingTable(true);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleClearFilters = () => {
    setFilter('');
  };

  if (loading) {
    return <LogoLoading />;
  }

  return (
    <Grid>
      <>
        <Grid className={styles.header}>
          <Typography className={styles.titleStyle}>
            Centro de custo e setor
          </Typography>

          <Button
            onClick={() =>
              setSelectedItem({
                type: 'include',
                itemData: null,
                itemTitle: value === 0 ? 'Centro de Custo' : 'Setor',
              })
            }
            className={
              value === 0 ? styles.includeButton : styles.includeSectorButton
            }
          >
            <AddRoundedIcon className={styles.addRoundedIcon} />
            Novo {value === 0 ? 'Centro de custo' : 'setor'}
          </Button>

          <Button
            className={styles.addButton}
            onClick={() =>
              setSelectedItem({
                type: 'include',
                itemData: null,
                itemTitle: value === 0 ? 'Centro de Custo' : 'Setor',
              })
            }
          >
            <PlaylistAddIcon className={styles.addButtonIcon} />
            Incluir
          </Button>
        </Grid>

        <SimpleTabs
          setPageSize={setPageSize}
          handleClearFilters={handleClearFilters}
          setValue={setValue}
          value={value}
          filter={filter}
          locationsCenterData={locationsCenterData}
          stockingCenterData={stockingCenterData}
          changedFilter={changedFilter}
          onSearchChangeCostCenter={onSearchChangeCostCenter}
          onSearchChangeLocationsCenter={onSearchChangeLocationsCenter}
          contentCostCenter={
            locationsCenterData?.content.length === 0 && !changedFilter ? (
              <EmptyList
                icon={<Icon />}
                title="A lista com os Centros de Custo será mostrada aqui!"
                description={
                  isMobile ? (
                    <span>
                      Para incluir um centro e custo,
                      <br /> basta clicar no botão “Incluir”.
                    </span>
                  ) : (
                    'Para incluir um centro e custo, basta clicar no botão “Incluir”.'
                  )
                }
              />
            ) : isMobile ? (
              <MobileList
                pageSize={pageSize}
                setPageSize={setPageSize}
                data={locationsCenterData}
                loading={loadingTable}
                changedFilter={changedFilter}
                handleEditCoastCenterAndSectors={
                  handleEditCoastCenterAndSectors
                }
                handleCostCenterAndSectorDeleteModal={
                  handleCostCenterAndSectorDeleteModal
                }
                value={value}
                titleOfItem="Centro de custo"
              />
            ) : (
              <SelectableTable
                value={value}
                loading={loadingTable}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                TablePaginationActions={TablePaginationActions}
                titleOfItem="Centro de custo"
                data={locationsCenterData}
                setData={newData => setLocationsCenterData(newData)}
                keyName="content"
                setSelectedItem={setSelectedItem}
                changedFilter={changedFilter}
                handleEditCoastCenterAndSectors={
                  handleEditCoastCenterAndSectors
                }
                handleCostCenterAndSectorDeleteModal={
                  handleCostCenterAndSectorDeleteModal
                }
              />
            )
          }
          contentSector={
            stockingCenterData?.content.length === 0 && !changedFilter ? (
              <EmptyList
                icon={<Icon />}
                title={
                  isMobile ? (
                    <span>
                      A lista com os Setores será
                      <br /> mostrada aqui!
                    </span>
                  ) : (
                    'A lista com os Setores será mostrada aqui!'
                  )
                }
                description={
                  isMobile ? (
                    <span>
                      Para incluir um setor, basta clicar
                      <br /> no botão “Incluir”.
                    </span>
                  ) : (
                    'Para incluir um setor, basta clicar no botão “Incluir”.'
                  )
                }
              />
            ) : isMobile ? (
              <MobileList
                pageSize={pageSize}
                setPageSize={setPageSize}
                data={stockingCenterData}
                loading={loadingTable}
                changedFilter={changedFilter}
                handleEditCoastCenterAndSectors={
                  handleEditCoastCenterAndSectors
                }
                handleCostCenterAndSectorDeleteModal={
                  handleCostCenterAndSectorDeleteModal
                }
                value={value}
                titleOfItem="Setor"
              />
            ) : (
              <SelectableTable
                value={value}
                loading={loadingTable}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                TablePaginationActions={TablePaginationActions}
                titleOfItem="Setor"
                data={stockingCenterData}
                setData={newData => setStockingCenterData(newData)}
                keyName="content"
                setSelectedItem={setSelectedItem}
                changedFilter={changedFilter}
                handleEditCoastCenterAndSectors={
                  handleEditCoastCenterAndSectors
                }
                handleCostCenterAndSectorDeleteModal={
                  handleCostCenterAndSectorDeleteModal
                }
              />
            )
          }
          setSelectedItem={setSelectedItem}
        />
      </>

      {selectedItem &&
        getModal(
          selectedItem.type,
          selectedItem.itemData,
          selectedItem.itemTitle,
        )}

      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
      >
        <Alert
          onClose={() => setSnackbar(false)}
          severity={snackbarOptions.severity}
        >
          {`${snackbarOptions.message}`}
        </Alert>
      </Snackbar>

      <ErrorModal
        open={enabledErrorModal}
        title="Ocorreu um erro na leitura das informações"
        text="Verifique sua conexão e tente novamente."
        onCloseDialog={handleClosedModal}
        openErrorDialog
      />
      <PrimaryModal
        open={handleDeleteModal}
        title={`Tem certeza que deseja excluir este ${centerType}?`}
        text={`Os funcionários alocados neste item terão seus registros atualizados para "${centerType} não especificado."`}
        cancelButtonText="Cancelar"
        confirmButtonText="Excluir"
        onCloseDialog={() => setHandleDeleteModal(prevState => !prevState)}
        onCancelButtonClicked={() =>
          setHandleDeleteModal(prevState => !prevState)
        }
        onConfirmClicked={onConfirmDeletionButtonClicked}
        loading={saveAndDeleteLoading}
      />
    </Grid>
  );
}

export default CostAndSectorCenter;
