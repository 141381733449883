import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PurpleCheckbox from '../../../../components/Checkbox/PurpleCheckbox';

import { styles } from './styles';

const headCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    hasOrdering: true,
    label: 'Situação',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    hasOrdering: true,
    label: 'Tipo',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    hasOrdering: true,
    label: 'NSU',
  },
  {
    id: 'importDate',
    numeric: false,
    disablePadding: false,
    hasOrdering: true,
    label: 'Criado',
  },
  {
    id: 'scheduleDate',
    numeric: false,
    disablePadding: false,
    hasOrdering: true,
    label: 'Agendado',
  },
  {
    id: 'totalAmount',
    numeric: true,
    disablePadding: false,
    hasOrdering: true,
    label: 'Valor',
  },
  {
    id: '',
    disablePadding: false,
    hasOrdering: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    onSelectAllClick,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <PurpleCheckbox
            inputProps={{ 'aria-label': 'select all desserts' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell =>
          headCell?.hasOrdering ? (
            <TableCell
              key={headCell.id}
              align="left"
              padding="10px"
              sortDirection={orderBy === headCell.id ? order : false}
              style={styles.tableHeadLabel}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'descendente' : 'ascedente'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align="left"
              padding="10px"
              style={styles.tableHeadLabel}
            >
              {headCell.label}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
