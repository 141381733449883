import React, { useCallback } from 'react';
import { Button, Box, Typography, Dialog } from '@material-ui/core';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';

const ConfirmModal = NiceModal.create(({ processId }) => {
  const modal = useModal();

  const history = useHistory();

  const handleOk = useCallback(() => {
    history.push(`/consigned/margins/batch/${processId}/confirm`);
    modal.hide();
  }, [processId]);

  const handleClose = useCallback(() => {
    modal.reject();
    modal.hide();
  }, []);

  return (
    <Dialog open={modal.visible} onClose={handleClose} fullWidth maxWidth="sm">
      <div className={styles.container}>
        <Box mt={0}>
          <Typography className={styles.title}>
            Processamento concluído com sucesso!
          </Typography>
          <Typography className={styles.subtitle}>
            Clique em “CONTINUAR” para seguir com o processo
          </Typography>
        </Box>

        <div className={styles.buttonsGroup}>
          <Button onClick={handleClose} className={styles.closeButton}>
            Cancelar
          </Button>

          <Button onClick={handleOk} className={styles.nextButton}>
            <Typography className={styles.confirmButtonText}>
              Continuar
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

export default ConfirmModal;
