import React from 'react';

import { Skeleton } from '@material-ui/lab';

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { headFake, bodyFake } from './mocks';

import styles from './styles.module.css';

export default ({ pageSize }) => {
  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headFake.map(item => (
              <TableCell key={item.id}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(pageSize).map(() => (
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell className={styles.tableRowSkeleton}>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
