import React from 'react';

import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { ReactComponent as IncompletePaymentIcon } from '../../../../assets/uncompletePayment.svg';
import { ReactComponent as PaymentIconExpress } from '../../../../assets/express-paid-out.svg';
import { ReactComponent as ExpressPaymentIcon } from '../../../../assets/expressPaymentIcon.svg';
import { ReactComponent as AuthorizedIcon } from '../../../../assets/icon_table_how_to_reg.svg';

import styles from './styles.module.css';

export default status => {
  const statusAnticipation = {
    'A Descontar': <UpdateRoundedIcon className={styles.iconBlue} />,
    HIRED: <MoneyOffRoundedIcon className={styles.iconYellow} />,
    SIMULATED: <CheckCircleOutlineRoundedIcon className={styles.iconGreen} />,
    PAID: <CheckCircleOutlineRoundedIcon className={styles.iconGreen} />,
  };

  return statusAnticipation[status] ?? statusAnticipation[''];
};
