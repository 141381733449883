import React from 'react';

import { Button, Grid, Hidden } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import Title from '../../../components/Title';

import styles from './styles.module.css';

function Header({ onClick }) {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.headerContainer}
    >
      <Grid item>
        <Title>Gerenciar Usuários</Title>
      </Grid>

      <Grid item>
        <Hidden xsDown>
          <Button
            startIcon={<AddRoundedIcon className={styles.buttonIcon} />}
            className={styles.newUserButton}
            onClick={onClick}
          >
            Novo usuário
          </Button>
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default Header;
