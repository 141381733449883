import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CashOutActions as Actions } from '../../../../redux/store/CashOut';

import validations from '../validations';

const STEPS = ['Dados do recebedor', 'Confirmação'];
const MASK = '00.000.000/0000-00';

export default function useCashoutTransfer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isSending, currentStep, transferError } = useSelector(
    state => state.cashOut,
  );

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues: {
      cnpj: '',
      amount: 0,
      description: '',
    },
  });

  const onConsultValues = (form, event) => {
    event.preventDefault();
    dispatch(Actions.setFormState(form));
    dispatch(Actions.getDetailsTransfer(form));
  };

  const onBack = () => {
    if (currentStep === 0) {
      history.goBack();
      return;
    }
    dispatch(Actions.changeStep(0));
  };

  const onSubmit = () => {
    dispatch(Actions.setTransferConfirmation());

    if (!transferError.hasError && !transferError.message) {
      setTimeout(() => history.push('/cash-out/manage'), 1000);
    }
  };

  useEffect(() => {
    reset();
    dispatch(Actions.changeStep(0));
    dispatch(Actions.resetForm());
  }, []);

  return {
    control,
    currentStep,
    isValid,
    onBack,
    isSending,
    onSubmit,
    errors,
    onConsult: () => handleSubmit(onConsultValues),
    meta: {
      steps: STEPS,
      mask: MASK,
    },
  };
}
