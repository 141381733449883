const valueStorageAmount = localStorage.getItem('valueStorageAmount');
const valueStoragePayments = localStorage.getItem('valueStoragePayments');

const initialState = {
  data: {
    totalAmount: Number(valueStorageAmount) ? Number(valueStorageAmount) : 0,
    paymentsAmount: Number(valueStoragePayments)
      ? Number(valueStoragePayments)
      : 0,
    fieldCurrentValue: null,
  },
};

export default function applyValueTotalAmount(state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_TOTAL_AMOUNT':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
