import React from 'react';

import {  Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
} 

export default function CustomSnackbar({
  open,
  close,
  severity,
  message
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={close}
    >
      <Alert
        severity={severity}
        onClose={close}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
