import React from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import Tab from '../../../components/Tab';
import FormProgress from '../../../components/FormProgress';
import {
  InsurancesForm,
  PersonalForm,
  ProfessionalForm,
  AddressForm,
  ContactForm,
} from './Forms';

import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';

export default function EditData({
  editMode,
  personalDataForm,
  setPersonalDataForm,
  professionalDataForm,
  setProfessionalDataForm,
  salary,
  setSalary,
  addressDataForm,
  setAddressDataForm,
  contactDataForm,
  setContactDataForm,
  loading,
  setOnSubmitForm,
  onSubmitForm,
  handleExpandedTab,
  expandedTabs,
  requestLoading,
  textFieldFocused,
  setTextFieldFocused,
  CHECK_INSURANCES_FORM,
  CHECK_PERSONAL_FORM,
  CHECK_PROFESSIONAL_FORM,
  CHECK_ADDRESS_FORM,
  CHECK_CONTACT_FORM,
  setExpandedTabs,
  COMPANY_NAME,
  setCheckedTabs,
  checkedTabs,
  locations,
  stockings,
  insuranceTypes,
  onChangeInsuranceType,
  currentInsuranceType,
}) {
  return (
    <Box mb={8} mt={3}>
      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item>
          <Box mr={3}>
            <FormProgress
              step="1"
              checked={
                CHECK_INSURANCES_FORM &&
                checkedTabs.find(item => item === 'insurancesData')
              }
              disabled={
                (!CHECK_INSURANCES_FORM &&
                  !expandedTabs.find(item => item === 'insurancesData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>Seguros</Typography>
            }
            details={
              <Grid
                container
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
                wrap="nowrap"
                style={isMobile ? { paddingBottom: 50 } : null}
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <InsurancesForm
                    loading={loading}
                    insuranceTypes={insuranceTypes}
                    onChangeInsuranceType={onChangeInsuranceType}
                    currentInsuranceType={currentInsuranceType}
                  />
                </Grid>

                <Grid item>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_INSURANCES_FORM}
                    style={isMobile ? { position: 'relative', top: 50 } : null}
                    onClick={() => {
                      setExpandedTabs(['personalData']);
                      setCheckedTabs([...checkedTabs, 'insurancesData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('insurancesData')}
            expanded={expandedTabs.includes('insurancesData')}
          />
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item>
          <Box mr={3}>
            <FormProgress
              step="2"
              checked={
                CHECK_PERSONAL_FORM &&
                checkedTabs.find(item => item === 'personalData')
              }
              disabled={
                (!CHECK_PERSONAL_FORM &&
                  !expandedTabs.find(item => item === 'personalData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>
                Dados Pessoais
              </Typography>
            }
            details={
              <Grid
                container
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
                wrap="nowrap"
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PersonalForm
                    disabled={requestLoading}
                    loading={loading}
                    personalDataForm={personalDataForm}
                    setPersonalDataForm={setPersonalDataForm}
                    setOnSubmitForm={setOnSubmitForm}
                    onSubmitForm={onSubmitForm}
                    editMode={editMode}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                  />
                </Grid>

                <Grid item>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_PERSONAL_FORM}
                    onClick={() => {
                      setExpandedTabs(['professionalData']);
                      setCheckedTabs([...checkedTabs, 'personalData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('personalData')}
            expanded={expandedTabs.includes('personalData')}
          />
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item>
          <Box mr={3}>
            <FormProgress
              step="3"
              checked={
                CHECK_PROFESSIONAL_FORM &&
                checkedTabs.find(item => item === 'professionalData')
              }
              disabled={
                (!CHECK_PROFESSIONAL_FORM &&
                  !expandedTabs.find(item => item === 'professionalData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>
                Dados Profissionais
              </Typography>
            }
            details={
              <Grid
                container
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
                wrap="nowrap"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={styles.professionalData}
                >
                  <ProfessionalForm
                    disabled={requestLoading}
                    loading={loading}
                    professionalDataForm={professionalDataForm}
                    setProfessionalDataForm={setProfessionalDataForm}
                    salary={salary}
                    setSalary={setSalary}
                    setOnSubmitForm={setOnSubmitForm}
                    onSubmitForm={onSubmitForm}
                    editMode={editMode}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                    COMPANY_NAME={COMPANY_NAME}
                    locations={locations}
                    stockings={stockings}
                  />
                </Grid>

                <Grid item>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_PROFESSIONAL_FORM}
                    onClick={() => {
                      setExpandedTabs(['addressData']);
                      setCheckedTabs([...checkedTabs, 'professionalData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('professionalData')}
            expanded={expandedTabs.includes('professionalData')}
          />
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item>
          <Box mr={3}>
            <FormProgress
              step="4"
              checked={
                CHECK_ADDRESS_FORM &&
                checkedTabs.find(item => item === 'addressData')
              }
              disabled={
                (!CHECK_ADDRESS_FORM &&
                  !expandedTabs.find(item => item === 'addressData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>Endereço</Typography>
            }
            details={
              <Grid
                container
                wrap="nowrap"
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AddressForm
                    disabled={requestLoading}
                    loading={loading}
                    addressDataForm={addressDataForm}
                    setAddressDataForm={setAddressDataForm}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                    onSubmitForm={onSubmitForm}
                    setOnSubmitForm={setOnSubmitForm}
                  />
                </Grid>

                <Grid item>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_ADDRESS_FORM}
                    onClick={() => {
                      setExpandedTabs(['contactData']);
                      setCheckedTabs([...checkedTabs, 'addressData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('addressData')}
            expanded={expandedTabs.includes('addressData')}
          />
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item>
          <Box mr={3}>
            <FormProgress
              step="5"
              checked={CHECK_CONTACT_FORM}
              disabled={
                (!CHECK_CONTACT_FORM &&
                  !expandedTabs.find(item => item === 'contactData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>Contato</Typography>
            }
            details={
              <ContactForm
                disabled={requestLoading}
                loading={loading}
                contactDataForm={contactDataForm}
                setContactDataForm={setContactDataForm}
                setOnSubmitForm={setOnSubmitForm}
                onSubmitForm={onSubmitForm}
                textFieldFocused={textFieldFocused}
                setTextFieldFocused={setTextFieldFocused}
              />
            }
            onChange={() => handleExpandedTab('contactData')}
            expanded={expandedTabs.includes('contactData')}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
