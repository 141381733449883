import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import Grid from './Grid';
import Filter from './Filter';
import Table from './Table/Table';
import MobileList from './MobileList';

import ProgressToolBar from '../../../components/ProgressToolBar';

import { isMobile } from '../../../utils/breakpoints';

import { api } from '../../../services/api';
import TablePaginationActions from './Table/TablePaginationActions';

export default function PaycheckDetails({
  match: {
    params: { id: paycheckId },
  },
}) {
  const initialPagination = { totalSize: 0, pageSize: 10, currentPage: 0 };

  const [cpfOrName, setCpfOrName] = useState('');
	const [changedFilter, setChangedFilter] = useState(false);
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [paycheckData, setPaycheckData] = useState({});
  const [paychecks, setPaychecks] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [paychecksLoading, setPaychecksLoading] = useState(false);

  const [totalSize, setTotalSize] = useState(initialPagination.totalSize);
	const [pageSize, setPageSize] = useState(initialPagination.pageSize);
	const [currentPage, setCurrentPage] = useState(initialPagination.currentPage);

  const history = useHistory();

  function handleChangeCpfOrName(event) {
    setCpfOrName(event.target.value);
  }

	const handlePageChange = (_, newPage) => setCurrentPage(newPage);

	const handleRowsPerPageChange = event => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	}

  const isLoadingOn = () => setIsLoading(true);
  const isLoadingOff = () => setIsLoading(false);

  const paychecksLoadingOn = () => setPaychecksLoading(true);
  const paychecksLoadingOff = () => setPaychecksLoading(false);

  function getPaycheckDetails() {
    isLoadingOn();

    api.get(`/paychecks/file/${paycheckId}`)
      .then(({ data }) => {
        setPaycheckData(data);
      })
      .finally(() => isLoadingOff());
  }

  function getPaychecks() {
    paychecksLoadingOn();

    const requestOptions = {
      params: {
        cpfOrName,
        page: currentPage,
        size: pageSize
      },
    };

    api.get(`/paychecks/file/${paycheckId}/search`, requestOptions)
      .then(({ data }) => {
        setPaychecks(data.content);
        setTotalSize(data.totalSize);
      })
      .finally(() => paychecksLoadingOff());
  }

  function onBack() {
    history.push('/paycheck');
  }

	useEffect(() => {
		getPaychecks();
	}, [currentPage, pageSize]);

  useEffect(() => {
		if (changedFilter) {
			const delay = setTimeout(() => {
				if(currentPage > initialPagination.currentPage)
					setCurrentPage(initialPagination.currentPage)
				else
          getPaychecks();
			}, 500);
			return () => clearTimeout(delay);
		}
	}, [cpfOrName]);

	useEffect(() => {
		if (cpfOrName) {
			setChangedFilter(true);
		}
	}, [cpfOrName]);

  useEffect(() => {
    getPaycheckDetails();
    getPaychecks();
  }, []);

  return (
    <>
      <Header />
      <Grid paycheckData={paycheckData} isLoading={isLoading} />
      <Filter
        cpfOrName={cpfOrName}
        setCpfOrName={setCpfOrName}
        handleChangeCpfOrName={handleChangeCpfOrName}
        textFieldFocused={textFieldFocused}
        setTextFieldFocused={setTextFieldFocused}
      />
      {isMobile ? (
        <MobileList employees={paychecks} />
      ) : (
        <Table
          employees={paychecks}
          isLoading={paychecksLoading}
          totalSize={totalSize}
          pageSize={pageSize}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          TablePaginationActions={TablePaginationActions}
        />
      )}
      <ProgressToolBar disableNext onBackClicked={onBack} hidden={isMobile} />
    </>
  );
}
