import React from 'react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from '../../../styles';

const IpDeletionModal = NiceModal.create(({ selected, onDelete }) => {
  const styles = useStyles();
  const modal = useModal();

  return (
    <Dialog open={modal.visible} onClose={modal.remove} maxWidth="sm">
      <DialogTitle>Confirmar operação</DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.accordionSubtitle}>
          Tem certeza de que quer excluir a permissão para o IP {selected.ip}?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.ipModalActions}>
        <Buttons.PrimaryButton
          color="primary"
          title="Cancelar"
          onClick={modal.remove}
        />
        <Buttons.PrimaryButton
          color="secondary"
          title="Excluir"
          onClick={onDelete}
        />
      </DialogActions>
    </Dialog>
  );
});

export default IpDeletionModal;
