import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useSearchReprovedEmployeesQuery } from '../../../../../../../redux/store/Employees/services/reprovedEmployees.api';

export function useReprovedEmployees() {
  const companyCode = useSelector(state => state.companies.currentCompany.code);

  const [filter, setFilter] = useState({
    nameOrCpf: '',
    registration: '',
    hasBankData: null,
    status: '',
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useSearchReprovedEmployeesQuery({
    params: {
      page,
      size,
    },
    filter,
    companyCode,
  });

  const filterDefaultValues = {
    nameOrCpf: '',
    registration: '',
    hasBankData: 'all',
    status: 'ALL',
  };

  const bankDataOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  const statusOptions = [
    { label: 'Todos', value: 'ALL' },
    { label: 'Rejeitado', value: 'REJECTED' },
    { label: 'Reprovado definitivo', value: 'DEFINITIVELY_REPROVED' },
    { label: 'Reprovado com pendência', value: 'PENDING' },
  ];

  function onFilterChange(data) {
    const dataParsed = {
      nameOrCpf: data?.nameOrCpf ?? '',
      registration: data?.registration ?? '',
      hasBankData: data?.hasBankData === 'all' ? null : data?.hasBankData,
      status: data?.status === 'ALL' ? '' : data?.status,
    };

    setFilter(dataParsed);
  }

  function handlePagination(currentPage, currentSize) {
    setPage(currentPage);
    setSize(currentSize);
  }

  return {
    filter: {
      defaultValues: filterDefaultValues,
      handleRequests: onFilterChange,
    },
    meta: {
      bankDataOptions,
      statusOptions,
    },
    table: {
      data: data?.content,
      length: data?.totalSize,
      isLoading: isLoading || isFetching,
      hasError: isError,
      requests: handlePagination,
    },
  };
}
