import React from 'react';
import { Button, Typography } from '@material-ui/core';
import styles from './styles.module.css';

export default function BottomBar({
  selected,
  onClick,
  insuranceSituation,
  purpleButton,
  activeTab,
  hidden,
}) {
  return (
    <div
      className={
        selected.length === 0 || activeTab !== 0 || hidden
          ? styles.hidden
          : styles.container
      }
    >
      <div className={styles.selecteds}>
        <Typography className={styles.count}>{selected.length}</Typography>
        <Typography className={styles.countLabel}>
          {selected?.length > 1 ? 'Selecionados' : 'Selecionado'}
        </Typography>
      </div>
      <Button
        className={purpleButton ? styles.purpleButton : styles.button}
        onClick={onClick}
      >
        {insuranceSituation === 'Vigente'
          ? selected?.length > 1
            ? 'Cancelar seguros'
            : 'Cancelar seguro'
          : selected?.length > 1
          ? 'Ativar seguros'
          : 'Ativar seguro'}
      </Button>
    </div>
  );
}
