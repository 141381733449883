import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export function useTabs() {
  const [valueTab, setValueTab] = useState(0);

  const { employeesAproved, employeesPending, employeesReproved } = useSelector(
    state => state.payroll.manualPayroll,
  );

  function handleChangeValueTab(event, newValue) {
    setValueTab(newValue);
  }

  return {
    tabs: {
      valueTab,
      handleChangeValueTab,
    },

    employeesCount: {
      aproved: employeesAproved.length,
      pending: employeesPending.length,
      reproved: employeesReproved.length,
    },
  };
}
