import React from 'react';

import { Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import styles from './styles.module.css';

export default ({ status }) => {
  return (
    <div className={styles.cardsContainer}>
      <div className={styles.leftCard}>
        <div className={styles.limitGrantedContainer}>
          <Typography className={styles.leftCardTitles}>
            Limite concedido
          </Typography>
          <Typography>
            <Skeleton className={styles.leftCardSubtitles} />
          </Typography>
        </div>
        <div className={styles.usedLimitContainer}>
          <Typography className={styles.leftCardTitles}>
            Limite utilizado
          </Typography>
          <Typography>
            <Skeleton className={styles.leftCardSubtitles} />
          </Typography>
        </div>
      </div>
      <div className={styles.rightCard}>
        <div className={styles.valuesLineUp}>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Funcionários{' '}
              {status === 1
                ? 'ativos'
                : status === 2
                ? 'demitidos'
                : status === 3
                ? 'com licença INSS'
                : status === 4
                ? 'com licença não remunerada'
                : status === 5
                ? 'de férias'
                : ''}
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              <Skeleton />
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Funcionários com limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              <Skeleton />
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Funcionários sem limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              <Skeleton />
            </Typography>
          </div>
        </div>
        <div className={styles.valuesLineDown}>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Valor mínimo de limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              <Skeleton />
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Valor médio de limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              <Skeleton />
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Valor máximo de limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              <Skeleton />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
