import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  MuiThemeProvider,
} from '@material-ui/core';

import Close from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import { theme } from '../../../styles';
import brazilianCurrencyFormatter from '../../../../../../utils/brazilianCurrencyFormatter';
import convertBrazilianDate from '../../../../../../utils/convertBrazilianDate';
import textCapitalize from '../../../../../../utils/textCapitalize';

import styles from './styles.module.css';
import { useGetLoanById, useGetLoanHistoryById } from '../../../utils/services';
import formatDate from '../../../../../../utils/formatDate';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contractItem: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  label: {
    fontWeight: 'bold',
  },
}));

function DetailModal({ open, onCloseDialog, title, text, propose }) {
  const classes = useStyles();

  const { data, isLoading } = useGetLoanById(!!propose && propose.id);
  const { data: history, isLoading: isHistoryLoading } = useGetLoanHistoryById(
    !!propose && propose.id,
  );

  return (
    <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth="sm">
      <div className={styles.container}>
        {/* Dialog Header */}
        <Grid
          container
          className={styles.titleContainer}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <Typography className={styles.historyTitle}>
              <h3>DETALHES DA PROPOSTA</h3>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={onCloseDialog}
              className={styles.closeButton}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {/* Contract Details */}
        <div className={classes.root}>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1, mb: 2 }}
              width="100%"
              height={76}
            />
          ) : (
            <Box
              sx={{
                padding: 16,
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                borderRadius: 4,
              }}
              mt={1}
            >
              <Box
                flexDirection={{
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                }}
                alignItems={{
                  xs: 'flex-start',
                  sm: 'flex-start',
                  md: 'center',
                  lg: 'center',
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 4,
                }}
              >
                <Typography variant="subtitle2">
                  Nº Contrato: <br />
                  <b>{data && !isLoading ? data.id : 'Não gerado'}</b>
                </Typography>

                <Divider orientation="vertical" flexItem />

                <Typography variant="subtitle2">
                  Conta: <br />
                  <b>
                    {data && !isLoading
                      ? data.account || 'Não localizada'
                      : 'Não localizada'}
                  </b>
                </Typography>

                <Divider orientation="vertical" flexItem />

                <Typography variant="subtitle2">
                  Lançamento: <br />
                  <b>
                    {data &&
                      !isLoading &&
                      formatDate(data.releaseDate, 'DD/MM/YYYY')}
                  </b>
                </Typography>

                <Divider orientation="vertical" flexItem />

                <Typography variant="subtitle2">
                  Situação: <br />
                  <b>{data && !isLoading && data.loanStatusDescription}</b>
                </Typography>
              </Box>
            </Box>
          )}

          <Box sx={{ height: 16 }} />

          <Grid container spacing={2}>
            <Grid item xs={7}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 1 }}
                  width="100%"
                  height={148}
                />
              ) : (
                <Box
                  sx={{
                    padding: 16,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderRadius: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'stretch',
                      gap: 16,
                    }}
                  >
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Valor da parcela:
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        <b>
                          {data &&
                            brazilianCurrencyFormatter(data.installmentAmount)}
                        </b>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Parcelas:
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        <b>{data && data.installmentsNumber}</b>
                      </Typography>
                    </Box>
                  </Box>
                  <Divider style={{ margin: '12px 0' }} />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 12,
                    }}
                  >
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Valor solicitado:
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        <b>
                          {data &&
                            brazilianCurrencyFormatter(data.principalAmount)}
                        </b>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Valor pago:
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        <b>
                          {data && brazilianCurrencyFormatter(data.amountPaid)}
                        </b>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Valor à pagar:
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        <b>
                          {data && brazilianCurrencyFormatter(data.amountToPay)}
                        </b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={5}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 1 }}
                  width="100%"
                  height={148}
                />
              ) : (
                <Box
                  sx={{
                    padding: 16,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderRadius: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                      gap: 2,
                    }}
                  >
                    <Typography variant="body2">
                      CET:{' '}
                      <b>{data && `${data.cet ? `${data.cet}%` : '-/-'}`}</b>
                    </Typography>
                    <Typography variant="body2">
                      Juros totais:{' '}
                      <b>
                        {data &&
                          brazilianCurrencyFormatter(data.interestAmount)}
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      Taxa de juros: <b>{data && `${data.interestRate}%`}</b>
                    </Typography>
                    <Typography variant="body2">
                      IOF:{' '}
                      <b>
                        {data && brazilianCurrencyFormatter(data.iofAmount)}
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      Taxa de serviço:{' '}
                      <b>
                        {data && brazilianCurrencyFormatter(data.feeAmount)}
                      </b>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Box sx={{ height: 16 }} />

          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1, mb: 2 }}
              width="100%"
              height={148}
            />
          ) : (
            <Box
              sx={{
                padding: 16,
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                borderRadius: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  Primeira parcela: <br />
                  <b>{data && convertBrazilianDate(data.initialDueDate)}</b>
                </Typography>

                <Divider orientation="vertical" flexItem />

                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  align="right"
                >
                  Próximo vencimento: <br />
                  <b>{data && convertBrazilianDate(data.nextDueDate)}</b>
                </Typography>
              </Box>
            </Box>
          )}
        </div>
        {/* History Table */}
        {history && !isHistoryLoading && history.length > 0 && (
          <>
            <Typography className={styles.historyTitle}>
              <h3>Histórico da proposta</h3>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className={styles.strong}>Operador</span>
                    </TableCell>
                    <TableCell>
                      <span className={styles.strong}>Operação</span>
                    </TableCell>
                    <TableCell>
                      <span className={styles.strong}>Data</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{textCapitalize(row.userModify)}</TableCell>
                      <TableCell>{row.reasonUpdate}</TableCell>
                      <TableCell>
                        {convertBrazilianDate(row.updatedAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default DetailModal;
