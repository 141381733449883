import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid, Button, FormControl } from '@material-ui/core';
import CustomDatePicker from '../../../../../components/DatePicker';
import { ReactComponent as ClearIcon } from '../../../../../assets/clear-filters.svg';

import styles from './styles.module.css';

function ContentFilterDrawer({
  handleClearFilters,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  anticipationList,
  setState,
}) {
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (!initialDate || !finalDate) {
      setInitialDate('');
      setFinalDate('');
    }
  }, []);

  useEffect(() => {
    anticipationList();
  }, [initialDate, finalDate]);

  const handleClickFilter = () => {
    setFilteredValues();
    setState(false);
  };

  const setFilteredValues = () => {
    setInitialDate(initialDate);
    setFinalDate(finalDate);
  };

  function onChangeInitialDate(value) {
    setInitialDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );

    setDisable(false);
  }

  function onChangeFinalDate(value) {
    setFinalDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );

    setDisable(false);
  }

  return (
    <Grid direction="column" className={styles.drawerContent}>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.headerContainer}
      >
        <Grid>
          <p className={styles.titleFilter}>Filtrar</p>
        </Grid>
        <Grid>
          <Button
            onClick={handleClearFilters}
            className={styles.clearFiltersButton}
          >
            <ClearIcon />
            <p className={styles.clearFiltersLabel}>Limpar filtros</p>
          </Button>
        </Grid>
      </Grid>

      <Grid item className={styles.period}>
        <p className={styles.periodLabel}>Período</p>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <CustomDatePicker
            value={initialDate}
            onChange={onChangeInitialDate}
            label={'initialDate ' ? 'Data inicial' : null}
            placeholder="Data inicial"
          />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <CustomDatePicker
            value={finalDate}
            onChange={onChangeFinalDate}
            label={'finalDate' ? 'Data final' : null}
            placeholder="Data final"
          />
        </FormControl>
      </Grid>

      <Grid item className={styles.applyFiltersContainer}>
        <Button
          onClick={handleClickFilter}
          className={
            disable === true
              ? styles.applyFiltersButtonDisabled
              : styles.applyFiltersButton
          }
          disabled={disable === true}
        >
          <p className={styles.applyFiltersLabel}>Aplicar Filtros</p>
        </Button>
      </Grid>
    </Grid>
  );
}

export default ContentFilterDrawer;
