import React from 'react';
import { Box, Divider, Tooltip } from '@material-ui/core';
import moment from 'moment';
import CardTools from '../../../../../../components/CardTools';
import { ReactComponent as ExcelIcon } from '../../../../../../assets/Icons/files/ic_excel.svg';
import truncateString from '../../../../../../utils/truncateString';

export function EnhancedCard({
  isLoading,
  fileName,
  userThatImportedTheFile,
  processedAt,
  statisticsData = [],
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <CardTools.GeneralCard title="Dados gerais">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            gap: '1.5rem',
            flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
          }}
        >
          <CardTools.CardInfo
            title="Nome do arquivo"
            description={
              <Tooltip arrow title={fileName}>
                <Box
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <ExcelIcon /> {truncateString(fileName, 56)}
                </Box>
              </Tooltip>
            }
          />

          <CardTools.CardInfo
            title="Processado em"
            description={moment(processedAt).format('DD/MM/YYYY')}
          />

          <CardTools.CardInfo
            title="Usuário que importou"
            description={userThatImportedTheFile}
          />
        </Box>
      </CardTools.GeneralCard>
      <CardTools.GeneralCard>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            gap: '2rem',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            },
          }}
        >
          {statisticsData.map((value, index, array) => (
            <>
              <CardTools.CardStatistics
                isLoading={isLoading}
                icon={value?.icon}
                title={value?.title}
                numberValue={value?.numberValue}
                numberColor={value?.numberColor}
                numberBoxColor={value?.numberBoxColor}
                numberLabel={value?.numberLabel}
                circularProgressValue={value?.circularProgressValue}
                circularProgressColor={value?.circularProgressColor}
              />
              {index !== array.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </>
          ))}
        </Box>
      </CardTools.GeneralCard>
    </Box>
  );
}
