import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConnectionError from './ConnectionError';
import OnShowBalances from './OnShowBalances';
import { ActionsBalance } from '../../../../redux/store/Balances';

function BalanceCard() {
  const dispatch = useDispatch();

  const { accountSelected } = useSelector(state => state.account);
  const { currentCompany } = useSelector(state => state.companies);
  const {
    isShowBalance,
    isLoadingBalance,
    balances,
    errorOnBalances,
  } = useSelector(state => state.balance);

  useEffect(() => {
    dispatch(ActionsBalance.getBalances());
  }, []);

  useEffect(() => {
    if (isShowBalance && accountSelected?.encodedKey) {
      dispatch(ActionsBalance.getBalances());
    }
  }, [isShowBalance, accountSelected?.encodedKey]);

  const toggleBalance = () => {
    dispatch(ActionsBalance.setIsShowBalance(!isShowBalance));
  };

  const getBalancesAgain = () => {
    dispatch(ActionsBalance.getBalances());
  };

  return errorOnBalances.hasError ? (
    <ConnectionError getBalancesAgain={getBalancesAgain} />
  ) : (
    <OnShowBalances
      showBalance={isShowBalance}
      loading={isLoadingBalance}
      balances={balances}
      toggleBalance={toggleBalance}
    />
  );
}

export default BalanceCard;
