import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import MenuCardRegister from './UI/MenuCardRegister';
import RegisteredLinkedEmployeesSuccessful from './UI/RegisteredLinkedEmployeesSuccessful';
import RegisteredNewEmployeesSuccessful from './UI/RegisteredNewEmployeesSuccessful';
import RegisteredNewEmployeesWithLinkedEmployeesSuccessful from './UI/RegisteredNewEmployeesWithLinkedEmployeesSuccessful';

import ProgressToolBar from '../../../components/ProgressToolBar';

import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';

export default function EmployeesBatchRegistrationSuccess() {
  const history = useHistory();

  const uploadEmployeesConfirmationData = JSON.parse(
    sessionStorage.getItem('uploadEmployeesConfirmationData'),
  );

  const { created, linked } = uploadEmployeesConfirmationData;

  const isNewEmployeesWithLinkedEmployees = created >= 1 && linked >= 1;
  const isNewEmployees = created >= 1 && linked === 0;
  const isLinkedEmployees = linked >= 1 && created === 0;

  function onNextClicked() {
    sessionStorage.removeItem('useTopBar');
    history.push('/employees');
  }

  return (
    <Box>
      {isNewEmployeesWithLinkedEmployees && (
        <RegisteredNewEmployeesWithLinkedEmployeesSuccessful
          createdCount={created}
          linkedCount={linked}
        />
      )}
      {isNewEmployees && (
        <RegisteredNewEmployeesSuccessful createdCount={created} />
      )}
      {isLinkedEmployees && (
        <RegisteredLinkedEmployeesSuccessful linkedCount={linked} />
      )}

      <Box className={styles.divider} />

      <Box className={styles.cardsBoxArea}>
        <Box className={styles.cardsBoxTitle}>
          <Typography className={styles.cardBoxtitleStyle}>
            Próximos passos
          </Typography>
        </Box>
        <Box className={styles.cardsBox}>
          <MenuCardRegister
            title="Agendar folha de pagamento"
            onClick={() => {
              history.push('/payroll');
            }}
            icon={<DescriptionIcon className={styles.cardsIcon} />}
          />
          <MenuCardRegister
            title="Incluir mais funcionários"
            text="Inclua mais funcionários manualmente ou utilizando uma planilha modelo da Somapay."
            buttonText="Acessar"
            onClick={() => {
              history.push('/employees');
            }}
            icon={<PersonAddIcon className={styles.cardsIcon} />}
          />
        </Box>
      </Box>

      <Button className={styles.footerButton} onClick={onNextClicked}>
        Gerenciar funcionários
      </Button>

      <ProgressToolBar
        onNextClicked={onNextClicked}
        nextButtonText="Gerenciar funcionários"
        disableBack
        hidden={isMobile}
      />
    </Box>
  );
}
