import React, { useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PurpleCheckbox from '../../../../components/Checkbox/PurpleCheckbox';
// import FakeTable from '../FakeTable';

import textCapitalize from '../../../../utils/textCapitalize';

import { isMobile } from '../../../../utils/breakpoints';

import styles from './styles.module.css';
import { FakeTable } from '../../../OnlinePointEmployees/UI/Table/UI';

const headCells = [
  { id: 'register', numeric: true, disablePadding: false, label: 'Matrícula' },
  { id: 'cpf', numeric: false, disablePadding: false, label: 'CPF' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'situation', numeric: false, disablePadding: false, label: 'Situação' },
];

function EnhancedTableHead(props) {
  // to do: reativar quando funcionalidade de add todos for implementada
  // const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell />
        {/* to do: reativar quando funcionalidade de add todos for implementada */}
        {/* <Tooltip title="Selecionar todos">
          <TableCell padding="checkbox">
            <PurpleCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        </Tooltip> */}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    marginBottom: 5,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  employees,
  isLoading,
  totalSize,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  selected,
  setSelected,
  isSelectAllClicked,
  setIsSelectAllClicked,
  ignoredEmployees,
  setIgnoredEmployees,
}) {

  const CHECKED_CHECKBOX =
    employees.length > 0 && selected.length === employees.length;
  const INDETERMINATE_CHECKBOX =
    selected.length > 0 && selected.length < employees.length;

  const classes = useStyles();

  useEffect(() => {
    if ((CHECKED_CHECKBOX || INDETERMINATE_CHECKBOX) && isSelectAllClicked) {
      setIsSelectAllClicked(true);
    }
  }, [page]);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setIsSelectAllClicked(true);

      const newSelecteds = employees.map(row => ({
        name: row.name,
        cpf: row.cpf,
        amount: row.amount,
        registration: row.companies[0]?.registration,
        employeeStatus: row.companies[0]?.statusDescription,
      }));

      setIgnoredEmployees([]);
      setSelected(newSelecteds.filter(item => item));
      return;
    }

    setIsSelectAllClicked(false);
    setSelected([]);
  }

  function handleClick(event, row) {
    const selectedIndex = selected
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    let newSelected = [];

    if (isSelectAllClicked) {
      handleIgnoredEmployees(row);
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        name: row.name,
        cpf: row.cpf,
        amount: row.amount,
        registration: row.companies[0]?.registration,
        employeeStatus: row.companies[0]?.statusDescription,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleIgnoredEmployees(row) {
    const selectedIndexIgnoreds = ignoredEmployees
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    const ignoreds = [...ignoredEmployees];

    if (selectedIndexIgnoreds !== -1) {
      ignoreds.splice(selectedIndexIgnoreds, 1);
      setIgnoredEmployees([...ignoreds]);
    } else {
      setIgnoredEmployees([
        ...ignoreds,
        {
          cpf: row.cpf
        },
      ]);
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const isSelected = row =>
    selected
      .map(function(e) {
        return e?.cpf;
      })
      .indexOf(row?.cpf) !== -1;

  const isIgnored = row =>
    ignoredEmployees
      .map(function(e) {
        return e?.cpf;
      })
      .indexOf(row?.cpf) !== -1;

  if (isLoading && !isMobile) {
    return <FakeTable />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={employees.length}
            />

            <TableBody>
              {employees.map((row, index) => {
                const isItemSelected = isSelected(row);
                const isItemIgnored = isIgnored(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.registration}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={
                        row.companies[0]?.statusDescription === 'Demitido'
                          ? styles.tableCellBlocked
                          : styles.tableCell
                      }
                    >
                      {row.companies[0]?.registration}
                    </TableCell>
                    <TableCell
                      className={
                        row.companies[0]?.statusDescription === 'Demitido'
                          ? styles.tableCellBlocked
                          : styles.tableCell
                      }
                    >
                      {row.cpf}
                    </TableCell>
                    <TableCell
                      className={
                        row.companies[0]?.statusDescription === 'Demitido'
                          ? styles.tableCellBlocked
                          : styles.tableCell
                      }
                    >
                      {textCapitalize(row.name)}
                    </TableCell>
                    <TableCell
                      className={
                        row.companies[0]?.statusDescription === 'Demitido'
                          ? styles.tableCellBlocked
                          : styles.tableCell
                      }
                    >
                      {row.companies[0]?.statusDescription}
                    </TableCell>
                    <Tooltip
                      title={
                        row.companies[0]?.statusDescription === 'Demitido'
                          ? 'Não é possivel selecionar, funcionário com situação demitido'
                          : 'Selecionar'
                      }
                    >
                      <TableCell padding="checkbox">
                        <PurpleCheckbox
                          checked={
                            isSelectAllClicked ? !isItemIgnored : isItemSelected
                          }
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={event => handleClick(event, row)}
                          disabled={
                            row.companies[0]?.statusDescription === 'Demitido'
                          }
                        />
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
