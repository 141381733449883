import { useMediaQuery, useTheme } from '@material-ui/core';

export function useFilter() {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'), {
    noSsr: true,
  });

  return {
    isMobile,
  };
}
