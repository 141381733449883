import React from 'react';

import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import moment from 'moment';
import currencyFormatter from '../../../../../../utils/currencyFormatter';

import { ReactComponent as EyeIcon } from '../../../../../../assets/eyesIcon.svg';

import styles from '../styles.module.css';

export default ({ values, pageSize, currentPage, handleGoToDetails }) => {
  return (
    <TableBody>
      {values.slice(0, pageSize).map((row, index) => (
        <TableRow hover>
          <TableCell>{moment(row.competence).format('MM/YYYY')}</TableCell>
          <TableCell>{row.totalLoansRequested}</TableCell>
          <TableCell>{row.totalEmployees}</TableCell>
          <TableCell>
            {row.loanAmountRequested !== 0
              ? currencyFormatter(row.loanAmountRequested)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            {row.loanAmountToPay !== 0
              ? currencyFormatter(row.loanAmountToPay)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            {row.loanAmountPaid !== 0
              ? currencyFormatter(row.loanAmountPaid)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>{row.situation}</TableCell>
          <TableCell className={styles.detailsIconButton}>
            <IconButton
              onClick={() => handleGoToDetails(row.id, row.competence)}
            >
              <Tooltip title="Visualizar detalhes">
                <EyeIcon />
              </Tooltip>
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
