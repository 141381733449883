import React from 'react';
import moment from 'moment';

import { VisibilityOutlined } from '@material-ui/icons';

import { requestTypeOptions } from '../../../../constants';

import currencyFormatter from '../../../../../../../utils/currencyFormatter';

export default function useTableConfig({ handleDetails }) {
  const renderTransferType = ({ transferType }) =>
    requestTypeOptions.find(type => type.value === transferType).label;

  return [
    {
      key: 'creationUser',
      type: 'field',
      align: 'left',
      label: 'Solicitante',
    },
    {
      key: 'transferType',
      type: 'field',
      align: 'left',
      label: 'Tipo de solicitação',
      render: row => renderTransferType(row),
    },
    {
      key: 'creationDate',
      type: 'field',
      align: 'left',
      label: 'Data da solicitação',
      render: row => moment(row.creationDate).format('DD/MM/YYYY'),
    },
    {
      key: 'cancelDate',
      type: 'field',
      align: 'left',
      label: 'Data de reprovação',
      render: row =>
        row?.cancelDate ? moment(row?.cancelDate).format('DD/MM/YYYY') : '-',
    },
    {
      key: 'amount',
      type: 'field',
      align: 'left',
      label: 'Valor',
      render: row => currencyFormatter(row.amount),
    },
    {
      key: 'actions',
      type: 'action',
      align: 'center',
      label: 'Visualizar detalhes',
      icon: <VisibilityOutlined color="primary" />,
      onClick: row => handleDetails(row),
    },
  ];
}
