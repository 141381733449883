import * as yup from 'yup';

export default yup.object().shape({
  cnpj: yup.string().required(),
  amount: yup
    .number()
    .required()
    .min(0.01),
  description: yup.string().test({
    test: val => (val ? val.toString().length <= 140 : true),
    message: 'Máximo de 140 caracteres',
  }),
});
