/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../../services/api';
import { PaginationConstructor } from '../../../helpers/PaginationConstructor';

const registerFilesDetailsBase = new PaginationConstructor({
  extraStates: {
    registerFilesDetails: {
      selectedFile: {},
      employeesList: [],
      generalCardData: {},
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
      filter: {
        name: '',
        cpf: '',
      },
    },
  },
  extraMethods: {
    setSelectedFile: (state, { payload }) => {
      state.registerFilesDetails.selectedFile = payload;
    },

    resetGeneralCardData: state => {
      state.registerFilesDetails.generalCardData = {};
    },
  },
});

const searchEmployeesInTheFile = createAsyncThunk(
  'employeesRegisterFilesDetails/search',
  async (payload, { getState, rejectWithValue }) => {
    const {
      registerFilesDetails: { selectedFile },
      filter,
      page,
      perPage,
    } = getState().employees.employeesRegisterFiles.details;

    const requestConfig = {
      params: {
        page,
        size: perPage,
      },
    };
    const requestBody = { ...filter, fileId: selectedFile?.fileId };

    return api
      .post('files/employees/details', requestBody, requestConfig)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const getEmployeesCount = createAsyncThunk(
  'employeesRegisterFilesDetails/count',
  async (payload, { getState, rejectWithValue }) => {
    const {
      selectedFile,
    } = getState().employees.employeesRegisterFiles.details.registerFilesDetails;

    const requestBody = { fileId: selectedFile?.fileId };

    return api
      .post('files/employees/details/count', requestBody)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const EmployeesRegisterFilesDetailsSlice = createSlice({
  name: 'employeesRegisterFilesDetails',
  initialState: registerFilesDetailsBase.initialState,
  reducers: registerFilesDetailsBase.methods,
  extraReducers: {
    [searchEmployeesInTheFile.pending]: state => {
      state.registerFilesDetails.isLoading = true;
      state.registerFilesDetails.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [searchEmployeesInTheFile.fulfilled]: (state, { payload }) => {
      state.registerFilesDetails.isLoading = false;

      state.registerFilesDetails.employeesList = payload.data?.object;
      state.perPage = payload.data.currentPageSize;
      state.length = payload.data.totalSize;
    },

    [searchEmployeesInTheFile.rejected]: (state, { payload }) => {
      state.registerFilesDetails.isLoading = false;
      state.registerFilesDetails.errorOn.hasError = true;

      if (!payload) {
        state.registerFilesDetails.errorOn.message =
          'Houve um erro ao buscar os arquivos de cadastro';
      } else state.registerFilesDetails.errorOn.message = payload;
    },
    [getEmployeesCount.pending]: state => {
      state.registerFilesDetails.isLoading = true;
      state.registerFilesDetails.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [getEmployeesCount.fulfilled]: (state, { payload: { data } }) => {
      state.registerFilesDetails.isLoading = false;

      state.registerFilesDetails.generalCardData = {
        totalLines: data?.totalLines,
        created: data?.created,
        pending: data?.pending,
        reproved: data?.reproved,
      };
    },
    [getEmployeesCount.rejected]: (state, { payload }) => {
      state.registerFilesDetails.isLoading = false;
      state.registerFilesDetails.errorOn.hasError = true;

      state.registerFilesDetails.errorOn.message =
        payload || 'Houve um erro ao buscar os arquivos de cadastro';
    },
  },
});

const EmployeesRegisterFilesDetailsReducer =
  EmployeesRegisterFilesDetailsSlice.reducer;
const ActionsEmployeesRegisterFilesDetails = {
  ...EmployeesRegisterFilesDetailsSlice.actions,
  searchEmployeesInTheFile,
  getEmployeesCount,
};

export {
  EmployeesRegisterFilesDetailsReducer,
  ActionsEmployeesRegisterFilesDetails,
};
