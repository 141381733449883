import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Buttons from '../../../../../../components/Buttons';
import currencyFormatter from '../../../../../../utils/currencyFormatter';

import useStyles from '../styles';

const ReprocessModal = NiceModal.create(({ selected, onSubmit, onClose }) => {
  const modal = useModal();
  const styles = useStyles();

  const {
    isSending,
    actionResults: { reprocess },
  } = useSelector(state => state.cashOut);

  const handleClose = () => {
    onClose();
    modal.remove();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={modal.visible}
      onClose={handleClose}
      PaperProps={{ className: styles.root }}
    >
      <DialogTitle className={styles.title}>
        Reenviar solicitação de {currencyFormatter(selected.amount)}?
      </DialogTitle>
      <DialogContent className={styles.content}>
        <DialogContentText className={styles.contentText}>
          Ao reenviar, essa solicitação será processada na sequência.
        </DialogContentText>

        {reprocess.message && (
          <Alert severity="error" className={styles.pinField}>
            {reprocess.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Buttons.SecondaryButton
          variant="text"
          color="primary"
          title="CANCELAR"
          onClick={handleClose}
        />
        <Buttons.PrimaryButton
          color="secondary"
          title="REENVIAR"
          onClick={onSubmit}
          loading={isSending}
        />
      </DialogActions>
    </Dialog>
  );
});

export default ReprocessModal;
