import React from 'react';
import {
  Button,
  IconButton,
  Typography,
  Tooltip,
  Menu,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import FakeOtherData from './UI/FakeOtherData';
import CustomDatePicker from '../../../../../../components/DatePicker';
import currencyFormatter from '../../../../../../utils/currencyFormatter';
import formatDate from '../../../../../../utils/formatDate';

import styles from './styles.module.css';

function OtherData({
  anotherData,
  loadingAnotherData,
  initialDateAnotherData,
  finalDateAnotherData,
  onChangeFinalDateAnotherData,
  onChangeInitialDateAnotherData,
  onHandleCleanFilters,
  openMenuEventAnotherData,
  setOpenMenuEventAnotherData,
  onButtonFilterAnotherData,
  setAnimationChartPayment,
  setAnimationTopFive,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.contentHeader}>
            <Typography className={styles.title}>Outros dados</Typography>
          </div>
          <div className={styles.contentDate}>
            {initialDateAnotherData &&
              finalDateAnotherData &&
              openMenuEventAnotherData === null && (
                <Button className={styles.buttonFilterActive}>
                  {formatDate(initialDateAnotherData, 'DD MMM YYYY')} -{' '}
                  {formatDate(finalDateAnotherData, 'DD MMM YYYY')}
                </Button>
              )}

            <Tooltip title="Selecionar período">
              <Button
                className={styles.buttonEvent}
                onClick={event => {
                  setAnimationChartPayment(false);
                  setAnimationTopFive(false);
                  setOpenMenuEventAnotherData(event.currentTarget);
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <IconButton className={styles.buttonIcon}>
                  <EventIcon className={styles.icon} />
                </IconButton>
              </Button>
            </Tooltip>

            <Menu
              id="simple-menu"
              anchorEl={openMenuEventAnotherData}
              keepMounted
              open={openMenuEventAnotherData}
              onClose={() => setOpenMenuEventAnotherData(null)}
            >
              <div className={styles.contentPeriod}>
                <div className={styles.containerPeriod}>
                  <Typography>Período</Typography>
                </div>

                <div className={styles.containerYearAndMonth}>
                  <div className={styles.containerDayDate}>
                    <CustomDatePicker
                      disableFuture
                      value={initialDateAnotherData}
                      onChange={onChangeInitialDateAnotherData}
                      label={initialDateAnotherData ? 'Data inicial' : null}
                      placeholder="Data inicial"
                    />
                  </div>

                  <div className={styles.containerDayDate}>
                    <CustomDatePicker
                      disableFuture
                      value={finalDateAnotherData}
                      onChange={onChangeFinalDateAnotherData}
                      label={finalDateAnotherData ? 'Data final' : null}
                      placeholder="Data final"
                    />
                  </div>
                  <div className={styles.buttonsGroup}>
                    <Button
                      onClick={onHandleCleanFilters}
                      className={styles.closeButton}
                    >
                      CANCELAR
                    </Button>

                    <Button
                      disabled={
                        !(initialDateAnotherData && finalDateAnotherData)
                      }
                      onClick={onButtonFilterAnotherData}
                      className={styles.confirmButton}
                    >
                      APLICAR
                    </Button>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
        </div>

        {loadingAnotherData ? (
          <FakeOtherData />
        ) : (
          <>
            <div className={styles.body}>
              <div className={styles.contentBody}>
                <Typography className={styles.title}>
                  Total de pagamentos realizados
                </Typography>
                <Typography className={styles.subTitle}>
                  {anotherData?.totalPaymentMade}
                </Typography>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.contentTotalEmployees}>
                <Typography className={styles.titleFooter}>
                  Total de
                  {'\n'}
                  <br />
                  funcionários Pagos
                </Typography>
                <Typography className={styles.subTitle}>
                  {anotherData?.totalEmployeesPaid}
                </Typography>
              </div>

              <div className={styles.contentTotalPaidOut}>
                <Typography className={styles.titleFooter}>
                  Valor Total
                  {'\n'}
                  <br />
                  pago
                </Typography>
                <Typography className={styles.subTitle}>
                  {currencyFormatter(anotherData?.totalAmount) || 0}
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OtherData;
