import { useCallback, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ConsignedMarginsListContext } from '..';
import {
  calculateDefaultMargin,
  getConsignedMargin,
  getConsignedMargins,
} from './services';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

export const useConsignedMargins = () => {
  const context = useContext(ConsignedMarginsListContext);

  return context;
};

export const useGetConsignedMargins = params => {
  const { currentCompany } = useSelector(state => state.companies);

  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await getConsignedMargins(params, currentCompany.cnpj);

      setData(data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [params, currentCompany]);

  useEffect(() => {
    getData();
  }, []);

  return {
    isLoading,
    isError,
    data,
    refresh: getData,
  };
};

export const useGetConsignedMargin = id => {
  const { currentCompany } = useSelector(state => state.companies);

  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await getConsignedMargin(id, currentCompany.cnpj);

      setData(data);
    } catch (err) {
      const { errorDetail } = err.response.data?.errors[0];

      handleSnackbarStack().error(errorDetail);

      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id, currentCompany]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  return {
    isLoading,
    isError,
    data,
    refresh: getData,
  };
};

export const useGetConsignedAssignableMargin = salary => {
  const { currentCompany } = useSelector(state => state.companies);

  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await calculateDefaultMargin(
        { salary },
        currentCompany.cnpj,
      );

      setData(data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [salary, currentCompany]);

  return {
    isLoading,
    isError,
    data,
    mutate: getData,
  };
};
