import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import NiceModal from '@ebay/nice-modal-react';

import { PayrollFileUploadActions as Actions } from '../../../../redux/store/Payroll/PayrollFiles';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';
import { pusher } from '../../../../utils/pusher';
import { pusherStatus } from '../../../../enums/pusher';

export default function useMultipleFilesUpload() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = handleSnackbarStack();

  const [showList, setShowList] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isFinished, setFinished] = useState(false);

  const toggleFileList = () => setShowList(!showList);

  const {
    currentCompany: { code: companyCode },
  } = useSelector(state => state.companies);

  const { files, progress } = useSelector(
    state => state.payroll.payrollFilesUpload,
  );

  const hasErrorOnAllFiles = useMemo(
    () => files.length > 0 && files.every(file => !!file.error),
    [files],
  );

  const onAddFile = useCallback(acceptedFiles => {
    const newFiles = acceptedFiles.map(file => {
      return { file, percentage: 0 };
    });

    setProcessing(false);
    setFinished(false);
    dispatch(Actions.addFiles(newFiles));
  }, []);

  const onDeleteFile = file => {
    setProcessing(false);
    setFinished(false);
    dispatch(Actions.removeFile(file));
  };

  const onDeleteAllFiles = () => {
    setProcessing(false);
    setFinished(false);
    dispatch(Actions.removeAllFiles());
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onAddFile,
    accept: {
      'text/plain': ['.txt'],
    },
    disabled: files.length > 0,
  });

  const onConfirm = () => {
    NiceModal.hide('process-confirm-modal');
    history.push('/payroll/file-batch/confirm');
  };

  const onNext = async () => {
    setProcessing(true);
    setFinished(false);
    const actionResult = await dispatch(Actions.setProcessFiles());

    try {
      unwrapResult(actionResult);
    } catch (err) {
      error(err.error);
    }
  };

  useEffect(() => {
    if (!companyCode) return;

    const fileChannel = pusher.channel(companyCode);

    fileChannel.bind(
      'PROCESSED_PAYROLL',
      ({ percentage, fileName, data, status }) => {
        if (
          status === pusherStatus.CANCELED &&
          data?.errors &&
          data?.errors?.length
        ) {
          dispatch(
            Actions.setErrorOnFile({
              fileName,
              error: `Falha ao enviar - ${data?.errors[0]?.description}`,
              status: pusherStatus.CANCELED,
            }),

            dispatch(
              Actions.setFileProgress({
                fileName,
                percentage: 100,
                status: pusherStatus.CANCELED,
              }),
            ),
          );
        } else {
          dispatch(
            Actions.setFileProgress({
              fileName,
              percentage,
              status,
            }),
          );
        }
      },
    );

    return () => {
      fileChannel.unsubscribe();
      fileChannel.unbind_all();
    };
  }, []);

  useEffect(() => {
    if (!companyCode) return;

    const payrollChannel = pusher.subscribe(companyCode);

    payrollChannel.bind('CREATED_PAYMENT_CNAB_240', ({ data }) => {
      if (data) {
        dispatch(Actions.addNsu(data));
      }
    });

    return () => {
      payrollChannel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      setProcessing(false);
      setFinished(true);
    }
  }, [progress]);

  useEffect(() => {
    const areAllFilesProcessed =
      files.length > 0 &&
      files.every(
        file =>
          file?.status &&
          file?.status !== pusherStatus.PROCESSING &&
          file?.percentage === 100,
      );

    const areAllFilesFailed =
      files.length > 0 &&
      files.every(
        file => file?.status && file?.status === pusherStatus.CANCELED,
      );

    if (areAllFilesProcessed && !areAllFilesFailed) {
      NiceModal.show('process-confirm-modal', {
        onConfirm,
      });
      return;
    }

    NiceModal.remove('process-confirm-modal');
  }, [files]);

  return {
    disabled: files.length === 0 || hasErrorOnAllFiles,
    progress,
    onDeleteFile,
    onDeleteAllFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isProcessing,
    isFinished,
    files,
    nsuListLength: files.filter(file => file.percentage === 100).length,
    toggleFileList,
    showList,
    onNext,
  };
}
