import React from 'react';

import { Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function Title({ children }) {
  return (
    <Typography component="h1" className={styles.title}>
      {children}
    </Typography>
  );
}
