import React from 'react';
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';
import Buttons from '../../../../../components/Buttons';

export function ExpandButton({ expanded, onClick, title, ...props }) {
  return (
    <Buttons.SecondaryButton
      color="primary"
      variant="text"
      endIcon={
        expanded ? (
          <ExpandLessRounded color="primary" />
        ) : (
          <ExpandMoreRounded color="primary" />
        )
      }
      title={title}
      onClick={onClick}
      {...props}
    />
  );
}
