import React from 'react';
import { CardList } from './Components/CardList';
import { EnhancedTable } from './Components/EnhancedTable';
import { useTable } from './Hooks/useTable';
import useSelectableTable from './Hooks/useSelectableTable';
import { getComparator, stableSort } from './Helpers/localDataOrdering';

/**
 * Simple or selectable Table component.
 *
 * @param identifier - An unique field in this set of data.
 * @param selectable - If true, the table is selectable.
 * @param selectObject - If true, returns the full object from requests method. If false, returns only the value of the identifier key.
 * @param canSelectAll - If true, clicking on the "select all" checkbox selects the whole list, not only the current page.
 */

export function Table({
  data = [],
  selectedItems = [],
  length = 0,
  selectionLimit = 200,
  isLoading = false,
  hasError = false,
  isHandleLocalData = false,
  selectable = false,
  selectObject = false,
  canSelectAll = false,
  tableConfig = [],
  hasPagination = true,
  rowsPerPageOptions = [5, 10, 50, 100, 200],
  requests = () => {},
  identifier,
  handleSelection = () => {},
  disableSelection,
  resetPageWhen = [],
}) {
  const {
    isMobile,
    hasOrdination,
    page,
    perPage,
    orderBy,
    orderDirection,
    handlePageChange,
    handleRowsPerPageChange,
    handleSortChange,
    handleFetchMoreCards,
  } = useTable({
    length,
    rowsPerPageOptions,
    tableConfig,
    resetPageWhen,
    requests,
  });

  const handleDisableSelection = entry => {
    if (disableSelection) return disableSelection(entry);
  };

  const { selectableConfig, selectedCount } = useSelectableTable({
    items: data,
    selectedItems,
    identifier,
    tableConfig,
    dispatch: handleSelection,
    selectionLimit,
    disableSelection: handleDisableSelection,
    selectObject,
    canSelectAll,
  });

  const config = selectableConfig(selectable, isLoading);

  return isMobile ? (
    <CardList
      data={data}
      length={length}
      config={config}
      isLoading={isLoading}
      hasError={hasError}
      hasMore={data?.length < length}
      onFetchMoreCards={handleFetchMoreCards}
    />
  ) : (
    <EnhancedTable
      data={
        isHandleLocalData
          ? hasOrdination
            ? stableSort(data, getComparator(orderDirection, orderBy))?.slice(
                page * perPage,
                page * perPage + perPage,
              )
            : data.slice(page * perPage, page * perPage + perPage)
          : data
      }
      isLoading={isLoading}
      hasError={hasError}
      isHandleLocalData={isHandleLocalData}
      config={config}
      hasPagination={hasPagination}
      rowsPerPageOptions={rowsPerPageOptions}
      length={length}
      rowsPerPage={perPage}
      page={page}
      hasOrdination={hasOrdination}
      orderBy={orderBy}
      orderDirection={orderDirection}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      handleSortChange={handleSortChange}
      selectedCount={selectable ? selectedCount : null}
    />
  );
}
