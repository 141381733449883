import React from 'react';

import {
  Box,
  Typography,
  Paper,
  Radio,
  Button,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import FakeList from './FakeList';

import styles from './styles.module.css';

export default function List({ types, option, onClickOption, loading }) {
  return (
    <Box mb={9}>
      <h3>Escolha o tipo de arquivo:</h3>

      {loading ? (
        <FakeList />
      ) : (
        types.map((item, index) => (
          <div className={styles.card}>
            <Paper
              className={
                option === index ? styles.borderCard : styles.notBorderCard
              }
            >
              <Button
                className={styles.buttonStyle}
                onClick={() => onClickOption(item, index)}
              >
                <Radio
                  checked={option === index}
                  className={styles.radioButtonStyle}
                />
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography className={styles.titleCard}>
                        {item.name}
                      </Typography>
                    }
                    secondary={
                      <Typography className={styles.descriptionCard}>
                        {item.description}
                      </Typography>
                    }
                  />
                </ListItem>
              </Button>
            </Paper>
          </div>
        ))
      )}
    </Box>
  );
}
