import React, { useState } from 'react';

import {
  Paper,
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  TablePagination,
  TableFooter,
  Tooltip,
  Chip,
  Grid,
  Avatar,
  Typography,
  MenuItem,
  Menu,
} from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';

import styles from './styles';
import FakeRowTable from './FakeRowTable';

import { ReactComponent as EyesIcon } from '../../../../../../assets/eyesIcon.svg';

import textCapitalize from '../../../../../../utils/textCapitalize';
import brazilianCurrencyFormatter from '../../../../../../utils/brazilianCurrencyFormatter';
import formatCPF from '../../../../../../utils/formatCPF';

export default ({
  data,
  theme,
  classes,
  totalSize,
  pageSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
  loading,
  handleDisable,
  handleEdit,
  handleInfo,
}) => {
  const [menuSelected, setMenuSelected] = useState();

  const STATUSES = {
    RELEASED: 'RELEASED',
    BLOCKED_BO: 'BLOCKED_BO',
  };

  const onClickMenu = (propose, event) => {
    setMenuSelected(renderMenuOptions(propose, event.currentTarget));
  };

  const headCells = [
    {
      id: 'employee',
      numeric: false,
      disablePadding: false,
      label: 'Colaborador',
    },
    {
      id: 'salary',
      numeric: true,
      disablePadding: false,
      label: 'Salário',
    },
    {
      id: 'assignableMargin',
      numeric: true,
      disablePadding: false,
      label: 'Margem disponível',
    },
    {
      id: 'payrollLoanLimit',
      numeric: true,
      disablePadding: false,
      label: 'Limite disponível',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Situação',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const renderEmployeeInfo = propose => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Avatar style={styles.nameAvatar}>
            {propose.name ? propose.name.split('')[0] : '-'}
          </Avatar>
        </Grid>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography style={styles.nameLabel}>
                {propose.name ? textCapitalize(propose.name) : '-'}
              </Typography>
            </Grid>

            <Grid item>
              <Typography style={styles.cpfLabel}>
                {formatCPF(propose.cpf)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderStatus = propose => {
    let chipStyle;

    switch (propose.marginSituation) {
      case STATUSES.RELEASED:
        chipStyle = styles.releasedStatus;
        break;

      default:
        chipStyle = styles.blockedBoStatus;

        break;
    }

    return (
      <Chip style={chipStyle} label={propose.marginSituationDescription} />
    );
  };

  const renderButtons = propose => {
    return (
      <div style={styles.displayStyle}>
        <IconButton
          onClick={() => {
            handleInfo(propose);
            closeMenuWhenClicked();
          }}
        >
          <Tooltip title="Visualizar detalhes">
            <EyesIcon />
          </Tooltip>
        </IconButton>

        <IconButton onClick={event => onClickMenu(propose, event)}>
          <Tooltip title="Mais opções">
            <MoreVert style={{ fill: '#5C2483' }} />
          </Tooltip>
        </IconButton>

        {menuSelected}
      </div>
    );
  };

  const renderMenuOptions = (item, anchor) => {
    function getOptions() {
      return [
        <MenuItem
          onClick={() => {
            handleEdit(item);
            closeMenuWhenClicked();
          }}
        >
          <span className={classes.menuListItem}>Editar margem</span>
        </MenuItem>,

        <MenuItem
          onClick={() => {
            handleDisable(item);
            closeMenuWhenClicked();
          }}
        >
          <span className={classes.menuListItem}>Desabilitar consignado</span>
        </MenuItem>,
      ];
    }

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open
        onClose={() => setMenuSelected(null)}
      >
        {getOptions()}
      </Menu>
    );
  };

  function closeMenuWhenClicked() {
    setMenuSelected(null);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding="15px"
                  style={styles.tableHeadLabel}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <FakeRowTable />
            ) : (
              data?.map(propose => (
                <TableRow className={classes.tableRow}>
                  <TableCell align="left" className={classes.statusCell}>
                    {renderEmployeeInfo(propose)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    <Typography style={styles.amountLabel}>
                      {brazilianCurrencyFormatter(propose.salary)}
                    </Typography>
                  </TableCell>

                  <TableCell align="left" className={classes.defaulCell}>
                    <Typography style={styles.amountLabel}>
                      {brazilianCurrencyFormatter(propose.assignableMargin)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    <Typography style={styles.amountLabel}>
                      {brazilianCurrencyFormatter(propose.payrollLoanLimit)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {renderStatus(propose)}
                  </TableCell>
                  <TableCell align="right" className={classes.defaulCell}>
                    {renderButtons(propose)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};
