import React from 'react';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Hidden,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import styles from './styles.module.css';

import CustomDatePicker from '../../../../../components/DatePicker';

function Filters({
  filterPaymentType,
  searchTextNSU,
  situation,
  initialDate,
  finalDate,
  onChangePaymentType,
  onSearchNSUChangeFilters,
  handleChangeSituation,
  onChangeInitialDate,
  onChangeFinalDate,
  validateInitialDateInfo,
  validateFinalDateInfo,
  changedFilter,
  payrollTypes,
}) {

  return (
    <div variant="outlined" className={styles.filters}>
      <TextField
        className={styles.nsu}
        onChange={onSearchNSUChangeFilters}
        value={searchTextNSU}
        placeholder="NSU"
        variant="outlined"
        autoFocus={changedFilter && searchTextNSU}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.searchIcon} />
            </InputAdornment>
          ),
        }}
      />

      <FormControl variant="outlined" className={styles.inputType}>
        <InputLabel id="outlined-type" className={styles.labelType}>
          Tipo
        </InputLabel>
        <Select
          labelId="outlined-type"
          id="outlined-type"
          value={filterPaymentType}
          onChange={onChangePaymentType}
          label="Tipo"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value='Todos'>Todos</MenuItem>
          {
            payrollTypes.map(data => {
              return <MenuItem value={data.code}>{data.description}</MenuItem>;
            })}
          }
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={styles.inputSituation}>
        <InputLabel id="outlined-situation">Situação</InputLabel>
        <Select
          labelId="outlined-situation"
          id="outlined-situation"
          label="Situação"
          onChange={handleChangeSituation}
          value={situation}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value='Todos'>Todos</MenuItem>
          <MenuItem value={1}>Aguardando autorização</MenuItem>
          <MenuItem value={2}>Autorizado</MenuItem>
          <MenuItem value={3}>Saldo insuficiente</MenuItem>
          <MenuItem value={4}>Pago</MenuItem>
          <MenuItem value={5}>Cancelado</MenuItem>
          <MenuItem value={6}>Autorizado imediato</MenuItem>
          <MenuItem value={7}>Pagamento imediato</MenuItem>
          <MenuItem value={8}>Expirado</MenuItem>
          <MenuItem value={9}>Pagamento incompleto</MenuItem>
          <MenuItem value={10}>Pagamento não realizado</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={styles.initialDateField}>
        <CustomDatePicker
          value={initialDate}
          onChange={onChangeInitialDate}
          label={initialDate ? 'Data inicial' : null}
          placeholder="Data inicial"
          error={validateInitialDateInfo}
          disableCalendarIcon={validateInitialDateInfo}
        />
        <Hidden smDown>
          {
            validateInitialDateInfo
            &&
            <Typography className="dateError">
              *Data inválida
            </Typography>
          }
        </Hidden>
      </FormControl>

      <span className={styles.textBetweenDate}>até</span>

      <FormControl variant="outlined" className={styles.finalDateField}>
        <CustomDatePicker
          className={styles.datePicker}
          value={finalDate}
          onChange={onChangeFinalDate}
          label={finalDate ? 'Data final' : null}
          placeholder="Data final"
          error={validateFinalDateInfo}
          disableCalendarIcon={validateFinalDateInfo}
        />
        <Hidden smDown>
          {
            validateFinalDateInfo
            &&
            <Typography className="dateError">
              *Data inválida
            </Typography>
          }
        </Hidden>
      </FormControl>
    </div>
  );
}

export default Filters;
