import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';
import { BankDataModal } from './components/BankDataModal';
import { EmployeesSituationInfoDrawer } from './components/EmployeeSituationInfoDrawer';

export function OnboardingReprovedModals() {
  return (
    <>
      <ModalDef id="handle-employee-bank-data" component={BankDataModal} />
      <ModalDef
        id="employees-situation-info"
        component={EmployeesSituationInfoDrawer}
      />
    </>
  );
}
