import React from 'react';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseOutlined } from '@material-ui/icons';
import Buttons from '../Buttons';

export function TemporaryDrawer({
  open,
  onClose,
  title,
  subtitle,
  drawerWidth,
  hasBackdrop = true,
  hasBgHeader = true,
  children,
}) {
  const useStyles = makeStyles({
    root: {
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },
    },
  });

  const classes = useStyles();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant="temporary"
      anchor="right"
      BackdropProps={{ invisible: hasBackdrop }}
      className={classes.root}
    >
      <Box
        sx={{
          backgroundColor: hasBgHeader ? '#52197F' : 'transparent',
          color: hasBgHeader ? 'var(--white)' : 'var(--black)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '0.5rem',
          padding: '1rem',
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          borderBottom: !hasBgHeader && 'solid 1px var(--backGroundFormField)',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            {title}
          </Typography>

          <Buttons.IconButton
            onClick={onClose}
            icon={
              <CloseOutlined
                style={{
                  color: hasBgHeader ? 'var(--white)' : 'var(--black)',
                  fontSize: '1.5rem',
                }}
              />
            }
            tooltipTitle="Fechar"
          />
        </Box>

        {subtitle && (
          <Box display="flex">
            <Typography variant="subtitle2" style={{ color: '#ebebeb' }}>
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>

      <Box px={3}>{children}</Box>
    </Drawer>
  );
}
