import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { FileCopyOutlined } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  Collapse,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';

import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

import { useStyles } from './styles';

const INITIAL_TOOLTIP = 'Copiar para a área de transferência';

const PayrollPixModal = NiceModal.create(
  ({ pixData: { base64QrCode, copyPasteCode } }) => {
    const modal = useModal();
    const styles = useStyles();
    const match = useMediaQuery(useTheme().breakpoints.up('sm'));

    const [showCode, setShowCode] = useState(false);
    const [tooltipText, setTooltipText] = useState(INITIAL_TOOLTIP);

    const copyCode = () => {
      navigator.clipboard.writeText(copyPasteCode);

      if (match) {
        setTooltipText('Código copiado para a área de transferência!');
        setTimeout(() => setTooltipText(INITIAL_TOOLTIP), 1000);
        return;
      }

      handleSnackbarStack().success(
        'Código copiado para a área de transferência!',
      );
    };

    return (
      <Dialog
        maxWidth="lg"
        fullWidth
        open={modal.visible}
        onClose={modal.remove}
        PaperProps={{ className: styles.root }}
      >
        <Box className={styles.introContainer}>
          <Typography variant="h3" component="h3" className={styles.title}>
            Agora é possível realizar pagamentos de folha via Pix!
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={styles.introText}
          >
            O uso do PIX para pagamento de folha de pagamento oferece diversas
            vantagens tanto para empregadores quanto para empregados.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={styles.introText}
          >
            Primeiramente, a agilidade é um dos principais benefícios: o PIX
            permite transferências instantâneas, realizadas em qualquer dia e a
            qualquer hora, o que elimina a necessidade de esperar por horários
            bancários ou processamentos de compensação que podem ocorrer em
            outros métodos de pagamento.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={styles.introText}
          >
            Isso não só agiliza o processo de pagamento, garantindo que os
            salários sejam creditados rapidamente nas contas dos funcionários,
            mas também contribui para a melhoria da gestão financeira das
            empresas, que podem evitar atrasos e problemas de fluxo de caixa.
          </Typography>
        </Box>
        <Divider orientation={match ? 'vertical' : 'horizontal'} />
        <Box className={styles.codeContainer}>
          <Box className={styles.codeIntro}>
            <Box className={styles.codeIntroTexts}>
              <Typography variant="body2" className={styles.codeTitle}>
                Clique no botão ao lado para copiar o código Pix do seu
                pagamento:
              </Typography>

              <Buttons.SecondaryButton
                size="small"
                variant="outlined"
                color="primary"
                title={showCode ? 'Ocultar código' : 'Exibir código'}
                onClick={() => setShowCode(!showCode)}
              />

              <Collapse in={showCode}>
                <Typography
                  variant="body2"
                  component="p"
                  className={styles.codeText}
                >
                  {copyPasteCode}
                </Typography>
              </Collapse>
            </Box>

            <Buttons.IconButton
              icon={<FileCopyOutlined color="primary" />}
              tooltipTitle={tooltipText}
              onClick={copyCode}
            />
          </Box>
          <Typography
            variant="subtitle"
            component="p"
            className={styles.qrLabel}
          >
            Ou, se preferir, escaneie o código QR abaixo:
          </Typography>
          <img
            src={`data:image/jpeg;base64,${base64QrCode}`}
            alt="Código QR do pagamento via Pix"
            width="200"
            height="200"
          />
        </Box>
        <DialogActions className={styles.actions}>
          <Buttons.PrimaryButton
            color="primary"
            title="Fechar"
            onClick={modal.remove}
          />
        </DialogActions>
      </Dialog>
    );
  },
);

export default PayrollPixModal;
