import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { ReactComponent as AddPersonIcon } from '../../../assets/addPersonIcon.svg';

import styles from './styles.module.css';

export default function NoUsers({ onClick }) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.noListContainer}
    >
      <Grid>
        <AddPersonIcon />
      </Grid>

      <Typography className={styles.titleText}>
        A lista de seus usuários aparecerá aqui.
      </Typography>

      <Typography className={styles.subTitleText}>
        Cadastre agora seus usuários e comece a gerenciá-los.
      </Typography>

      <Button className={styles.noListButton} onClick={onClick}>
        Adicionar usuário
      </Button>
    </Grid>
  );
}
