import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import styles from './styles.module.css';
import { useStyles } from './styles';

const headCells = [
  
  { id: 'id', numeric: true, disablePadding: false, label: 'ID', align: 'left' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Tipo', align: 'left' },
  { id: 'endDate', numeric: false, disablePadding: false, label: 'Vencimento para pagamento', align: 'left' },
  { id: 'priceInCents', numeric: false, disablePadding: false, label: 'Valor', align: 'left' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', align: 'center' },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: ' ',
  },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="10px"
            sortDirection={orderBy === headCell.id ? order : false}
            className={styles.defaultCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'descendente' : 'ascedente'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;