import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import Content from './Content';

import PrimaryModal from '../../../../components/PrimaryModal';
import EndUploadModal from '../../../../components/PrimaryModal';
import CancelUploadModal from '../../../../components/PrimaryModal';
import ProgressToolBar from '../../../../components/ProgressToolBar';

import { api } from '../../../../services/api';
import { pusher } from '../../../../utils/pusher';
import { pusherStatus, pusherChannels } from '../../../../enums/pusher';
import contractTypes from '../../../../enums/contractTypes';
import insuranceTypes from '../../../../enums/insuranceTypes';

export default function FileImport() {
  const insuranceFileItem = JSON.parse(
    localStorage.getItem('insuranceFileItem'),
  );
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const FILE_TYPE = sessionStorage.getItem('fileType');
  const UPLOAD_DATA_INSURANCE = JSON.parse(
    localStorage.getItem(`uploadDataInsurance-${CURRENT_COMPANY_CODE}`),
  );
  const IS_EXCEL_FILE = Boolean(FILE_TYPE === '1');

  const [cancelUploadLoading, setCancelUploadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openEndUploadModal, setOpenEndUploadModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [insuranceType, setInsuranceType] = useState('');
  const [contractType, setContractType] = useState(null);

  const [file, setFile] = useState([]);
  const [insuranceData, setInsuranceData] = useState(
    UPLOAD_DATA_INSURANCE || {},
  );

  const history = useHistory();

  const {
    PROCESSED_INSURANCES_SUCCESS,
    PROCESSED_INSURANCES_ERROR,
    PROCESSING,
    CANCELED,
  } = pusherStatus;

  const { PROCESSED_INSURANCES } = pusherChannels;

  useEffect(() => {
    if (insuranceData?.status === PROCESSING) setIsLoading(true);

    const channel = pusher.subscribe(CURRENT_COMPANY_CODE);

    channel.bind(PROCESSED_INSURANCES, function(data) {
      const { status, data: errorData } = data;

      setInsuranceData(data);

      localStorage.setItem(
        `uploadDataInsurance-${CURRENT_COMPANY_CODE}`,
        JSON.stringify(data),
      );

      if (status === PROCESSING) {
        setIsLoading(true);
      }

      if (status === CANCELED) {
        clearDropArea();
      }

      if (status === PROCESSED_INSURANCES_SUCCESS) {
        setIsLoading(false);
        setOpenEndUploadModal(true);
      }

      if (status === PROCESSED_INSURANCES_ERROR) {
        setIsLoading(false);
        setOpenModal(true);

        if (errorData) {
          setModalTitle(errorData?.errorDetail);
          setModalDescription(errorData?.errorsDescription);
        } else {
          setModalTitle('Preenchimento de arquivo incorreto');
          setModalDescription(
            'Verifique se você preencheu o arquivo modelo corretamente e tente novamente',
          );
        }
      }
    });
  }, []);

  function clearDropArea() {
    setIsLoading(false);
    setCancelUploadLoading(false);
    setOpenCancelModal(false);
    localStorage.removeItem(`uploadDataInsurance-${CURRENT_COMPANY_CODE}`);
    localStorage.removeItem('insuranceFileItem');
    setInsuranceData({});
    setFile([]);
  }

  const onDrop = useCallback(acceptedFiles => {
    let value = acceptedFiles[0].name.substring(
      acceptedFiles[0].name.lastIndexOf('.') + 1,
    );

    value = value?.toLowerCase();

    const VALIDATE_EXCEL_FILE =
      IS_EXCEL_FILE && value !== 'xls' && value !== 'xlsx';

    const VALIDATE_ERP_FILE = !IS_EXCEL_FILE && value !== 'txt';

    if (VALIDATE_EXCEL_FILE) {
      setModalTitle('Formato de arquivo inválido');
      setModalDescription('Escolha um arquivo do tipo .xls ou .xlsx');
      setOpenModal(true);
      return;
    }

    if (VALIDATE_ERP_FILE) {
      setModalTitle('Formato de arquivo inválido');
      setModalDescription('Escolha um arquivo do tipo .txt');
      setOpenModal(true);
      return;
    }

    localStorage.setItem('insuranceFileItem', JSON.stringify(acceptedFiles));
    setFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function onRemoveFileClicked() {
    setFile([]);
    localStorage.removeItem('insuranceFileItem');
  }

  function onCancelUpload() {
    setOpenCancelModal(true);
  }

  function confirmCancelUpload() {
    const { fileId } = insuranceData;

    setCancelUploadLoading(true);

    api
      .delete(`/import/insurance/${fileId}`)
      .catch(() => setCancelUploadLoading(false));
  }

  function fileValidate() {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('file', file[0]);
    formData.append('fileType', FILE_TYPE);
    formData.append('insuranceType', insuranceType);
    !IS_EXCEL_FILE && formData.append('contractType', contractType);

    const requestOptions = {
      headers: {
        companyCode: CURRENT_COMPANY_CODE,
      },
    };

    api.post(`/import/insurance/validate`, formData, requestOptions).catch(
      ({
        response: {
          data: { errors },
        },
      }) => {
        setModalTitle(errors[0].errorDetail);
        setModalDescription(errors[0].errorsDescription);
        setOpenModal(true);
      },
    );
  }

  function handleBack() {
    history.push('/insurance-management/batch-registration/menu');
  }

  function goToReview() {
    history.push(
      `/insurance-management/batch-registration/menu/validate/review/${UPLOAD_DATA_INSURANCE?.fileId}`,
    );

    sessionStorage.setItem(
      'insuranceImportData',
      JSON.stringify({
        name: file[0]?.name,
        insuranceType: insuranceTypes?.filter(
          item => item?.value === insuranceType,
        )[0]?.label,
        contractType: contractTypes?.filter(
          item => item?.value === contractType,
        )[0]?.label,
      }),
    );

    localStorage.removeItem(`uploadDataInsurance-${CURRENT_COMPANY_CODE}`);
    localStorage.removeItem('insuranceFileItem');
    setInsuranceData({});
  }

  function closeModal() {
    if (insuranceData?.data || modalTitle === 'Formato de arquivo inválido') {
      setOpenModal(false);
      return;
    }

    goToReview();
  }

  function handleChangeInsuranceType(event) {
    setInsuranceType(event.target.value);
  }

  function handleChangeContractType(event) {
    setContractType(event.target.value);
  }

  return (
    <>
      <Header />

      <Content
        file={file}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
        useDropzone={useDropzone}
        onDrop={onDrop}
        insuranceFileItem={insuranceFileItem}
        isLoading={isLoading}
        onRemoveFileClicked={onRemoveFileClicked}
        onCancelUpload={onCancelUpload}
        insuranceData={insuranceData}
        IS_EXCEL_FILE={IS_EXCEL_FILE}
        handleChangeInsuranceType={handleChangeInsuranceType}
        insuranceType={insuranceType}
        handleChangeContractType={handleChangeContractType}
        contractType={contractType}
      />

      <PrimaryModal
        open={openModal}
        title={modalTitle}
        text={modalDescription}
        confirmButtonText="Entendi"
        onConfirmClicked={closeModal}
      />

      <CancelUploadModal
        open={openCancelModal}
        onConfirmClicked={confirmCancelUpload}
        onCancelButtonClicked={() => setOpenCancelModal(false)}
        confirmButtonText="Sim, cancelar"
        cancelButtonText="Não, continuar"
        title="Você tem certeza que deseja cancelar a criação deste cadastro?"
        loading={cancelUploadLoading}
        disabledConfirm={cancelUploadLoading}
        disabledCancel={cancelUploadLoading}
      />

      <EndUploadModal
        open={openEndUploadModal}
        onConfirmClicked={goToReview}
        onCancelButtonClicked={() => setOpenEndUploadModal(false)}
        confirmButtonText="Continuar"
        title="Processamento concluído com sucesso!"
        text="Clique em “CONTINUAR” para seguir com o processo"
        purpleButton
      />

      <ProgressToolBar
        onBackClicked={handleBack}
        onNextClicked={fileValidate}
        nextButtonText="Processar"
        backButtonText="Voltar"
        disabled={
          file.length === 0 ||
          !insuranceType ||
          (!contractType && !IS_EXCEL_FILE)
        }
        loading={isLoading}
      />
    </>
  );
}
