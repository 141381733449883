import React, { useRef } from 'react';
import {
  Button,
  Typography,
  TextField,
  Dialog,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import styles from './styles.module.css';
import PurpleRadio from '../RadioButton/PurpleRadio';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { isMobile } from '../../utils/breakpoints';

function FeedbackModal({
  open,
  title,
  text,
  secondTitle,
  sendButtonText,
  description,
  onClick,
  selectedValue,
  setSelectedValue,
  loading,
  feedbackTextField,
}) {
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <div className={styles.container}>
          <div className={styles.content}>
            <Typography className={styles.textBigger}>{description}</Typography>
            <Typography className={styles.text}>{text}</Typography>
          </div>
          <div className={styles.radioLabelsContainer}>
            <Typography
              className={styles.text}
              style={{ position: 'relative', right: 40 }}
            >
              Ruim
            </Typography>
            <Typography
              className={styles.text}
              style={{ position: 'relative', left: 40 }}
            >
              Ótima
            </Typography>
          </div>
          <div className={styles.radioContainer}>
            <FormControlLabel
              value="1"
              control={
                <PurpleRadio
                  checked={selectedValue === '1'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="1"
                />
              }
              label="1"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="2"
              control={
                <PurpleRadio
                  checked={selectedValue === '2'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="2"
                />
              }
              label="2"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="3"
              control={
                <PurpleRadio
                  checked={selectedValue === '3'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="3"
                />
              }
              label="3"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="4"
              control={
                <PurpleRadio
                  checked={selectedValue === '4'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="4"
                />
              }
              label="4"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="5"
              control={
                <PurpleRadio
                  checked={selectedValue === '5'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="5"
                />
              }
              label="5"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="6"
              control={
                <PurpleRadio
                  checked={selectedValue === '6'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="6"
                />
              }
              label="6"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="7"
              control={
                <PurpleRadio
                  checked={selectedValue === '7'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="7"
                />
              }
              label="7"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="8"
              control={
                <PurpleRadio
                  checked={selectedValue === '8'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="8"
                />
              }
              label="8"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="9"
              control={
                <PurpleRadio
                  checked={selectedValue === '9'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="9"
                />
              }
              label="9"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
            <FormControlLabel
              value="10"
              control={
                <PurpleRadio
                  checked={selectedValue === '10'}
                  onChange={event => {
                    setSelectedValue(event.target.value);
                  }}
                  value="10"
                />
              }
              label="10"
              labelPlacement={isMobile ? 'bottom' : 'end'}
              className={styles.formControlLabel}
            />
          </div>
          <div className={styles.header}>
            <Typography className={styles.textBigger}>{title}</Typography>
            <Typography className={styles.text}>{secondTitle}</Typography>
          </div>
          <div className={styles.divider} />
          <TextField
            variant="outlined"
            className={styles.textField}
            rows={2}
            multiline
            inputRef={feedbackTextField}
            placeholder="Gostaria de deixar sua sugestão?"
          />
          <div className={styles.buttonContainer}>
            <Button onClick={onClick} className={styles.sendButton}>
              {!loading ? (
                sendButtonText
              ) : (
                <CircularProgress className={styles.circularLoading} />
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default FeedbackModal;
