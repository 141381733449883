import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../services/api';

const initialState = {
  isLoading: false,
  emailOrCpf: '',
  id: '',
  phoneNumber: '',
  location: {
    latitude: '',
    longitude: '',
  },
  error: {
    hasError: false,
    message: '',
  },
};

const getEmailToken = createAsyncThunk(
  'resetPassword/getEmailToken',
  async ({ emailOrCpf }, { getState, rejectWithValue }) => {
    const { location } = getState().resetPassword;

    const requestOptions = {
      params: {
        emailOrCpf,
      },
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        ...location,
      },
    };

    return api
      .post('/auth/forgot', {}, requestOptions)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getEmailConfirmation = createAsyncThunk(
  'resetPassword/getEmailConfirmation',
  async ({ token }, { getState, rejectWithValue }) => {
    const { emailOrCpf } = getState().resetPassword;
    return api
      .post(`/auth/forgot/sms`, { email: emailOrCpf, token })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const confirmSmsToken = createAsyncThunk(
  'resetPassword/confirmSmsToken',
  async ({ token }, { getState, rejectWithValue }) => {
    const { emailOrCpf } = getState().resetPassword;

    return api
      .put(`/auth/confirmToken`, { email: emailOrCpf, token })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const setNewPassword = createAsyncThunk(
  'resetPassword/setNewPassword',
  async ({ password }, { getState, rejectWithValue }) => {
    const { emailOrCpf, id } = getState().resetPassword;

    const requestOptions = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    return api
      .put(
        '/auth/reset',
        {
          username: emailOrCpf,
          validationToken: id,
          password,
        },
        requestOptions,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const ResetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setEmailOrCpf: (state, { payload }) => {
      state.emailOrCpf = payload;
    },
    setId: (state, { payload }) => {
      state.id = payload;
    },
    setLocation: (state, { payload }) => {
      state.location = { ...payload };
    },
  },
  extraReducers: {
    [getEmailToken.pending]: state => {
      state.isLoading = true;
      state.error = initialState.error;
    },
    [getEmailToken.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.emailOrCpf = payload.data?.email;
    },
    [getEmailToken.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload ||
          'Houve um erro ao enviar o token de acesso para o e-mail cadastrado. Por favor, tente novamente em instantes.',
      };
    },
    [getEmailConfirmation.pending]: state => {
      state.isLoading = true;
      state.error = initialState.error;
    },
    [getEmailConfirmation.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.mobilePhone = payload.data?.mobilePhone;
    },
    [getEmailConfirmation.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload ||
          'Houve um erro ao enviar o token de acesso para o e-mail cadastrado. Por favor, tente novamente em instantes.',
      };
    },
    [confirmSmsToken.pending]: state => {
      state.isLoading = true;
      state.error = initialState.error;
    },
    [confirmSmsToken.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.id = payload.data?.validationToken;
    },
    [confirmSmsToken.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload ||
          'Houve um erro ao enviar o token de acesso para o e-mail cadastrado. Por favor, tente novamente em instantes.',
      };
    },
    [setNewPassword.pending]: state => {
      state.isLoading = true;
      state.error = initialState.error;
    },
    [setNewPassword.fulfilled]: state => {
      state.isLoading = false;
    },
    [setNewPassword.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload ||
          'Houve um erro ao enviar o token de acesso para o e-mail cadastrado. Por favor, tente novamente em instantes.',
      };
    },
  },
});

const resetPasswordReducer = ResetPasswordSlice.reducer;
const ResetPasswordActions = {
  ...ResetPasswordSlice.actions,
  getEmailToken,
  getEmailConfirmation,
  confirmSmsToken,
  setNewPassword,
};

export { resetPasswordReducer, ResetPasswordActions };
