import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export function useConfirmIncludeEmployess() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedRowsEmployees } = useSelector(
    state => state.payroll.manualPayroll,
  );

  const [openFiredEmployeesModal, setOpenFiredEmployeesModal] = useState(false);

  const handleIncludeEmployees = () => {
    const isEmployeeResigned = selectedRowsEmployees?.some(
      item =>
        !item?.hasBankData &&
        item?.companies[0]?.statusDescription === 'Demitido',
    );

    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Pagamento manual',
        step: 3,
        route: history.location.pathname,
      }),
    );

    sessionStorage.setItem(
      'selectedEmployees',
      JSON.stringify(selectedRowsEmployees),
    );

    dispatch({
      type: 'SET_VALUE_INPUTS',
      inputValue: '',
    });

    if (isEmployeeResigned) {
      setOpenFiredEmployeesModal(true);
    } else {
      handleConfirmIncludeEmployees();
    }
  };

  function handleConfirmIncludeEmployees() {
    history.push(
      '/payroll/manual-payroll/include-collaborators/set-employees-values',
    );
  }

  return {
    openFiredEmployeesModal,
    setOpenFiredEmployeesModal,
    handleIncludeEmployees,
    handleConfirmIncludeEmployees,
  };
}
