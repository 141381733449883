import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import style from './styles.module.css';

import Title from '../../../../../../components/Title';

export default function Header({ onManualInsertionClick }) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAnchorClick = e => setAnchorEl(e.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleRedirectToBatch = () => history.push('/consigned/margins/batch');

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      className={style.header}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Title>Gerenciar margens</Title>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={style.buttonMoreMargin}
            startIcon={<AddIcon />}
            onClick={handleAnchorClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography className={style.buttonTitle} display="inline">
              Nova margem
            </Typography>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={onManualInsertionClick}>
              <span>Incluir individualmente</span>
            </MenuItem>
            <MenuItem onClick={handleRedirectToBatch}>
              <span>Incluir a partir de um arquivo</span>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}
