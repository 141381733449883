import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import Form from '../../../../../components/Form';

import { requestTypeOptions } from '../../constants';

export default function ManageRequestsFilters({ onSearch, control }) {
  return (
    <Form.FormBase>
      <Grid container spacing={1} style={{ marginBottom: '0.5rem' }}>
        <Grid item xs={3}>
          <Controller
            name="creationUser"
            control={control}
            render={({ field }) => (
              <Form.TextField
                size="large"
                label="Nome do solicitante"
                {...field}
                onChange={e => {
                  field.onChange(e);
                  onSearch();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="transferType"
            control={control}
            render={({ field }) => (
              <Form.SelectField
                size="large"
                label="Tipo de solicitação"
                options={requestTypeOptions}
                {...field}
                onChange={e => {
                  field.onChange(e);
                  onSearch();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="initialDate"
            render={({ field }) => (
              <Form.DatePicker
                size="large"
                label="Data inicial"
                {...field}
                onChange={e => {
                  field.onChange(e ? moment(e).format('YYYY-MM-DD') : e);
                  onSearch();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="finalDate"
            render={({ field }) => (
              <Form.DatePicker
                size="large"
                label="Data final"
                {...field}
                onChange={e => {
                  field.onChange(e ? moment(e).format('YYYY-MM-DD') : e);
                  onSearch();
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Form.FormBase>
  );
}
