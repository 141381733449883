import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from '../../styles.module.css';

function FakeCommonCard() {
  return (
    <div className={styles.card}>
      <Skeleton height={30} width={120} color="#202020" highlightColor="#444" />

      <div style={{display: 'flex', flexDirection: 'column', marginTop: '7px'}}>
        <Skeleton height={15} width={25} color="#202020" highlightColor="#444" />
        <Skeleton height={25} width={80} color="#202020" highlightColor="#444" />
      </div>
    </div>
  );
}

export default FakeCommonCard;