import React from 'react';

import styles from './styles.module.css';

export default function Header() {
  return (
    <div className={styles.header}>
      <h1>
        Importar contra cheques
      </h1>
    </div>
  )
}
