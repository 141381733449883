import React from 'react';
import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

export function EmployeesQuantityInfo({ row = {} }) {
  return (
    <Box display="flex" alignItems="center" sx={{ gap: '0.5rem' }}>
      <Avatar
        style={{
          height: '2rem',
          width: '2rem',
          backgroundColor: '#52197F1A',
        }}
      >
        <PeopleAltOutlinedIcon color="primary" fontSize="small" />
      </Avatar>
      {`Total: ${row?.totalLines ?? 0}`}
    </Box>
  );
}
