import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { CancelOutlined, Error } from '@material-ui/icons';
import Buttons from '../../../../../Buttons';

export function FilterBottom({
  hasMandatoryFields = false,
  isClearFieldsDisabled = true,
  onClearFields = () => {},
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          visibility: hasMandatoryFields ? 'visible' : 'hidden',
        }}
      >
        <Error style={{ color: '#F3A328' }} />
        <Typography variant="subtitle2" style={{ color: '#666666' }}>
          Campos com{' '}
          {<Box sx={{ display: 'inline', fontWeight: 'bold' }}>(*)</Box>} são
          obrigatórios
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Buttons.SecondaryButton
          icon={
            <CancelOutlined
              color={isClearFieldsDisabled ? 'disabled' : 'secondary'}
            />
          }
          title="Limpar filtros"
          disabled={isClearFieldsDisabled}
          onClick={onClearFields}
        />
      </Box>
    </Box>
  );
}
