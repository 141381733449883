/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { TableActions } from './components/TableActions';

export function Render({ config = [], data = [] }) {
  return data.map(row => (
    <TableRow key={JSON.stringify(row)} hover>
      {config.some(item => item?.type === 'select') && (
        <TableCell>
          {config.find(item => item?.type === 'select')?.render(row)}
        </TableCell>
      )}

      {config
        .filter(value => value?.type === 'field')
        ?.map(field => (
          <TableCell>
            {field?.render ? field?.render(row) : row[field?.key]}
          </TableCell>
        ))}

      {config.some(value => value?.type === 'action') && (
        <TableCell>
          <Box sx={{ width: 'fit-content', display: 'flex', gap: '0.5rem' }}>
            <TableActions
              actionsArray={
                config.filter(value => value?.type === 'action') ?? []
              }
              row={row}
            />
          </Box>
        </TableCell>
      )}
    </TableRow>
  ));
}
