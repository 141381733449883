import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

export default function TabLabel({
  title,
  count,
  selected,
  cellColor,
  selectedColor,
}) {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      {title}

      <Typography
        className={styles.cell}
        style={{
          background: selected ? selectedColor : cellColor,
          color: selected ? '#FFF' : selectedColor,
        }}
      >
        {count ?? '-'}
      </Typography>
    </Box>
  );
}
