const initialState = {
  data: [],
  errors: [],
  linked: 0,
  created: 0,
  processed: 0,
};

export default function batchRegister(state = initialState, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.data };
    case 'SET_DATA_ERRORS':
      return { ...state, errors: action.errors };
    case 'SET_REGISTER_DATA':
      return {
        ...state,
        linked: action.linked,
        created: action.created,
        processed: action.processed,
      };
    default:
      return state;
  }
}
