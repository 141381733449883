import React from 'react'
import {
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    IconButton,
    TableCell
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.css';
import ChevronRight from '@material-ui/icons/ChevronRight';

export default function FakeTable() {
    return (
    <Grid container style={{ height: 281 }}>
        <TableContainer >
          <Table>
            <TableBody className={styles.tableContainer}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ padding: '6px 10px' }}
                  >
                    <Grid
                      container
                      style={{
                        color: 'black',
                        fontFamily: 'DM Sans',
                        marginBottom: 1.88,
                      }}
                    >
                      <Skeleton
                        width={280}
                        color="#202020"
                        highlightColor="#444"
                      />

                    </Grid>
                    <Grid
                      container
                      style={{ color: '#828282', fontFamily: 'DM Sans' }}
                    >
                      <Skeleton
                        width={100}
                        color="#202020"
                        highlightColor="#444"
                      />
                      <Skeleton
                        width={80}
                        color="#202020"
                        highlightColor="#444"
                        style={{ marginLeft: '20px' }}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell align="right" padding="checkbox">
                    <IconButton
                    >
                      <ChevronRight></ChevronRight>
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="left"
                    style={{ padding: '6px 10px' }}
                  >
                    <Grid
                      container
                      style={{
                        color: 'black',
                        fontFamily: 'DM Sans',
                        marginBottom: 1.88,
                      }}
                    >
                      <Skeleton
                        width={280}
                        color="#202020"
                        highlightColor="#444"
                      />

                    </Grid>
                    <Grid
                      container
                      style={{ color: '#828282', fontFamily: 'DM Sans' }}
                    >
                      <Skeleton
                        width={100}
                        color="#202020"
                        highlightColor="#444"
                      />
                      <Skeleton
                        width={80}
                        color="#202020"
                        highlightColor="#444"
                        style={{ marginLeft: '20px' }}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell align="right" padding="checkbox">
                    <IconButton
                    >
                      <ChevronRight></ChevronRight>
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="left"
                    style={{ padding: '6px 10px' }}
                  >
                    <Grid
                      container
                      style={{
                        color: 'black',
                        fontFamily: 'DM Sans',
                        marginBottom: 1.88,
                      }}
                    >
                      <Skeleton
                        width={280}
                        color="#202020"
                        highlightColor="#444"
                      />

                    </Grid>
                    <Grid
                      container
                      style={{ color: '#828282', fontFamily: 'DM Sans' }}
                    >
                      <Skeleton
                        width={100}
                        color="#202020"
                        highlightColor="#444"
                      />
                      <Skeleton
                        width={80}
                        color="#202020"
                        highlightColor="#444"
                        style={{ marginLeft: '20px' }}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell align="right" padding="checkbox">
                    <IconButton
                    >
                      <ChevronRight></ChevronRight>
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="left"
                    style={{ padding: '6px 10px' }}
                  >
                    <Grid
                      container
                      style={{
                        color: 'black',
                        fontFamily: 'DM Sans',
                        marginBottom: 1.88,
                      }}
                    >
                      <Skeleton
                        width={280}
                        color="#202020"
                        highlightColor="#444"
                      />

                    </Grid>
                    <Grid
                      container
                      style={{ color: '#828282', fontFamily: 'DM Sans' }}
                    >
                      <Skeleton
                        width={100}
                        color="#202020"
                        highlightColor="#444"
                      />
                      <Skeleton
                        width={80}
                        color="#202020"
                        highlightColor="#444"
                        style={{ marginLeft: '20px' }}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell align="right" padding="checkbox">
                    <IconButton
                    >
                      <ChevronRight></ChevronRight>
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="left"
                    style={{ padding: '6px 10px' }}
                  >
                    <Grid
                      container
                      style={{
                        color: 'black',
                        fontFamily: 'DM Sans',
                        marginBottom: 1.88,
                      }}
                    >
                      <Skeleton
                        width={280}
                        color="#202020"
                        highlightColor="#444"
                      />

                    </Grid>
                    <Grid
                      container
                      style={{ color: '#828282', fontFamily: 'DM Sans' }}
                    >
                      <Skeleton
                        width={100}
                        color="#202020"
                        highlightColor="#444"
                      />
                      <Skeleton
                        width={80}
                        color="#202020"
                        highlightColor="#444"
                        style={{ marginLeft: '20px' }}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell align="right" padding="checkbox">
                    <IconButton
                    >
                      <ChevronRight></ChevronRight>
                    </IconButton>
                  </TableCell>
                </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )
}
