import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import CustomDatePicker from '../../../../../components/DatePicker';
import { ReactComponent as ClearIcon } from '../../../../../assets/clear-filters.svg';

import styles from './styles.module.css';

function ContentFilterDrawer({
  depositType,
  setDepositType,
  depositSituation,
  setDepositSituation,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  handleClearFilters,
  setIsFilteredFinalDate,
  setIsFilteredSituation,
  setIsFilteredType,
  getDeposits,
  setOpenDrawer,
  type,
  setType,
  situation,
  setSituation,
  date1,
  date2,
  setDate1,
  setDate2
}) {
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (!depositType) {
      setType('');
      setIsFilteredType(false);
    }

    if (!depositSituation) {
      setSituation('');
      setIsFilteredSituation(false);
    }

    if (!initialDate || !finalDate) {
      setDate1('');
      setDate2('');
      setIsFilteredFinalDate(false);
    }
  }, []);

  useEffect(() => {
    getDeposits();
  }, [depositType, depositSituation, initialDate, finalDate]);

  const handleClickFilter = () => {
    setFilteredValues();
    setFilteredStatus();
    setOpenDrawer(false);
  }

  const setFilteredValues = () => {
    setDepositType(type);
    setDepositSituation(situation);
    setInitialDate(date1);
    setFinalDate(date2);
  }

  const setFilteredStatus = () => {
    setIsFilteredType(true);
    setIsFilteredSituation(true)
    setIsFilteredFinalDate(true);
  }

  const handleChangeType = (event) => {
    setType(event.target.value);
    setDisable(false);
  }

  const handleChangeSituation = (event) => {
    setSituation(event.target.value);
    setDisable(false);
  }

  const onChangeInitialDate = (value) => {
    setDate1(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : ''
    );

    setDisable(false);
  }

  const onChangeFinalDate = (value) => {
    setDate2(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : ''
    );
    setDisable(false);
  }

  return (
    <Grid direction="column" className={styles.drawerContent}>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.headerContainer}
      >
        <Grid>
          <p className={styles.titleFilter}>Filtrar</p>
        </Grid>
        <Grid>
          <Button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            <ClearIcon />
            <p className={styles.clearFiltersLabel}>Limpar filtros</p>
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <InputLabel id="demo-simple-select-outlined-label-type">
            Tipo
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label-type"
            id="demo-simple-select-outlined"
            label="Tipo"
            value={type}
            onChange={handleChangeType}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value={1}>TED</MenuItem>
            <MenuItem value={2}>Boleto</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <InputLabel id="demo-simple-select-outlined-label-type">
            Situação
          </InputLabel>
          <Select
            className={styles.selectFilterSituation}
            labelId="demo-simple-select-outlined-label-type"
            id="demo-simple-select-outlined"
            label="Situação"
            value={situation}
            onChange={handleChangeSituation}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value={10}>Aguardando pagamento</MenuItem>
            <MenuItem value={1}>Confirmado</MenuItem>
            <MenuItem value={2}>Aguardando confirmação</MenuItem>
            <MenuItem value={3}>Cancelado</MenuItem>
            <MenuItem value={4}>Pago</MenuItem>
            <MenuItem value={5}>Vencido</MenuItem>
            <MenuItem value={6}>Verificação em processamento</MenuItem>
            <MenuItem value={7}>Verificação desfeita</MenuItem>
            <MenuItem value={8}>Transferência não Confirmada</MenuItem>
            <MenuItem value={9}>Transferência confirmada</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item className={styles.period}>
        <p className={styles.periodLabel}>Período</p>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <CustomDatePicker
            value={date1}
            onChange={onChangeInitialDate}
            label={'initialDate ' ? 'Data inicial' : null}
            placeholder="Data inicial"
          />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <CustomDatePicker
            value={date2}
            onChange={onChangeFinalDate}
            label={'finalDate' ? 'Data final' : null}
            placeholder="Data final"
          />
        </FormControl>
      </Grid>

      <Grid item className={styles.applyFiltersContainer}>
        <Button
          onClick={handleClickFilter} 
          className={disable ? styles.applyFiltersButtonDisabled : styles.applyFiltersButton}
          disabled={disable}
        >
          <p className={styles.applyFiltersLabel}>Aplicar Filtros</p>
        </Button>
      </Grid>
    </Grid>
  );
}

export default ContentFilterDrawer;
