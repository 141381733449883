import React from 'react';

import { Table } from '../../../../../../components/Table';
import { Filter } from '../../../../../../components/Filter';
import Form from '../../../../../../components/Form';

import { useTableConfig } from './Hooks/useTableConfig';
import { usePendingEmployees } from './Hooks/usePendingEmployees';

export function PendingEmployeesTab() {
  const { table, filter } = usePendingEmployees();

  const tableConfig = useTableConfig();

  return (
    <>
      <Filter {...filter}>
        <Form.MaskedField
          name="nameOrCpf"
          label="Pesquisar por nome ou CPF"
          mask={[
            {
              mask: '000.000.000-00',
            },
            {
              mask: /\D/g,
            },
          ]}
        />
      </Filter>

      <Table
        identifier="cpf"
        hasPagination
        tableConfig={tableConfig}
        {...table}
      />
    </>
  );
}
