import React from 'react';
import Lottie from 'react-lottie';

import { Box, Typography } from '@material-ui/core';

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { Alert } from '@material-ui/lab';

import { ReactComponent as PendingActionsIcon } from '../../../../../assets/pending_actions_icon.svg';

import successEmployees from '../../../../../lotties/successEmployees.json';

import { isMobile } from '../../../../../utils/breakpoints';

import styles from '../../styles.module.css';

export default function RegisteredNewEmployeesWithLinkedEmployeesSuccessful({
  createdCount,
  linkedCount,
}) {
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: successEmployees,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box>
      <Box className={styles.header}>
        <Typography className={styles.titleStyle}>Quase lá...</Typography>
      </Box>

      <Box mt={3} className={styles.rowArea}>
        <Box className={styles.block}>
          <Box className={styles.informationBlock}>
            <Box className={styles.iconStyles}>
              <PendingActionsIcon />
            </Box>

            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                className={styles.subtitleStyle}
              >
                {createdCount > 1
                  ? `${createdCount} funcionários foram pré-cadastrados.`
                  : `${createdCount} funcionário foi pré-cadastrado.`}
              </Typography>
              <Typography className={styles.fieldsSubTitle}>
                Estamos conferindo os dados informados para garantir que estejam
                de acordo com os critérios do Sistema Financeiro Nacional.
              </Typography>
              <Typography className={styles.fieldsSubTitle}>
                {createdCount > 1
                  ? `Assim que concluirmos, as contas serão criadas e todos os
                    benefícios estarão disponíveis e acessíveis através do
                    aplicativo Somapay que pode ser baixado nas lojas das
                    plataformas Android e IOS.`
                  : `Assim que concluirmos, a conta será criada e todos os
                    benefícios estarão disponíveis e acessíveis através do
                    aplicativo Somapay que pode ser baixado nas lojas das
                    plataformas Android e IOS.`}
              </Typography>
            </Box>
          </Box>

          <Box mt={3} className={styles.informationBlock}>
            <Box className={styles.iconStyles}>
              <PeopleAltOutlinedIcon className={styles.icon} />
            </Box>

            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                className={styles.subtitleStyle}
              >
                {linkedCount > 1
                  ? `${linkedCount} funcionários foram vinculados com sucesso.`
                  : `${linkedCount} funcionário foi vinculado com sucesso.`}
              </Typography>

              <Typography className={styles.fieldsSubTitle}>
                {linkedCount > 1
                  ? `Os funcionários já possuem conta Somapay e estão aptos a baixar o
                  app Somapay e usufruir de todos os nossos serviços, além do saque digital.
                  Em caso de dúvidas, seu colaborador poderá entrar em contato com os nossos
                  canais de atendimento.`
                  : `O funcionário já possui conta Somapay e está apto a baixar o
                  app Somapay e usufruir de todos os nossos serviços, além do saque digital.
                  Em caso de dúvidas, seu colaborador poderá entrar em contato com os nossos
                  canais de atendimento.`}
              </Typography>
            </Box>
          </Box>

          <Box mt={3}>
            <Alert severity="info">
              {createdCount > 1 ? (
                <Typography>
                  Caso os funcionários pré-cadastrados não sejam aprovados, você
                  pode consultar a tela de{' '}
                  <strong>Funcionários reprovados</strong> para realizar as
                  ações necessários.
                </Typography>
              ) : (
                <Typography>
                  Caso o funcionário pré-cadastrado não seja aprovado, você pode
                  consultar a tela de <strong>Funcionários reprovados</strong>{' '}
                  para realizar as ações necessários.
                </Typography>
              )}
            </Alert>
          </Box>
        </Box>
        <Box className={styles.ilustrationArea}>
          <Lottie
            options={animationOptions}
            width={isMobile ? 250 : 300}
            height={isMobile ? 190 : 250}
          />
        </Box>
      </Box>
    </Box>
  );
}
