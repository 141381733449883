import React from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import {
  Button,
  Container,
  CssBaseline,
  Typography,
  Box,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import styles from './styles.module.css';

import checkAnimation from '../../../lotties/check.json';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const EmailAndCpf = () => {
  const history = useHistory();

  const onButtonClicked = () => {
    history.push('/login');
  };

  return (
    <div className={styles.pageContainer}>
      <MuiThemeProvider>
        <Container>
          <CssBaseline />
          <div className={styles.paper}>
            <Box mb={3}>
              <Lottie options={animationOptions} width={150} height={120} />
            </Box>

            <div className={styles.header}>
              <Typography className={styles.textBigger}>
                Senha alterada com sucesso!
              </Typography>
            </div>
            <div className={styles.content}>
              <Typography className={styles.textSmall}>
                A partir de agora, utilize sua nova senha para acessar a
                plataforma.
              </Typography>
            </div>
            <div className={styles.submitArea}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submit}
                onClick={onButtonClicked}
              >
                Fazer Login
              </Button>
            </div>
          </div>
        </Container>
      </MuiThemeProvider>
    </div>
  );
};

export default EmailAndCpf;
