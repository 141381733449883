import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ActionsAccount as Actions } from '../../../../../../redux/store/Accounts';

import textCapitalize from '../../../../../../utils/textCapitalize';

export default function useRevenueModal({ onClose }) {
  const dispatch = useDispatch();

  const { isRevenueLoading } = useSelector(state => state.account);

  const user = JSON.parse(sessionStorage.getItem('userJobRole'));
  const FORMATTED_USER_NAME = textCapitalize(
    user?.name.substring(0, user?.name.indexOf(' ')),
  );

  const schema = yup.object({
    revenue: yup.string().required(),
    patrimony: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (form, event) => {
    event.preventDefault();

    const newForm = {
      patrimony: Number(
        form.patrimony
          .substr(3)
          .replaceAll('.', '')
          .replace(',', '.'),
      ),
      revenue: Number(
        form.revenue
          .substr(3)
          .replaceAll('.', '')
          .replace(',', '.'),
      ),
    };
    dispatch(Actions.setRevenueData(newForm));
    onClose();
  };

  return {
    form: {
      isDirty,
      isValid,
      handleSubmit: () => handleSubmit(onSubmit),
      control,
      isLoading: isRevenueLoading,
    },
    FORMATTED_USER_NAME,
  };
}
