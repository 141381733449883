import React, { useCallback, useReducer, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Dialog,
  CircularProgress,
  TextField,
  FormControl,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { ReportProblem } from '@material-ui/icons';
import styles from './styles.module.css';
import { removeEmployeeMargin } from '../../../utils/services';
import { handleSnackbarStack } from '../../../../../../../utils/handleSnackbarStack';

function DisableModal({ open, onClose, onUpdate, selected }) {
  const { currentCompany } = useSelector(state => state.companies);

  const [isLoading, toggleLoading] = useReducer(old => !old, false);

  const [reason, setReason] = useState('');

  const handleInputChange = useCallback(e => {
    const { value } = e.target;

    setReason(value);
  }, []);

  const handleSubmit = useCallback(async () => {
    toggleLoading();

    try {
      await removeEmployeeMargin({
        idEmployee: selected.id,
        marginSituation: 'BLOCKED_RH',
        marginChangeReason: reason,
      });

      handleSnackbarStack().success('Margem removida com sucesso');

      onClose();
      onUpdate();
    } catch (err) {
      const { errorDetail } = err.response.data?.errors[0];

      handleSnackbarStack().error(errorDetail);
    }

    toggleLoading();
  }, [reason, selected, currentCompany]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className={styles.container}>
        <Box mt={0}>
          <Typography className={styles.title}>
            Desabilitar consignado do colaborador?
          </Typography>
        </Box>

        <Typography className={styles.text}>
          Informe o motivo para desabilitar o consignado do colaborador
        </Typography>

        <Alert
          color="warning"
          icon={<ReportProblem />}
          style={{ marginBottom: 24 }}
        >
          O colaborador não poderá realizar solicitações por 90 dias.
        </Alert>

        <FormControl>
          <TextField
            type="text"
            variant="outlined"
            onChange={handleInputChange}
            value={reason}
            placeholder="Motivo"
            maxRows={5}
            disabled={isLoading}
            inputProps={{
              maxLength: 150,
              style: { height: 133 },
            }}
            fullWidth
            multiline
            required
          />
        </FormControl>

        <div className={styles.buttonsGroup}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={styles.closeButton}
          >
            Cancelar
          </Button>

          <Button
            onClick={handleSubmit}
            className={styles.nextButton}
            disabled={reason.length < 10 || isLoading}
          >
            {isLoading ? (
              <CircularProgress className={styles.loader} />
            ) : (
              <Typography className={styles.confirmButtonText}>
                Desabilitar
              </Typography>
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DisableModal;
