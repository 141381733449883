import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Typography
} from '@material-ui/core';

import styles from './styles';

export default ({
  open,
  onCloseDialog,
  title,
  text,
  confirmText,
  onConfirmClicked,
  cancelText,
  ignoreText,
  ignoreLink = false,
  circularLoading,
  close = false,
  confirButtonStyle,
  cancelButton = false,
  onClose
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth='xs'
  >
    <div style={styles.container}>
      <DialogTitle id="alert-dialog-title">
        <Typography style={styles.title}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography style={styles.text}>
            {text}
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ padding: 20 }}>
        {!cancelButton ? (
          <Button
            onClick={onCloseDialog}
            style={styles.cancelButtonStyle}
            color="primary"
          >
            {cancelText}
          </Button>
        ) : (
          <span></span>
        )}
        {ignoreLink ? (
          <Button onClick={onConfirmClicked} color="primary" autoFocus>
            {ignoreText}
          </Button>
        ) : (
          <Button
            onClick={close === false ? onConfirmClicked : onCloseDialog}
            variant="contained"
            autoFocus
            style={
              confirButtonStyle === false
                ? styles.confirmButtonStyle
                : styles.closeButtonStyle
            }
          >
            {circularLoading ? (
              <CircularProgress style={styles.circularLoadingStyle} />
            ) : (
              confirmText
            )}
          </Button>
        )}
      </DialogActions>
    </div>
  </Dialog>
);
