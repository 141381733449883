import React from 'react';

import { Typography } from '@material-ui/core';

import currencyFormatter from '../../../../../utils/currencyFormatter';

import styles from './styles.module.css';

export default function GeneralInfo({
  limitGranted,
  usedLimit,
  totalEmployees,
  employeesWithLimit,
  employeesWithoutLimit,
  minLimitValue,
  mediumLimitValue,
  maxLimitValue,
  status,
}) {
  return (
    <div className={styles.cardsContainer}>
      <div className={styles.leftCard}>
        <div className={styles.limitGrantedContainer}>
          <Typography className={styles.leftCardTitles}>
            Limite concedido
          </Typography>
          <Typography className={styles.leftCardSubtitles}>
            {currencyFormatter(limitGranted)}
          </Typography>
        </div>
        <div className={styles.usedLimitContainer}>
          <Typography className={styles.leftCardTitles}>
            Limite utilizado
          </Typography>
          <Typography className={styles.leftCardSubtitles}>
            {currencyFormatter(usedLimit)}
          </Typography>
        </div>
      </div>
      <div className={styles.rightCard}>
        <div className={styles.valuesLineUp}>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Funcionários{' '}
              {status === 1
                ? 'ativos'
                : status === 2
                ? 'demitidos'
                : status === 3
                ? 'com licença INSS'
                : status === 4
                ? 'com licença não remunerada'
                : status === 5
                ? 'de férias'
                : ''}
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              {totalEmployees}
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Funcionários com limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              {employeesWithLimit}
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Funcionários sem limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              {employeesWithoutLimit}
            </Typography>
          </div>
        </div>
        <div className={styles.valuesLineDown}>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Valor mínimo de limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              {minLimitValue !== 0
                ? currencyFormatter(minLimitValue)
                : 'R$ 0,00'}
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Valor médio de limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              {mediumLimitValue !== 0
                ? currencyFormatter(mediumLimitValue)
                : 'R$ 0,00'}
            </Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.rightCardTitles}>
              Valor máximo de limite
            </Typography>
            <Typography className={styles.rightCardSubtitles}>
              {maxLimitValue !== 0
                ? currencyFormatter(maxLimitValue)
                : 'R$ 0,00'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
