import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    stepper: {
      padding: '1.25rem',
      marginBottom: '1rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',

      '& .MuiStepper-root': {
        padding: 0,
      },
    },
    root: {
      padding: '1.5rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.125rem',
    },
    form: {
      margin: '1rem 0',
    },
  }),
);
