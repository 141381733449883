import { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { AccessActions } from '../../../redux/store/AccessSettings';

import { hasAuthorization, profiles } from '../../../utils/hasAuthorization';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

export default function useAccessSettings() {
  const dispatch = useDispatch();
  const { error } = handleSnackbarStack();

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const isAdmin = hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN]);

  const [expanded, setExpanded] = useState(false);

  const handleExpand = id => (_, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  const getInitialData = async () => {
    const actionResult = await dispatch(AccessActions.getAccessSettings());

    try {
      unwrapResult(actionResult);
    } catch (err) {
      error(err.message);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return {
    expanded,
    handleExpand,
    isAdmin,
  };
}
