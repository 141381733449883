import React from 'react';
import { Typography } from '@material-ui/core';

import { ReactComponent as NoContentImage } from '../../../../../../assets/no-content.svg';
import { ReactComponent as NoChartImage } from '../../../../../../assets/no-chart.svg';
import styles from './styles.module.css';

function NoContent({ title, subTitle }) {
  return (
    <div className={styles.container}>
      {subTitle ? (
        <NoContentImage className={styles.image} />
      ) : (
        <NoChartImage className={styles.image} />
      )}
      <Typography className={styles.textMedium}>{title}</Typography>
      <Typography className={styles.textSmall}>{subTitle}</Typography>
    </div>
  );
}

export default NoContent;
