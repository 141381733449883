import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  TableHead,
} from '@material-ui/core';

import styles from '../styleView.module.css';
import formatCPF from '../../../../utils/formatCPF';
import { ChipStatus } from '../../../../components/ChipStatus';
import { useEmployeesAprovedSituations } from '../Hooks/useEmployeesAprovedSituations';

export default function TableEmployees({
  data,
  loading,
  totalSize,
  pageSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
}) {
  const {
    handleEmployeeSituation,
    handleEmployeeSituationIcon,
  } = useEmployeesAprovedSituations();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">CPF</TableCell>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">Centro de custo</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? (
            data.map(element => (
              <TableRow
                className={
                  element?.employeeStatusDescription === 'Demitido' &&
                  styles.tableRowWithFiredEmployee
                }
              >
                <TableCell className={styles.tableCell} align="left">
                  {element.cpf ? formatCPF(element?.cpf) : '-'}
                </TableCell>
                <TableCell className={styles.tableCell} align="left">
                  {element.name}
                </TableCell>
                <TableCell className={styles.tableCell} align="left">
                  {element.locationName === null
                    ? 'Não cadastrado'
                    : element.locationName}
                </TableCell>
                <TableCell className={styles.tableCell} align="left">
                  <ChipStatus
                    icon={handleEmployeeSituationIcon(element?.employeeStatus)}
                    status={handleEmployeeSituation(element?.employeeStatus)}
                    label={element?.employeeStatusDescription}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell} align="left">
                  {element.amount.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="th" scope="row">
                <Skeleton width={80} color="#202020" highlightColor="#444" />
              </TableCell>
              <TableCell align="center">
                <Skeleton width={80} color="#202020" highlightColor="#444" />
              </TableCell>
              <TableCell align="center">
                <Skeleton width={80} color="#202020" highlightColor="#444" />
              </TableCell>
              <TableCell align="center">
                <Skeleton width={80} color="#202020" highlightColor="#444" />
              </TableCell>
              <TableCell align="center">
                <Skeleton width={80} color="#202020" highlightColor="#444" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={totalSize}
              rowsPerPage={pageSize}
              page={currentPage}
              labelRowsPerPage="Linhas por página"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
