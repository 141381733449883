import React from 'react';
import moment from 'moment';

import Chip from '@material-ui/core/Chip';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import styles from './styles.module.css';

function ChipsComponent({
  paycheckType,
  competence,
  setPaycheckType,
  setCompetence
}) {
  function convertPaymentType(value) {
    switch(value) {
      case 'SALARY':
        return 'Salário';
      case 'VACATION':
        return 'Férias';
      case 'THIRTEENTH_SALARY':
        return '13º Salário';
    }
  };

  const TYPE = convertPaymentType(paycheckType);

  return (
    <div className={styles.chipContainer}>
      <Chip
        label={TYPE}
        onDelete={() => setPaycheckType('')}
        className={(paycheckType && paycheckType !== 'Todos') ? styles.chip : styles.hiddenChip}
        deleteIcon={<CancelRoundedIcon />}
        variant="outlined"
      />
      <Chip
        label={`${moment(competence).format('MMMM')} ${moment(competence).format('YYYY')}`}
        onDelete={() => setCompetence('')}
        className={competence ? styles.chip : styles.hiddenChip}
        deleteIcon={<CancelRoundedIcon />}
        variant="outlined"
      />
    </div>
  );
}

export default ChipsComponent;
