import { reduxApi } from '../../../../services/reduxApi';

const reprovedEmployeesTags = {
  tag: 'REPROVED_EMPLOYEES',
  listAbstractTag: { type: 'REPROVED_EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [reprovedEmployeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      searchReprovedEmployees: builder.query({
        query: ({ params = {}, filter = {}, companyCode = '' }) => ({
          url: `/employees/reproved/${companyCode}`,
          method: 'POST',
          params,
          body: filter,
        }),
        providesTags: [reprovedEmployeesTags.listAbstractTag],
      }),

      updateEmployeeBankData: builder.mutation({
        query: body => ({
          url: '/employees/bankData',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [reprovedEmployeesTags.tag],
      }),
    }),
  });

export const {
  useSearchReprovedEmployeesQuery,
  useGetBankListQuery,
  useUpdateEmployeeBankDataMutation,
} = slice;
