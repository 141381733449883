import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@material-ui/core';
import styles from './styles.module.css';

function PrimaryModal({
  open,
  onCloseDialog,
  fullscreen = false,
  title,
  text,
  children = null,
  cancelButtonText,
  onCancelButtonClicked,
  onConfirmClicked,
  confirmButtonText,
  loading,
  errorMessage,
  purpleButton,
  disabledConfirm,
  disabledCancel,
}) {
  return (
    <Dialog
      fullScreen={fullscreen}
      open={open}
      onClose={onCloseDialog}
      fullWidth
      maxWidth="xs"
    >
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.text}>{text}</Typography>
        {children}
        <Typography className={styles.errorMessage}>{errorMessage}</Typography>

        <div className={styles.buttonsGroup}>
          {cancelButtonText && (
            <Button
              onClick={onCancelButtonClicked}
              className={styles.closeButton}
              disabled={disabledCancel}
            >
              {cancelButtonText}
            </Button>
          )}

          {confirmButtonText && (
            <Button
              onClick={onConfirmClicked}
              className={
                purpleButton ? styles.purpleConfirmButton : styles.confirmButton
              }
              disabled={disabledConfirm}
            >
              {loading ? (
                <CircularProgress className={styles.loader} />
              ) : (
                confirmButtonText
              )}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default PrimaryModal;
