import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from '../../styles.module.css';

export function DataContent({ label, content, loading }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: '0.875rem',
      }}
    >
      <Typography variant="subtitle2" className={styles.label}>
        {label}
      </Typography>
      <Typography variant="subtitle2" className={styles.contentAlignRight}>
        {loading ? (
          <Skeleton variant="text" animantion="wave" width={150} />
        ) : (
          content ?? '-'
        )}
      </Typography>
    </Box>
  );
}
