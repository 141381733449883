import React from 'react';
import { Box, Collapse } from '@material-ui/core';
import { Controller } from 'react-hook-form';

export function FilterExtraFields({
  fields,
  isViewingMoreFields = false,
  control = {},
}) {
  return (
    <Collapse
      orientation="vertical"
      unmountOnExit
      collapsedSize={0}
      in={isViewingMoreFields}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '0.5rem 0 2rem 0',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '1rem',
          }}
        >
          {fields?.map((filterField, index) => (
            <Box
              key={String(index)}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name={filterField?.props?.name}
                control={control}
                defaultValue={
                  control?._defaultValues?.[filterField?.props?.name]
                }
                render={({ field }) =>
                  React.Children.map(filterField, child =>
                    React.isValidElement(child)
                      ? React.cloneElement(child, { size: 'medium', ...field })
                      : child,
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Collapse>
  );
}
