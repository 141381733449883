import React from 'react';
import Lottie from 'react-lottie';

import { Box, Typography } from '@material-ui/core';

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

import successEmployees from '../../../../../lotties/successEmployees.json';

import { isMobile } from '../../../../../utils/breakpoints';

import styles from '../../styles.module.css';

export default function RegisteredLinkedEmployeesSuccessful({ linkedCount }) {
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: successEmployees,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box>
      <Box className={styles.header}>
        <Typography className={styles.titleStyle}>Tudo certo!</Typography>
      </Box>

      <Box mt={3} className={styles.rowArea}>
        <Box className={styles.block}>
          <Box className={styles.informationBlock}>
            <Box className={styles.iconStyles}>
              <PeopleAltOutlinedIcon className={styles.icon} />
            </Box>

            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                className={styles.subtitleStyle}
              >
                {linkedCount > 1
                  ? `${linkedCount} funcionários foram vinculados com sucesso.`
                  : `${linkedCount} funcionário foi vinculado com sucesso.`}
              </Typography>
              <Typography className={styles.fieldsSubTitle}>
                {linkedCount > 1
                  ? `Os funcionários já possuem conta Somapay e estão aptos a baixar o
                  app Somapay e usufruir de todos os nossos serviços, além do saque digital.
                  Em caso de dúvidas, seu colaborador poderá entrar em contato com os nossos
                  canais de atendimento.`
                  : `O funcionário já possui conta Somapay e está apto a baixar o
                  app Somapay e usufruir de todos os nossos serviços, além do saque digital.
                  Em caso de dúvidas, seu colaborador poderá entrar em contato com os nossos
                  canais de atendimento.`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.ilustrationArea}>
          <Lottie
            options={animationOptions}
            width={isMobile ? 250 : 300}
            height={isMobile ? 190 : 250}
          />
        </Box>
      </Box>
    </Box>
  );
}
