export function handleEmployeeStatus(status) {
  switch(status) {
    case 'ACTIVE':
      return {
        label: 'Ativo',
        color: '#AB40FF'
      }
    case 'PENDING':
      return {
        label: 'Pendente',
        color: '#FF8565'
      }
    case 'DISABLED':
      return {
        label: 'Desabilitado',
        color: '#B7B3B2'
      }
    default:
      return {
        label: 'Em branco',
        color: 'white'
      }
  }
}