import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { Search } from '@material-ui/icons';
import styles from './styles.module.css';
import SituationEmployeesTabs from '../../../../Payroll/ManualPayroll/UI/EmployeesListModal/Components/SituationEmployeesTabs';
import CustomSnackbar from '../../../../../components/Snackbar';
import TableEmployeeRecharge from '../TableEmployeeRecharge';
import TablePaginationActions from '../../../../OnlinePointCharges/UI/Table/TablePaginationActions';
import { api } from '../../../../../services/api';
import verifyNumberInString from '../../../../../utils/verifyNumberInString';
import TableEmployee from '../TableEmployee';

function EmployeesRechargeListModal({
  open,
  onClose,
  onCancelClicked,
  onConfirmClicked,
}) {
  const [
    employeesResignCountSelecteds,
    setEmployeesResignCountSelecteds,
  ] = useState([]);
  const [previewSelected, setPreviewSelected] = useState([]);
  const [nameOrCpf, setNameOrCpf] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [loading, setLoading] = useState(true);
  const [changedFilter, setChangedFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [employeesBeeVale, setEmployeesBeeVale] = useState([]);
  const [snackbar, setSnackbar] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ignoredEmployees, setIgnoredEmployees] = useState([]);
  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);

  const companyCode = sessionStorage.getItem('currentCompanyCode');

  useEffect(() => {
    getEmployeeBeeVale();
  }, [currentPage, currentPageSize]);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        getEmployeeBeeVale();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [nameOrCpf]);

  function getEmployeeBeeVale() {
    setLoading(true);
    const isNumber = verifyNumberInString(nameOrCpf);
    const requestOptions = {
      params: {
        page: currentPage,
        size: currentPageSize,
        cpf: isNumber ? nameOrCpf : '',
        name: !isNumber ? nameOrCpf : '',
        // status: (searchSituation !== 'Todos' && searchSituation) || null
      },
    };

    api
      .get(`beeValeBenefits/search/${companyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setCurrentPage(data.currentPage);
        setCurrentPageSize(data.currentPageSize);
        setEmployeesBeeVale(data.employees);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function handleChangePage(event, newPage) {
    setCurrentPage(newPage);
    setChangedFilter(true);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  function handleOpenModalDeleteEmployee(employeeCpf) {}

  function onSearchChange(event) {
    setNameOrCpf(event?.target?.value);
    setChangedFilter(true);
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <Grid
          container
          direction="column"
          className={styles.employeesListModalContainer}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', marginBottom: '1.5rem' }}
          >
            Incluir colaboradores
          </Typography>

          <TextField
            value={nameOrCpf}
            onChange={event => onSearchChange(event)}
            className={styles.employeesListModalSearchInput}
            variant="outlined"
            size="small"
            placeholder="Pesquise por nome ou CPF"
            InputProps={{
              style: {
                borderRadius: 6,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.iconColor} />
                </InputAdornment>
              ),
              endAdornment: nameOrCpf?.length > 0 && (
                <IconButton
                  className={styles.endAdornment}
                  onClick={() => setNameOrCpf('')}
                >
                  <CloseRoundedIcon className={styles.endAdornmentIcon} />
                </IconButton>
              ),
            }}
          />

          <Box mt={3}>
            <TableEmployee
              employees={employeesBeeVale}
              isLoading={loading}
              totalSize={totalSize}
              page={currentPage}
              setPage={setCurrentPage}
              rowsPerPage={currentPageSize}
              setRowsPerPage={setCurrentPageSize}
              selected={previewSelected}
              isSelectAllClicked={isSelectAllClicked}
              setIsSelectAllClicked={setIsSelectAllClicked}
              setSelected={setPreviewSelected}
              ignoredEmployees={ignoredEmployees}
              setIgnoredEmployees={setIgnoredEmployees}
            />

            {/* <TableEmployeeRecharge
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              loading={loading}
              changedFilter={changedFilter}
              currentPage={currentPage}
              pageSize={pageSize}
              totalSize={totalSize}
              currentPageSize={currentPageSize}
              rows={employeesBeeVale}
              getComparator={getComparator(order, orderBy)}
              stableSort={stableSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              TablePaginationActions={TablePaginationActions}
              handleOpenModalDeleteEmployee={handleOpenModalDeleteEmployee}
              previewSelected={previewSelected}
              setPreviewSelected={setPreviewSelected}
            /> */}
          </Box>

          <Grid
            container
            justifyContent={
              previewSelected?.length > 0 ? 'space-between' : 'flex-end'
            }
            alignItems="center"
            style={{ marginTop: '2rem' }}
          >
            {previewSelected?.length > 0 && (
              <Box display="flex" alignItems="center" sx={{ gap: '1rem' }}>
                <Grid item className={styles.employeesListSelecteds}>
                  <div className={styles.employeesListSelectedsCount}>
                    {previewSelected?.length}
                  </div>

                  <Typography className={styles.employeesListSelectedsText}>
                    {previewSelected?.length > 1
                      ? 'Selecionados'
                      : 'Selecionado'}
                  </Typography>
                </Grid>

                {employeesResignCountSelecteds.length > 0 && (
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <InfoIcon className={styles.iconInfo} />
                      <Typography
                        variant="subtitle2"
                        className={styles.inforResignColor}
                      >
                        {employeesResignCountSelecteds.length > 1
                          ? `Você selecionou ${employeesResignCountSelecteds.length} colaboradores demitidos`
                          : `Você selecionou ${employeesResignCountSelecteds.length} colaborador demitido`}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={onCancelClicked}
                className={styles.employeesListModalCloseButton}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => {
                  onConfirmClicked(previewSelected);
                  setSnackbar(true);
                }}
                className={styles.employeesListModalConfirmButton}
                disabled={!previewSelected?.length > 0}
              >
                Incluir
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      <CustomSnackbar
        severity="success"
        open={snackbar}
        close={() => setSnackbar(false)}
        message="Colaboradores adicionando com sucesso."
      />
    </>
  );
}

export default EmployeesRechargeListModal;
