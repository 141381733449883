import React from 'react';
import NiceModal, { ModalDef, useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@material-ui/core';

import useFaceAuth from './hooks/useFaceAuth';

import { useStyles } from './styles';

const FirstAccessFaceAuth = NiceModal.create(({ validationToken }) => {
  const styles = useStyles();
  const modal = useModal();

  const { authLink } = useFaceAuth({ validationToken });

  return (
    <Dialog
      open={modal.visible}
      onClose={() => {}}
      keepMounted
      maxWidth="xl"
      PaperProps={{ className: styles.root }}
    >
      <iframe
        className={styles.frame}
        title="Autenticação via selfie"
        src={authLink}
        allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
      />
    </Dialog>
  );
});

export default function AuthModal() {
  return <ModalDef component={FirstAccessFaceAuth} id="first-access-auth" />;
}
