import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: [],
};

const employeesGeneralSlice = createSlice({
  name: 'employeesGeneral',
  initialState,
  reducers: {
    setLocations: (state, { payload }) => {
      state.locations = payload;
    },
  },
  extraReducers: {},
});

const employeesGeneralReducer = employeesGeneralSlice.reducer;
const ActionsEmployeesGeneral = {
  ...employeesGeneralSlice.actions,
};

export { employeesGeneralReducer, ActionsEmployeesGeneral };
