import { isNumeric } from './validateIsNumeric';

export default function formatDigitValue(value) {
  if (value === 'x' || value === 'xx') {
    return 'x';
  }

  if (isNumeric(value) && value % 1 === 0) {
    return isNumeric(value) && value.trim();
  }

  return '';
}
