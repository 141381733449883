import React from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import Tab from '../../../components/Tab';
import FormProgress from '../../../components/FormProgress';
import {
  PersonalForm,
  ProfessionalForm,
  BankForm,
  AddressForm,
  ContactForm,
} from './Forms';
import { PaymentHistory } from './PaymentHistory';

import styles from './styles.module.css';

export default function EditData({
  editMode,
  personalDataForm,
  setPersonalDataForm,
  professionalDataForm,
  setProfessionalDataForm,
  bankDataForm,
  setBankDataForm,
  addressDataForm,
  setAddressDataForm,
  contactDataForm,
  setContactDataForm,
  loading,
  setOnSubmitForm,
  onSubmitForm,
  handleExpandedTab,
  expandedTabs,
  requestLoading,
  textFieldFocused,
  setTextFieldFocused,
  IS_REGISTER_VIEW,
  CHECK_PERSONAL_FORM,
  CHECK_PROFESSIONAL_FORM,
  CHECK_BANK_FORM,
  CHECK_ADDRESS_FORM,
  activeTransferSwitch,
  setActiveTransferSwitch,
  setExpandedTabs,
  COMPANY_NAME,
  setCheckedTabs,
  checkedTabs,
  locations,
  stockings,
  onboardEnabled,
  COMPANY_SCHEDULED_TRANSFEER,
  isRegistrationDataEditable,
}) {
  return (
    <Box mb={8}>
      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
          <Box mr={3}>
            <FormProgress
              step="1"
              checked={
                CHECK_PERSONAL_FORM &&
                checkedTabs.find(item => item === 'personalData')
              }
              disabled={
                (!CHECK_PERSONAL_FORM &&
                  !expandedTabs.find(item => item === 'personalData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>
                Dados Pessoais
              </Typography>
            }
            details={
              <Grid
                container
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
                wrap="nowrap"
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PersonalForm
                    disabled={
                      (!editMode && !IS_REGISTER_VIEW) ||
                      requestLoading ||
                      !isRegistrationDataEditable
                    }
                    loading={loading}
                    personalDataForm={personalDataForm}
                    setPersonalDataForm={setPersonalDataForm}
                    setOnSubmitForm={setOnSubmitForm}
                    onSubmitForm={onSubmitForm}
                    editMode={editMode}
                    viewMode={IS_REGISTER_VIEW}
                    requestLoading={requestLoading}
                    isRegistrationDataEditable={isRegistrationDataEditable}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                    onboardEnabled={onboardEnabled}
                  />
                </Grid>

                <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_PERSONAL_FORM}
                    onClick={() => {
                      setExpandedTabs(['professionalData']);
                      setCheckedTabs([...checkedTabs, 'personalData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('personalData')}
            expanded={expandedTabs.includes('personalData')}
          />
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
          <Box mr={3}>
            <FormProgress
              step="2"
              checked={
                CHECK_PROFESSIONAL_FORM &&
                checkedTabs.find(item => item === 'professionalData')
              }
              disabled={
                (!CHECK_PROFESSIONAL_FORM &&
                  !expandedTabs.find(item => item === 'professionalData')) ||
                loading
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>
                Dados Profissionais
              </Typography>
            }
            details={
              <Grid
                container
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
                wrap="nowrap"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={styles.professionalData}
                >
                  <ProfessionalForm
                    disabled={
                      (!editMode && !IS_REGISTER_VIEW) || requestLoading
                    }
                    loading={loading}
                    professionalDataForm={professionalDataForm}
                    setProfessionalDataForm={setProfessionalDataForm}
                    setOnSubmitForm={setOnSubmitForm}
                    onSubmitForm={onSubmitForm}
                    editMode={editMode}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                    COMPANY_NAME={COMPANY_NAME}
                    locations={locations}
                    stockings={stockings}
                  />
                </Grid>

                <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_PROFESSIONAL_FORM}
                    onClick={() => {
                      if (COMPANY_SCHEDULED_TRANSFEER) {
                        setExpandedTabs(['bankData']);
                      } else {
                        setExpandedTabs(['addressData']);
                      }
                      setCheckedTabs([...checkedTabs, 'professionalData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('professionalData')}
            expanded={expandedTabs.includes('professionalData')}
          />
        </Grid>
      </Grid>

      {COMPANY_SCHEDULED_TRANSFEER && (
        <Grid container wrap="nowrap" alignItems="flex-start">
          <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
            <Box mr={3}>
              <FormProgress
                step="3"
                checked={
                  activeTransferSwitch
                    ? CHECK_BANK_FORM &&
                      checkedTabs.find(item => item === 'bankData')
                    : checkedTabs.find(item => item === 'bankData')
                }
                disabled={
                  (!CHECK_BANK_FORM &&
                    !checkedTabs.find(item => item === 'bankData') &&
                    !expandedTabs.find(item => item === 'bankData')) ||
                  loading
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tab
              summary={
                <Typography className={styles.tabTitle}>
                  Dados Bancários
                </Typography>
              }
              details={
                <Grid
                  container
                  direction="column"
                  alignItems={loading ? 'center' : 'flex-end'}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={styles.bankData}
                  >
                    <BankForm
                      disabled={
                        (!editMode && !IS_REGISTER_VIEW) || requestLoading
                      }
                      loading={loading}
                      bankDataForm={bankDataForm}
                      setBankDataForm={setBankDataForm}
                      setOnSubmitForm={setOnSubmitForm}
                      onSubmitForm={onSubmitForm}
                      editMode={editMode}
                      textFieldFocused={textFieldFocused}
                      setTextFieldFocused={setTextFieldFocused}
                      activeTransferSwitch={activeTransferSwitch}
                      setActiveTransferSwitch={setActiveTransferSwitch}
                      IS_REGISTER_VIEW={IS_REGISTER_VIEW}
                    />
                  </Grid>

                  <Grid
                    item
                    className={!IS_REGISTER_VIEW ? styles.hidden : null}
                  >
                    <Button
                      className={loading ? styles.hidden : styles.tabButton}
                      onClick={() => {
                        setExpandedTabs(['addressData']);
                        setCheckedTabs([...checkedTabs, 'bankData']);
                      }}
                      disabled={!!(activeTransferSwitch && !CHECK_BANK_FORM)}
                    >
                      Próximo
                    </Button>
                  </Grid>
                </Grid>
              }
              onChange={() => handleExpandedTab('bankData')}
              expanded={expandedTabs.includes('bankData')}
            />
          </Grid>
        </Grid>
      )}

      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
          <Box mr={3}>
            <FormProgress
              step={!COMPANY_SCHEDULED_TRANSFEER ? '3' : '4'}
              checked={
                CHECK_ADDRESS_FORM &&
                checkedTabs.find(item => item === 'addressData')
              }
              disabled={
                (!CHECK_ADDRESS_FORM &&
                  !expandedTabs.find(item => item === 'addressData')) ||
                loading ||
                !isRegistrationDataEditable
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tab
            summary={
              <Typography className={styles.tabTitle}>Endereço</Typography>
            }
            details={
              <Grid
                container
                wrap="nowrap"
                direction="column"
                alignItems={loading ? 'center' : 'flex-end'}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={styles.addressData}
                >
                  <AddressForm
                    disabled={
                      (!editMode && !IS_REGISTER_VIEW) ||
                      requestLoading ||
                      !isRegistrationDataEditable
                    }
                    loading={loading}
                    addressDataForm={addressDataForm}
                    setAddressDataForm={setAddressDataForm}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                    onSubmitForm={onSubmitForm}
                    setOnSubmitForm={setOnSubmitForm}
                    onboardEnabled={onboardEnabled}
                  />
                </Grid>

                <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
                  <Button
                    className={loading ? styles.hidden : styles.tabButton}
                    disabled={!CHECK_ADDRESS_FORM}
                    onClick={() => {
                      setExpandedTabs(['contactData']);
                      setCheckedTabs([...checkedTabs, 'addressData']);
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            }
            onChange={() => handleExpandedTab('addressData')}
            expanded={expandedTabs.includes('addressData')}
          />
        </Grid>
      </Grid>

      {!IS_REGISTER_VIEW &&
        (contactDataForm?.email || contactDataForm?.phones) && (
          <Grid container wrap="nowrap" alignItems="flex-start">
            <Grid item className={!IS_REGISTER_VIEW ? styles.hidden : null}>
              <Box mr={3}>
                <FormProgress
                  step="5"
                  // TODO: Voltar caso mude a regra de negócio do onboarding relacionado aos numeros
                  // checked={CHECK_CONTACT_FORM}
                  // disabled={
                  //   (!CHECK_CONTACT_FORM &&
                  //     !expandedTabs.find(item => item === 'contactData')) ||
                  //   loading
                  // }
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Tab
                summary={
                  <Typography className={styles.tabTitle}>Contato</Typography>
                }
                details={
                  <ContactForm
                    disabled={
                      (!editMode && !IS_REGISTER_VIEW) ||
                      requestLoading ||
                      !isRegistrationDataEditable
                    }
                    loading={loading}
                    contactDataForm={contactDataForm}
                    setContactDataForm={setContactDataForm}
                    setOnSubmitForm={setOnSubmitForm}
                    onSubmitForm={onSubmitForm}
                    textFieldFocused={textFieldFocused}
                    setTextFieldFocused={setTextFieldFocused}
                    onboardEnabled={onboardEnabled}
                    editMode={editMode}
                  />
                }
                onChange={() => handleExpandedTab('contactData')}
                expanded={expandedTabs.includes('contactData')}
              />
            </Grid>
          </Grid>
        )}

      {!IS_REGISTER_VIEW && (
        <Tab
          summary={
            <Typography className={styles.tabTitle}>
              Histórico de Pagamentos
            </Typography>
          }
          details={<PaymentHistory />}
          onChange={() => handleExpandedTab('historyData')}
          expanded={expandedTabs.includes('historyData')}
        />
      )}
    </Box>
  );
}
