import { makeStyles, createStyles } from '@material-ui/core';
import { yellow, green } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      marginBottom: 0,
    },
    subtitle: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#2E2E2E',
      marginBottom: '1.5rem',
    },
    button: {
      borderRadius: '50px',
      padding: '12px 24px',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    tab: {
      textTransform: 'none',
    },
    tabpanel: {
      padding: '24px 0',
    },
    userAvatar: {
      color: theme.palette.primary.main,
      backgroundColor: '#52197F1A',
      marginRight: theme.spacing(2),
    },
    userName: {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    userEmail: {
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    userProfile: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    userStatus: {
      '& .MuiChip-icon': {
        color: 'inherit',
      },
    },
    userHrProfile: {
      color: yellow[900],
    },
    userFinancialProfile: {
      color: green[900],
    },
    cardHeader: {
      justifyContent: 'flex-end',
      gap: theme.spacing(1),

      '& .MuiCardHeader-content': {
        flex: 'initial',
      },

      '& .MuiCardHeader-action': {
        marginTop: 0,
        marginRight: 0,
      },
    },

    cardGrid: {
      display: 'grid',
      gap: theme.spacing(2),
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(3, 1fr)',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(9, 1fr)',
      },
    },

    paginationContainer: {
      marginTop: theme.spacing(2),
    },

    cardAvatar: {
      color: theme.palette.primary.main,
      backgroundColor: '#52197F1A',
      backgroundClip: 'content-box',
      border: `1px solid #52197F1A`,
      padding: '4px',
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    cardBody: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
      paddingTop: 0,
    },
    cardText: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
    },
    cardTitle: {
      fontSize: '1rem',
      fontWeight: 600,
      textAlign: 'center',
    },
    cardSubtitle: {
      fontSize: '0.75rem',
    },
    cardInfo: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    cardDataTitle: {
      color: theme.palette.text.secondary,
      fontWeight: 400,
    },
    form: {
      padding: theme.spacing(3),
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
      [theme.breakpoints.down('md')]: {
        marginBottom: '40px',
      },
    },
    accessTitle: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    hrAvatar: {
      color: green[900],
      backgroundColor: green[50],
      marginRight: theme.spacing(2),
    },
    financialAvatar: {
      color: yellow[900],
      backgroundColor: yellow[50],
      marginRight: theme.spacing(2),
    },
    avatarLabel: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    authModal: {
      padding: theme.spacing(2),
      height: 'min(700px, 90%)',
      width: '75%',
    },
    video: {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  }),
);
