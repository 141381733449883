import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    button: {
      borderRadius: '50px',
    },
    tab: {
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    tabpanel: {
      padding: '2rem 0',
    },
  }),
);
