import React from 'react';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  Typography,
  Hidden,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import styles from './styles.module.css';

import CustomDatePicker from '../../../../../components/DatePicker';

function Filters({
  searchText,
  initialDate,
  finalDate,
  onSearchChangeFilters,
  onChangeInitialDate,
  onChangeFinalDate,
  validateInitialDateInfo,
  validateFinalDateInfo,
  changedFilter,
}) {
  return (
    <Grid container spacing={1} className={styles.containerInputs}>
      <Grid item xs={12} sm={6} md={6} lg={6} wrap="nowrap">
        <TextField
          className={styles.searchBackground}
          onChange={onSearchChangeFilters}
          value={searchText || ''}
          placeholder="Digite o nome ou CPF"
          variant="outlined"
          fullWidth
          autoFocus={changedFilter && searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <FormControl
          fullWidth
          variant="outlined"
          className={styles.formControl}
        >
          <CustomDatePicker
            value={initialDate}
            onChange={onChangeInitialDate}
            label={initialDate ? 'Data inicial' : null}
            placeholder="Data inicial"
            error={validateInitialDateInfo}
            disableCalendarIcon={validateInitialDateInfo}
          />
          <Hidden smDown>
            {validateInitialDateInfo && (
              <Typography className="dateError">*Data inválida</Typography>
            )}
          </Hidden>
          <Hidden smDown>
            {initialDate == null && (
              <Typography className="dateError">*Data obrigatória</Typography>
            )}
          </Hidden>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={3} md={3} lg={3}>
        <FormControl
          fullWidth
          variant="outlined"
          className={styles.formControl}
        >
          <CustomDatePicker
            value={finalDate}
            onChange={onChangeFinalDate}
            label={finalDate ? 'Data final' : null}
            placeholder="Data final"
            error={validateFinalDateInfo}
            disableCalendarIcon={validateFinalDateInfo}
          />
          <Hidden smDown>
            {validateFinalDateInfo && (
              <Typography className="dateError">*Data inválida</Typography>
            )}
          </Hidden>
          <Hidden smDown>
            {finalDate == null && (
              <Typography className="dateError">*Data obrigatória</Typography>
            )}
          </Hidden>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default Filters;
