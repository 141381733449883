import React, { useState, useEffect } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
  Header
} from '../OnlinePointEmployees/UI';

import {
  Filters,
  EmployeesListModal
} from './UI';

import { api } from '../../services/api';
import { TablePaginationActions } from '../OnlinePointEmployees/UI/Table/UI';

import verifyNumberInString from '../../utils/verifyNumberInString';
import LogoLoading from '../../components/LogoLoading';
import ResultNotFound from '../../components/ResultNotFound';
import ModalDeleteEmployee from '../../components/PrimaryModal';
import TableEmployeesList from './UI/Table';

function BeevaleBenefitsEmployees() {
  const companyCode = sessionStorage.getItem('currentCompanyCode');

  const [loading, setLoading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [employeesBeeVale, setEmployeesBeeVale] = useState([]);
  const [openEmployeesListModal, setOpenEmployeesListModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const [
    searchCostAndCenterBeeVale,
    setSearchCostAndCenterBeeVale,
  ] = useState('Todos');
  const [costAndCenterBeeVale, setCostAndCenterBeeVale] = useState([]);

  const [searchSituation, setSearchSituation] = useState('Todos');
  const [cpfOrName, setCpfOrName] = useState('');
  const [changedFilter, setChangedFilter] = useState(false);
  const [openModalDeleteEmployee, setOpenModalDeleteEmployee] = useState(false);
  const [openSnackErrorOrSucess, setOpenSnackErrorOrSucess] = useState(false);
  const [isErrorSnack, setIsErrorSnack] = useState(false);
  const [isItemDeleted, setIsItemDeleted] = useState(false);

  const [employeesCompany, setEmployeesCompany] = useState([]);

  useEffect(() => {
    getEmployeeBeeVale();
    // getEmployeesCompany();
  }, [pageSize, currentPage, searchSituation])

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        console.log("🚀 ~ cpfOrName ~ cpfOrName:", cpfOrName)
        
        getEmployeeBeeVale();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [cpfOrName]);

  function closeDialogAndRefreshList() {
    setOpenEmployeesListModal(false)
    getEmployeeBeeVale();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function getEmployeeBeeVale() {
    setLoading(true);
    const isNumber = verifyNumberInString(cpfOrName);
    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize,
        cpf: isNumber ? cpfOrName : '',
        name: !isNumber ? cpfOrName : '',
        location:
          (searchCostAndCenterBeeVale !== 'Todos' &&
            searchCostAndCenterBeeVale) ||
          null,
        status: (searchSituation !== 'Todos' && searchSituation) || null
      },
    };

    api
      .get(`beeValeBenefits/search/${companyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setCurrentPageSize(data.currentPageSize);
        setEmployeesBeeVale(data.employees);
      })
      .finally(() => {
        setLoading(false);
        setLoadingLogo(false);
      });
  }

  function handleChangeCostAndCenter(costCenterSearch) {
    setSearchCostAndCenterBeeVale(costCenterSearch);
    setChangedFilter(true);
  }

  function handleChangeSituation(event) {
    setSearchSituation(event.target.value);
    setChangedFilter(true);
  }

  function onSearchChange(event) {
    setCpfOrName(event?.target?.value);
    setChangedFilter(true);
  }

  function handleClearFilters() {
    setLoading(true);
    setSearchCostAndCenterBeeVale('Todos');
    setSearchSituation('Todos');
    setCpfOrName('');
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleOpenModalDeleteEmployee(employeeCpf) {
    sessionStorage.setItem('employeeCpfDelete', employeeCpf);
    
    setOpenModalDeleteEmployee(true);
  }

  function handleCloseModalDeleteEmployee() {
    setOpenModalDeleteEmployee(false);
    setTimeout(() => {
      sessionStorage.removeItem('employeeCpfDelete');
    }, 200);
  }

  function handleDeleteEmployee() {
    const employeeDelete = sessionStorage.getItem('employeeCpfDelete');
    setLoadingButtonDelete(true);
    api
      .post(`/beeValeBenefits/employee/disable/${companyCode}/${employeeDelete}`)
      .then(() => {
        setIsErrorSnack(false);
        setIsItemDeleted(true);
        setTimeout(() => {
          getEmployeeBeeVale();
        }, 2000);
      })
      .catch(() => {
        setIsErrorSnack(true);
      })
      .finally(() => {
        setLoadingButtonDelete(false);
        handleCloseModalDeleteEmployee();
        setOpenSnackErrorOrSucess(true);
      });
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function handleChangePage(event, newPage) {
    setCurrentPage(newPage);
    setChangedFilter(true);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <>
      {loadingLogo ? (
        <LogoLoading />
      ) : (
        <>
          <Header onClick={() => setOpenEmployeesListModal(true)} />
          <EmployeesListModal
            ignoredEmployeesBeeVale={employeesBeeVale}
            open={openEmployeesListModal}
            selected={selected}
            setSelected={setSelected}
            setOpenEmployeesListModal={closeDialogAndRefreshList}
            isItemDeleted={isItemDeleted}
          />
          <Filters
            cpfOrName={cpfOrName}
            setCpfOrName={setCpfOrName}
            onSearchChange={onSearchChange}
          />
          {employeesBeeVale.length === 0 ? (
            <ResultNotFound
              text="Nenhum Resultado Encontrado"
              alternativeText="Verifique os dados pesquisados e tente novamente."
              onClick={handleClearFilters}
              buttonText="Limpar Filtros"
            />
          ) : (
            <TableEmployeesList
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              loading={loading}
              changedFilter={changedFilter}
              currentPage={currentPage}
              pageSize={pageSize}
              totalSize={totalSize}
              currentPageSize={currentPageSize}
              rows={employeesBeeVale}
              getComparator={getComparator(order, orderBy)}
              stableSort={stableSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              TablePaginationActions={TablePaginationActions}
              handleOpenModalDeleteEmployee={handleOpenModalDeleteEmployee}
            />
          )}
        </>
      )}
      <ModalDeleteEmployee
        open={openModalDeleteEmployee}
        onCloseDialog={handleCloseModalDeleteEmployee}
        title="Desabilitar este colaborador?"
        text="Esta ação não poderá ser desfeita."
        errorMessage={`CPF: ${sessionStorage.getItem('employeeCpfDelete')}`}
        cancelButtonText="Sair"
        onCancelButtonClicked={handleCloseModalDeleteEmployee}
        confirmButtonText="Confirmar"
        onConfirmClicked={handleDeleteEmployee}
        loading={loadingButtonDelete}
      />

      <Snackbar
        open={openSnackErrorOrSucess}
        autoHideDuration={5000}
        onClose={() => setOpenSnackErrorOrSucess(false)}
      >
        <Alert
          severity={isErrorSnack ? 'error' : 'success'}
          onClose={() => setOpenSnackErrorOrSucess(false)}
        >
          {isErrorSnack
            ? 'Não foi possivel Desabilitar colaborador, tente novamente.'
            : 'Colaborador cancelado com sucesso!'}
        </Alert>
      </Snackbar>
    </>
  )
}

export default BeevaleBenefitsEmployees;