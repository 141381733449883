import React from 'react';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  DashboardOutlined,
  ListOutlined,
  SearchOutlined,
} from '@material-ui/icons';

import BlockModal from './components/BlockModal';
import Form from '../../../../../../components/Form';
import { Table } from '../../../../../../components/Table';
import UserGrid from '../../UserGrid';

import useTableConfig from './hooks/useTableConfig';
import useUsersTab from './hooks/useUsersTab';

export default function UsersTab() {
  const {
    control,
    onSearch,
    handleViewChange,
    onChangePage,
    currentView,
    isFetchingUsers,
    users,
    onOpenUserModal,
  } = useUsersTab();

  const config = useTableConfig({
    onOpenUserModal,
  });

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={9} md={10}>
        <Controller
          control={control}
          name="nameOrCpf"
          render={({ field }) => (
            <Form.TextField
              size="medium"
              placeholder="Pesquise por nome ou CPF"
              InputProps={{
                startAdornment: (
                  <SearchOutlined style={{ marginRight: '8px' }} />
                ),
              }}
              {...field}
              onChange={e => {
                field.onChange(e);
                onSearch();
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} md={2} container justifyContent="flex-end">
        <ToggleButtonGroup
          exclusive
          aria-label="Visualização dos resultados"
          onChange={handleViewChange}
          value={currentView}
          size="large"
        >
          <ToggleButton value="table" aria-label="Visualização em lista">
            <ListOutlined />
          </ToggleButton>
          <ToggleButton value="grid" aria-label="Visualização em grade">
            <DashboardOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {currentView === 'table' ? (
          <Table
            hasPagination
            isHandleLocalData
            identifier="id"
            data={users}
            length={users.length}
            isLoading={isFetchingUsers}
            tableConfig={config}
            requests={onChangePage}
          />
        ) : (
          <UserGrid
            data={users}
            isLoading={isFetchingUsers}
            onOpenUserModal={onOpenUserModal}
          />
        )}
      </Grid>
      <BlockModal />
    </Grid>
  );
}
