import * as yup from 'yup';

export default yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3),
  cpf: yup
    .string()
    .required()
    .length(14, 'Insira um CPF válido.'),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup.string().required(),
  role: yup.string().required(),
});
