import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCountdown } from '../../../hooks/useValidationForUnformattedDate/useCountdown';

import { ActionsCompanies as Actions } from '../../../redux/store/Companies';

import { api } from '../../../services/api';
import { getUser, loginConfirmation } from '../../../services/user';

const COUNTDOWN_TIME = 60;

export default function useLogin(hideAlert) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [blockedUser, setBlockedUser] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [errorDetail, setErrorDetail] = useState('');
  const [requestValidation, setRequestValidation] = useState('');
  const [codeRequest, setCodeRequest] = useState('');
  const [showCodeRequest, setShowCodeRequest] = useState(false);
  const [emailNotReceived, setEmailNotReceived] = useState(false);
  const [countdownHasStarted, setCountdownHasStarted] = useState(false);
  const [isSnackbarActive, setIsSnackbarActive] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [values, setValues] = useState({
    cpfEmail: '',
    password: '',
    helperText: false,
    errorCpfCnpj: false,
    errorPassword: false,
    errorMessageCpfCnpj: '',
    errorMessagePassword: '',
    loading: false,
    disabled: false,
    showPassword: false,
    disabledCpfEmail: false,
    disabledPassword: false,
  });

  const formatNumber = rawNumber =>
    '****'?.concat(rawNumber?.slice(rawNumber?.length - 4));

  const onChangeCodeRequest = event => {
    setCodeRequest(event.target.value.trim());
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onResetPasswordButtonClicked = () => {
    history.push('/reset-password/email-and-cpf');
  };

  const { currentTime } = useCountdown(COUNTDOWN_TIME, countdownHasStarted);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorPassword: false });
  };

  const handleError = () => {
    const passwordHasCpfEmail = values.cpfEmail.trim().length <= 0;
    const passwordHasError = values.password.trim().length <= 0;

    setValues({
      ...values,
      errorCpfCnpj: passwordHasCpfEmail,
      errorMessageCpfCnpj: 'Por favor, informe seu e-mail ou CPF',
      errorPassword: passwordHasError,
      errorMessagePassword: 'Por favor, informe sua senha',
      helperText: true,
      loading: false,
      disabled: false,
    });
  };

  const handleErrorMessages = (status, detail, password) => {
    let errorMessage = '';

    if (password.trim().length === 0) return 'Por favor, informe sua senha';

    switch (status) {
      default:
        errorMessage =
          detail ??
          'Tivemos um problema ao realizar o login. Verifique sua conexão e tente novamente.';
        break;
      case 'USER_NOT_AUTHORIZED':
        errorMessage = `Não foi possível realizar o login. ${detail}`;
        break;
      case 'TOKEN_INVALID':
        errorMessage = 'Código inválido! Verifique se o código está correto.';
        break;
      case 'INVALID_PHONE_NUMBER':
        errorMessage =
          'O número de telefone é inválido. Por favor, tente novamente ou entre em contato com nosso atendimento.';
        break;
    }
    return errorMessage;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setValues({ ...values, loading: true, disabled: true });
    const { cpfEmail, password } = values;

    if (!cpfEmail || !password) {
      handleError();
      return;
    }

    const user = await loginConfirmation({
      cpfEmail,
      requestValidation,
      codeRequest,
      setErrorStatus,
      setErrorDetail,
    });

    if (!user) {
      setValues({
        ...values,
        errorMessageCpfCnpj: '',
        errorCpfCnpj: true,
        errorPassword: true,
        helperText: true,
        loading: false,
        disabled: false,
      });

      return;
    }

    const availableCompanies = user.companies.filter(
      company => !!company.hasIpAccess,
    );

    const isAccessBlocked = availableCompanies === 0;

    if (isAccessBlocked) {
      setBlockedUser(true);
      setValues(prevState => ({
        ...prevState,
        loading: false,
        disabled: false,
      }));
      return;
    }
    
    dispatch({ type: 'LOGIN_USER', user });
    dispatch(Actions.setCurrentCompany(availableCompanies[0]));

    sessionStorage.setItem('@ContaSoma-Token', user.token);
    sessionStorage.setItem('cpf', user.cpf);
    sessionStorage.setItem('currentCompanyCode', availableCompanies[0].code);
    sessionStorage.setItem(
      'userJobRole',
      JSON.stringify({
        name: user.name,
        jobRole: user.jobRole,
        email: values.cpfEmail,
        viewedComponents: user.viewedComponents,
      }),
    );
    sessionStorage.setItem(
      'accessProfile',
      JSON.stringify(availableCompanies[0].roles),
    );

    const companiesWithTradingName = user.companies.map(company => {
      return {
        ...company,
        name: company?.tradingName || company?.name,
      };
    });

    dispatch(Actions.setCompanies(companiesWithTradingName));
    sessionStorage.setItem(
      'companies',
      JSON.stringify(companiesWithTradingName),
    );
    hideAlert();

    const { code } = availableCompanies[0];
    getConfigCompany(code);

    localStorage.setItem('showScdPopup', user?.showScdPopup);

    history.push(user.firstAccess ? '/welcome' : '/');
  };

  const handleSubmitRequestValidation = async (e, isTrySendForSms) => {
    e.preventDefault();

    setValues({ ...values, loading: true, disabled: true });
    const { cpfEmail, password } = values;
    if (cpfEmail === '' || password === '') {
      handleError();
    } else {
      const data = await getUser({
        cpfEmail,
        password,
        setErrorStatus,
        setErrorDetail,
        emailNotReceived: isTrySendForSms,
      });
      if (data) {
        setRequestValidation(data?.requestValidation);
        setShowCodeRequest(true);
        setValues({
          ...values,
          loading: false,
          disabled: false,
          disabledCpfEmail: true,
          disabledPassword: true,
        });

        if (data?.phoneNumber) {
          setIsSnackbarActive(true);
          setSnackbarMessage(
            `O SMS foi enviado para o número: ${formatNumber(
              data?.phoneNumber,
            )}`,
          );

          setTimeout(() => {
            setIsSnackbarActive(false);
          }, 5000);
        }
      } else {
        setValues({
          ...values,
          errorMessageCpfCnpj: '',
          errorCpfCnpj: true,
          errorPassword: true,
          helperText: true,
          loading: false,
          disabled: false,
        });
      }
    }

    setEmailNotReceived(isTrySendForSms);
    setCountdownHasStarted(true);

    setTimeout(() => {
      setCountdownHasStarted(false);
    }, COUNTDOWN_TIME * 1000);
  };

  const getConfigCompany = codeCompany => {
    api.get(`companies/${codeCompany}/search`).then(({ data }) => {
      const zeroProduct = data?.zeroProduct;
      const pontoGoProduct = data?.pontoGoProduct;
      const pontoGoActivationDate = data?.pontoGoCompany
        ? data?.pontoGoCompany?.pontoGoActivationDate
        : data?.pontoGoCompany;

      const emailNfe = data?.generalData
        ? data.generalData?.emailNfe
        : data?.generalData;

      const onboardEnabled = data?.onboardEnabled;
      const canGenerateCard = data?.canGenerateCard;

      sessionStorage.setItem('zeroProduct', zeroProduct || null);
      sessionStorage.setItem('pontoGoProduct', pontoGoProduct);
      sessionStorage.setItem('pontoGoActivationDate', pontoGoActivationDate);
      sessionStorage.setItem('emailNfe', emailNfe);
      sessionStorage.setItem('onboardEnabled', onboardEnabled);
      sessionStorage.setItem(
        'canGenerateCard',
        canGenerateCard === undefined ? false : canGenerateCard,
      );

      dispatch({
        type: 'SITUATION_POINT_PLAN',

        data: {
          situationPointPlan: pontoGoProduct,
          pontoGoActivationDate,
        },
      });
    });
  };

  useEffect(() => {
    if (values.cpfEmail.length >= 0) {
      setValues({
        ...values,
        cpfEmail: '',
        errorCpfCnpj: false,
        errorPassword: false,
        helperText: false,
        loading: false,
        disabled: false,
      });
    }
    if (values.password.length >= 0) {
      setValues({
        ...values,
        password: '',
        errorCpfCnpj: false,
        errorPassword: false,
        helperText: false,
        loading: false,
        disabled: false,
      });
    }
  }, [values.cpfEmail]);

  return {
    currentTime,
    formatNumber,
    handleChange,
    handleError,
    handleSubmit,
    handleSubmitRequestValidation,
    onResetPasswordButtonClicked,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleErrorMessages,
    onChangeCodeRequest,
    values,
    state: {
      blockedUser,
      setBlockedUser,
      codeRequest,
      errorDetail,
      errorStatus,
      showCodeRequest,
      emailNotReceived,
      countdownHasStarted,
      isSnackbarActive,
      snackbarMessage,
    },
  };
}
