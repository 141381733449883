import { useRef } from 'react';

export function useNumericField({
  returnParsed = false,
  prefix = '',
  hasThousandSeparator = false,
  isFloat = false,
  minValue = 0,
  maxValue = Number.MAX_SAFE_INTEGER,
  precision = 21,
  value = '',
  onChange = () => {},
}) {
  const numericStringValue = useRef(value);

  function handleFinalValue(targetValue) {
    if (!targetValue) {
      onChange(returnParsed ? 0 : '');
      numericStringValue.current = '';

      return;
    }

    if (targetValue === `${prefix} 0,0` || targetValue === '0,0') {
      onChange(returnParsed ? 0 : '');
      numericStringValue.current = '';

      return;
    }

    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      useGrouping: hasThousandSeparator,
      minimumFractionDigits: 2,
    }).format(String(targetValue).replace(/\D/g, '') / 100);

    const finalValue = isFloat
      ? formattedValue
      : String(targetValue).replace(/\D/g, '');

    const formValue = finalValue?.replace(',', '.');

    if (finalValue === '') {
      onChange(returnParsed ? 0 : '');
      numericStringValue.current = '';

      return;
    }

    if (Number(formValue) >= minValue && Number(formValue) <= maxValue) {
      if (isFloat || finalValue.length <= precision) {
        if (returnParsed) {
          onChange(Number(formValue));

          numericStringValue.current = prefix
            ? `${prefix} ${Number(finalValue)}`
            : Number(finalValue);
        } else {
          onChange(formValue);

          numericStringValue.current = prefix
            ? `${prefix} ${finalValue}`
            : finalValue;
        }
      }
    }
  }

  return {
    numericStringValue:
      value === '' || value === null ? '' : numericStringValue.current,
    setNumericStringValue: handleFinalValue,
  };
}
