import React, { useState, useEffect } from 'react';

import styles from './styles.module.css';

import {
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import EmployeesTable from './EmployeesTable';
import LimitModal from '../../../../components/PrimaryModal';


import { api } from '../../../../services/api';

function EmployeesListModal({
  open,
  onClose,
  onCancelClicked,
  onConfirmClicked,
  cpfOrName,
  setCpfOrName,
  handleChangeCpfOrName,
  currentCompanyCode,
  selected,
  setSelected,
  setIsSelectedAll,
  isSelectedAll,
  ignoredEmployees,
  setIgnoredEmployees,
  setEmployees: setEmployeesTotal,
  employees: employeesTotal,
  isLimitModal,
  setIsLimitModal,
}) {
  const [textFieldFocused, setTextFieldFocused] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const CURRENT_SELECTEDS = employeesTotal?.lenght || 0;

  useEffect(() => {
    getEmployees();
  }, [page, rowsPerPage, cpfOrName]);

  function getEmployees() {
    const requestOptions = {
      params: {
        active: true,
        page,
        size: rowsPerPage,
        cpfOrName: cpfOrName,
      },
    };

    setIsLoading(true);

    api.get(`/employee/search/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setEmployees(data.content);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function handleClosedLimitModal() {
    setIsLimitModal(false);
  };

  function getEmployeesIgnored() {
    if (totalSize - CURRENT_SELECTEDS >= 1000) {
      setIsLimitModal(true);
      return;
    }
    const encodedKeysIgnoredEmployees = ignoredEmployees.map((item) => {
      return item.encodedKey
    })
    const formatedIgnoredEmployees = encodedKeysIgnoredEmployees.join();

    const requestOptions = {
      params: {
        page: 0,
        size: totalSize,
        ignoredEmployees: formatedIgnoredEmployees,
      },
    };

    setLoadingButton(true);
    api.get(`/employee/search/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        const newSelecteds = data?.employees.map((row) => (
          {
            name: row.name,
            cpf: row.cpf,
            amount: row.amount,
            registration: row.registration,
            employeeStatus: row.employeeStatus,
          }
        ));
        setEmployeesTotal(newSelecteds.filter(item => item));
        onConfirmClicked(newSelecteds.filter(item => item));
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <Grid
        container
        direction="column"
        className={styles.employeesListModalContainer}
      >
        <Grid item md={12} lg={12}>
          <TextField
            value={cpfOrName}
            onChange={handleChangeCpfOrName}
            className={styles.employeesListModalSearchInput}
            variant="outlined"
            placeholder="Pesquise por nome ou CPF"
            onFocus={() => setTimeout(() => setTextFieldFocused('cpfOrName'), 200)}
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            InputProps={{
              style: {
                height: 40,
                borderRadius: 6
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.iconColor} />
                </InputAdornment>
              ),
              endAdornment: (cpfOrName?.length > 0 && textFieldFocused === 'cpfOrName') && (
                <IconButton
                  className={styles.endAdornment}
                  onClick={() => setCpfOrName('')}
                >
                  <CloseRoundedIcon className={styles.endAdornmentIcon} />
                </IconButton>
              )
            }}
          />
        </Grid>

        <EmployeesTable
          employees={employees}
          isLoading={isLoading}
          totalSize={totalSize}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          selected={selected}
          setSelected={setSelected}
          setIsSelectedAll={setIsSelectedAll}
          isSelectedAll={isSelectedAll}
          ignoredEmployees={ignoredEmployees}
          setIgnoredEmployees={setIgnoredEmployees}

        />

        <Grid
          container
          justify={(selected.length > 0 && !isLoading) ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          {(selected.length > 0 && !isLoading) && (
            <Grid item className={styles.employeesListSelecteds}>
              <div className={
                isSelectedAll || selected.length >= 1000 ?
                  styles.employeesListSelectedsAllCount :
                  styles.employeesListSelectedsCount}
              >
                {isSelectedAll ? (totalSize - ignoredEmployees.length) : selected.length}
              </div>

              <Typography className={styles.employeesListSelectedsText}>
                {isSelectedAll ? (totalSize - ignoredEmployees.length) > 1 ? 'Selecionados' : 'Selecionado' :
                  selected.length > 1 ? 'Selecionados' : 'Selecionado'}
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  onClick={onCancelClicked}
                  className={styles.employeesListModalCloseButton}
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item>
                <Button
                  onClick={() => isSelectedAll ? getEmployeesIgnored() : onConfirmClicked(selected)}
                  className={styles.employeesListModalConfirmButton}
                >
                  {
                    loadingButton ?
                      <CircularProgress className={styles.circularLoadingStyle} /> :
                      <div>Incluir</div>
                  }
                </Button>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LimitModal
        open={isLimitModal}
        onConfirmClicked={handleClosedLimitModal}
        confirmButtonText="Entendi"
        title="Atencão!"
        text={`Só é possível adicionar mais ${1000 - CURRENT_SELECTEDS} colaboradores ao pagamento, clique em
        entendi para refazer a seleção.`}
      />
    </Dialog>
  );
}

export default EmployeesListModal;
