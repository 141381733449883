import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export function useMobileMode({
  fields,
  defaultValues = {},
  validation = null,
}) {
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: validation ? yupResolver(validation) : undefined,
  });

  const hasMandatoryFields = validation?.fields
    ? Object.values(validation?.fields)?.some(
        config => !config?.specs?.optative,
      )
    : false;
  const isClearFieldsDisabled = !isDirty;

  function handleFieldsList() {
    if (!Array.isArray(fields)) {
      return [fields];
    }

    return fields;
  }

  return {
    filterComponentObject: {
      fieldsList: handleFieldsList(),
      isClearFieldsDisabled,
      hasMandatoryFields,
      isMobileFilterOpen,
      handleOpenFilterDrawer: () =>
        setIsMobileFilterOpen(prevState => !prevState),
      handleClearFields: () => reset(defaultValues),
    },
    form: {
      control,
      handleSubmit,
    },
  };
}
