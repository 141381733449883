import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import styles from './styles.module.css';

export default ({ open, onCloseDialog, title, text, openErrorDialog }) => (
  <Dialog
    open={open}
    onClose={onCloseDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className={styles.container}
  >
    <DialogTitle className={styles.header}>{title}</DialogTitle>
    <DialogContent className={styles.content}>
      <DialogContentText className={styles.subTitle} id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {openErrorDialog ? (
        <Button className={styles.button} onClick={onCloseDialog} color="primary" autoFocus>
          ENTENDI
        </Button>
      ) : (
        <Button onClick={onCloseDialog} color="primary" autoFocus>
          Ok
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
