const initialState = {
  registerOption: [],
  company: '',
  file: []
}

export default function batchRegister(state=initialState, action) {
  switch(action.type){
    case 'SET_REGISTER_OPTION':
      return {...state, registerOption: action.registerOption};
    case 'SET_COMPANY':
      return {...state, company: action.company}
    default:
      return state;
  }
}
