import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from './styles';

const DeletePixKeyModal = NiceModal.create(({ onDelete, onClose }) => {
  const modal = useModal();
  const styles = useStyles();

  const { hasError, message, isSending } = useSelector(state => {
    const { hasError, message, isSending } = state.pixStatus;
    return { hasError, message, isSending };
  });

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={onClose}
      PaperProps={{ className: styles.root }}
    >
      <DialogTitle className={styles.title}>
        Deseja excluir a chave?
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.content}>
          Esta ação não pode ser desfeita.
        </DialogContentText>

        {hasError ? (
          <Alert severity="error" className={styles.alert}>
            {message}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Buttons.SecondaryButton
          variant="text"
          color="primary"
          onClick={onClose}
          title="CANCELAR"
        />
        <Buttons.PrimaryButton
          color="secondary"
          onClick={onDelete}
          title="EXCLUIR"
          loading={isSending}
        />
      </DialogActions>
    </Dialog>
  );
});

export default DeletePixKeyModal;
