import React from 'react';

import PersonalRegisterCardIcon from '@material-ui/icons/PersonAddRounded';

import styles from './styles.module.css';

export const actions = [
  {
    icon: <PersonalRegisterCardIcon className={styles.iconColor} />,
    name: 'Cadastro individual',
    onClick: '/insurance-management/insurance-single',
    value: ''
  },
]
