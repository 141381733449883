import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
  Typography,
  Paper,
  Radio,
  Button,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import ProgressToolBar from '../../../components/ProgressToolBar';
import ComingSoon from '../../../components/CommingSoon';

import { api } from '../../../services/api';
import { isMobile } from '../../../utils/breakpoints';
import styles from './styles';

const PayrollMenu = () => {
  const [option, setOption] = useState('');
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabledStatus, setDisabledStatus] = useState(true);

  const authToken = sessionStorage.getItem('@ContaSoma-Token');

  const onBackClicked = () => {
    history.push('/payroll');
  };

  const setOptionAndDisabled = index => {
    setOption(index);
    setDisabledStatus(false);
  };

  const onNextClicked = () => {
    const registerOption = options[option];
    localStorage.setItem('payrollMenuData', JSON.stringify(registerOption));
    history.push(`/payroll/menu/info-payroll/register-batch`);
  };

  useEffect(() => {
    sessionStorage.removeItem('loadingPayment');

    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
    };

    api.get('/employeesPayments/types', requestOptions).then(({ data }) => {
      setOptions(data);
      setLoading(false);
    });
  }, []);

  const renderOptions = () => {
    return options.map((element, index) => {
      const valueElement = element.description;
      const descriptionBold = valueElement.split(':');
      const [newDescription, newDescriptionBold] = descriptionBold;
      return (
        <div style={styles.card}>
          <Paper
            style={option === index ? styles.borderCard : styles.notBorderCard}
          >
            <Button
              style={styles.buttonStyle}
              onClick={() => setOptionAndDisabled(index)}
            >
              <Radio
                checked={option === index}
                style={styles.radioButtonStyle}
              />
              <ListItem>
                {newDescriptionBold ? (
                  <ListItemText
                    primary={
                      <Typography style={styles.primaryTextSTyle}>
                        {element.name}
                      </Typography>
                    }
                    secondary={
                      <Typography style={styles.secondaryTextStyle}>
                        {newDescription}:{' '}
                        <span style={styles.spanBold}>
                          {newDescriptionBold}
                        </span>
                      </Typography>
                    }
                  />
                ) : (
                  <ListItemText
                    primary={
                      <Typography style={styles.primaryTextSTyle}>
                        {element.name}
                      </Typography>
                    }
                    secondary={(
                      <Typography style={styles.secondaryTextStyle}>
                        {element.description}
                      </Typography>
                    )}
                  />
                )}
              </ListItem>
            </Button>
          </Paper>
        </div>
      );
    });
  };

  return isMobile ? (
    <ComingSoon />
  ) : (
    <div style={{ paddingBottom: 60 }}>
      <Typography style={styles.titleStyle}>
        Novo pagamento via arquivo
      </Typography>
      <Typography style={styles.subtitle}>Escolha o tipo de arquivo</Typography>
      <div>
        {loading ? (
          <div style={styles.loadingButtonArea}>
            <Paper style={styles.paper}>
              <Button>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography style={styles.primaryTextSTyle}>
                        <Skeleton
                          width={400}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                    secondary={
                      <Typography style={styles.secondaryTextStyle}>
                        <Skeleton
                          width={900}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                  />
                </ListItem>
              </Button>
            </Paper>

            <Paper style={styles.paper}>
              <Button>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography style={styles.primaryTextSTyle}>
                        <Skeleton
                          width={400}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                    secondary={
                      <Typography style={styles.secondaryTextStyle}>
                        <Skeleton
                          width={900}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                  />
                </ListItem>
              </Button>
            </Paper>
          </div>
        ) : (
          renderOptions()
        )}
      </div>
      <ProgressToolBar
        disabled={disabledStatus}
        nextButtonText="Continuar"
        onBackClicked={onBackClicked}
        onNextClicked={onNextClicked}
      />
    </div>
  );
};

export default PayrollMenu;
