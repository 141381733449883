import React from 'react';
import styles from './styles.module.css';
import {
    Button,
    Dialog,
    Typography
} from '@material-ui/core';

export default function index({
    open,
    onCloseDialog,
    requestCards,
    title,
    description,
    alert,
    textButtonConfirm
}) {
    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
        >
            <div className={styles.container}>
                <div className={styles.content}>
                    <Typography className={styles.modalTitle}>{title}</Typography>
                    <Typography className={styles.modalText}>{description}</Typography>
                    <Typography className={styles.modalText}>{alert}</Typography>
                </div>
                <div className={styles.buttons}>
                    <Button
                        onClick={onCloseDialog}
                        className={styles.buttonCancel}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={styles.buttonConfirm}
                        onClick={requestCards}
                    >
                        {textButtonConfirm}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
