import React from 'react';
import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
} from '@material-ui/core';

import EnhancedTableHead from './EnhancedTableHead';
import FakeRowTable from '../../../CashIn/ConsultCashIn/UI/FakeRowTable';

import { styles } from './styles';

export default ({
  theme,
  classes,
  classesHead,
  pageSize,
  anticipationList,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  loadingFilter,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <EnhancedTableHead
            classes={classesHead}
            rowCount={anticipationList.length}
          />
          <TableBody>
            {loadingFilter ? (
              <FakeRowTable />
            ) : (
              anticipationList
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize,
                )
                .map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        padding="none"
                        scope="row"
                        align="left"
                        className={classes.statusCell}
                      >
                        {row.contracting_date}
                      </TableCell>
                      <TableCell align="left" className={classes.defaultCell}>
                        {row.advance_date}
                      </TableCell>
                      <TableCell align="left" className={classes.defaultCell}>
                        {row.cpf}
                      </TableCell>
                      <TableCell align="left" className={classes.dateCell}>
                        {row.name}
                      </TableCell>
                      <TableCell align="left" className={classes.dateCell}>
                        {row.amount_to_pay}
                      </TableCell>
                      <TableCell align="left" className={classes.defaultCell}>
                        {row.amount}
                      </TableCell>
                      <TableCell align="left" className={classes.defaultCell}>
                        {row.status}
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                count={anticipationList.length}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};
