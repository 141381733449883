const styles = {
  errorIconStyle: {
    marginRight: 10,
    color: '#B00020'
  },
  sucessIconStyle: {
    marginRight: 10,
    color: '#168243'
  },
  successStyle: {
    padding: '2px 12px',
    color: '#168243',
    marginTop: '-20px'
  },
  errorStyle: {
    padding: '2px 12px',
    color: '#B00020'
  },
  listItemStyle: {
    flexDirection: 'column'
  },
  table: {
    width: '100%',
    marginBottom: 10
  }
};

export default styles;
