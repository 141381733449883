import React from 'react';
import { Box } from '@material-ui/core';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
