import cryptoJS from 'crypto-js';

const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;

export const encrypt = input => {
  const key = cryptoJS.enc.Latin1.parse(CRYPTO_KEY);
  const iv = cryptoJS.enc.Latin1.parse(CRYPTO_KEY);
  const encrypted = cryptoJS.AES.encrypt(input, key, {
    iv,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};
