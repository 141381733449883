import { reduxApi } from '../../../../services/reduxApi';

const employeesTags = {
  tag: 'EMPLOYEES',
  listAbstractTag: { type: 'EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [employeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getBankList: builder.query({
        query: () => ({
          url: '/accountCashIn/bank/findAll',
        }),
      }),

      getEmployeesListQuantity: builder.query({
        query: companyCode => ({
          url: `/employees/count/${companyCode}`,
        }),
      }),
    }),
  });

export const {
  useGetBankListQuery,
  useLazyGetEmployeesListQuantityQuery,
} = slice;
