import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import List from './List';
import Header from './Header';
import ProgressToolBar from '../../../../components/ProgressToolBar';

import { api } from '../../../../services/api';

export default function Menu() {
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [option, setOption] = useState('');

  const history = useHistory();

  useEffect(() => {
    getFileTypes();
  }, []);

  function getFileTypes() {
    api
      .get('/import/insurance/type')
      .then(({ data }) => {
        setTypes(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onClickOption(item, index) {
    setOption(index);
    setDisabledStatus(false);
    setLoading(false);

    sessionStorage.setItem('exampleLink', item?.exampleLink);

    sessionStorage.setItem('fileType', item?.fileType);
  }

  function handleNext() {
    history.push('/insurance-management/batch-registration/menu/validate');
  }

  function handleBack() {
    history.push('/insurance-management');
  }

  return (
    <>
      <Header />

      <List
        option={option}
        onClickOption={onClickOption}
        loading={loading}
        types={types}
      />

      <ProgressToolBar
        onBackClicked={handleBack}
        onNextClicked={handleNext}
        nextButtonText="Continuar"
        backButtonText="Voltar"
        disabled={disabledStatus}
      />
    </>
  );
}
