import React from 'react';

import {
  Button,
  Drawer,
  IconButton,
  Typography
} from '@material-ui/core';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

import styles from './styles.module.css';

function FilterDrawer({
  anchor,
  open,
  onClose,
  label = "Filtrar",
  cleanButtonText="Limpar filtros",
  content,
  applyButtonText="Aplicar filtros",
  disabledButton,
  applyFilter,
  cleanFilter
}) {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.header}>
            <Typography className={styles.headerLabel}>
              {label}
            </Typography>

            <IconButton onClick={cleanFilter} className={styles.cleanFilterButton}>
              <RemoveCircleOutlineRoundedIcon className={styles.iconHeader} />
              {cleanButtonText}
            </IconButton>
          </div>

          <div>
            {content}
          </div>
        </div>

        <Button
          className={styles.applyButton}
          disabled={disabledButton}
          onClick={applyFilter}
        >
          {applyButtonText}
        </Button>
      </div>
    </Drawer>
  );
}

export default FilterDrawer;
