/* eslint-disable no-param-reassign */
import { setIn } from 'final-form';

const ValidateFormSchema = schema => async values => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce(
      (formError, innerError) =>
        setIn(formError, innerError.path, innerError.message),
      {},
    );

    return errors;
  }
};

export { ValidateFormSchema };
