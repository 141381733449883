import React, { useState } from 'react';
import moment from 'moment';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import CustomDatePicker from '../DatePicker';

import styles from './styles';

const DISABLED_SITUATIONS = [
  'Demitido',
  'Licença INSS',
  'Licença não remunerada',
];

export default ({
  open,
  onCloseDialog,
  title,
  confirmText,
  onConfirmClicked,
  cancelText,
  ignoreText,
  ignoreLink = false,
  circularLoading,
  successConfirm,
  status,
  statusList,
  setStatusValue,
  date,
  onChangeDate,
  statusValue,
}) => {
  const [dateDisabled, setDateDisabled] = useState(false);

  const handleStatusChange = ({ target }) => {
    setStatusValue(target.value);

    if (DISABLED_SITUATIONS.includes(target.value)) {
      onChangeDate(moment());
      setDateDisabled(true);
      return;
    }

    onChangeDate('');
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth="xs">
      <DialogTitle id="alert-dialog-title" style={styles.title}>
        {title}
      </DialogTitle>

      <DialogContent>
        <FormControl variant="outlined" style={styles.textFieldStyle}>
          <InputLabel id="label-situation">Situação</InputLabel>
          <Select
            value={statusValue}
            labelId="label-situation"
            label="Situação"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            onChange={handleStatusChange}
            disabled={circularLoading}
          >
            {statusList.map(statusEmployee => (
              <MenuItem
                value={statusEmployee.description}
                key={statusEmployee.code}
                selected={statusValue === statusEmployee.description}
              >
                {statusEmployee.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <CustomDatePicker
          disabled={
            statusValue !== 'Demitido' || circularLoading || dateDisabled
          }
          className={styles.datePickerStyle}
          value={date}
          onChange={onChangeDate}
          label="Data de desligamento"
        />
      </DialogContent>

      <DialogActions style={styles.buttonsContainer}>
        {!successConfirm ? (
          <Button onClick={onCloseDialog} style={styles.cancelTextStyle}>
            {cancelText}
          </Button>
        ) : (
          <span />
        )}
        {ignoreLink ? (
          <Button onClick={onConfirmClicked} color="primary" autoFocus>
            {ignoreText}
          </Button>
        ) : (
          <Button
            onClick={onConfirmClicked}
            variant="contained"
            autoFocus
            style={
              status === statusValue ||
              (statusValue === 'Demitido' &&
                moment(date).format('DD/MM/YYYY') === 'Data inválida')
                ? styles.disabledConfirmButtonStyle
                : styles.confirmButtonStyle
            }
            disabled={
              status === statusValue ||
              (statusValue === 'Demitido' &&
                moment(date).format('DD/MM/YYYY') === 'Data inválida') ||
              circularLoading
            }
          >
            {circularLoading ? (
              <CircularProgress style={styles.circularLoadingStyle} />
            ) : (
              confirmText
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
