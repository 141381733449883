import React from 'react';
import { Box, Typography } from '@material-ui/core';

export function EmployeesQuantityTabLabel({
  isSelected,
  quantity = '',
  title = '',
  backgroundColor = '',
  color = '',
  backgroundColorAfterSelected = '',
  colorAfterSelected = '',
}) {
  return (
    <Box sx={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
      {title}

      <Typography
        style={{
          background: isSelected
            ? backgroundColorAfterSelected
            : backgroundColor,
          color: isSelected ? colorAfterSelected : color,
          padding: '1px 6px',
          borderRadius: '4px',
          alignSelf: 'flex-start',
        }}
      >
        {quantity}
      </Typography>
    </Box>
  );
}
