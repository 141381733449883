import React from 'react';

import { Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function GeneralInfo({ cpf, name }) {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.valuesLineUp}>
          <div className={styles.valuesGroup}>
            <Typography className={styles.cardTitles}>Nome</Typography>
            <Typography className={styles.cardSubtitles}>{name}</Typography>
          </div>
          <div className={styles.valuesGroup}>
            <Typography className={styles.cardTitles}>CPF</Typography>
            <Typography className={styles.cardSubtitles}>{cpf}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
