/* eslint-disable prefer-destructuring */
import { useEffect, useMemo, useRef } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const PER_PAGE_KEY = 'TablePageSize';

export const useTable = ({
  length = 0,
  rowsPerPageOptions = [5, 10, 50, 100, 200],
  tableConfig = [],
  resetPageWhen = [],
  requests = () => {},
}) => {
  const orderBy = useRef('');
  const orderDirection = useRef('asc');
  const cardListBlock = useRef(10);
  const hasOrdination = tableConfig?.some(item => item?.hasOrder);

  const page = useRef(0);
  const perPage = useRef(
    parseInt(sessionStorage.getItem(PER_PAGE_KEY)) || rowsPerPageOptions[0],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(
    useMemo(() => theme.breakpoints.down('sm'), [
      useMediaQuery(theme.breakpoints.down('sm')),
    ]),
  );

  const handlePageChange = (_, newPage) => {
    page.current = newPage;

    if (hasOrdination) {
      requests(
        newPage,
        perPage.current,
        orderBy.current,
        orderDirection.current,
      );
    } else {
      requests(newPage, perPage.current);
    }
  };

  const handleRowsPerPageChange = event => {
    page.current = 0;
    perPage.current = event?.target?.value;

    cardListBlock.current = perPage.current;

    if (hasOrdination) {
      requests(0, perPage.current, orderBy.current, orderDirection.current);
    } else {
      requests(0, perPage.current);
    }
  };

  const handleSortChange = columnId => _ => {
    if (orderBy.current === columnId) {
      orderDirection.current =
        orderDirection.current === 'asc' ? 'desc' : 'asc';

      orderBy.current = columnId;
    } else {
      orderDirection.current = 'asc';
      orderBy.current = columnId;
    }

    requests(
      page.current,
      perPage.current,
      orderBy.current,
      orderDirection.current,
    );
  };

  const handleFetchMoreCards = () => {
    if (cardListBlock.current < length) {
      cardListBlock.current =
        length - (perPage.current + 10) < 0 ? length : perPage.current + 10;

      perPage.current =
        length - (perPage.current + 10) < 0 ? length : perPage.current + 10;

      requests(0, cardListBlock.current);
    }
  };

  useEffect(() => {
    return () => {
      page.current = 0;
      perPage.current = rowsPerPageOptions[0];
    };
  }, []);

  useEffect(
    () => {
      page.current = 0;
    },
    Array.isArray(resetPageWhen) ? resetPageWhen : [resetPageWhen],
  );

  useEffect(() => {
    sessionStorage.setItem(PER_PAGE_KEY, perPage.current);
  }, [perPage.current]);

  useEffect(() => {
    requests(page.current, perPage.current);
  }, []);

  return {
    isMobile,
    hasOrdination,
    page: page.current,
    perPage: perPage.current,
    orderBy: orderBy.current,
    orderDirection: orderDirection.current,
    handlePageChange,
    handleRowsPerPageChange,
    handleSortChange,
    handleFetchMoreCards,
  };
};
