import React from 'react';
import {
  alpha,
  Paper,
  Table,
  TableContainer,
  Toolbar,
  Typography,
} from '@material-ui/core';

import TableParts from './TableParts';

export function EnhancedTable({
  data = [],
  isLoading = false,
  hasError = false,
  config = [],
  hasPagination = true,
  length = 5,
  page = 0,
  rowsPerPage = 5,
  rowsPerPageOptions = [5, 10, 50],
  orderBy = '',
  orderDirection = 'asc',
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  handleSortChange = () => {},
  selectedCount,
}) {
  return (
    <TableContainer
      component={Paper}
      style={{
        overflow: 'hidden',
        borderRadius: '0.5rem',
        boxShadow: '0px 4px 10px #3b35411f',
      }}
    >
      {selectedCount !== null && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selectedCount > 0 && {
              bgcolor: theme =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity,
                ),
            }),
          }}
        >
          {selectedCount > 0 && (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${selectedCount} selecionado${selectedCount > 1 ? 's' : ''}`}
            </Typography>
          )}
        </Toolbar>
      )}
      <Table>
        <TableParts.EnhancedTableHead
          tableConfig={config}
          orderBy={orderBy}
          orderDirection={orderDirection}
          handleSortChange={handleSortChange}
          count={selectedCount}
        />
        <TableParts.EnhancedTableBody
          data={data}
          tableConfig={config}
          dataLength={length}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          hasError={hasError}
        />
      </Table>

      {hasPagination && (
        <TableParts.EnhancedTableFooter
          dataLength={length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </TableContainer>
  );
}
