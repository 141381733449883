import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';

import Buttons from '../../../../../components/Buttons';

import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';
import useAcademy from '../../../hooks/useAcademy';

import { useStyles } from '../../../styles';

export default function ResultTopic({ result }) {
  const styles = useStyles();

  const { currentResult, onExpandResult, onOpenHelpArticle } = useAcademy();

  const handleExpandResult = e => {
    e.preventDefault();
    onExpandResult(result.topicId);
  };

  return (
    <Accordion
      expanded={result.topicId === currentResult}
      className={styles.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        onClick={handleExpandResult}
      >
        <Typography className={styles.title}>
          {capitalizeFirstLetter(result.topic)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <Box className={styles.buttonsContainer}>
          {result?.subtopic.map(subtopic => (
            <Buttons.PrimaryButton
              key={subtopic.id}
              color="primary"
              variant="outlined"
              title={subtopic.title}
              onClick={() => onOpenHelpArticle(subtopic.id)}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
