import React from 'react';
import styles from './styles.module.css';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Dialog,
  Grid,
  Typography,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { isMobile } from '../../../../utils/breakpoints';

function AccountDebitModal({
  open,
  onClose,
  title,
  text,
  cancelButtonText,
  confirmButtonText,
  onCancelButtonClicked,
  onConfirmButtonClicked,
  loading,
  getValues,
  avaliableBalance,
  signatureAmount,
  sufficientBalance,
  total,
  loadingValues,
}) {
  const formattedTotal = currencyFormatter(total);

  const restAmount = sufficientBalance ? "Saldo restante" : "Saldo pendente";

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Grid
        container
        direction="column"
        alignItems="center"
        className={styles.container}
      >
        <Grid item>
          {loadingValues ? (
            <Skeleton
              className={styles.title}
              height={26}
              width={270}
              color="#202020"
              highlightColor="#444"
            />
          ) : (
            <Typography className={styles.title}>{title}</Typography>
          )}
        </Grid>
        <Grid item>
          {isMobile ? (
            <>
              {sufficientBalance ? (
                <Typography className={styles.text}>
                  Ao clicar em <bold>PAGAR</bold> seu plano será
                  <br /> renovado e debitaremos o valor
                  <br /> referente diretamente da sua conta.
                </Typography>
              ) : (
                <Typography className={styles.text}>
                  Você não possui saldo suficiente em
                  <br /> conta para ativar seu plano.
                </Typography>
              )}
            </>
          ) : (
            <>
              {sufficientBalance ? (
                <>
                  {loadingValues ? (
                    <Skeleton
                      className={styles.title}
                      height={32}
                      width={400}
                      color="#202020"
                      highlightColor="#444"
                    />
                  ) : (
                    <Typography className={styles.text}>
                      Ao clicar em <bold>PAGAR ASSINATURA</bold> seu plano será
                      renovado e debitaremos o valor referente diretamente da
                      sua conta.
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {loadingValues ? (
                    <Skeleton
                      className={styles.title}
                      height={32}
                      width={290}
                      color="#202020"
                      highlightColor="#444"
                    />
                  ) : (
                    <Typography className={styles.textInsufficient}>
                      Você não possui saldo suficiente em conta
                      <br /> para ativar seu plano.
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </Grid>

        <Grid
          item
          container
          direction={isMobile ? 'column' : 'row'}
          wrap="nowrap"
          justify={isMobile ? 'flex-start' : 'space-between'}
          className={styles.valuesContainer}
        >
          <Grid
            item
            container
            direction={isMobile ? 'row' : 'column'}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              {loadingValues ? (
                <Skeleton
                  className={styles.labelValue}
                  height={24}
                  width={118}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <p className={styles.labelValue}>Saldo em conta</p>
              )}
            </Grid>
            <Grid item className={styles.valueAmountContainer}>
              {loadingValues ? (
                <Skeleton
                  height={14}
                  width={92}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <Typography className={styles.valueAmount}>
                  {currencyFormatter(avaliableBalance)}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Hidden smDown>
            <Grid item className={styles.iconContainer}>
              {loadingValues ? (
                <Skeleton
                  circle
                  className={styles.labelValue}
                  height={15}
                  width={15}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <RemoveCircleOutlinedIcon className={styles.icon} />
              )}
            </Grid>
          </Hidden>

          <Grid
            item
            container
            direction={isMobile ? 'row' : 'column'}
            justify="space-between"
            alignItems="center"
            className={styles.signatureAmountContainer}
          >
            <Grid item>
              {loadingValues ? (
                <Skeleton
                  className={styles.labelValue}
                  height={24}
                  width={118}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <p className={styles.labelValue}>Plano</p>
              )}
            </Grid>
            <Grid item className={styles.valueAmountContainer}>
              {loadingValues ? (
                <Skeleton
                  height={14}
                  width={60}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <Typography className={styles.valueAmount}>
                  {signatureAmount
                    ? currencyFormatter(signatureAmount)
                    : 'R$ 0,00'}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Hidden smDown>
            <Grid item className={styles.iconContainer}>
              {loadingValues ? (
                <Skeleton
                  circle
                  className={styles.labelValue}
                  height={15}
                  width={15}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <PauseCircleFilledIcon className={styles.iconEquals} />
              )}
            </Grid>
          </Hidden>

          <Grid
            item
            container
            direction={isMobile ? 'row' : 'column'}
            justify="space-between"
            alignItems="center"
            className={styles.remainingBalance}
          >
            <Grid item>
              {loadingValues ? (
                <Skeleton
                  className={styles.labelValue}
                  height={24}
                  width={118}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <p className={styles.labelValue}>{restAmount}</p>
              )}
            </Grid>
            <Grid item className={styles.valueAmountResultContainer}>
              {loadingValues ? (
                <Skeleton
                  height={14}
                  width={92}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <Typography className={styles.valueAmountResult}>
                  {formattedTotal.slice(0, 1) === '-'
                    ? `R$ -${formattedTotal.slice(4)}`
                    : formattedTotal}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {!sufficientBalance && (
          <>
            {loadingValues ? (
              <Skeleton
                height={55}
                width={400}
                color="#202020"
                highlightColor="#444"
                style={{ marginTop: '39px' }}
              />
            ) : (
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justify="space-between"
                alignItems="center"
                className={styles.alertContainer}
              >
                <Grid item>
                  <ErrorOutlineOutlinedIcon className={styles.iconError} />
                </Grid>
                <Grid item>
                  <Typography className={styles.textAlert}>
                    Deposite o valor pendente para poder seguir com a ativação
                    do seu plano.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={styles.buttonsContainer}
        >
          <Grid item>
            {loadingValues ? (
              <Skeleton
                height={40}
                width={92}
                color="#202020"
                highlightColor="#444"
                style={{ marginRight: '10px' }}
              />
            ) : (
              <Button
                className={styles.cancelButton}
                onClick={onCancelButtonClicked}
              >
                {cancelButtonText}
              </Button>
            )}
          </Grid>
          <Grid item>
            {loadingValues ? (
              <Skeleton
                height={40}
                width={156}
                color="#202020"
                highlightColor="#444"
                style={{ marginRight: '10px' }}
              />
            ) : (
              <Button
                className={styles.payButton}
                onClick={onConfirmButtonClicked}
              >
                {loading ? (
                  <CircularProgress
                    className={styles.spinner}
                    size={styles.spinner}
                  />
                ) : (
                  <p>
                    {isMobile
                      ? 'PAGAR'
                      : isMobile && !sufficientBalance
                      ? 'DEPÓSITO'
                      : !isMobile && sufficientBalance
                      ? 'PAGAR ASSINATURA'
                      : 'FAZER DEPÓSITO'}
                  </p>
                )}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AccountDebitModal;
