import { useState } from 'react';

export function useCardContainer() {
  const [currentCardOpen, setCurrentCardOpen] = useState('');

  return {
    currentCardOpen,
    handleOpenCard: cardId =>
      currentCardOpen === cardId
        ? setCurrentCardOpen('')
        : setCurrentCardOpen(cardId),
  };
}
