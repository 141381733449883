import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
      },

      '& > div': {
        padding: 0,
      },
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 700,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      fontSize: '.875rem',
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    alert: {
      marginBottom: theme.spacing(3),
    },
  }),
);
