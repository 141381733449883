export const situationOfEmployees = [
  { text: 'Ativos', value: 1, search: 'Ativo' },
  { text: 'Demitidos', value: 2, search: 'Demitido' },
  { text: 'Férias', value: 3, search: 'Férias' },
  { text: 'Licença INSS', value: 4, search: 'Licença INSS' },
  {
    text: 'Licença não remunerada',
    value: 5,
    search: 'Licença não remunerada',
  },
  { text: 'Todos', value: 6, search: '' },
];

export const situationOfRejectedEmployees = [
  { text: 'Rejeitado', value: 1, search: 'REJECTED' },
  { text: 'Reprovado definitivo', value: 2, search: 'DEFINITIVELY_REPROVED' },
  {
    text: 'Reprovado com pendência',
    value: 3,
    search: 'PENDING',
  },
];

export const situationType = [
  { text: 'Conta corrente', value: 1, search: 'CC' },
  { text: 'Conta poupança', value: 2, search: 'CP' },
];

export const bankData = [
  { text: 'Sim', value: 1, search: true },
  { text: 'Não', value: 2, search: false },
];

export const situationOfEmployeesPointOnline = [
  { text: 'Ativos', value: 1, search: 'Ativo' },
  { text: 'Demitidos', value: 2, search: 'Demitido' },
  { text: 'Todos', value: 3, search: '' },
];
