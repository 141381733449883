import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Controller } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';
import Form from '../../../../../components/Form';

import useCreateModal from './hooks/useCreationModal';

import { useStyles } from '../../../styles';

const IpCreationModal = NiceModal.create(({ onCreate }) => {
  const styles = useStyles();
  const modal = useModal();

  const { onSubmit, errors, isValid, control } = useCreateModal({
    onCreate,
  });

  return (
    <Dialog open={modal.visible} onClose={modal.remove} maxWidth="md">
      <DialogTitle>Adicionar permissão de IP</DialogTitle>
      <Form.FormBase onSubmit={onSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="ip"
                control={control}
                render={({ field }) => (
                  <Form.TextField
                    label="IP"
                    size="medium"
                    error={!!errors?.ip}
                    helperText={errors?.ip?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.ipModalActions}>
          <Buttons.PrimaryButton
            color="primary"
            title="Cancelar"
            type="button"
            onClick={modal.remove}
          />
          <Buttons.PrimaryButton
            color="secondary"
            title="Adicionar"
            type="submit"
            disabled={!isValid}
          />
        </DialogActions>
      </Form.FormBase>
    </Dialog>
  );
});

export default IpCreationModal;
