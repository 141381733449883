import React from 'react';
import moment from 'moment';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';

import CustomDatePicker from '../DatePicker';

import formatOnlyNumbers from '../../utils/formatOnlyNumbers';

import styles from './styles';

export default function AlterDateAndAuthorizePayrollModal({
  open,
  onCloseDialog,
  title,
  text,
  confirmText,
  onConfirmClicked,
  cancelText,
  ignoreText,
  ignoreLink = false,
  onChangeDate,
  circularLoading,
  cancelButton,
  errorMessage,
  date,
  paymentType,
  openModalSuccess,
  codeValue,
  codeVerification,
  handleChangeCodeValue,
  errorMessageCode,
  emailLabel,
  pinOnConfirmPayroll,
  sendPinStatus,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title" style={styles.title}>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={styles.contentDialog}
        >
          <Typography>{text}</Typography>
          {!openModalSuccess ? (
            <div>
              <InputLabel style={styles.inputLabelStyle}>
                Reagendar para o dia:
              </InputLabel>
              <CustomDatePicker
                value={date}
                onChange={onChangeDate}
                minDate={
                  paymentType !== 'Rescisão' ? moment().add(1, 'day') : moment()
                }
              />
            </div>
          ) : (
            ''
          )}
        </DialogContentText>
      </DialogContent>

      {codeVerification && (
        <DialogContent>
          <Box mb={5}>
            <Typography style={styles.bigText}>
              {sendPinStatus === 'SMS_SENT' ? 'Digite o código enviado por SMS.': false}
              {sendPinStatus === 'EMAIL_SENT' ? 'Digite o código enviado para seu e-mail.': false}
            </Typography>

            <TextField
              value={formatOnlyNumbers(codeValue)}
              placeholder="Código"
              variant="outlined"
              fullWidth
              onChange={handleChangeCodeValue}
              disabled={circularLoading}
              inputProps={{ maxLength: 6 }}
            />

            <Typography style={styles.helperText}>
              {sendPinStatus === 'SMS_SENT' ? 'Não recebeu o SMS? Tente realizar novamente a autorização.': false}
              {sendPinStatus === 'EMAIL_SENT' ? 'Não recebeu o e-mail? Verifique as pastas Spam e Lixeira': false}
            </Typography>

            {errorMessage && (
              <Typography style={styles.errorText}>{errorMessage}</Typography>
            )}
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        {!cancelButton ? (
          <Button
            onClick={onCloseDialog}
            color="primary"
            autoFocus
            style={styles.cancelButton}
          >
            {cancelText}
          </Button>
        ) : (
          <span />
        )}

        {ignoreLink ? (
          <Button onClick={onConfirmClicked} color="primary" autoFocus>
            {ignoreText}
          </Button>
        ) : (
          <Button
            onClick={onConfirmClicked}
            variant="contained"
            autoFocus
            style={
              errorMessage ||
              circularLoading ||
              !codeValue ||
              (codeVerification && codeValue?.length < 4)
                ? styles.disabledConfirmButtonStyle
                : styles.confirmButtonStyle
            }
            disabled={
              errorMessage ||
              circularLoading ||
              (pinOnConfirmPayroll &&
                (!codeValue || (codeVerification && codeValue?.length < 4)))
            }
          >
            {circularLoading ? (
              <CircularProgress style={styles.circularLoadingStyle} />
            ) : (
              confirmText
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
