import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input';

import {
  CircularProgress,
  IconButton,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Tooltip
} from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import EnhancedTableHead from './EnhancedTableHead';
import TablePaginationActions from './TablePaginationActions';
import FakeTable from './FakeTable';

import PurpleCheckbox from '../../../../../components/Checkbox/PurpleCheckbox';

import { ReactComponent as SelectBoxIcon } from '../../../../../assets/eyesIcon.svg';

import textCapitalize from '../../../../../utils/textCapitalize';
import brazilianCurrencyFormatter from '../../../../../utils/brazilianCurrencyFormatter';

import { api } from '../../../../../services/api';

import styles from '../../styles.module.css';

export default function EnhancedTable({
  employeeData,
  openSnackbarOnSalaryUpdate,
  loadingTable,
  setSelected,
  selected,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalSize,
  showSalaryTextField,
  setShowSalaryTextField,
  currentCompanyCode,
  openSnackbarErrorOnSalaryUpdate
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [insuredDetails, setInsuredDetails] = useState({});

  const [salary, setSalary] = useState(0);
  const [initialSalary, setInitialSalary] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [locations, setLocations] = useState([]);
  const [stockings, setStockings] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const selectedFilter = selected.filter(item => item !== ',');

    dispatch({
      type: 'SET_INSURANCE_COUNT',
      count: selectedFilter.length
    });
  }, [selected, dispatch]);

  useEffect(() => {
    getLocations();
    getStockings();
  }, []);

  function getLocations() {
    api.get(`companies/${currentCompanyCode}/locations`)
    .then(({ data }) => setLocations(data))
  };

  function getStockings() {
    api.get(`companies/${currentCompanyCode}/stockings`)
    .then(({ data }) => setStockings(data))
  };

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = employeeData.map((row) => {
        if (row.situation !== "Em cancelamento") {
          return (
            {
              id: row.id,
              cpf: row.cpf,
              name: row.name,
              initialValidityDate: row.initialValidityDate,
              insuranceValue: row.insuranceValue,
              companyEncodedKey: row.insuranceValue,
              type: row.type,
              situation: row.situation,
              cancellationDate: row.cancellationDate,
              createdDate: row.createdDate,
              creationUser: row.creationUser,
            }
          )
        }
      });

      sessionStorage.setItem('insuranceEmployeesTable', JSON.stringify(newSelecteds.filter(item => item)));
      setSelected(newSelecteds.filter(item => item ));
      return;
    }

    sessionStorage.setItem('insuranceEmployeesTable', JSON.stringify([]));
    setSelected([]);
  };

  function handleClick(event, id, row) {
    const selectedIndex = selected.map(function(e) { return e.id; }).indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1 && row.situation !== "Em cancelamento") {
      newSelected = newSelected.concat(selected, {
        id: row.id,
        cpf: row.cpf,
        name: row.name,
        initialValidityDate: row.initialValidityDate,
        insuranceValue: row.insuranceValue,
        companyEncodedKey: row.insuranceValue,
        type: row.type,
        situation: row.situation,
        cancellationDate: row.cancellationDate,
        createdDate: row.createdDate,
        creationUser: row.creationUser,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (row.situation !== "Em cancelamento") {
      sessionStorage.setItem('insuranceEmployeesTable', JSON.stringify(newSelected));
      setSelected(newSelected);
    }
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => selected.map(function(e) {
    return e?.id;
  }).indexOf(row?.id) !== -1;

  function viewDetails(row) {
    sessionStorage.setItem('insuranceEncodedKey', row.employeeEncodedKey);
    history.push(`/insurance-management/insurance-details/${row.cpf}`);
  };

  function getInsuredDetails(row, index) {
    const cpf = row.cpf;
    const currentSalary = row.salary;

    setInitialSalary(currentSalary);
    setShowSalaryTextField(index);

    api.get(`insurance/v2/${cpf}/${currentCompanyCode}`)
    .then(({ data }) => {
      setInsuredDetails(data);
    })
  };

  function editSalary(index) {
    const insuranceEncodedKey = employeeData[index]?.employeeEncodedKey;
    const currentLocatedAt = locations.filter(item => item.description === insuredDetails?.professionalData?.locatedAt);
    const currentStocking = stockings.filter(item => item.description === insuredDetails?.professionalData?.stocking);
    let body = insuredDetails;

    setIsLoading(true);

    body.professionalData.salary = salary;
    body.professionalData.locatedAt = currentLocatedAt === [] ? null : currentLocatedAt[0]?.code;
    body.professionalData.stocking = currentStocking === [] ? null : currentStocking[0]?.code;

    api.put(`/insurance/v2/update/${insuranceEncodedKey}/${currentCompanyCode}`, body)
    .then(() => {
      setShowSalaryTextField(null);
      openSnackbarOnSalaryUpdate();
    })
    .catch(() => {
      openSnackbarErrorOnSalaryUpdate();
    })
    .finally(() => {
      setIsLoading(false);
    })
  };

  function handleChangeSalary(value, index) {
    let currentList = employeeData;

    currentList[index].salary = value;

    setSalary(value);
  };

  function handleCancelEditSalary(index) {
    let currentList = employeeData;

    currentList[index].salary = initialSalary;

    setShowSalaryTextField(null);
    setSalary(initialSalary);
  };

  return (
    <div className={styles.tableContainer}>
      <Paper>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={employeeData.filter(item => item.situation !== "Em cancelamento").length}
            />
            {
              loadingTable ? (
                <FakeTable />
              ) : (
                <TableBody>
                  {stableSort(employeeData, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell
                            onClick={(event) => handleClick(event, row.id, row)}
                            padding="checkbox"
                          >
                            <PurpleCheckbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              disabled={row.situation === "Em cancelamento"}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className={styles.tableCell}
                          >
                            {textCapitalize(row.name)}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {row.cpf}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {row?.type}
                          </TableCell>

                          <TableCell className={styles.salaryTableCell}>
                            {showSalaryTextField === index ? (
                              <FormControl className={styles.salaryTextField}>
                                {isLoading && <CircularProgress className={styles.circularProgress}/>}
                                
                                <CurrencyInput
                                  autoFocus
                                  onChange={(event, value) => handleChangeSalary(value, index)}
                                  value={row?.salary}
                                  className={styles.inputValue}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix="R$ "
                                  label
                                />

                                <div className={styles.salaryTextFieldButtonContainer}>
                                  <div 
                                    className={styles.salaryTextFieldButton}
                                    onClick={() => isLoading ? {} : editSalary(index)}
                                  >
                                    <CheckRoundedIcon className={styles.salaryTextFieldButtonIcon} />
                                  </div>

                                  <div 
                                    className={styles.salaryTextFieldButton}
                                    onClick={() => isLoading ? {} : handleCancelEditSalary(index)}
                                  >
                                    <CloseRoundedIcon className={styles.salaryTextFieldButtonIconRed} />
                                  </div>
                                </div>
                              </FormControl>
                            ) : (
                              <Tooltip title="Atualizar salário">
                                <span onClick={() => getInsuredDetails(row, index)}>
                                  {row?.salary ? brazilianCurrencyFormatter(row.salary * 100) : 'Em Branco'}
                                </span>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {row?.situation}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => viewDetails(row)}
                            >
                              <Tooltip title="Visualizar detalhes">
                                <SelectBoxIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              )
            }
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}
