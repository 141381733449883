export const itemsProfilesAllowed = {
  generalOverview: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  cashIn: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
  },
  payment: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  bankExtract: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
  },
  manageAccounts: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
  },
  pix: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
  },
  pixKeys: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
  },
  valuesQuery: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  manageEmployees: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  paycheck: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  valuesAnticipation: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  limitQuery: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  costCenterAndSector: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
  },
  manageUsers: {
    ADMIN: 'ADMIN',
  },
  myPlanSomapay: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
  },
  somapayAcademy: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  collaborators: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  myPoint: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  invoices: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  insurance: {
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  supportService: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  consigned: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
  cashOut: {
    ADMIN: 'ADMIN',
    FINANCIAL: 'FINANCIAL',
    AUTHORIZER: 'AUTHORIZER',
  },
  beevaleBenefits: {
    ADMIN: 'ADMIN',
    PERSONAL_DEPARTMENT: 'PERSONAL_DEPARTMENT',
  },
};
