import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Typography, FormControl, Hidden } from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import Table from './Table';
import FakeTable from './FakeTable';
import LogoLoading from '../../../../components/LogoLoading';
import Error from './Error';

import { api } from '../../../../services/api';

import styles from './styles.module.css';

export default () => {
  const history = useHistory();
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const [loansPerCompetenceList, setAllLoansPerCompetenceList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [employeesListLoaded, setEmployeesListLoaded] = useState(false);
  const [employeesListLoading, setEmployeesListLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [competenceFilterValue, setCompetenceFilterValue] = useState('');

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const sort = tableHeadId => {
    if (orderBy === tableHeadId) {
      setOrder(prevState => (prevState === 'asc' ? 'desc' : 'asc'));
    }
    setOrderBy(tableHeadId);
  };

  const handleGoToDetails = (id, competence) => {
    history.push(`/requesting-employees`, {
      id,
      competence,
    });
  };

  const handleChangeCompetenceFilter = value => {
    if (value !== null) {
      setCompetenceFilterValue(moment(value).format('YYYY-MM'));
      return;
    }
    setCompetenceFilterValue('');
  };

  const getEmployeesList = () => {
    setEmployeesListLoading(true);
    const requestOptions =
      competenceFilterValue !== ''
        ? {
            params: {
              page: currentPage,
              size: pageSize,
              sort: `${orderBy},${order}`,
              competence: moment(competenceFilterValue).format('MM/YYYY'),
            },
          }
        : {
            params: {
              page: currentPage,
              size: pageSize,
              sort: `${orderBy},${order}`,
            },
          };
    api
      .get(`consolidatedValues/competence/${companyCode}`, requestOptions)
      .then(({ data }) => {
        setError(false);
        setAllLoansPerCompetenceList(data.content);
        setCount(data.totalSize);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0].errorReason === 'COMPETENCE_NOT_FOUND') {
            setErrorData({
              type: 'COMPETENCE_NOT_FOUND',
              title: 'Nada encontrado',
              text: '',
            });
            setError(true);
            return;
          }
          setGeneralError(true);
        },
      )
      .finally(() => {
        if (!employeesListLoaded) {
          setEmployeesListLoaded(true);
        }
        setEmployeesListLoading(false);
      });
  };

  useEffect(() => {
    getEmployeesList();
  }, [currentPage, pageSize, order, orderBy, competenceFilterValue]);

  useEffect(() => {
    if (!employeesListLoaded) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [employeesListLoaded]);

  return (
    <div>
      <Typography className={styles.pageTitle}>
        Consulta de valores consolidados
      </Typography>
      {pageLoading ? (
        <LogoLoading />
      ) : (
        <div>
          {generalError ? (
            <div>
              <Error />
            </div>
          ) : (
            <div>
              <div className={styles.filtersContainer}>
                <FormControl
                  variant="outlined"
                  className={styles.competenceInput}
                >
                  <MuiPickersUtilsProvider
                    utils={(DateFnsUtils, MomentUtils)}
                    locale="pt-br"
                  >
                    <DatePicker
                      openTo="year"
                      views={['year', 'month']}
                      label="Competência"
                      placeholder="Competência"
                      value={competenceFilterValue || null}
                      onChange={handleChangeCompetenceFilter}
                      inputVariant="outlined"
                      invalidDateMessage="A data não é válida"
                      clearable={competenceFilterValue !== ''}
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </div>
              {employeesListLoading ? (
                <FakeTable
                  count={count}
                  pageSize={pageSize}
                  currentPage={currentPage}
                />
              ) : error ? (
                <Error type={errorData.type} title={errorData.title} text />
              ) : (
                <Table
                  bodyData={loansPerCompetenceList}
                  count={count}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleGoToDetails={handleGoToDetails}
                  sort={sort}
                  order={order}
                  orderBy={orderBy}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
