import React from 'react';

import {
  Button,
  Dialog,
  Grid,
  Typography
} from "@material-ui/core";

import { useConfirmation } from '../../context/ModalProcessBlock';

import styles from "./styles.module.css";

function ModalProcessAbandonment() {
  const { allowTransition, confirm, blockTransition, messageConfirm } = useConfirmation();

  return (

    <Dialog open={confirm} maxWidth="xs" fullWidth>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={styles.container}
      >
        <Grid item className={styles.titleContainer}>
          <Typography className={styles.title}>
            {messageConfirm}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={styles.subtitle}>
            Todos os dados informados até agora serão perdidos e essa ação não poderá ser desfeita.
          </Typography>
        </Grid>

        <Grid
          item
          container
          direction="row-reverse"
        >
          <Grid item className={styles.buttonContainer}>
            <Button onClick={allowTransition} className={styles.button}>
              Sim, cancelar
            </Button>
          </Grid>

          <Grid item className={styles.buttonContainer}>
            <Button onClick={blockTransition} className={styles.closeButton}>
              Não, continuar
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ModalProcessAbandonment;
