import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Grid, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import ConfirmImmediateReleaseModal from '../../../../../components/ConfirmImmediateReleaseModal';
import BalancesModal from '../../../../../components/BalancesModal';
import SuccessModal from '../../../../../components/SuccessModal';

import DepositAccountModal from '../DepositAccountModal';

import { api } from '../../../../../services/api';

import styles from './styles.module.css';

import currencyFormatter from '../../../../../utils/currencyFormatter';
import {
  hasAuthorization,
  profiles,
} from '../../../../../utils/hasAuthorization';
import { isSubsidiaryCompanie } from '../../../../../utils/isSubsidiaryCompanie';

import { ReactComponent as ArrowWithCircle } from '../../../../../assets/arrowWithCircle.svg';
import { ReactComponent as OragenArrowRight } from '../../../../../assets/orangeArrowRight.svg';
import { ReactComponent as OpacityCircle } from '../../../../../assets/opacityCircle.svg';
import { ReactComponent as OrangeCheckCircle } from '../../../../../assets/orange-check-circle.svg';
import { ReactComponent as ReleasePaymentIcon } from '../../../../../assets/release-payment.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../../../assets/minusIcon.svg';
import { ActionsBalance } from '../../../../../redux/store/Balances';

export default function ContentDrawer({
  onPaymentClick,
  paymentTypeDescription,
  authorizeButtonDisabled,
  insufficientMoney,
  nsu,
  handleChangePinValue,
  EMAIL_MASKED,
  setPinValue,
  pinValue,
  sendPinStatus,
}) {
  const [loadingButton, setLoadingButton] = useState(false);
  const [openDepositAccountModal, setOpenDepositAccountModal] = useState(false);
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [loadingRates, setLoadingRates] = useState(false);
  const [openImmediateReleaseModal, setOpenImmediateReleaseModal] = useState(
    false,
  );
  const [
    openConfirmImmediateReleaseModal,
    setOpenConfirmImmediateReleaseModal,
  ] = useState(false);
  const [
    openImmediateReleaseSuccessModal,
    setOpenImmediateReleaseSuccessModal,
  ] = useState(false);
  const [released, setReleased] = useState(false);
  const [pinOnConfirmPayroll, setPinOnConfirmPayroll] = useState(false);

  const [rateValues, setRateValues] = useState({});

  const [statusPayroll, setStatusPayroll] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [requestId, setRequestId] = useState('');

  const LOADING_PAYMENT = sessionStorage.getItem('loadingPayment');
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const { accountSelected } = useSelector(state => state.account);

  const dispatch = useDispatch();
  const history = useHistory();
  const isSubsidiary = isSubsidiaryCompanie();

  const zeroProduct = JSON.parse(sessionStorage.getItem('zeroProduct'));
  const IS_RESCISION = '2';

  useEffect(() => {
    verifyNecessityOfPinForAuthorizePayroll();
  }, []);

  const immediateRelease = (status, nsu) => {
    setErrorMessage('');
    setPinValue('');
    setInsufficientFunds(false);
    setLoadingRates(true);
    setOpenImmediateReleaseModal(true);
    setStatusPayroll(status);
    getRates(nsu);
  };

  const resetError = () => setErrorMessage('');

  function getRates(nsu) {
    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .get(
        `/employeesPayments/expressPayment/fee/${CURRENT_COMPANY_CODE}/${nsu}`,
        requestOptions,
      )
      .then(({ data }) => {
        const { errorMessage, missingBalance } = data;

        if (pinOnConfirmPayroll) {
          preAuthorizePayroll(false);
        }

        if (errorMessage && missingBalance) {
          setInsufficientFunds(true);
        }
        setRateValues(data);
      })
      .finally(() => setLoadingRates(false));
  }

  const onConfirmImmediateRelease = () => {
    setLoadingButton(true);

    const payrollId = rateValues.nsu;

    const requestOptions = {
      headers: {
        requestId,
        pin: pinValue,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .post(`employeesPayments/expressPayment/${payrollId}`, '', requestOptions)
      .then(() => {
        setOpenConfirmImmediateReleaseModal(false);
        setOpenImmediateReleaseSuccessModal(true);
        setOpenImmediateReleaseModal(false);
        setReleased(true);
        setInsufficientFunds(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorMessage(errors[0]?.errorDetail);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  };

  function preAuthorizePayroll(isTryingSendCodeBySms) {
    const nsuList = [Number(nsu)];

    const requestBody = {
      nsuList,
      emailNotReceived: isTryingSendCodeBySms,
    };

    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    api
      .put('/employeesPayments/pre-authorize', requestBody, requestConfig)
      .then(({ data }) => {
        setRequestId(data.requestId);
      });
  }

  function verifyNecessityOfPinForAuthorizePayroll() {
    api.get(`/companies/${CURRENT_COMPANY_CODE}/search`).then(({ data }) => {
      setPinOnConfirmPayroll(data.confirmPayroll);
    });
  }

  const onCloseConfirmImmediateReleaseModal = () => {
    setOpenConfirmImmediateReleaseModal(false);
  };

  const onCloseImmediateReleaseSuccessModal = () => {
    dispatch(ActionsBalance.getBalances());
    history.push('/payroll');
    setOpenImmediateReleaseSuccessModal(false);
  };

  const goToDeposits = () => {
    history.push('/cash-in');
  };

  const formatFeeAmount = rateValues.feeAmount
    ? currencyFormatter(rateValues.feeAmount)
    : 'R$ 0,00';

  const formatPayrollValue = rateValues.totalAmount
    ? currencyFormatter(rateValues.totalAmount)
    : 'R$ 0,00';

  const totalPayrollAmount = rateValues.totalAmount + rateValues.feeAmount;

  const formatTotalPayrollAmount = totalPayrollAmount
    ? currencyFormatter(totalPayrollAmount)
    : 'R$ 0,00';

  const formatedMissingBalance = rateValues.missingBalance
    ? currencyFormatter(rateValues.missingBalance)
    : 'R$ 0,00';

  const formatedBalance = rateValues?.availableBalance
    ? currencyFormatter(rateValues?.availableBalance)
    : 'R$ 0,00';

  return (
    <>
      <Grid>
        <DepositAccountModal
          open={openDepositAccountModal}
          onCloseDialog={() => setOpenDepositAccountModal(false)}
        />
        <Grid className={styles.listGroup}>
          <div className={styles.iconAndLineContainer}>
            <Grid className={styles.icon}>
              <OpacityCircle />
            </Grid>
          </div>

          <div>
            <Typography className={styles.mediumTextVioletStyleFaded}>
              Monte a folha
            </Typography>
            <Typography className={styles.smallTextVioletStyleFaded}>
              Nessa etapa você monta a folha indicando quais funcionários e
              valores devem ser pagos.
            </Typography>
          </div>
        </Grid>

        <Grid className={styles.listGroupValidate}>
          <div className={styles.iconAndLineContainer}>
            <Grid className={styles.icon}>
              <OpacityCircle />
            </Grid>
          </div>

          <div>
            <Typography className={styles.mediumTextVioletStyleFaded}>
              Valide e confirme a folha
            </Typography>
            <Typography className={styles.smallTextVioletStyleFaded}>
              Aqui você valida e agenda a folha conferindo os dados dos
              funcionários, data de agendamento, valores e tarifa.
            </Typography>
          </div>
        </Grid>

        {!LOADING_PAYMENT ? (
          <Grid className={styles.OragenListGroup}>
            <div className={styles.iconAndLineContainer}>
              <Grid className={styles.icon}>
                <OragenArrowRight />
              </Grid>
            </div>

            <Grid>
              <Typography className={styles.mediumTextOrangeStyle}>
                Autorize o agendamento
              </Typography>
              <Typography className={styles.smallTextOrangeStyle}>
                Para que a folha seja executada é preciso que você autorize o
                pagamento.
              </Typography>
              <Button
                className={
                  (paymentTypeDescription === 'Rescisão' &&
                    authorizeButtonDisabled === true) ||
                  !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER])
                    ? styles.orangeButtonDisabled
                    : styles.oragenButton
                }
                disabled={
                  (paymentTypeDescription === 'Rescisão' &&
                    authorizeButtonDisabled === true) ||
                  !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER])
                }
                onClick={onPaymentClick}
              >
                Autorize agora
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid className={styles.listGroupScheduled}>
            <Grid className={styles.icon}>
              <OrangeCheckCircle />
            </Grid>

            <Grid>
              <Typography className={styles.mediumTextOrangeStyle}>
                Autorize o Agendamento
              </Typography>
              <Typography className={styles.smallTextOrangeStyle}>
                O pagamento foi autorizado!{' '}
                <span role="img" aria-label="clapping-hands">
                  👏
                </span>
              </Typography>
            </Grid>
          </Grid>
        )}

        {insufficientMoney === true && paymentTypeDescription === 'Rescisão' ? (
          <Grid container>
            <Grid
              container
              justify="center"
              alignItems="center"
              wrap="nowrap"
              className={styles.containerWarning}
            >
              <Grid item className={styles.doubtsIcon}>
                <ErrorOutlineIcon
                  fontSize="small"
                  className={styles.errorIcon}
                />
              </Grid>

              <Grid item>
                <Typography className={styles.textWarning}>
                  Você não possui saldo suficiente para pagar esta folha.
                </Typography>
              </Grid>
            </Grid>

            <Grid className={styles.depositGroup}>
              <Grid className={styles.icon}>
                <ArrowWithCircle />
              </Grid>

              <Grid>
                <Typography className={styles.mediumTextVioletStyle}>
                  Deposite o valor na conta
                </Typography>
                <Typography className={styles.smallTextVioletStyle}>
                  É preciso ter saldo suficiente em conta para poder autorizar o
                  pagamento da folha.
                </Typography>
                <Button
                  className={styles.violletButton}
                  onClick={() => setOpenDepositAccountModal(true)}
                  disabled={
                    !hasAuthorization(ACCESS_PROFILES, [
                      profiles.ADMIN,
                      profiles.FINANCIAL,
                    ]) || isSubsidiary
                  }
                >
                  Deposite agora
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        {paymentTypeDescription !== 'Rescisão' && insufficientMoney ? (
          <Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              wrap="nowrap"
              className={styles.containerWarning}
            >
              <Grid item className={styles.doubtsIcon}>
                <ErrorOutlineIcon
                  fontSize="small"
                  className={styles.errorIcon}
                />
              </Grid>

              <Grid item>
                <Typography className={styles.textWarning}>
                  Você não possui saldo suficiente para pagar esta folha.
                </Typography>
              </Grid>
            </Grid>

            <Grid className={styles.depositGroup}>
              <Grid className={styles.icon}>
                <ArrowWithCircle />
              </Grid>

              <Grid>
                <Typography className={styles.mediumTextVioletStyle}>
                  Deposite o valor na conta
                </Typography>
                <Typography className={styles.smallTextVioletStyle}>
                  É preciso ter saldo suficiente em conta para que o pagamento
                  da folha seja executado.
                </Typography>
                <Button
                  className={styles.violletButton}
                  onClick={() => setOpenDepositAccountModal(true)}
                  disabled={
                    !hasAuthorization(ACCESS_PROFILES, [
                      profiles.ADMIN,
                      profiles.FINANCIAL,
                    ]) || isSubsidiary
                  }
                >
                  Deposite agora
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          paymentTypeDescription !== 'Rescisão' &&
          !insufficientMoney && (
            <Grid
              container
              wrap="nowrap"
              className={styles.containerReleasePayment}
            >
              <Grid item className={styles.icon}>
                <ReleasePaymentIcon />
              </Grid>

              <Grid
                item
                container
                direction="column"
                className={styles.containerTitleReleasePayment}
              >
                <Grid item className={styles.containerTitleReleasePayment}>
                  <Typography className={styles.titleReleasePayment}>
                    Libere o pagamento agora!
                  </Typography>
                </Grid>

                <Grid item className={styles.containerTextRealeasePayment}>
                  {released === true ? (
                    <Typography className={styles.textRealeasePayment}>
                      O pagamento foi liberado!{' '}
                      <span role="img" aria-label="clapping-hands">
                        👏
                      </span>
                    </Typography>
                  ) : (
                    <Typography className={styles.textRealeasePayment}>
                      Faça a liberação imediata e garanta o o pagamento da folha
                      em até uma hora.
                    </Typography>
                  )}
                </Grid>

                {!released && (
                  <Grid item className={styles.containerButtonRelease}>
                    <Button
                      disabled={
                        !LOADING_PAYMENT ||
                        paymentTypeDescription?.paymentType === IS_RESCISION ||
                        zeroProduct ||
                        insufficientMoney
                      }
                      onClick={() => immediateRelease(statusPayroll, nsu)}
                      className={styles.releaseButton}
                    >
                      Libere Agora
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )
        )}
      </Grid>

      <BalancesModal
        pinOnConfirmPayroll={pinOnConfirmPayroll}
        codeVerification={!insufficientFunds}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        resetError={resetError}
        emailLabel={EMAIL_MASKED}
        open={openImmediateReleaseModal}
        onCloseDialog={() => setOpenImmediateReleaseModal(false)}
        title={
          insufficientFunds
            ? 'Saldo insuficiente'
            : 'Liberar este pagamento imediatamente?'
        }
        text={
          insufficientFunds
            ? 'Você não possui saldo suficiente para liberação imediata do pagamento desta folha.'
            : 'O pagamento será efetuado em até 1 hora, a partir do momento da confirmação e não poderá ser cancelado.'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setOpenImmediateReleaseModal(false)}
        confirmButtonText={
          insufficientFunds ? 'Fazer depósito' : 'Liberar pagamento'
        }
        onConfirmClicked={
          insufficientFunds ? goToDeposits : onConfirmImmediateRelease
        }
        loading={loadingRates}
        loadingButton={loadingButton}
        firstLabel={insufficientFunds ? 'Seu saldo' : 'Folha'}
        firstValue={insufficientFunds ? formatedBalance : formatPayrollValue}
        secondLabel={insufficientFunds ? 'Valor da Folha' : 'Tarifa'}
        secondValue={insufficientFunds ? formatPayrollValue : formatFeeAmount}
        thirdLabel={insufficientFunds ? 'Diferença' : 'Total da Folha'}
        thirdValue={
          insufficientFunds ? formatedMissingBalance : formatTotalPayrollAmount
        }
        alertText={
          insufficientFunds
            ? 'Deposite o valor pendente para conseguir realizar a liberação.'
            : 'A tarifa será cobrada após a execução do pagamento.'
        }
        icon={insufficientFunds ? <MinusIcon /> : <PlusIcon />}
        disabledConfirmButton={
          loadingButton ||
          (insufficientFunds &&
            !hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ])) ||
          isSubsidiary
        }
        sendPinStatus={sendPinStatus}
      />

      <ConfirmImmediateReleaseModal
        open={openConfirmImmediateReleaseModal}
        onCloseDialog={onCloseConfirmImmediateReleaseModal}
        title="Confirmar liberação imediata?"
        titleDescription="Ao confirmar, o pagamento da folha não poderá ser cancelado"
        onConfirm={onConfirmImmediateRelease}
        loadingButton={loadingButton}
        ErrorMessage={errorMessage}
      />

      <SuccessModal
        open={openImmediateReleaseSuccessModal}
        onCloseDialog={onCloseImmediateReleaseSuccessModal}
        title={
          statusPayroll === 'Aguardando autorização'
            ? 'Pagamento autorizado e liberado com sucesso!'
            : 'Pagamento liberado com sucesso!'
        }
        closeButtonText="Fechar"
        text="Em até 1 hora todos os pagamentos serão realizados."
      />
    </>
  );
}
