import React from 'react';
import { Controller } from 'react-hook-form';
import { CloseOutlined } from '@material-ui/icons';
import {
  Box,
  Typography,
  InputAdornment,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';

import { Header } from '../../../components/Header';
import Buttons from '../../../components/Buttons';
import Form from '../../../components/Form';
import CashOutBillBottomBar from './components/BottomBar';
import CashOutBillReviewStep from './components/ReviewStep';

import useCashoutBill from './hooks/useCashoutBill';

import { useStyles } from './styles';

export default function CashOutBillScreen() {
  const styles = useStyles();

  const {
    isSending,
    isLoading,
    currentStep,
    control,
    onConsult,
    onSubmit,
    onBack,
    isValid,
    onDueDateEdit,
    meta: { steps, barCodeMask },
  } = useCashoutBill();

  const getStepContent = () =>
    currentStep === 0 ? (
      <>
        <Box className={styles.root}>
          <Form.FormBase onSubmit={onConsult}>
            <Typography variant="h2" className={styles.title}>
              Insira o código de barras do seu boleto abaixo:
            </Typography>
            <Controller
              name="digitable"
              control={control}
              render={({ field }) => (
                <Form.MaskedField
                  size="medium"
                  className={styles.codeField}
                  label="Insira o código de barras*"
                  mask={barCodeMask}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: field.value.length > 0 && (
                      <InputAdornment position="end">
                        <Buttons.IconButton
                          icon={<CloseOutlined />}
                          onClick={() => field.onChange('')}
                        />
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
            <Typography variant="label" className={styles.label}>
              Obrigatório*
            </Typography>

            <CashOutBillBottomBar
              disabled={!isValid}
              isLoading={isLoading}
              onBack={onBack}
            />
          </Form.FormBase>
        </Box>
      </>
    ) : (
      <CashOutBillReviewStep
        onNext={onSubmit}
        onBack={onBack}
        onEdit={onDueDateEdit}
        isLoading={isSending}
      />
    );

  return (
    <>
      <Header
        title={
          currentStep === 0
            ? 'Nova solicitação via Boleto'
            : 'Confirme os dados da solicitação via Boleto'
        }
      />

      <Box className={styles.stepper}>
        <Stepper activeStep={currentStep}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {getStepContent()}
    </>
  );
}
