import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import FakeItemActivities from './FakeItemActivities';

import styles from './styles.module.css';

function FakeActivities() {
  return (
    <Scrollbars className={styles.itens}>
      <FakeItemActivities />
      <FakeItemActivities />
      <FakeItemActivities />
      <FakeItemActivities />
      <FakeItemActivities />
      <FakeItemActivities />
      <FakeItemActivities />
    </Scrollbars>
  );
}

export default FakeActivities;
