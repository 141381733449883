import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { ReactComponent as SuccessAnimation } from '../../../../assets/successAnimation.svg';
import { ReactComponent as TedProcessing } from '../../../../assets/tedProcessing.svg';
import { ReactComponent as DefaultError } from '../../../../assets/default-error.svg';

import { styles } from './styles';

export default ({ status }) => {
  switch (status) {
    case 'Verificação em processamento':
      return (
        <Grid style={styles.subheader} item xs={12}>
          <TedProcessing />
          <Box ml={6} mt={-3}>
            <Typography style={styles.textBigger}>
              Verificação da TED em processamento
            </Typography>
            <Typography style={styles.textSmallSubheader}>
              Estamos analisando seu depósito e estando tudo
              <br /> certo o valor será creditado.
            </Typography>
          </Box>
        </Grid>
      );
    case 'Transferência Confirmada':
      return (
        <Grid style={styles.subheader} item xs={12}>
          <SuccessAnimation width={120} height={100} />
          <Box ml={6} mt={-3}>
            <Typography style={styles.textBigger}>
              Transferência verificada com sucesso!
            </Typography>
            <Typography style={styles.textSmallSubheader}>
              O valor já foi creditado.
            </Typography>
          </Box>
        </Grid>
      );
    case 'Verificação desfeita':
      return (
        <Grid style={styles.subheader} item xs={12}>
          <DefaultError width={120} height={100} />
          <Box ml={6} mt={-3}>
            <Typography style={styles.textBigger}>
              Erro na verificação
            </Typography>
            <Typography style={styles.textSmallSubheader}>
              Verifique os dados e tente novamente.
            </Typography>
          </Box>
        </Grid>
      );
    case 'Transferência não Confirmada':
      return (
        <Grid style={styles.subheader} item xs={12}>
          <DefaultError width={120} height={100} />
          <Box ml={6} mt={-3}>
            <Typography style={styles.textBigger}>
              Erro na verificação
            </Typography>
            <Typography style={styles.textSmallSubheader}>
              Verifique os dados e tente novamente.
            </Typography>
          </Box>
        </Grid>
      );
    default:
      return null;
  }
};
