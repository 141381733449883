import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import {
  Avatar,
  Box,
  Collapse,
  Hidden,
  Typography,
  useTheme,
} from '@material-ui/core';
import { VpnKeyOutlined } from '@material-ui/icons';

import { Header } from '../../../components/Header';
import Form from '../../../components/Form';
import BottomBar from '../../../components/BottomBar';
import Buttons from '../../../components/Buttons';
import PixKeysModals from '../components/Modals';

import { isMobile } from '../../../utils/breakpoints';
import useNewPixKey from './hooks/useNewPixKey';

import { useStyles } from './styles';

export default function NewPixKeyPage() {
  const styles = useStyles();
  const theme = useTheme();

  const {
    form: {
      errors,
      hasValidKeyType,
      control,
      isValid,
      isRandomKey,
      onBack,
      onValidate,
      onChangeKeyType,
      isValidating,
    },
    keyFieldState: { disabled, mask },
    keys,
    existentTypes,
  } = useNewPixKey({ styles });

  const renderKeyLabel = value => {
    const currentKey = keys.find(item => item.value === value);

    return (
      <>
        {currentKey.icon}
        <Typography className={styles.cardLabel} component="label">
          {currentKey.label}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Nova chave Pix',
        step: 0,
        route: '/pix/keys',
      }),
    );

    return () => sessionStorage.removeItem('useTopBar');
  }, []);

  return (
    <>
      <Hidden smDown>
        <Header title="Nova chave Pix" />
      </Hidden>
      <Box className={styles.root}>
        <Form.FormBase>
          <Controller
            name="keyType"
            control={control}
            render={({ field }) => (
              <Form.RadioCardOptions
                row
                label="Escolha o tipo de chave Pix para cadastrar"
                disabledTooltipMessageGeneral="Você já possui uma chave cadastrada desse tipo."
                disabledOptions={existentTypes}
                containerStyleProps={{
                  width: '60%',
                }}
                radioCardPaperStyleProps={{
                  flexFlow: 'column nowrap',
                  gap: theme.spacing(1),
                  width: isMobile ? 'calc(50% - 8px)' : '120px',
                  maxWidth: isMobile ? '50%' : '25%',
                  boxShadow: 'none',
                }}
                className={styles.radioCard}
                options={keys.map(({ label, value }) => ({ label, value }))}
                getOptionLabel={({ value }) => renderKeyLabel(value)}
                size="large"
                {...field}
                onChange={e => {
                  field.onChange(e);
                  onChangeKeyType(e);
                }}
              />
            )}
          />

          <Collapse in={hasValidKeyType}>
            {isRandomKey ? (
              <Box className={styles.randomKeyContainer}>
                <Avatar variant="circular" className={styles.avatar}>
                  <VpnKeyOutlined color="primary" />
                </Avatar>
                <Typography variant="body2">
                  Chave aleatória será gerada automaticamente
                </Typography>
              </Box>
            ) : (
              <Controller
                control={control}
                name="key"
                disabled={disabled}
                render={({ field }) => (
                  <Form.MaskedField
                    InputLabelProps={{ shrink: true }}
                    label="Digite a chave"
                    className={styles.keyField}
                    size="large"
                    mask={mask}
                    {...field}
                    error={!!errors?.key}
                    helperText={errors?.key?.message}
                  />
                )}
              />
            )}
          </Collapse>
        </Form.FormBase>
      </Box>
      <BottomBar>
        <Hidden smDown>
          <Buttons.SecondaryButton
            variant="outlined"
            color="primary"
            title="Voltar"
            onClick={onBack}
          />
        </Hidden>
        <Buttons.PrimaryButton
          title="Cadastrar chave"
          onClick={onValidate}
          disabled={!isValid}
          loading={isValidating}
        />
      </BottomBar>
      <PixKeysModals />
    </>
  );
}
