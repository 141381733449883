import React, { useState } from 'react';

import { TableContainer, Table, Paper } from '@material-ui/core';

import TableHead from './TableHead';
import TableBody from './TableBody';

import styles from './styles.module.css';

export default ({
  bodyData,
  count,
  pageSize,
  currentPage,
  sort,
  order,
  orderBy,
}) => {
  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead sort={sort} order={order} orderBy={orderBy} />
        <TableBody
          values={bodyData}
          pageSize={pageSize}
          currentPage={currentPage}
        />
      </Table>
    </TableContainer>
  );
};
