const situationPlan = JSON.parse(sessionStorage.getItem('pontoGoProduct'));
const datePlan = sessionStorage.getItem('pontoGoActivationDate');

const initialState = {
  data: {
    situationPointPlan: situationPlan,
    pontoGoActivationDate: datePlan,
  },
};

export default function situationPointPlan(state = initialState, action) {
  switch (action.type) {
    case 'SITUATION_POINT_PLAN':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
