import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/ArrowDownward';
import styles from './styles.module.css';
import { getFileDownload } from '../../utils/services';
import convertBytesToBlobDownloadXls from '../../../../../../../utils/convertBytesToBlobDownloadXls';
import { handleSnackbarStack } from '../../../../../../../utils/handleSnackbarStack';

function DownloadStep() {
  const handleDownload = useCallback(async () => {
    try {
      const { data } = await getFileDownload();

      convertBytesToBlobDownloadXls(data, 'model-cadastro-margens');

      handleSnackbarStack().success('Download do modelo feito com sucesso.');
    } catch {
      handleSnackbarStack().error(
        'Não foi possível fazer download do modelo, tente novamente.',
      );
    }
  }, []);

  return (
    <Grid className={styles.wrapper} container>
      <Grid item xs={12} md={9}>
        <Typography className={styles.title}>
          1. Comece fazendo o download do modelo da planilha
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} className={styles['justify-right']}>
        <Button
          startIcon={<CloudDownloadIcon />}
          className={styles['download-button']}
          onClick={handleDownload}
        >
          Baixar modelo
        </Button>
      </Grid>
    </Grid>
  );
}

export default DownloadStep;
