import React from 'react';

import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Chip,
} from '@material-ui/core';
import { useStyles, theme } from './styles';
import EnhancedTableHead from '../../../UI/EnhancedTableHead';
import { FakeTable } from '../../../../OnlinePointEmployees/UI/Table/UI';
import { ReactComponent as DeleteIcon } from '../../../../../assets/removeItem.svg';
import PurpleCheckbox from '../../../../../components/Checkbox/PurpleCheckbox';
import { handleStatus } from '../../../Utils/handleStatus';
import { handleEmployeeStatus } from '../../../Utils/handleEmployeeStatus';

const TableEmployeeRecharge = ({
  loading,
  pageSize,
  totalSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  handleRequestSort,
  rows,
  stableSort,
  getComparator,
  TablePaginationActions,
  handleOpenModalDeleteEmployee,
  previewSelected,
  setPreviewSelected,
}) => {
  const classes = useStyles();

  function handleClick(event, row) {
    const selectedIndex = previewSelected
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(previewSelected, {
        cpf: row.cpf,
        name: row.name,
        amount: row.amount,
        walletType: row.walletType,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(previewSelected.slice(1));
    } else if (selectedIndex === previewSelected.length - 1) {
      newSelected = newSelected.concat(previewSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        previewSelected.slice(0, selectedIndex),
        previewSelected.slice(selectedIndex + 1),
      );
    }
    setPreviewSelected(newSelected);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody>
            {!loading ? (
              stableSort(rows, getComparator).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell align="left">{row?.cpf}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={handleEmployeeStatus(row.status).label}
                        style={{
                          backgroundColor: handleEmployeeStatus(row.status)
                            .color,
                          color: '#FFFFFF',
                        }}
                      />
                    </TableCell>

                    <TableCell
                      align="right"
                      padding="checkbox"
                      className={classes.defaultCell}
                    >
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {row?.status === 'ACTIVE' ? (
                          <PurpleCheckbox
                            onClick={event => handleClick(event, row)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <FakeTable />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};

export default TableEmployeeRecharge;
