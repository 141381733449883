import React from 'react';

import { Typography } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import styles from './styles.module.css';

export default function FormProgress({
  checked,
  step,
  disabled
}) {
  return (
    <div item className={disabled ? styles.disabledItem : styles.item}>
      {checked ? 
      <CheckRoundedIcon className={styles.itemValue} /> : 
      <Typography className={styles.itemValue}>{step}</Typography>}
    </div>
  )
}
