import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box } from '@material-ui/core';
import { TemporaryDrawer } from '../../../../../../../components/DrawerV2';
import { ReprovedWithPendencies } from './Components/ReprovedWithPendencies';
import { ReprovedDefinitive } from './Components/ReprovedDefinitive';
import { Rejected } from './Components/Rejected';

export const EmployeesSituationInfoDrawer = NiceModal.create(() => {
  const [section, setSection] = useState('');

  const modal = useModal();

  function handleOpenSection(currentSection) {
    if (currentSection === section) {
      setSection('');
      return;
    }

    setSection(currentSection);
  }

  return (
    <TemporaryDrawer
      open={modal.visible}
      onClose={modal.hide}
      title="Situações dos funcionários"
      drawerWidth={400}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <ReprovedWithPendencies
          sectionOpen={section}
          handleOpenSection={handleOpenSection}
        />

        <ReprovedDefinitive
          sectionOpen={section}
          handleOpenSection={handleOpenSection}
        />

        <Rejected sectionOpen={section} handleOpenSection={handleOpenSection} />
      </Box>
    </TemporaryDrawer>
  );
});
