import { produce } from 'immer';

const INITIAL_STATE = {
  timeBegin:'',
  timeEnd:'',
  color: '#28a745',
  useBot: false
}

export default function page(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@config/ADD_CONFIG':
      return produce(state, draft => {
        draft.color = action.payload.color;
        draft.useBot = action.payload.useBot
      });
    default:
      return state;
  }
}
