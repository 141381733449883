import React from 'react';
import { Box, Typography } from '@material-ui/core';
import LottiesScreen from '../../../../../LottiesScreen';

export function Error() {
  return (
    <Box sx={{ p: '2rem 1rem' }}>
      <LottiesScreen.TableError />
      <Typography
        variant="h5"
        style={{ mt: '1.5rem', fontWeight: '700', textAlign: 'center' }}
      >
        {' '}
        Houve um erro inesperado
      </Typography>
    </Box>
  );
}
