import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsManualPayroll } from '../../../../../../../../redux/store/Payroll/ManualPayroll';

export function useEmployeesAproved() {
  const dispatch = useDispatch();
  const { employeesAproved, selectedRowsEmployees } = useSelector(
    state => state.payroll.manualPayroll,
  );

  const [hasMoreData, setHasMoreData] = useState(true);
  const [hasData, setHasData] = useState(false);

  function handleMoreSearchEmployeesAprovedData() {
    if (employeesAproved.perPage >= employeesAproved.length) {
      setHasMoreData(false);
    } else {
      dispatch(
        ActionsManualPayroll.setHandlePerPageChangeEmployeesAproved(
          employeesAproved.perPage + 10,
        ),
      );
      setHasData(true);
    }
  }

  const isEmployeeSelected = row =>
    selectedRowsEmployees?.some(employee => employee?.cpf === row);

  const newSelectedEmployee = row => {
    if (isEmployeeSelected(row?.cpf)) {
      return selectedRowsEmployees?.filter(
        employee => employee?.cpf !== row?.cpf,
      );
    }
    const newSelectedEmployeesArray = [...selectedRowsEmployees, row];

    return newSelectedEmployeesArray;
  };

  function handleSelectEmployee(row) {
    dispatch(
      ActionsManualPayroll.setSelectedRowsEmployees(newSelectedEmployee(row)),
    );
  }

  function handleSelectAllEmployeesAproved(event) {
    if (event.target.checked) {
      const allEmployeesAprovedMap = employeesAproved?.data?.map(
        employee => employee,
      );
      dispatch(
        ActionsManualPayroll.setSelectedRowsEmployees(allEmployeesAprovedMap),
      );

      return;
    }
    handleDeselectAllEmployeesAproved();
  }

  function handleDeselectAllEmployeesAproved() {
    dispatch(ActionsManualPayroll.setSelectedRowsEmployees([]));
  }

  useEffect(() => {
    // dispatch(ActionsManualPayroll.setHandlePerPageChangeEmployeesAproved(10));
    dispatch(ActionsManualPayroll.searchEmployees());
  }, [employeesAproved.perPage]);

  return {
    cardListObject: {
      data: employeesAproved?.data,
      length: employeesAproved.length,
      perPage: employeesAproved.perPage,
      isLoading: employeesAproved.isLoading,
      hasError: employeesAproved.errorOn.hasError,
      hasMoreData,
      hasData,
    },
    selectedRowsEmployees,
    isEmployeeSelected,
    handleSelectEmployee,
    handleSelectAllEmployeesAproved,
    handleDeselectAllEmployeesAproved,
    handleMoreSearchEmployeesAprovedData,
  };
}
