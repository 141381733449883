import React, { useEffect } from 'react';

import { TableBody, TableRow, TableCell } from '@material-ui/core';

import currencyFormatter from '../../../../../../utils/currencyFormatter';

import styles from '../styles.module.css';

export default ({ values, pageSize, currentPage }) => {
  return (
    <TableBody>
      {values
        .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
        .map(row => (
          <TableRow hover>
            <TableCell>{row.loanKey}</TableCell>
            <TableCell>{row.contractDate}</TableCell>
            <TableCell>{row.dueDate}</TableCell>
            <TableCell>
              {row.loanAmountToPay !== 0
                ? currencyFormatter(row.loanAmountToPay)
                : 'R$ 0,00'}
            </TableCell>
            <TableCell>
              {row.loanAmountRequested !== 0
                ? currencyFormatter(row.loanAmountRequested)
                : 'R$ 0,00'}
            </TableCell>
            <TableCell>
              {row.loanAmountPaid !== 0
                ? currencyFormatter(row.loanAmountPaid)
                : 'R$ 0,00'}
            </TableCell>
            <TableCell className={styles.statusCell}>{row.situation}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};
