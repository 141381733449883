/* eslint-disable class-methods-use-this */
import Pusher from 'pusher-js';

import { pusherKeys } from '../enums/pusher';

const { APP_KEY, APP_CLUSTER } = pusherKeys;

export class PusherJs {
  #pusherInstance;

  #channel;

  create() {
    return new Pusher(APP_KEY, {
      cluster: APP_CLUSTER,
    });
  }

  listen(channel, event, callback) {
    const currentInstance = this.create();
    const channelLinked = currentInstance
      .subscribe(channel)
      .bind(event, callback);

    this.#pusherInstance = currentInstance;
    this.#channel = channel;
    return channelLinked;
  }

  pop() {
    if (this.#pusherInstance) {
      this.#pusherInstance.unsubscribe(this.#channel);
    }
  }
}
