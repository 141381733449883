export function useDotStatus({ status = 'primary' }) {
  const STATUS_ENUM = {
    primary: '#2E2E2E',
    secondary: '#E54217',
    error: '#CA2317',
    info: '#2D54B9',
    success: '#19797F',
    warning: '#E39F17',
  };

  return STATUS_ENUM?.[status] ?? STATUS_ENUM?.primary;
}
