import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';

import LogoLoading from '../../../components/LogoLoading';
import ProgressToolBar from '../../../components/ProgressToolBar';

import TedDetails from './UI/TedDetails';
import ResponsiveTedDetails from './UI/ResponsiveTedDetails';

import currencyFormatter from '../../../utils/currencyFormatter';
import { isMobile } from '../../../utils/breakpoints';

import { api } from '../../../services/api';

import styles from './styles.module.css';

const TED = ({
  match: {
    params: { id },
  },
}) => {
  const [ted, setTed] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    api
      .get(`/cashIn/teds/id/${id}`)
      .then(({ data }) => setTed(data))
      .catch(({ response: { data: { errors } } }) => setErrors(errors))
      .finally(() => setLoading(false));
  }, []);

  function handleBack() {
    history.push('/cash-in');
  }

  if (loading) {
    return (
      <div className={styles.circularLoading}>
        <LogoLoading />
      </div>
    );
  }

  const error = errors.shift();

  if (error) {
    return (
      <div className={styles.contentStyle}>
        <div className={styles.approvalDivStyle}>
          <Grid className={styles.subheader} item xs={12}>
            <Typography className={styles.textOrangeBigger}>
              {error?.errorDetail}
            </Typography>
          </Grid>
        </div>
      </div>
    );
  }

  const {
    origin,
    destination,
    receipt,
    scheduleDate,
    amount,
    notes,
    creationDate,
    depositProcessedOn,
    creationUserName,
  } = ted;

  const scheduleDateFormatted = moment(scheduleDate).format('DD/MM/YYYY');
  const formatedCreationDate = `${moment(creationDate.slice(0, 10))?.format(
    'DD/MM/YYYY',
  )} às ${creationDate?.slice(11, 16)}`;
  const amountFormatted = currencyFormatter(amount);
  const originName = origin?.name || 'Não informado';
  const formatedDepositProcessedOn = `${moment(depositProcessedOn).format('DD/MM/YYYY') !== 'Data inválida'
      ? `${moment(depositProcessedOn).format('DD/MM/YYYY')} 'às' ${moment(
        depositProcessedOn,
      ).format('LT')}`
      : 'Não informado'
    }`;
  return (
    <div className={styles.contentStyle}>
      {isMobile ? (
        <ResponsiveTedDetails
          amountFormatted={amountFormatted}
          notes={notes}
          receipt={receipt}
          destination={destination}
          scheduleDateFormatted={scheduleDateFormatted}
          originName={originName}
          origin={origin}
          creationUserName={creationUserName}
          formatedCreationDate={formatedCreationDate}
          depositProcessedOn={formatedDepositProcessedOn}
          ted={ted}
          id={id}
        />
      ) : (
        <TedDetails
          amountFormatted={amountFormatted}
          notes={notes}
          receipt={receipt}
          destination={destination}
          scheduleDateFormatted={scheduleDateFormatted}
          originName={originName}
          origin={origin}
          creationUserName={creationUserName}
          depositProcessedOn={formatedDepositProcessedOn}
          ted={ted}
          formatedCreationDate={formatedCreationDate}
        />
      )}

      <ProgressToolBar
        disableNext
        hidden={isMobile}
        onBackClicked={handleBack}
      />
    </div>
  );
};

export default TED;
