import React from 'react';

import { Button, Typography } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import Tab from '../../../components/Tab';
import {
  PersonalForm,
  ProfessionalForm,
  AddressForm,
  ContactForm,
} from './Forms';

import styles from './styles.module.css';

export default function EditData({
  editMode,
  activeEditMode,
  personalDataForm,
  setPersonalDataForm,
  professionalDataForm,
  setProfessionalDataForm,
  salary,
  setSalary,
  addressDataForm,
  setAddressDataForm,
  contactDataForm,
  setContactDataForm,
  loading,
  setOnSubmitForm,
  onSubmitForm,
  handleExpandedTab,
  expandedTabs,
  requestLoading,
  textFieldFocused,
  setTextFieldFocused,
  locations,
  setLocations,
  stockings,
  setStockings,
  contracts,
  setContracts,
}) {
  return (
    <div className={styles.editDataContainer}>
      <div className={styles.editDataHead}>
        <Button
          className={styles.editDataHeadButton}
          onClick={activeEditMode}
          disabled={requestLoading}
        >
          <EditRoundedIcon className={styles.editDataHeadButtonIcon} />
          Editar dados
        </Button>
      </div>

      <Tab
        summary={
          <Typography className={styles.tabTitle}>Dados Pessoais</Typography>
        }
        details={
          <PersonalForm
            disabled={!editMode || requestLoading}
            loading={loading}
            personalDataForm={personalDataForm}
            setPersonalDataForm={setPersonalDataForm}
            setOnSubmitForm={setOnSubmitForm}
            onSubmitForm={onSubmitForm}
            editMode={editMode}
            textFieldFocused={textFieldFocused}
            setTextFieldFocused={setTextFieldFocused}
          />
        }
        onChange={() => handleExpandedTab('personalData')}
        expanded={expandedTabs.includes('personalData')}
      />

      <Tab
        summary={
          <Typography className={styles.tabTitle}>
            Dados Profissionais
          </Typography>
        }
        details={
          <ProfessionalForm
            disabled={!editMode || requestLoading}
            loading={loading}
            professionalDataForm={professionalDataForm}
            setProfessionalDataForm={setProfessionalDataForm}
            salary={salary}
            setSalary={setSalary}
            setOnSubmitForm={setOnSubmitForm}
            onSubmitForm={onSubmitForm}
            editMode={editMode}
            textFieldFocused={textFieldFocused}
            setTextFieldFocused={setTextFieldFocused}
            locations={locations}
            setLocations={setLocations}
            stockings={stockings}
            setStockings={setStockings}
            contracts={contracts}
            setContracts={setContracts}
          />
        }
        onChange={() => handleExpandedTab('professionalData')}
        expanded={expandedTabs.includes('professionalData')}
      />

      <Tab
        summary={<Typography className={styles.tabTitle}>Endereço</Typography>}
        details={
          <AddressForm
            disabled={!editMode || requestLoading}
            loading={loading}
            addressDataForm={addressDataForm}
            setAddressDataForm={setAddressDataForm}
            textFieldFocused={textFieldFocused}
            setTextFieldFocused={setTextFieldFocused}
          />
        }
        onChange={() => handleExpandedTab('addressData')}
        expanded={expandedTabs.includes('addressData')}
      />

      <Tab
        summary={<Typography className={styles.tabTitle}>Contato</Typography>}
        details={
          <ContactForm
            disabled={!editMode || requestLoading}
            loading={loading}
            contactDataForm={contactDataForm}
            setContactDataForm={setContactDataForm}
            setOnSubmitForm={setOnSubmitForm}
            onSubmitForm={onSubmitForm}
            textFieldFocused={textFieldFocused}
            setTextFieldFocused={setTextFieldFocused}
          />
        }
        onChange={() => handleExpandedTab('contactData')}
        expanded={expandedTabs.includes('contactData')}
      />
    </div>
  );
}
