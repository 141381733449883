import React from 'react';

import { Button, CircularProgress, Typography } from '@material-ui/core';

import styles from './styles.module.css';

function CarouselCard({
  description,
  icon,
  title,
  lastCard,
  onClick,
  loading,
}) {
  return (
    <div className={styles.card}>
      {icon}
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.description}>{description}</Typography>
      {lastCard && (
        <Button className={styles.button} onClick={onClick}>
          {loading ? (
            <CircularProgress className={styles.circularLoading} />
          ) : (
            'Pronto'
          )}
        </Button>
      )}
    </div>
  );
}

export default CarouselCard;
