import React from 'react';

import { Button, Hidden, Typography } from '@material-ui/core';

import CustomDatePicker from '../../components/DatePicker';

import styles from './styles.module.css';

export default function Filters({
  activeButton,
  initialDate,
  finalDate,
  onButtonFilterClicked,
  onChangeInitialDate,
  onChangeFinalDate,
  validateInitialDateInfo,
  validateFinalDateInfo,
}) {
  return (
    <div className={styles.filters}>
      <div>
        <Button
          className={
            activeButton === 3 ? styles.buttonFilterActive : styles.buttonFilter
          }
          onClick={() => onButtonFilterClicked(3)}
        >
          3 DIAS
        </Button>
        <Button
          className={
            activeButton === 7 ? styles.buttonFilterActive : styles.buttonFilter
          }
          onClick={() => onButtonFilterClicked(7)}
        >
          7 DIAS
        </Button>
        <Button
          className={
            activeButton === 15
              ? styles.buttonFilterActive
              : styles.buttonFilter
          }
          onClick={() => onButtonFilterClicked(15)}
        >
          15 DIAS
        </Button>
        <Button
          className={
            activeButton === 30
              ? styles.buttonFilterActive
              : styles.buttonFilter
          }
          onClick={() => onButtonFilterClicked(30)}
        >
          30 DIAS
        </Button>
      </div>

      <div className={styles.dateContainer}>
        <div className={styles.dateFilter}>
          <CustomDatePicker
            value={initialDate}
            onChange={onChangeInitialDate}
            label={initialDate ? 'Data inicial' : null}
            placeholder="Data inicial"
            height={5}
            error={validateInitialDateInfo}
            disableCalendarIcon={validateInitialDateInfo}
          />
          <Hidden smDown>
            {validateInitialDateInfo && (
              <Typography className={styles.dateError}>
                *Data inválida
              </Typography>
            )}
          </Hidden>
        </div>

        <span className={styles.dateLabel}>até</span>

        <div className={styles.dateFilter}>
          <CustomDatePicker
            value={finalDate}
            onChange={onChangeFinalDate}
            label={finalDate ? 'Data final' : null}
            placeholder="Data final"
            height={5}
            error={validateFinalDateInfo}
            disableCalendarIcon={validateFinalDateInfo}
          />
          <Hidden smDown>
            {validateFinalDateInfo && (
              <Typography className={styles.dateError}>
                *Data inválida
              </Typography>
            )}
          </Hidden>
        </div>
      </div>
    </div>
  );
}
