import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';
import { reduxApi } from '../services/reduxApi';

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    localStorage.clear();
    sessionStorage.removeItem('persist:manualPayroll');

    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    reduxApi.reducerPath,
    'pointOnline',
    'employeePaymentsQrCodeInformation',
    'payroll',
  ],
  whitelist: ['account', 'balance', 'companies'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(reduxApi.middleware),
});

export const persistor = persistStore(store);
export default store;
