import React from 'react';
import { TextField } from '@material-ui/core';
import { useCurrencyField } from './Hooks/useCurrencyField';

export function CurrencyField({
  fullWidth = true,
  name = '',
  label = '',
  size = 'small',
  variant = 'outlined',
  disabled = false,
  value = 0,
  onChange = () => {},
  ...otherProps
}) {
  const { currencyStringValue, setCurrencyStringValue } = useCurrencyField(
    value,
    onChange,
  );

  return (
    <TextField
      fullWidth={fullWidth}
      name={name}
      label={label}
      size={size}
      variant={variant}
      disabled={disabled}
      value={`R$ ${value?.toFixed(2).replace('.', ',')}` ?? currencyStringValue}
      onChange={event => setCurrencyStringValue(event.target.value)}
      InputProps={{ style: { borderRadius: '0.5rem' } }}
      {...otherProps}
    />
  );
}
