import React from 'react';

import MobileListCard from '../../../components/MobileListCard';
import EmptyList from '../../../components/EmptyList';

import { ReactComponent as FileIcon } from '../../../assets/file-icon.svg';

function MobileList({ goToDetails, goToReview, paychecksList }) {
  if (paychecksList.length === 0) {
    return (
      <EmptyList
        icon={<FileIcon />}
        title="A lista com os arquivos de contra cheques importados aparecerá aqui"
      />
    )
  };

  return (
    paychecksList.map(item => (
      <MobileListCard
        title={`Identificador: ${item.id}`}
        text={item.competency}
        description={item.paymentType}
        info={
          item.paycheckCount <= 1 ? 
          `${item.paycheckCount} funcionário` : 
          `${item.paycheckCount} funcionários`
        }
        canToReview={item.canToReview}
        onClickIcon={() => {
          if(item.canToReview)
            goToReview(item)
          else
            goToDetails(item)
        }}
        onClickReviewIcon={() => goToReview(item)}
      />
    ))
  );
}

export default MobileList;
