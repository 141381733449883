import { useCallback, useEffect, useState } from 'react';
import { api } from '../../../../services/api';

export const getLoans = async (params = {}) => {
  return api.get('/consigned/companies/search', {
    params,
  });
};

export const getLoanStatus = async (params = {}) => {
  return api.get('/consigned/companies/proposes/statuses');
};

export const getLoan = async id => {
  return api.get(`/consigned/companies/loan/${id}`);
};

export const getLoanHistory = async id => {
  return api.get(`/consigned/companies/history/${id}`);
};

export const editLoan = async (id, body = {}) => {
  return api.post('/consigned/companies/updateContract', {
    idLoan: id,
    ...body,
  });
};

export const updateStatus = async ({ id, status, reasonUpdate }) => {
  return api.post('/consigned/companies/update', {
    idLoan: id,
    status,
    reasonUpdate,
  });
};

export const updateLoan = async ({ id, status, reasonUpdate }) => {
  return api.patch('/consigned/companies/update', {
    idLoan: id,
    status,
    reasonUpdate,
  });
};

export const useGetLoanById = id => {
  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await getLoan(id);

      setData(data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id, getData]);

  return {
    isLoading,
    isError,
    data,
  };
};

export const useGetLoanHistoryById = id => {
  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await getLoanHistory(id);

      setData(data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id, getData]);

  return {
    isLoading,
    isError,
    data,
  };
};

export const useGetLoanStatus = () => {
  const [data, setData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await getLoanStatus();

      setData(data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    isLoading,
    isError,
    data,
  };
};
