import React from 'react';

import { CircularProgress, Box, Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default ({ value }) => {
  return (
    <Box className={styles.container}>
      <CircularProgress variant="determinate" value={value} />
      <Box className={styles.percentageContainer}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
