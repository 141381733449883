import React from 'react';

import {
    Typography,
    Paper,
    Button,
    ListItem,
    ListItemText,
  } from '@material-ui/core';
  
import Skeleton from 'react-loading-skeleton';

import styles from './styles';

export default function FakeMenu() {
    return (
        <>
            <Paper style={styles.paper}>
              <Button>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography style={styles.primaryTextSTyle}>
                        <Skeleton
                          width={400}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                    secondary={
                      <Typography style={styles.secondaryTextStyle}>
                        <Skeleton
                          width={900}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                  />
                </ListItem>
              </Button>
            </Paper>

            <Paper style={styles.paper}>
              <Button>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography style={styles.primaryTextSTyle}>
                        <Skeleton
                          width={400}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                    secondary={
                      <Typography style={styles.secondaryTextStyle}>
                        <Skeleton
                          width={900}
                          height={23}
                          color="#202020"
                          highlightColor="#fff"
                        />
                      </Typography>
                    }
                  />
                </ListItem>
              </Button>
            </Paper>
        </>
    )
}
