import React from 'react';
import styles from './styles.module.css';
import {
  Dialog,
  Typography
} from '@material-ui/core';

import DefaultGif from '../../assets/escolha-emp.gif';

function WithoutAccessModal({
  open,
  onClose,
  title,
  text,
  image
}) {
  return (
    <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='xs'
        BackdropProps={{style: { marginTop: 64 }}}
        style={{ marginTop: 64 }}
    >
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.text}>{text}</Typography>
        <div className={styles.containerGif}>
          <div className={styles.line} />
          <img src={image || DefaultGif} className={styles.gif} alt="Chose company" />
        </div>
      </div>
    </Dialog>
  );
}

export default WithoutAccessModal;
