import React, { createContext, useCallback, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Header from './UI/Header/Header';
import { useProcessResult } from './utils/hooks';
import FileInfo from './UI/FileInfo';
import ProgressToolBar from '../../../../../components/ProgressToolBar';
import SuccessAccordion from './UI/SuccessAccordion';
import ErrorAccordion from './UI/ErrorAccordion';

export const ConsignedMarginsBatchResultContext = createContext({});

function ConsignedMarginsBatchResult() {
  const history = useHistory();

  const { processId } = useParams();

  const { data, isLoading } = useProcessResult(processId);

  const goBack = useCallback(() => {
    history.replace('/consigned/margins/batch');
  }, [history]);

  const onNext = useCallback(async () => {
    history.replace('/consigned/margins');
  }, [data, processId, goBack]);

  return (
    <ConsignedMarginsBatchResultContext.Provider value={{ data, isLoading }}>
      <Header />
      <FileInfo />
      <SuccessAccordion />
      <ErrorAccordion />
      <ProgressToolBar
        nextButtonText="Gerenciar margens"
        backButtonText="Reenviar arquivo"
        onBackClicked={goBack}
        onNextClicked={onNext}
        disabled={isLoading}
        loading={isLoading}
      />
      <Box sx={{ height: 56 }} />
    </ConsignedMarginsBatchResultContext.Provider>
  );
}

export default ConsignedMarginsBatchResult;
