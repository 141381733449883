import { enqueueSnackbar } from 'notistack';

export function handleSnackbarStack(preventDuplicate = false) {
  const snackbarConfig = {
    preventDuplicate,
    anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
    autoHideDuration: 4000,
  };

  function success(message) {
    enqueueSnackbar(message, { ...snackbarConfig, variant: 'success' });
  }

  function error(message) {
    enqueueSnackbar(message, { ...snackbarConfig, variant: 'error' });
  }

  function info(message) {
    enqueueSnackbar(message, { ...snackbarConfig, variant: 'info' });
  }

  function warning(message) {
    enqueueSnackbar(message, { ...snackbarConfig, variant: 'warning' });
  }

  return {
    success,
    error,
    info,
    warning,
  };
}
