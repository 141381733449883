import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { TableRow, TableCell } from '@material-ui/core';

export default function FakeTable() {
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
        <TableCell align="left">
          <Skeleton width={80} color="#202020" highlightColor="#444" />
        </TableCell>
      </TableRow>
    </>
  );
}
