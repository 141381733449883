import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    root: {
      display: 'inline-flex',
      gap: '10px',
      alignItems: 'center',
    },
    cell: {
      padding: '1px 6px',
      borderRadius: '4px',
    },
  }),
);
