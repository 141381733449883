import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import FormPassword from './Form';
import Success from './Success';
import FirstAccessFaceAuth from '../FaceAuth';

import styles from './styles.module.css';

import { api } from '../../../services/api';
import { encrypt } from '../../../utils/security';

export default function Password({
  match: {
    params: { id },
  },
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const history = useHistory();

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleShowSecondPassword() {
    setShowSecondPassword(!showSecondPassword);
  }

  function createPassword(e) {
    setLoading(true);

    const body = {
      validationToken: id,
      newPassword: encrypt(e.password),
    };

    api
      .post(`/users/createPassword`, body)
      .then(() => setShowSuccess(true))
      .finally(() => {
        setLoading(false);
      });
  }

  function goToBack() {
    history.push(`/password-instructions/${id}`);
  }

  useEffect(() => {
    NiceModal.show('first-access-auth', { validationToken: id });
  }, []);

  return (
    <>
      <FirstAccessFaceAuth />
      <div className={styles.container}>
        {showSuccess ? (
          <Success />
        ) : (
          <FormPassword
            goToBack={goToBack}
            createPassword={createPassword}
            handleShowPassword={handleShowPassword}
            handleShowSecondPassword={handleShowSecondPassword}
            showPassword={showPassword}
            showSecondPassword={showSecondPassword}
            loading={loading}
          />
        )}
      </div>
    </>
  );
}
