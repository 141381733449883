import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function ResponsiveFakeCards({ isMoreThanOneAccount }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.row}>
          <Skeleton
            width={150}
            height={18}
            color="#202020"
            highlightColor="#444"
          />
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <Typography className={styles.label}>NSU</Typography>
            <Skeleton
              width={70}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          {isMoreThanOneAccount && (
            <div className={styles.block}>
              <Typography className={styles.label}>Conta</Typography>
              <Skeleton
                width={70}
                height={18}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          )}

          <div className={styles.block}>
            <Typography className={styles.label}>Tipo</Typography>
            <Skeleton
              width={70}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <Typography className={styles.label}>Agendada para</Typography>
            <Skeleton
              width={150}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <Typography className={styles.label}>Nome</Typography>
            <Skeleton
              width={150}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <Typography className={styles.label}>Criação</Typography>
            <Skeleton
              width={100}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={styles.block}>
            <Typography className={styles.label}>Autorização</Typography>
            <Skeleton
              width={100}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.rowTitle}>
          <Typography className={styles.title}>Pagamento</Typography>

          <Skeleton
            width={120}
            height={18}
            color="#202020"
            highlightColor="#444"
          />
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <Typography className={styles.label}>Total</Typography>
            <Skeleton
              width={70}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <Typography className={styles.label}>Folha</Typography>
            <Skeleton
              width={70}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={styles.block}>
            <Typography className={styles.label}>Tarifa</Typography>
            <Skeleton
              width={70}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={styles.block}>
            <Typography className={styles.label}>L. imediata</Typography>
            <Skeleton
              width={70}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>
    </>
  );
}
