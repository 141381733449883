import React from 'react';
import { Header } from '../../../../components/Header';
import { Table } from '../../../../components/Table';
import { useRegisterFilesGeneral } from './Hooks/useRegisterFilesGeneral';
import { useTableConfig } from './Hooks/useTableConfig';
import { Filter } from '../../../../components/Filter';
import Form from '../../../../components/Form';

export default function List() {
  const { filterObject, tableObject } = useRegisterFilesGeneral();
  const tableConfig = useTableConfig();

  return (
    <>
      <Header title="Arquivos de cadastro" />

      <Filter
        defaultValues={filterObject.filterInitialValues}
        validation={filterObject.filterValidation}
        handleRequests={filterObject.handleFilterChange}
      >
        <Form.MaskedField
          name="cpf"
          mask="000.000.000-00"
          label="Pesquisar por CPF"
        />
        <Form.DatePicker name="initialDate" label="Data inicial*" />
        <Form.DatePicker name="finalDate" label="Data final*" />
      </Filter>

      <Table
        data={tableObject.data}
        hasError={tableObject.hasError}
        isLoading={tableObject.isLoading}
        tableConfig={tableConfig}
        length={tableObject.length}
        rowsPerPageOptions={tableObject.rowsPerPageOptions}
        requests={tableObject.paginationRequests}
      />
    </>
  );
}
