import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectEnhanced } from '../../../../../hooks/useEffectEnhanced';

export function useDesktopMode({
  fields,
  defaultValues = {},
  validation = null,
}) {
  const [isViewingMoreFields, setIsViewingMoreFields] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: validation ? yupResolver(validation) : undefined,
  });

  const hasMandatoryFields = validation?.fields
    ? Object.values(validation?.fields)?.some(
        config => !config?.specs?.optative,
      )
    : false;
  const isClearFieldsDisabled = !isDirty;

  function handleMainFields() {
    if (!Array.isArray(fields)) {
      return [fields];
    }

    if (fields?.length <= 4) {
      return fields;
    }

    return fields?.slice(0, 4);
  }

  function handleExtraFields() {
    if (!Array.isArray(fields) || fields?.length <= 4) {
      return [];
    }

    return fields?.slice(4);
  }

  useEffectEnhanced(
    () => {
      document
        .getElementById('filter-form')
        .dispatchEvent(new Event('submit', { cancelable: true }));
    },
    [watch()],
    true,
  );

  return {
    filterComponentObject: {
      mainFields: handleMainFields(),
      extraFields: handleExtraFields(),
      isClearFieldsDisabled,
      isViewingMoreFields,
      hasMandatoryFields,
      handleClearFields: () => reset(defaultValues),
      handleIsViewingMoreFields: () =>
        setIsViewingMoreFields(prevState => !prevState),
    },
    form: {
      control,
      handleSubmit,
    },
  };
}
