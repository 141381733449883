import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  accordionStyle: {
    border: 'none',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)'
  },
})

export default function Tab({
  summary,
  details,
  style,
  expanded,
  onChange
}) {
  const classes = useStyles();
  
  return (
    <div style={{ marginBottom: 10 || style }}>
      <Accordion 
        className={classes.accordionStyle} 
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {summary}
        </AccordionSummary>

        <AccordionDetails>
          {details}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
