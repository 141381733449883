/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../services/api';

const initialState = {
  qrCodeInformation: {
    data: [],
    isLoading: false,
    errorOnQrCodeInformation: {
      hasError: false,
      message: '',
    },
  },
};

const getEmployeePaymentsQrCodeInformation = createAsyncThunk(
  'employeePaymentsQrCodeInformation/get',
  async (
    { nsuAndLineIdEncrypted },
    { getState, dispatch, rejectWithValue },
  ) => {
    const requestOptions = {
      headers: {
        nsuAndLineIdEncrypted,
      },
    };

    return api
      .get(`employeesPayments/QRCode/information`, requestOptions)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue({ error: error.response.data.errors[0].errorDetail }),
      );
  },
);

const employeePaymentsQrCodeInformationSlice = createSlice({
  name: 'employeePaymentsQrCodeInformation',
  initialState,
  reducers: {
    cleanup: state => {
      Object.keys(initialState).forEach(item => {
        state[item] = initialState[item];
      });
    },
  },

  extraReducers: {
    [getEmployeePaymentsQrCodeInformation.pending]: state => {
      state.qrCodeInformation.isLoading = true;
      state.qrCodeInformation.errorOnQrCodeInformation = {
        hasError: false,
        message: '',
      };
    },
    [getEmployeePaymentsQrCodeInformation.fulfilled]: (state, { payload }) => {
      state.qrCodeInformation.isLoading = false;
      state.qrCodeInformation.data = payload.data;
    },
    [getEmployeePaymentsQrCodeInformation.rejected]: (state, { payload }) => {
      state.qrCodeInformation.isLoading = false;
      state.qrCodeInformation.errorOnQrCodeInformation.hasError = true;

      if (!payload) {
        state.qrCodeInformation.errorOnQrCodeInformation.message =
          'Oops! Houve um erro ao buscar as informações do comprovante';
      } else {
        state.qrCodeInformation.errorOnQrCodeInformation.message =
          payload.error;
      }
    },
  },
});

const employeePaymentsQrCodeInformationReducer =
  employeePaymentsQrCodeInformationSlice.reducer;
const ActionsEmployeePaymentQrCodeInformation = {
  ...employeePaymentsQrCodeInformationSlice.actions,
  getEmployeePaymentsQrCodeInformation,
};

export {
  employeePaymentsQrCodeInformationReducer,
  ActionsEmployeePaymentQrCodeInformation,
};
