import React from 'react';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  AttachMoneyOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { ReactComponent as PixIcon } from '../../../../../../assets/pix.svg';
import { ReactComponent as TransferIcon } from '../../../../../../assets/transfer-icon-mobile.svg';

import currencyFormatter from '../../../../../../utils/currencyFormatter';

import { requestTypeOptions, statusMap } from '../../../constants';
import { useStyles } from '../styles';

export default function DrawerRequestTab({ selected, status }) {
  const styles = useStyles();

  const ICON_MAP = [
    { type: 'PIX', icon: <PixIcon aria-description="Ícone de Pix" /> },
    {
      type: 'INTERNAL_TRANSFER',
      icon: <TransferIcon aria-description="Ícone de transferência Somapay" />,
    },
    {
      type: 'BILL_PAYMENT',
      icon: <TransferIcon aria-description="Ícone de Boleto" />,
    },
  ];

  const renderStatus = () => {
    const { label, color, background } = statusMap[selected.status];

    return (
      <Chip
        style={{ backgroundColor: background, color }}
        size="small"
        variant="default"
        status="default"
        label={label}
      />
    );
  };

  const renderDate = date => (date ? moment(date).format('DD/MM/YYYY') : '-');
  const renderTime = date => (date ? moment(date).format('HH:mm') : '-');

  const renderTransferType = requestTypeOptions.find(
    type => type.value === selected.transferType,
  ).label;

  const renderCaption = authorizer => {
    if (status === 'CANCELED') {
      return (
        <Typography
          className={styles.authorizerReprovedCaption}
          variant="caption"
        >
          Reprovou
          <CloseOutlined className={styles.authorizerIcon} />
        </Typography>
      );
    }

    return (
      <Typography variant="caption">
        <b className={styles.authorizerApprovedCaption}>
          Autorizou
          <CheckOutlined className={styles.authorizerIcon} />
        </b>{' '}
        &#183; Data da autorização:{' '}
        {moment(authorizer.approveDate).format('DD/MM/YYYY [às] HH:mm')}
      </Typography>
    );
  };

  return (
    <Box>
      {selected.status === 'ERROR' && !!selected.errorReason && (
        <Alert className={styles.errorReason} severity="error">
          {selected.errorReason}
        </Alert>
      )}

      <Card className={styles.valueCard} elevation={0}>
        <Box className={styles.avatarBox}>
          <Avatar className={styles.avatar}>
            <AttachMoneyOutlined color="primary" />
          </Avatar>
          <Box>
            <Typography className={styles.labelText} variant="h4">
              Valor
            </Typography>
            <Typography className={styles.amountText} variant="body2">
              {currencyFormatter(selected?.amount) || '-'}
            </Typography>
          </Box>
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.statusRow}>
          <Typography className={styles.labelText} variant="h4">
            Tipo de solicitação
          </Typography>
          <Typography className={styles.pixText}>
            {ICON_MAP.find(entry => entry.type === selected?.transferType).icon}
            {renderTransferType}
          </Typography>
        </Box>
        <Box className={styles.statusRow}>
          <Typography className={styles.labelText} variant="h4">
            Situação
          </Typography>
          {renderStatus()}
        </Box>
        <Box className={styles.statusRow}>
          <Typography className={styles.labelText} variant="h4">
            Solicitado por
          </Typography>
          <Typography>{selected?.creationUser || '-'}</Typography>
        </Box>
      </Card>
      <Divider className={styles.divider} />
      <Grid container className={styles.dateContainer}>
        <Grid item xs={4}>
          <Typography className={styles.labelText} variant="h4">
            Data de solicitação
          </Typography>
          <Typography variant="body2" className={styles.dateText}>
            {selected?.creationDate ? (
              <>
                {renderDate(selected?.creationDate)}
                <br />
                {renderTime(selected?.creationDate)}
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
        {status === 'APPROVED' && (
          <>
            <Grid item xs={4}>
              <Typography className={styles.labelText} variant="h4">
                Data de aprovação
              </Typography>
              <Typography variant="body2" className={styles.dateText}>
                {selected?.approvedDate ? (
                  <>
                    {renderDate(selected?.approvedDate)}
                    <br />
                    {renderTime(selected?.approvedDate)}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.labelText} variant="h4">
                Data de efetivação
              </Typography>
              <Typography variant="body2" className={styles.dateText}>
                {selected?.releaseDate ? (
                  <>
                    {renderDate(selected?.releaseDate)}
                    <br />
                    {renderTime(selected?.releaseDate)}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
          </>
        )}

        {status === 'CANCELED' && (
          <Grid item xs={4}>
            <Typography className={styles.labelText} variant="h4">
              Data de reprovação
            </Typography>
            <Typography className={styles.dateText}>
              {selected?.cancelDate ? (
                <>
                  {renderDate(selected?.cancelDate)}
                  <br />
                  {renderTime(selected?.cancelDate)}
                </>
              ) : (
                '-'
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider className={styles.divider} />
      <Box className={styles.authorizers}>
        <Box className={styles.authorizersTitle}>
          <Typography className={styles.authorizersHeading} variant="h4">
            {status === 'CANCELED'
              ? 'Autorizador que reprovou'
              : 'Autorizadores'}
          </Typography>
          {status !== 'CANCELED' && (
            <Typography className={styles.authorizersChip} variant="caption">
              {selected?.authorizers?.length}
            </Typography>
          )}
        </Box>
        {status !== 'CANCELED' ? (
          <Grid container className={styles.authorizersContainer}>
            {selected.authorizers &&
              selected.authorizers?.map(authorizer => (
                <Grid
                  item
                  key={authorizer.name}
                  className={styles.authorizerEntry}
                >
                  <Avatar>{authorizer.name?.charAt(0)}</Avatar>
                  <Box className={styles.authorizerEntryText}>
                    <Typography
                      variant="body2"
                      className={styles.authorizerName}
                    >
                      {authorizer.name}
                    </Typography>
                    {renderCaption(authorizer)}
                  </Box>
                </Grid>
              ))}
          </Grid>
        ) : (
          <Grid container className={styles.authorizersContainer}>
            <Grid item className={styles.authorizerEntry}>
              <Avatar>{selected.cancelUser?.charAt(0)}</Avatar>
              <Box className={styles.authorizerEntryText}>
                <Typography variant="body2" className={styles.authorizerName}>
                  {selected.cancelUser}
                </Typography>
                {renderCaption(selected)}
              </Box>
            </Grid>
            <Box className={styles.cancelContainer}>
              <Typography variant="h5" className={styles.cancelTitle}>
                Motivo da reprovação
              </Typography>
              <Typography variant="body2" className={styles.cancelReason}>
                {selected?.cancelReason}
              </Typography>
            </Box>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
