import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../../../../../components/Form';
import CashOutPixBottomBar from '../../components/BottomBar';
import { PixContext } from '../../components/Context';

import validations from './validations';

export default function AccountAgencyTab() {
  const {
    isLoading,
    onNext,
    bankOptions,
    defaultValues,
    accountTypeOptions,
  } = useContext(PixContext);

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues,
  });

  const onSubmit = (form, event) => {
    event.preventDefault();
    onNext({ ...form, bank: parseInt(form.bank), keyType: '' });
  };

  return (
    <Form.FormBase onSubmit={() => handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="bank"
            render={({ field }) => (
              <Form.AutocompleteField
                options={bankOptions}
                getOptionLabel={e => e?.name}
                getOptionValue={e => e?.code}
                size="large"
                label="Instituição bancária*"
                error={Boolean(errors?.bank)}
                helperText={errors?.bank?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="accountType"
            render={({ field }) => (
              <Form.AutocompleteField
                options={accountTypeOptions}
                size="large"
                label="Tipo de conta*"
                error={Boolean(errors?.accountType)}
                helperText={errors?.accountType?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="recipientName"
            render={({ field }) => (
              <Form.TextField
                size="large"
                label="Nome do recebedor*"
                error={Boolean(errors?.recipientName)}
                helperText={errors?.recipientName?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="agency"
            render={({ field }) => (
              <Form.MaskedField
                size="large"
                label="Agência e dígito*"
                error={Boolean(errors?.agency)}
                helperText={errors?.agency?.message}
                mask={[{ mask: '000' }, { mask: '0000' }, { mask: '0000-0' }]}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="account"
            render={({ field }) => (
              <Form.MaskedField
                size="large"
                label="Conta e dígito*"
                error={Boolean(errors?.account)}
                helperText={errors?.account?.message}
                mask={[
                  { mask: '0000-0' },
                  { mask: '00000-0' },
                  { mask: '000000-0' },
                  { mask: '0000000-0' },
                  { mask: '00000000-0' },
                  { mask: '00000000000-0' },
                ]}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="cpfCnpj"
            render={({ field }) => (
              <Form.MaskedField
                size="large"
                label="CPF/CNPJ*"
                error={Boolean(errors?.cpfCnpj)}
                helperText={errors?.cpfCnpj?.message}
                mask={[
                  { mask: '000.000.000-00' },
                  { mask: '00.000.000/0000-00' },
                ]}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <Form.CurrencyField
                size="large"
                label="Valor do Pix*"
                error={Boolean(errors?.value)}
                helperText={errors?.value?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Form.TextField
                size="large"
                label="Descrição"
                error={Boolean(errors?.description)}
                helperText={errors?.description?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CashOutPixBottomBar disabled={!isValid} isLoading={isLoading} />
    </Form.FormBase>
  );
}
