import React, { useEffect } from 'react';
import moment from 'moment';

import { Grid, Button, FormControl } from '@material-ui/core';

import CustomDatePicker from '../../components/DatePicker';

import styles from './styles.module.css';

function ContentFilterDrawer({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  setIsFilteredFinalDate,
  setOpenDrawer,
  drawerFirstDate,
  drawerSecondDate,
  setDrawerFirstDate,
  setDrawerSecondDate,
}) {
  const DISABLED_BUTTON = Boolean(!drawerFirstDate || !drawerSecondDate);

  useEffect(() => {
    if (!initialDate || !finalDate) {
      setDrawerFirstDate('');
      setDrawerSecondDate('');
      setIsFilteredFinalDate(false);
    }
  }, []);

  function handleClickFilter() {
    setFilteredValues();
    setOpenDrawer(false);
    setIsFilteredFinalDate(true);
  }

  function setFilteredValues() {
    setInitialDate(drawerFirstDate);
    setFinalDate(drawerSecondDate);
  }

  function onChangeInitialDate(value) {
    setDrawerFirstDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );
  }

  function onChangeFinalDate(value) {
    setDrawerSecondDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );
  }

  return (
    <Grid direction="column" className={styles.drawerContent}>
      <Grid item className={styles.period}>
        <p> Filtrar período</p>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <CustomDatePicker
            value={drawerFirstDate}
            onChange={onChangeInitialDate}
            label={drawerFirstDate ? 'Data inicial' : null}
            placeholder="Data inicial"
          />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl variant="outlined" className={styles.selectFilter}>
          <CustomDatePicker
            value={drawerSecondDate}
            onChange={onChangeFinalDate}
            label={drawerSecondDate ? 'Data final' : null}
            placeholder="Data final"
          />
        </FormControl>
      </Grid>

      <Grid item className={styles.applyFiltersContainer}>
        <Button
          onClick={handleClickFilter}
          className={styles.applyFiltersButton}
          disabled={DISABLED_BUTTON}
        >
          Aplicar Filtros
        </Button>
      </Grid>
    </Grid>
  );
}

export default ContentFilterDrawer;
