import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export function CardInfo({
  orientation = 'column',
  center = false,
  isReverse = false,
  isLoading = false,
  title = '',
  titleStyle = {},
  description = '',
  descriptionStyle = {},
}) {
  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        flexDirection: orientation ?? 'column',
        alignItems: orientation === 'row' || center ? 'center' : 'start',
        gap: '0.5rem',
      }}
    >
      <Typography
        style={{
          color: isReverse ? '#000000' : '#808080',
          fontSize: isReverse ? '16px' : '14px',
          fontWeight: '500',
          ...titleStyle,
        }}
      >
        {title}
      </Typography>

      {isLoading ? (
        <Skeleton style={{ width: '100%' }} animation="wave" variant="text" />
      ) : (
        <Typography
          style={{
            color: isReverse ? '#808080' : '#000000',
            fontSize: isReverse ? '14px' : '16px',
            fontWeight: '500',
            ...descriptionStyle,
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
}
