import React from 'react';

import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { ReactComponent as EyeIcon } from '../../../../../../assets/eyesIcon.svg';

import currencyFormatter from '../../../../../../utils/currencyFormatter';
import formatCPF from '../../../../../../utils/formatCPF';

import styles from '../styles.module.css';

export default ({ values, pageSize, currentPage, handleGoToDetails }) => {
  return (
    <TableBody>
      {values.slice(0, pageSize).map((row, index) => (
        <TableRow hover>
          <TableCell className={styles.cpfCell}>{formatCPF(row.cpf)}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.situation}</TableCell>
          <TableCell>
            {row.loanAmountRequested !== 0
              ? currencyFormatter(row.loanAmountRequested)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            {row.loanAmountToPay !== 0
              ? currencyFormatter(row.loanAmountToPay)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>
            {row.loanAmountPaid !== 0
              ? currencyFormatter(row.loanAmountPaid)
              : 'R$ 0,00'}
          </TableCell>
          <TableCell>{row.totalLoans}</TableCell>
          <TableCell className={styles.detailsIconButton}>
            <IconButton
              onClick={() =>
                handleGoToDetails(row.id, row.cpf, row.name, row.competence)
              }
            >
              <Tooltip title="Visualizar detalhes">
                <EyeIcon />
              </Tooltip>
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
