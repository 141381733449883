import React from 'react';
import styles from './styles.module.css';
import { Button, IconButton, Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import currencyFormatter from '../../../../utils/currencyFormatter';
import EyeIcon from '@material-ui/icons/Visibility';
import EyeIconOff from '@material-ui/icons/VisibilityOff';
import { isMobile } from '../../../../utils/breakpoints';

function OnShowBalances({
  loading,
  balances,
  toggleBalance,
  showBalance
}) {
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div>
          <Typography className={styles.balanceLabel}>Saldo disponível</Typography>
          <Typography className={showBalance ? styles.balanceValue : styles.balanceValueNotShow}>
            {
              loading || ACCESS_PROFILES.length === 0 ? (
                <Skeleton height={22} width={100} color="#202020" highlightColor="#444" />
              ) : (
                 balances.companyBalance
                  ? currencyFormatter( balances.companyBalance)
                  : ( balances.companyBalance === 0
                    ? 'R$ 0,00'
                    : <Skeleton height={18} width={90} color="#202020" highlightColor="#444" />)
              )
            }
          </Typography>
        </div>
        {isMobile ?(
          <div className={styles.eyeIconMobile}>
            <IconButton onClick={toggleBalance}>
              {showBalance ? (
                <EyeIconOff className={styles.eyeIconButtonMobile} />
              ) : (
                <EyeIcon className={styles.eyeIconButtonMobile} />
              )}
            </IconButton>
          </div>
        ) : (
          <Button
            className={styles.viewArea}
            onClick={toggleBalance}
          >
            <Typography className={styles.viewButton}>
              { showBalance ? 'Esconder' : 'Mostrar'}
            </Typography>
            {showBalance ? (
                <EyeIconOff className={styles.eyeIconButton} />
              ) : (
                <EyeIcon className={styles.eyeIcon} />
            )}
          </Button>
        )}
      </div>
      <div>
        <Typography className={styles.reservedBalanceLabel}>Saldo reservado</Typography>
        <Typography className={showBalance ? styles.reservedBalance : styles.reservedBalanceNotShow}>
          {
            loading || ACCESS_PROFILES.length === 0 ? (
              <Skeleton height={18} width={90} color="#202020" highlightColor="#444" />
            ) : (
              balances.companyHoldBalance
                ? currencyFormatter(balances.companyHoldBalance)
                : (balances.companyHoldBalance === 0
                  ? 'R$ 0,00'
                  : <Skeleton height={18} width={90} color="#202020" highlightColor="#444" />)
            )
          }
        </Typography>
      </div>
    </div>
  );
}

export default OnShowBalances;
