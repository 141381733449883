import React from 'react';

import {
  Grid,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import ProgressBar from '../../../components/ProgressBar';

import { ReactComponent as DescriptionIcon } from '../../../assets/fileText.svg';

import styles from './styles.module.css';

export default function Content({
  file,
  getRootProps,
  getInputProps,
  isDragActive,
  paycheckFileItem,
  onRemoveFileClicked,
  onCancelUpload,
  isLoading,
  paycheckData
}) {
  const renderDropzone = () => {
    const {
      percentage = 0, status,
      step = 1, steps = 2,
      fileName = 'Nome do arquivo',
      titleProcess = 'Enviando arquivo'
    } = paycheckData;

    if (isLoading) {
      return (
        <>
          <div className={styles.dropzone}>
            <ListItem>
              <ListItemAvatar className={styles.fileAvatarStyle}>
                <DescriptionIcon width="58" height="58" />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <Typography className={styles.fileTextStyle}>
                      {fileName}
                    </Typography>

                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography className={styles.fileTextDescriptionFinish}>
                          {`${titleProcess} (${step}/${steps})`}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography className={styles.percentageText}>
                          {`${percentage} %`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              /> 
            </ListItem>

            <Button
              variant="outlined"
              className={styles.fileButtonStyle}
              onClick={onCancelUpload}
            >
              Cancelar
            </Button>
          </div>

          <Typography className={styles.infoTextStyleLoad}>
            Enquanto verificamos o arquivo você pode continuar seu trabalho e
            acompanhar o andamento pelo indicador na parte superior.
          </Typography>
          <ProgressBar progress={percentage} />
        </>
      );
    }

    if (file.length === 0 && status !== 'PROCESSING') {
      return (
        <>
          <Button
            {...getRootProps()}
            className={styles.dropzone}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography className={styles.primaryColor}>Soltar</Typography>
            ) : (
              <Typography className={styles.buttonText}>
                <PublishIcon className={styles.buttonTextIcon} />
                Enviar um arquivo
              </Typography>
            )}
          </Button>
          <Typography className={styles.infoTextStyle}>
            Você pode arrastar ou clicar na caixa para selecionar o arquivo.
          </Typography>
        </>
      );
    }

    return (
      <>
        <div className={styles.dropzone}>
          <ListItem>
            <ListItemAvatar className={styles.fileAvatarStyle}>
              <DescriptionIcon width="58" height="58" />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography className={styles.fileTextStyle}>
                  {paycheckFileItem ? paycheckFileItem[0]?.path : ''}
                </Typography>
              }
            />
          </ListItem>
          <Button
            variant="outlined"
            className={styles.fileButtonStyle}
            onClick={onRemoveFileClicked}
            disabled={isLoading}
          >
            Remover
          </Button>
        </div>
        <Typography className={styles.infoTextStyle}>
          Enquanto verificamos o arquivo você pode continuar seu trabalho.
        </Typography>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.step}>
        <h3>
          1. Comece extraindo o arquivo do seu ERP
        </h3>
      </div>

      <div className={styles.step}>
        <h3>
          2. Envie o arquivo
        </h3>
        {renderDropzone()}
      </div>
    </div>
  )
}
