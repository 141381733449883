import React, { useEffect } from 'react';

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import RadioButton from '../../../../components/RadioButton/PurpleRadio';

import formatContactNumber from '../../../../utils/formatContactNumber';
import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';

import styles from '../styles.module.css';

export default function ContactForm({
  disabled,
  loading,
  contactDataForm,
  setContactDataForm,
  onSubmitForm,
  setOnSubmitForm,
  onboardEnabled,
  editMode,
}) {
  useEffect(() => {
    if (!contactDataForm?.phones) {
      setContactDataForm({
        email: '',
        phones: [
          {
            type: '',
            number: '',
            isMain: true,
          },
        ],
      });
    }
  }, []);

  function inputValidate(name, index) {
    const validate = contactDataForm?.phones[0][name];

    if (!validate && onSubmitForm) {
      return true;
    }
  }

  function handleChangeContactDataForm(event, index, name) {
    const formFields = contactDataForm.phones;
    let email = contactDataForm.email ? contactDataForm.email : '';

    setOnSubmitForm(false);

    if (name === 'isMain') {
      formFields.map((item, currentIndex) => {
        if (currentIndex === index) {
          formFields[currentIndex][name] = true;
        } else {
          formFields[currentIndex][name] = false;
        }
      });
    } else if (name === 'number') {
      formFields[index][name] = formatOnlyNumbers(event.target.value);
    } else if (name === 'type') {
      formFields[index][name] = event.target.value;
    } else {
      email = event.target.value;
    }

    setContactDataForm({
      email,
      phones: [...formFields],
    });
  }

  function handleAddNewContact() {
    const formFields = contactDataForm.phones;
    const email = contactDataForm.email ? contactDataForm.email : '';

    if (formFields.length >= 3) {
      return;
    }

    formFields.push({
      isMain: false,
      number: '',
      type: '',
    });

    setContactDataForm({
      email,
      phones: [...formFields],
    });
  }

  function handleRemoveContact() {
    const formFields = contactDataForm.phones;
    const email = contactDataForm.email ? contactDataForm.email : '';

    formFields.pop();

    setContactDataForm({
      email,
      phones: [...formFields],
    });
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <form className={styles.form}>
      <Grid container spacing={3}>
        {contactDataForm?.phones?.map((item, index) => (
          <>
            <Grid item xs={12} sm={3} md={3} lg={3} key={item.number}>
              <Tooltip
                title={editMode ? 'Este campo não pode ser alterado' : ''}
              >
                <FormControl
                  variant="outlined"
                  className={styles.filter}
                  fullWidth
                  size="small"
                  error={!onboardEnabled && inputValidate('type', index)}
                >
                  <InputLabel id="select-outlined-label-uf">
                    {`${
                      onboardEnabled ? 'Tipo de contato' : 'Tipo de contato*'
                    }`}
                  </InputLabel>
                  <Select
                    labelId="select-outlined-label-uf"
                    value={contactDataForm?.phones[index].type}
                    onChange={event => {
                      handleChangeContactDataForm(event, index, 'type');
                    }}
                    label={`${
                      onboardEnabled ? 'Tipo de contato' : 'Tipo de contato*'
                    }`}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    disabled
                  >
                    <MenuItem value="Residencial">Residencial</MenuItem>
                    <MenuItem value="Celular">Celular</MenuItem>
                    <MenuItem value="Comercial">Comercial</MenuItem>
                  </Select>
                  {!onboardEnabled && inputValidate('type', index) && (
                    <FormHelperText className={styles.helperText}>
                      Selecione uma opção
                    </FormHelperText>
                  )}
                </FormControl>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={5} md={5} lg={5}>
              <Tooltip
                title={editMode ? 'Este campo não pode ser alterado' : ''}
              >
                <TextField
                  label={`${onboardEnabled ? 'Número' : 'Número*'}`}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={formatContactNumber(
                    contactDataForm?.phones[index].number,
                  )}
                  onChange={event => {
                    handleChangeContactDataForm(event, index, 'number');
                  }}
                  error={!onboardEnabled && inputValidate('number', index)}
                  helperText={
                    !onboardEnabled &&
                    inputValidate('number', index) &&
                    'Esse campo é obrigatório'
                  }
                  inputProps={{ maxLength: 15 }}
                  size="small"
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Tooltip
                title={editMode ? 'Este campo não pode ser alterado' : ''}
              >
                <FormControl>
                  <RadioGroup
                    name="isMain"
                    value={contactDataForm?.phones[index].isMain}
                    onChange={event => {
                      handleChangeContactDataForm(event, index, 'isMain');
                    }}
                  >
                    <FormControlLabel
                      checked={contactDataForm?.phones[index].isMain}
                      value="isMain"
                      control={<RadioButton />}
                      label="Utilizar como número principal"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Tooltip>
            </Grid>
          </>
        ))}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tooltip title={editMode ? 'Este campo não pode ser alterado' : ''}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              disabled
              value={contactDataForm.email}
              size="small"
              onChange={event => {
                handleChangeContactDataForm(event, 'email');
              }}
            />
          </Tooltip>
        </Grid>

        {/*
          // TODO: Voltar caso mude a regra de negócio do onboarding relacionado aos numeros
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                className={styles.buttonRemoveContact}
                disabled
                onClick={() => handleRemoveContact()}
              >
                <DeleteForeverRoundedIcon className={styles.buttonRemoveIcon} />
                Excluir contato
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={styles.buttonAddNew}
                disabled
                onClick={handleAddNewContact}
              >
                <AddRoundedIcon className={styles.buttonRemoveIcon} />
                Adicionar outro contato
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </form>
  );
}
