import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  List,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';

import ProgressToolBar from '../../../../components/ProgressToolBar';
import formatCPF from '../../../../utils/formatCPF';

import { ReactComponent as ListIconError } from '../../../../assets/ListIconError.svg';

import styles from './styles.module.css';

export default () => {
  const history = useHistory();
  const { errors } = history.location.state;

  const goBackToSendFileScreen = () => {
    history.go(-1);
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.pageTitle}>
        Identificamos os seguintes erros
      </Typography>
      <Typography className={styles.pageSubtitle}>
        Por favor, corrija os erros abaixo antes de continuar.
      </Typography>
      <div className={styles.errorsListContainer}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <div className={styles.accordionSummaryContainer}>
              <ListIconError />
              <Typography className={styles.errorTitle}>
                Erros encontrados
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.accordionDetailsContainer}>
              <List>
                {errors.map(value => (
                  <ListItem className={styles.errorText}>
                    <Typography className={styles.errorSubtitle}>
                      • Funcionário portador do CPF{' '}
                      {formatCPF(value?.cpfEmployee)}:
                    </Typography>
                    <Typography className={styles.errorDescription}>
                      ◦ {value?.errorDescription}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <ProgressToolBar
        onNextClicked={goBackToSendFileScreen}
        nextButtonText="Reenviar arquivo"
        disableBack
      />
    </div>
  );
};
