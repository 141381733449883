import React from 'react';
import { useSelector } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Alert } from '@material-ui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from './styles';

const ConfirmCommonPixKeyModal = NiceModal.create(({ onSubmit }) => {
  const modal = useModal();
  const styles = useStyles();

  const { hasError, message, isSending } = useSelector(state => {
    const { hasError, message, isSending } = state.pixStatus;
    return { hasError, message, isSending };
  });

  const handleError = ()=>{
    if(message === 'EXISTENTE_OUTRO_DONO'){
      return 'A chave Pix informada já está registrada sob outro proprietário.'
    }
    if(message === 'EXISTENTE_MESMO_DONO_OUTRO_PSP'){
      return 'A chave Pix já está registrada em outra instituição.'
    }
  }

  return (
    <Dialog
      PaperProps={{ className: styles.root }}
      open={modal.visible}
      onClose={modal.remove}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Typography variant="h3" className={styles.title}>
          Confirmar cadastro de chave?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="label" className={styles.subtitle}>
            Ao confirmar, sua chave Pix será criada.
          </Typography>
        </DialogContentText>

        {hasError ? (
          <Alert severity="error" className={styles.alert}>
            {handleError()}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Buttons.SecondaryButton
          color="primary"
          variant="text"
          title="CANCELAR"
          onClick={modal.remove}
          size="large"
        />
        <Buttons.PrimaryButton
          color="secondary"
          title="CONFIRMAR"
          onClick={onSubmit}
          size="large"
          loading={isSending}
        />
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmCommonPixKeyModal;
