import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  CircularProgress,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  Grid,
  TextField,
  MenuItem,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import CustomDatePicker from '../../../../components/DatePicker';
import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';
import brazilianCurrencyFormatter2 from '../../../../utils/brazilianCurrencyFormatter2';

import styles from '../styles.module.css';

import { api } from '../../../../services/api';

import textCapitalize from '../../../../utils/textCapitalize';

export default function ProfessionalDataForm({
  disabled,
  loading,
  professionalDataForm,
  setProfessionalDataForm,
  salary,
  setSalary,
  setOnSubmitForm,
  onSubmitForm,
  textFieldFocused,
  setTextFieldFocused,
  COMPANY_NAME,
  locations,
  stockings,
}) {
  const [contracts, setContracts] = useState([]);

  const MANDATORY_SALARY =
    professionalDataForm?.typeContract === 'Múltiplo salarial';

  useEffect(() => {
    getContracts();
  }, []);

  function handleChangeProfessionalDataForm(event, name) {
    const formFields = professionalDataForm;

    setOnSubmitForm(false);

    formFields[name] = event?._isAMomentObject
      ? moment(event?._d).format('YYYY-MM-DD')
      : event?.target?.value;

    setProfessionalDataForm({ ...formFields });
  }

  function removeSpacesOnBlur(value) {
    return (professionalDataForm[value] = professionalDataForm[value]?.trim());
  }

  function inputValidate(name) {
    if (
      (!professionalDataForm[name] ||
        professionalDataForm[name] === 'Data inválida') &&
      onSubmitForm
    ) {
      return true;
    }
  }

  function getContracts() {
    api.get('companies/contracts').then(({ data }) => setContracts(data));
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <form className={styles.form}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Tooltip title="Este campo não pode ser alterado">
            <TextField
              disabled
              label="Empresa*"
              variant="outlined"
              fullWidth
              size="small"
              value={
                textCapitalize(professionalDataForm.companyName) ||
                textCapitalize(COMPANY_NAME)
              }
              onChange={event =>
                handleChangeProfessionalDataForm(event, 'companyName')
              }
            />
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
            error={inputValidate('typeContract')}
          >
            <InputLabel id="select-outlined-label-type">Regime*</InputLabel>
            <Select
              label="Regime*"
              labelId="select-outlined-label-type"
              value={professionalDataForm.typeContract}
              onChange={event =>
                handleChangeProfessionalDataForm(event, 'typeContract')
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              disabled={disabled}
            >
              {contracts.map(item => (
                <MenuItem value={item.description} key={item.description}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
            {inputValidate('typeContract') && (
              <FormHelperText className={styles.helperText}>
                Selecione uma opção
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <TextField
            label="Matrícula*"
            variant="outlined"
            fullWidth
            size="small"
            disabled={disabled}
            value={professionalDataForm.employeeRegistration}
            onChange={event =>
              handleChangeProfessionalDataForm(event, 'employeeRegistration')
            }
            error={inputValidate('employeeRegistration')}
            helperText={
              inputValidate('employeeRegistration') &&
              'Esse campo é obrigatório'
            }
            onFocus={() =>
              setTimeout(() => setTextFieldFocused('employeeRegistration'), 200)
            }
            onBlur={() => {
              setTimeout(() => setTextFieldFocused(''), 200);
              removeSpacesOnBlur('employeeRegistration');
            }}
            InputProps={{
              endAdornment: professionalDataForm.employeeRegistration?.length >
                0 &&
                textFieldFocused === 'employeeRegistration' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() =>
                      (professionalDataForm.employeeRegistration = '')
                    }
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <TextField
            label={MANDATORY_SALARY ? '*Salário' : 'Salário'}
            variant="outlined"
            size="small"
            fullWidth
            disabled={disabled}
            value={brazilianCurrencyFormatter2(formatOnlyNumbers(salary))}
            onChange={event => setSalary(event.target.value)}
            error={MANDATORY_SALARY ? inputValidate('salary') : null}
            helperText={
              MANDATORY_SALARY
                ? inputValidate('salary') && 'Esse campo é obrigatório'
                : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
          >
            <InputLabel id="select-outlined-label-type">
              Centro de custo
            </InputLabel>
            <Select
              label="Centro de custo"
              labelId="select-outlined-label-type"
              value={professionalDataForm.locatedAt}
              onChange={event =>
                handleChangeProfessionalDataForm(event, 'locatedAt')
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              disabled={disabled}
            >
              {locations.map(item => (
                <MenuItem value={item.description} key={item.code}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
          >
            <InputLabel id="select-outlined-label-type">Setor</InputLabel>
            <Select
              label="Setor"
              labelId="select-outlined-label-type"
              id="stocking"
              value={professionalDataForm.stocking}
              onChange={event =>
                handleChangeProfessionalDataForm(event, 'stocking')
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              disabled={disabled}
            >
              {stockings.map(item => (
                <MenuItem value={item.description} key={item.code}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <CustomDatePicker
            label="Data de admissão*"
            disabled={disabled}
            id="admissionDate"
            variant="outlined"
            value={professionalDataForm.admissionDate}
            onChange={event =>
              handleChangeProfessionalDataForm(event, 'admissionDate')
            }
            error={inputValidate('admissionDate')}
            helperText={inputValidate('admissionDate') && 'Selecione uma data'}
            height={4}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Tooltip title="Este campo não pode ser alterado">
            <CustomDatePicker
              label="Data de demissão"
              disabled
              id="resignationDate"
              variant="outlined"
              value={professionalDataForm.resignationDate}
              onChange={event =>
                handleChangeProfessionalDataForm(event, 'resignationDate')
              }
              height={4}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}
