import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

import styles from './styles.module.css';
import { ReactComponent as NotFoundIcon } from '../../assets/not-found.svg';

function ResultNotFound({
  text,
  onClick,
  alternativeText,
  buttonText,
  style,
  icon,
}) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={`${styles.notFoundContainer} ${style}`}
    >
      <Grid item className={styles.notFoundIcon}>
        {icon || <NotFoundIcon />}
      </Grid>

      <Grid item>
        <Typography className={styles.text}>{text}</Typography>
      </Grid>

      <Grid item>
        <Typography className={styles.alternativeText}>
          {alternativeText}
        </Typography>
      </Grid>

      {buttonText && (
        <Grid item className={styles.buttonContainer}>
          <Button onClick={onClick} className={styles.button}>
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default ResultNotFound;
