import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { styles } from './styles';

const headCells = [
  {
    id: 'contracting_date',
    numeric: false,
    disablePadding: false,
    label: 'Data da Contratação',
  },
  {
    id: 'advance_date',
    numeric: false,
    disablePadding: false,
    label: 'Data da Liberação',
  },
  {
    id: 'cpf',
    numeric: true,
    disablePadding: false,
    label: 'CPF',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'amount_to_pay',
    numeric: true,
    disablePadding: false,
    label: 'Valor Contratado',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Valor Líquido',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Situação',
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="15px"
            style={styles.tableHeadLabel}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
