import moment from 'moment';
import { api } from '../../../../services/api';

export default class CollaboratorsService {
  static getCollaborators({ requestOptions, companyCode, setStates }) {
    api.get(`/employees/search/${companyCode}`, requestOptions).then(data => {
      let listOfEmployees = data.data.employees;
      setStates(listOfEmployees, data.data.totalSize);
    });
  }

  static getListOfTypes(objectOptions) {
    api
      .get('/employeesPayments/payments/types', objectOptions.requestOptions)
      .then(({ data }) => {
        objectOptions.setStates(data);
      });
  }

  static sendListForBackEnd({
    requestOptions,
    listForService,
    paymentType,
    date,
    companyCode,
    depositAccountOrigin,
    dispatch,
    onShowErrorAlert,
    setLoading,
    setConfirmModal,
    handleConfirm,
  }) {
    let payments = [];
    listForService.forEach(item => {
      payments.push({
        name: item.name,
        cpf: item.cpf,
        amount: item.amount,
        registration: item.registration,
        employeeStatus: item.employeeStatus,
      });
    });

    let body = {
      fileEncodedKey: null,
      scheduleDate: moment(date).format('YYYY-MM-DD'),
      paymentType,
      depositAccountOrigin,
      payments,
    };

    api
      .post(`employeesPayments/drafts/${companyCode}`, body, requestOptions)
      .then(data => {
        dispatch(data?.data);
        const { skippedInvalidDeposits } = data?.data;

        if (skippedInvalidDeposits) {
          setConfirmModal(true);
          return;
        }
        handleConfirm(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          const message = errors[0].errorDetail;
          onShowErrorAlert(message);
        },
      )
      .finally(() => setLoading(false));
  }
}
