import React from 'react';
import Drawer from '@material-ui/core/Drawer';

export default function TemporaryDrawer({ 
  content, 
  anchor,
  openDrawer,
  onClose
}) {
  return (
    <Drawer 
      anchor={anchor} 
      open={openDrawer}
      onClose={onClose}
    >
      {content}
    </Drawer>
  );
}