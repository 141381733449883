import React, { createContext, useState, useContext } from 'react';

import { BrowserRouter } from 'react-router-dom';

const ModalProcessBlock = createContext();

function ModalProcessBlockProvider({ children }) {
  const [confirm, setConfirm] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [messageConfirm, setMessageConfirm] = useState('');

  function getConfirmation(message, callback) {
    setConfirmCallback(() => callback);
    setConfirm(true);
    setMessageConfirm(message);
  }

  function allowTransition() {
    setConfirm(false);
    confirmCallback(true);
  }

  function blockTransition() {
    setConfirm(false);
    confirmCallback(false);
  }

  const value = {
    getConfirmation,
    setConfirm,
    confirm,
    confirmCallback,
    messageConfirm,
    allowTransition,
    blockTransition
  };

  return (
    <BrowserRouter getUserConfirmation={getConfirmation}>
      <ModalProcessBlock.Provider value={value}>
        {children}
      </ModalProcessBlock.Provider>
    </BrowserRouter>
  )
}


function useConfirmation() {
  const context = useContext(ModalProcessBlock);

  if (context === undefined) throw new Error('useConfirmation must be used within a ModalProcessBlockProvider')

  return context;
}

export {
  ModalProcessBlockProvider,
  useConfirmation
}
