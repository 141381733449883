import { alpha, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      borderRadius: '6px',
    },
    radioCard: {
      display: 'none',
    },
    icon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    cardLabel: {
      fontSize: '.875rem',
      fontWeight: 500,
      color: theme.palette.grey[900],
    },
    keyField: {
      marginTop: theme.spacing(4),
    },
    randomKeyContainer: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    avatar: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  }),
);
