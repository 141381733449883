import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pt-br';
import styles from '../styles.module.css';
import {
  Button,
  FormGroup,
  FormControlLabel,
  Menu,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as FilterIcon } from '../../../../../../assets/filterIcon.svg';
import GrayCheckbox from '../../../../../../components/Checkbox/GrayCheckbox';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('pt-br');

function Filters() {
  const insuranceTypeStorage = JSON.parse(
    sessionStorage.getItem('insuranceType')
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const insuranceTypeDefaultObject = {
    healthInsurance: true,
    lifeInsurance: false,
    dentalInsurance: false,
  };
  const [insuranceType, setInsuranceType] = useState(
    insuranceTypeStorage ? insuranceTypeStorage : insuranceTypeDefaultObject
  );
  const { lifeInsurance, dentalInsurance, healthInsurance } = insuranceType;

  const [firstDate, setFirstDate] = useState(moment({}));
  const [secondDate, setSecondDate] = useState(moment({}));

  const handleClickFilter = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleChangeCheckbox = event => {
    setInsuranceType({
      ...insuranceType,
      [event.target.name]: event.target.checked,
    });
  };

  const onChangeFirstDate = value => {
    setFirstDate(value);
  };

  const onChangeSecondDate = value => {
    setSecondDate(value);
  };

  return (
    <div className={styles.containerFilter}>
      <div className={styles.filterButton}>
        <MuiPickersUtilsProvider
          utils={(DateFnsUtils, MomentUtils)}
          locale={'pt-br'}
        >
          <DatePicker
            openTo="year"
            views={['year', 'month']}
            label="Período(De)"
            value={firstDate}
            onChange={onChangeFirstDate}
            inputVariant="outlined"
            style={{ width: '100%' }}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className={styles.filterButton}>
        <MuiPickersUtilsProvider
          utils={(DateFnsUtils, MomentUtils)}
          locale={'pt-br'}
        >
          <DatePicker
            openTo="year"
            views={['year', 'month']}
            label="Período(Até)"
            value={secondDate}
            onChange={onChangeSecondDate}
            inputVariant="outlined"
            style={{ width: '100%' }}
          />
        </MuiPickersUtilsProvider>
      </div>

      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={styles.filterButton}
        onClick={handleClickFilter}
      >
        <div className={styles.iconContainer}>
          <FilterIcon />
          <Typography className={styles.filterButtonText}>
            Tipo de seguro
          </Typography>
        </div>
        <ArrowDropDownIcon />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseFilter}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FormGroup className={styles.formGroupCheckbox}>
          <FormControlLabel
            control={
              <GrayCheckbox
                checked={healthInsurance}
                onChange={handleChangeCheckbox}
                name="healthInsurance"
              />
            }
            label="Saúde"
          />
          <FormControlLabel
            control={
              <GrayCheckbox
                checked={lifeInsurance}
                onChange={handleChangeCheckbox}
                name="lifeInsurance"
              />
            }
            label="Vida"
          />
          <FormControlLabel
            control={
              <GrayCheckbox
                checked={dentalInsurance}
                onChange={handleChangeCheckbox}
                name="dentalInsurance"
              />
            }
            label="Odontológico"
          />
        </FormGroup>
      </Menu>
    </div>
  );
}

export default Filters;
