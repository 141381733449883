import React from 'react';
import { Box, Collapse, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { ChipStatus } from '../../../../../../../../../components/ChipStatus';
import Buttons from '../../../../../../../../../components/Buttons';

export function Rejected({ sectionOpen = '', handleOpenSection = () => {} }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <ChipStatus status="primary" label="Rejeitado" />

      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '13px',
        }}
      >
        Este colaborador não atende a todos os critérios estabelecidos pelo
        Banco Central para criação de uma conta de pagamento do sistema
        financeiro nacional.
      </Typography>

      <Buttons.SecondaryButton
        color="primary"
        icon={
          sectionOpen === 'section3' ? (
            <RemoveCircleOutlineIcon color="primary" />
          ) : (
            <AddCircleOutlineIcon color="primary" />
          )
        }
        title={sectionOpen === 'section3' ? 'Ver menos' : 'Ver mais'}
        onClick={() =>
          handleOpenSection(sectionOpen === 'section3' ? '' : 'section3')
        }
        style={{ width: 'fit-content' }}
      />

      <Collapse in={sectionOpen === 'section3'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography
            style={{
              fontSize: '13px',
            }}
          >
            Infelizmente não teremos como realizar o pagamento deste
            colaborador.
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}
