import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import FakeCards from './FakeCards';

import { ReactComponent as EditButtonIcon } from '../../assets/edit-button.svg';
import { ReactComponent as RemoveButtonIcon } from '../../assets/remove-button.svg';

import styles from './styles.module.css';

function CardsAccounts({
  accountsList,
  confirmEditAccount,
  confirmRemoveAccount,
  loading,
}) {
  if (loading) {
    return <FakeCards />;
  }

  return (
    <Grid className={styles.cards}>
      {accountsList.map(item => (
        <div
          className={
            accountsList.length > 4
              ? styles.cardAccountMoreFour
              : styles.cardAccount
          }
          key={item.id}
        >
          <div className={styles.containerBankAndEditRemove}>
            <Typography
              className={
                accountsList.length > 4
                  ? styles.bankNameMoreFour
                  : styles.bankName
              }
            >
              {item.bankName}
            </Typography>

            <Typography
              className={
                accountsList.length > 4 ? styles.nameMoreFour : styles.name
              }
            >
              Nome: <span>{item.name || '-'}</span>
            </Typography>

            <div
              className={
                accountsList.length > 4 ? styles.agencyMoreFour : styles.agency
              }
            >
              <Typography className={styles.accountNumber}>
                Conta Corrente: <span>{item.account}</span>
              </Typography>
              <Typography className={styles.accountNumber}>
                Agência: <span>{item.agency}</span>
              </Typography>
            </div>
          </div>

          <div className={styles.containerEditRemove}>
            <Button
              className={styles.editButton}
              onClick={event => confirmEditAccount(item, event)}
            >
              <EditButtonIcon className={styles.editIcon} />
              Editar
            </Button>

            <Button
              className={styles.removeButton}
              onClick={event => confirmRemoveAccount(item, event)}
            >
              <RemoveButtonIcon className={styles.removeIcon} />
              Remover
            </Button>
          </div>
        </div>
      ))}
    </Grid>
  );
}

export default CardsAccounts;
