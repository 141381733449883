import React from 'react';
import { Box, Dialog, Slide, makeStyles } from '@material-ui/core';
import { FullscreenModalFooter } from './components/FullscreenModalFooter';
import { FullscreenModalHeader } from './components/FullscreenModalHeader';

const Transition = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
));

export function FullscreenModal({
  title = '',
  subtitle = '',
  open = false,
  hasBottomBar = true,
  errorOn = { hasError: false, message: '' },
  onClose = () => {},
  children = <></>,
  firstButtonText = 'Confirmar',
  firstButtonIcon = null,
  firstButtonDisabled = false,
  firstButtonOnClick = () => {},
  secondButtonShow = true,
  secondButtonText = 'Cancelar',
  secondButtonIcon = null,
  secondButtonDisabled = false,
  secondButtonOnClick = () => {},
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      classes={{
        paper: makeStyles({
          paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowX: 'hidden',
          },
        })().paper,
      }}
      TransitionComponent={Transition}
    >
      <FullscreenModalHeader
        title={title}
        subtitle={subtitle}
        onClose={onClose}
      />

      <Box sx={{ width: '100%', display: 'flex' }}>{children}</Box>

      {hasBottomBar && (
        <FullscreenModalFooter
          errorOn={errorOn}
          firstButtonText={firstButtonText}
          firstButtonIcon={firstButtonIcon}
          firstButtonDisabled={firstButtonDisabled}
          firstButtonOnClick={firstButtonOnClick}
          secondButtonShow={secondButtonShow}
          secondButtonText={secondButtonText}
          secondButtonIcon={secondButtonIcon}
          secondButtonDisabled={secondButtonDisabled}
          secondButtonOnClick={secondButtonOnClick}
        />
      )}
    </Dialog>
  );
}
