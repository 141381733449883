import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Typography
} from '@material-ui/core';

import styles from './styles';

export default ({
  open,
  onCloseDialog,
  title,
  text,
  confirmText,
  onConfirmClicked,
  cancelText,
  ignoreText,
  ignoreLink = false,
  circularLoading,
  close = false,
  confirButtonStyle,
  successConfirm,
  errorMessage,
}) => (
  <Dialog
    open={open}
    onClose={onCloseDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <Typography id="alert-dialog-title" style={styles.title}>
      {title}
    </Typography>
    <DialogContent>
      <DialogContentText
        id="alert-dialog-description"
      >
        {text}
      </DialogContentText>
      <DialogContentText
        id="alert-dialog-description"
        style={styles.errorMessage}
      >
        {errorMessage}
      </DialogContentText>
    </DialogContent>

    <DialogActions style={{ marginBottom: 12 }}>
      {!successConfirm ? (
        <Button onClick={onCloseDialog} style={styles.cancelText}>
          {cancelText}
        </Button>
      ) : (
        <span></span>
      )}
      {ignoreLink ? (
        <Button onClick={onConfirmClicked} color="primary">
          {ignoreText}
        </Button>
      ) : (
        <Button
          onClick={close === false ? onConfirmClicked : onCloseDialog}
          variant="contained"
          style={
            confirButtonStyle === false
              ? styles.confirmButtonStyle
              : styles.closeButtonStyle
          }
        >
          {circularLoading ? (
            <CircularProgress style={styles.circularLoadingStyle} />
          ) : (
            confirmText
          )}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
