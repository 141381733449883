const INITIAL_STATE = 'Wait'

export default function page(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@page/NEXT_PAGE':
      return action.payload;
    case '@CLEAR_DATA_PAGE':
      return INITIAL_STATE
    default:
      return state;
  }
}
