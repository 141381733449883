import { useForm } from 'react-hook-form';
import { useModal } from '@ebay/nice-modal-react';
import { useUpdateEmployeeBankDataMutation } from '../../../../../../../../redux/store/Employees/services/pendingEmployees.api';
import { handleSnackbarStack } from '../../../../../../../../utils/handleSnackbarStack';

export function useHandleEmployeeBankData({
  employee = {},
  employeeType = '',
}) {
  const modal = useModal();

  const { control, handleSubmit, getValues, watch, reset } = useForm({
    defaultValues: {
      bank: '',
      accountType: '',
      agency: '',
      agencyDigit: '',
      account: '',
      accountDigit: '',
    },
    values: {
      bank: employee?.bank,
      accountType: employee?.accountType,
      agency: employee?.agency,
      agencyDigit: employee?.agencyDigit,
      account: employee?.account,
      accountDigit: employee?.accountDigit,
    },
  });

  const [
    handleUpdateEmployeeBankData,
    { isLoading },
  ] = useUpdateEmployeeBankDataMutation();

  const accountTypeOptions = [
    {
      label: 'Conta Corrente',
      value: 'CC',
    },
    {
      label: 'Conta Poupança',
      value: 'CP',
    },
  ];

  watch();

  const canSubmit = Object.values(getValues()).every(
    values => values !== undefined && values !== null && values !== '',
  );

  function handleCloseModal() {
    modal.hide();
    reset();
  }

  async function onSubmitNewEmployeeBankData(data) {
    try {
      await handleUpdateEmployeeBankData({
        ...data,
        id: employee?.id,
        employeeType,
      }).unwrap();

      handleSnackbarStack().success('Dados bancários inseridos com sucesso!');

      modal.hide();
    } catch (_) {
      handleSnackbarStack().error(
        'Houve um erro ao inserir os dados bancários',
      );
    }
  }

  return {
    modal,
    handleCloseModal,
    form: {
      control,
      canSubmit,
      accountTypeOptions,
      isLoading,
      handleSubmitBankData: () => handleSubmit(onSubmitNewEmployeeBankData),
    },
  };
}
