import './utils/yupConfig';
import React from 'react';
import ReactDOM from 'react-dom';

import {
  StylesProvider,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import App from './App';

import './globalStyles.css';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#52197F',
        main10: '#52197F1A',
        main5: ' #52197F0D',
        light: '#9775B2',
        dark: '#391158',
        transparent: 'rgba(82, 25, 127,0.1)',
        hover: '#7500D1',
        disabled: '#CBBAD9',
      },
      secondary: {
        main: '#E64300',
        main10: '#E643001A',
        main5: '#E643000D',
        dark: '#a02e10',
        light: '#ea6745',
        transparent: 'rgba(229, 66, 23,0.1)',
        hover: '#F23300',
        disabled: '#F7C6B9',
      },
      error: {
        main: '#E42431',
        main10: '#E424311A',
        main5: '#E424310D',
        light: '#EF4E53',
        dark: '#D2172B',
      },
      success: {
        main: '#31842A',
        main10: '#31842A1A',
        main5: '#31842A0D',
        light: '#6CC264',
        dark: '#1B6517',
      },
      warning: {
        main: '#F19207',
        main10: '#F192071A',
        main5: '#F192070D',
        light: '#F3A328',
        dark: '#E16902',
      },
      info: {
        main: '#0765F1',
        main10: '#0765F11A',
        main5: '#0765F10D',
        light: '#00AAFF',
        dark: '#1F41D2',
      },
      text: {
        disabled: '#0f001a61',
        icon: '#0f001a80',
        secondary: '#0f001a99',
        primary: '#12001fcc',
      },
    },
    secondary: {
      main: '#f1f1f1',
    },
  },
  ptBR,
);

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
