import React from 'react';
import { Box } from '@material-ui/core';
import { useDotStatus } from './Hooks/useDotStatus';

export function DotStatus({ status = 'primary' }) {
  const statusColor = useDotStatus({ status });

  return (
    <Box
      sx={{
        backgroundColor: statusColor,
        height: '0.45rem',
        width: '0.45rem',
        borderRadius: '50%',
      }}
    />
  );
}
