/* eslint-disable react/no-array-index-key */
import React, { useMemo, useReducer } from 'react';
import { Box, Typography } from '@material-ui/core';

import { PriorityHigh, RemoveCircleOutline } from '@material-ui/icons';
import styles from './styles.module.css';
import { useConsignedMarginBatchConfirm } from '../../utils/hooks';
import formatCPF from '../../../../../../../utils/formatCPF';

function ErrorAccordion() {
  const { data } = useConsignedMarginBatchConfirm();

  const [opened, toggleOpen] = useReducer(dirt => !dirt, true);

  const list = useMemo(() => {
    if (!data) return [];

    return data.lines.filter(line => line.withProcessingErrors);
  }, [data]);

  if (data && data.validationErrorLines === 0) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header} data-show="true" onClick={toggleOpen}>
        <Box className={styles.icon}>
          <PriorityHigh />
        </Box>
        <Box>
          <Typography className={styles.title}>
            <b>{data ? data.validationErrorLines : 0} colaborador(es)</b> não
            forão cadastrados
          </Typography>
          <Typography className={styles.subtitle}>
            Verifique os motivos dos erros abaixo e reenvie o arquivo.
          </Typography>
        </Box>
      </Box>
      {opened && (
        <Box className={styles.content}>
          {list.map((item, i) => (
            <AccordionItem key={item.lineNumber} data={item} />
          ))}
        </Box>
      )}
    </Box>
  );
}

const AccordionItem = ({ data }) => {
  const [opened, toggleOpen] = useReducer(dirt => !dirt, true);

  return (
    <Box className={styles.accordion}>
      <Box
        className={styles.accordionHeader}
        data-show={opened}
        onClick={toggleOpen}
      >
        <Box className={styles.accordionLine}>
          <Typography className={styles.accordionHeaderTitle}>
            Linha do arquivo
          </Typography>
          <Typography className={styles.accordionHeaderValue}>
            {data.lineNumber}
          </Typography>
        </Box>
        <Box>
          <Typography className={styles.accordionHeaderTitle}>CPF</Typography>
          <Typography className={styles.accordionHeaderValue}>
            {formatCPF(data.cpf)}
          </Typography>
        </Box>
      </Box>
      {opened && (
        <Box className={styles.accordionContent}>
          <ul>
            {data.validationErrors.map((error, i) => (
              <li key={`error-${i}`}>
                <Box className={styles.accordionIcon}>
                  <RemoveCircleOutline />
                </Box>
                <Box>
                  <Typography className={styles.accordionErrorTitle}>
                    Motivo do erro {i + 1}
                  </Typography>
                  <Typography className={styles.accordionErrorMessage}>
                    {error}
                  </Typography>
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default ErrorAccordion;
