import React, { useContext } from 'react';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from './styles';
import { PixContext } from '../Context';

export default function CashOutPixBottomBar({ isLoading, isFinal, disabled }) {
  const { onBack } = useContext(PixContext);

  const styles = useStyles();

  const renderTitle = isFinal ? 'Solicitar' : 'Continuar';

  return (
    <footer className={styles.root}>
      <Buttons.PrimaryButton
        variant="outlined"
        color="primary"
        onClick={onBack}
        title="Voltar"
      />

      <Buttons.PrimaryButton
        type="submit"
        color={isFinal ? 'secondary' : 'primary'}
        disabled={disabled}
        loading={isLoading}
        title={renderTitle}
      />
    </footer>
  );
}
