import React from 'react';
import styles from './styles.module.css';
import {
  Dialog,
  Typography
} from '@material-ui/core';

import { isMobile } from '../../utils/breakpoints';

function PrimaryModal({
  open,
  title,
  image
}) {
  return (
    <Dialog
        open={open}
        fullWidth
        maxWidth='xs'
    >
      {isMobile ? (
        <div className={styles.container}>
          <div className={styles.image}>
            {image}
          </div>
          <Typography className={styles.title}>{title}</Typography>
        </div>
      ) : (
        <div className={styles.container}>
          <Typography className={styles.title}>{title}</Typography>
          <div className={styles.image}>
            {image}
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default PrimaryModal;
