import React from 'react';
import moment from 'moment';

import {
  CircularProgress,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  Grid,
  TextField,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import CustomDatePicker from '../../../../components/DatePicker';
import { states } from './mocks';

import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';

import styles from '../styles.module.css';

export default function PersonalDataForm({
  disabled,
  personalDataForm,
  setPersonalDataForm,
  loading,
  setOnSubmitForm,
  onSubmitForm,
  textFieldFocused,
  setTextFieldFocused,
}) {
  const EMPLOYEE_REGISTRATION_DATA = JSON.parse(
    sessionStorage.getItem('EMPLOYEE_REGISTRATION_DATA'),
  );

  function handleChangePersonalDataForm(event, name, value) {
    const formFields = personalDataForm;

    setOnSubmitForm(false);

    formFields[name] = event?._isAMomentObject
      ? moment(event?._d).format('YYYY-MM-DD')
      : name === 'rgIssuingState'
      ? value
      : removeExtraSpaces(event?.target?.value);

    setPersonalDataForm({ ...formFields });
  }

  function removeExtraSpaces(string) {
    return string && string?.replace(/\s{2,}/g, ' ');
  }

  function removeSpacesOnBlur(value) {
    return (personalDataForm[value] = personalDataForm[value]?.trim());
  }

  function inputValidate(name) {
    if (
      (!personalDataForm[name] || personalDataForm[name] === 'Data inválida') &&
      onSubmitForm
    ) {
      return true;
    }
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <form className={styles.form}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={9} md={9} lg={9}>
          <TextField
            label="Nome completo*"
            variant="outlined"
            fullWidth
            size="small"
            disabled={disabled}
            value={personalDataForm.name}
            onChange={event => handleChangePersonalDataForm(event, 'name')}
            error={inputValidate('name')}
            helperText={inputValidate('name') && 'Esse campo é obrigatório'}
            onFocus={() => {
              setTimeout(() => setTextFieldFocused('name'), 200);
            }}
            onBlur={() => {
              setTimeout(() => setTextFieldFocused(''), 200);
              removeSpacesOnBlur('name');
            }}
            InputProps={{
              endAdornment: personalDataForm.name?.length > 0 &&
                textFieldFocused === 'name' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => (personalDataForm.name = '')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Tooltip title="Este campo não pode ser alterado">
            <FormControl
              variant="outlined"
              className={styles.filter}
              fullWidth
              size="small"
            >
              <InputLabel id="select-outlined-label-type">
                Tipo de pessoa
              </InputLabel>
              <Select
                labelId="select-outlined-label-type"
                label="Tipo de pessoa"
                value="Física"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                disabled
              >
                <MenuItem value="Física">Física</MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Tooltip title="Este campo não pode ser alterado">
            <TextField
              label="CPF*"
              variant="outlined"
              fullWidth
              size="small"
              value={personalDataForm.cpf || EMPLOYEE_REGISTRATION_DATA?.cpf}
              onChange={event => handleChangePersonalDataForm(event, 'cpf')}
              disabled
            />
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <CustomDatePicker
            label="Data de nascimento*"
            disabled={disabled}
            variant="outlined"
            value={personalDataForm.birthdate}
            onChange={event => handleChangePersonalDataForm(event, 'birthdate')}
            error={inputValidate('birthdate')}
            helperText={inputValidate('birthdate') && 'Selecione uma data'}
            height={4}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
          >
            <InputLabel id="select-outlined-label-gender">Sexo</InputLabel>
            <Select
              labelId="select-outlined-label-gender"
              value={personalDataForm.gender}
              onChange={event => handleChangePersonalDataForm(event, 'gender')}
              label="Sexo"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              disabled={disabled}
            >
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Feminino">Feminino</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
          >
            <InputLabel id="select-outlined-label-marial-status">
              Estado Civil
            </InputLabel>
            <Select
              labelId="select-outlined-label-marial-status"
              value={personalDataForm.maritalStatus}
              onChange={event =>
                handleChangePersonalDataForm(event, 'maritalStatus')
              }
              label="Estado Civil"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              disabled={disabled}
            >
              <MenuItem value="Solteiro">Solteiro</MenuItem>
              <MenuItem value="Casado">Casado(a)</MenuItem>
              <MenuItem value="Separado">Separado(a)</MenuItem>
              <MenuItem value="Divorciado">Divorciado(a)</MenuItem>
              <MenuItem value="Viúvo">Viúvo(a)</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <TextField
            label="RG*"
            variant="outlined"
            fullWidth
            size="small"
            disabled={disabled}
            value={formatOnlyNumbers(personalDataForm.rg)}
            onChange={event => handleChangePersonalDataForm(event, 'rg')}
            error={inputValidate('rg')}
            helperText={inputValidate('rg') && 'Esse campo é obrigatório'}
            onFocus={() => setTimeout(() => setTextFieldFocused('rg'), 200)}
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            InputProps={{
              endAdornment: personalDataForm.rg?.length > 0 &&
                textFieldFocused === 'rg' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => (personalDataForm.rg = '')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <CustomDatePicker
            label="Data de emissão do RG*"
            disabled={disabled}
            variant="outlined"
            value={personalDataForm.rgIssuingDate}
            onChange={event =>
              handleChangePersonalDataForm(event, 'rgIssuingDate')
            }
            error={inputValidate('rgIssuingDate')}
            helperText={inputValidate('rgIssuingDate') && 'Selecione uma data'}
            height={4}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <TextField
            label="Orgão emissor*"
            variant="outlined"
            fullWidth
            size="small"
            disabled={disabled}
            value={personalDataForm.rgIssuingAgency}
            onChange={event =>
              handleChangePersonalDataForm(event, 'rgIssuingAgency')
            }
            error={inputValidate('rgIssuingAgency')}
            helperText={
              inputValidate('rgIssuingAgency') && 'Esse campo é obrigatório'
            }
            onFocus={() => {
              setTimeout(() => setTextFieldFocused('rgIssuingAgency'), 200);
              removeSpacesOnBlur('rgIssuingAgency');
            }}
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            InputProps={{
              endAdornment: personalDataForm.rgIssuingAgency?.length > 0 &&
                textFieldFocused === 'rgIssuingAgency' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => (personalDataForm.rgIssuingAgency = '')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
          >
            <Autocomplete
              id="combo-box-demo"
              options={states}
              getOptionLabel={option => option}
              onChange={(event, value) =>
                handleChangePersonalDataForm(event, 'rgIssuingState', value)
              }
              disabled={disabled}
              value={personalDataForm.rgIssuingState}
              closeIcon={false}
              renderInput={params => (
                <TextField
                  {...params}
                  label="UF de emissão*"
                  variant="outlined"
                  size="small"
                  error={inputValidate('rgIssuingState')}
                  helperText={
                    inputValidate('rgIssuingState') &&
                    'Esse campo é obrigatório'
                  }
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            label="Nome da mãe*"
            variant="outlined"
            fullWidth
            size="small"
            disabled={disabled}
            value={personalDataForm.motherName}
            onChange={event =>
              handleChangePersonalDataForm(event, 'motherName')
            }
            error={inputValidate('motherName')}
            helperText={
              inputValidate('motherName') && 'Esse campo é obrigatório'
            }
            onFocus={() => {
              setTimeout(() => setTextFieldFocused('motherName'), 200);
              removeSpacesOnBlur('motherName');
            }}
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            InputProps={{
              endAdornment: personalDataForm.motherName?.length > 0 &&
                textFieldFocused === 'motherName' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => (personalDataForm.motherName = '')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
