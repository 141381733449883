import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Header } from '../../../../components/Header';
import HelpCenterBottomBar from '../BottomBar';

import useAcademy from '../../hooks/useAcademy';

import { useStyles } from '../../styles';

export default function HelpFlowPage() {
  const { id } = useParams();
  const styles = useStyles();

  const { currentFlow, onGetFlow, isLoadingFlowSteps } = useAcademy();

  useEffect(() => {
    onGetFlow(id);
  }, []);

  return isLoadingFlowSteps ? (
    <Box className={styles.spinner}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Header title={currentFlow?.topic} />
      <Box className={clsx(styles.root, styles.flowRoot)}>
        <Typography className={styles.title} variant="h2">
          {currentFlow?.subtopic}
        </Typography>

        <Box>
          {currentFlow?.flows?.length > 0 ? (
            currentFlow?.flows?.map(step => (
              <Box key={step.sequence} className={styles.stepContainer}>
                <Typography variant="body2" className={styles.flowText}>
                  {`${step.sequence}.  ${step.descriptionHtml}`}
                </Typography>
                {step?.pictureUrl && (
                  <img
                    className={styles.flowImage}
                    src={step?.pictureUrl}
                    alt={step.descriptionHtml}
                    loading="lazy"
                  />
                )}
              </Box>
            ))
          ) : (
            <Typography variant="body2" className={styles.emptyFlowText}>
              Nenhum fluxo encontrado.
            </Typography>
          )}
        </Box>
      </Box>
      <HelpCenterBottomBar id={id} />
    </>
  );
}
