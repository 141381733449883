import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AccessActions as Actions } from '../../../../../redux/store/AccessSettings';

import { SettingsContext } from '../../../contexts';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

import validations from '../validations';

const SUCCESS_MESSAGE =
  'Alterações salvas com sucesso! Em 48hs as alterações serão aplicadas.';

const HOUR_OPTIONS = [
  { label: '06:00', value: 6 },
  { label: '07:00', value: 7 },
  { label: '08:00', value: 8 },
  { label: '09:00', value: 9 },
  { label: '10:00', value: 10 },
  { label: '11:00', value: 11 },
  { label: '12:00', value: 12 },
  { label: '13:00', value: 13 },
  { label: '14:00', value: 14 },
  { label: '15:00', value: 15 },
  { label: '16:00', value: 16 },
  { label: '17:00', value: 17 },
  { label: '18:00', value: 18 },
  { label: '19:00', value: 19 },
  { label: '20:00', value: 20 },
  { label: '21:00', value: 21 },
  { label: '22:00', value: 22 },
];

export default function useAccessHours() {
  const dispatch = useDispatch();
  const { error, success } = handleSnackbarStack();
  const { handleExpand, isAdmin } = useContext(SettingsContext);

  const [isReseted, setReset] = useState(false);

  const {
    isSending,
    currentConfig: {
      initialMovementHour,
      initialMovementHourScheduled,
      finalMovementHour,
      finalMovementHourScheduled,
    },
  } = useSelector(state => state.access);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { isValid, errors, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    defaultValues: {
      initialMovementHour,
      finalMovementHour,
    },
  });

  const onSubmit = async form => {
    const actionResult = await dispatch(Actions.setAccessHours(form));

    try {
      unwrapResult(actionResult);
      success(SUCCESS_MESSAGE);
      handleExpand(false)();
      reset();
    } catch (err) {
      error(err.message);
    }
  };

  useEffect(() => {
    reset({ initialMovementHour, finalMovementHour }, { keepErrors: true });
    setReset(true);
  }, [initialMovementHour, finalMovementHour]);

  useEffect(() => {
    isReseted && trigger();
    setReset(false);
  }, [trigger, isReseted]);

  return {
    control,
    onSubmit: () => handleSubmit(onSubmit),
    isValid: isDirty && isValid && isAdmin,
    isSending,
    errors,
    alert: initialMovementHourScheduled || finalMovementHourScheduled,
    selectOptions: HOUR_OPTIONS,
    hours: {
      initial: initialMovementHour,
      final: finalMovementHour,
      initialScheduled: initialMovementHourScheduled,
      finalScheduled: finalMovementHourScheduled,
    },
  };
}
