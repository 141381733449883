import React from 'react';
import { Box, Divider, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CardTools from '../../../../../../../../../components/CardTools';
import Buttons from '../../../../../../../../../components/Buttons';
import formatCPF from '../../../../../../../../../utils/formatCPF';

export function ErrorReasonCardItem({
  error = {},
  errorData = {},
  errorsIndex = 0,
  handleEditEmployeeBankData = () => {},
}) {
  return (
    <Paper
      style={{
        display: 'flex',
        padding: '1rem',
        border: '1px solid #E5E5E5',
        borderRadius: '0.5rem',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CardTools.CardInfo
          title={`Motivo do erro ${errorsIndex + 1}`}
          description={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!errorData?.errorDescription
                ? 'Houve um erro inesperado. Contate o suporte para solucionar o seu problema e tente novamente.'
                : errorData?.errorDescription}
            </Box>
          }
        />

        {errorData?.identification && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Divider orientation="vertical" flexItem />

            <Buttons.IconButton
              color="secondary"
              icon={<Edit />}
              onClick={() =>
                handleEditEmployeeBankData({
                  employeeData: {
                    name: error?.employeeName,
                    title: error?.cpf ? 'CPF' : 'Matrícula',
                    cpfOrRegistration: error?.cpf
                      ? formatCPF(error?.cpf)
                      : error?.registration,
                  },
                  employeeIdentification: {
                    id: errorData?.identification,
                    employeeType: errorData?.employeeType,
                  },
                })
              }
              tooltipTitle="Corrigir erro"
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
}
