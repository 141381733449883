import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '@material-ui/core';
import style from '../styles.module.css';
import { styles } from '../styles';

function FakeCards() {
  return (
    <>
      <div className={style.twoCardsDetails}>
        <div className={style.cardImportDetails}>
          <div className={style.containerDetailsStatus}>
            <Typography style={styles.biggerTextStyle}>
              <Skeleton
                width={200}
                height={18}
                color="#202020"
                highlightColor="#444"
              />
            </Typography>
            <Skeleton
              width={130}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={style.nsuTypeName}>
            <div className={style.containerNsu}>
              <Skeleton
                width={32}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={32}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.containerPaymentType}>
              <Skeleton
                width={34}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.containerPaymentType}>
              <Skeleton
                width={45}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.containerPaymentType}>
              <Skeleton
                width={45}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.containerPaymentType}>
              <Skeleton
                width={45}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div className={style.containerCriationAuth}>
            <div className={style.containerCriation}>
              <Skeleton
                className={style.detailTitle}
                width={80}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{ display: 'flex', flexDirection: 'column' }}
                width={140}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '7px',
                }}
                width={120}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.containerAuth}>
              <Skeleton
                width={80}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{ display: 'flex', flexDirection: 'column' }}
                width={140}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '7px',
                }}
                width={120}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>
        </div>

        <div className={style.cardPayment}>
          <div className={style.containerTitleDate}>
            <Typography style={styles.biggerTextStyle}>
              <Skeleton
                width={130}
                height={18}
                color="#202020"
                highlightColor="#444"
              />
            </Typography>
            <Skeleton
              width={100}
              height={18}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={style.totalPayedOut}>
            <Skeleton
              width={40}
              height={10}
              color="#202020"
              highlightColor="#444"
            />
            <Skeleton
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              width={100}
              height={20}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div className={style.rollFeeImediate}>
            <div className={style.rollValue}>
              <Skeleton
                width={34}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.feeAmount}>
              <Skeleton
                width={34}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div className={style.immediateRelease}>
              <Skeleton
                width={34}
                height={10}
                color="#202020"
                highlightColor="#444"
              />
              <Skeleton
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                width={60}
                height={12}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FakeCards;
