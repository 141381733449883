import React, { useEffect, useState } from 'react';
import cep from 'cep-promise';
import axios from 'axios';

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import styles from '../styles.module.css';

import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';
import formatCEP from '../../../../utils/formatCEP';

import { states } from './mocks';

export default function AddressForm({
  disabled,
  addressDataForm,
  setAddressDataForm,
  loading,
  onSubmitForm,
  setOnSubmitForm,
}) {
  const [loadingCep, setLoadingCep] = useState(false);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if (addressDataForm.length === 0) {
      setAddressDataForm([
        {
          type: null,
          street: null,
          number: null,
          complement: null,
          zipCode: null,
          neighborhood: null,
          city: null,
          state: null,
        },
      ]);
    }
  }, []);

  function handleChangeAddressDataForm(event, index, name, value) {
    const formFields = addressDataForm;

    setOnSubmitForm(false);

    if (name === 'state') {
      formFields[index][name] = value;
      formFields[index].city = null;
      getCityByUF(index);
    } else if (name === 'city') {
      formFields[index][name] = value.nome;
    } else {
      formFields[index][name] = removeExtraSpaces(event.target.value);
    }

    setAddressDataForm([...formFields]);
  }

  function removeExtraSpaces(string) {
    return string && string?.replace(/\s{2,}/g, ' ');
  }

  function handleAddNewAddress() {
    const formFields = addressDataForm;

    if (formFields.length >= 3) {
      return;
    }

    formFields.push({
      type: '',
      street: '',
      number: '',
      complement: '',
      zipCode: '',
      neighborhood: '',
      city: '',
      state: '',
    });

    setAddressDataForm([...formFields]);
  }

  function inputValidate(name) {
    if (!addressDataForm[0][name] && onSubmitForm) {
      return true;
    }
  }

  function handleRemoveAddress() {
    const formFields = addressDataForm;

    formFields.pop();

    setAddressDataForm([...formFields]);
  }

  function findAddressByCep(index) {
    const formFields = addressDataForm;
    const currentZipCode = formFields[index].zipCode;

    setLoadingCep(true);

    cep(currentZipCode)
      .then(response => {
        formFields[index].street = response.street;
        formFields[index].city = response.city;
        formFields[index].neighborhood = response.neighborhood;
        formFields[index].state = response.state;

        setAddressDataForm([...formFields]);
      })
      .finally(() => setLoadingCep(false));
  }

  function getCityByUF(index) {
    const currentUF = addressDataForm[index]?.state;

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${currentUF}/municipios`,
      )
      .then(response => {
        setCityList(response.data);
      });
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <form className={styles.form}>
      {addressDataForm?.map((item, index) => (
        <Grid container spacing={3} className={styles.formGroup}>
          {index !== 0 && (
            <Typography className={styles.formGroupTitle}>
              Endereço adicional
            </Typography>
          )}

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <FormControl
              variant="outlined"
              className={styles.filter}
              fullWidth
              size="small"
            >
              <InputLabel id="select-outlined-label-type">
                Tipo de endereço*
              </InputLabel>
              <Select
                labelId="select-outlined-label-type"
                value={item.type}
                onChange={event => {
                  handleChangeAddressDataForm(event, index, 'type');
                }}
                label="Tipo de endereço*"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                disabled={disabled || loadingCep}
              >
                <MenuItem value="Residencial">Residencial</MenuItem>
                <MenuItem value="Cobrança">Cobrança</MenuItem>
                <MenuItem value="Comercial">Comercial</MenuItem>
                <MenuItem value="Correspondência">Correspondência</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <TextField
              label="CEP*"
              variant="outlined"
              fullWidth
              size="small"
              disabled={disabled || loadingCep}
              value={formatCEP(item.zipCode || '')}
              error={inputValidate('zipCode')}
              helperText={
                inputValidate('zipCode') && 'Esse campo é obrigatório'
              }
              onBlur={() => findAddressByCep(index)}
              onChange={event => {
                handleChangeAddressDataForm(event, index, 'zipCode');
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  findAddressByCep(index);
                  e.preventDefault();
                }
              }}
              InputProps={{
                endAdornment: loadingCep && (
                  <CircularProgress className={styles.circularLoadingCep} />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Rua*"
              variant="outlined"
              fullWidth
              size="small"
              disabled={disabled || loadingCep}
              value={item.street || ''}
              error={inputValidate('street')}
              helperText={inputValidate('street') && 'Esse campo é obrigatório'}
              onChange={event => {
                handleChangeAddressDataForm(event, index, 'street');
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Número*"
              variant="outlined"
              fullWidth
              size="small"
              disabled={disabled || loadingCep}
              value={formatOnlyNumbers(item.number)}
              error={inputValidate('number')}
              helperText={inputValidate('number') && 'Esse campo é obrigatório'}
              onChange={event => {
                handleChangeAddressDataForm(event, index, 'number');
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              label="Bairro*"
              variant="outlined"
              fullWidth
              size="small"
              disabled={disabled || loadingCep}
              value={item.neighborhood || ''}
              error={inputValidate('neighborhood')}
              helperText={
                inputValidate('neighborhood') && 'Esse campo é obrigatório'
              }
              onChange={event => {
                handleChangeAddressDataForm(event, index, 'neighborhood');
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Complemento"
              variant="outlined"
              fullWidth
              size="small"
              disabled={disabled || loadingCep}
              value={item.complement}
              onChange={event => {
                handleChangeAddressDataForm(event, index, 'complement');
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl variant="outlined" className={styles.filter} fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={states}
                getOptionLabel={option => option}
                onChange={(event, value) => {
                  handleChangeAddressDataForm(event, index, 'state', value);
                }}
                disabled={disabled || loadingCep}
                value={item.state}
                closeIcon={false}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Estado*"
                    variant="outlined"
                    size="small"
                    error={inputValidate('state')}
                    helperText={
                      inputValidate('state') && 'Esse campo é obrigatório'
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl variant="outlined" className={styles.filter} fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={cityList}
                onFocus={() => getCityByUF(index)}
                getOptionLabel={option => {
                  return option.nome || addressDataForm[index].city;
                }}
                onChange={(event, value) => {
                  handleChangeAddressDataForm(event, index, 'city', value);
                }}
                disabled={
                  disabled || loadingCep || !addressDataForm[index]?.state
                }
                value={item.city}
                closeIcon={false}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Cidade*"
                    variant="outlined"
                    size="small"
                    error={inputValidate('city')}
                    helperText={
                      inputValidate('city') && 'Esse campo é obrigatório'
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              className={styles.buttonRemoveAddress}
              disabled={disabled || addressDataForm?.length <= 1}
              onClick={handleRemoveAddress}
            >
              <DeleteForeverRoundedIcon className={styles.buttonRemoveIcon} />
              Excluir endereço
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={styles.buttonAddNew}
              disabled={disabled || loadingCep}
              onClick={handleAddNewAddress}
            >
              <AddRoundedIcon className={styles.buttonRemoveIcon} />
              Adicionar outro endereço
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
