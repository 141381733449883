import React from 'react';
import { Tab, makeStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import { EmployeesQuantityTabLabel } from '../EmployeesQuantityTabLabel';
import ApprovedEmployeesTab from './ApprovedEmployees';
import { PendingEmployeesTab } from './PendingEmployees';
import { ReprovedEmployeesTab } from './ReprovedEmployees';
import { EmployeesListModals } from '../EmployeesListModals';

export function Tabs({
  currentTab = 'approved',
  employeesTabsQuantities = {},
  handleChangeTab = () => {},
}) {
  return (
    <>
      <TabContext value={currentTab}>
        <TabList
          onChange={handleChangeTab}
          classes={{
            ...makeStyles({
              indicator: { backgroundColor: 'var(--purple)' },
              flexContainer: {
                borderBottom: '1px solid #B3B3B3',
              },
            })(),
          }}
        >
          <Tab
            label={
              <EmployeesQuantityTabLabel
                isSelected={currentTab === 'approved'}
                title="Cadastrados"
                quantity={
                  employeesTabsQuantities?.totalApprovedEmployees ?? '-'
                }
                backgroundColor="#E8F2F2"
                color="#19797F"
                backgroundColorAfterSelected="#19797F"
                colorAfterSelected="#FFF"
              />
            }
            value="approved"
            style={{ textTransform: 'capitalize' }}
          />

          <Tab
            label={
              <EmployeesQuantityTabLabel
                isSelected={currentTab === 'pending'}
                title="Em análise"
                quantity={employeesTabsQuantities?.totalPendingEmployees ?? '-'}
                backgroundColor="#FCF5E8"
                color="#E39F17"
                backgroundColorAfterSelected="#E39F17"
                colorAfterSelected="#FFF"
              />
            }
            value="pending"
            style={{ textTransform: 'capitalize' }}
          />

          <Tab
            label={
              <EmployeesQuantityTabLabel
                isSelected={currentTab === 'reproved'}
                title="Reprovados"
                quantity={
                  employeesTabsQuantities?.totalReprovedEmployees ?? '-'
                }
                backgroundColor="#FAE9E8"
                color="#CA2317"
                backgroundColorAfterSelected="#CA2317"
                colorAfterSelected="#FFF"
              />
            }
            value="reproved"
            style={{ textTransform: 'capitalize' }}
          />
        </TabList>

        <TabPanel value="approved" style={{ padding: '1.5rem 0 0 0' }}>
          <ApprovedEmployeesTab />
        </TabPanel>

        <TabPanel value="pending" style={{ padding: '1.5rem 0 0 0' }}>
          <PendingEmployeesTab />
        </TabPanel>

        <TabPanel value="reproved" style={{ padding: '1.5rem 0 0 0' }}>
          <ReprovedEmployeesTab />
        </TabPanel>
      </TabContext>
      <EmployeesListModals />
    </>
  );
}
