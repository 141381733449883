import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import styles from './styles.module.css';

import { ReactComponent as GoIcon } from '../../../../assets/go-icon.svg';
import currencyFormatter from '../../../../utils/currencyFormatter';
import convertBrazilianDate from '../../../../utils/convertBrazilianDate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: '#FFFFFF',
    marginBottom: '10px',
    alignItems: 'baseline',
  },
  column: {
    flexBasis: '100%',
  },
  summary: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'baseline',
  },
  summaryOpened: {
    alignItems: 'baseline',
  },
  expanded: {
    background: '#FFFFFF',
  },
  expandIcon: {
    marginBottom: '70px',
  }
}));

function AccordionHistory({dataPlans}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const dataPlansArray = dataPlans?.history?.content;

  function handleChange(event, isExpanded) {
    setExpanded(isExpanded ? true : false);
  };

  return (
    <>
      {dataPlansArray?.map((row, key) => (
        <div key={row.name}>
          <ExpansionPanel className={styles.expansionPanel} onChange={handleChange}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={!expanded && styles.icon} />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div className={`${classes.column} ${styles.accordionSummary}`}>
                <div className={expanded ? classes.summaryOpened : classes.summary}>
                  <p className={styles.h1}>{row.compentency}</p>
                  {expanded ? (
                    ''
                  ) : (
                    <>
                      <p>{currencyFormatter(row.total)}</p>
                      <div className={styles.nfeContainer}>
                        <p>NF-e</p>
                        <Button
                          disabled={dataPlansArray.nf === null}
                          endIcon={<GoIcon />}
                        >
                          {dataPlansArray.nf === null ? (
                            <span className={styles.nfeSummary} styles={{ color: '#3B8CED' }}>-</span>
                          ) : (
                            <span styles={{ color: '#3B8CED' }} className={styles.nfeSummary}>{dataPlansArray.nf}</span>
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div className={styles.container}>
                <p className={styles.labelPlan}>{row.description}</p>
                <p className={styles.label}>
                  Data de pagamento: {convertBrazilianDate(row.date)}
                </p>
                <p className={styles.label}>
                  Vigência do plano: {convertBrazilianDate(row.initialCurrencyDate)}-{convertBrazilianDate(row.finalCurrencyDate)}
                </p>
                <p className={styles.label}>{row.transactionType}</p>

                <div className={styles.values}>
                  <p>Assinatura</p>
                  <span>{currencyFormatter(row.signatureAmount)}</span>
                </div>

                <div className={styles.values}>
                  <p>Tarifas</p>
                  <span id={styles.valueFee}>
                    {currencyFormatter(row.feeAmount)}
                  </span>
                </div>

                <Divider />

                <div className={styles.totalValue}>
                  <p>Valor total</p>
                  <span>{currencyFormatter(row.total)}</span>
                </div>

                <Divider />

                <div className={styles.nfe}>
                  <p>NF-e</p>
                  <Button disabled={row.nf === null} endIcon={<GoIcon />}>
                    {row.nf === null ? (
                      <span styles={{ color: '#3B8CED' }}>-</span>
                    ) : (
                      row.nf
                    )}
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      ))}
    </>
  );
}

export default AccordionHistory;
