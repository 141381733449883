import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import truncateString from '../../../../../utils/truncateString';
import { EmployeesQuantityInfo } from '../Components/EmployeesQuantityInfo';
import { ReactComponent as ExcelIcon } from '../../../../../assets/Icons/files/ic_excel.svg';
import { ReactComponent as TxtIcon } from '../../../../../assets/Icons/files/ic_txt.svg';
import { ReactComponent as DefaultFileIcon } from '../../../../../assets/Icons/files/ic_file.svg';
import { ActionsEmployeesRegisterFilesDetails } from '../../../../../redux/store/Employees/RegisterFiles/FileDetails';

export function useTableConfig() {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleRenderFileIcon(fileName) {
    const fileExtension = fileName.split('.').pop();

    switch (fileExtension) {
      case 'xls':
      case 'xlsx':
        return <ExcelIcon />;
      case 'txt':
        return <TxtIcon />;
      default:
        return <DefaultFileIcon />;
    }
  }

  function handleFormatDate(date) {
    if (date && date !== undefined) {
      return moment(date).format('DD/MM/YYYY');
    }

    return '-';
  }

  function handleGoToDetails(row) {
    dispatch(ActionsEmployeesRegisterFilesDetails.setSelectedFile(row));

    history.push('/employees/register-files/details');
  }

  return [
    {
      key: 'name',
      type: 'field',
      label: 'Nome do arquivo',

      render: row => (
        <Tooltip arrow title={row?.name}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Box>{handleRenderFileIcon(row?.name)}</Box>
            <Typography>{row?.name}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'processedAt',
      type: 'field',
      label: 'Processado em',
      render: row => handleFormatDate(row?.processedAt),
    },
    {
      key: 'importedBy',
      type: 'field',
      label: 'Importado por',
      render: row =>
        row?.importedBy ? truncateString(row?.importedBy, 40) : '-',
    },
    {
      key: 'totalLines',
      type: 'field',
      label: 'Quant. de funcionários',
      render: row => <EmployeesQuantityInfo row={row} />,
    },
    {
      key: 'fileDetails',
      type: 'action',
      icon: <VisibilityOutlined color="primary" />,
      label: 'Visualizar detalhes',
      onClick: row => handleGoToDetails(row),
    },
  ];
}
