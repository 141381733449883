import React, { useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import styles from './styles.module.css';

import textCapitalize from '../../../../utils/textCapitalize';
import formatCPF from '../../../../utils/formatCPF';

import insuranceTypes from '../../../../enums/insuranceTypes';

const headCells = [
  { id: '1', label: 'Nome' },
  { id: '2', label: 'CPF' },
  { id: '3', label: 'Seguro' },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} className={styles.labelHead}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  successList,
  getResult,
  insuranceType,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    getResult();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={styles.tableContainer}>
      <div>
        <TableContainer>
          <Table>
            <EnhancedTableHead />
            <TableBody>
              {successList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(row => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.cpf}>
                      <TableCell className={styles.tableCell}>
                        {textCapitalize(row.name)}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {formatCPF(row.cpf)}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {insuranceType}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={successList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
