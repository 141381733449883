/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { api } from '../../../../services/api';
import { BaseConstructor } from '../../helpers/BaseConstructor';
import verifyNumberInString from '../../../../utils/verifyNumberInString';

const manualPayrollBase = new BaseConstructor({
  extraStates: {
    employeesAproved: {
      data: [],
      page: 0,
      perPage: 5,
      length: 0,
      currentLength: 0,
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
      filter: {},
    },

    employeesPending: {
      data: [],
      page: 0,
      perPage: 5,
      length: 0,
      currentLength: 0,
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
      filter: {},
    },

    employeesReproved: {
      data: [],
      page: 0,
      perPage: 5,
      length: 0,
      currentLength: 0,
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
      filter: {},
    },

    employeeBankData: {
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
      successOn: {
        hasSuccess: false,
        message: '',
      },
    },

    costAndCenter: {
      data: [],
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
    },

    banksList: {
      data: [],
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
    },

    selectedRowsEmployees: [],
    previewSelected: [],
    selectedEmployeeToInsertBankData: {},
    isOpenInsertBankDataDrawer: false,
    isOpenInsertBankDataDialog: false,
  },
});

const searchEmployees = createAsyncThunk(
  'manualPayroll/search/employeesAproved',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    const {
      employeesAproved: { filter, page, perPage },
    } = getState().payroll.manualPayroll;

    const { nameOrCpf, searchSituation, costAndCenterList } = filter;

    const isNumber = verifyNumberInString(nameOrCpf);
    const requestOptions = {
      params: {
        active: true,
        page,
        size: perPage,
        cpf: isNumber ? nameOrCpf : '',
        name: !isNumber ? nameOrCpf : '',
        status: searchSituation,
        location: costAndCenterList,
      },
    };

    return api
      .get(`/employee/search/${currentCompany.code}`, requestOptions)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const searchEmployeesPending = createAsyncThunk(
  'manualPayroll/search/employeesPending',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    const {
      employeesPending: { filter, page, perPage },
    } = getState().payroll.manualPayroll;

    const requestOptions = {
      params: {
        page,
        size: perPage,
      },
    };

    const requestBody = {
      nameOrCpf: filter.nameOrCpf,
    };

    return api
      .post(
        `/employees/pending/${currentCompany.code}`,
        requestBody,
        requestOptions,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const searchEmployeesReproved = createAsyncThunk(
  'manualPayroll/search/employeesReproved',
  async (_, { getState, rejectWithValue }) => {
    const { employeesReproved } = getState().payroll.manualPayroll;
    const { currentCompany } = getState().companies;

    const requestOptions = {
      params: {
        page: employeesReproved.page,
        size: employeesReproved.perPage,
      },
    };

    const requestBody = {
      nameOrCpf: employeesReproved.filter.nameOrCpf,
    };

    return api
      .post(
        `/employees/reproved/${currentCompany.code}`,
        requestBody,
        requestOptions,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const updateEmployeeBankData = createAsyncThunk(
  'manualPayroll/update/employeeBankData',
  async (payload, { rejectWithValue }) => {
    return api
      .patch('/employees/bankData', payload)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const getCostAndCenter = createAsyncThunk(
  'manualPayroll/get/CostAndCenter',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    return api
      .get(`companies/${currentCompany.code}/locations`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const getBanksList = createAsyncThunk(
  'manualPayroll/get/banksList',
  async (_, { rejectWithValue }) => {
    return api
      .get('/accountCashIn/bank/findAll')
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const getEmployeesCount = createAsyncThunk(
  'manualPayroll/getEmployeesCount',
  async (_, { getState, rejectWithValue }) => {
    const companyCode = getState().companies.currentCompany.code;

    return api
      .get(`employees/count/${companyCode}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const searchSimpleApprovedList = createAsyncThunk(
  'manualPayroll/searchSimpleApprovedList',
  async (_, { getState, rejectWithValue }) => {
    const companyCode = getState().companies.currentCompany.code;

    const {
      nameOrCpf,
      searchSituation,
      costAndCenterList,
    } = getState().payroll.manualPayroll.employeesAproved.filter;

    const isNumber = verifyNumberInString(nameOrCpf);

    const requestBody = {
      cpf: isNumber ? nameOrCpf : '',
      name: !isNumber ? nameOrCpf : '',
      location: costAndCenterList,
      status: searchSituation,
    };

    return api
      .post(`/employee/search/basic/${companyCode}`, requestBody)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const searchSimplePendingList = createAsyncThunk(
  'manualPayroll/searchSimplePendingList',
  async (_, { getState, rejectWithValue }) => {
    const companyCode = getState().companies.currentCompany.code;

    const {
      nameOrCpf,
    } = getState().payroll.manualPayroll.employeesPending.filter;

    const requestBody = {
      nameOrCpf,
    };

    return api
      .post(`/employee/search/basic/${companyCode}/pending`, requestBody)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const searchSimpleReprovedList = createAsyncThunk(
  'manualPayroll/searchSimpleReprovedList',
  async (_, { getState, rejectWithValue }) => {
    const { accountSelected } = getState().account;
    const companyCode = getState().companies.currentCompany.code;

    const {
      nameOrCpf,
      searchSituation,
      costAndCenterList,
    } = getState().payroll.manualPayroll.employeesReproved.filter;

    const isNumber = verifyNumberInString(nameOrCpf);

    const requestBody = {
      cpf: isNumber ? nameOrCpf : '',
      name: !isNumber ? nameOrCpf : '',
      location: costAndCenterList,
      status: searchSituation,
      companyEncodedKey: accountSelected?.encodedKey,
    };

    return api
      .post(`/employee/search/basic/${companyCode}/reproved`, requestBody)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors?.[0].errorDetail),
      );
  },
);

const manualPayrollSlice = createSlice({
  name: 'manualPayroll',
  initialState: manualPayrollBase.initialState,
  reducers: {
    ...manualPayrollBase.methods,

    setFilterEmployeesAproved: (state, { payload }) => {
      state.employeesAproved.filter = payload;
    },

    setFilterEmployeesPending: (state, { payload }) => {
      state.employeesPending.filter = payload;
    },

    setFilterEmployeesReproved: (state, { payload }) => {
      state.employeesReproved.filter = payload;
    },

    setApprovedPagination: (state, { payload: { page, perPage } }) => {
      state.employeesAproved = {
        ...state.employeesAproved,
        page,
        perPage,
      };
    },

    setPendingPagination: (state, { payload: { page, perPage } }) => {
      state.employeesPending = {
        ...state.employeesPending,
        page,
        perPage,
      };
    },

    setReprovedPagination: (state, { payload: { page, perPage } }) => {
      state.employeesReproved = {
        ...state.employeesReproved,
        page,
        perPage,
      };
    },

    setHandlePageChangeEmployeesAproved: (state, { payload }) => {
      state.employeesAproved.page = payload;
    },

    setHandlePerPageChangeEmployeesAproved: (state, { payload }) => {
      state.employeesAproved.perPage = payload;
    },

    setHandlePageChangeEmployeesPending: (state, { payload }) => {
      state.employeesPending.page = payload;
    },

    setHandlePerPageChangeEmployeesPending: (state, { payload }) => {
      state.employeesPending.perPage = payload;
    },

    setHandlePageChangeEmployeesReproved: (state, { payload }) => {
      state.employeesReproved.page = payload;
    },

    setHandlePerPageChangeEmployeesReproved: (state, { payload }) => {
      state.employeesReproved.perPage = payload;
    },

    setIsOpenInsertBankDataDrawer: (state, { payload }) => {
      state.isOpenInsertBankDataDrawer = payload;
    },

    setIsOpenInsertBankDataDialog: (state, { payload }) => {
      state.isOpenInsertBankDataDialog = payload;
    },

    setSelectedRowsEmployees: (state, { payload }) => {
      state.selectedRowsEmployees = payload;
    },

    setPreviewSelected: (state, { payload }) => {
      state.previewSelected = payload;
    },

    setSelectedEmployeeToInsertBankData: (state, { payload }) => {
      state.selectedEmployeeToInsertBankData = payload;
    },

    resetSuccessStates: state => {
      state.employeeBankData.successOn = { hasSuccess: false, message: '' };
    },
  },
  extraReducers: {
    // searchEmployees
    [searchEmployees.pending]: state => {
      state.employeesAproved.isLoading = true;
      state.employeesAproved.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [searchEmployees.fulfilled]: (state, { payload }) => {
      state.employeesAproved.isLoading = false;
      state.employeesAproved.data = payload.data.content;
      state.employeesAproved.length = payload.data.totalSize;
    },
    [searchEmployees.rejected]: (state, { payload }) => {
      state.employeesAproved.isLoading = false;
      state.employeesAproved.errorOn.hasError = true;

      if (!payload) {
        state.employeesAproved.errorOn.message =
          'Houve um erro ao buscar os funcionários';
      } else state.employeesAproved.errorOn.message = payload;
    },

    // searchSimpleApprovedList
    [searchSimpleApprovedList.pending]: state => {
      state.employeesAproved = {
        ...state.employeesAproved,
        isLoading: true,
        errorOn: {
          ...state.employeesAproved.errorOn,
          hasError: false,
          message: '',
        },
      };
    },
    [searchSimpleApprovedList.fulfilled]: (state, { payload: { data } }) => {
      state.employeesAproved = {
        ...state.employeesAproved,
        isLoading: false,
        data,
        currentLength: data?.length,
      };
    },
    [searchSimpleApprovedList.rejected]: (state, { payload }) => {
      state.employeesAproved = {
        ...state.employeesAproved,
        isLoading: false,
        errorOn: {
          ...state.employeesAproved.errorOn,
          hasError: true,
          message: payload || 'Houve um erro ao buscar os funcionários',
        },
      };
    },

    // searchEmployeesPending
    [searchEmployeesPending.pending]: state => {
      state.employeesPending.isLoading = true;
      state.employeesPending.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [searchEmployeesPending.fulfilled]: (state, { payload }) => {
      state.employeesPending.isLoading = false;
      state.employeesPending.data = payload.data.content;
      state.employeesPending.length = payload.data.totalSize;
    },
    [searchEmployeesPending.rejected]: (state, { payload }) => {
      state.employeesPending.isLoading = false;
      state.employeesPending.errorOn.hasError = true;

      if (!payload) {
        state.employeesPending.errorOn.message =
          'Houve um erro ao buscar os funcionários pendentes';
      } else state.employeesPending.errorOn.message = payload;
    },

    // searchSimplePendingList
    [searchSimplePendingList.pending]: state => {
      state.employeesPending = {
        ...state.employeesPending,
        isLoading: true,
        errorOn: {
          ...state.employeesPending.errorOn,
          hasError: false,
          message: '',
        },
      };
    },
    [searchSimplePendingList.fulfilled]: (state, { payload: { data } }) => {
      state.employeesPending = {
        ...state.employeesPending,
        isLoading: false,
        data,
        currentLength: data?.length,
      };
    },
    [searchSimplePendingList.rejected]: (state, { payload }) => {
      state.employeesPending = {
        ...state.employeesPending,
        isLoading: false,
        errorOn: {
          ...state.employeesPending.errorOn,
          hasError: true,
          message: payload || 'Houve um erro ao buscar os funcionários',
        },
      };
    },

    // searchEmployeesReproved
    [searchEmployeesReproved.pending]: state => {
      state.employeesReproved.isLoading = true;
      state.employeesReproved.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [searchEmployeesReproved.fulfilled]: (state, { payload }) => {
      state.employeesReproved.isLoading = false;
      state.employeesReproved.data = payload.data.content;
      state.employeesReproved.length = payload.data.totalSize;
    },
    [searchEmployeesReproved.rejected]: (state, { payload }) => {
      state.employeesReproved.isLoading = false;
      state.employeesReproved.errorOn.hasError = true;

      if (!payload) {
        state.employeesReproved.errorOn.message =
          'Houve um erro ao buscar os funcionários reprovados';
      } else state.employeesReproved.errorOn.message = payload;
    },

    // searchSimpleReprovedList
    [searchSimpleReprovedList.pending]: state => {
      state.employeesReproved = {
        ...state.employeesReproved,
        isLoading: true,
        errorOn: {
          ...state.employeesReproved.errorOn,
          hasError: false,
          message: '',
        },
      };
    },
    [searchSimpleReprovedList.fulfilled]: (state, { payload: { data } }) => {
      state.employeesReproved = {
        ...state.employeesReproved,
        isLoading: false,
        data,
        currentLength: data?.length,
      };
    },
    [searchSimpleReprovedList.rejected]: (state, { payload }) => {
      state.employeesReproved = {
        ...state.employeesReproved,
        isLoading: false,
        errorOn: {
          ...state.employeesReproved.errorOn,
          hasError: true,
          message: payload || 'Houve um erro ao buscar os funcionários',
        },
      };
    },

    // getEmployeesCount
    [getEmployeesCount.pending]: state => {
      state.employeesAproved.isLoading = true;
      state.employeesPending.isLoading = true;
      state.employeesReproved.isLoading = true;
    },
    [getEmployeesCount.fulfilled]: (state, { payload: { data } }) => {
      state.employeesAproved = {
        ...state.employeesAproved,
        isLoading: false,
        length: data?.approvedEmployee,
      };

      state.employeesPending = {
        ...state.employeesPending,
        isLoading: false,
        length: data?.pendingEmployee,
      };

      state.employeesReproved = {
        ...state.employeesReproved,
        isLoading: false,
        length: data?.reprovedEmployee,
      };
    },

    [getEmployeesCount.rejected]: (state, { payload }) => {
      state.employeesAproved.isLoading = false;
      state.employeesPending.isLoading = false;
      state.employeesReproved.isLoading = false;

      state.employeesAproved.errorOn = {
        ...state.employeesAproved.errorOn,
        hasError: true,
        message:
          payload || 'Houve um erro ao buscar a quantidade de funcionários.',
      };
    },

    // updateEmployeeBankData
    [updateEmployeeBankData.pending]: state => {
      state.employeeBankData.isLoading = true;
      state.employeeBankData.errorOn = {
        hasError: false,
        message: '',
      };
      state.employeeBankData.successOn = {
        hasSuccess: false,
        message: '',
      };
    },
    [updateEmployeeBankData.fulfilled]: (state, { payload }) => {
      state.employeeBankData.isLoading = false;
      state.employeeBankData.successOn = {
        hasSuccess: true,
        message: 'Conta bancaria inserida com sucesso!',
      };
    },
    [updateEmployeeBankData.rejected]: (state, { payload }) => {
      state.employeeBankData.isLoading = false;
      state.employeeBankData.errorOn.hasError = true;

      if (!payload) {
        state.employeeBankData.errorOn.message =
          'Houve um erro ao atualizar os dados bancários do funcionário';
      } else state.employeeBankData.errorOn.message = payload;
    },

    // getCostAndCenter
    [getCostAndCenter.pending]: state => {
      state.costAndCenter.isLoading = true;
      state.costAndCenter.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [getCostAndCenter.fulfilled]: (state, { payload }) => {
      state.costAndCenter.isLoading = false;
      state.costAndCenter.data = payload.data;
    },
    [getCostAndCenter.rejected]: (state, { payload }) => {
      state.costAndCenter.isLoading = false;
      state.costAndCenter.errorOn.hasError = true;

      if (!payload) {
        state.costAndCenter.errorOn.message =
          'Houve um erro ao buscar os centros de custo';
      } else state.costAndCenter.errorOn.message = payload;
    },

    // getBanks
    [getBanksList.pending]: state => {
      state.banksList.isLoading = true;
      state.banksList.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [getBanksList.fulfilled]: (state, { payload }) => {
      state.banksList.isLoading = false;
      state.banksList.data = payload.data;
    },
    [getBanksList.rejected]: (state, { payload }) => {
      state.banksList.isLoading = false;
      state.banksList.errorOn.hasError = true;

      if (!payload) {
        state.banksList.errorOn.message = 'Houve um erro ao buscar os bancos';
      } else state.banksList.errorOn.message = payload;
    },
  },
});

const manualPayrollReducer = manualPayrollSlice.reducer;
const ActionsManualPayroll = {
  ...manualPayrollSlice.actions,
  getEmployeesCount,
  searchSimpleApprovedList,
  searchSimplePendingList,
  searchSimpleReprovedList,
  searchEmployees,
  searchEmployeesPending,
  searchEmployeesReproved,
  updateEmployeeBankData,
  getCostAndCenter,
  getBanksList,
};

export { manualPayrollReducer, ActionsManualPayroll };
