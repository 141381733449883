import * as yup from 'yup';

export default yup.object().shape(
  {
    keyType: yup
      .string()
      .oneOf(['CNPJ', 'PHONE', 'EMAIL', 'EVP'])
      .required(),
    key: yup
      .string()
      .when('keyType', {
        is: val => val === 'EMAIL',
        then: () =>
          yup
            .string()
            .email()
            .required(),
        otherwise: () => yup.string().required(),
      })
      .when('keyType', {
        is: val => val === 'EVP',
        then: () => yup.string().notRequired(),
        otherwise: () => yup.string().required(),
      }),
  },
  ['keyType', 'key'],
);
