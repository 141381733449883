import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

import FakeList from '../../../components/FakeList';

import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';

import { ReactComponent as NotFoundIcon } from '../../../assets/not-found.svg';

function MobileList({
  loading,
  setPageSize,
  pageSize,
  data,
  changedFilter,
  value,
  handleEditCoastCenterAndSectors,
  handleCostCenterAndSectorDeleteModal,
  titleOfItem,
}) {
  const [fetchMoreData, setFetchMoreData] = useState(false);
  const emptyList = 0;

  function fetchData() {
    setPageSize(pageSize + 10);
    setFetchMoreData(true);
  }

  return loading && !fetchMoreData ? (
    <FakeList />
  ) : (
    <div className={styles.container}>
      {data?.content.length === emptyList && changedFilter ? (
        <div className={styles.resultNotFound}>
          <NotFoundIcon />
          <Typography className={styles.resultNotFoundText}>
            Nenhum resultado encontrado
          </Typography>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={data ? data?.content?.length : 10}
          next={fetchData}
          hasMore
          loader={
            fetchData &&
            data?.currentPageSize < pageSize - 5 && (
              <div className={styles.circularProgressContainer}>
                <CircularProgress
                  size={25}
                  className={styles.circularProgress}
                />
              </div>
            )
          }
        >
          {data?.content?.map(item => (
            <div className={styles.card}>
              <div>
                <Typography className={styles.registration}>
                  {item.code}
                </Typography>
                <Typography className={styles.name}>
                  {item.description}
                </Typography>

                {isMobile ? (
                  <>
                    <Button
                      className={styles.editButton}
                      onClick={() => handleEditCoastCenterAndSectors(item)}
                    >
                      <Grid container alignItems="center">
                        <Grid item>
                          <CreateIcon className={styles.colorIconTable} />
                        </Grid>

                        <Grid item>
                          <Typography className={styles.buttonPurpleLabel}>
                            Editar
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                    <Button
                      className={styles.editButton}
                      onClick={() =>
                        handleCostCenterAndSectorDeleteModal(item, titleOfItem)
                      }
                    >
                      <Grid container alignItems="center">
                        <Grid item>
                          <DeleteOutlined className={styles.colorIconTable} />
                        </Grid>

                        <Grid item>
                          <Typography className={styles.buttonPurpleLabel}>
                            Excluir
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </>
                ) : (
                  value === 0 && (
                    <IconButton
                      onClick={() => handleEditCoastCenterAndSectors(item)}
                    >
                      <Tooltip title="Editar">
                        <EditOutlinedIcon className={styles.colorIconTable} />
                      </Tooltip>
                    </IconButton>
                  )
                )}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default MobileList;
