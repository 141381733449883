import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import useDebounce from '../../../../../../../../hooks/useDebounce';
import { ActionsManualPayroll } from '../../../../../../../../redux/store/Payroll/ManualPayroll';

export function useFilters() {
  const dispatch = useDispatch();
  const [nameOrCpf, setNameOrCpf] = useState('');

  const nameOrCpfDebounced = useDebounce(nameOrCpf, 500);

  function handleClearFilters() {
    setNameOrCpf('');
  }

  useEffect(() => {
    dispatch(
      ActionsManualPayroll.setFilterEmployeesAproved({
        nameOrCpf,
      }),
    );
    dispatch(ActionsManualPayroll.searchEmployees());
  }, [nameOrCpfDebounced]);

  return {
    nameOrCpfFieldObject: {
      nameOrCpf,
      setNameOrCpf,
    },
    handleClearFilters,
  };
}
