import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyInput from 'react-currency-input';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
} from '@material-ui/core';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import DescriptionIcon from '@material-ui/icons/Description';

import textCapitalize from '../../../../utils/textCapitalize';

import styles from './styles.module.css';
import { ActionsManualPayroll } from '../../../../redux/store/Payroll/ManualPayroll';
import formatCPF from '../../../../utils/formatCPF';
import { ChipStatus } from '../../../../components/ChipStatus';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'cpf', numeric: true, disablePadding: false, label: 'CPF' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  {
    id: 'costCenter',
    numeric: false,
    disablePadding: false,
    label: 'Centro de custo',
  },
  {
    id: 'employeeStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.id === 'actions' ? 'center' : 'left'}
            className={styles.tableCellHeader}
          >
            {headCell.id === 'actions' ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        <TableCell className={styles.tableCellHeader} />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  manualPaymentEmployees,
  selectedEmployees,
  setSelectedEmployees,
  SHOW_BOTTOM_BAR,
  loading,
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: 25,
    },
    paper: {
      width: '100%',
      marginBottom: SHOW_BOTTOM_BAR ? 70 : -60,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    header: {
      background: '#ffffff',
    },
  }));

  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const inputValueModal = useSelector(
    state => state.applyValueInputs.inputValue,
  );

  const { selectedRowsEmployees } = useSelector(
    state => state.payroll.manualPayroll,
  );

  useEffect(() => {
    if (inputValueModal) {
      manualPaymentEmployees.map((item, index) => {
        const auxList = [...manualPaymentEmployees];

        auxList[index].amount = inputValueModal;

        sessionStorage.setItem(
          'manualPaymentEmployees',
          JSON.stringify(auxList.flat(Infinity)),
        );

        setSelectedEmployees(auxList.flat(Infinity));
      });
    }
  }, [inputValueModal]);

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onChangeCurrentTextFild(event, row, value) {
    const auxList = [...manualPaymentEmployees];
    const amount = value;
    const index = auxList.findIndex(item => item.cpf === row.cpf);

    dispatch({
      type: 'SET_VALUE_INPUTS',
      inputValue: '',
    });

    auxList[index].amount = amount;

    sessionStorage.setItem(
      'manualPaymentEmployees',
      JSON.stringify(auxList.flat(Infinity)),
    );

    setSelectedEmployees(auxList.flat(Infinity));
  }

  function handleRemoveEmployee(row) {
    const auxArray = manualPaymentEmployees;
    const index = auxArray.findIndex(item => item.cpf === row.cpf);
    const newEmployeeList = [...selectedRowsEmployees];

    newEmployeeList.splice(index, 1);

    auxArray.splice(index, 1);

    setSelectedEmployees([...auxArray]);
    sessionStorage.setItem('manualPaymentEmployees', JSON.stringify(auxArray));

    dispatch(ActionsManualPayroll.setSelectedRowsEmployees(newEmployeeList));
  }

  function handleEmployeeSituationLabel(situation) {
    const situationEnums = {
      ACTIVE: 'Ativo',
      RESIGN: 'Demitido',
      INSS_LICENSE: 'Licença INSS',
      UNPAID_LICENSE: 'Licença não remunerada',
      HOLIDAYS: 'Férias',
    };
    return situationEnums[situation];
  }

  function handleEmployeeSituation(situation) {
    const situationEnums = {
      ACTIVE: 'info',
      RESIGN: 'error',
      INSS_LICENSE: 'success',
      UNPAID_LICENSE: 'success',
      HOLIDAYS: 'success',
    };
    return situationEnums[situation];
  }

  function handleEmployeeSituationIcon(situation) {
    const situationEnums = {
      ACTIVE: <CheckCircleRoundedIcon style={{ color: '#2D54B9' }} />,
      RESIGN: <RemoveCircleRoundedIcon style={{ color: '#CA2317' }} />,
      INSS_LICENSE: <DescriptionIcon style={{ color: '#19797F' }} />,
      UNPAID_LICENSE: <DescriptionIcon style={{ color: '#19797F' }} />,
      HOLIDAYS: <BeachAccessIcon style={{ color: '#19797F' }} />,
    };
    return situationEnums[situation];
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={styles.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={selectedEmployees.length}
            />
            <TableBody>
              {stableSort(selectedEmployees, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.cpf}>
                      <TableCell className={styles.tableCell}>
                        {row.cpf ? formatCPF(row?.cpf) : '-'}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {textCapitalize(row.name)}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {row.location ?? 'Não cadastrado'}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        <ChipStatus
                          icon={handleEmployeeSituationIcon(
                            row?.status ?? 'ACTIVE',
                          )}
                          status={handleEmployeeSituation(
                            row?.status ?? 'ACTIVE',
                          )}
                          label={handleEmployeeSituationLabel(
                            row?.status ?? 'ACTIVE',
                          )}
                          size="small"
                        />
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        <CurrencyInput
                          onChange={(event, value) =>
                            onChangeCurrentTextFild(event, row, value)
                          }
                          value={row.amount}
                          className={styles.inputValue}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix="R$ "
                          disabled={loading}
                        />
                      </TableCell>
                      <TableCell align="center" className={styles.tableCell}>
                        <IconButton
                          className={styles.removeEmployeeIconButton}
                          onClick={() => handleRemoveEmployee(row)}
                          disabled={loading}
                        >
                          <CloseRoundedIcon
                            className={styles.removeEmployeeIcon}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={selectedEmployees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
