import { useEffect, useState } from 'react';

export function useCountdown(timeInSeconds, start) {
  const [countdownSeconds, setCountdownSeconds] = useState(timeInSeconds);

  useEffect(() => {
    if (countdownSeconds > 0 && start) {
      setTimeout(() => {
        setCountdownSeconds(prevState => prevState - 1);
      }, 1000);
    } else {
      setCountdownSeconds(timeInSeconds);
    }
  }, [countdownSeconds, start]);

  return { currentTime: countdownSeconds };
}
