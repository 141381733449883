import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AddOutlined } from '@material-ui/icons';
import { Fab, Tab } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';

import { Header } from '../../../components/Header';
import Buttons from '../../../components/Buttons';
import UsersTab from './components/Tabs/UsersTab';
import ActivitiesTab from './components/Tabs/ActivitiesTab';

import { isMobile } from '../../../utils/breakpoints';

import { useStyles } from './styles';

export default function ManageUsersPage() {
  const styles = useStyles();
  const history = useHistory();

  const [currentTab, changeTab] = useState('users');

  const handleChangeTab = (_, value) => {
    changeTab(value);
  };

  const goToNewUserPage = () => {
    history.push('/employees/manage-users/new');
  };

  return (
    <>
      <Header
        title="Gerenciar utilizadores"
        buttons={
          <>
            {isMobile ? (
              <Fab
                color="primary"
                className={styles.fab}
                aria-label="Novo utilizador"
                onClick={goToNewUserPage}
              >
                <AddOutlined />
              </Fab>
            ) : (
              <Buttons.PrimaryButton
                className={styles.button}
                title="Novo utilizador"
                icon={<AddOutlined />}
                onClick={goToNewUserPage}
              />
            )}
          </>
        }
      />

      <TabContext value={currentTab}>
        <TabList onChange={handleChangeTab} indicatorColor="primary">
          <Tab value="users" className={styles.tab} label="Utilizadores" />
          <Tab value="activities" className={styles.tab} label="Atividades" />
        </TabList>
        <TabPanel className={styles.tabpanel} value="users">
          <UsersTab />
        </TabPanel>
        <TabPanel className={styles.tabpanel} value="activities">
          <ActivitiesTab />
        </TabPanel>
      </TabContext>
    </>
  );
}
