import React from 'react';
import PaymentCardIcon from '@material-ui/icons/DescriptionRounded';
import styles from './styles.module.css';

export const actions = [
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'PDF',
    onClick: 'PDF_EXTRACT',
    value: 'FILE_PDF',
  },
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'CSV',
    onClick: 'CSV_EXTRACT',
    value: 'FILE_CSV',
  },

];
