import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory  } from 'react-router-dom';

import Header from './Header';
import Title from './Title';
import Cards from './Cards';
import Table from './Table/Table';

import ProgressToolBar from '../../../components/ProgressToolBar';
import TablePaginationActions from './Table/TablePaginationActions';

import { api } from '../../../services/api';
import LogoLoading from '../../../components/LogoLoading';

export default function PaycheckSuccess({
  match: { params: { fileId } }
}) {
  const history = useHistory();

  const initialPagination = { totalSize: 0, pageSize: 10, currentPage: 0 };

  const [totalSize, setTotalSize] = useState(initialPagination.totalSize);
	const [pageSize, setPageSize] = useState(initialPagination.pageSize);
	const [currentPage, setCurrentPage] = useState(initialPagination.currentPage);

  const [paycheckData, setPaycheckData] = useState({});
  const [paychecks, setPaychecks] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingPaychecks, setLoadingPaychecks] = useState(true);
  const [loadingPaycheckDetail, setLoadingPaycheckDetail] = useState(true);

  const paychecksLoadingOn = () => setLoadingPaychecks(true);
  const paychecksLoadingOff = () => setLoadingPaychecks(false);

  const paycheckDetailLoadingOn = () => setLoadingPaycheckDetail(true);
  const paycheckDetailLoadingOff = () => setLoadingPaycheckDetail(false);

  const isLoadingOff = () => setIsLoading(false);

	const handlePageChange = (_, newPage) => setCurrentPage(newPage);

	const handleRowsPerPageChange = event => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	}

  function handleNext() {
    history.push('/paycheck');
  };

  function getPaycheckDetails() {
    paycheckDetailLoadingOn();

    api.get(`/paychecks/file/${fileId}`)
      .then(({ data }) => {
        setPaycheckData(data);
      })
      .finally(() => paycheckDetailLoadingOff());
  }

  function getPaychecks() {
    paychecksLoadingOn();

    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize
      },
    };

    api.get(`/paychecks/file/${fileId}/search`, requestOptions)
      .then(({ data }) => {
        setPaychecks(data.content);
        setTotalSize(data.totalSize);
      })
      .finally(() => paychecksLoadingOff());
  }

  useEffect(() => {
    if(!loadingPaycheckDetail && !loadingPaychecks)
      isLoadingOff();
  }, [loadingPaycheckDetail, loadingPaychecks]);

	useEffect(() => {
		getPaychecks();
	}, [currentPage, pageSize]);

  useEffect(() => {
    getPaychecks();
    getPaycheckDetails();
  }, []);

  if(isLoading)
    return <LogoLoading />

  return (
    <>
      <Header />
      <Title />
      <Cards paycheckData={paycheckData} />
      <Table
        data={paychecks}
        isLoading={loadingPaychecks}
        totalSize={totalSize}
        pageSize={pageSize}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        TablePaginationActions={TablePaginationActions}
      />
      <ProgressToolBar
        onNextClicked={handleNext}
        nextButtonText="Gerenciar contra cheques"
        disableBack
      />
    </>
  )
}
