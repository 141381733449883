import React from 'react';
import { Box, Typography } from '@material-ui/core';

import Buttons from '../../../../components/Buttons';

import { ReactComponent as PixKeyIllustration } from '../../../../assets/pix-key-illustration.svg';

import { useStyles } from './styles';

export default function PixKeysEmptyState({ goToNewKeyPage }) {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.textContainer}>
        <PixKeyIllustration />
        <Typography component="h2">Nenhuma chave Pix cadastrada.</Typography>
        <Typography component="p">
          Crie uma nova chave para começar a receber Pix na sua conta.
        </Typography>
      </Box>
      <Box className={styles.buttonContainer}>
        <Buttons.PrimaryButton
          size="large"
          variant="outlined"
          color="primary"
          title="Nova chave Pix"
          onClick={goToNewKeyPage}
        />
      </Box>
    </Box>
  );
}
