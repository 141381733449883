import React from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DefaultModalHeader } from './components/DefaultModalHeader';
import { DefaultModalFooter } from './components/DefaultModalFooter';

export function DefaultModal({
  title = '',
  subtitle = '',
  description = '',
  open = false,
  hasBottomBar = true,
  errorOn = { hasError: false, message: '' },
  onClose = () => {},
  children = <></>,
  firstButtonText = 'Confirmar',
  firstButtonIcon = null,
  firstButtonDisabled = false,
  firstButtonOnClick = () => {},
  secondButtonShow = true,
  secondButtonText = 'Cancelar',
  secondButtonIcon = null,
  secondButtonDisabled = false,
  secondButtonOnClick = () => {},
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: makeStyles({
          paper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
            padding: ' 1.5rem',
            borderRadius: '0.5rem',
            overflowX: 'hidden',
          },
        })().paper,
      }}
    >
      <DefaultModalHeader title={title} subtitle={subtitle} />

      <Box sx={{ width: '100%', display: 'flex' }}>
        {description ? (
          <Typography variant="subtitle1">{description}</Typography>
        ) : (
          children
        )}
      </Box>

      {hasBottomBar && (
        <DefaultModalFooter
          errorOn={errorOn}
          firstButtonText={firstButtonText}
          firstButtonIcon={firstButtonIcon}
          firstButtonDisabled={firstButtonDisabled}
          firstButtonOnClick={firstButtonOnClick}
          secondButtonShow={secondButtonShow}
          secondButtonText={secondButtonText}
          secondButtonIcon={secondButtonIcon}
          secondButtonDisabled={secondButtonDisabled}
          secondButtonOnClick={secondButtonOnClick}
        />
      )}
    </Dialog>
  );
}
