import React from 'react';

import { Button } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import Title from '../../../components/Title';

import { ReactComponent as FilterIcon } from '../../../assets/filter-icon.svg';

import styles from './styles.module.css';

export default function Header({ goToImportPaycheck, setOpenDrawer }) {
  return (
    <div className={styles.header}>
      <Title>Contra cheques</Title>

      <Button className={styles.buttonHeader} onClick={goToImportPaycheck}>
        <AddRoundedIcon className={styles.buttonHeaderIcon} />
        Importar contra cheque
      </Button>

      <Button
        className={styles.filterButton}
        onClick={() => setOpenDrawer(true)}
      >
        <FilterIcon className={styles.filterButtonIcon} />
        Filtrar
      </Button>
    </div>
  );
}
