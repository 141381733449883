export function useAutocompleteField() {
  function findValueInArray(array, valueField, getOptionValue) {
    const selectedOptions = array.filter(
      option => getOptionValue(option) === valueField,
    );

    return selectedOptions.length > 0 ? selectedOptions[0] : null;
  }

  return { findValueInArray };
}
