import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CashOutActions as Actions } from '../../../../redux/store/CashOut';
import { accountTypeOptions, keyOptions } from '../constants';

const steps = ['Dados do recebedor', 'Confirmação'];
const tabs = ['Chave Pix', 'Agência e conta', 'Pix Copia e cola'];

export default function useCashoutPix() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, currentStep, bankOptions, form, error } = useSelector(
    state => state.cashOut,
  );

  const onNext = form => {
    if (currentStep === 0) {
      dispatch(Actions.setFormState(form));
      if (!form.keyType) dispatch(Actions.changeStep(1));
      return;
    }

    dispatch(Actions.setPixConfirmation());
    if (!error.hasError && !error.message) {
      setTimeout(() => history.push('/cash-out/manage'), 1000);
    }
  };

  const onBack = () => {
    if (currentStep === 1) {
      dispatch(Actions.changeStep(0));
      return;
    }

    history.goBack();
  };

  useEffect(() => {
    dispatch(Actions.resetForm());
    dispatch(Actions.changeStep(0));
    dispatch(Actions.getBankOptions());
  }, []);

  return {
    context: {
      accountTypeOptions,
      bankOptions,
      defaultValues: form,
      isLoading,
      keyOptions,
      onNext,
      onBack,
    },
    meta: { steps, tabs, currentStep },
  };
}
