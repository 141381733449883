import React from 'react';
import { Box, MenuItem, Tooltip } from '@material-ui/core';
import Buttons from '../../../../../../../Buttons';

export function TableActions({ actionsArray = [], row = {} }) {
  return actionsArray.length > 2 ? (
    <>
      <Buttons.IconButton
        icon={actionsArray?.[0]?.icon}
        color={actionsArray?.[0]?.color}
        tooltipTitle={actionsArray?.[0]?.label}
        disabled={actionsArray?.[0]?.disabled}
        tooltipTitleDisabled={actionsArray?.[0]?.disabledLabel}
        loading={actionsArray?.[0]?.isLoading}
        onClick={() => actionsArray?.[0]?.onClick(row)}
      />
      <Buttons.MoreButtonMenu
        disabled={actionsArray?.every((value, index) =>
          index !== 0 ? value?.disabled : false,
        )}
        colorButton={theme => theme.palette.primary.main}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', px: 1 }}>
          {actionsArray?.map(
            (value, index) =>
              index !== 0 && (
                <Tooltip
                  arrow
                  title={value?.disabled ? value?.disabledLabel : ''}
                >
                  <MenuItem
                    disabled={value?.disabled}
                    onClick={() => value?.onClick(row)}
                    sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                  >
                    {value?.icon}
                    {value?.label}
                  </MenuItem>
                </Tooltip>
              ),
          )}
        </Box>
      </Buttons.MoreButtonMenu>
    </>
  ) : (
    actionsArray?.map(value => (
      <Buttons.IconButton
        icon={
          typeof value?.icon === 'function' ? value?.icon(row) : value?.icon
        }
        color={value?.color}
        tooltipTitle={value?.label}
        disabled={
          typeof value?.disabled === 'function'
            ? value?.disabled(row)
            : value?.disabled
        }
        tooltipTitleDisabled={value?.disabledLabel}
        loading={
          typeof value?.isLoading === 'function'
            ? value?.isLoading(row)
            : value?.isLoading
        }
        onClick={event => value?.onClick(row, event)}
      />
    ))
  );
}
