import formatCpf from '../../utils/formatCPF.js';

const initialState = {
  singleEmployer: null,
  cpf: null,
};

export default function singleEmployerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SINGLE_EMPLOYER_CPF':
      return {
        ...state,
        cpf: formatCpf(action.cpf),
      };
    case 'SET_SINGLE_EMPLOYER':
      return {
        ...state,
        singleEmployer: action.singleEmployer,
      };
    default:
      return state;
  }
}
