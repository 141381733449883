import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import moment from 'moment';
import { api } from '../../../services/api';

const initialState = {
  isLoading: false,
  isSending: false,
  changeOnIp: false,
  companyDeletedIps: [],
  currentConfig: {},
  forms: {
    hoursForm: {
      startTime: '',
      endTime: '',
    },
    limitForm: {
      limit: 0,
    },
    ipForm: {
      restricted: false,
      page: 0,
      perPage: 5,
    },
  },
  error: {
    hasError: false,
    message: '',
  },
};

const getAccessSettings = createAsyncThunk(
  'accessSettings/getAccessSettings',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    return api
      .get(`security/config/${currentCompany.code}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const setAccessHours = createAsyncThunk(
  'accessSettings/setAccessHours',
  async (
    { initialMovementHour, finalMovementHour },
    { getState, rejectWithValue },
  ) => {
    const { currentCompany } = getState().companies;

    const body = {
      initialMovementHour: moment(initialMovementHour, 'HH:mm').get('hours'),
      finalMovementHour: moment(finalMovementHour, 'HH:mm').get('hours'),
      companyCode: currentCompany.code,
    };

    const config = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    return api
      .post('security/config/movement-hour', body, config)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const setMinLimit = createAsyncThunk(
  'accessSettings/setAccessHours',
  async (payload, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    const config = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    return api
      .post(
        'security/config/amount-to-notify',
        {
          ...payload,
          companyCode: currentCompany.code,
        },
        config,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const setAllowedIps = createAsyncThunk(
  'accessSettings/setAllowedIps',
  async ({ isIpRestricted }, { getState, rejectWithValue }) => {
    const { companyDeletedIps, currentConfig } = getState().access;
    const { currentCompany } = getState().companies;

    const config = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    const companyAllowedIps = currentConfig.companyAllowedIps.map(
      ({ id, ip }) => ({ id, ip }),
    );

    return api
      .post(
        'security/config/allowed-ips',
        {
          isIpRestricted,
          companyDeletedIps,
          companyAllowedIps,
          companyCode: currentCompany.code,
        },
        config,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const AccessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    addIp: (state, { payload }) => {
      state.changeOnIp = true;
      state.currentConfig.companyAllowedIps = [
        ...state.currentConfig.companyAllowedIps,
        payload,
      ];
    },
    setDeletedIps: (state, { payload }) => {
      state.changeOnIp = true;
      state.companyDeletedIps = [...state.companyDeletedIps, payload];
      state.currentConfig.companyAllowedIps = state.currentConfig.companyAllowedIps.filter(
        permission => permission.id !== payload,
      );
    },
    setPagination: (state, { payload: { page, perPage } }) => {
      state.forms.ipForm = {
        ...state.forms.ipForm,
        page,
        perPage,
      };
    },
  },
  extraReducers: {
    [getAccessSettings.pending]: state => {
      state.isLoading = true;
      state.error = initialState.error;
    },
    [getAccessSettings.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentConfig = {
        ...payload?.data,
        companyAllowedIps: payload?.data?.companyAllowedIps ?? [],
      };
    },
    [getAccessSettings.rejected]: (state, { payload }) => {
      state.isLoading = false;

      state.error = {
        hasError: false,
        message:
          payload?.error ||
          'Houve um erro ao obter as configurações de acesso da empresa. Por favor, tente novamente em alguns instantes.',
      };
    },
    [setAccessHours.pending]: state => {
      state.isSending = true;
      state.error = initialState.error;
    },
    [setAccessHours.fulfilled]: state => {
      state.isSending = false;
    },
    [setAccessHours.rejected]: (state, { payload }) => {
      state.isSending = false;

      state.error = {
        hasError: false,
        message:
          payload?.error ||
          'Houve um erro ao definir os novos horários de acesso. Por favor, tente novamente mais tarde.',
      };
    },
    [setMinLimit.pending]: state => {
      state.isSending = true;
      state.error = initialState.error;
    },
    [setMinLimit.fulfilled]: state => {
      state.isSending = false;
    },
    [setMinLimit.rejected]: (state, { payload }) => {
      state.isSending = false;

      state.error = {
        hasError: false,
        message:
          payload?.error ||
          'Houve um erro ao definir o novo limite mínimo de notificação. Por favor, tente novamente mais tarde.',
      };
    },
    [setAllowedIps.pending]: state => {
      state.isSending = true;
      state.error = initialState.error;
    },
    [setAllowedIps.fulfilled]: state => {
      state.isSending = false;
      state.changeOnIp = false;
    },
    [setAllowedIps.rejected]: (state, { payload }) => {
      state.isSending = false;

      state.error = {
        hasError: false,
        message:
          payload?.error ||
          'Houve um erro ao definir os novos IPs permitidos. Por favor, tente novamente mais tarde.',
      };
    },
  },
});

const accessReducer = AccessSlice.reducer;
const AccessActions = {
  ...AccessSlice.actions,
  getAccessSettings,
  setAccessHours,
  setMinLimit,
  setAllowedIps,
};

export { accessReducer, AccessActions };
