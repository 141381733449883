import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

function FakeRowTable() {
  function renderFakeTable() {
    return (
      <>
        <TableRow>
          <TableCell align="left">
            <Skeleton width={80} color="#202020" highlightColor="#444" />
          </TableCell>
          <TableCell align="left">
            <Skeleton width={100} color="#202020" highlightColor="#444" />
          </TableCell>
          <TableCell align="left">
            <Skeleton width={80} color="#202020" highlightColor="#444" />
          </TableCell>
          <TableCell align="left">
            <Skeleton width={80} color="#202020" highlightColor="#444" />
          </TableCell>
          <TableCell align="left">
            <Skeleton width={80} color="#202020" highlightColor="#444" />
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      {renderFakeTable()}
      {renderFakeTable()}
      {renderFakeTable()}
      {renderFakeTable()}
      {renderFakeTable()}
    </>
  );
}

export default FakeRowTable;
