import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { isMobile, isTablet } from '../../../../utils/breakpoints';

import { useStyles } from '../../styles';

export default function PixKeyListSkeleton() {
  const styles = useStyles();
  const cardHeight = isMobile || isTablet ? 240 : 80;

  return (
    <div className={styles.list}>
      <Skeleton width="100%" height={cardHeight} />
      <Skeleton width="100%" height={cardHeight} />
      <Skeleton width="100%" height={cardHeight} />
      <Skeleton width="100%" height={cardHeight} />
      <Skeleton width="100%" height={cardHeight} />
    </div>
  );
}
