import React from 'react';
import { Box } from '@material-ui/core';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@material-ui/icons';
import Buttons from '../../../../../../../Buttons';
import { usePaginationComponent } from './Hooks/usePaginationComponent';

export function PaginationComponent({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) {
  const {
    handleNextButtonClick,
    handleBackButtonClick,
    handleFirstPageButtonClick,
    handleLastPageButtonClick,
  } = usePaginationComponent({ count, page, rowsPerPage, onPageChange });

  return (
    <Box sx={{ flexShrink: 0, marginLeft: '1rem' }}>
      <Buttons.IconButton
        disabled={page === 0}
        icon={<FirstPage />}
        onClick={handleFirstPageButtonClick}
      />
      <Buttons.IconButton
        disabled={page === 0}
        icon={<KeyboardArrowLeft />}
        onClick={handleBackButtonClick}
      />
      <Buttons.IconButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        icon={<KeyboardArrowRight />}
        onClick={handleNextButtonClick}
      />
      <Buttons.IconButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        icon={<LastPage />}
        onClick={handleLastPageButtonClick}
      />
    </Box>
  );
}
