import React from 'react';
import { Header } from '../../../../components/Header';
import { Table } from '../../../../components/Table';
import { useTableConfig } from './Hooks/useTableConfig';
import { useOnboardingReproved } from './Hooks/useOnboardingReproved';
import { Filter } from '../../../../components/Filter';
import Form from '../../../../components/Form';
import { OnboardingReprovedModals } from './Components/OnboardingReprovedModals';

export function List() {
  const { filterObject, tableObject } = useOnboardingReproved();
  const tableConfig = useTableConfig();

  return (
    <>
      <Header title="Funcionários reprovados" />

      <Filter
        defaultValues={filterObject.filterInitialValues}
        handleRequests={filterObject.handleFilterChange}
      >
        <Form.MaskedField
          name="nameOrCpf"
          label="Pesquisar por nome ou CPF"
          mask={[
            {
              mask: '000.000.000-00',
            },
            {
              mask: /\D/g,
            },
          ]}
        />
        <Form.NumericField name="registration" label="Matrícula" />
        <Form.SelectField
          name="bankData"
          label="Tem dados bancários"
          options={[
            {
              label: 'Sim',
              value: true,
            },
            {
              label: 'Não',
              value: false,
            },
          ]}
        />
        <Form.SelectField
          name="onboardingSituation"
          label="Situação"
          options={[
            { label: 'Rejeitado', value: 'REJECTED' },
            { label: 'Reprovado definitivo', value: 'DEFINITIVELY_REPROVED' },
            {
              label: 'Reprovado com pendência',
              value: 'PENDING',
            },
          ]}
        />
      </Filter>

      <Table
        data={tableObject.data}
        tableConfig={tableConfig}
        hasError={tableObject.hasError}
        isLoading={tableObject.isLoading}
        length={tableObject.length}
        rowsPerPageOptions={tableObject.rowsPerPageOptions}
        requests={tableObject.handlePaginationRequests}
      />

      <OnboardingReprovedModals />
    </>
  );
}
