import { alpha, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0.5rem',
    },
    baselineCard: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: '0px 4px 10px #3b35411f',
      cursor: 'pointer',
    },
    selectedCard: {
      boxShadow: 'none',
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      border: `1px solid ${theme.palette.primary.main}`,
    },
    disabledCard: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.disabled,
      cursor: 'default',
      border: `1px solid ${theme.palette.grey[100]}`,

      '& svg, & label': {
        color: theme.palette.text.disabled,
      },
    },
    control: {
      marginRight: theme.spacing(1),
    },
  }),
);
