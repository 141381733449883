import React, { createContext, useCallback, useEffect, useState } from 'react';
import Header from './UI/Header/Header';
import SearchBar from './UI/SearchBar';
import Table from './UI/Table/Table';
import { theme, useStyles } from './styles';
import { useGetConsignedMargins } from './utils/hooks';
import EditModal from './UI/Modal/Edit';
import DisableModal from './UI/Modal/Disable';
import ManualInsertionModal from './UI/Modal/ManualInsertion';
import InfoModal from './UI/Modal/Info';
import { useDebounce } from '../../../../hooks/useDebounceValue';

export const ConsignedMarginsListContext = createContext({});

function ConsignedMarginsList() {
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [openViewModal, setOpenInfoModal] = useState(false);
  const [openManualInsertionModal, setOpenManualInsertionModal] = useState(
    false,
  );

  const [selectedMargin, setSelectedMargin] = useState();

  const { data, isLoading, refresh } = useGetConsignedMargins({
    nameOrCpf: debouncedSearch,
    page,
    size: pageSize,
  });

  const classes = useStyles();

  const handleOpenEditModal = useCallback(item => {
    setSelectedMargin(item);
    setOpenEditModal(true);
  }, []);

  const handleOpenInfoModal = useCallback(item => {
    setSelectedMargin(item);
    setOpenInfoModal(true);
  }, []);

  const handleOpenDisableModal = useCallback(item => {
    setSelectedMargin(item);
    setOpenDisableModal(true);
  }, []);

  const handleManualInsertionModal = useCallback(() => {
    setOpenManualInsertionModal(true);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setOpenEditModal(false);
  }, []);

  const handleCloseInfoModal = useCallback(() => {
    setOpenInfoModal(false);
  }, []);

  const handleCloseDisableModal = useCallback(() => {
    setOpenDisableModal(false);
  }, []);

  const handleCloseManualInsertionModal = useCallback(() => {
    setOpenManualInsertionModal(false);
  }, []);

  const handleChangePage = useCallback((_event, page) => {
    setPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  useEffect(() => {
    refresh();
  }, [page, debouncedSearch, pageSize]);

  return (
    <ConsignedMarginsListContext.Provider value={{ search, setSearch }}>
      <Header onManualInsertionClick={handleManualInsertionModal} />
      <SearchBar />
      <Table
        data={data ? data.employees : []}
        theme={theme}
        classes={classes}
        totalSize={data ? data.totalSize : 0}
        pageSize={pageSize}
        currentPage={page}
        loading={isLoading}
        handleEdit={handleOpenEditModal}
        handleInfo={handleOpenInfoModal}
        handleDisable={handleOpenDisableModal}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <EditModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        onUpdate={refresh}
        selected={selectedMargin}
      />
      <DisableModal
        open={openDisableModal}
        onClose={handleCloseDisableModal}
        onUpdate={refresh}
        selected={selectedMargin}
      />
      <ManualInsertionModal
        open={openManualInsertionModal}
        onClose={handleCloseManualInsertionModal}
        onUpdate={refresh}
        selected={selectedMargin}
      />
      <InfoModal
        open={openViewModal}
        onClose={handleCloseInfoModal}
        selected={selectedMargin}
      />
    </ConsignedMarginsListContext.Provider>
  );
}

export default ConsignedMarginsList;
