import React from 'react'

import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Chip,
  TableHead,
  TableSortLabel
} from '@material-ui/core';
import { useStyles, theme } from './styles';
import EnhancedTableHead from '../../../UI/EnhancedTableHead';
import { FakeTable } from '../../../../OnlinePointEmployees/UI/Table/UI';
import { ReactComponent as DeleteIcon } from '../../../../../assets/removeItem.svg';
import PurpleCheckbox from '../../../../../components/Checkbox/PurpleCheckbox';
import { handleStatus } from '../../../Utils/handleStatus';
import TableHeader from '../EnhancedTableHead';

const TableRechargeDetails = ({
  loading,
  pageSize,
  totalSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  handleRequestSort,
  rows,
  stableSort,
  getComparator,
  TablePaginationActions,
  handleOpenModalDeleteEmployee,
  previewSelected,
  setPreviewSelected
}) => {

  const classes = useStyles();

  function currentMoneyLocal(value) {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  }

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody>
            {!loading ? (
              stableSort(rows, getComparator).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    
                    <TableCell  align="left">
                      {row?.walletType}
                    </TableCell>
                    <TableCell align="left">
                      {row.cpf}
                    </TableCell>
                    <TableCell align="left">
                      {currentMoneyLocal(row.totalAmount)}
                    </TableCell>

                    <TableCell align="right" className={classes.defaultCell}>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        
                      </div>
                    </TableCell>
                    
                  </TableRow>
                );
              })
            ) : (
              <FakeTable />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  )
}

export default TableRechargeDetails