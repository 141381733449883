import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useRouteMatch, Prompt } from 'react-router-dom';
import { Button, Grid, Typography, Tooltip } from '@material-ui/core';

import { useSelector } from 'react-redux';
import ModalSkippedInvalidDeposits from '../../../components/ModalSkippedInvalidDeposits';
import ProgressToolBar from '../../../components/ProgressToolBar';
import ResponsiveIncludeCollaborators from '../ResponsiveIncludeCollaborators';
import CollaboratorsService from './utils/services';
import DualListModal from './UI/DualListModal';
import ApplyValueModal from './UI/ApplyValueModal';
import ChangeSeetDataModal from './UI/ChangeSeetDataModal';
import EmployeesTable from './UI/EmployeesTable/index';
import AlertError from './UI/Alert/ErrorAlert';
import SuccessAlert from './UI/Alert/SuccessAlert';

import { ReactComponent as EditIcon } from '../../../assets/editIcon.svg';

import styles from './styles.module.css';
import { isMobile } from '../../../utils/breakpoints';

export default function IncludeCollaborators() {
  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');

  const companyCodeValue = companyCodeValueStorage || companies[0].code;

  const { accountSelected } = useSelector(state => state.account);

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [openApplyValueModal, setOpenApplyValueModal] = useState(false);
  const [openChangeSeetDataModal, setOpenChangeSeetDataModal] = useState(false);
  const selectedEmployees = JSON.parse(
    sessionStorage.getItem('selectedEmployees'),
  );
  const [emptyList, setEmptyList] = useState(
    !JSON.parse(sessionStorage.getItem('selectedEmployees')),
  );
  const { paymentTypeDescription, paymentType, date } = JSON.parse(
    sessionStorage.getItem('paymentDescription'),
  );
  const [listForService, setListForService] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errroAlert, setErrorAlert] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [tableState, setTableState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);
  const { url } = useRouteMatch();

  async function searchToken() {
    let token = await sessionStorage.getItem('@ContaSoma-Token');
    return token;
  }

  const onBackClicked = () => {
    history.push('/payroll/manual-payroll');
  };

  const onNextClicked = () => {
    setLoading(true);

    searchToken().then(token => {
      const requestOptions = {
        headers: {
          Authorization: token,
        },
      };

      CollaboratorsService.sendListForBackEnd({
        requestOptions,
        listForService: JSON.parse(sessionStorage.getItem('selectedEmployees')),
        paymentType,
        date,
        onShowErrorAlert,
        setConfirmModal,
        handleConfirm,
        setLoading,
        companyCode: companyCodeValue,
        depositAccountOrigin: accountSelected?.origin,
        dispatch: data => {
          setLoading(false);
          sessionStorage.setItem('importFileData', JSON.stringify(data));
        },
      });
    });

    sessionStorage.setItem('urlPayroll', url);
    setIsBlocking(false);
  };

  useEffect(() => {
    if (!paymentType && !paymentTypeDescription) {
      sessionStorage.removeItem('selectedEmployees');
      history.push('/payroll/manual-payroll');
    }
  }, []);

  function handleConfirm(valueConfirm) {
    if (valueConfirm) {
      setConfirmModal(false);
      history.push('/payroll/menu/info-payroll/register-batch/data-review');
    } else {
      setConfirmModal(false);
    }
  }

  const onShowErrorAlert = message => {
    setShowErrorMessage(true);
    setErrorAlert(message);
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);
  };

  const onShowSuccessAlert = () => {
    setOpenApplyValueModal(false);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 3000);
  };

  if (isMobile) {
    return <ResponsiveIncludeCollaborators />;
  }

  return (
    <>
      <Prompt
        when={isBlocking}
        message="Você tem certeza que deseja cancelar a criação desta folha?"
      />

      <Grid container justify="space-between">
        <Grid className={styles.headerStyle} item>
          <Typography className={styles.titleStyle}>
            Valores do pagamento
          </Typography>
          <div className={styles.headerInfosStyle}>
            <div className={styles.headerInfoGroupStyle}>
              <Typography className={styles.headerSmallTextStyle}>
                Tipo
              </Typography>
              <Typography className={styles.headerMediumTextStyle}>
                {paymentTypeDescription}
              </Typography>
            </div>
            <div className={styles.headerInfoGroupStyle}>
              <Typography className={styles.headerSmallTextStyle}>
                Agendar para
              </Typography>
              <Typography className={styles.headerMediumTextStyle}>
                {moment(date).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <div>
              <Tooltip title="Alterar dados">
                <div
                  className={styles.iconEditStyle}
                  onClick={() => setOpenChangeSeetDataModal(!open)}
                >
                  <EditIcon />
                </div>
              </Tooltip>
            </div>
          </div>
        </Grid>
        <Grid className={styles.subheaderStyle} item>
          <Button
            onClick={() => setOpen(!open)}
            className={styles.subheaderFirstIncludeButton}
          >
            Incluir colaboradores
          </Button>
          <Button
            className={
              emptyList
                ? styles.disabledButton
                : styles.subheaderSecondIncludeButton
            }
            disabled={emptyList}
            onClick={() => setOpenApplyValueModal(!openApplyValueModal)}
          >
            Aplicar mesmo valor a todos
          </Button>
        </Grid>
      </Grid>
      <DualListModal
        open={open}
        onCloseDialog={() => setOpen(false)}
        setEmptyList={bool => {
          setEmptyList(bool);
        }}
        setTableState={setTableState}
        tableState={tableState}
      />
      <ApplyValueModal
        open={openApplyValueModal}
        onCloseDialog={() => setOpenApplyValueModal(false)}
        setShowSuccessAlert={() => onShowSuccessAlert()}
      />
      <ChangeSeetDataModal
        open={openChangeSeetDataModal}
        onCloseDialog={() => setOpenChangeSeetDataModal(false)}
        changedDate={date}
      />

      {selectedEmployees?.length === 0 || !selectedEmployees ? (
        <Grid xs={12}>
          <div className={styles.contentStyle}>
            <Typography className={styles.contentTextStyle}>
              Inclua colaboradores para informar os valores dos pagamentos
            </Typography>
            <Button
              className={styles.includeButton}
              onClick={() => setOpen(!open)}
            >
              Incluir colaboradores
            </Button>
          </div>
        </Grid>
      ) : (
        <EmployeesTable
          tableState={tableState}
          setListForService={setListForService}
          setEmptyList={bool => setEmptyList(bool)}
        />
      )}

      <ModalSkippedInvalidDeposits
        confirmModal={confirmModal}
        onContinueProcess={() => handleConfirm(true)}
        onDontContinueProcess={() => handleConfirm(false)}
      />

      {showSuccessAlert && (
        <SuccessAlert message="Valor aplicado a todos com sucesso." />
      )}
      {showErrorMessage && <AlertError message={errroAlert} />}
      <ProgressToolBar
        disabled={emptyList}
        nextButtonText="AVANÇAR"
        onBackClicked={onBackClicked}
        onNextClicked={onNextClicked}
        loading={loading}
      />
    </>
  );
}
