import React from 'react';

import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import styles from './styles';

export default status => {
  switch (status) {
    case 'HIRED':
      return (
        <div style={styles.displayStyle}>
          <QueryBuilderRoundedIcon style={styles.blueIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.waitingStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Transferência não Confirmada':
      return (
        <div style={styles.displayStyle}>
          <SyncProblemRoundedIcon style={styles.orangeIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.waitingStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Aguardando confirmação':
      return (
        <div style={styles.displayStyle}>
          <ReportProblemRoundedIcon style={styles.orangeIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.alertStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Pago':
      return (
        <div style={styles.displayStyle}>
          <CheckCircleOutlineRoundedIcon style={styles.greenIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.paidStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Transferência Confirmada':
      return (
        <div style={styles.displayStyle}>
          <CheckRoundedIcon style={styles.greenIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.paidStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Vencido':
      return (
        <div style={styles.displayStyle}>
          <ReportProblemRoundedIcon style={styles.orangeIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.cancelledStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Cancelado':
      return (
        <div style={styles.displayStyle}>
          <ReportProblemRoundedIcon style={styles.orangeIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.cancelledStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Verificação Desfeita':
      return (
        <div style={styles.displayStyle}>
          <ReportProblemRoundedIcon style={styles.orangeIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.cancelledStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Confirmado':
      return (
        <div style={styles.displayStyle}>
          <CheckRoundedIcon style={styles.greenIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.confirmedStyle}>{status}</span>
          </div>
        </div>
      );
    case 'Verificação em processamento':
      return (
        <div style={styles.displayStyle}>
          <UpdateRoundedIcon style={styles.blueIcon} />
          <div style={styles.marginStyle}>
            <span style={styles.inProcessVerificationStyle}>{status}</span>
          </div>
        </div>
      );
    default:
      return status;
  }
};
