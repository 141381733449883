import React from 'react';

import formatCnpj from '../../../../utils/formatCnpj';
import formatContactNumber from '../../../../utils/formatContactNumber';

import { ReactComponent as PhoneIcon } from '../../../../assets/phoneIcon.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/emailIcon.svg';
import { ReactComponent as CnpjIcon } from '../../../../assets/cnpjIcon.svg';
import { ReactComponent as EvpIcon } from '../../../../assets/evpIcon.svg';

export const getTypeIcon = (type) => {
  switch (type) {
    case 'CNPJ':
      return <CnpjIcon />;
    case 'PHONE':
      return <PhoneIcon />;
    case 'EMAIL':
      return <EmailIcon />;
    case 'EVP':
      return <EvpIcon />;
    default:
      return <></>;
  }
};

export const getPixDisplayName = (type) => {
  switch (type) {
    case 'CNPJ':
      return 'CNPJ';
    case 'PHONE':
      return 'Celular';
    case 'EMAIL':
      return 'E-mail';
    case 'EVP':
      return 'Aleatório';
    default:
      return 'Chave Pix';
  }
};

export const formatPixKey = (pix) => {
  switch (pix.type) {
    case 'CNPJ':
      return formatCnpj(pix.key);
    case 'PHONE':
      return formatContactNumber(pix.key);
    case 'EMAIL':
      return pix.key.toLowerCase();
    default:
      return pix.key;
  }
};

export const unformatPixKey = (formattedKey, type) => {
  switch (type) {
    case 'CNPJ':
      return formattedKey.replace(/[.\-/]/g, '');
    case 'PHONE':
      return formattedKey.replace(/[()\s-]/g, '');
    case 'EMAIL':
      return formattedKey;
    case 'EVP':
      return formattedKey;
    default:
      return formattedKey;
  }
};