import React from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ResponsiveFakeList from './ResponsiveFakeList';

import styles from './styles.module.css';
import textCapitalize from '../../../../utils/textCapitalize';

export default function ResponsiveList({ employees, loading, requestPdf }) {
  return (
    <div className={styles.containerResponsive}>
      {loading ? (
        <ResponsiveFakeList />
      ) : (
        employees.map(row => (
          <div className={styles.card}>
            <div>
              <Typography className={styles.cardTitle}>{row.status}</Typography>
              <Typography className={styles.cardText}>{row.cpf}</Typography>
              <Typography className={styles.cardText}>
                {textCapitalize(row.name)}
              </Typography>
              <Typography className={styles.cardText}>
                {row.amount.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>

              <Box display="flex" alignItems="center" sx={{ gap: '0.5rem' }}>
                <Typography className={styles.consumesFranchiseLabel}>
                  Consome franquia:
                </Typography>
                <Typography className={styles.consumesFranchiseContent}>
                  {row.consumesFranchise === true ? 'Sim' : 'Não'}
                </Typography>
              </Box>
            </div>

            <div>
              {row.status === 'Paga Imediata' || row.status === 'Paga' ? (
                <IconButton className={styles.receiptIcon}>
                  <ReceiptIcon onClick={() => requestPdf(row)} />
                </IconButton>
              ) : (
                <IconButton className={styles.receiptIcon} disabled>
                  <ReceiptIcon
                    onClick={() => requestPdf(row)}
                    style={{ opacity: '.3' }}
                  />
                </IconButton>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
}
