import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Snackbar,
  Select,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CustomDatePicker from '../../../../../components/DatePicker';

import CollaboratorsService from '../../utils/services';

import style from './style.module.css';
import { isMobile } from '../../../../../utils/breakpoints';

export default function ChangeSeetDataModal({ open, onCloseDialog }) {
  const {
    paymentTypeDescription: description,
    paymentType: type,
    date: paymentDate
  } = JSON.parse(sessionStorage.getItem(
    'paymentDescription'
  ));
  const [paymentType, setPaymentType] = useState(type);
  const [date, setDate] = useState(paymentDate);
  const [paymentTypeDescription, setPaymentTypeDescription] = useState(description);
  const [types, setTypes] = useState([]);
  const today = moment({});
  const authToken = sessionStorage.getItem('@ContaSoma-Token');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));

  const changedDate = useSelector(state => state.manualPayroll.date);
  const changedPaymentType = useSelector(
    state => state.manualPayroll.paymentType
  );
  const changedPaymentDescription = useSelector(
    state => state.manualPayroll.paymentDescription
  );

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onChangeDate = value => {
    setDate(value);
  };

  useEffect(() => {
    if (changedDate) {
      setDate(changedDate);
      setPaymentType(changedPaymentType);
      setPaymentTypeDescription(changedPaymentDescription);
    }
  }, []);

  useEffect(() => {
    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
    };

    CollaboratorsService.getListOfTypes({
      requestOptions,
      setStates: data => {
        setTypes(data);
      },
    });
  }, []);

  const changePaymentTypeAndDate = () => {
    const obj = {
      paymentTypeDescription,
      paymentType,
      date
    }
    sessionStorage.setItem('paymentDescription', JSON.stringify(obj));

    setOpenSnackbar(true);
    onCloseDialog();
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} fullScreen={fullScreenDialog}>
        <div className={isMobile ? style.mobileDialog : style.dialog}>
          <div>
            <Typography className={style.biggerText}>
              Alterar dados
            </Typography>
            <Typography className={style.smallText}>
              Esta alteração será aplicada a todos da lista.
            </Typography>
          </div>
          <div>
            <Grid className={style.form}>
              <Grid className={style.formGroup}>
                <FormControl variant="outlined" className={style.textFieldStyle}>
                  <InputLabel id="payment-type-select">Tipo de pagamento*</InputLabel>
                  <Select
                    labelId="payment-type-select"
                    label="Tipo de pagamento*"
                    value={paymentType}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={({ target: { value } }) => {
                      setPaymentType(value);
                      setPaymentTypeDescription(
                        types.filter(item => item.code === value)[0].description
                      );
                    }}
                  >
                    {types.map((type, index) => (
                      <MenuItem key={type.description} value={type.code}>
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className={style.formGroup}>
                <CustomDatePicker
                  label="Data do pagamento*"
                  value={date}
                  onChange={onChangeDate}
                  minDate={
                    paymentType !== '2' ? moment().add(1, 'day') : moment()
                  }
                />
              </Grid>
            </Grid>
          </div>
          <div className={style.modalFooter}>
            <Button className={style.cancelButton} onClick={onCloseDialog}>
              Cancelar
            </Button>
            <Button
              disabled={date < today && paymentType !== '2' ? true : false}
              onClick={changePaymentTypeAndDate}
              className={
                date < today && paymentType !== '2'
                  ? style.applyButtonBlocked
                  : style.applyButton
              }
            >
              Aplicar
            </Button>
          </div>
        </div>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        style={{ marginBottom: 75 }}
        >
        <Alert
          severity="success"
          onClose={() => setOpenSnackbar(false)}
        >
          Dados alterados com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}
