import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Paper,
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  TablePagination,
  TableFooter,
  Tooltip,
} from '@material-ui/core';
import FakeRowTable from './FakeRowTable';

import { ReactComponent as PdfIcon } from '../../../../assets/pdfIcon.svg';
import { ReactComponent as EditIconDeposit } from '../../../../assets/editIconDeposits.svg';
import { ReactComponent as ReceiptIcon } from '../../../../assets/receiptIcon.svg';
import { ReactComponent as DisabledReceiptIcon } from '../../../../assets/disabledReceiptIcon.svg';
import { ReactComponent as EyesIcon } from '../../../../assets/eyesIcon.svg';

import colorStatus from './ColorStatus';
import styles from './styles';
import convertBrazilianDate from '../../../../utils/convertBrazilianDate';
import { api } from '../../../../services/api';

export default ({
  data,
  theme,
  classes,
  otherOptionsAnchorEl,
  awaitingConfirmationAnchorEl,
  handleAwaitingConfirmationClose,
  handleOtherOptionsClose,
  totalSize,
  pageSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
  onHandlePdfButton,
  loading,
}) => {
  const history = useHistory();

  const handleEyesButtonClick = id => history.push(`/cash-in/teds/${id}`);

  const handleClickEditDeposit = row => {
    api.get(`/cashIn/teds/id/${row.id}`).then(({ data }) => {
      sessionStorage.setItem('editDepositInform', JSON.stringify(data));
      history.push(`/cash-in/inform-transfer-Deposit`, {
        byScreen: true,
      });
    });
  };

  const renderMenu = (status, row) => {
    switch (status) {
      case 'Aguardando confirmação':
        return (
          <Menu
            id="simple-menu"
            anchorEl={awaitingConfirmationAnchorEl}
            keepMounted
            open={Boolean(awaitingConfirmationAnchorEl)}
            onClose={handleAwaitingConfirmationClose}
            className={classes.menuList}
          >
            <MenuItem onClick={() => {}}>
              <span className={classes.menuListItem}>Baixar Comprovante</span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {}}>
              <span className={classes.menuListItem}>
                Cancelar Transferência
              </span>
            </MenuItem>
          </Menu>
        );
      default:
        return (
          <Menu
            id="simple-menu"
            anchorEl={otherOptionsAnchorEl}
            keepMounted
            open={Boolean(otherOptionsAnchorEl)}
            onClose={handleOtherOptionsClose}
            className={classes.menuList}
          >
            <MenuItem>
              <span className={classes.menuListItem}>Editar</span>
            </MenuItem>
          </Menu>
        );
    }
  };

  const renderButtons = (status, row) => {
    switch (row.type) {
      case 'Boleto':
        return renderButtonsBoleto(status, row);
      case 'TED':
        return renderButtonsTED(status, row);
      default:
        return null;
    }
  };

  const renderButtonsTED = (status, row) => {
    return (
      <div style={styles.displayStyle}>
        <IconButton>
          <Tooltip title="Visualizar detalhes">
            <EyesIcon onClick={() => handleEyesButtonClick(row.id)} />
          </Tooltip>
        </IconButton>

        <IconButton
          onClick={() => handleClickEditDeposit(row)}
          disabled={status === 'Transferência Confirmada'}
          style={status === 'Transferência Confirmada' ? { opacity: '.5' } : {}}
        >
          <Tooltip title="Editar">
            <EditIconDeposit />
          </Tooltip>
        </IconButton>
      </div>
    );
  };

  const renderButtonsBoleto = (status, row) => {
    switch (status) {
      case 'Aguardando pagamento':
        return (
          <div style={styles.displayStyle}>
            <IconButton>
              <PdfIcon
                onClick={() => {
                  onHandlePdfButton(row);
                }}
              />
            </IconButton>
          </div>
        );
      case 'Aguardando confirmação':
        return (
          <div style={styles.displayStyle}>
            <IconButton>
              <DisabledReceiptIcon />
            </IconButton>
          </div>
        );
      case 'Pago':
        return (
          <div style={styles.displayStyle}>
            <IconButton style={{ opacity: '.3', cursor: 'initial' }} disabled>
              <ReceiptIcon />
            </IconButton>
          </div>
        );
      case 'Concluído':
        return (
          <div style={styles.displayStyle}>
            <IconButton>
              <ReceiptIcon />
            </IconButton>
          </div>
        );
      case 'Vencido':
        return (
          <div style={styles.displayStyle}>
            <IconButton
              placeholder="Boleto vencido"
              style={styles.iconButtonOverduePayment}
              disabled
            >
              <DisabledReceiptIcon />
            </IconButton>
          </div>
        );
      case 'Cancelado':
        return (
          <div style={styles.displayStyle}>
            <IconButton style={styles.iconButtonOverduePayment} disabled>
              <DisabledReceiptIcon />
            </IconButton>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHeadLabel}>Situação</TableCell>
              <TableCell style={styles.tableHeadLabel}>Tipo</TableCell>
              <TableCell style={styles.tableHeadLabel}>Empresa</TableCell>
              <TableCell style={styles.tableHeadLabel}>Solicitação</TableCell>
              <TableCell style={styles.tableHeadLabel}>Depósito</TableCell>
              <TableCell style={styles.tableHeadLabel}>Valor</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <FakeRowTable />
            ) : (
              data?.map(element => (
                <TableRow className={classes.tableRow}>
                  <TableCell align="left" className={classes.statusCell}>
                    {colorStatus(element.status)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.type}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.companyName}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {convertBrazilianDate(element.creationDate)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.paymentDate === null
                      ? '-'
                      : convertBrazilianDate(element.paymentDate)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.amount.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {renderButtons(element.status, element)}
                    {renderMenu(element.status, element)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};
