import React from 'react';
import { Typography } from '@material-ui/core';

import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import style from '../styles.module.css';

export default ({ status }) => {
  switch (status) {
    case 'Verificação em processamento':
      return (
        <Typography className={style.processingStatus}>
          <UpdateRoundedIcon className={style.updateRoundedIcon} />
          Verificação em processamento
        </Typography>
      );
    case 'Confirmado':
      return (
        <Typography className={style.confirmedStatus}>
          <DoneRoundedIcon className={style.updateRoundedIcon} />
          Transferência confirmada
        </Typography>
      );
    case 'Transferência não Confirmada':
      return (
        <Typography className={style.notConfirmedStatus}>
          <ErrorOutlineIcon className={style.updateRoundedIcon} />
          Transferência não confirmada
        </Typography>
      );
    default:
      return null;
  }
};
