import React from 'react';
import styles from './styles.module.css';
import {
  Button,
  Dialog,
} from '@material-ui/core';

import { ReactComponent as InfoIcon } from '../../../../assets/info-icon.svg'

function ExpiredModal({
  open,
  onClose,
  title,
  text,
  cancelButtonText,
  confirmButtonText,
  onCancelButtonClicked,
  onConfirmButtonClicked
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='xs'
    >
      <div className={styles.container}>
        <InfoIcon />
        <p className={styles.title}>{title}</p>
        <p className={styles.text}>{text}</p>

        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={onCancelButtonClicked}
          >
            {cancelButtonText}
          </Button>

          <Button
            className={styles.confirmButton}
            onClick={onConfirmButtonClicked}
          >
            <span>{confirmButtonText}</span>
          </Button>
        </div>

      </div>
    </Dialog>
  );
}

export default ExpiredModal;
