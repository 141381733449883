import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import UserCard from '../UserCard';

import { useStyles } from '../../styles';

export default function UserGrid({ data, isLoading, onOpenUserModal }) {
  const styles = useStyles();

  const [page, setPage] = useState(1);

  const handleChange = (_, value) => setPage(value);

  const count = Math.ceil(data.length / 9);

  const currentView = data.slice((page - 1) * 9, page * 9);

  return (
    <>
      <Box className={styles.cardGrid}>
        {currentView.map(user => (
          <UserCard user={user} onOpenUserModal={onOpenUserModal} />
        ))}
      </Box>
      <Box className={styles.paginationContainer}>
        <Pagination
          page={page}
          count={count}
          siblingCount={0}
          boundaryCount={0}
          onChange={handleChange}
          color="primary"
        />
      </Box>
    </>
  );
}
