import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsManualPayroll as Actions } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

export const useEmployeesReproved = () => {
  const dispatch = useDispatch();

  const { employeesReproved, previewSelected } = useSelector(
    state => state.payroll.manualPayroll,
  );

  const handlePaginationRequests = (page, perPage) => {
    dispatch(Actions.setReprovedPagination({ page, perPage }));
  };

  const disableSelection = entry =>
    !entry?.hasBankData || entry?.situationType === 'REJECTED';

  const handleSelection = useCallback(
    employees => {
      dispatch(Actions.setPreviewSelected(employees));
    },
    [dispatch],
  );

  return {
    disableSelection,
    handleSelection,
    table: {
      data: employeesReproved?.data,
      selectedItems: previewSelected,
      length: employeesReproved.currentLength,
      isLoading: employeesReproved.isLoading,
      hasError: employeesReproved.errorOn.hasError,
      requests: handlePaginationRequests,
    },
  };
};
