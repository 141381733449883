import React from 'react';

import Buttons from '../../../../../components/Buttons';

import { useStyles } from './styles';

export default function CashOutTransferBottomBar({
  isLoading,
  isFinal,
  disabled,
  onBack,
}) {
  const styles = useStyles();

  const renderConfirmLabel = isFinal ? 'Solicitar' : 'Continuar';

  const getButtonColor = isFinal ? 'secondary' : 'primary';

  return (
    <footer className={styles.root}>
      <Buttons.PrimaryButton
        variant="outlined"
        color="primary"
        onClick={onBack}
        title="Voltar"
      />

      <Buttons.PrimaryButton
        type="submit"
        color={getButtonColor}
        disabled={disabled}
        loading={isLoading}
        title={renderConfirmLabel}
      />
    </footer>
  );
}
