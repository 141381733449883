import React from 'react';
import { useSelector } from 'react-redux';

import {
  Toolbar,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';

import { isMobile } from '../../utils/breakpoints';

import { ReactComponent as PastStepIcon } from '../../assets/pastStepIcon.svg';
import { ReactComponent as FutureStepIcon } from '../../assets/futureStepIcon.svg';
import { ReactComponent as CurrentStepIcon } from '../../assets/currentStepIcon.svg';

import styles from './styles';

const createProgressBar = (numberOfSteps, currentStep) => {
  const icons = [];

  for (let i = 0; i < numberOfSteps; i += 1) {
    if (i < currentStep) icons.push('future');
    else if (i === currentStep) icons.push('current');
    else icons.push('past');
  }
  return icons;
};

function ProgressToolBar({
  finalStep = false,
  numberOfSteps,
  currentStep,
  onBackClicked,
  disableBack = false,
  disableNext = false,
  onNextClicked,
  backButtonText = 'Voltar',
  nextButtonText = currentStep > 0 ? 'Avançar' : 'Iniciar',
  loading = false,
  disabled = false,
  count,
  numberAccount,
  textAccount,
  orangeNextButton,
  hidden,
  typeNextButton,
  formRef,
  placementTooltip,
  titleTooltip = null,
}) {
  const { drawer } = useSelector(state => state.openAndClosedDrawer);
  return (
    <Paper
      style={
        hidden
          ? styles.hidden
          : drawer
          ? styles.desktopPaperStyle
          : styles.tollBarTablet
      }
    >
      <Toolbar style={isMobile ? styles.mobileSpacing : {}}>
        {count ? (
          <div style={styles.count}>
            <div style={styles.numberCount}>
              <Typography style={{ fontSize: 12 }}>{numberAccount}</Typography>
            </div>
            <Typography style={styles.textCount}>{textAccount}</Typography>
          </div>
        ) : null}
        <Grid container justify="space-between" alignItems="center">
          <div style={!isMobile ? styles.progressBarStyle : {}}>
            {createProgressBar(numberOfSteps, currentStep).map(element => {
              if (element === 'past')
                return <PastStepIcon style={styles.iconStyle} />;
              if (element === 'current')
                return <CurrentStepIcon style={styles.iconStyle} />;
              return <FutureStepIcon style={styles.iconStyle} />;
            })}
          </div>
          {loading ? (
            <CircularProgress style={styles.loadingStyle} />
          ) : (
            <div>
              <Grid container spacing={1}>
                {disableBack ? (
                  ''
                ) : (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={onBackClicked}
                      style={styles.backButtonStyle}
                    >
                      {backButtonText}
                    </Button>
                  </Grid>
                )}
                {disableNext ? (
                  ''
                ) : (
                  <Grid item>
                    <Tooltip
                      placement={placementTooltip}
                      title={titleTooltip}
                      disableHoverListener={!disabled || !titleTooltip}
                    >
                      <div>
                        <Button
                          form={formRef}
                          type={typeNextButton}
                          disabled={disabled}
                          variant="contained"
                          onClick={onNextClicked}
                          style={
                            finalStep
                              ? styles.nextButtonFinalStepStyle
                              : disabled && orangeNextButton
                              ? styles.disabledNextButtonOrange
                              : styles.nextButtonStyle && disabled
                              ? styles.nextButtonfadedStyle
                              : orangeNextButton
                              ? styles.nextButtonOrange
                              : styles.nextButtonStyle
                          }
                        >
                          <Typography
                            style={styles.textButtonNext}
                            variant="button"
                            noWrap
                          >
                            {nextButtonText}
                          </Typography>
                        </Button>
                      </div>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Grid>
      </Toolbar>
    </Paper>
  );
}

export default ProgressToolBar;
