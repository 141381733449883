import React from 'react';
import { Box, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Buttons from '../../../../../Buttons';

export function DefaultModalFooter({
  errorOn = { hasError: false, message: '' },
  firstButtonText = 'Confirmar',
  firstButtonIcon = null,
  firstButtonDisabled = false,
  firstButtonOnClick = () => {},
  secondButtonShow = true,
  secondButtonText = 'Cancelar',
  secondButtonIcon = null,
  secondButtonDisabled = false,
  secondButtonOnClick = () => {},
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Slide
        in={errorOn.hasError}
        direction="left"
        timeout={{ enter: 300 }}
        mountOnEnter
        unmountOnExit
      >
        <Alert severity="error">{errorOn.message}</Alert>
      </Slide>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          gap: '1rem',
        }}
      >
        {secondButtonShow && (
          <Buttons.SecondaryButton
            title={secondButtonText}
            disabled={secondButtonIcon}
            icon={secondButtonDisabled}
            onClick={secondButtonOnClick}
          />
        )}

        <Buttons.PrimaryButton
          title={firstButtonText}
          disabled={firstButtonIcon}
          icon={firstButtonDisabled}
          onClick={firstButtonOnClick}
        />
      </Box>
    </Box>
  );
}
