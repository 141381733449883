import React, { useEffect, useState } from 'react';

import {
  FormControl,
  InputAdornment,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

import { api } from '../../services/api';

import { isMobile } from '../../utils/breakpoints';
import textCapitalize from '../../utils/textCapitalize';

function Filters({
  bank,
  setBank,
  search,
  setSearch,
  changedFilter,
  setChangedFilter,
}) {
  const [searchFocus, setSearchFocus] = React.useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [accountsFilter, setAccountsFilter] = useState([]);

  const COMAPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  useEffect(() => getAccountsList(), []);

  function handleChangeBank(event) {
    setBank(event.target.value);
    setChangedFilter(true);
  }

  function onSearchChange(event) {
    setSearch(event.target.value);
    setChangedFilter(true);
  }

  function getAccountsList() {
    const requestOptions = {
      params: {
        nameOrAccount: search,
        bankCode: bank,
      },
    };
    api
      .get(`/accountCashIn/${COMAPANY_CODE}`, requestOptions)
      .then(({ data }) => {
        data.content.forEach(item =>
          accountsFilter.push({
            bankName: item.bankName,
            bankCode: item.bankCode,
          }),
        );

        const newArray = accountsFilter.filter(function(a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));

        setAccountsFilter(newArray);
      });
  }

  return (
    <div className={styles.filters}>
      <TextField
        placeholder="Pesquise por nome ou conta"
        variant="outlined"
        onFocus={() => {
          setSearchFocus(true);
          setTimeout(() => setTextFieldFocused('search'), 200);
        }}
        onBlur={() => {
          setSearchFocus(false);
          setTimeout(() => setTextFieldFocused(''), 200);
        }}
        autoFocus={changedFilter && search}
        className={
          searchFocus ? styles.filterAccount : styles.filterAccountFocused
        }
        onChange={onSearchChange}
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: search?.length > 0 && textFieldFocused === 'search' && (
            <IconButton
              className={styles.endAdornment}
              onClick={() => setSearch('')}
            >
              <CloseRoundedIcon className={styles.endAdornmentIcon} />
            </IconButton>
          ),
        }}
      />

      {isMobile ? (
        <div className={styles.chipFilter}>
          {accountsFilter.length > 0 && (
            <div
              id=""
              onClick={() => setBank('')}
              className={bank === '' ? styles.activeChip : styles.chip}
            >
              Todos
            </div>
          )}

          {accountsFilter?.map(item => (
            <div
              key={item.bankCode}
              className={
                bank === item.bankCode ? styles.activeChip : styles.chip
              }
              onClick={() => setBank(item.bankCode)}
            >
              {textCapitalize(item.bankName)}
            </div>
          ))}
        </div>
      ) : (
        <FormControl variant="outlined" className={styles.bankFilter}>
          <InputLabel id="outlined-situation">Banco</InputLabel>
          <Select
            labelId="outlined-situation"
            id="outlined-situation"
            label="Banco"
            value={bank}
            onChange={handleChangeBank}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem>Todos</MenuItem>
            {accountsFilter?.map(item => (
              <MenuItem key={item.bankCode} value={item.bankCode}>
                {item.bankName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}

export default Filters;
