import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = {
  titleStyle: {
    fontSize: 34,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    marginBottom: 60,
    fontFamily: 'DM Sans',
  },
  contentStyle: {
    height: '65vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileContentStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  biggerTextStyle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    marginTop: '15px',
    fontFamily: 'DM Sans',
  },
  biggerTextStyleTwo: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    marginBottom: '15px',
    fontFamily: 'DM Sans',
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontFamily: 'DM Sans',
  },
  containerSize: {
    height: '70vh',
    flex: 1,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mobileContainerSize: {
    height: '55vh',
    flex: 1,
    textAlign: 'center',
  },
  ContainerWidth: {
    width: 450,
    height: 200,
    marginTop: -60,
  },
  mobileContainerWidth: {
    width: '70vw',
  },
};

export default styles;

export const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      display: 'flex',
      marginTop: '30px',
      marginLeft: '-22px',
      marginRight: '-22px',
      wordBreak: 'break-all',
    },
  })
);
