import React, { useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  FormHelperText,
  Hidden,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import formatCPF from '../../utils/formatCPF';
import formatCnpj from '../../utils/formatCnpj';
import { isMobile } from '../../utils/breakpoints';
import formatAccountAndAgencyNumber from '../../utils/formatAccountAndAgencyNumber';
import formatDigitValue from '../../utils/formatDigitValue';

import styles from './styles.module.css';

import { api } from '../../services/api';

function ModalRemoveAccount({
  open,
  onClose,
  handleEdit,
  idAccount,
  accountValue,
  agencyValue,
  bankValue,
  bankCodeValue,
  cpfCnpjValue,
  optionalName,
  checkDigitValue,
}) {
  const [bank, setBank] = useState('');
  const [agency, setAgency] = useState('');
  const [errorBankMessage, setErrorBankMessage] = useState('');
  const [errorAgencyMessage, setErrorAgencyMessage] = useState('');
  const [errorAccountMessage, setErrorAccountMessage] = useState('');
  const [account, setAccount] = useState('');
  const [errorCpfCnpjMessage, setErrorCpfCnpjMessage] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [errorCheckDigitMessage, setErrorCheckDigitMessage] = useState('');
  const [checkDigit, setCheckDigit] = useState('');
  const [name, setName] = useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [banksList, setBanksList] = useState([]);

  const [errorBank, setErrorBank] = useState(false);
  const [errorAgency, setErrorAgency] = useState(false);
  const [errorAccount, setErrorAccount] = useState(false);
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [errorCheckDigit, setErrorCheckDigit] = useState(false);
  const [loading, setLoading] = useState(false);

  const DISABLED_BUTTON = Boolean(
    !bank || !agency || !account || !cpfCnpj || !checkDigit,
  );

  useEffect(() => {
    if (open) {
      setCpfCnpj(cpfCnpjValue);
      setAccount(accountValue);
      setAgency(agencyValue);
      setBank(bankValue);
      setName(optionalName);
      setCheckDigit(checkDigitValue);
    }
    getBanksList();
  }, [open]);

  const cleanAllInputs = () => {
    setCpfCnpj('');
    setAccount('');
    setAgency('');
    setBank('');
    setName('');
    setCheckDigit('');
    setErrorAgency(false);
    setErrorAccount(false);
    setErrorCpfCnpj(false);
    setErrorBank(false);
    setErrorCheckDigit(false);
    setErrorAgencyMessage('');
    setErrorAccountMessage('');
    setErrorCpfCnpjMessage('');
    setErrorBankMessage('');
    setErrorCheckDigitMessage('');
  };

  const closeModal = () => {
    cleanAllInputs();
    onClose();
  };

  const editAccount = () => {
    setLoading(true);
    const requestBody = {
      bankCode: bank.code ? bank.code : bankCodeValue,
      agency,
      account,
      cpfCnpj,
      name,
      checkDigit,
    };
    api
      .put(`/accountCashIn/bank/${idAccount}`, requestBody)
      .then(editAccountThen)
      .catch(({ response: { data: { errors } } }) => editAccountCatch(errors))
      .finally(() => setLoading(false));
  };

  const editAccountThen = () => {
    handleEdit();
    cleanAllInputs();
  };

  const editAccountCatch = errors => {
    const objectError = errors.shift();

    if (objectError.errorReason === 'INVALID_PARAMETER') {
      setErrorCpfCnpj(true);
      setErrorCpfCnpjMessage(objectError.errorDetail);
      setErrorAccount(false);
      setErrorAccountMessage('');
    } else {
      setErrorAccount(true);
      setErrorAccountMessage(objectError.errorDetail);
      setErrorCpfCnpj(false);
      setErrorCpfCnpjMessage('');
    }
  };

  const getBanksList = () => {
    api
      .get('/accountCashIn/bank/findAll')
      .then(({ data }) => setBanksList(data));
  };

  const maskedAgencyNumber = value => {
    value = value.replace(/\D/g, '');
    return value;
  };

  const onChangeCpfCnpj = value => {
    const number = value.replace(/\D/g, '');
    const maskedCpfCnpj =
      number.length <= 11 ? formatCPF(number) : formatCnpj(number);
    setCpfCnpj(maskedCpfCnpj);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className={styles.dialog}>
        <div>
          <Typography className={styles.biggerText}>
            Editar conta bancária
          </Typography>
          <Typography className={styles.smallText}>
            Altere os dados abaixo:
          </Typography>
        </div>
        <div>
          <form className={styles.form}>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Banco</Typography>
              </Hidden>
              <Autocomplete
                disabled={loading}
                value={bank}
                onChange={(event, value) => setBank(value)}
                className={errorBank ? styles.selectError : styles.textField}
                options={banksList}
                closeIcon={false}
                autoHighlight
                getOptionLabel={option =>
                  option.name ? option.name : bankValue
                }
                renderOption={option => (
                  <>
                    {option.name} - {option.code}
                  </>
                )}
                renderInput={params => (
                  <TextField
                    disabled={loading}
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    placeholder="Selecione um banco"
                  />
                )}
              />
              {errorBank && (
                <FormHelperText className={styles.helperText}>
                  {errorBankMessage}
                </FormHelperText>
              )}
            </div>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Agência</Typography>
              </Hidden>
              <TextField
                disabled={loading}
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                label={isMobile && 'Agência'}
                className={styles.textField}
                error={errorAgency}
                helperText={errorAgencyMessage}
                value={maskedAgencyNumber(agency)}
                onChange={({ target: { value } }) => setAgency(value)}
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('agency'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{ maxLength: 4 }}
                InputProps={{
                  endAdornment: agency?.length > 0 &&
                    textFieldFocused === 'agency' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setAgency('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Conta corrente</Typography>
              </Hidden>
              <TextField
                disabled={loading}
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                label={isMobile && 'Conta corrente'}
                className={styles.textField}
                error={errorAccount}
                helperText={errorAccountMessage}
                value={formatAccountAndAgencyNumber(account)}
                onChange={({ target: { value } }) =>
                  setAccount(formatAccountAndAgencyNumber(value))
                }
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('account'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{ maxLength: 16 }}
                InputProps={{
                  endAdornment: account?.length > 0 &&
                    textFieldFocused === 'account' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setAccount('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>Dígito verificador</Typography>
              </Hidden>
              <TextField
                disabled={loading}
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                label={isMobile && 'Dígito verificador'}
                className={styles.digit}
                error={errorCheckDigit}
                helperText={errorCheckDigitMessage}
                value={formatDigitValue(checkDigit)}
                onChange={({ target: { value } }) =>
                  setCheckDigit(formatDigitValue(value))
                }
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('checkDigit'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{ maxLength: 2 }}
                InputProps={{
                  endAdornment: checkDigit?.length > 0 &&
                    textFieldFocused === 'checkDigit' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setCheckDigit('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <Hidden smDown>
                <Typography className={styles.label}>CPF / CNPJ</Typography>
              </Hidden>
              <TextField
                disabled={loading}
                variant="outlined"
                placeholder={!isMobile && 'Digite aqui'}
                label={isMobile && 'CPF / CNPJ'}
                className={styles.textField}
                error={errorCpfCnpj}
                helperText={errorCpfCnpjMessage}
                value={cpfCnpj}
                onChange={({ target: { value } }) => onChangeCpfCnpj(value)}
                onFocus={() =>
                  setTimeout(() => setTextFieldFocused('cpfCnpj'), 200)
                }
                onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
                inputProps={{ maxLength: 18 }}
                InputProps={{
                  endAdornment: cpfCnpj?.length > 0 &&
                    textFieldFocused === 'cpfCnpj' && (
                      <IconButton
                        className={styles.endAdornment}
                        onClick={() => setCpfCnpj('')}
                      >
                        <CloseRoundedIcon className={styles.endAdornmentIcon} />
                      </IconButton>
                    ),
                }}
              />
            </div>
          </form>
          <div className={styles.formName}>
            <Hidden smDown>
              <Typography className={styles.label}>Nome (opcional)</Typography>
            </Hidden>
            <TextField
              disabled={loading}
              variant="outlined"
              placeholder={!isMobile && 'Digite aqui'}
              label={isMobile && 'Nome (opcional)'}
              style={isMobile ? { width: '100%' } : { width: 550 }}
              value={name}
              onChange={({ target: { value } }) => setName(value)}
              onFocus={() => setTimeout(() => setTextFieldFocused('name'), 200)}
              onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
              InputProps={{
                endAdornment: name?.length > 0 && textFieldFocused === 'name' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => setName('')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>
        <div className={styles.buttonsGroup}>
          <Button className={styles.cancelButton} onClick={closeModal}>
            Cancelar
          </Button>
          <Button
            className={styles.confirmButton}
            onClick={editAccount}
            disabled={DISABLED_BUTTON || loading}
          >
            {loading ? (
              <CircularProgress
                className={styles.spinner}
                size={styles.spinner}
              />
            ) : (
              'Alterar'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalRemoveAccount;
