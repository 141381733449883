import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Typography } from '@material-ui/core';
import { api } from '../../../services/api';
import styles from './styles';
import { pusherStatus } from '../../../enums/pusher';
import { pusher } from '../../../utils/pusher';

import EmployeeList from './UI/EmployeeList';
import ProgressToolBar from '../../../components/ProgressToolBar';
import AlreadyProcessModal from '../../../components/PrimaryModal';
import ConfirmModal from '../../../components/PrimaryModal';

const EmployeesBatchRegistrationEmployeeList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [alreadyProcessModal, setAlreadyProcessModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const fileId = sessionStorage.getItem('employeesRegistrationFileId');
  const registerFileData = JSON.parse(
    sessionStorage.getItem('registerFileData'),
  );
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const importEmployeesErrors = JSON.parse(
    sessionStorage.getItem('importEmployeesErrors'),
  );

  const { errors, success } = importEmployeesErrors;

  const { PROCESSED_EMPLOYEES_SUCCESS } = pusherStatus;

  useEffect(() => {
    const channel = pusher.subscribe(currentCompanyCode);

    channel.bind('EMPLOYEES_REGISTER_CONFIRMATION', function(data) {
      sessionStorage.setItem(
        'uploadEmployeesConfirmationData',
        JSON.stringify(data?.data),
      );

      if (data.status === PROCESSED_EMPLOYEES_SUCCESS) {
        history.push('/employees/register-batch/success');
        setLoading(false);
      }
    });
  }, []);

  const onBackClicked = () => {
    history.push('/employees/register-batch/menu/select-company/excel-form');
  };

  const onNextClicked = () => {
    if (success?.length === 0 && errors?.length > 0) {
      onBackClicked();
    } else if (success?.length > 0 && errors?.length > 0) {
      setOpenConfirmModal(true);
    } else {
      verifyFile();
    }
  };

  const verifyFile = () => {
    setLoading(true);

    api
      .get(`files/employees/notAlreadyExistsWorksheet/${fileId}`)
      .then(() => registerEmployees())
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setLoading(false);

          if (errors[0].errorReason === 'FILE_ALREADY_PROCESSED') {
            setErrorTitle(errors[0].errorsDescription.title);
            setErrorMessage(errors[0].errorsDescription.description);
            setAlreadyProcessModal(true);
          }
        },
      );
  };

  const registerEmployees = () => {
    api.post(`/employees/files/process/${fileId}`);
  };

  const renderSuccessList = () => {
    if (success?.length > 0) {
      return (
        <div>
          <EmployeeList
            employeeList={success}
            label={
              <Typography style={{ fontWeight: 500 }}>
                {`${success?.length} Funcionário${
                  success?.lenght > 1 ? 's' : ''
                }
                identificado${success?.length > 1 ? 's' : ''} com sucesso`}
              </Typography>
            }
          />
        </div>
      );
    }
    return null;
  };

  const renderErrorList = () => {
    if (errors?.length > 0) {
      return (
        <div style={styles.errorListMargin}>
          <EmployeeList
            error
            employeeList={errors}
            label={
              <Typography style={{ fontWeight: 500 }}>
                {`${errors?.length} Registro${
                  errors?.length > 1 ? 's' : ''
                } com problemas `}
              </Typography>
            }
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ paddingBottom: 90 }}>
      <Typography style={styles.titleStyle}>
        {success?.length > 0
          ? 'Confirme o cadastro via planilha do Excel'
          : 'Identificamos os seguintes problemas:'}
      </Typography>
      {errors?.length > 0 && (
        <Typography style={styles.biggerTextStyle}>
          Por favor, corrija os erros abaixo antes de continuar.
        </Typography>
      )}

      {success?.length > 0 && (
        <div style={styles.infosContainer}>
          <Typography style={styles.biggerTextStyle}>
            As informações abaixo estão corretas?
          </Typography>

          <div style={styles.infosBlock}>
            <div style={styles.info}>
              <Typography style={styles.labelInfo}>Nome do arquivo</Typography>
              <Typography style={styles.infoContent}>
                {registerFileData?.fileName}
              </Typography>
            </div>

            <div style={styles.info}>
              <Typography style={styles.labelInfo}>Data de cadastro</Typography>
              <Typography style={styles.infoContent}>
                {moment().format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        </div>
      )}

      <div style={styles.contentStyle}>
        {renderErrorList()}
        {renderSuccessList()}
      </div>

      <AlreadyProcessModal
        title={errorTitle}
        text={errorMessage}
        confirmButtonText="Entendi"
        onConfirmClicked={onBackClicked}
        open={alreadyProcessModal}
      />

      <ConfirmModal
        title="Tem certeza que quer ignorar os erros?"
        text="Ao confirmar, apenas os funcionários identificados
        corretamente serão cadastrados com sucesso."
        confirmButtonText="Sim, confirmar"
        cancelButtonText="Cancelar"
        onConfirmClicked={verifyFile}
        onCancelButtonClicked={() => setOpenConfirmModal(false)}
        open={openConfirmModal}
        loading={loading}
      />

      <ProgressToolBar
        loading={loading && !(success?.length > 0 && errors?.length > 0)}
        onBackClicked={onBackClicked}
        onNextClicked={onNextClicked}
        nextButtonText={
          success?.length > 0 && errors?.length > 0
            ? 'Ignorar erros e confirmar'
            : success?.length === 0
            ? 'Reenviar planilha'
            : 'Confirmar'
        }
        backButtonText={
          success?.length > 0 && errors?.length > 0
            ? 'Reenviar planilha'
            : 'Voltar'
        }
        disableBack={success?.length === 0}
        orangeNextButton
      />
    </div>
  );
};

export default EmployeesBatchRegistrationEmployeeList;
