import React from 'react';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ReactComponent as TransferIconMobile } from '../../../assets/transfer-icon-mobile.svg';
import { ReactComponent as BillingMobile } from '../../../assets/billing-mobile.svg';

import styles from './styles.module.css';

export const actions = [
  {
    icon: <BillingMobile className={styles.iconColor} />,
    name: 'Depósito via boleto',
    onClick: '/cash-in/new/billing',
    value: 'BILLET_DEPOSIT',
  },
  {
    icon: <TransferIconMobile className={styles.iconColor} />,
    name: 'Registro de transferência',
    onClick: [
      '/cash-in/inform-transfer-Deposit',
      {
        byScreen: true,
      },
    ],
    value: 'TRANSFER',
  },
  {
    icon: <AttachMoneyIcon className={styles.iconColor} />,
    name: 'Depósito via Pix',
    onClick: '/cash-in/pix',
    value: 'PIX_DEPOSIT',
  },
];
