import React from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import styles from './styles.module.css';
import CardTools from '../../../../../components/CardTools';
import brazilianCurrencyFormatter from '../../../../../utils/brazilianCurrencyFormatter';
import Buttons from '../../../../../components/Buttons';

export default function BottomBar({
  selected,
  onBatchPaymentClick,
  multiplePaymentsSelected,
  hasAuthorization,
}) {
  const selectedTotalToPay = selected
    ?.map(item => item?.totalAmount)
    ?.reduce((acc, current) => acc + current, 0);

  return (
    <div className={selected.length === 0 ? styles.hidden : styles.container}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
        <div className={styles.selecteds}>
          <Typography className={styles.count}>{selected.length}</Typography>
          <Typography className={styles.countLabel}>
            {multiplePaymentsSelected ? 'Selecionados' : 'Selecionado'}
          </Typography>
        </div>

        <Divider orientation="vertical" flexItem />

        <CardTools.CardInfo
          orientation="row"
          title="Total"
          description={brazilianCurrencyFormatter(selectedTotalToPay)}
        />
      </Box>

      <Box>
        <Buttons.PrimaryButton
          size="medium"
          color="secondary"
          disabled={hasAuthorization}
          title={
            multiplePaymentsSelected
              ? 'Autorizar pagamentos'
              : 'Autorizar pagamento'
          }
          onClick={onBatchPaymentClick}
        />
      </Box>
    </div>
  );
}
