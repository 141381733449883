import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';

import Buttons from '../../../../components/Buttons';

import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';

import useAcademy from '../../hooks/useAcademy';

import { useStyles } from '../../styles';

export default function HelpTopic({ topic }) {
  const styles = useStyles();

  const {
    onGetSubtopics,
    subtopics,
    isLoadingSubtopics,
    currentTopic,
    onOpenHelpArticle,
  } = useAcademy();

  const handleOpenDetails = e => {
    e.preventDefault();
    onGetSubtopics(topic.id);
  };

  return (
    <Accordion
      expanded={topic.id === currentTopic}
      className={styles.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        onClick={handleOpenDetails}
      >
        <Typography className={styles.title}>
          {capitalizeFirstLetter(topic.title)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        {isLoadingSubtopics ? (
          <Box className={styles.subtopicSpinner}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box className={styles.buttonsContainer}>
            {subtopics.length > 0 ? (
              subtopics.map(subtopic => (
                <Buttons.PrimaryButton
                  key={subtopic.id}
                  color="primary"
                  variant="outlined"
                  title={subtopic.title}
                  onClick={() => onOpenHelpArticle(subtopic.id)}
                />
              ))
            ) : (
              <Typography variant="body2" className={styles.emptyText}>
                Nenhum subtópico encontrado.
              </Typography>
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
