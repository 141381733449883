/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Box } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { MoreHoriz } from '@material-ui/icons';
import Buttons from '../../../../../Buttons';

export function FilterMainFields({
  fields,
  control = {},
  hasMoreFields = false,
  isViewingMoreFields = false,
  onViewMoreFields = () => {},
}) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        paddingBottom: '1.5rem',
      }}
    >
      {fields?.map(filterField => (
        <Box
          key={filterField?.props?.name}
          sx={{
            width: '100%',
          }}
        >
          <Controller
            name={filterField?.props?.name}
            control={control}
            defaultValue={control?._defaultValues?.[filterField?.props?.name]}
            render={({ field, fieldState }) =>
              React.Children.map(filterField, child =>
                React.isValidElement(child)
                  ? React.cloneElement(child, {
                      error: fieldState.invalid,
                      helperText: fieldState.error?.message,
                      size: 'medium',
                      ...field,
                    })
                  : child,
              )
            }
          />
        </Box>
      ))}

      {hasMoreFields && (
        <Box>
          <Buttons.IconButton
            tooltipTitle="Mais filtros"
            icon={
              <MoreHoriz
                style={{
                  transition: 'rotate 0.3s',
                  rotate: isViewingMoreFields ? '90deg' : '-0deg',
                }}
              />
            }
            onClick={onViewMoreFields}
          />
        </Box>
      )}
    </Box>
  );
}
