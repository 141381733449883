import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsManualPayroll } from '../../../../../../../../redux/store/Payroll/ManualPayroll';

import { useInsertBankData } from '../../InsertBankDataDialogResponsive/Hooks/useInsertBankData';

export function useEmployeesPending() {
  const dispatch = useDispatch();

  const { insertBankDataDialog } = useInsertBankData();

  const { employeesPending, selectedRowsEmployees, banksList } = useSelector(
    state => state.payroll.manualPayroll,
  );

  const [hasMoreData, setHasMoreData] = useState(true);
  const [hasData, setHasData] = useState(false);

  const isEmployeeSelected = row =>
    selectedRowsEmployees?.some(employee => employee?.cpf === row);

  const newSelectedEmployee = row => {
    if (isEmployeeSelected(row?.cpf)) {
      return selectedRowsEmployees?.filter(
        employee => employee?.cpf !== row?.cpf,
      );
    }
    const newSelectedEmployeesArray = [...selectedRowsEmployees, row];

    return newSelectedEmployeesArray;
  };

  function handleSelectEmployee(row) {
    dispatch(
      ActionsManualPayroll.setSelectedRowsEmployees(newSelectedEmployee(row)),
    );
  }

  function handleInsertBankData(row) {
    insertBankDataDialog.handleOpen();
    dispatch(
      ActionsManualPayroll.setSelectedEmployeeToInsertBankData({
        ...row,
        employeeType: 'PENDING',
      }),
    );
  }

  function handleMoreSearchEmployeesPendingData() {
    if (employeesPending.perPage >= employeesPending.length) {
      setHasMoreData(false);
    } else {
      dispatch(
        ActionsManualPayroll.setHandlePerPageChangeEmployeesPending(
          employeesPending.perPage + 10,
        ),
      );
      setHasData(true);
    }
  }

  useEffect(() => {
    dispatch(ActionsManualPayroll.searchEmployeesPending());
  }, [employeesPending.perPage]);

  return {
    cardListObject: {
      data: employeesPending?.data,
      length: employeesPending.length,
      perPage: employeesPending.perPage,
      isLoading: employeesPending.isLoading,
      hasError: employeesPending.errorOn.hasError,
      hasMoreData,
      hasData,
    },
    banksList,
    selectedRowsEmployees,
    isEmployeeSelected,
    handleSelectEmployee,
    handleInsertBankData,
    handleMoreSearchEmployeesPendingData,
  };
}
