import React from 'react';
import Skeleton from 'react-loading-skeleton';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import styles from './styles.module.css';

export default function InfoGrid({ 
  hidden,
  onChangePayCheckPortion,
  paycheckPortion,
  competence,
  onChangeCompetence,
  onChangePayCheckType,
  paycheckType,
  isLoading,
  paycheckInfo
}) {
  return (
    <div className={hidden ? styles.hidden : styles.infoGridContainer}>
      <h3 className={styles.infoGridTitle}>
        As informações abaixo estão corretas?
      </h3>

      <div className={styles.grid}>
        <span>
          Nome do arquivo
        </span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>
            {paycheckInfo.name}
          </p>
        )}
      </div>

      <div className={styles.grid}>
        <span>
          Data de importação
        </span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>
            {moment(paycheckInfo?.creationDate).format('DD/MM/YYYY') !== 'Data inválida' ? 
            moment(paycheckInfo?.creationDate).format('DD/MM/YYYY') : '-'}
          </p>
        )}
      </div>

      <div className={styles.grid}>
        <span>
          Funcionários
        </span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>
            {paycheckInfo.paycheckCount}
          </p>
        )}
      </div>

      <div className={styles.optionsContainer}>
        <FormControl
          variant="outlined"
          className={styles.textField}
        >
          <InputLabel id="paycheck-notification-select-label">
            Parcela*
          </InputLabel>
          <Select
            labelId="paycheck-notification-select-label"
            id="paycheck-notification-select"
            label="Parcela*"
            disabled={isLoading}
            onChange={onChangePayCheckPortion}
            value={paycheckPortion}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="FIRST_INSTALLMENT">
              1ª Parcela
            </MenuItem>
            <MenuItem value="SECOND_INSTALLMENT">
              2ª Parcela
            </MenuItem>
            <MenuItem value="SINGLE_INSTALLMENT">
              Parcela única
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className={styles.textField}
        >
          <InputLabel id="payment-type-select">
            Tipo de contra cheque*
          </InputLabel>
          <Select
            labelId="payment-type-select"
            label="Tipo de contra cheque*"
            onChange={onChangePayCheckType}
            disabled={isLoading}
            value={paycheckType}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="SALARY">
              Salário
            </MenuItem>
            <MenuItem value="VACATION">
              Férias
            </MenuItem>
            <MenuItem value="THIRTEENTH_SALARY">
              13º Salário
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className={styles.textField}
        >
          <InputLabel
            id="select-date-label"
            style={competence ? { display: 'none' } : {}}
            className={
              competence ? styles.disabledInputLabel : styles.inputLabel
            }
          >
            Competência*
          </InputLabel>
          <MuiPickersUtilsProvider
            utils={(DateFnsUtils, MomentUtils)}
            locale={'pt-br'}
          >
            <KeyboardDatePicker
              openTo="year"
              views={['year', 'month']}
              label={competence ? 'Competência*' : null}
              value={competence ? competence : null}
              onChange={onChangeCompetence}
              inputVariant="outlined"
              keyboardIcon={<EventIcon />}
              InputAdornmentProps={{ position: 'end' }}
              helperText={false}
              error={false}
              disabled={isLoading}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </div>
    </div>
  )
}
