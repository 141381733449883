import React, { useState } from 'react';
import { Box, Tabs, Tab, Stepper, Step, StepLabel } from '@material-ui/core';

import TabPanel from '../../Payroll/ManualPayroll/UI/EmployeesListModal/Components/SituationEmployeesTabs/Components/TabsElements/TabPanel';
import PixKeyTab from './Tabs/PixKey';
import AccountAgencyTab from './Tabs/AccountAgency';
import CopyPastePixTab from './Tabs/CopyPaste';
import ReviewStep from './components/ReviewStep';
import { PixContext } from './components/Context';
import { Header } from '../../../components/Header';

import { useStyles } from './styles';

import useCashoutPix from './hooks/useCashoutPix';

export default function CashOutPixPage() {
  const styles = useStyles();

  const {
    context,
    meta: { steps, tabs, currentStep },
  } = useCashoutPix();

  const [currentTab, changeTab] = useState(0);

  const getStepContent = step => {
    return step === 0 ? (
      <>
        <Tabs
          value={currentTab}
          onChange={(_, value) => changeTab(value)}
          indicatorColor="primary"
        >
          {tabs.map(tab => (
            <Tab key={tab} label={tab} className={styles.tab} />
          ))}
        </Tabs>
        <TabPanel value={currentTab} index={0} className={styles.tabpanel}>
          <PixKeyTab />
        </TabPanel>
        <TabPanel value={currentTab} index={1} className={styles.tabpanel}>
          <AccountAgencyTab />
        </TabPanel>
        <TabPanel value={currentTab} index={2} className={styles.tabpanel}>
          <CopyPastePixTab />
        </TabPanel>
      </>
    ) : (
      <ReviewStep />
    );
  };

  return (
    <PixContext.Provider value={context}>
      <Header title="Nova solicitação via Pix" />

      <Box className={styles.stepper}>
        <Stepper activeStep={currentStep}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box className={styles.root}>{getStepContent(currentStep)}</Box>
    </PixContext.Provider>
  );
}
