import React from 'react';

import { TableFooter, TablePagination, TableRow } from '@material-ui/core';

import TablePaginationActions from '../TablePaginationActions';

export default ({
  count,
  pageSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          count={count}
          rowsPerPage={pageSize}
          page={currentPage}
          labelRowsPerPage="Linhas por página"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
};
