import React from "react";

import {
    Button,
    Typography,
    Dialog,
} from '@material-ui/core';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { theme } from './theme';
import styles from './styles.module.css';

import convertBrazilianDate from "../../utils/convertBrazilianDate";
import brazilianCurrencyFormatter from "../../utils/brazilianCurrencyFormatter";

function OnlinePointChargeModal({
    charges,
    open,
    onClick
}) {

    return(
        <MuiThemeProvider theme={theme}>
            <Dialog open={open} fullWidth>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <Typography className={styles.textBigger}>As seguintes faturas do Ponto Online encontram-se em atraso</Typography>
                    </div>
                    
                    {charges?.map(charge => {
                        return (
                            <div className={styles.chargesContent}>
                                <Typography className={styles.chargesText}>{convertBrazilianDate(charge.dueDate)} - {charge.amount === 0 ? "R$ 0,00" : brazilianCurrencyFormatter(charge.amount)}</Typography>
                            </div>
                        )
                    })}

                    <div className={styles.directionContent}>
                        <Typography className={styles.text}>{'Realize o download do boleto para pagamento no menu "Ponto online -> Faturas"'}</Typography>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button onClick={onClick} className={styles.sendButton}>
                            Entendi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </MuiThemeProvider>
    );
}

export default OnlinePointChargeModal;