import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function Header() {
  return (
    <Grid md={12} className={styles.header}>
      <Typography className={styles.title}>
        Visualizar detalhes
      </Typography>
    </Grid>
  )
}
