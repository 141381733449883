import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function ResponsiveFakeList() {
    return (
        <>
            <div className={styles.card}>
                <div>
                    <Typography className={styles.cardTitle}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                    <Typography className={styles.cardText}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                    <Typography className={styles.cardText}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                    <Typography className={styles.cardText}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                </div>

                <div>
                    <Skeleton
                        color="#202020"
                        highlightColor="#444"
                        circle={true} 
                        height={40} 
                        width={40}
                    />
                </div>
            </div>

            <div className={styles.card}>
                <div>
                    <Typography className={styles.cardTitle}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                    <Typography className={styles.cardText}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                    <Typography className={styles.cardText}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                    <Typography className={styles.cardText}>
                        <Skeleton
                            width={180}
                            height={18}
                            color="#202020"
                            highlightColor="#444"
                        />
                    </Typography>
                </div>

                <div>
                    <Skeleton
                        color="#202020"
                        highlightColor="#444"
                        circle={true} 
                        height={40} 
                        width={40}
                    />
                </div>
            </div>
        </>
    )
}
