import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import { ActionsEmployeePaymentQrCodeInformation } from '../../../redux/store/EmployeePaymentQrCodeInformation';

import { ReactComponent as LogoCompany } from '../../../assets/somapay_digital_bank_white.svg';
import { ReactComponent as ErrorIlustration } from '../../../assets/error-ilustration.svg';

import { GeneralDataContent } from './UI/GeneralDataContent';
import { DataContent } from './UI/DataContent';

import ResultNotFound from '../../../components/ResultNotFound';
import styles from './styles.module.css';

import { maskCPF } from '../../../utils/maskCPF';
import { maskCNPJ } from '../../../utils/maskCNPJ';

function EmployeePaymentsQrCodeInformation() {
  const dispatch = useDispatch();
  const { qrCodeInformation } = useSelector(
    state => state.employeePaymentsQrCodeInformation,
  );

  const pathNameSplited = window.location.pathname.split('/');

  const nsuAndLineIdEncrypted = pathNameSplited[pathNameSplited.length - 1];

  function getQrCodeInformation() {
    dispatch(
      ActionsEmployeePaymentQrCodeInformation.getEmployeePaymentsQrCodeInformation(
        { nsuAndLineIdEncrypted },
      ),
    );
  }

  function maskName(name) {
    if (name === null || name === undefined) {
      return '-';
    }

    const [firstName] = name.split(' ');
    return `${firstName} *`;
  }

  function maskNameCompany(companyName) {
    if (companyName === null || companyName === undefined) {
      return '-';
    }

    const words = companyName.split(' ');
    const firstWord = words[0];

    if (words.length === 1) {
      if (firstWord.length === 3) {
        return `${firstWord.slice(0, 2)}*`;
      }
      return `${firstWord.slice(0, firstWord.length - 1)}*`;
    }
    return `${firstWord} *`;
  }

  useEffect(() => {
    getQrCodeInformation();

    return () => {
      dispatch(ActionsEmployeePaymentQrCodeInformation.cleanup());
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: { xs: 'none', sm: '100vh' },
          width: { xs: 'none', sm: '100%' },
          backgroundColor: '#FAFAFA',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            width: { xs: '100%', sm: '37.5rem' },
            borderRadius: { xs: 'none', sm: '0 0 0.5rem 0.5rem' },
            boxShadow: {
              xs: 'none',
              sm: '0px 4px 10px 0px rgba(59, 53, 65, 0.12)',
            },
          }}
        >
          <Box
            className={styles.header}
            flexDirection={{ xs: 'column', sm: 'row-reverse' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            sx={{ borderRadius: { xs: 'none', sm: '0.5rem 0.5rem 0 0' } }}
          >
            <LogoCompany />
            <Typography variant="h6" className={styles.titleHeader}>
              Comprovante de depósito de pagamentos
            </Typography>
          </Box>

          <Box padding={3}>
            {qrCodeInformation?.errorOnQrCodeInformation?.hasError ? (
              <ResultNotFound
                icon={<ErrorIlustration />}
                text="Oops! Houve um erro ao buscar as informações do comprovante"
                alternativeText="Por favor, clique no botão abaixo para buscar as informações novamente."
                buttonText="Recarregar"
                onClick={getQrCodeInformation}
              />
            ) : (
              <>
                <Box className={styles.generalDataContent}>
                  <GeneralDataContent
                    label="N° do comprovante de depósito"
                    content={qrCodeInformation?.data?.receiptPaymentId}
                    loading={qrCodeInformation?.isLoading}
                  />
                  <GeneralDataContent
                    label="Data do depósito"
                    content={qrCodeInformation?.data?.datePayment}
                    loading={qrCodeInformation?.isLoading}
                  />

                  {qrCodeInformation?.data?.typePayment !== null && (
                    <GeneralDataContent
                      label="Tipo de pagamento"
                      content={qrCodeInformation?.data?.typePayment}
                      loading={qrCodeInformation?.isLoading}
                    />
                  )}

                  <Box className={styles.dividerDashed} />

                  <Box sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                    <Box
                      display="flex"
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      sx={{ gap: '0.5rem' }}
                    >
                      <Typography variant="subtitle2" className={styles.label}>
                        Valor depositado
                      </Typography>

                      <Typography variant="h6" className={styles.contentAmount}>
                        {qrCodeInformation?.isLoading ? (
                          <Skeleton
                            variant="text"
                            animantion="wave"
                            width={150}
                          />
                        ) : (
                          qrCodeInformation?.data?.amount ?? 'R$ 0,00'
                        )}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      className={styles.contentAmountExtensive}
                    >
                      {qrCodeInformation?.isLoading ? (
                        <Box
                          display="flex"
                          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                        >
                          <Skeleton
                            variant="text"
                            animantion="wave"
                            width={150}
                          />
                        </Box>
                      ) : (
                        qrCodeInformation?.data?.amountExtensive ?? 'ZERO REAL'
                      )}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={styles.dividerSpacing}
                  sx={{
                    border: { xs: 'solid 1px rgb(0 0 0 / 12%)', sm: 'none' },
                  }}
                />

                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gap: '1.5rem' }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      className={styles.titleContent}
                    >
                      Beneficiário
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ gap: '0.875rem' }}
                    >
                      <DataContent
                        label="Nome"
                        content={maskName(
                          qrCodeInformation?.data?.employeeName,
                        )}
                        loading={qrCodeInformation?.isLoading}
                      />
                      <DataContent
                        label="CPF"
                        content={maskCPF(
                          qrCodeInformation?.data?.cpfCnpjEmployee,
                        )}
                        loading={qrCodeInformation?.isLoading}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      variant="subtitle1"
                      className={styles.titleContent}
                    >
                      Depositante
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ gap: '0.875rem' }}
                    >
                      <DataContent
                        label="Empresa"
                        content={maskNameCompany(
                          qrCodeInformation?.data?.companyReceipment,
                        )}
                        loading={qrCodeInformation?.isLoading}
                      />
                      <DataContent
                        label="CNPJ"
                        content={maskCNPJ(
                          qrCodeInformation?.data?.cpfCnpjCompany,
                        )}
                        loading={qrCodeInformation?.isLoading}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  className={styles.dividerSpacing}
                  sx={{
                    border: { xs: 'solid 1px rgb(0 0 0 / 12%)', sm: 'none' },
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EmployeePaymentsQrCodeInformation;
