import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../services/api';
import convertBytesToBlobDowload from '../../../../utils/convertBytesToBlobDowload';
import convertBytesToBlobDownloadZip from '../../../../utils/convertBytesToBlobDownloadZip';
import { BaseConstructor } from '../../helpers/BaseConstructor';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

const employeeDetailsBase = new BaseConstructor({
  extraStates: {
    employeeData: {},

    paymentHistory: {
      paymentHistoryTable: {
        paymentList: [],
        page: 0,
        perPage: 5,
        length: 0,
        isLoading: false,
      },

      downloadSingleReport: {
        selectedNsu: '',
        documentDownloaded: null,
        isLoading: false,
      },

      downloadMultipleReport: {
        selectedReports: [],
        documentsCompactedDownloaded: null,
        isLoading: false,
        successOnSendList: false,
        percentage: null,
      },
    },
  },
});

const searchPaymentHistory = createAsyncThunk(
  'paymentHistory/search',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      employeeData: { cpf },
      paymentHistory: { paymentHistoryTable },
    } = getState().employees.employeeDetails;

    const requestOptions = {
      params: {
        page: paymentHistoryTable?.page,
        size: paymentHistoryTable?.perPage,
      },
    };

    return api
      .get(`/employees/${cpf}/${currentCompany?.code}/payments`, requestOptions)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getDownloadSinglePaymentReport = createAsyncThunk(
  'paymentHistory/getDownloadSinglePaymentReport',
  async (paymentLineId, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      employeeData: { cpf },
      paymentHistory: { downloadSingleReport },
    } = getState().employees.employeeDetails;

    const requestOptions = {
      params: {
        lineId: paymentLineId,
      },
      responseType: 'blob',
    };

    return api
      .get(
        `/employeesPayments/receiptPayment/${currentCompany?.code}/${downloadSingleReport?.selectedNsu}`,
        requestOptions,
      )
      .then(response => ({
        data: convertBytesToBlobDowload(
          response.data,
          `recibo_pagamento_${cpf}`,
        ),
      }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const postSendMultiplePaymentNsu = createAsyncThunk(
  'paymentHistory/postSendMultiplePaymentNsu',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      employeeData: { cpf },
      paymentHistory: {
        downloadMultipleReport: { selectedReports },
      },
    } = getState().employees.employeeDetails;

    return api
      .post(
        `employeesPayments/receiptPayment/batch/${currentCompany?.code}/${cpf}/cpf`,
        selectedReports,
      )
      .then(response => ({ data: response?.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getDownloadMultiplePaymentReport = createAsyncThunk(
  'paymentHistory/getDownloadMultiplePaymentReport',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { cpf } = getState().employees.employeeDetails.employeeData;

    const requestOptions = {
      headers: {
        url: payload,
      },
      responseType: 'blob',
    };

    return api
      .get('employeesPayments/receiptPayment/file', requestOptions)
      .then(response => ({
        data: convertBytesToBlobDownloadZip(
          response.data,
          `recibos_pagamento_${cpf}`,
        ),
      }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const employeeDetailsSlice = createSlice({
  name: 'employeeDetails',
  initialState: employeeDetailsBase.initialState,
  reducers: {
    ...employeeDetailsBase.methods,
    setEmployeeBasicData: (state, { payload }) => {
      state.employeeData = payload;
    },

    setPaymentHistoryPage: (state, { payload }) => {
      state.paymentHistory.paymentHistoryTable.page = payload;
    },
    setPaymentHistoryPerPage: (state, { payload }) => {
      state.paymentHistory.paymentHistoryTable.perPage = payload;
    },
    setPaymentHistorySelectedReports: (state, { payload }) => {
      state.paymentHistory.downloadMultipleReport.selectedReports = payload;
    },
    setPaymentHistorySelectedNsu: (state, { payload }) => {
      state.paymentHistory.downloadSingleReport.selectedNsu = payload;
    },
    setDownloadMultipleFilesPercentage: (state, { payload }) => {
      state.paymentHistory.downloadMultipleReport.percentage = payload;
    },

    resetDownloadMultipleReportSelectedReports: state => {
      state.paymentHistory.downloadMultipleReport.selectedReports = [];
    },
    resetDownloadMultipleReportSuccessOnSendList: state => {
      state.paymentHistory.downloadMultipleReport.successOnSendList = false;
    },
  },
  extraReducers: {
    // --------------------------------------------- SEARCH PAYMENT HISTORY -------------------------------------

    [searchPaymentHistory.pending]: state => {
      state.paymentHistory.paymentHistoryTable.isLoading = true;
    },
    [searchPaymentHistory.fulfilled]: (state, { payload }) => {
      state.paymentHistory.paymentHistoryTable.isLoading = false;
      state.paymentHistory.paymentHistoryTable.paymentList =
        payload.data.content;
      state.paymentHistory.paymentHistoryTable.length = payload.data.totalSize;
    },

    [searchPaymentHistory.rejected]: (state, { payload }) => {
      state.paymentHistory.paymentHistoryTable.isLoading = false;

      if (!payload)
        handleSnackbarStack().error(
          'Houve um erro ao buscar histórico de pagamento',
        );
      else handleSnackbarStack().error(payload);
    },

    // --------------------------------------------- DOWNLOAD SINGLE REPORT ----------------------

    [getDownloadSinglePaymentReport.pending]: state => {
      state.paymentHistory.downloadSingleReport.isLoading = true;
    },
    [getDownloadSinglePaymentReport.fulfilled]: (state, { payload }) => {
      state.paymentHistory.downloadSingleReport.isLoading = false;
      state.paymentHistory.downloadSingleReport.selectedNsu = '';

      handleSnackbarStack().success('Recibo baixado com sucesso!');

      state.paymentHistory.downloadSingleReport.documentDownloaded = payload;
    },

    [getDownloadSinglePaymentReport.rejected]: (state, { payload }) => {
      state.paymentHistory.downloadSingleReport.isLoading = false;
      state.paymentHistory.downloadSingleReport.selectedNsu = '';

      if (!payload)
        handleSnackbarStack().error('Houve um erro ao baixar o recibo');
      else handleSnackbarStack().error(payload);
    },

    // --------------------------------------------- SEND NSU LIST TO DOWNLOAD MULTIPLE REPORT ----------------------

    [postSendMultiplePaymentNsu.pending]: state => {
      state.paymentHistory.downloadMultipleReport.isLoading = true;
    },
    [postSendMultiplePaymentNsu.fulfilled]: state => {
      state.paymentHistory.downloadMultipleReport.isLoading = false;
      state.paymentHistory.downloadMultipleReport.successOnSendList = true;
      state.paymentHistory.downloadMultipleReport.selectedReports = [];

      handleSnackbarStack().success(
        'Lista de comprovantes enviada com sucesso! O download irá começar em breve',
      );
    },

    [postSendMultiplePaymentNsu.rejected]: (state, { payload }) => {
      state.paymentHistory.downloadMultipleReport.isLoading = false;
      state.paymentHistory.downloadMultipleReport.successOnSendList = false;
      state.paymentHistory.downloadMultipleReport.selectedReports = [];

      if (!payload)
        handleSnackbarStack().error(
          'Houve um erro ao enviar a lista de comprovantes',
        );
      else handleSnackbarStack().error(payload);
    },

    // --------------------------------------------- DOWNLOAD MULTIPLE REPORT ----------------------

    [getDownloadMultiplePaymentReport.pending]: state => {
      state.paymentHistory.downloadMultipleReport.isLoading = true;
    },
    [getDownloadMultiplePaymentReport.fulfilled]: (state, { payload }) => {
      state.paymentHistory.downloadMultipleReport.isLoading = false;

      handleSnackbarStack().success('Download concluído com sucesso!');

      state.paymentHistory.downloadMultipleReport.documentsCompactedDownloaded = payload;
    },

    [getDownloadMultiplePaymentReport.rejected]: (state, { payload }) => {
      state.paymentHistory.downloadMultipleReport.isLoading = false;

      if (!payload)
        handleSnackbarStack().error('Houve um erro ao baixar os comprovantes');
      else handleSnackbarStack().error(payload);
    },
  },
});

const employeeDetailsReducer = employeeDetailsSlice.reducer;
const ActionsEmployeeDetails = {
  ...employeeDetailsSlice.actions,
  searchPaymentHistory,
  getDownloadSinglePaymentReport,
  postSendMultiplePaymentNsu,
  getDownloadMultiplePaymentReport,
};

export { employeeDetailsReducer, ActionsEmployeeDetails };
