import React from 'react';
import {
  Dialog,
} from '@material-ui/core';

import style from './style.module.css';
import DualList from '../DualList';
export default ({
  open,
  onCloseDialog,
  setEmptyList,
  ignoreText,
  ignoreLink = false,
  setTableState,
  tableState
}) => (
  <Dialog open={open} onClose={onCloseDialog} maxWidth="80vw">
    <div className={style.dialog}>
      <DualList setTableState={setTableState} tableState={tableState} setEmptyList={setEmptyList} onClose={onCloseDialog} />
    </div>
  </Dialog>
);
