/* eslint-disable no-param-reassign */
import { removeInvalidObjects } from '../removeInvalidAttributes';

export function BaseConstructor({ extraStates = {}, extraMethods = {} }) {
  this.initialState = {
    filter: {},
    ...extraStates,
  };

  this.methods = {
    cleanup: state => {
      Object.keys(this.initialState).forEach(item => {
        state[item] = this.initialState[item];
      });
    },
    setFilter: (state, { payload }) => {
      state.filter = removeInvalidObjects(payload);
    },
    resetFilter: state => {
      state.filter = {};
    },
    ...extraMethods,
  };
}
