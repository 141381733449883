import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Buttons from '../../../../../../components/Buttons';

import { useStyles } from '../../../styles';

const ProcessModal = NiceModal.create(({ onConfirm }) => {
  const modal = useModal();
  const styles = useStyles();

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return;

    modal.remove();
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={handleClose}
      PaperProps={{ className: styles.dialog }}
      disableEscapeKeyDown
    >
      <DialogTitle className={styles.dialogTitle}>
        Processamento concluído com sucesso!
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <DialogContentText>
          Clique em “CONTINUAR” para seguir com o processo.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons.PrimaryButton
          color="secondary"
          onClick={onConfirm}
          title="Continuar"
        />
      </DialogActions>
    </Dialog>
  );
});

export default ProcessModal;
