import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import {
  Typography,
  Button,
  Grid,
  Menu,
  MenuItem,
  Hidden,
  Drawer,
  TextField,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import style from './styles.module.css';
import { styles, useStyles, theme } from './styles';
import Filters from './UI/Filters';
import LogoLoading from '../../../components/LogoLoading';
import { api } from '../../../services/api';
import TableRechargeList from './UI/Table';
import ResultNotFound from '../../../components/ResultNotFound';
import { TablePaginationActions } from '../../OnlinePointEmployees/UI/Table/UI';

function BeevaleBenefitsRecharges() {
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const anchorRef = useRef(null);
  const history = useHistory();

  const companyCodeValueReducer = useSelector(
    state => state.currentCompanyReducer.company,
  );
  const { accountSelected } = useSelector(state => state.account);

  const [filterPaymentType, setFilterPaymentType] = useState(null);
  const [situation, setSituation] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [recharges, setRecharges] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [changedFilter, setChangedFilter] = useState(false);

  const onChangePaymentType = event => {
    setCurrentPage(0);
    setFilterPaymentType(event.target.value);
  };

  const handleChangeSituation = event => {
    setCurrentPage(0);
    setSituation(event.target.value);
  };

  useEffect(() => {
    getRecharges();
  }, [pageSize, currentPage, filterPaymentType, situation]);

  function getRecharges() {
    setLoading(true);

    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize,
        type: filterPaymentType,
        status: situation,
      },
    };

    api
      .get(`beeValeBenefits/search/recharge/${companyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setCurrentPageSize(data.currentPageSize);
        setRecharges(data.recharges);
      })
      .finally(() => {
        setLoading(false);
        setLoadingLogo(false);
      });
  }

  const renderHeader = () => {
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        className={style.headerPayments}
      >
        <Grid item container direction="column">
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography className={style.titleStyle}>Recargas</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only="xs">
          <div style={styles.buttonPadding}>
            <Button
              ref={anchorRef}
              variant="contained"
              color="primary"
              style={styles.buttonStyle}
              startIcon={<AddIcon />}
              onClick={() => history.push('/beevaleBenefits/create/recharge')}
              aria-controls="simple-menu"
              aria-haspopup="true"
              disabled={accountSelected?.status === 'CLOSED'}
            >
              <Typography style={styles.buttonTextStyle} display="inline">
                Novo recarga
              </Typography>
            </Button>
          </div>
        </Hidden>
      </Grid>
    );
  };

  const renderFilters = () => {
    return (
      <Filters
        filterPaymentType={filterPaymentType}
        onChangePaymentType={onChangePaymentType}
        handleChangeSituation={handleChangeSituation}
        situation={situation}
      />
    );
  };

  if (loadingLogo) {
    return <LogoLoading />;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function handleChangePage(event, newPage) {
    setCurrentPage(newPage);
    setChangedFilter(true);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  function handleOpenModalAvaliable(employeeCpf) {}

  const onDetailsClicked = row => {
    sessionStorage.setItem('rechargeDataBeevale', JSON.stringify(row));
    history.push('/beevaleBenefits/recharge/details');
  };

  function handleClearFilters() {
    setLoading(true);
    setSituation(null);
    setFilterPaymentType(null);
  }

  return (
    <>
      {renderHeader()}
      {renderFilters()}
      {recharges.length === 0 ? (
        <ResultNotFound
          text="Nenhum Resultado Encontrado"
          alternativeText="Verifique os dados pesquisados e tente novamente."
          onClick={handleClearFilters}
          buttonText="Limpar Filtros"
        />
      ) : (
        <TableRechargeList
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          loading={loading}
          changedFilter={changedFilter}
          currentPage={currentPage}
          pageSize={pageSize}
          totalSize={totalSize}
          currentPageSize={currentPageSize}
          rows={recharges}
          getComparator={getComparator(order, orderBy)}
          stableSort={stableSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          TablePaginationActions={TablePaginationActions}
          handleOpenModalAvaliable={handleOpenModalAvaliable}
          onDetailsClicked={onDetailsClicked}
        />
      )}
    </>
  );
}

export default BeevaleBenefitsRecharges;
