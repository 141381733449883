import React from 'react';
import styles from '../styles.module.css';
import {
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

function TableSearch() {
  return (
    <div>
      <Typography className={styles.tableTitle}>Seguro de Vida</Typography>
      <TextField
        className={styles.searchInput}
        variant="outlined"
        placeholder="Pesquise por nome ou CPF"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search style={{ color: '#666666' }}/>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default TableSearch;
