import React from 'react';

import { Button, Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function EmptyList({
  icon,
  title,
  description,
  buttonText,
  onClick
}) {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        {icon}
      </div>
      <Typography className={styles.title}>
        {title}
      </Typography>
      <Typography className={styles.description}>
        {description}
      </Typography>
      {buttonText && (
         <Button onClick={onClick} className={styles.button}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
