import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import Content from './Content';

import PrimaryModal from '../../../components/PrimaryModal';
import EndUploadModal from '../../../components/PrimaryModal';
import CancelUploadModal from '../../../components/PrimaryModal';
import ProgressToolBar from '../../../components/ProgressToolBar';

import { api } from '../../../services/api';
import { pusher } from '../../../utils/pusher';
import { pusherStatus, pusherChannels } from '../../../enums/pusher';

export default function Index() {
  const paycheckFileItem = JSON.parse(localStorage.getItem('paycheckFileItem'));
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const FILE_TYPE = sessionStorage.getItem('fileType');
  const UPLOAD_DATA_PAYCHECK = JSON.parse(
    localStorage.getItem(`uploadDataPaycheck-${CURRENT_COMPANY_CODE}`),
  );

  const [cancelUploadLoading, setCancelUploadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openEndUploadModal, setOpenEndUploadModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');

  const [file, setFile] = useState([]);
  const [paycheckData, setPaycheckData] = useState(UPLOAD_DATA_PAYCHECK || {});

  const history = useHistory();

  const {
    PROCESSED_PAYCHECK_SUCCESS,
    PROCESSED_PAYCHECK_ERROR,
    PROCESSING,
    CANCELED,
  } = pusherStatus;

  const { PROCESSED_PAYCHECK } = pusherChannels;

  useEffect(() => {
    if (paycheckData?.status === PROCESSING) setIsLoading(true);

    const channel = pusher.subscribe(CURRENT_COMPANY_CODE);

    channel.bind(PROCESSED_PAYCHECK, function(data) {
      const { status, data: errorData } = data;

      setPaycheckData(data);

      localStorage.setItem(
        `uploadDataPaycheck-${CURRENT_COMPANY_CODE}`,
        JSON.stringify(data),
      );

      if (status === PROCESSING) {
        setIsLoading(true);
      }

      if (status === CANCELED) {
        clearDropArea();
      }

      if (status === PROCESSED_PAYCHECK_SUCCESS) {
        setIsLoading(false);
        setOpenEndUploadModal(true);
      }

      if (status === PROCESSED_PAYCHECK_ERROR) {
        setIsLoading(false);
        setOpenModal(true);
        setModalTitle(errorData?.errorDetail);
        setModalDescription(errorData?.errorsDescription);
      }
    });
  }, []);

  function clearDropArea() {
    setIsLoading(false);
    setCancelUploadLoading(false);
    setOpenCancelModal(false);
    localStorage.removeItem(`uploadDataPaycheck-${CURRENT_COMPANY_CODE}`);
    localStorage.removeItem('paycheckFileItem');
    setPaycheckData({});
    setFile([]);
  }

  const onDrop = useCallback(acceptedFiles => {
    let value = acceptedFiles[0].name.substring(
      acceptedFiles[0].name.lastIndexOf('.') + 1,
    );

    value = value?.toLowerCase();

    if (value !== 'txt') {
      setModalTitle('Formato de arquivo inválido');
      setModalDescription('Escolha um arquivo do tipo .txt');
      setOpenModal(true);
      return;
    }

    localStorage.setItem('paycheckFileItem', JSON.stringify(acceptedFiles));
    setFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function onRemoveFileClicked() {
    setFile([]);
    localStorage.removeItem('paycheckFileItem');
  }

  function onCancelUpload() {
    setOpenCancelModal(true);
  }

  function confirmCancelUpload() {
    const { processId } = paycheckData;

    setCancelUploadLoading(true);

    api
      .put(`/files/paychecks/cancel/${processId}`)
      .then(() => clearDropArea())
      .catch(() => setCancelUploadLoading(false));
  }

  function validatePaycheckFile() {
    const formData = new FormData();

    setIsLoading(true);

    formData.append('file', file[0]);

    api
      .post(
        `/files/paychecks/import/validate/${CURRENT_COMPANY_CODE}/${FILE_TYPE}`,
        formData,
      )
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setModalTitle(errors[0].errorDetail);
          setModalDescription(errors[0].errorsDescription);
          setOpenModal(true);
        },
      );
  }

  function handleBack() {
    history.push('/paycheck/paycheck-menu/');
  }

  function goToPaycheckReview() {
    const { fileId } = paycheckData;

    history.push(`/paycheck/paycheck-data-review/${fileId}`);

    localStorage.removeItem(`uploadDataPaycheck-${CURRENT_COMPANY_CODE}`);
    localStorage.removeItem('paycheckFileItem');
    setPaycheckData({});
  }

  function closeModal() {
    if (paycheckData?.data?.errorReason === 'FILE_PROCESS_GENERAL_ERROR') {
      goToPaycheckReview();
    }

    setOpenModal(false);
  }

  return (
    <>
      <Header />

      <Content
        file={file}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
        useDropzone={useDropzone}
        onDrop={onDrop}
        paycheckFileItem={paycheckFileItem}
        isLoading={isLoading}
        onRemoveFileClicked={onRemoveFileClicked}
        onCancelUpload={onCancelUpload}
        paycheckData={paycheckData}
      />

      <PrimaryModal
        open={openModal}
        title={modalTitle}
        text={modalDescription}
        confirmButtonText="Entendi"
        onConfirmClicked={closeModal}
      />

      <CancelUploadModal
        open={openCancelModal}
        onConfirmClicked={confirmCancelUpload}
        onCancelButtonClicked={() => setOpenCancelModal(false)}
        confirmButtonText="Sim, cancelar"
        cancelButtonText="Não, continuar"
        title="Você tem certeza que deseja cancelar a criação deste cadastro?"
        loading={cancelUploadLoading}
        disabledConfirm={cancelUploadLoading}
        disabledCancel={cancelUploadLoading}
      />

      <EndUploadModal
        open={openEndUploadModal}
        onConfirmClicked={goToPaycheckReview}
        onCancelButtonClicked={() => setOpenEndUploadModal(false)}
        confirmButtonText="Continuar"
        title="Processamento concluído com sucesso!"
        text="Clique em “CONTINUAR” para seguir com o processo"
        purpleButton
      />

      <ProgressToolBar
        onBackClicked={handleBack}
        onNextClicked={validatePaycheckFile}
        nextButtonText="Processar"
        backButtonText="Voltar"
        disabled={file.length === 0}
        loading={isLoading}
      />
    </>
  );
}
