import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppBar, Tab, Tabs } from '@material-ui/core';

import ConfirmationModal from '../../../components/PrimaryModal';
import SuccessModal from '../../../components/SuccessModal';
import LogoLoading from '../../../components/LogoLoading';
import FloatButton from '../../../components/FloatButton';
import Snackbar from '../../../components/Snackbar';

import InsuranceFile from './UI/InsuranceFile/InsuranceFileScreen';
import InsuranceTab from './UI/InsuranceTab';
import Header from './UI/Header';
import BottomBar from './UI/BottomBar';

import styles from './styles.module.css';

import { api } from '../../../services/api';

import { actions } from './actions';
import { isMobile } from '../../../utils/breakpoints';

function InsuranceManagement() {
  const dispatch = useDispatch();

  const count = useSelector(state => state.insuranceReducer.count);
  const insuranceSituation = useSelector(
    state => state.insuranceReducer.insuranceSituation,
  );
  const insuranceType = useSelector(
    state => state.insuranceReducer.insuranceType,
  );
  const cpfOrName = useSelector(state => state.insuranceReducer.cpfOrName);

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const insuranceEmployeesTable = JSON.parse(
    sessionStorage.getItem('insuranceEmployeesTable'),
  )
    ? JSON.parse(sessionStorage.getItem('insuranceEmployeesTable'))
    : [];

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [hasNotAssureds, setHasNotAssureds] = useState(false);

  const [employeeData, setEmployeeData] = useState([]);

  const [showSalaryTextField, setShowSalaryTextField] = useState(null);

  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [selected, setSelected] = useState(insuranceEmployeesTable);

  const [errorMessage, setErrorMessage] = useState('');

  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);

  useEffect(() => {
    getInsured();
  }, [insuranceType, insuranceSituation, activeTab, page, rowsPerPage]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getInsured();
    }, 500);

    return () => clearTimeout(delay);
  }, [cpfOrName]);

  function getInsured() {
    setLoadingTable(true);

    const requestOptions = {
      params: {
        cpfOrName,
        insuranceType,
        assuredStatus: insuranceSituation,
        page,
        size: rowsPerPage,
      },
    };

    api
      .get(`insurance/v2/search/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        setHasNotAssureds(false);
        setRowsPerPage(data.currentPageSize);
        setTotalSize(data.totalSize);
        setEmployeeData(data.content);
        setCurrentPageSize(data.currentPageSize);
      })
      .catch(({ response }) => {
        if (
          response?.data?.errors[0]?.errorReason === 'COMPANY_HAS_NOT_ASSUREDS'
        )
          setHasNotAssureds(true);
      })
      .finally(() => {
        setLoadingData(false);
        setLoadingTable(false);
      });
  }

  function handleContinue() {
    setErrorMessage('');
    setOpenConfirmationModal(true);
  }

  function handleConfirm() {
    setLoadingButton(true);

    const code = insuranceSituation === 'Vigente' ? 1 : 2;

    api
      .put(`/insurance/v2/situation?code=${code}`, insuranceEmployeesTable)
      .then(() => {
        setOpenConfirmationModal(false);
        setOpenSuccessModal(true);
        setErrorMessage('');
      })
      .catch(() => {
        setErrorMessage(
          insuranceSituation === 'Vigente'
            ? 'Não foi possível cancelar o seguro para o funcionário'
            : 'Não foi possível ativar o seguro para o funcionário',
        );
      })
      .finally(() => setLoadingButton(false));
  }

  function handleChangeTab(event, newValue) {
    switch (newValue) {
      case 0:
        setActiveTab(0);
        break;
      case 1:
        setActiveTab(1);
        break;
    }

    sessionStorage.removeItem('insuranceType');
  }

  function handleCloseSuccessModal() {
    setOpenSuccessModal(false);
    getInsured();
    clearSelectedInsurances();

    sessionStorage.removeItem('insuranceSituation');
    sessionStorage.removeItem('insuranceType');
  }

  function clearSelectedInsurances() {
    sessionStorage.removeItem('insuranceEmployeesTable');

    setSelected([]);

    dispatch({
      type: 'SET_INSURANCE_COUNT',
      count: 0,
    });
  }

  if (loadingData) {
    return <LogoLoading />;
  }

  function openSnackbarOnSalaryUpdate() {
    setOpenSnackbar(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Salário atualizado com sucesso.');
  }

  function openSnackbarErrorOnSalaryUpdate() {
    setOpenSnackbar(true);
    setSnackbarSeverity('error');
    setSnackbarMessage(
      'Houve um erro ao atualizar o salário, tente novamente mais tarde.',
    );
  }

  return (
    <div>
      <Header setOpenDrawer={setOpenDrawer} />

      {/*
        - Abas removidas porque os clientes achavam que eram um erro não poder acessar a aba de Arquivo.
        - Código comentado pois haverão implementações futuras.
      */}

      {/* <AppBar elevation={0} position="static" className={styles.appBar}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          className={styles.tabs}
        >
          <Tab className={styles.tab} label="Gerenciar seguros" />
          <Tab className={styles.tab} label="Arquivo" disabled />
        </Tabs>
      </AppBar> */}

      {activeTab === 0 ? (
        <InsuranceTab
          clearSelectedInsurances={clearSelectedInsurances}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          setSelected={setSelected}
          selected={selected}
          handleContinue={handleContinue}
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          loadingTable={loadingTable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalSize={totalSize}
          currentPageSize={currentPageSize}
          hasNotAssureds={hasNotAssureds}
          showSalaryTextField={showSalaryTextField}
          setShowSalaryTextField={setShowSalaryTextField}
          currentCompanyCode={currentCompanyCode}
          openSnackbarOnSalaryUpdate={openSnackbarOnSalaryUpdate}
          openSnackbarErrorOnSalaryUpdate={openSnackbarErrorOnSalaryUpdate}
        />
      ) : (
        <InsuranceFile
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          loadingTable={loadingTable}
        />
      )}

      <BottomBar
        selected={selected}
        onClick={handleContinue}
        insuranceSituation={insuranceSituation}
        activeTab={activeTab}
        purpleButton
        hidden={isMobile}
      />

      <ConfirmationModal
        open={openConfirmationModal}
        onCloseDialog={() => setOpenConfirmationModal(false)}
        title={
          insuranceSituation === 'Vigente'
            ? 'Cancelar seguros'
            : 'Ativar Seguros'
        }
        text={
          insuranceSituation === 'Vigente'
            ? 'Importante: o seguro permanece em vigor até fim do mês de cancelamento.'
            : 'Ao confirmar a ativação, a situação do segurado será alterada imediatamente.'
        }
        confirmButtonText={
          insuranceSituation === 'Vigente' ? 'Cancelar' : 'Ativar'
        }
        loading={loadingButton}
        onConfirmClicked={handleConfirm}
        cancelButtonText="Sair"
        onCancelButtonClicked={() => setOpenConfirmationModal(false)}
        errorMessage={errorMessage}
      />

      <SuccessModal
        open={openSuccessModal}
        onCloseDialog={handleCloseSuccessModal}
        title={
          insuranceSituation === 'Vigente'
            ? 'Seguros cancelados com sucesso'
            : 'Seguros ativados com sucesso'
        }
        text={
          insuranceSituation === 'Vigente'
            ? `Você cancelou o seguro de ${count} usuário(s)`
            : `Você ativou o seguro de ${count} usuário(s)`
        }
        closeButtonText="Fechar"
      />

      <Snackbar
        open={openSnackbar}
        close={() => setOpenSnackbar(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />

      <FloatButton
        actions={actions}
        hidden={!isMobile || selected.length > 0}
      />
    </div>
  );
}

export default InsuranceManagement;
