import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
} from '@material-ui/core';

import { RadioCardDefault } from './Components/RadioCardDefault';

import { useStyles } from './styles';

export function RadioCardOptions({
  name = '',
  label = '',
  size = 'medium',
  disabled = false,
  disabledOptions = [],
  disabledTooltipMessageGeneral = 'Não é possível escolher esta opção no momento.',
  defaultValue = null,
  value = null,
  onChange = () => {},
  options = [],
  getOptionLabel = item =>
    typeof item?.label === 'function' ? item?.label() : item?.label,
  getOptionValue = item => item?.value,
  error = false,
  helperText = '',
  row = false,
  labelRow = false,
  containerBorders = false,
  containerStyleProps = {},
  radioCardPaperStyleProps = {},
  ...otherProps
}) {
  const ID = `radio-group-${name}`;

  const styles = useStyles();

  return (
    <FormControl error={error} style={{ width: '100%' }}>
      <Box
        className={`${styles.container} ${labelRow &&
          styles.labelRowContainer} ${containerBorders &&
          styles.borderedContainer}`}
        sx={containerStyleProps}
      >
        <FormLabel htmlFor={ID} className={styles.label}>
          {label}
        </FormLabel>

        <RadioGroup
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={(_, i) => onChange(i)}
          id={ID}
        >
          <Box
            className={styles.cardContainer}
            flexDirection={row ? 'row' : 'column'}
          >
            {options?.map(item => (
              <RadioCardDefault
                key={JSON.stringify(item)}
                size={size}
                disabled={
                  disabled || disabledOptions.includes(getOptionValue(item))
                }
                disabledTooltipMessageGeneral={disabledTooltipMessageGeneral}
                value={value}
                onChange={onChange}
                item={item}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                radioCardPaperStyleProps={radioCardPaperStyleProps}
                {...otherProps}
              />
            ))}
          </Box>
        </RadioGroup>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
