import React, { useState } from 'react';

import {
  Dialog,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import brazilianCurrencyFormatter from '../../../../../utils/brazilianCurrencyFormatter';
import brazilianCurrencyFormatter2 from '../../../../../utils/brazilianCurrencyFormatter2';

import styles from './styles.module.css';

export default ({
  open,
  onClose,
  onCancelButtonClicked,
  onConfirmButtonClicked,
  loading,
  handleNewLimitInput,
  newLimitValue,
  employeeName,
  employeeCpf,
  actualEmployeeLimit,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <div className={styles.container}>
        <Typography className={styles.title}>
          Mudança de limite de funcionário
        </Typography>
        <div className={styles.employeeData}>
          <Typography className={styles.text}>Nome: {employeeName}</Typography>
          <Typography className={styles.text}>CPF: {employeeCpf}</Typography>
          <Typography className={styles.text}>
            Limite atual:{' '}
            {actualEmployeeLimit === 0
              ? 'R$ 0,00'
              : brazilianCurrencyFormatter(actualEmployeeLimit)}
          </Typography>
        </div>
        <TextField
          variant="outlined"
          label="Novo limite"
          onChange={handleNewLimitInput}
          value={brazilianCurrencyFormatter2(newLimitValue)}
        />
        <div className={styles.buttonsGroup}>
          <Button
            className={styles.closeButton}
            onClick={onCancelButtonClicked}
          >
            Cancelar
          </Button>
          <Button
            className={styles.confirmButton}
            onClick={onConfirmButtonClicked}
            disabled={newLimitValue === '' || newLimitValue === '00'}
          >
            {loading ? (
              <CircularProgress className={styles.circularLoading} />
            ) : (
              'Salvar'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
