import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { refreshToken } from './user';
import { logout } from './auth';

const mutex = new Mutex();

const baseUrl = process.env.REACT_APP_API_HOST_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async headers => {
    const accessToken = sessionStorage.getItem('@ContaSoma-Token');

    if (accessToken) {
      headers.append('Authorization', accessToken);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        await refreshToken();

        result = await baseQuery(args, api, extraOptions);
      } catch {
        logout();
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const reduxApi = createApi({
  reducerPath: 'reduxApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
