import React from 'react';

import { Box } from '@material-ui/core';
import { Header } from '../../../../components/Header';
import { useRegisterFilesDetails } from './Hooks/useRegisterFilesDetails';
import { EnhancedCard } from './Components/EnhancedCard';
import { Table } from '../../../../components/Table';
import { useTableConfig } from './Hooks/useTableConfig';
import { Filter } from '../../../../components/Filter';
import Form from '../../../../components/Form';

export default function List() {
  const {
    generalCardObject,
    filterObject,
    tableObject,
  } = useRegisterFilesDetails();
  const tableConfig = useTableConfig();

  return (
    <>
      <Header title="Detalhes do arquivo" />

      <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <EnhancedCard
          isLoading={generalCardObject.isLoading}
          fileName={generalCardObject.fileName}
          userThatImportedTheFile={generalCardObject.userThatImportedTheFile}
          processedAt={generalCardObject.processedAt}
          statisticsData={generalCardObject.statisticsComponentsData}
        />

        <Filter
          defaultValues={filterObject.filterInitialValues}
          handleRequests={filterObject.handleFilterChange}
        >
          <Form.MaskedField
            name="cpf"
            label="Pesquisar por CPF"
            mask="000.000.000-00"
          />
          <Form.TextField name="name" label="Pesquisar por nome" />
        </Filter>

        <Table
          data={tableObject.data}
          hasError={tableObject.hasError}
          isLoading={tableObject.isLoading}
          tableConfig={tableConfig}
          length={tableObject.length}
          requests={tableObject.paginationRequests}
        />
      </Box>
    </>
  );
}
