import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsOnboardingReprovedGeneral } from '../../../../../redux/store/Employees/OnboardingReproved';

export function useOnboardingReproved() {
  const dispatch = useDispatch();
  const { onboardingReproved, length } = useSelector(
    state => state.employees.onboardingReprovedGeneral,
  );

  const filterInitialValues = {
    nameOrCpf: '',
    registration: '',
    bankData: '',
    onboardingSituation: '',
  };

  function handleFilterChange(data) {
    dispatch(ActionsOnboardingReprovedGeneral.setFilter(data));
    dispatch(
      ActionsOnboardingReprovedGeneral.searchOnboardingReprovedEmployees(),
    );
  }

  function handlePaginationRequests(page, perPage) {
    dispatch(ActionsOnboardingReprovedGeneral.handleChangePage(page));
    dispatch(ActionsOnboardingReprovedGeneral.handleChangePerPage(perPage));

    dispatch(
      ActionsOnboardingReprovedGeneral.searchOnboardingReprovedEmployees(),
    );
  }

  useEffect(() => {
    dispatch(
      ActionsOnboardingReprovedGeneral.searchOnboardingReprovedEmployees(),
    );
    dispatch(ActionsOnboardingReprovedGeneral.getBankList());
  }, []);

  return {
    filterObject: {
      filterInitialValues,
      handleFilterChange,
    },
    tableObject: {
      data: onboardingReproved?.employeesReprovedList,
      length,
      isLoading: onboardingReproved?.isLoading,
      hasError: onboardingReproved?.errorOn?.hasError,
      handlePaginationRequests,
    },
  };
}
