import React from 'react';
import { Hidden, Typography } from '@material-ui/core';

import styles from './styles.module.css';

function MenuCard({ icon, title, onButtonClicked, disabled }) {
  return (
    <Hidden only="xs">
      <div
        className={disabled ? styles.disabledCard : styles.card}
        onClick={onButtonClicked}
      >
        <div className={styles.icon}>{icon}</div>
        <Typography className={styles.title}>{title}</Typography>
      </div>
    </Hidden>
  );
}

export default MenuCard;
