import React from 'react';

import {
  IconButton,
  Typography
} from '@material-ui/core';

import Progress from './Progress';

import styles from './styles.module.css';

export default function StepTopBar({
  name,
  icon,
  onClick,
  progress
}) {
  const FIRST_STEP = 1;
  const DEFAULT_STEP = 0;
  const FULL_PROGRESS = 100;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.nameContainer}>
          <IconButton onClick={onClick}>
            {icon}
          </IconButton>
          <Typography className={styles.name}>
            {name}
          </Typography>
        </div>

        <Progress 
          progress={
            progress === FIRST_STEP ? 
            progress * 10 :
            progress > FIRST_STEP ?
            progress * 20 :
            FULL_PROGRESS
          }
          hidden={progress === DEFAULT_STEP}
        />
      </div>    
    </>
  )
}
