import React from 'react';
import { Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

function FakeOtherData() {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.contentBody}>
          <Typography className={styles.title}>
            Total de pagamentos realizados
          </Typography>
          <Typography className={styles.subTitle}>
            <Skeleton
              color="#202020"
              highlightColor="#444"
              className={styles.skeletonOne}
            />
          </Typography>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.contentTotalEmployees}>
          <Typography className={styles.titleFooter}>
            Total de
            {'\n'}
            <br />
            funcionários Pagos
          </Typography>
          <Typography className={styles.subTitleTwo}>
            <Skeleton
              color="#202020"
              highlightColor="#444"
              className={styles.skeletonTwo}
            />
          </Typography>
        </div>

        <div className={styles.contentTotalPaidOut}>
          <Typography className={styles.titleFooter}>
            Valor Total
            {'\n'}
            <br />
            pago
          </Typography>
          <Typography className={styles.subTitleTwo}>
            <Skeleton
              color="#202020"
              highlightColor="#444"
              className={styles.skeletonTwo}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default FakeOtherData;
