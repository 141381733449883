import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    title: {
      marginBottom: '1rem',
      fontSize: '2rem',
      fontWeight: 700,
    },
    stepper: {
      padding: '1.25rem',
      marginBottom: '1rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',

      '& .MuiStepper-root': {
        padding: 0,
      },
    },
    root: {
      padding: '1.5rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
    },
    tab: {
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    tabpanel: {
      padding: '2rem 0',
    },
  }),
);
