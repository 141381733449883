import React from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const head = [
  {
    id: 'competence',
    numeric: false,
    label: 'Competência',
  },
  {
    id: 'totalLoansRequested',
    numeric: true,
    label: 'Qtd. empréstimos',
  },
  {
    id: 'totalEmployees',
    numeric: true,
    label: 'Qtd. funcionários',
  },
  {
    id: 'loanAmountRequested',
    numeric: true,
    label: 'Valor contratado',
  },
  {
    id: 'loanAmountToPay',
    numeric: true,
    label: 'Valor à pagar',
  },
  {
    id: 'loanAmountPaid',
    numeric: true,
    label: 'Valor pago',
  },
  {
    id: 'situation',
    numeric: false,
    label: 'Situação',
  },
  {
    id: '',
    label: '',
  },
];

export default ({ sort, order, orderBy }) => {
  return (
    <TableHead>
      <TableRow>
        {head.map(item =>
          item.id !== '' && item.id !== 'situation' ? (
            <TableCell
              key={item.id}
              sortDirection={orderBy === item.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : 'asc'}
                onClick={() => sort(item.id)}
              >
                {item.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={item.id}>{item.label}</TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};
