import React from 'react';
import { Box, DialogTitle, Typography, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Buttons from '../../../../../Buttons';

export function FilterHeader({ onCloseFilterDrawer = () => {} }) {
  return (
    <DialogTitle
      classes={{
        root: makeStyles({
          root: {
            boxShadow: '0px -2px 6px 6px rgba(59, 53, 65, 0.2)',
          },
        })().root,
      }}
      style={{ backgroundColor: '#52197F', color: 'var(--white)' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">Filtros</Typography>

        <Buttons.IconButton
          icon={<Close style={{ color: 'var(--white)' }} />}
          onClick={onCloseFilterDrawer}
        />
      </Box>
    </DialogTitle>
  );
}
