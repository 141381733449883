import React from 'react';
import { SecondaryButtonStyled } from './Styled';

export function SecondaryButton({
  variant = 'text',
  color = 'secondary',
  icon = () => {},
  title = 'SECONDARY BUTTON',
  onClick = () => {},
  disabled = false,
  ...props
}) {
  return (
    <SecondaryButtonStyled
      variant={variant}
      color={color}
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {title}
    </SecondaryButtonStyled>
  );
}
