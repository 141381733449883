export default [
  {
    value: 1,
    label: 'CLT (tempo determinado)',
  },
  {
    value: 2,
    label: 'CLT (tempo Indeterminado)',
  },
  {
    value: 3,
    label: 'Autônomo (RPA)',
  },
  {
    value: 4,
    label: 'Jovem aprendiz',
  },
  {
    value: 5,
    label: 'Estágio',
  },
  {
    value: 6,
    label: 'Temporário',
  },
  {
    value: 7,
    label: 'Pro labore',
  },
];
