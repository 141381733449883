import { useEffect, useState } from 'react';

export default function useDebounce(value, delay, returnBoolToo = false) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsTyping(false);
    }, delay);

    return () => {
      if (!isTyping) setIsTyping(true);
      clearTimeout(handler);
    };
  }, [value, delay]);

  return returnBoolToo ? { isTyping, debouncedValue } : debouncedValue;
}
