import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsManualPayroll as Actions } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

import useDebounce from '../../../../../../../../../../hooks/useDebounce';

export function useFilters() {
  const dispatch = useDispatch();

  const [nameOrCpf, setNameOrCpf] = useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [costAndCenterList, setCostAndCenterList] = useState('');

  const [activeSituationButton, setActiveSituationButton] = useState(6);
  const [searchSituation, setSearchSituation] = useState('');

  const nameOrCpfDebounced = useDebounce(nameOrCpf, 500);

  const handleFilterSituation = (valueActiveButton, situationSearch) => {
    setActiveSituationButton(valueActiveButton);
    setSearchSituation(situationSearch);
    dispatch(Actions.setPreviewSelected([]));
  };

  const handleCostAndCenter = value => {
    setCostAndCenterList(value);
    dispatch(Actions.setPreviewSelected([]));
  };

  const handleNameOrCpf = value => {
    setNameOrCpf(value);
    dispatch(Actions.setPreviewSelected([]));
  };

  useEffect(() => {
    dispatch(Actions.setHandlePageChangeEmployeesAproved(0));

    dispatch(
      Actions.setFilterEmployeesAproved({
        nameOrCpf,
        costAndCenterList,
        searchSituation,
      }),
    );
    dispatch(Actions.searchSimpleApprovedList());
  }, [nameOrCpfDebounced, costAndCenterList, searchSituation]);

  return {
    nameOrCpfFieldObject: {
      nameOrCpf,
      setNameOrCpf: handleNameOrCpf,
    },
    textFieldFocusedObject: {
      textFieldFocused,
      setTextFieldFocused,
    },
    costAndCenterFieldObject: {
      costAndCenterList,
      setCostAndCenterList: handleCostAndCenter,
    },
    employeeSituationsObject: {
      activeSituationButton,
      setActiveSituationButton,
      searchSituation,
      setSearchSituation,
      handleFilterSituation,
    },
  };
}
