import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(_ =>
  createStyles({
    root: {
      height: '90%',
      width: '90%',
    },
    frame: {
      height: '100%',
    },
  }),
);
