import React, { createContext } from 'react';
import { useContext } from 'react';

const SuccessModalContext = createContext();
const defaultModal = {
  open: false,
  title: '',
  text: '',
  onClose: () => null,
  closeButtonText: '',
  onCloseDialog: () => null,
  image: ''
}

export default function SuccessModalContextProvider({ children }) {
  const [modalContext, setModalProps] = React.useState(defaultModal);

  function applyConfigSuccessModal(config) {
    setModalProps(config);
  }

  return (
    <SuccessModalContext.Provider value={{modalContext, applyConfigSuccessModal}}>
      {children}
    </SuccessModalContext.Provider>
  );
}

export function useModalContext() {
  const context = useContext(SuccessModalContext);

  if(!context) {
    throw new Error('useModalContext must be used within a SuccessModalContext')
  }

  const {modalContext, applyConfigSuccessModal} = context;
  return { modalContext, applyConfigSuccessModal };
}
