import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import Header from './Header';
import InfoGrid from './InfoGrid';
import Accordion from './Accordion';
import EmployeesTable from './EmployeesTable';

import ProgressToolBar from '../../../../components/ProgressToolBar';
import SuccessModal from '../../../../components/SuccessModal';
import ConfirmProcessModal from '../../../../components/PrimaryModal';

import { api } from '../../../../services/api';

import { pusher } from '../../../../utils/pusher';

import { pusherStatus, pusherChannels } from '../../../../enums/pusher';

import styles from './styles.module.css';

export default function DataReview({
  match: {
    params: { fileId },
  },
}) {
  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const INSURANCE_IMPORT_DATA = JSON.parse(
    sessionStorage.getItem('insuranceImportData'),
  );
  const FILE_TYPE = sessionStorage.getItem('fileType');
  const IS_EXCEL_FILE = Boolean(FILE_TYPE === '1');

  const history = useHistory();

  const [successList, setSuccessList] = useState([]);
  const [errorsList, setErrorsList] = useState([]);

  const [openConfirmProcessModal, setOpenConfirmProcessModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  const IS_ERROR = errorsList?.length > 0;
  const IS_SUCCESS = successList?.length > 0;

  const { PROCESSED_INSURANCES_SUCCESS } = pusherStatus;
  const { INSURANCES_REGISTER_CONFIRMATION } = pusherChannels;

  useEffect(() => {
    const channel = pusher.subscribe(CURRENT_COMPANY_CODE);

    channel.bind(INSURANCES_REGISTER_CONFIRMATION, function(data) {
      const { status } = data;

      if (status === PROCESSED_INSURANCES_SUCCESS) {
        setIsProcessLoading(false);
        setOpenSuccessModal(true);
      }
    });
  }, []);

  function handleNext() {
    IS_ERROR && !IS_SUCCESS
      ? handleBack()
      : IS_ERROR && IS_SUCCESS
      ? setOpenConfirmProcessModal(true)
      : fileProcess();
  }

  function handleBack() {
    history.push('/insurance-management/batch-registration/menu/validate');
  }

  function fileProcess() {
    const body = {};

    setIsProcessLoading(true);

    api.post(`/import/insurance/${fileId}`, body);
  }

  function getResult() {
    setIsLoading(true);

    api
      .get(`/import/insurance/${fileId}`)
      .then(({ data }) => {
        const { success, errors } = data;

        setSuccessList(success);
        setErrorsList(errors);
      })
      .finally(() => setIsLoading(false));
  }

  function finishProcess() {
    history.push('/insurance-management');
  }

  function addNewInsured() {
    history.push('/insurance-management/batch-registration/menu');
  }

  return (
    <div>
      <Header
        title={
          IS_ERROR
            ? 'Indentificamos os seguintes problemas'
            : IS_EXCEL_FILE
            ? 'Confirme o cadastro via planilha do Excel'
            : 'Confirme o cadastro via arquivo ERP'
        }
        description={
          IS_ERROR && 'Por favor, corrija os erros baixo antes de continuar.'
        }
      />

      <InfoGrid
        hidden={IS_ERROR && !IS_SUCCESS}
        INSURANCE_IMPORT_DATA={INSURANCE_IMPORT_DATA}
        isLoading={isLoading}
      />

      <Accordion
        title={
          <Typography className={styles.accordionErrorHeading}>
            <ErrorRoundedIcon className={styles.accordionHeadingIcon} />
            {`${errorsList?.length} registros com problemas`}
          </Typography>
        }
        content={errorsList?.map(item => (
          <Typography className={styles.accordionErrorDetails}>
            Linha {item.line}
            <br />
            {item?.errors?.map(row => (
              <>
                <br />- {row}
                <br />
              </>
            ))}
            <br /> <br />
          </Typography>
        ))}
        hidden={!IS_ERROR || isLoading}
        style={IS_ERROR && !IS_SUCCESS ? { marginBottom: 70 } : {}}
      />

      <Accordion
        title={
          <Typography className={styles.accordionSuccessHeading}>
            <CheckCircleRoundedIcon className={styles.accordionHeadingIcon} />
            {successList?.length > 1
              ? `${successList?.length} segurados identificados com sucesso`
              : `${successList?.length} segurado identificado com sucesso`}
          </Typography>
        }
        content={
          <EmployeesTable
            successList={successList}
            getResult={getResult}
            insuranceType={INSURANCE_IMPORT_DATA?.insuranceType}
          />
        }
        style={{ marginBottom: 70 }}
        hidden={!IS_SUCCESS || isLoading}
      />

      <ConfirmProcessModal
        open={openConfirmProcessModal}
        title="Tem certeza que quer ignorar os erros?"
        text="Ao confirmar, apenas os segurados identificados corretamente serão cadastrados com sucesso."
        confirmButtonText="Sim, confirmar"
        cancelButtonText="Cancelar"
        onConfirmClicked={fileProcess}
        onCancelButtonClicked={() => setOpenConfirmProcessModal(false)}
        loading={isProcessLoading}
      />

      <SuccessModal
        open={openSuccessModal}
        title="Segurados adicionados!"
        text="Agora você pode editar a situação dos seus assegurados e adicionar outros."
        titleMarginTop={3}
        onCloseDialog={addNewInsured}
        onNextButtonClicked={finishProcess}
        nextButton
        nextButtonText="Continuar"
        closeButtonText="Adicionar outros"
      />

      <ProgressToolBar
        onBackClicked={handleBack}
        onNextClicked={handleNext}
        nextButtonText={
          IS_ERROR && !IS_SUCCESS
            ? 'Reenviar arquivo'
            : IS_SUCCESS && !IS_ERROR
            ? 'Confirmar'
            : 'Ignorar erros e confirmar'
        }
        backButtonText="Voltar"
        orangeNextButton={!IS_ERROR}
        disableBack={IS_ERROR}
        hidden={isLoading}
        loading={isProcessLoading && !IS_ERROR && IS_SUCCESS}
      />
    </div>
  );
}
