import React from 'react';

import { ReactComponent as AuthorizedIcon } from '../../../../assets/icon_table_how_to_reg.svg';
import { ReactComponent as ExpressPaymentIcon } from '../../../../assets/expressPaymentIcon.svg';
import { ReactComponent as PaymentIconExpress } from '../../../../assets/express-paid-out.svg';
import { ReactComponent as IncompletePaymentIcon } from '../../../../assets/uncompletePayment.svg';

import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import styles from './styles.module.css';

export default status => {

  const statusPayment = {
    'Verificação em processamento': <UpdateRoundedIcon className={styles.iconBlue} />,
    'Aguardando pagamento': <QueryBuilderRoundedIcon className={styles.iconBlue} />,
    'Transferência Confirmada': <CheckCircleOutlineRoundedIcon className={styles.iconGreen}/>,
    'Transferência não Confirmada': <SyncProblemRoundedIcon className={styles.iconOrange} />,
    'Aguardando confirmação': <ReportProblemRoundedIcon className={styles.iconOrange} />,
    'Pago': <CheckRoundedIcon className={styles.iconGreen} />,
    'Vencido': <HighlightOffRoundedIcon className={styles.iconRed} />,
    'Cancelado': <HighlightOffRoundedIcon className={styles.iconRed} />,
    'Verificação Desfeita': <HighlightOffRoundedIcon className={styles.iconRed} />,
    'Autorizada': <AuthorizedIcon />,
    'Autorizada Imediata': <ExpressPaymentIcon />,
    'Aguardando Autorização': <QueryBuilderRoundedIcon className={styles.iconYellow} />,
    'Saldo Insuficiente': <MoneyOffRoundedIcon className={styles.iconOrange} />,
    'Paga': <CheckRoundedIcon className={styles.iconGreen} />,
    'Paga Imediata': <PaymentIconExpress />,
    'Cancelada': <HighlightOffRoundedIcon className={styles.iconRed} />,
    'Expirada': <UpdateRoundedIcon className={styles.iconYellow} />,
    'Pagamento Incompleto': <IncompletePaymentIcon />,
    'Pagamento não Realizado': <AssignmentLateOutlinedIcon className={styles.iconOrange} />,
  }

  return statusPayment[status] ?? statusPayment[''];
};
