import React from 'react';

import {
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import styles from './styles.module.css';

function UsersAccordion({ usersList, handleClickOption, viewDetails }) {
  function renderChip(name) {
    switch (name) {
      case 'Ativo':
        return (
          <div className={styles.activeSituationChip}>
            <CheckCircleRoundedIcon className={styles.iconSituationChip} />
            Ativo
          </div>
        );
      case 'Pendente':
        return (
          <div className={styles.peddingSituationChip}>
            <InfoRoundedIcon className={styles.iconSituationChip} />
            Pendente
          </div>
        );
    }
  }

  return usersList.map(item => (
    <ExpansionPanel className={styles.expansionPanel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <div className={styles.expansionPanelSummary}>
          <Typography className={styles.accodionTitle}>{item.name}</Typography>
          <div>{renderChip(item.status)}</div>
        </div>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <Grid container direction="column">
          <Grid item>
            <Typography className={styles.informations}>
              {item.email}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.informations}>
              Data de criação: {item.createDate}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            justify="space-around"
            className={styles.groupButton}
          >
            <Grid item>
              <Button
                startIcon={<CreateIcon />}
                className={styles.button}
                onClick={() => viewDetails(item.cpf)}
              >
                Editar
              </Button>
            </Grid>

            <Grid item>
              <Button
                startIcon={<RefreshRoundedIcon />}
                className={styles.button}
                onClick={() => handleClickOption(1)}
              >
                Resetar senha
              </Button>
            </Grid>

            <Grid item>
              <Button
                startIcon={<DeleteForeverIcon />}
                className={styles.button}
                onClick={() => handleClickOption(2)}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
}

export default UsersAccordion;
