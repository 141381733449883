import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';

import styles from './styles.module.css';

export default function ResponsiveBottoMBar({
  onCancel,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  hidden,
  loading,
}) {
  return (
    <div className={hidden ? styles.hidden : styles.container}>
      <Button onClick={onCancel} className={styles.cancelButton}>
        {cancelButtonText}
      </Button>

      <Button onClick={onConfirm} className={styles.confirmButton}>
        {loading ? (
          <CircularProgress className={styles.circularProgress} />
        ) : (
          confirmButtonText
        )}
      </Button>
    </div>
  );
}
