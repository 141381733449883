import { alpha, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'grid',
      gap: theme.spacing(3),
      gridTemplate: '1fr / repeat(5, 2fr)',
      boxShadow: '0px 3.85px 7.7px 0px rgba(114, 114, 114, 0.1)',

      [theme.breakpoints.down('md')]: {
        gridTemplate: 'repeat(3, 2fr) / repeat(2, 1fr)',
      },

      '& > .MuiBox-root:last-of-type': {
        justifySelf: 'center',

        [theme.breakpoints.down('md')]: {
          justifySelf: 'end',
          gridRow: 1,
          gridColumn: '2 / -1',
        },
      },
    },

    infoContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'flex-start',
      overflow: 'hidden',
    },

    avatarContainer: {
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'hidden',

      '& span, & p': {
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },

    avatar: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderRadius: '6px',
    },

    label: {
      color: theme.palette.text.secondary,
      fontSize: '.75rem',
    },

    content: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: '.875rem',
    },
  }),
);
