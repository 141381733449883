import React from 'react';

import {
  BlockRounded,
  PauseCircleOutlineRounded,
  CheckCircleOutlineRounded,
  HighlightOffRounded,
} from '@material-ui/icons';

export const statusMap = {
  ACTIVE: {
    label: 'Ativo',
    color: 'success',
    icon: <CheckCircleOutlineRounded color="inherit" />,
  },
  PENDING: {
    label: 'Pendente',
    color: 'warning',
    icon: <PauseCircleOutlineRounded color="warning" />,
  },
  DELETED: {
    label: 'Deletado',
    color: 'error',
    icon: <HighlightOffRounded color="error" />,
  },
  BLOCKED: {
    label: 'Bloqueado',
    color: 'error',
    icon: <BlockRounded color="error" />,
  },
  WAITING_APPROVE: {
    label: 'Esperando aprovação',
    color: 'info',
    icon: <PauseCircleOutlineRounded color="info" />,
  },
  WAITING_CAF_RESPONSE: {
    label: 'Esperando resposta CAF',
    color: 'info',
    icon: <PauseCircleOutlineRounded color="info" />,
  },
};
