/* eslint-disable react/jsx-indent */
import React from 'react';
import { Box } from '@material-ui/core';
import { ErrorDataContainerAccordion } from './Components/ErrorDataContainerAccordion';
import CardTools from '../../../../../../../components/CardTools';
import formatCPF from '../../../../../../../utils/formatCPF';

export function ErrorCardList({
  isFileXls = true,
  errorsList = [],
  accordionsObject = {},
  handleEditEmployeeBankData = () => {},
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {isFileXls
        ? errorsList?.map((error, errorListIndex) => (
            <ErrorDataContainerAccordion
              IdentificationField={
                <>
                  <CardTools.CardInfo
                    title="CPF"
                    description={error?.cpf ? formatCPF(error?.cpf) : '-'}
                  />

                  <CardTools.CardInfo
                    title="Nome do funcionário"
                    description={
                      !error?.employeeName ? '-' : error?.employeeName
                    }
                  />
                </>
              }
              error={error}
              errorListIndex={errorListIndex}
              accordionsObject={accordionsObject}
              handleEditEmployeeBankData={handleEditEmployeeBankData}
            />
          ))
        : errorsList?.map((error, errorListIndex) => (
            <ErrorDataContainerAccordion
              IdentificationField={
                <CardTools.CardInfo
                  title={error?.cpf ? 'CPF' : 'Matrícula'}
                  description={
                    error?.cpf ? formatCPF(error?.cpf) : error?.registration
                  }
                />
              }
              error={error}
              errorListIndex={errorListIndex}
              accordionsObject={accordionsObject}
              handleEditEmployeeBankData={handleEditEmployeeBankData}
            />
          ))}
    </Box>
  );
}
