import React from 'react';
import { Table } from '../../../../components/Table';
import { useTableConfig } from './Hooks/useTableConfig';
import { usePaymentHistory } from './Hooks/usePaymentHistory';

export function PaymentHistory() {
  usePaymentHistory();

  const {
    data,
    length,
    config,
    isLoading,
    hasError,
    handleTablePagination,
    handleSelection,
  } = useTableConfig();

  return (
    <Table
      identifier="nsu"
      selectable
      data={data}
      length={length}
      tableConfig={config}
      isLoading={isLoading}
      hasError={hasError}
      requests={handleTablePagination}
      handleSelection={handleSelection}
    />
  );
}
