import React from 'react';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import style from './style.module.css';

export default ({ open, onCloseDialog }) => (
  <Dialog open={open} onClose={onCloseDialog} maxWidth="77vw">
    <Grid className={style.dialog}>
      <Grid>
        <Typography className={style.biggerText}>
          Autorizar este pagamento?
        </Typography>
        <Typography className={style.smallText}>
          Ao autorizar, o pagamento desta importação será processado.
        </Typography>
        <Typography className={style.smallText}>
          Importante: esta ação não poderá ser desfeita.
        </Typography>
      </Grid>
      <Grid className={style.modalFooter}>
        <Button className={style.cancelButton} onClick={onCloseDialog}>
          Cancelar
        </Button>
        <Button className={style.authorizeButton}>Autorizar pagamento</Button>
      </Grid>
    </Grid>
  </Dialog>
);
