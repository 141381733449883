import { api } from '../../../../../../services/api';

export const getFileDownload = async () => {
  const requestConfig = {
    headers: {
      branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      accept: 'application/xls',
    },
    responseType: 'blob',
  };

  return api.get('/consigned/employee/file/download/model', requestConfig);
};

export const uploadEmployeeMarginsFile = async formData => {
  return api.post('/consigned/employee/file/import', formData, {
    headers: {
      ...api.defaults.headers,
      companyCode: sessionStorage.getItem('currentCompanyCode'),
    },
  });
};
