import React from 'react';

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
  Typography,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import styles from '../styles.module.css';

import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';
import formatCEP from '../../../../utils/formatCEP';

import { states } from './mocks';

export default function AddressForm({
  disabled,
  addressDataForm,
  setAddressDataForm,
  loading
}) {
  function handleChangeAddressDataForm(event, index, name, value) {
    let formFields = addressDataForm;

    if (name === 'state') {
      formFields[index][name] = value;
    } else {
      formFields[index][name] = event.target.value;
    }
    
    setAddressDataForm([...formFields]);
  }

  function handleAddNewAddress() {
    let formFields = addressDataForm;

    if (formFields.length >= 3) {
      return;
    }

    formFields.push({
      type: '',
      street: '',
      number: '',
      complement: '',
      zipCode: '',
      neighborhood: '',
      city: '',
      state: ''
    });

    setAddressDataForm([...formFields]);
  }

  function handleRemoveAddress() {
    let formFields = addressDataForm;

    formFields.pop();

    setAddressDataForm([...formFields]);
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <form className={styles.form}>
      {addressDataForm?.map((item, index) => (
        <Grid container spacing={3} className={styles.formGroup}>
          {index !== 0 && <Typography className={styles.formGroupTitle}>Endereço adicional</Typography>}

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <FormControl variant="outlined" className={styles.filter} fullWidth size="small">
              <InputLabel id="select-outlined-label-type">Tipo de endereço</InputLabel>
              <Select
                labelId="select-outlined-label-type"
                value={item.type}
                onChange={(event) => {
                  handleChangeAddressDataForm(event, index, "type")
                }}
                label="Tipo de endereço"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
                disabled={disabled}
              >
                <MenuItem value='Residencial'>Residencial</MenuItem>
                <MenuItem value='Cobrança'>Cobrança</MenuItem>
                <MenuItem value='Comercial'>Comercial</MenuItem>
                <MenuItem value='Correspondência'>Correspondência</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <TextField
              label="CEP"
              size="small"
              variant="outlined"
              fullWidth
              disabled={disabled}
              value={formatCEP(item.zipCode || '')}
              onChange={(event) => {
                handleChangeAddressDataForm(event, index, "zipCode")
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Rua"
              size="small"
              variant="outlined"
              fullWidth
              disabled={disabled}
              value={item.street}
              onChange={(event) => {
                handleChangeAddressDataForm(event, index, "street")
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Número"
              size="small"
              variant="outlined"
              fullWidth
              disabled={disabled}
              value={formatOnlyNumbers(item.number)}
              onChange={(event) => {
                handleChangeAddressDataForm(event, index, "number")
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              label="Bairro"
              size="small"
              variant="outlined"
              fullWidth
              disabled={disabled}
              value={item.neighborhood}
              onChange={(event) => {
                handleChangeAddressDataForm(event, index, "neighborhood")
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Complemento"
              size="small"
              variant="outlined"
              fullWidth
              disabled={disabled}
              value={item.complement}
              onChange={(event) => {
                handleChangeAddressDataForm(event, index, "complement")
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Cidade"
              size="small"
              variant="outlined"
              fullWidth
              disabled={disabled}
              value={item.city}
              onChange={(event) => {
                handleChangeAddressDataForm(event, index, "city")
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl variant="outlined" className={styles.filter} fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={states}
                getOptionLabel={(option) => option}
                onChange={(event, value) => {
                  handleChangeAddressDataForm(event, index, "state", value)
                }}
                disabled={disabled}
                value={item.state}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Estado" 
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12} sm={12} md={12} lg={12}>
        {addressDataForm?.length > 1 && (
          <Button 
            className={styles.buttonRemoveAddress} 
            disabled={disabled}
            onClick={handleRemoveAddress}
          >
            Remover
          </Button>
        )}

        <Button 
          className={styles.buttonAddNew} 
          disabled={disabled}
          onClick={handleAddNewAddress}
        >
          Adicionar outro endereço
        </Button>
      </Grid>
    </form>
  )
}
