import moment from 'moment';
import currencyFormatter from '../../../../utils/currencyFormatter';

export default function usePayrollDetailsUtils() {
  const formatNumber = rawNumber =>
    '****'?.concat(rawNumber?.slice(rawNumber?.length - 4));

  const today = moment().format('DD/MM/YYYY');

  const dateValidation = date =>
    date ? moment(date).format('DD/MM/YYYY') : '(Em branco)';

  const timeValidation = time =>
    time ? moment(time).format('HH:mm') : '(Em branco)';

  const formatTotalValue = (value, allowZero = false) => {
    const realValue = allowZero ? value || 0 : value;

    return realValue?.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  const formatAmount = amount =>
    amount ? currencyFormatter(amount) : 'R$ 0,00';

  return {
    formatNumber,
    formatTotalValue,
    formatAmount,
    today,
    dateValidation,
    timeValidation,
  };
}
