import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import styles from './styles.module.css';

export default function BottomBarActionsTable({
  selected,
  cancelText = 'Cancelar',
  onCancelClick,
  confirmText,
  onConfirmClick,
  multipleRowsSelected,
}) {
  return (
    <div className={selected?.length === 0 ? styles.hidden : styles.container}>
      <div className={styles.selecteds}>
        <Typography className={styles.count}>{selected?.length}</Typography>
        <Typography className={styles.countLabel}>
          {multipleRowsSelected ? 'Selecionados' : 'Selecionado'}
        </Typography>
      </div>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          className={styles.cancelButton}
          onClick={onCancelClick}
        >
          {cancelText}
        </Button>
        <Button className={styles.button} onClick={onConfirmClick}>
          {multipleRowsSelected ? `${confirmText}s` : confirmText}
        </Button>
      </Box>
    </div>
  );
}
