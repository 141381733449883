/* eslint-disable react/jsx-indent */

import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Typography,
} from '@material-ui/core';
import {
  AttachFileRounded,
  DescriptionRounded,
  ErrorOutlineRounded,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { Header } from '../../../components/Header';
import PayrollFilePreview from './components/FilePreview';
import BottomBar from '../../../components/BottomBar';
import Buttons from '../../../components/Buttons';
import MultiplePayrollFileModals from './components/Modals';
import { ExpandButton } from './components/ExpandButton';

import { isMobile } from '../../../utils/breakpoints';
import useMultipleFilesUpload from './hooks/useMultipleFilesUpload';

import { useStyles } from './styles';

export default function MultipleFilesUploadScreen() {
  const {
    disabled,
    progress,
    getRootProps,
    getInputProps,
    isDragActive,
    isProcessing,
    isFinished,
    files,
    onDeleteAllFiles,
    onDeleteFile,
    toggleFileList,
    showList,
    onNext,
    nsuListLength,
  } = useMultipleFilesUpload();

  const styles = useStyles({ progress });

  const renderLength = () => {
    const hasOnlyOneFile = files.length === 1;

    if (!isProcessing)
      return (
        <Typography variant="body2" className={styles.length}>
          {`${files.length} ${hasOnlyOneFile ? 'arquivo' : 'arquivos'}`}
        </Typography>
      );

    return (
      <Box className={styles.progressContainer}>
        <Typography variant="body2" className={styles.length}>
          Processando arquivos
        </Typography>
        <Typography variant="body2" className={styles.percentage}>
          {`${nsuListLength} de ${files.length} ${
            hasOnlyOneFile ? 'arquivo' : 'arquivos'
          } `}{' '}
          &#8226; {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    );
  };

  const renderDropzone = () => {
    return files.length === 0 ? (
      <Box
        className={clsx(
          `${styles.dropzoneBase} ${
            isDragActive ? styles.dropzoneActive : styles.dropzoneIdle
          }`,
        )}
      >
        <Avatar variant="rounded" className={styles.avatar}>
          <AttachFileRounded color="primary" />
        </Avatar>
        <Typography variant="label">
          {isMobile ? (
            <span>
              <b className={styles.clickText}>Toque</b> para selecionar arquivos
            </span>
          ) : (
            <span>
              Arraste e solte seus arquivos aqui, ou{' '}
              <b className={styles.clickText}>clique para selecionar</b>
            </span>
          )}
        </Typography>
      </Box>
    ) : (
      <Box
        className={clsx(
          styles.dropzoneBase,
          styles.dropzoneIdle,
          styles.dropzoneData,
          styles.dropzoneBackground,
        )}
      >
        <Box className={styles.lengthContainer}>
          <Avatar
            variant="rounded"
            className={clsx(styles.avatar, styles.largeAvatar)}
          >
            {isProcessing ? (
              <CircularProgress color="primary" />
            ) : (
              <DescriptionRounded
                color="primary"
                className={styles.largeIcon}
              />
            )}
          </Avatar>
          {renderLength()}
        </Box>
        <Buttons.SecondaryButton
          variant="outlined"
          color="primary"
          title="REMOVER TODOS"
          onClick={onDeleteAllFiles}
        />
      </Box>
    );
  };

  return (
    <>
      <MultiplePayrollFileModals />
      <Header title="Novo Pagamento de vários arquivos" />
      <Alert
        severity="warning"
        className={styles.alert}
        icon={<ErrorOutlineRounded />}
      >
        Certifique-se de enviar os arquivos no layout CNAB 240.
      </Alert>
      <Box className={styles.root}>
        <Typography variant="h2" className={styles.title}>
          Envie os arquivos preenchidos
        </Typography>
        <Box component="div" {...getRootProps()}>
          <input aria-labelledby="dropzone-label" {...getInputProps()} />
          {renderDropzone()}
        </Box>

        {files.length > 0 ? (
          <ExpandButton
            title="Ver arquivos"
            expanded={showList}
            onClick={toggleFileList}
            className={styles.viewButton}
          />
        ) : (
          <Typography
            className={styles.label}
            id="dropzone-label"
            variant="label"
            component="p"
          >
            Formato suportado: TXT
          </Typography>
        )}

        <Collapse in={showList}>
          <Box className={styles.previewGrid}>
            {files.map(file => (
              <PayrollFilePreview
                onDelete={onDeleteFile}
                deletable={isProcessing}
                {...file}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
      <BottomBar>
        <Buttons.PrimaryButton
          color="primary"
          title="Continuar"
          onClick={onNext}
          disabled={disabled || isFinished}
          loading={isProcessing}
        />
      </BottomBar>
    </>
  );
}
