import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

function FakePaymentsChartDashboard() {
  return (
    <div className={styles.container}>
      <Skeleton
        color="#202020"
        highlightColor="#444"
        className={styles.skeletonOne}
      />

      <Skeleton
        color="#202020"
        highlightColor="#444"
        className={styles.skeletonTwo}
      />
    </div>
  );
}

export default FakePaymentsChartDashboard;
