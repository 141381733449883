import React from 'react';
import Carousel from 'nuka-carousel';
import { useHistory } from 'react-router-dom';

import Banner1 from '../../../../assets/banner_1.jpg';

import styles from './styles.module.css';
import { isMobile } from '../../../../utils/breakpoints';

function Slider() {
  const history = useHistory();

  const banners = [
    {
      alt: 'Banner Seguro Estagiário',
      src: Banner1,
      link:
        'https://wingseguros.com.br/fortespay/?utm_source=wing&utm_medium=parceria&utm_campaign=interno&utm_term=fortes',
    },
  ];

  function handleClickBanner(url, externalLink) {
    if (externalLink) {
      window.open(url, '_blank');
      return;
    }

    history.push(url);
  }

  return (
    <div className={styles.sliderContainer}>
      <div>
        <Carousel
          className={styles.carousel}
          navButtonsAlwaysVisible
          // animation="fade"
          wrapAround
          // autoplay
          autoplayInterval={10000}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: '#A0001F',
              outline: 'none',
              position: 'relative',
              top: isMobile ? 35 : 40,
            },
            prevButtonStyle: {
              display: 'none',
            },
            nextButtonStyle: {
              display: 'none',
            },
          }}
        >
          {banners?.map(({ alt, link, src }) => (
            <img
              key={alt}
              onClick={() => handleClickBanner(link, true)}
              src={src}
              alt={alt}
              className={styles.bannerClick}
              onKeyDown={() => handleClickBanner(link, true)}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Slider;
