/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { useCardList } from './Hooks/useCardList';
import { CardContainer } from './Components/CardContainer';
import { useInfiniteScroll } from '../../../../../../hooks/useInfiniteScroll';

export function Render({
  data = [],
  config = [],
  hasMore = false,
  onFetchMoreCards = () => {},
}) {
  const { cards } = useCardList({ data, config });
  const observerTarget = useInfiniteScroll({
    handleFetchData: () => {
      onFetchMoreCards();
    },
    hasMore,
  });

  return (
    <Box
      style={{
        width: '100%',
        borderRadius: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {cards?.map(cardData => (
            <CardContainer cardData={cardData} />
          ))}
          <Paper ref={observerTarget} />
        </Box>
      </Box>
    </Box>
  );
}
