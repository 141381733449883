import { useRef } from 'react';

export function useCurrencyField(value = 0, onChange = () => {}) {
  const currencyStringValue = useRef(
    `R$ ${value?.toFixed(2).replace('.', ',') || '0,00'}`,
  );

  function handleFormatToFloat(value) {
    const a = String(value)
      .replace(/\D/g, '')
      .split('');

    a.splice(a.length - 2, 0, '.');

    return Number(a.join(''));
  }

  function handleFinalValue(value) {
    const formattedValue = handleFormatToFloat(value);

    onChange(isNaN(formattedValue) ? 0 : formattedValue);

    currencyStringValue.current =
      isNaN(formattedValue) || formattedValue === 0
        ? 'R$ 0,00'
        : new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(formattedValue);
  }

  return {
    currencyStringValue: currencyStringValue.current,
    setCurrencyStringValue: handleFinalValue,
  };
}
