import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

export const useStyles = makeStyles(_ =>
  createStyles(
    {
      dialog: {
        borderRadius: '50px',
      },
      rootContainer: {
        height: '60dvh',
        display: 'flex',
        flexFlow: 'row nowrap',
        overflow: 'hidden',
      },
      textContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '2rem',
        borderRadius: '0px 50px 50px 50px',
        background:
          'linear-gradient(180deg, rgba(82, 25, 127, 0.32) -66.19%, rgba(213, 153, 255, 0.08) 76.63%, rgba(252, 249, 255, 0.08) 100%)',
      },

      title: {
        fontSize: '1.5rem',
        fontWeight: 700,
        marginBottom: '.5rem',
        lineHeight: '1.75rem',
      },
      span: {
        color: 'rgb(82, 25, 127)',
      },
      subtitle: {
        fontSize: '1.75rem',
        fontWeight: 700,
        marginBottom: '.5rem',
        lineHeight: '1.75rem',
      },
      image: {
        alignSelf: 'self-end',
      },
      action: {
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '1rem',
      },
      buttonsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        gap: '0.5rem',
      },
      fields: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem',
      },
      form: {
        padding: '2rem',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        gap: '1rem',
      },
      reasonText: {
        color: '#52197F',
        fontWeight: 500,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    ptBR,
  ),
);
