import React, { useMemo } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { TemporaryDrawer } from '../../../../../../../components/DrawerV2';
import textCapitalize from '../../../../../../../utils/textCapitalize';
import currencyFormatter from '../../../../../../../utils/currencyFormatter';
import formatCPF from '../../../../../../../utils/formatCPF';
import convertBrazilianDate from '../../../../../../../utils/convertBrazilianDate';
import styles from './styles.module.css';
import { useGetConsignedMargin } from '../../../utils/hooks';

const InfoModal = ({ open, onClose, selected }) => {
  const { data, isError } = useGetConsignedMargin(
    open && selected && selected.id,
  );

  const usedAmounts = useMemo(() => {
    if (!data) return {};

    const {
      assignableMargin,
      payrollLoanLimit,
      usedAssignableMargin,
      usedPayrollLoanLimit,
    } = data;

    return {
      margin: {
        available:
          assignableMargin === 0 ? 0 : assignableMargin - usedAssignableMargin,
        percentage: (usedAssignableMargin * 100) / assignableMargin,
      },
      limit: {
        available:
          payrollLoanLimit === 0 ? 0 : payrollLoanLimit - usedPayrollLoanLimit,
        percentage: (usedPayrollLoanLimit * 100) / payrollLoanLimit,
      },
    };
  }, [data]);

  return (
    <TemporaryDrawer
      open={open}
      onClose={onClose}
      title="Detalhes do colaborador"
      drawerWidth={600}
      hasBgHeader={false}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            p: '1rem',
            borderRadius: '0.5rem',
            border: 'solid 1px var(--backGroundFormField)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid container spacing={1} className={styles['employee-card']}>
              <Grid item>
                <Avatar className={styles.avatar}>
                  {!!selected &&
                    (selected.name ? selected.name.split('')[0] : '-')}
                </Avatar>
              </Grid>

              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={styles['employee-name']}>
                      {!!selected &&
                        (selected.name ? textCapitalize(selected.name) : '-')}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      className={[
                        styles['inline-text'],
                        styles['document-label'],
                      ].join(' ')}
                    >
                      CPF:
                      <Typography>
                        {!!selected && formatCPF(selected.cpf)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="text"
              startIcon={<Visibility />}
              className={styles['employee-button']}
            >
              <Typography display="inline">Visualizar colaborador</Typography>
            </Button>
          </Box>
          <Box
            sx={{
              height: '1px',
              bgcolor: 'var(--backGroundFormField)',
            }}
          />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography className={styles['resume-label']}>
                Salário
              </Typography>
              <Typography className={styles['resume-value']}>
                {!!selected && currencyFormatter(selected.salary)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles['resume-label']}>
                Data de admissão
              </Typography>
              <Typography className={styles['resume-value']}>
                {!data
                  ? isError
                    ? 'ERROR'
                    : 'Carregando...'
                  : convertBrazilianDate(data.admissionDate)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            p: '1rem',
            borderRadius: '0.5rem',
            border: 'solid 1px var(--backGroundFormField)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            <Typography className={styles['section-title']}>
              Empréstimo Consignado
            </Typography>
            <Chip
              label={selected ? selected.marginSituationDescription : '-'}
              className={styles['chip-status']}
              data-status={selected && selected.marginSituation}
            />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={styles['progress-title']}>
                Margem consignável
              </Typography>
              <Typography
                className={[
                  styles['inline-text'],
                  styles['progress-description'],
                ].join(' ')}
              >
                <Typography>
                  {!data
                    ? isError
                      ? 'ERROR'
                      : 'Calculando...'
                    : currencyFormatter(data.usedAssignableMargin)}
                </Typography>{' '}
                / {!!selected && currencyFormatter(selected.assignableMargin)}
              </Typography>
            </Box>
            <LinearProgress
              className={styles['progress-bar']}
              variant="determinate"
              value={!data ? 0 : usedAmounts.margin.percentage}
            />
            <Typography
              className={[
                styles['inline-text'],
                styles['progress-available'],
              ].join(' ')}
            >
              <Typography>Disponível:</Typography>
              {!data
                ? isError
                  ? 'ERROR'
                  : 'Calculando...'
                : currencyFormatter(usedAmounts.margin.available)}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={styles['progress-title']}>
                Limite
              </Typography>
              <Typography
                className={[
                  styles['inline-text'],
                  styles['progress-description'],
                ].join(' ')}
              >
                <Typography>
                  {!data
                    ? isError
                      ? 'ERROR'
                      : 'Calculando...'
                    : currencyFormatter(data.usedPayrollLoanLimit)}
                </Typography>{' '}
                / {!!selected && currencyFormatter(selected.payrollLoanLimit)}
              </Typography>
            </Box>
            <LinearProgress
              className={styles['progress-bar']}
              variant="determinate"
              value={!data ? 0 : usedAmounts.limit.percentage}
            />
            <Typography
              className={[
                styles['inline-text'],
                styles['progress-available'],
              ].join(' ')}
            >
              <Typography>Disponível:</Typography>{' '}
              {!data
                ? isError
                  ? 'ERROR'
                  : 'Calculando...'
                : currencyFormatter(usedAmounts.limit.available)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 16 }}>
            <Box sx={{ display: 'flex', gap: 6, alignItems: 'center' }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: 12,
                  bgcolor: '#52197F',
                }}
              />
              <Typography className={styles['progress-label']}>
                Utilizado
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 6, alignItems: 'center' }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: 12,
                  bgcolor: 'rgb(189, 167, 206)',
                }}
              />
              <Typography className={styles['progress-label']}>
                Total
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </TemporaryDrawer>
  );
};

export default InfoModal;
