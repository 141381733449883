import React, { useEffect, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Typography,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Menu,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GeneralInfo from './GeneralInfo';
import Table from './Table';
import FakeGeneralInfo from './FakeGeneralInfo';
import FakeTable from './FakeTable';
import LogoLoading from '../../../../components/LogoLoading';
import Error from './Error';
import HandleEmployeeLimitModal from './HandleEmployeeLimitModal';
import Snackbar from '../../../../components/Snackbar';
import convertBytesToBlobDowloadXls from '../../../../utils/convertBytesToBlobDowloadXls';
import PercentageCircularProgress from '../../../../components/PercentageCircularProgress';
import PrimaryModal from '../../../../components/PrimaryModal';

import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';

import { api } from '../../../../services/api';
import { pusherChannels } from '../../../../enums/pusher';
import { pusher } from '../../../../utils/pusher';

import styles from './styles.module.css';

export default function ConsultLimit() {
  const history = useHistory();
  const {
    CONSULT_LIMIT_REPORT_PROCESSING,
    CONSULT_LIMIT_REPORT_FINISHED,
    PROCESSED_EMPLOYEES_LIMITS,
  } = pusherChannels;
  const exampleSheet =
    'https://somapay-companies.s3.amazonaws.com/import-file-type/Planilha%2Bde%2Bcadastro%2Blimite%2BSomapay.xlsx';

  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const [companyLimits, setCompanyLimits] = useState({});
  const [employeesLimits, setEmployeesLimits] = useState([]);
  const [status, setStatus] = useState('Todos');
  const [limitType, setLimitType] = useState('Todos');
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [companyDataLoaded, setCompanyDataLoaded] = useState(false);
  const [companyDataLoading, setCompanyDataLoading] = useState(false);
  const [employeesListLoaded, setEmployeesListLoaded] = useState(false);
  const [employeesListLoading, setEmployeesListLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [employeesStatus, setEmployeesStatus] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [handleNewLimitModal, setHandleNewLimitModal] = useState(false);
  const [newLimitValue, setNewLimitValue] = useState('');
  const [cpfOrNameValue, setCpfOrNameValue] = useState('');
  const [saveNewLimitButtonLoading, setSaveNewLimitButtonLoading] = useState(
    false,
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});
  const [actionsButtonAnchor, setActionsButtonAnchor] = useState('');
  const [actionButtonLoading, setActionButtonLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [
    handleFiredEmployeeLimitModal,
    setHandleFiredEmployeeLimitModal,
  ] = useState(false);

  const goToMenuScreen = () => {
    history.push('/limit-query/menu');
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleNewLimitInput = ({ target }) => {
    setNewLimitValue(formatOnlyNumbers(target.value));
  };

  const handleCpfOrNameInput = ({ target }) => {
    setCpfOrNameValue(target.value);
  };

  const sort = tableHeadId => {
    if (orderBy === tableHeadId) {
      setOrder(prevState => (prevState === 'asc' ? 'desc' : 'asc'));
    }
    setOrderBy(tableHeadId);
  };

  const handleChangeStatus = ({ target }) => {
    setStatus(target.value);
  };

  const handleChangeLimitType = ({ target }) => {
    setLimitType(target.value);
  };

  const handleActionsMenu = event => {
    setActionsButtonAnchor(event.currentTarget);
  };

  const editEmployeeLimit = employeeData => {
    if (employeeData.status === 'Demitido' && employeeData.actualLimit === 0) {
      setOpenSnackbar(true);
      setSnackbarData({
        severity: 'error',
        description: 'Não pode editar o limite de um funcionário demitido.',
      });
    } else if (
      employeeData.status === 'Demitido' &&
      employeeData.actualLimit > 0
    ) {
      setHandleFiredEmployeeLimitModal(true);
      setNewLimitValue(0);
      setSelectedEmployee({
        cpf: employeeData.cpf,
      });
    } else {
      setOpenSnackbar(false);
      setSnackbarData({});
      setSelectedEmployee({
        status: employeeData.status,
        name: employeeData.name,
        cpf: employeeData.cpf,
        actualLimit: employeeData.actualLimit,
      });
      setNewLimitValue('');
      setHandleNewLimitModal(true);
    }
  };

  const getCompanyLimits = () => {
    setCompanyDataLoading(true);
    const requestOptions = {
      params: {
        situation: status === 'Todos' ? '' : status,
        cpfOrName: cpfOrNameValue,
        withLimit: limitType === 'Todos' ? '' : limitType,
      },
    };
    api
      .get(
        `/anticipation/salary/consultCompanyLimits/${companyCode}`,
        requestOptions,
      )
      .then(({ data }) => {
        setError(false);
        setCompanyLimits(data);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0].errorReason === 'EMPLOYEES_NOT_FOUND') {
            setErrorData({
              type: 'EMPLOYEES_NOT_FOUND',
              title: 'Nada encontrado',
              text: '',
            });
            setError(true);
            return;
          }
          setGeneralError(true);
          setCompanyDataLoaded(true);
        },
      )
      .finally(() => {
        if (!companyDataLoaded) {
          setCompanyDataLoaded(true);
        }
        setCompanyDataLoading(false);
      });
  };

  const getEmployeesLimits = () => {
    setEmployeesListLoading(true);
    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize,
        situation: status === 'Todos' ? '' : status,
        withLimit: limitType === 'Todos' ? '' : limitType,
        sort: `${orderBy},${order}`,
        cpfOrName: cpfOrNameValue,
      },
    };
    api
      .get(
        `/anticipation/salary/consultEmployeeLimits/${companyCode}`,
        requestOptions,
      )
      .then(({ data }) => {
        setError(false);
        setEmployeesLimits(data.content);
        setCount(data.totalSize);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0].errorReason === 'EMPLOYEES_NOT_FOUND') {
            setErrorData({
              type: 'EMPLOYEES_NOT_FOUND',
              title: 'Nada encontrado',
              text: '',
            });
            setError(true);
            return;
          }
          setGeneralError(true);
        },
      )
      .finally(() => {
        if (employeesListLoaded !== true) {
          setEmployeesListLoaded(true);
        }
        setEmployeesListLoading(false);
      });
  };

  const getEmployeesStatus = () => {
    api
      .get('employees/status')
      .then(({ data }) => {
        setEmployeesStatus(data);
      })
      .catch(generalError => {
        setGeneralError(true);
      });
  };

  const changeEmployeeLimit = () => {
    setSaveNewLimitButtonLoading(true);
    api
      .put(
        `anticipation/limit/${companyCode}/${
          selectedEmployee.cpf
        }/${newLimitValue / 100}`,
      )
      .then(() => {
        setOpenSnackbar(true);
        setSnackbarData({
          severity: 'success',
          description: 'Limite alterado com sucesso!',
        });
        getCompanyLimits();
        getEmployeesStatus();
        getEmployeesLimits();
        setNewLimitValue('');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0].errorReason === 'INVALID_PARAMETER') {
            setOpenSnackbar(true);
            setSnackbarData({
              severity: 'error',
              description: `${errors[0].errorDetail}`,
            });
            return;
          }
          setOpenSnackbar(true);
          setSnackbarData({
            severity: 'error',
            description:
              'Houve um erro! Por favor, tente novamente mais tarde.',
          });
        },
      )
      .finally(() => {
        setSaveNewLimitButtonLoading(false);
        setHandleNewLimitModal(false);
        setHandleFiredEmployeeLimitModal(false);
      });
  };

  const handleDownloadModelSheet = () => {
    window.open(exampleSheet);
  };

  const handleDownloadExtract = () => {
    setActionButtonLoading(true);
    setActionsButtonAnchor('');
    const requestOptionsFirstRoute = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      params: {
        cpfOrName: cpfOrNameValue,
        situation: status === 'Todos' ? '' : status,
        withLimit: limitType === 'Todos' ? '' : limitType,
      },
      responseType: 'blob',
    };
    const requestOptionsSecondRoute = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      responseType: 'blob',
    };

    const sendRequest = new Promise((resolve, reject) => {
      api
        .get(
          `anticipation/asyncReport/consultLimit/${companyCode}`,
          requestOptionsFirstRoute,
        )
        .then(({ data }) => {
          resolve(data);
        });
    });

    const processingRequest = new Promise((resolve, reject) => {
      sendRequest.then(() => {
        const channel = pusher.subscribe(companyCode);
        channel.bind(CONSULT_LIMIT_REPORT_PROCESSING, async function(data) {
          setPercentage(data?.percentage);
        });
        channel.bind(CONSULT_LIMIT_REPORT_FINISHED, async function(data) {
          setPercentage(100);
          resolve(data);
        });
      });
    });

    const doneRequest = new Promise((resolve, reject) => {
      processingRequest.then(data => {
        api
          .get(
            `/anticipation/report/byId/${data?.data?.reportId}`,
            requestOptionsSecondRoute,
          )
          .then(({ data }) => {
            resolve(data);
          });
      });
    });

    Promise.all([sendRequest, processingRequest, doneRequest]).then(result => {
      convertBytesToBlobDowloadXls(result[2], 'Funcionários');
      setActionButtonLoading(false);
      setPercentage(0);
    });
  };

  useEffect(() => {
    getCompanyLimits();
    getEmployeesStatus();
  }, [status, cpfOrNameValue, limitType]);

  useEffect(() => {
    getEmployeesLimits();
  }, [
    currentPage,
    pageSize,
    status,
    order,
    orderBy,
    cpfOrNameValue,
    limitType,
  ]);

  useEffect(() => {
    if (!companyDataLoaded || !employeesListLoaded) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [companyDataLoaded, employeesListLoaded]);

  return (
    <div>
      <div className={styles.actionBarContainer}>
        <Typography className={styles.pageTitle}>Consulta de limite</Typography>
        <div>
          <Button
            variant="contained"
            className={styles.downloadTicket}
            startIcon={actionButtonLoading ? null : <DescriptionOutlinedIcon />}
            onClick={handleActionsMenu}
          >
            {actionButtonLoading ? (
              <PercentageCircularProgress value={percentage} />
            ) : (
              <Typography>Ações</Typography>
            )}
          </Button>
          <Menu
            id="simple-menu"
            keepMounted
            anchorEl={actionsButtonAnchor}
            open={Boolean(actionsButtonAnchor)}
            onClose={() => setActionsButtonAnchor('')}
            className={styles.menu}
          >
            <MenuItem onClick={handleDownloadExtract}>
              Baixar planilha de funcionários
            </MenuItem>
            <MenuItem onClick={goToMenuScreen}>Importar arquivo</MenuItem>
            {/* Remover comentário apenas quando a funcionalidade estiver pronta. */}
            {/* <MenuItem onClick={handleUploadFileModal}>
              Importar arquivo
            </MenuItem> */}
          </Menu>
        </div>
      </div>
      {pageLoading ? (
        <LogoLoading />
      ) : (
        <div>
          {generalError ? (
            <Error />
          ) : (
            <div>
              {companyDataLoading ? (
                <FakeGeneralInfo status={status} />
              ) : error ? (
                <GeneralInfo
                  limitGranted={0}
                  usedLimit={0}
                  totalEmployees={0}
                  employeesWithLimit={0}
                  employeesWithoutLimit={0}
                  minLimitValue={0}
                  mediumLimitValue={0}
                  maxLimitValue={0}
                  status="Todos"
                />
              ) : (
                <GeneralInfo
                  limitGranted={companyLimits?.totalLimitGranted}
                  usedLimit={companyLimits?.totalLimitUsed}
                  totalEmployees={companyLimits?.numberOfEmployees}
                  employeesWithLimit={companyLimits?.numberOfEmployeesWithLimit}
                  employeesWithoutLimit={
                    companyLimits?.numberOfEmployeesWithoutLimit
                  }
                  minLimitValue={companyLimits?.minimumLimitValue}
                  mediumLimitValue={companyLimits?.averageLimitValue}
                  maxLimitValue={companyLimits?.maximumLimitValue}
                  status={status}
                />
              )}
              <div className={styles.filtersContainer}>
                <TextField
                  className={styles.searchByCpfTextField}
                  onChange={handleCpfOrNameInput}
                  value={cpfOrNameValue || ''}
                  placeholder="Digite o nome ou CPF"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={styles.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl variant="outlined" className={styles.formControl}>
                  <InputLabel id="select-situation">Situação</InputLabel>
                  <Select
                    labelId="select-situation"
                    id="select-outlined"
                    value={status}
                    onChange={handleChangeStatus}
                    label="Situação"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="Todos">Todos</MenuItem>
                    {employeesStatus.map(item => (
                      <MenuItem value={item.code} key={item.code}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={styles.formControl}>
                  <InputLabel id="select-limit-type">Tipo de limite</InputLabel>
                  <Select
                    labelId="select-limit-type"
                    id="select-outlined"
                    value={limitType}
                    onChange={handleChangeLimitType}
                    label="Tipo de limite"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="Todos">Todos</MenuItem>
                    <MenuItem value>Com limite</MenuItem>
                    <MenuItem value={false}>Sem limite</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {employeesListLoading ? (
                <FakeTable
                  count={count}
                  pageSize={pageSize}
                  currentPage={currentPage}
                />
              ) : error ? (
                <Error type={errorData.type} title={errorData.title} text />
              ) : (
                <Table
                  bodyData={employeesLimits}
                  count={count}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  sort={sort}
                  order={order}
                  orderBy={orderBy}
                  editEmployeeLimit={editEmployeeLimit}
                />
              )}
              <HandleEmployeeLimitModal
                open={handleNewLimitModal}
                onClose={() => setHandleNewLimitModal(false)}
                loading={saveNewLimitButtonLoading}
                handleNewLimitInput={handleNewLimitInput}
                newLimitValue={newLimitValue}
                onCancelButtonClicked={() => setHandleNewLimitModal(false)}
                onConfirmButtonClicked={changeEmployeeLimit}
                employeeName={selectedEmployee.name}
                employeeCpf={selectedEmployee.cpf}
                actualEmployeeLimit={selectedEmployee.actualLimit}
              />
              <PrimaryModal
                open={handleFiredEmployeeLimitModal}
                title="Atenção!"
                text="O funcionário demitido possui limite. Você deseja zerá-lo?"
                confirmButtonText="confirmar"
                cancelButtonText="cancelar"
                onConfirmClicked={changeEmployeeLimit}
                onCancelButtonClicked={() =>
                  setHandleFiredEmployeeLimitModal(false)
                }
                onCloseDialog={() => setHandleFiredEmployeeLimitModal(false)}
                loading={saveNewLimitButtonLoading}
              />
            </div>
          )}
        </div>
      )}
      <Snackbar
        open={openSnackbar}
        severity={snackbarData.severity}
        message={snackbarData.description}
        close={() => setOpenSnackbar(false)}
      />
    </div>
  );
}
