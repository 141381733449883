import React, { useCallback } from 'react';
import { Button, Box, Typography, Dialog } from '@material-ui/core';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styles from './styles.module.css';

const ErrorModal = NiceModal.create(({ titleProcess, statusDescription }) => {
  const modal = useModal();

  const handleClose = useCallback(() => {
    modal.resolve();
    modal.hide();
  }, []);

  return (
    <Dialog open={modal.visible} onClose={handleClose} fullWidth maxWidth="sm">
      <div className={styles.container}>
        <Box mt={0}>
          <Typography className={styles.title}>{titleProcess}</Typography>
          <Typography className={styles.subtitle}>
            {statusDescription}
          </Typography>
        </Box>

        <div className={styles.buttonsGroup}>
          <Button onClick={handleClose} className={styles.nextButton}>
            <Typography className={styles.confirmButtonText}>
              Revisar
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

export default ErrorModal;
