import React from 'react';
import styles from './styles.module.css';
import { ReactComponent as Icon } from '../../assets/default-error.svg';
import { Typography } from '@material-ui/core';
import Button from '../Button';
import { useHistory } from 'react-router-dom';

function PageError({
  title,
  description,
  route
}) {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Icon className={styles.icon} />
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.description}>{description}</Typography>
      <Button onClick={() => history.push(route)} title="Voltar" outlined />
    </div>
  );
}

export default PageError;
