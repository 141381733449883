import * as yup from 'yup';

const insertBankDataYupSchema = yup.object().shape({
  bank: yup.object().required(),
  agency: yup.number().required(),
  agencyDigit: yup.number().required(),
  account: yup.number().required(),
  accountDigit: yup.number().required(),
  accountType: yup.string().required(),
});

export { insertBankDataYupSchema };
