import React from 'react';
import { Box, Typography } from '@material-ui/core';

export function FilterHeader() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
      }}
    >
      <Typography variant="h6">Filtros</Typography>
    </Box>
  );
}
