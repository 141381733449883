import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { Typography, Button } from '@material-ui/core';

import Publish from '@material-ui/icons/Publish';
import { ReactComponent as DescriptionIcon } from '../../../../assets/fileText.svg';
import { ReactComponent as UploadFile } from '../../../../assets/fileUploadWhite.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/fileDownloadWhite.svg';

import { api } from '../../../../services/api';
import { pusherChannels } from '../../../../enums/pusher';
import { pusher } from '../../../../utils/pusher';

import styles from './styles.module.css';
import ProgressToolBar from '../../../../components/ProgressToolBar';
import PercentageCircularProgress from '../../../../components/PercentageCircularProgress';
import PrimaryModal from '../../../../components/PrimaryModal';

export default () => {
  const history = useHistory();
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const { PROCESSED_EMPLOYEES_LIMITS } = pusherChannels;
  const exampleSheet =
    'https://somapay-companies.s3.amazonaws.com/import-file-type/Planilha%2Bde%2Bcadastro%2Blimite%2BSomapay.xlsx';

  const [file, setFile] = useState(null);
  const [dropFileError, setDropFileError] = useState(false);
  const [fileName, setFileName] = useState('');
  const [processingFile, setProcessingFile] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [importFileError, setImportFileError] = useState(false);
  const [importFileErrorValues, setImportFileErrorValues] = useState([]);
  const [importFileSuccess, setImportFileSuccess] = useState(false);

  const handleDownloadModelSheet = () => {
    window.open(exampleSheet);
  };

  const onDrop = useCallback(file => {
    if (file.length === 0) {
      setDropFileError(true);
      return;
    }

    setDropFileError(false);
    setFile(file);
    setFileName(file[0].name);
  }, []);

  const onRemove = () => {
    setFileName('');
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.xls,.xlsx',
    multiple: false,
    onDrop,
  });

  const goToErrorsList = () => {
    history.push('/limit-query/menu/info-send-file/list-error', {
      errors: importFileErrorValues,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const goBackToInitialScreen = () => {
    history.go(-2);
  };

  const handleImportFile = () => {
    setProcessingFile(true);
    setPercentage(0);
    const sendFile = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file[0]);
      api
        .post(`anticipation/import/limits/${companyCode}`, formData)
        .then(() => {
          resolve();
        })
        .catch(error => {});
    });

    const processingAndDoneSend = new Promise((resolve, reject) => {
      const channel = pusher.subscribe(companyCode);
      channel.bind(PROCESSED_EMPLOYEES_LIMITS, function(data) {
        data?.status === 'PROCESSING'
          ? setPercentage(data?.percentage)
          : resolve(data?.data);
      });
    });

    Promise.all([sendFile, processingAndDoneSend]).then(result => {
      setTimeout(() => {
        setProcessingFile(false);
        setFile(null);
        setFileName('');
        if (result[1].length !== 0) {
          setImportFileErrorValues(result[1]);
          setImportFileError(true);
        } else {
          setImportFileSuccess(true);
        }
      }, 1000);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.pageTitleContainer}>
        <Typography className={styles.pageTitle}>
          Alteração de limite via arquivo
        </Typography>
        <div>
          <Button
            variant="text"
            className={styles.downloadExample}
            startIcon={<DownloadIcon className={styles.downloadIcon} />}
            onClick={handleDownloadModelSheet}
          >
            <Typography className={styles.downloadModelText}>
              Baixar modelo
            </Typography>
          </Button>
        </div>
      </div>
      <Typography className={styles.pageSubtitle}>
        Comece fazendo o download do modelo da planilha
      </Typography>
      <Typography className={styles.text}>
        Após o download, utilize o excel ou equivalente e preencha com os dados
        dos seu colaboradores.
      </Typography>
      <Typography className={styles.pageSubtitle}>
        Envie o arquivo preenchido
      </Typography>
      <div className={styles.sendFileContainer}>
        <div className={styles.fileDropZone}>
          {fileName === '' ? (
            <div className={styles.fileDropZone} {...getRootProps()}>
              <input {...getInputProps()} />

              {isDragActive ? (
                <Typography className={styles.dropZoneText}>Soltar</Typography>
              ) : (
                <>
                  {dropFileError ? (
                    <Typography className={styles.dropZoneTextError}>
                      Erro! O tipo de arquivo é inválido. Tente novamente.
                    </Typography>
                  ) : (
                    <>
                      <Typography className={styles.dropZoneText}>
                        <UploadFile className={styles.uploadFileIcon} />
                        Importar arquivo
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className={styles.fileDropZone}>
              {isDragActive ? (
                <Typography className={styles.dropZoneText}>Soltar</Typography>
              ) : (
                <>
                  {dropFileError ? (
                    <Typography className={styles.dropZoneTextError}>
                      Erro! O tipo de arquivo é inválido. Tente novamente.
                    </Typography>
                  ) : processingFile ? (
                    <div className={styles.checkingDataContainer}>
                      <Typography className={styles.checkingDataText}>
                        Conferindo dados
                      </Typography>
                      <PercentageCircularProgress value={percentage} />
                    </div>
                  ) : (
                    <div className={styles.sendFileLoadedField}>
                      <Typography className={styles.dropZoneTextFileName}>
                        <DescriptionIcon width="48" height="48" />
                        {fileName}
                      </Typography>
                      <Button
                        variant="outlined"
                        className={styles.removeFileButton}
                        onClick={onRemove}
                      >
                        Remover
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <Typography className={styles.subtitle}>
          {fileName === ''
            ? 'Você pode arrastar ou clicar na caixa para selecionar o arquivo.'
            : 'Enquanto verificamos o arquivo você pode continuar seu trabalho.'}
        </Typography>
      </div>
      <ProgressToolBar
        nextButtonText="Processar"
        disabled={fileName === ''}
        onBackClicked={goBack}
        onNextClicked={handleImportFile}
      />
      <PrimaryModal
        open={importFileError || importFileSuccess}
        title={
          importFileError
            ? 'Preenchimento de arquivo incorreto'
            : 'Limites alterados com sucesso!'
        }
        text={
          importFileError
            ? `Verificamos que ${importFileErrorValues.length} ${
                importFileErrorValues.length > 1
                  ? 'registros estão incorretos.'
                  : 'registro está incorreto.'
              }`
            : 'Clique em "CONTINUAR" para voltar para a tela inicial.'
        }
        confirmButtonText={importFileError ? 'Entendi' : 'Continuar'}
        disabledCancel
        onConfirmClicked={
          importFileError ? goToErrorsList : goBackToInitialScreen
        }
      />
    </div>
  );
};
