import React from 'react';
import Lottie from 'react-lottie';

import { Box, Typography } from '@material-ui/core';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import successEmployees from '../../../../../lotties/successEmployees.json';
import { isMobile } from '../../../../../utils/breakpoints';

import styles from '../../styles.module.css';

export default function RegisterEmployeeSuccessful({ employeeName }) {
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: successEmployees,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box>
      <Box className={styles.header}>
        <Typography className={styles.titleStyle}>Tudo certo!</Typography>
      </Box>

      <Box mt={3} className={styles.rowArea}>
        <Box className={styles.block}>
          <Box className={styles.informationBlock}>
            <Box className={styles.iconStyles}>
              <PersonOutlineOutlinedIcon className={styles.icon} />
            </Box>

            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                className={styles.subtitleStyle}
              >
                O funcionário
                <span className={styles.subtitleBoldStyle}>
                  {' '}
                  {employeeName ? employeeName[0] : ''}{' '}
                </span>
                foi cadastrado com sucesso.
              </Typography>
              <Typography className={styles.fieldsSubTitle}>
                Para que o funcionário tenha acesso aos serviços e benefícios da
                conta, por favor, orientá-lo a baixar o aplicativo Somapay
                (disponível para Android e IOS).
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.ilustrationArea}>
          <Lottie
            options={animationOptions}
            width={isMobile ? 250 : 300}
            height={isMobile ? 190 : 250}
          />
        </Box>
      </Box>
    </Box>
  );
}
