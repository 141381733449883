import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Grid,
  IconButton,
  Typography,
  Button,
  TablePagination,
  TableFooter,
  Chip,
  TextField,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import Cancel from '@material-ui/icons/Cancel';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

import FakeTable from './FakeTable';
import TablePaginationActions from './TablePaginationActions';
import FiredEmployeesModal from '../../../../components/PrimaryModal';

import { ReactComponent as DoublePersonIcon } from '../../../../assets/doublePersonIcon.svg';

import CollaboratorsService from '../utils/services';
import textCapitalize from '../../../../utils/textCapitalize';

export default function DualListComponent({
  setEmptyList,
  onClose,
  setTableState,
  tableState,
}) {
  const authToken = sessionStorage.getItem('@ContaSoma-Token');

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCodeValue = companyCodeValueStorage
    ? companyCodeValueStorage
    : companies[0].code;

  const [listOne, setListOne] = useState([]);

  const [listTwo, setListTwo] = useState(
    sessionStorage.getItem('selectedEmployees') !== null
      ? _.chunk(JSON.parse(sessionStorage.getItem('selectedEmployees')), 5)
      : [[]]
  );

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [currentChunk, setCurrentChunk] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [openFiredEmployeesModal, setOpenFiredEmployeesModal] = useState(false);

  useEffect(() => {
    if (listTwo.length === 0) setListTwo([[]])
  }, []);

  const [listTwoBeforeSearch, setListTwoBeforeSearch] = useState(
    sessionStorage.getItem('selectedEmployees') !== null
      ? _.chunk(JSON.parse(sessionStorage.getItem('selectedEmployees')), 5)
      : [[]]
  );

  const passItemToListTwo = itemIndex => {
    const auxListOne = [...listOne];
    const auxListTwo = [...listTwo];

    if (auxListTwo[currentChunk].length < 5) {
      auxListTwo[currentChunk].push(auxListOne[itemIndex]);
    } else {
      auxListTwo.push([]);

      auxListTwo[currentChunk + 1].push(auxListOne[itemIndex]);

      setCurrentChunk(currentChunk + 1);
    }

    if (!auxListOne.length) {
      // TODO: Request when all of listOne is selected
      requestCollaborators();
    }

    setListOne([...auxListOne]);
    setListTwo([...auxListTwo]);

    setListTwoBeforeSearch([...auxListTwo]);
  };

  const removeFromListTwo = (index, chunkIndex) => {
    const auxListOne = [...listOne];
    const auxListTwo = [...listTwo];

    auxListTwo[chunkIndex].splice(index, 1);

    setListOne([...auxListOne]);
    setListTwo([...auxListTwo]);

    setListTwoBeforeSearch([...auxListTwo]);
  };

  const removeAllFromListTwo = () => {
    setListTwo([[]]);
    setListTwoBeforeSearch([[]]);
  };

  const testIfItemIsOnSomeChunk = item => {
    const auxListTwo = [...listTwoBeforeSearch];

    let is = auxListTwo.some(
      array => array.filter(itemArray => itemArray.cpf === item.cpf).length
    );

    return is;
  };

  useEffect(() => {
    let auxArray = [];
    if (listTwo.length) {
      listTwo[currentChunk].forEach(item => {
        auxArray.push(item.encodedKey);
      });
    }
  }, [listTwo]);

  const requestCollaborators = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
      params: {
        page: currentPage,
        size: 5,
        cpfOrName: searchValue,
      },
    };

    CollaboratorsService.getCollaborators({
      requestOptions,
      companyCode: companyCodeValue,
      setStates: (listOneData, totalSize) => {
        setLoading(false);
        setListOne([...listOneData]);
        setTotalSize(totalSize);
      },
    });
  };

  function getArrayOfSelected() {
    const auxListTwo = listTwo;
    let arrayOfSelected = [];

    auxListTwo.forEach(item => {
      item.forEach(element => {
        arrayOfSelected.push(element);
      });
    });

    return arrayOfSelected;
  }

  const ARRAY_OF_SELECTED = getArrayOfSelected();

  function includeOnSessionStorage() {
    if (ARRAY_OF_SELECTED.find(item => item.employeeStatus === 'Demitido')) {
      setOpenFiredEmployeesModal(true);
      return;
    };

    confirmIncludeEmployees();
  };

  function confirmIncludeEmployees() {
    sessionStorage.setItem(
      'selectedEmployees',
      JSON.stringify(ARRAY_OF_SELECTED)
    );

    setTableState(!tableState);
    setOpenFiredEmployeesModal(false);

    if (ARRAY_OF_SELECTED.length) {
      setEmptyList(false);
      onClose();
    }
  }

  useEffect(() => {
    requestCollaborators();
  }, [currentPage, searchValue]);

  const searchOnChunksOfListTwo = inputSearch => {
    setSearchValue2(inputSearch);

    if (!inputSearch) {
      setListTwo([...listTwoBeforeSearch]);
    } else {
      let arrayOfSearched = [];

      listTwoBeforeSearch.forEach(item => {
        arrayOfSearched.push(
          ...item.filter(o =>
            o.name.toLowerCase().includes(inputSearch.toLowerCase())
          )
        );
      });

      setListTwo(_.chunk(arrayOfSearched, 5));
    }
  };

  const searchOnChunksOfListOne = (value) => {
    setLoading(true);
    setSearchValue(value);
    setCurrentPage(0);
  }

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item md={6}>
        <div className={styles.firstTableHeader}>
          <Typography
            className={styles.firstTableTitle}
          >
            Base de funcionários
          </Typography>
        </div>
        <TextField
          onChange={({ target: { value } }) => searchOnChunksOfListOne(value)}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="Pesquisar por nome ou CPF"
          fullWidth
          variant="outlined"
          value={searchValue}
          onFocus={() => setTimeout(() => setTextFieldFocused('searchValue'), 200)}
          onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
          InputProps={{
            style: {
              height: 40,
              borderRadius: 6
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: (searchValue?.length > 0 && textFieldFocused === 'searchValue') && (
              <IconButton
                className={styles.endAdornment}
                onClick={() => setSearchValue('')}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            )
          }}
        />

         {!loading ? (
           <Grid container style={{ height: 281 }}>
           <TableContainer component={Paper}>
             <Table>
               <TableBody className={styles.tableContainer}>
                 {listOne.map((item, index) => (
                   <TableRow
                    onClick={
                      testIfItemIsOnSomeChunk(item)
                      ? null
                      : () => passItemToListTwo(index)}
                      className={styles.tableRowStyle}
                      style={{
                        backgroundColor: testIfItemIsOnSomeChunk(item)
                          ? '#F2f2f2'
                          : '#ffffff',
                        cursor: testIfItemIsOnSomeChunk(item)
                        ? "default"
                        : "pointer",
                      }}
                    >
                     <TableCell
                       className={
                         testIfItemIsOnSomeChunk(item)
                         ? styles.buttonRowDisabled
                         : styles.buttonRow
                       }
                       align="left"
                       style={{ padding: '6.5px 10px 6.5px 10px', height: 52 }}
                     >
                       <Grid
                         container
                         style={{ fontWeight: 500 }}
                       >
                         {textCapitalize(item.name)}
                       </Grid>
                       <Grid
                         container
                         style={{ color: '#828282' }}
                       >
                         {item.cpf}
                         {item.employeeStatus !== 'Ativo' &&
                           item.employeeStatus && (
                             <Chip
                                className={styles.employeeStatus}
                                size="small"
                                label={item.employeeStatus.toUpperCase()}
                             />
                           )}
                       </Grid>
                     </TableCell>
                     <TableCell align="right" >{item.code}</TableCell>
                     <TableCell align="right" padding="checkbox">
                       <IconButton
                         disabled={testIfItemIsOnSomeChunk(item)}
                       >
                         <ChevronRight></ChevronRight>
                       </IconButton>
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
         </Grid>
         ): (
          <FakeTable />
         )}
        <Grid item md={12}>
          <TableContainer component={Paper} className={styles.footerStyle}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={styles.paginationComponentStyle}
                  count={totalSize}
                  rowsPerPageOptions={[5]}
                  rowsPerPage={5}
                  page={currentPage}
                  labelRowsPerPage="Linhas por página"
                  onChangePage={(event, newPage) => {
                    setCurrentPage(newPage);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Grid container >
          <Grid item style={{ flexGrow: 1 }}>
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 500,
                marginBottom: 16
              }}
            >
              Funcionários selecionados
            </Typography>
          </Grid>

          <Grid item>
            <Button
              disabled={listTwo <= 0 ? true : false}
              onClick={() => {
                removeAllFromListTwo();
              }}
              className={
                listTwo <= 0
                  ? styles.retirarButtonBlocked
                  : styles.retirarButton
              }
            >
              Remover Todos
            </Button>
          </Grid>
        </Grid>

        <TextField
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="Pesquisar por nome ou CPF"
          fullWidth
          variant="outlined"
          value={searchValue2}
          onChange={({ target: { value } }) => searchOnChunksOfListTwo(value)}
          onFocus={() => setTimeout(() => setTextFieldFocused('searchValue2'), 200)}
          onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
          InputProps={{
            style: {
              height: 40,
              borderRadius: 6
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: (searchValue2?.length > 0 && textFieldFocused === 'searchValue2') && (
              <IconButton
                className={styles.endAdornment}
                onClick={() => {
                  setSearchValue2('');
                  setListTwo([...listTwoBeforeSearch]);
                }}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            )
          }}
        />

        <Grid container style={{ height: 281 }}>
          {listTwo <= 0 ? (
            <div className={styles.iconArea}>
              <div>
                <DoublePersonIcon className={styles.icon} />
              </div>
              <div className={styles.textOneArea}>
                <Typography className={styles.textOne}>
                  Clique em um nome ao lado,
                </Typography>
                <Typography className={styles.textOne}>
                  e o colaborador aparecerá nesta lista.
                </Typography>
              </div>
              <div className={styles.textTwoArea}>
                <Typography className={styles.textTwo}>
                  Quando escolher todos,
                </Typography>
                <Typography className={styles.textTwo}>
                  clique no botão{' '}
                  <span className={styles.incluirColor}> Incluir</span> abaixo.
                </Typography>
              </div>
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {listTwo[currentChunk].map((item, index) => (
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ padding: '8px 10px', height: 52 }}
                      >
                        <Grid container style={{ fontWeight: 500 }}>
                          {textCapitalize(item.name)}
                        </Grid>
                        <Grid
                          container
                          style={{ color: '#828282' }}
                        >
                          {item.cpf}
                        </Grid>
                      </TableCell>
                      <TableCell align="right">{item.code}</TableCell>
                      <TableCell align="right" padding="checkbox">
                        <Tooltip title="Remover da Lista">
                          <IconButton
                            onClick={() => {
                              removeFromListTwo(index, currentChunk);
                            }}
                          >
                            <Cancel></Cancel>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        <Grid item md={12}>
          <TableContainer component={Paper} className={styles.footerStyle}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={styles.paginationComponentStyle}
                  count={listTwo.reduce(
                    (acc, element) => acc + element.length,
                    0
                  )}
                  rowsPerPageOptions={[5]}
                  rowsPerPage={5}
                  page={currentChunk}
                  labelRowsPerPage="Linhas por página"
                  onChangePage={(event, newPage) => {
                    setCurrentChunk(newPage);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container justify="flex-end" className={styles.buttonStyle}>
        <Grid item>
          <Button
            className={styles.cancelButton}
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={styles.includeButton}
            onClick={includeOnSessionStorage}
            disabled={listTwo === 0}
          >
            Incluir
          </Button>
        </Grid>
      </Grid>
      
      <FiredEmployeesModal 
        title="Atencão!"
        text="Você selecionou funcionários que estão com status de demitido. 
        Quer continuar mesmo assim com o pagamento?"
        open={openFiredEmployeesModal}
        confirmButtonText="Sim, continuar"
        cancelButtonText="Não"
        onCancelButtonClicked={() => setOpenFiredEmployeesModal(false)}
        onConfirmClicked={confirmIncludeEmployees}
      />
    </Grid>
  );
}
