import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import {
  DomainRounded,
  ExpandMoreRounded,
  InfoOutlined,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import CardTools from '../../../../../components/CardTools';
import { InfoCard } from '../../../../../components/InfoCard';
import { Charts } from '../../../../../components/Charts';
import Buttons from '../../../../../components/Buttons';
import Form from '../../../../../components/Form';

export function CardLoading() {
  return (
    <CardTools.GeneralCard>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            <Form.Checkbox disabled />

            <InfoCard isLoading icon={<DomainRounded />} title="Empresa" />

            <CardTools.CardInfo isLoading title="Quantidade de folhas" />

            <CardTools.CardInfo isLoading title="Saldo da conta" />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Divider flexItem orientation="vertical" />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <CardTools.CardInfo
                isLoading
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Saldo disponível da conta
                    <InfoOutlined color="gray" style={{ width: '1.25rem' }} />
                  </Box>
                }
                titleStyle={{
                  alignSelf: 'end',
                }}
              />

              <Skeleton variant="text" width="100%" />
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box sx={{ width: '100%' }}>
          <Charts.LinearWithMiddleSpace
            isLoading
            firstColor="success"
            secondColor="error"
            title="Valor total"
            firstValueLabel="Valor válido"
            secondValueLabel="Valor inválido"
          />
        </Box>

        <Box sx={{ marginBottom: '1rem' }}>
          <Buttons.SecondaryButton
            disabled
            color="primary"
            variant="text"
            title="Ver detalhamento da folha"
            endIcon={
              <ExpandMoreRounded color="primary" style={{ opacity: 0.6 }} />
            }
          />
        </Box>
      </Box>
    </CardTools.GeneralCard>
  );
}
