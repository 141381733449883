import {
  Tooltip,
  IconButton as Button,
  CircularProgress,
} from '@material-ui/core';
import React from 'react';

export function IconButton({
  icon = () => {},
  color = 'default',
  disabled = false,
  loading = false,
  tooltipTitle = '',
  tooltipTitleDisabled = '',
  onClick = () => {},
  ...buttonProps
}) {
  const colors = [
    'inherit',
    'default',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning',
  ];

  return (
    <Tooltip arrow title={disabled ? tooltipTitleDisabled : tooltipTitle}>
      <Button
        style={{
          opacity: disabled ? 0.3 : 1,
        }}
        color={
          colors.some(arrayColor => arrayColor === color) ? color : 'default'
        }
        disabled={disabled}
        onClick={disabled ? () => null : onClick}
        {...buttonProps}
      >
        {!loading ? icon : <CircularProgress size={16} color="primary" />}
      </Button>
    </Tooltip>
  );
}
