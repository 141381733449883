import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Table from './Table';
import MobileList from './MobileList';
import Header from './Header';
import { actions } from './actions';

import LogoLoading from '../../../components/LogoLoading';
import PrimaryModal from '../../../components/PrimaryModal';
import Snackbar from '../../../components/Snackbar';
import FloatButton from '../../../components/FloatButton';

import { isMobile } from '../../../utils/breakpoints';

import { api } from '../../../services/api';

function ManageUsers() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [logoLoading, setLogoLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [titleModal, setTitleModal] = useState('');
  const [textModal, setTextModal] = useState('');
  const [cancelButtonTextModal, setCancelButtonTextModal] = useState('');
  const [confirmButtonTextModal, setConfirmButtonTextModal] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [cpf, setCpf] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const [usersList, setUsersList] = useState([]);

  const history = useHistory();

  const COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage]);

  function handleClickNewUser() {
    history.push('/manage-users/register');
  }

  function getUsers() {
    setLoadingFilter(true);

    const requestOptions = {
      params: {
        page,
        size: rowsPerPage,
      },
    };

    api
      .get(`/users/manageUsers/${COMPANY_CODE}`, requestOptions)
      .then(({ data }) => {
        setUsersList(data.content);
        setTotalSize(data.totalSize);
      })
      .finally(() => {
        setLogoLoading(false);
        setLoadingFilter(false);
      });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleClickOption(option) {
    handleClose();

    if (option === 1) {
      setModalArgumentsForResetPassword();
      return;
    }

    if (option === 2) {
      setModalArgumentsForDeleteUser();
    }
  }

  function setModalArgumentsForResetPassword() {
    return setModalArguments(
      'Resetar senha',
      'Um e-mail com um link para alterar a senha será enviado.',
      'Cancelar',
      'Enviar',
    );
  }

  function setModalArgumentsForDeleteUser() {
    return setModalArguments(
      'Você deseja realmente excluir este usuário?',
      'Ao excluír, o usuário não poderá acessar a plataforma.',
      'Não, cancelar',
      'Sim, excluir',
    );
  }

  function setModalArguments(title, text, cancelButton, confirmButton) {
    setOpenModal(true);
    setTitleModal(title);
    setTextModal(text);
    setCancelButtonTextModal(cancelButton);
    setConfirmButtonTextModal(confirmButton);
  }

  function onConfirmModalClick() {
    const IS_RESET_PASSWORD = Boolean(titleModal === 'Resetar senha');
    const IS_DELETE_USER = Boolean(
      titleModal === 'Você deseja realmente excluir este usuário?',
    );

    setLoading(true);

    if (IS_RESET_PASSWORD) {
      resetPassword();
      return;
    }

    if (IS_DELETE_USER) {
      deleteUser();
    }
  }

  function deleteUser() {
    api
      .delete(`/users/delete/${cpf}/${COMPANY_CODE}`)
      .then(() => {
        setOpenModal(false);
        setOpenSnackbar(true);
        setSnackbarMessage('Usuário excluído com sucesso!');
        getUsers();
      })
      .finally(() => setLoading(false));
  }

  function resetPassword() {
    api
      .post(`/users/resetPassword/${cpf}/${COMPANY_CODE}`)
      .then(() => {
        setOpenModal(false);
        setOpenSnackbar(true);
        setSnackbarMessage('E-mail para resetar senha enviado com sucesso!');
      })
      .finally(() => setLoading(false));
  }

  function handleClickMenu(event, cpf) {
    setAnchorEl(event.currentTarget);
    setCpf(cpf);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function viewDetails(cpf) {
    if (isMobile) {
      sessionStorage.setItem(
        'useTopBar',
        JSON.stringify({
          name: 'Detalhes do usuário',
          step: 0,
          route: '/manage-users',
        }),
      );
    }

    history.push(`/manage-users/${cpf}`);
  }

  if (logoLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <Header onClick={handleClickNewUser} />

      {isMobile ? (
        <MobileList
          usersList={usersList}
          handleClickOption={handleClickOption}
          viewDetails={viewDetails}
        />
      ) : (
        <Table
          usersList={usersList}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          loadingFilter={loadingFilter}
          getUsers={getUsers}
          handleClickNewUser={handleClickNewUser}
          viewDetails={viewDetails}
          anchorEl={anchorEl}
          handleClickOption={handleClickOption}
          handleClickMenu={handleClickMenu}
          handleClose={handleClose}
          totalSize={totalSize}
        />
      )}

      <PrimaryModal
        open={openModal}
        title={titleModal}
        text={textModal}
        cancelButtonText={cancelButtonTextModal}
        confirmButtonText={confirmButtonTextModal}
        onCancelButtonClicked={() => setOpenModal(false)}
        onConfirmClicked={onConfirmModalClick}
        loading={loading}
      />

      <Snackbar
        open={openSnackbar}
        close={() => setOpenSnackbar(false)}
        severity="success"
        message={snackbarMessage}
      />

      <FloatButton actions={actions} hidden={!isMobile} />
    </>
  );
}

export default ManageUsers;
