import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsEmployeesRegisterFilesDetails } from '../../../../../redux/store/Employees/RegisterFiles/FileDetails';
import { useStatisticsComponentsData } from './useStatisticsComponentsData';
import { ActionsEmployeesRegisterFilesGeneral } from '../../../../../redux/store/Employees/RegisterFiles';

export function useRegisterFilesDetails() {
  const dispatch = useDispatch();
  const { registerFilesDetails, length } = useSelector(
    state => state.employees.employeesRegisterFiles.details,
  );

  const statisticsComponentsData = useStatisticsComponentsData({
    totalLines: registerFilesDetails?.generalCardData?.totalLines,
    created: registerFilesDetails?.generalCardData?.created,
    pending: registerFilesDetails?.generalCardData?.pending,
    reproved: registerFilesDetails?.generalCardData?.reproved,
  });

  const filterInitialValues = { cpf: '', name: '' };

  function paginationRequests(page, perPage) {
    dispatch(ActionsEmployeesRegisterFilesDetails.handleChangePage(page));
    dispatch(ActionsEmployeesRegisterFilesDetails.handleChangePerPage(perPage));
    dispatch(ActionsEmployeesRegisterFilesDetails.searchEmployeesInTheFile());
  }

  function handleFilterChange(data) {
    dispatch(ActionsEmployeesRegisterFilesDetails.setFilter({ ...data }));
    dispatch(ActionsEmployeesRegisterFilesDetails.searchEmployeesInTheFile());
  }

  useEffect(() => {
    dispatch(ActionsEmployeesRegisterFilesDetails.searchEmployeesInTheFile());
    dispatch(ActionsEmployeesRegisterFilesDetails.getEmployeesCount());

    return () => {
      dispatch(ActionsEmployeesRegisterFilesGeneral.cleanup());
      dispatch(ActionsEmployeesRegisterFilesDetails.resetGeneralCardData());
    };
  }, []);

  return {
    generalCardObject: {
      isLoading: registerFilesDetails?.isLoading,
      fileName: registerFilesDetails?.selectedFile?.name,
      userThatImportedTheFile:
        registerFilesDetails?.selectedFile?.importedBy ?? '-',
      processedAt: registerFilesDetails?.selectedFile?.processedAt,
      statisticsComponentsData,
    },
    filterObject: {
      filterInitialValues,
      handleFilterChange,
    },
    tableObject: {
      data: registerFilesDetails?.employeesList,
      length,
      isLoading: registerFilesDetails?.isLoading,
      hasError: registerFilesDetails?.errorOn?.hasError,
      paginationRequests,
    },
  };
}
