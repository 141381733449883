import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
  MenuItem,
  Tooltip,
  Typography,
  Switch,
  FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ErrorIcon from '@material-ui/icons/Error';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import styles from '../styles.module.css';

import { api } from '../../../../services/api';

import formatAccountAndAgencyNumber from '../../../../utils/formatAccountAndAgencyNumber';
import formatDigitValue from '../../../../utils/formatDigitValue';
import { isMobile } from '../../../../utils/breakpoints';
import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';

export default function BankForm({
  disabled,
  bankDataForm,
  setBankDataForm,
  loading,
  setOnSubmitForm,
  onSubmitForm,
  activeTransferSwitch,
  setActiveTransferSwitch,
  editMode,
  IS_REGISTER_VIEW,
}) {
  const [banksList, setBanksList] = useState([]);

  useEffect(() => {
    getBanksList();
  }, []);

  function handleChangeBankDataForm(event, name, value) {
    const formFields = bankDataForm;

    setOnSubmitForm(false);

    formFields[name] = event?._isAMomentObject
      ? moment(event?._d).format('YYYY-MM-DD')
      : name === 'bankCode'
        ? value.code
        : event?.target?.value;

    setBankDataForm({ ...formFields });
  }

  function inputValidate(name) {
    if (
      (!bankDataForm[name] || bankDataForm[name] === 'Data inválida') &&
      onSubmitForm &&
      activeTransferSwitch
    ) {
      return true;
    }
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  function handleChangeActiveTransferSwitch() {
    setActiveTransferSwitch(!activeTransferSwitch);
    setOnSubmitForm(false);
  }

  function getBanksList() {
    api.get('/accountCashIn/bank/findAll').then(({ data }) => {
      setBanksList(data);
    });
  }

  function maskedPercentage(value) {
    value = value
      .toString()
      .replace(/\D/g, '')
      .replace(/$/, '%');
    return value;
  }

  return (
    <form className={styles.form}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Grid
            container
            alignItems={isMobile ? 'flex-start' : 'center'}
            direction={isMobile ? 'column' : 'row'}
          >
            <Grid item>
              <Box mb={2} mt={-2}>
                <Typography className={styles.bankDataTitle}>
                  Ativar transferência programada
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box mb={2} mt={-2}>
                <Switch
                  checked={activeTransferSwitch}
                  onChange={handleChangeActiveTransferSwitch}
                  disabled={(!IS_REGISTER_VIEW && !editMode) || loading}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box mb={2} mt={-2}>
            <Tooltip
              title="Ativando a transferência programada, todo pagamento
              feito a esse funcionário, será transferido automaticamente
              para a conta cadastrada."
            >
              <HelpRoundedIcon className={styles.bankDataIcon} />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <TextField
            label="Percentual (%)*"
            variant="outlined"
            fullWidth
            size="small"
            disabled={disabled || !activeTransferSwitch}
            value={
              bankDataForm.transfeerPercent &&
              maskedPercentage(bankDataForm.transfeerPercent)
            }
            onChange={event =>
              handleChangeBankDataForm(event, 'transfeerPercent')
            }
            error={inputValidate('transfeerPercent')}
            helperText={
              inputValidate('transfeerPercent') && 'Esse campo é obrigatório'
            }
            InputProps={{
              endAdornment: (
                <Tooltip title="Percentual a transferir para a conta">
                  <ErrorOutlineRoundedIcon
                    className={styles.endAdornmentBankData}
                  />
                </Tooltip>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
          >
            <Autocomplete
              id="combo-box-demo"
              options={banksList}
              onChange={(event, value) =>
                handleChangeBankDataForm(event, 'bankCode', value)
              }
              getOptionLabel={option =>
                option.name
                  ? option.name
                  : banksList.filter(
                    item => item.code === bankDataForm?.bankCode,
                  )[0]?.name
              }
              renderOption={option => (
                <>
                  {option.name} - {option.code}
                </>
              )}
              disabled={disabled || !activeTransferSwitch}
              value={
                banksList.filter(
                  item => item.code === bankDataForm?.bankCode,
                )[0]?.name
              }
              closeIcon={false}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Banco*"
                  variant="outlined"
                  size="small"
                  error={inputValidate('bankCode')}
                  helperText={
                    inputValidate('bankCode') && 'Esse campo é obrigatório'
                  }
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <FormControl
            variant="outlined"
            className={styles.filter}
            fullWidth
            size="small"
            error={inputValidate('accountType')}
          >
            <InputLabel id="select-outlined-label-type">
              Tipo de conta*
            </InputLabel>
            <Select
              disabled={disabled || !activeTransferSwitch}
              labelId="select-outlined-label-type"
              label="Tipo de conta*"
              value={bankDataForm.accountType}
              onChange={event => handleChangeBankDataForm(event, 'accountType')}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value={1}>Conta Corrente</MenuItem>
              <MenuItem value={2}>Conta Poupança</MenuItem>
              <MenuItem value={3}>Conta Fácil Caixa</MenuItem>
            </Select>
            {inputValidate('accountType') && (
              <FormHelperText className={styles.helperText}>
                Selecione uma opção
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
          <TextField
            className={styles.textHelperAgency}
            disabled={disabled || !activeTransferSwitch}
            label="Agência*"
            variant="outlined"
            fullWidth
            size="small"
            value={formatOnlyNumbers(bankDataForm.agency)}
            onChange={event => handleChangeBankDataForm(event, 'agency')}
            error={inputValidate('agency')}
            helperText={() =>
              (inputValidate('agency') && 'Esse campo é obrigatório') ||
              '*Digite sem o dígito'
            }
            inputProps={{ maxLength: 4 }}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
          <TextField
            disabled={disabled || !activeTransferSwitch}
            label="Nº da Conta*"
            variant="outlined"
            fullWidth
            size="small"
            value={
              bankDataForm.account &&
              formatAccountAndAgencyNumber(bankDataForm.account)
            }
            onChange={event => handleChangeBankDataForm(event, 'account')}
            error={inputValidate('account')}
            helperText={inputValidate('account') && 'Esse campo é obrigatório'}
            inputProps={{ maxLength: 13 }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <TextField
            disabled={disabled || !activeTransferSwitch}
            label="Dígito*"
            variant="outlined"
            fullWidth
            size="small"
            value={
              bankDataForm.checkDigit &&
              formatDigitValue(bankDataForm.checkDigit)
            }
            onChange={event => handleChangeBankDataForm(event, 'checkDigit')}
            error={inputValidate('checkDigit')}
            helperText={
              inputValidate('checkDigit') && 'Esse campo é obrigatório'
            }
            inputProps={{ maxLength: 2 }}
          />
        </Grid>
      </Grid>
      <div className={styles.containerOwnership}>
        <ErrorIcon className={styles.errorIcon} />
        <Typography className={styles.textOwnership}>
          A conta deve ter a mesma titularidade do funcionário.
        </Typography>
      </div>
    </form>
  );
}
