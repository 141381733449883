import React from 'react';
import styles from '../styles.module.css';
import Button from '../../../../components/Button';
import { Typography } from '@material-ui/core';

function Header() {
  return (
    <div className={styles.header}>
      <Typography className={styles.title}>Visualizar Detalhes</Typography>
      <Button title="Baixar Relatório" outlined />
    </div>
  );
}

export default Header;
