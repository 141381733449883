import React from 'react';
import './styles.modules.css';
import { Typography, Hidden } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import CustomDatePicker from '../../../../components/DatePicker';

function Filters({
  depositType,
  depositSituation,
  initialDate,
  finalDate,
  handleChangeType,
  handleChangeSituation,
  onChangeInitialDate,
  onChangeFinalDate,
  validateInitialDateInfo,
  validateFinalDateInfo,
}) {
  return (
    <div className="container">
      <FormControl variant="outlined" className="filter">
        <InputLabel id="demo-simple-select-outlined-label-type">
          Tipo
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label-type"
          id="demo-simple-select-outlined"
          value={depositType}
          onChange={handleChangeType}
          label="Tipo"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="Todos">Todos</MenuItem>
          <MenuItem value={1}>TED</MenuItem>
          <MenuItem value={2}>Boleto</MenuItem>
          <MenuItem value={3}>PIX</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className="filter">
        <InputLabel id="demo-simple-select-outlined-label-situation">
          Situação
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label-situation"
          id="demo-simple-select-outlined"
          value={depositSituation}
          onChange={handleChangeSituation}
          label="Situação"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="Todos">Todos</MenuItem>
          <MenuItem value={0}>Aguardando pagamento</MenuItem>
          <MenuItem value={1}>Confirmado</MenuItem>
          <MenuItem value={2}>Aguardando confirmação</MenuItem>
          <MenuItem value={3}>Cancelado</MenuItem>
          <MenuItem value={4}>Pago</MenuItem>
          <MenuItem value={5}>Vencido</MenuItem>
          <MenuItem value={6}>Verificação em processamento</MenuItem>
          <MenuItem value={7}>Verificação desfeita</MenuItem>
          <MenuItem value={8}>Transferência não Confirmada</MenuItem>
          <MenuItem value={9}>Transferência Confirmada</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className="filter">
        <CustomDatePicker
          value={initialDate}
          onChange={onChangeInitialDate}
          label={initialDate ? 'Data inicial' : null}
          placeholder="Data inicial"
          error={validateInitialDateInfo}
          disableCalendarIcon={validateInitialDateInfo}
        />
        <Hidden smDown>
          {validateInitialDateInfo && (
            <Typography className="dateError">*Data inválida</Typography>
          )}
        </Hidden>
      </FormControl>

      <span className="dateLabel">até</span>

      <FormControl variant="outlined" className="filter">
        <CustomDatePicker
          value={finalDate}
          onChange={onChangeFinalDate}
          label={finalDate ? 'Data final' : null}
          placeholder="Data final"
          error={validateFinalDateInfo}
          disableCalendarIcon={validateFinalDateInfo}
        />
        <Hidden smDown>
          {validateFinalDateInfo && (
            <Typography Typography className="dateError">
              *Data inválida
            </Typography>
          )}
        </Hidden>
      </FormControl>
    </div>
  );
}

export default Filters;
