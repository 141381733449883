import React from 'react';
import { Box } from '@material-ui/core';
import CardTools from '../../../../../../../CardTools';

export function CardContent({ cardData = {}, cardStyle }) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        ...cardStyle,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {cardData?.cardBody?.otherInfos?.map(info => (
          <CardTools.CardInfo
            title={info?.title}
            description={info?.description}
          />
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {cardData?.cardBody?.actions?.map(action => action)}
      </Box>
    </Box>
  );
}
