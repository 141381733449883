import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Typography, Grid } from '@material-ui/core';

import Form from '../../../../../components/Form';
import CashOutPixBottomBar from '../BottomBar';

import { maskCPF } from '../../../../../utils/maskCPF';
import { maskCNPJ } from '../../../../../utils/maskCNPJ';
import currencyFormatter from '../../../../../utils/currencyFormatter';

import { useStyles } from './styles';
import { PixContext } from '../Context';

export default function ReviewStep() {
  const styles = useStyles();

  const { bankOptions, onNext } = useContext(PixContext);

  const {
    current: { owner, account, ...props },
    form,
  } = useSelector(state => state.cashOut);

  const { handleSubmit } = useForm();

  const renderTaxId = () => {
    if (!form?.keyType) {
      return form?.cpfCnpj.length < 14
        ? maskCNPJ(form?.cpfCnpj)
        : maskCPF(form?.cpfCnpj);
    }

    return owner?.taxIdNumber.length < 14
      ? maskCNPJ(owner?.taxIdNumber)
      : maskCPF(owner?.taxIdNumber);
  };

  const renderBankName = code =>
    bankOptions.find(bank => bank?.code === parseInt(code))?.name;

  const onSubmit = (_, event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <Form.FormBase onSubmit={() => handleSubmit(onSubmit)}>
      <Box>
        <Typography variant="h2" className={styles.title}>
          As informações abaixo estão corretas?
        </Typography>
        <Grid container className={styles.container}>
          <Grid item xs={3}>
            <Typography variant="label" className={styles.label}>
              Nome do recebedor
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {(form.keyType ? owner?.name : form?.recipientName) || '-'}
            </Typography>
          </Grid>
          {form.keyType && props?.key && (
            <Grid item xs={6}>
              <Typography variant="label" className={styles.label}>
                Chave Pix
              </Typography>
              <Typography variant="body2" className={styles.keyText}>
                {props?.key || '-'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={3}>
            <Typography variant="label" className={styles.label}>
              Instituição bancária
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {(form.keyType
                ? account?.bankName
                : renderBankName(form?.bank)) || '-'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="label" className={styles.label}>
              Agência
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {(form.keyType ? account?.branch : form?.agency) || '-'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="label" className={styles.label}>
              Conta
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {(form.keyType ? account?.accountNumber : form?.account) || '-'}
            </Typography>
          </Grid>
          {owner?.taxIdNumber || form?.cpfCnpj ? (
            <Grid item xs={3}>
              <Typography variant="label" className={styles.label}>
                CPF/CNPJ
              </Typography>
              <Typography variant="body2" className={styles.item}>
                {renderTaxId()}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={3}>
            <Typography variant="label" className={styles.label}>
              Valor
            </Typography>
            <Typography variant="body2" className={styles.item}>
              {currencyFormatter(form.value)}
            </Typography>
          </Grid>
          {(props?.description || form?.description) && (
            <Grid item xs={6}>
              <Typography variant="label" className={styles.label}>
                Descrição
              </Typography>
              <Typography variant="body2" className={styles.item}>
                {form.keyType ? props.description : form?.description}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <CashOutPixBottomBar isFinal />
    </Form.FormBase>
  );
}
