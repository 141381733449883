import React, { useState } from 'react';

import {
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

function Filters({
  cpfOrName,
  setCpfOrName,
  onSearchChange,
  changedFilter,
}) {
  const [textFieldFocused, setTextFieldFocused] = useState('');

  return (
    <>
      <Grid container spacing={1} className={styles.containerInputs}>
        <Grid item xs={12} sm={9} md={9} lg={9} wrap="nowrap">
          <TextField
            onFocus={() =>
              setTimeout(() => setTextFieldFocused('searchText'), 200)
            }
            onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
            onChange={onSearchChange}
            className={styles.searchBackground}
            placeholder="Pesquisar por nome ou CPF"
            autoFocus={changedFilter && cpfOrName}
            fullWidth
            variant="outlined"
            value={cpfOrName || ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.iconColor} />
                </InputAdornment>
              ),
              endAdornment: cpfOrName?.length > 0 &&
                textFieldFocused === 'cpfOrName' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => setCpfOrName('')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Filters;