import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Menu, MenuItem } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import { Add } from '@material-ui/icons';

import TabLabel from './components/TabLabel';
import Buttons from '../../../components/Buttons';
import { Header } from '../../../components/Header';
import RequestScreenModals from './components/Modals';
import PendingRequestsTab from './components/Tabs/Pending';
import ApprovedRequestsTab from './components/Tabs/Approved';
import CanceledRequestsTab from './components/Tabs/Canceled';

import { CashOutActions as Actions } from '../../../redux/store/CashOut';

import { useStyles } from './styles';

const tabMap = {
  waiting_approve: {
    title: 'Pendentes',
    cellColor: '#FCF5E8',
    selectedColor: '#E39F17',
  },
  approved: {
    title: 'Autorizadas',
    cellColor: '#E8F2F2',
    selectedColor: '#19797F',
  },
  canceled: {
    title: 'Reprovadas',
    cellColor: '#FAE9E8',
    selectedColor: '#CA2317',
  },
};

export default function ManageCashOutRequests() {
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();

  const [currentTab, changeTab] = useState('waiting_approve');
  const [anchor, setAnchor] = useState(null);

  const { count } = useSelector(state => state.cashOut);

  const goToPixScreen = () => {
    history.push('/cash-out/pix');
  };

  const goToBillScreen = () => {
    history.push('/cash-out/bill');
  };

  const goToTransferScreen = () => {
    history.push('/cash-out/transfer');
  };

  const handleChangeTab = (_, value) => {
    changeTab(value);
  };

  const handleRequestsMenu = event => {
    setAnchor(event.currentTarget);
  };

  useEffect(() => {
    dispatch(Actions.resetFilters());
    dispatch(Actions.resetForm());
    dispatch(Actions.getRequestsCount());
    dispatch(Actions.createCelcoinAccount());
  }, []);

  return (
    <>
      <Header
        title="Gerenciar Solicitações"
        buttons={
          <Buttons.PrimaryButton
            aria-controls="requests-menu"
            aria-haspopup="true"
            color="primary"
            className={styles.button}
            icon={<Add />}
            title="Nova solicitação"
            onClick={handleRequestsMenu}
          />
        }
      />

      <Menu
        id="requests-menu"
        keepMounted
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MenuItem onClick={goToPixScreen}>Pix</MenuItem>
        <MenuItem onClick={goToBillScreen}>Boleto</MenuItem>
        <MenuItem onClick={goToTransferScreen}>Transferência Somapay</MenuItem>
      </Menu>

      <Box>
        <TabContext value={currentTab}>
          <TabList onChange={handleChangeTab} indicatorColor="primary">
            <Tab
              value="waiting_approve"
              className={styles.tab}
              label={
                <TabLabel
                  {...tabMap.waiting_approve}
                  count={count.pending}
                  selected={currentTab === 'waiting_approve'}
                />
              }
            />
            <Tab
              value="approved"
              className={styles.tab}
              label={
                <TabLabel
                  {...tabMap.approved}
                  count={count.approved}
                  selected={currentTab === 'approved'}
                />
              }
            />
            <Tab
              value="canceled"
              className={styles.tab}
              label={
                <TabLabel
                  {...tabMap.canceled}
                  count={count.rejected}
                  selected={currentTab === 'canceled'}
                />
              }
            />
          </TabList>
          <TabPanel className={styles.tabpanel} value="waiting_approve">
            <PendingRequestsTab />
          </TabPanel>
          <TabPanel className={styles.tabpanel} value="approved">
            <ApprovedRequestsTab />
          </TabPanel>
          <TabPanel className={styles.tabpanel} value="canceled">
            <CanceledRequestsTab />
          </TabPanel>
        </TabContext>
      </Box>
      <RequestScreenModals />
    </>
  );
}
