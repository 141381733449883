import React from 'react';
import styles from '../styles.module.css';
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { ReactComponent as EyeIcon } from '../../../../../../assets/eyesIcon.svg';
import FakeTable from './FakeTable';

function EnhancedTableBody({
  rows,
  onClickedDetails,
  page,
  rowsPerPage,
  loadingTable
}) {
  return (
    loadingTable ? (
      <FakeTable />
    ) : (
      <TableBody>
        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <TableRow
            hover
            key={row.id}
            className={styles.tableRow}
          >
            <TableCell className={styles.tableCell}>{row.identificador}</TableCell>
            <TableCell className={styles.tableCell}>{row.Competência}</TableCell>
            <TableCell className={styles.tableCell}>{row.Consolidação}</TableCell>
            <TableCell className={styles.tableCell}>{row.Seguro}</TableCell>
            <TableCell className={styles.tableCell}>{row.Segurado}</TableCell>
            <TableCell>
              <IconButton onClick={onClickedDetails}>
                <Tooltip title="Visualizar detalhes">
                  <EyeIcon />
                </Tooltip>
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  )
}

export default EnhancedTableBody;
