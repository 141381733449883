import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import { Table } from '../../../../../../components/Table';
import ManageRequestsFilters from '../../Filters';
import DetailsDrawer from '../../DetailsDrawer';

import useManageRequests from '../../../hooks/useManageRequests';

import useTableConfig from './hooks/useTableConfig';

const STATUS = 'CANCELED';

export default function CanceledRequestsTab() {
  const [open, setOpen] = useState(false);

  const {
    results,
    length,
    isLoading,
    onChangePage,
    onOpenDetails,
    control,
    selected,
    onSearch,
  } = useManageRequests({
    status: STATUS,
  });

  const handleDetails = entry => {
    setOpen(!open);
    onOpenDetails(entry);
  };

  const config = useTableConfig({ handleDetails });

  return (
    <Box>
      <ManageRequestsFilters onSearch={onSearch} control={control} />
      <Table
        identifier="id"
        hasPagination
        data={results}
        length={length}
        isLoading={isLoading}
        tableConfig={config}
        requests={onChangePage}
      />
      <DetailsDrawer
        status={STATUS}
        open={open}
        onClose={() => setOpen(false)}
        selected={selected}
      />
    </Box>
  );
}
