import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import ApproveModal from './ApproveModal';
import ReproveModal from './ReproveModal';
import ReprocessModal from './ReprocessModal';
import ConfirmationModal from './ConfirmationModal';
import MFAModal from './MFAModal';

export default function RequestScreenModals() {
  return (
    <>
      <ModalDef id="approve-request" component={ApproveModal} />
      <ModalDef id="reprove-request" component={ReproveModal} />
      <ModalDef id="reprocess-request" component={ReprocessModal} />
      <ModalDef id="approval-confirmed" component={ConfirmationModal} />
      <ModalDef id="mfa-request" component={MFAModal} />
    </>
  );
}
