import React from 'react';
import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { InfoCard } from '../../InfoCard';

export function Loading() {
  const skeletonList = [];

  for (let i = 0; i < 5; i += 1) {
    skeletonList.push(
      <InfoCard>
        <Skeleton style={{ height: '1.5rem', my: '0.5rem' }} />
        <Skeleton style={{ height: '1.5rem', my: '0.5rem' }} />
        <Skeleton style={{ height: '1.5rem', my: '0.5rem' }} />

        <Divider orientation="horizontal" />

        <Skeleton style={{ height: '1.5rem', my: '0.5rem' }} />
      </InfoCard>,
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {skeletonList}
    </Box>
  );
}
