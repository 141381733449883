import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Dialog,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import brazilianCurrencyFormatter from '../../../../../../../utils/brazilianCurrencyFormatter';
import formatOnlyNumbers from '../../../../../../../utils/formatOnlyNumbers';
import { changeEmployeeMargin } from '../../../utils/services';
import { handleSnackbarStack } from '../../../../../../../utils/handleSnackbarStack';

function EditModal({ open, onClose, onUpdate, selected }) {
  const { currentCompany } = useSelector(state => state.companies);

  const [isLoading, toggleLoading] = useReducer(old => !old, false);

  const [salary, setSalary] = useState();
  const [margin, setMargin] = useState();

  const errorCurrencyValue = useCallback(
    currency => {
      if (!selected) return;

      if (currency > parseFloat(selected.assignableMargin)) {
        return (
          <Typography className={styles.currencyError}>
            {`Digite um valor no máximo até ${brazilianCurrencyFormatter(
              selected.assignableMargin,
            )}`}
          </Typography>
        );
      }
    },
    [selected],
  );

  const handleInputChange = useCallback(
    input => e => {
      const { value } = e.target;

      const parsedValue = formatOnlyNumbers(value) / 100;

      switch (input) {
        case 'salary':
          setSalary(parsedValue);
          return;
        case 'margin':
          setMargin(parsedValue);
      }
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    toggleLoading();

    try {
      await changeEmployeeMargin(
        {
          idEmployee: selected.id,
          salary,
          assignableMargin: margin,
        },
        currentCompany.cnpj,
      );

      onClose();
      onUpdate();

      handleSnackbarStack().success('Margem alterada com sucesso');
    } catch (err) {
      const { errorDetail } = err.response.data?.errors[0];

      handleSnackbarStack().error(errorDetail);
    }

    toggleLoading();
  }, [salary, selected, margin, currentCompany]);

  useEffect(() => {
    if (selected) {
      setSalary(selected.salary);
      setMargin(selected.assignableMargin);
    }
  }, [selected]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className={styles.container}>
        <Box mt={0}>
          <Typography className={styles.title}>
            Editar margem consignável
          </Typography>
        </Box>

        <Typography className={styles.text}>
          Preencha os campos abaixo para editar a margem consignável do
          colaborador
        </Typography>

        <FormControl>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
          >
            <Grid item sm={12}>
              <TextField
                variant="outlined"
                placeholder="Salário"
                value={brazilianCurrencyFormatter(salary || 0)}
                onChange={handleInputChange('salary')}
                inputProps={{
                  maxLength: 13,
                  minLength: 9,
                }}
                disabled={isLoading}
                required
                fullWidth
                label="Salário"
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                variant="outlined"
                placeholder="Margem consignável"
                value={brazilianCurrencyFormatter(margin || 0)}
                onChange={handleInputChange('margin')}
                inputProps={{
                  maxLength: 13,
                  minLength: 9,
                }}
                disabled={isLoading}
                required
                fullWidth
                error={!margin || margin > selected.assignableMargin}
                label="Margem consignável"
              />
              {errorCurrencyValue(margin)}
            </Grid>
          </Grid>
        </FormControl>

        <div className={styles.buttonsGroup}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={styles.closeButton}
          >
            Cancelar
          </Button>

          <Button
            onClick={handleSubmit}
            className={styles.nextButton}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress className={styles.loader} />
            ) : (
              <Typography className={styles.confirmButtonText}>
                Editar
              </Typography>
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default EditModal;
