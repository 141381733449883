import React from 'react';
import { TextField } from '@material-ui/core';
import { useNumericField } from './Hooks/useNumericField';

export function NumericField({
  fullWidth = true,
  name = '',
  label = '',
  size = 'small',
  variant = 'outlined',
  disabled = false,
  returnParsed = false,
  prefix = '',
  hasThousandSeparator = false,
  isFloat = false,
  minValue = 0,
  maxValue = Number.MAX_SAFE_INTEGER,
  precision = 21,
  value = '',
  onChange = () => {},
  ...otherProps
}) {
  const { numericStringValue, setNumericStringValue } = useNumericField({
    returnParsed,
    prefix,
    isFloat,
    hasThousandSeparator,
    minValue,
    maxValue,
    precision,
    value,
    onChange,
  });

  return (
    <TextField
      fullWidth={fullWidth}
      name={name}
      label={label}
      size={size}
      variant={variant}
      disabled={disabled}
      value={numericStringValue}
      onChange={event => setNumericStringValue(event.target.value)}
      InputProps={{ style: { borderRadius: '0.5rem' } }}
      {...otherProps}
    />
  );
}
