import React from 'react';

import { Table } from '../../../../../../components/Table';
import { Filter } from '../../../../../../components/Filter';
import Form from '../../../../../../components/Form';

import { useTableConfig } from './Hooks/useTableConfig';
import { useApprovedEmployees } from './Hooks/useApprovedEmployees';

export default function ApprovedEmployeesTab() {
  const {
    filter,
    meta: { locationsOptions, statusOptions },
    table,
  } = useApprovedEmployees();

  const tableConfig = useTableConfig();

  return (
    <>
      <Filter {...filter}>
        <Form.MaskedField
          name="nameOrCpf"
          label="Pesquisar por nome ou CPF"
          mask={[
            {
              mask: '000.000.000-00',
            },
            {
              mask: /\D/g,
            },
          ]}
        />
        <Form.NumericField name="registration" label="Matrícula" />
        <Form.SelectField
          label="Centro de custo"
          name="location"
          options={locationsOptions}
          getOptionLabel={({ description }) => description}
          getOptionValue={({ code }) => code}
        />
        <Form.SelectField
          name="status"
          label="Situação"
          options={statusOptions}
        />
      </Filter>

      <Table
        hasPagination
        identifier="cpf"
        tableConfig={tableConfig}
        {...table}
      />
    </>
  );
}
