import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Paper,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import FakeTable from './FakeTable';
import NoUsers from './NoUsers';

import { api } from '../../../services/api';

import styles from './styles.module.css';

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.labelHead}>Nome</TableCell>
        <TableCell className={styles.labelHead}>Email</TableCell>
        <TableCell className={styles.labelHead}>Data de criação</TableCell>
        <TableCell className={styles.labelHead}>Status</TableCell>
        <TableCell className={styles.labelHead} />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  loadingFilter,
  usersList,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleClickNewUser,
  viewDetails,
  anchorEl,
  handleClickOption,
  handleClickMenu,
  handleClose,
  totalSize,
}) {
  if (usersList.length === 0) {
    return <NoUsers onClick={handleClickNewUser} />;
  }

  if (loadingFilter) {
    return <FakeTable />;
  }

  return (
    <Paper className={styles.tableContainer}>
      <div>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead />
            <TableBody>
              {usersList?.map(row => {
                return (
                  <>
                    <TableRow hover tabIndex={-1} key={row.email}>
                      <TableCell className={styles.tableCell}>
                        {row.name}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {row.email}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {row.createDate}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {row.status}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Visualizar detalhes">
                          <IconButton onClick={() => viewDetails(row.cpf)}>
                            <VisibilityOutlinedIcon
                              className={styles.fileIcon}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Mais opções">
                          <IconButton
                            onClick={e => handleClickMenu(e, row.cpf)}
                          >
                            <MoreVertIcon className={styles.fileIcon} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => handleClickOption(1)}>
                  Resetar senha
                </MenuItem>
                <MenuItem onClick={() => handleClickOption(2)}>
                  Excluir
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  );
}
