import React from 'react';
import styles from './styles.module.css';
import {
  Button,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconSuccess } from '../../../../assets/check-circle-big-green.svg';
import { ReactComponent as FirstCardIcon } from '../../../../assets/card_giftcard@2x.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/people_outline.svg';

function SuccessRegister() {
  const history = useHistory();

  const goToManagerCollaborators = () => history.push('/employees');

  return (
    <div className={styles.container}>
        <div>
            <Typography className={styles.textBigger}>Cadastrar via planilha do Excel</Typography>
        </div>
        <div className={styles.content}>
          <IconSuccess className={styles.iconSuccess} />
          <Typography className={styles.textMediumGreen}>
            Funcionários cadastrados com sucesso!
          </Typography>
          <Typography className={styles.textMedium}>
            Comunique aos funcionários que seus cartões já estão a caminho!
          </Typography>
          <div className={styles.cardsContainerButtons}>
            <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <FirstCardIcon />
                <Typography className={styles.number}>18</Typography>
                <Typography className={styles.label}>Novas Contas</Typography>
              </div>
              <div className={styles.card}>
                <PeopleIcon className={styles.peopleIcon} />
                <Typography className={styles.number}>3</Typography>
                <Typography className={styles.label}>Contas Viculadas</Typography>
              </div>
            </div>
            <div>
              <Button
                className={styles.buttonGoToCollaborators}
                onClick={goToManagerCollaborators}
              >
                Ir para gerenciar colaboradores
              </Button>
            </div>
        </div>
        </div>
    </div>
  );
}

export default SuccessRegister;
