import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function FakeInfo() {
  return (
    <>
      <Skeleton width={220} height={15} color="#202020" highlightColor="#444" />
      <br />
      <Skeleton width={280} height={15} color="#202020" highlightColor="#444" />
      <br />
      <Skeleton width={280} height={15} color="#202020" highlightColor="#444" />
      <br />
      <Skeleton width={280} height={15} color="#202020" highlightColor="#444" />
    </>
  );
}
