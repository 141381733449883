import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Add, Info, Remove } from '@material-ui/icons';
import CardTools from '../../../../../../../components/CardTools';
import Buttons from '../../../../../../../components/Buttons';

export function ErrorCardHarder({ generalData = {}, accordionsObject = {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <CardTools.CardInfo
            orientation="row"
            isReverse
            title="Linhas com erro:"
            description={generalData?.errorLinesQtt}
            titleStyle={{ fontSize: '20px' }}
            descriptionStyle={{ fontSize: '20px' }}
          />
          <CardTools.CardInfo
            orientation="row"
            isReverse
            title="Total de erros:"
            description={generalData?.totalErrors}
            titleStyle={{ fontSize: '20px' }}
            descriptionStyle={{ fontSize: '20px' }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Info style={{ color: '#F19207' }} />

          <Typography variant="subtitle2">
            Corrija os erros abaixo no que foi enviado ou clique no ícone de
            lápis ao lado do motivo do erro. Após corrigir, reenvie o arquivo
            com os problemas corrigidos.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Buttons.SecondaryButton
          icon={
            accordionsObject.currentAccordion !== 'all' ? <Add /> : <Remove />
          }
          color="primary"
          title={
            accordionsObject.currentAccordion !== 'all'
              ? 'Expandir tudo'
              : 'Recolher tudo'
          }
          onClick={() =>
            accordionsObject?.handleExpandCurrentAccordion(
              accordionsObject.currentAccordion !== 'all' ? 'all' : '',
            )
          }
        />
      </Box>
    </Box>
  );
}
