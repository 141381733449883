import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { ActionsManualPayroll } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

import { useInsertBankData } from '../../InsertBankDataDrawer/Hooks/useInsertBankData';
import formatCPF from '../../../../../../../../../../utils/formatCPF';

export const useTableConfig = () => {
  const dispatch = useDispatch();
  const { insertBankDataDrawer, banksList } = useInsertBankData();

  const handleInsertBankData = row => {
    insertBankDataDrawer.handleOpen();
    dispatch(
      ActionsManualPayroll.setSelectedEmployeeToInsertBankData({
        ...row,
        employeeType: 'PENDING',
      }),
    );
  };

  const handleFindBankName = bankCode => {
    const convertedBankCodeToInteger = parseInt(bankCode);

    return banksList?.data?.find(
      bank => bank?.code === convertedBankCodeToInteger,
    )?.name;
  };

  const handleConcatAgencyWithDigit = row =>
    `${row?.agency}-${row?.agencyDigit}`;

  const handleConcatAccountWithDigit = row =>
    `${row?.account}-${row?.accountDigit}`;

  const tableConfig = [
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      align: 'left',
      render: row => row?.name ?? '-',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      align: 'left',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'bank',
      type: 'field',
      label: 'Banco',
      align: 'left',
      render: row => (row?.bank ? handleFindBankName(row?.bank) : '-'),
    },
    {
      key: 'agency',
      type: 'field',
      label: 'Agência',
      align: 'left',
      render: row =>
        row?.agency && row?.agencyDigit
          ? handleConcatAgencyWithDigit(row)
          : '-',
    },
    {
      key: 'account',
      type: 'field',
      label: 'Conta',
      align: 'left',
      render: row =>
        row?.account && row?.accountDigit
          ? handleConcatAccountWithDigit(row)
          : '-',
    },
    {
      key: 'actions',
      type: 'field',
      label: '',
      align: 'center',
      render: row => (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {!row.hasBankData && (
            <Tooltip
              arrow
              title="Não é possível selecionar um colaborador sem dados bancários. Clique para inseri-los."
            >
              <IconButton
                color="primary"
                disabled={row.hasBankData}
                onClick={() => handleInsertBankData(row)}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return {
    tableConfig,
  };
};
