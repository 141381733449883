import React from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { MyPayments, OtherData, TopPayments } from './UI';
import Extract from '../Extract';
import Activities from '../Activities';
import { isMobile } from '../../../../utils/breakpoints';

import styles from './styles.module.css';

function PaymentChartDashboard({
  activeButton,
  onButtonFilterDashboard,
  openMenuOptions,
  setOpenMenuOptions,
  openMenuEvent,
  setOpenMenuEvent,
  initialDateDash,
  finalDateDash,
  onChangeInitialDateDash,
  onChangeFinalDateDash,
  onButtonFilterMounthInDays,
  activeTab,
  handleChangeTab,
  mounthPayments,
  totalPayments,
  loadingDashPayments,
  onHandleCleanFilters,
  textErrorDashPayments,
  anotherData,
  loadingAnotherData,
  initialDateAnotherData,
  finalDateAnotherData,
  onChangeFinalDateAnotherData,
  onChangeInitialDateAnotherData,
  openMenuEventAnotherData,
  setOpenMenuEventAnotherData,
  onButtonFilterAnotherData,
  animationChartPayment,
  setAnimationChartPayment,
  noContentPayments,
  topFiveAmounts,
  topFive,
  noContentTopFive,
  loadingTopFive,
  textErrorTopFive,
  yearMouthTopFive,
  onChangeYearMouthTopFive,
  openMenuEventTopFive,
  setOpenMenuEventTopFive,
  onButtonFilterTopFive,
  animationTopFive,
  setAnimationTopFive,
  setLastDays,
  setLastMonths,
  lastYearMouthTopFive,
  setYearMouthTopFive,
}) {
  return (
    <div className={styles.container}>
      {isMobile && (
        <AppBar elevation={0} position="static" className={styles.appBar}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            className={styles.tabs}
          >
            <Tab className={styles.tab} label="Gráficos" />
            <Tab className={styles.tab} label="Atividades" />
            <Tab className={styles.tab} label="Extrato" />
          </Tabs>
        </AppBar>
      )}
      <div className={styles.containerGraphics}>
        {activeTab === 0 && (
          <>
            <MyPayments
              activeButton={activeButton}
              onButtonFilterDashboard={onButtonFilterDashboard}
              openMenuOptions={openMenuOptions}
              setOpenMenuOptions={setOpenMenuOptions}
              setOpenMenuEvent={setOpenMenuEvent}
              openMenuEvent={openMenuEvent}
              initialDateDash={initialDateDash}
              finalDateDash={finalDateDash}
              onChangeInitialDateDash={onChangeInitialDateDash}
              onChangeFinalDateDash={onChangeFinalDateDash}
              onButtonFilterMounthInDays={onButtonFilterMounthInDays}
              mounthPayments={mounthPayments}
              totalPayments={totalPayments}
              loadingDashPayments={loadingDashPayments}
              onHandleCleanFilters={onHandleCleanFilters}
              textErrorDashPayments={textErrorDashPayments}
              animationChartPayment={animationChartPayment}
              setAnimationChartPayment={setAnimationChartPayment}
              noContentPayments={noContentPayments}
              setAnimationTopFive={setAnimationTopFive}
              setLastDays={setLastDays}
              setLastMonths={setLastMonths}
            />
            <div className={styles.content}>
              <TopPayments
                topFiveAmounts={topFiveAmounts}
                topFive={topFive}
                noContentTopFive={noContentTopFive}
                loadingTopFive={loadingTopFive}
                textErrorTopFive={textErrorTopFive}
                onHandleCleanFilters={onHandleCleanFilters}
                yearMouthTopFive={yearMouthTopFive}
                onChangeYearMouthTopFive={onChangeYearMouthTopFive}
                openMenuEventTopFive={openMenuEventTopFive}
                setOpenMenuEventTopFive={setOpenMenuEventTopFive}
                onButtonFilterTopFive={onButtonFilterTopFive}
                setAnimationChartPayment={setAnimationChartPayment}
                animationTopFive={animationTopFive}
                setAnimationTopFive={setAnimationTopFive}
                lastYearMouthTopFive={lastYearMouthTopFive}
                setYearMouthTopFive={setYearMouthTopFive}
              />
              <OtherData
                anotherData={anotherData}
                loadingAnotherData={loadingAnotherData}
                initialDateAnotherData={initialDateAnotherData}
                finalDateAnotherData={finalDateAnotherData}
                onChangeFinalDateAnotherData={onChangeFinalDateAnotherData}
                onChangeInitialDateAnotherData={onChangeInitialDateAnotherData}
                openMenuEventAnotherData={openMenuEventAnotherData}
                setOpenMenuEventAnotherData={setOpenMenuEventAnotherData}
                onButtonFilterAnotherData={onButtonFilterAnotherData}
                onHandleCleanFilters={onHandleCleanFilters}
                setAnimationChartPayment={setAnimationChartPayment}
                setAnimationTopFive={setAnimationTopFive}
              />
            </div>
          </>
        )}

        {activeTab === 1 && <Activities />}

        {activeTab === 2 && <Extract />}
      </div>
    </div>
  );
}

export default PaymentChartDashboard;
