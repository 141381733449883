import React from 'react';
import styles from '../styles.module.css';
import {
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

function EnhancedTableBody({
  page,
  rows,
  rowsPerPage
}) {
  return (
    <TableBody>
      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
        <TableRow
          hover
          key={row.id}
          className={styles.tableRow}
        >
          <TableCell className={styles.tableCell}>{row.name}</TableCell>
          <TableCell className={styles.tableCell}>{row.cpf}</TableCell>
          <TableCell className={styles.tableCell}>
            <div className={ row.situation === 'Vigente' ? styles.situationCell : styles.situationCellCanceled}>
              {row.situation}
            </div>
          </TableCell>
        </TableRow>
      ))
      }
    </TableBody>
  );
}

export default EnhancedTableBody;
