import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import styles from './styles.module.css';

export default function ModalErase({ setSelectedItem, setSnackbar }) {
  return (
    <Dialog open onClose={() => setSelectedItem(null)}>
      <DialogTitle className={styles.titleDialog} id="alert-dialog-title">
        {'Tem certeza que deseja remover este item?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ao remover este item, os colaboradores alocados a este Centro de
          Custo/Setor terão seus registros atualizados para para "Centro de
          Custo/Setor não especificado".
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.buttonAreaDialog}>
        <Button
          className={styles.cancelButtonDialog}
          onClick={() => setSelectedItem(null)}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          className={styles.removeButtonDialog}
          onClick={() => setSelectedItem(null)}
          color="primary"
          autoFocus
        >
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  );
}
