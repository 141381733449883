import React from 'react';
import moment from 'moment';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Typography,
  Grid,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';

import formatCPF from '../../../../../utils/formatCPF';

import { useStyles } from '../../../styles';

const IpDetailsModal = NiceModal.create(({ selected }) => {
  const modal = useModal();
  const styles = useStyles();

  return (
    <Dialog open={modal.visible} maxWidth="sm" onClose={modal.remove}>
      <DialogTitle>Detalhes de permissão de IP</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h2" className={styles.ipModalTitle}>
                Cadastrado por
              </Typography>
              <Typography variant="p" className={styles.ipModalBody}>
                {selected?.createdBy?.name || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2" className={styles.ipModalTitle}>
                CPF
              </Typography>
              <Typography variant="p" className={styles.ipModalBody}>
                {selected?.createdBy?.cpf
                  ? formatCPF(selected?.createdBy?.cpf)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2" className={styles.ipModalTitle}>
                IP da máquina
              </Typography>
              <Typography variant="p" className={styles.ipModalBody}>
                {selected?.ip}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2" className={styles.ipModalTitle}>
                Última atualização
              </Typography>
              <Typography variant="p" className={styles.ipModalBody}>
                {moment(selected?.createdAt).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.ipModalActions}>
        <Buttons.PrimaryButton
          color="secondary"
          onClick={modal.hide}
          title="Fechar"
        />
      </DialogActions>
    </Dialog>
  );
});

export default IpDetailsModal;
