import React from 'react';
import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Chip,
} from '@material-ui/core';
import { useStyles, theme } from './styles';

import { ReactComponent as DeleteIcon } from '../../../../assets/removeItem.svg';
import textCapitalize from '../../../../utils/textCapitalize';
import EnhancedTableHead from '../EnhancedTableHead';
import { FakeTable } from '../../../OnlinePointEmployees/UI/Table/UI';
import { handleStatus } from '../../Utils/handleStatus';
import { handleEmployeeStatus } from '../../Utils/handleEmployeeStatus';

const TableEmployeesList = ({
  loading,
  pageSize,
  totalSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  handleRequestSort,
  rows,
  stableSort,
  getComparator,
  TablePaginationActions,
  handleOpenModalDeleteEmployee,
}) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody>
            {!loading ? (
              stableSort(rows, getComparator).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell align="left">{row?.cpf}</TableCell>
                    <TableCell align="left">
                      {textCapitalize(row.name)}
                    </TableCell>
                    <TableCell align="left">
                      {textCapitalize(row.email)}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={handleEmployeeStatus(row.status).label}
                        style={{
                          backgroundColor: handleEmployeeStatus(row.status)
                            .color,
                          color: '#FFFFFF',
                        }}
                      />
                    </TableCell>
                    <TableCell align="right" className={classes.defaultCell}>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {row?.status === 'ACTIVE' ? (
                          <IconButton
                            onClick={() =>
                              handleOpenModalDeleteEmployee(row.cpf)
                            }
                          >
                            <Tooltip title="Desabilitar colaborador do beeVale">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <FakeTable />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};

export default TableEmployeesList;
