import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { previousMonday } from 'date-fns';
import { ActionsImportFilePayrollErrors } from '../../../../redux/store/Payroll/FilePayrollErrors';

export function usePayrollErrors() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { fileErrorsGeneral, fileErrorsFixing } = useSelector(
    state => state.payroll.importFilePayrollErrors,
  );

  const [isOpenWarningModal, setIsOpenWarningModal] = useState(true);
  const [isOpenAddBankDataModal, setIsOpenAddBankDataModal] = useState(false);
  const [employeeToEditBankData, setEmployeeToEditBankData] = useState({});
  const [snackbarData, setSnackbarData] = useState(null);

  function handleGoBackToImportFileScreen() {
    history.push('/payroll/menu/info-payroll/register-batch');
  }

  function handleEditEmployeeBankData(data) {
    setEmployeeToEditBankData(data?.employeeData);

    dispatch(
      ActionsImportFilePayrollErrors.setEmployeeNewBankData(
        data?.employeeIdentification,
      ),
    );
    setIsOpenAddBankDataModal(true);
  }

  function handleAddBankDataSubmit(data) {
    dispatch(
      ActionsImportFilePayrollErrors.setEmployeeNewBankData({
        ...fileErrorsFixing.employeeNewBankData,
        ...data,
      }),
    );
    dispatch(ActionsImportFilePayrollErrors.patchEmployeeBankData());
  }

  function handleCloseModal() {
    setIsOpenAddBankDataModal(false);
  }

  function handleOnSendBankDataSuccess() {
    setSnackbarData({
      open: true,
      message: fileErrorsFixing?.successOn?.message,
    });

    setTimeout(() => {
      setSnackbarData(prev => {
        return { ...prev, open: false };
      });
      setTimeout(() => {
        setSnackbarData(null);
      }, 1000);
    }, 4000);
  }

  useEffect(() => {
    dispatch(ActionsImportFilePayrollErrors.getBankOptions());
  }, []);

  useEffect(() => {
    if (fileErrorsFixing?.successOn?.hasSuccess) {
      handleCloseModal();
      handleOnSendBankDataSuccess();
    }
  }, [fileErrorsFixing?.successOn]);

  return {
    errorsObject: {
      generalData: {
        errorLinesQtt: fileErrorsGeneral?.fileErrorsData?.errorLinesQtt,
        totalErrors: fileErrorsGeneral?.fileErrorsData?.totalErrors,
      },
      errorsList: fileErrorsGeneral?.fileErrorsData?.errorsList,
      isFileXls: fileErrorsGeneral?.isFileXls,
      handleEditEmployeeBankData,
    },
    warningModalObject: {
      isOpenWarningModal,
      setIsOpenWarningModal,
    },
    addBankDataModalObject: {
      employeeToEditBankData,
      isOpenAddBankDataModal,
      setEmployeeToEditBankData,
      addBankDataFormDefaultValues: {
        bank: '',
        accountType: '',
        agencyDigit: 0,
        account: 0,
        accountDigit: 0,
      },
      handleCloseModal,
      handleAddBankDataSubmit,
    },
    bottomBarObject: {
      handleGoBackToImportFileScreen,
    },
    snackbarObject: snackbarData,
  };
}
