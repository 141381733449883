import React from 'react';

export default function Header() {
  return (
    <div>
      <h1>
        Contra cheques
      </h1>
    </div>
  )
}
