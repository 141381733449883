import React, { useState } from 'react';
import { Avatar, Box, Card, Tooltip, Typography } from '@material-ui/core';
import {
  BusinessRounded,
  CheckRounded,
  DeleteOutlineRounded,
  FileCopyOutlined,
  MailOutlineRounded,
  SmartphoneRounded,
  VpnKeyOutlined,
} from '@material-ui/icons';

import Buttons from '../../../../components/Buttons';
import { ChipStatus } from '../../../../components/ChipStatus';

import formatCnpj from '../../../../utils/formatCnpj';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';
import { KEY_LABELS, KEY_STATUSES } from '../../constants';

import { useStyles } from './styles';

export default function PixKeyItem({ entry, onDelete }) {
  const styles = useStyles();
  const { success } = handleSnackbarStack();

  const [copyIcon, setCopyIcon] = useState(
    <FileCopyOutlined color="inherit" />,
  );

  const onCopyKey = () => {
    navigator.clipboard.writeText(entry?.key);
    success('Chave copiada!');
    setCopyIcon(<CheckRounded color="success" />);

    setTimeout(() => {
      setCopyIcon(<FileCopyOutlined color="inherit" />);
    }, 4000);
  };

  const renderKeyTypeIcon = () => {
    switch (entry?.type) {
      case 'PHONE':
        return <SmartphoneRounded color="primary" />;
      case 'EMAIL':
        return <MailOutlineRounded color="primary" />;
      case 'EVP':
        return <VpnKeyOutlined color="primary" />;
      case 'CNPJ':
        return <BusinessRounded color="primary" />;
      default:
        return <VpnKeyOutlined color="primary" />;
    }
  };

  const renderStatus = () => {
    if (!entry?.status) return '-';

    return (
      <ChipStatus
        variant="outlined"
        size="small"
        {...KEY_STATUSES[entry?.status]}
      />
    );
  };

  const formattedKey =
    entry?.type === 'CNPJ' ? formatCnpj(entry?.key) : entry?.key;

  return (
    <Card role="listitem" className={styles.root}>
      <Box className={styles.avatarContainer}>
        <Avatar variant="rounded" className={styles.avatar}>
          {renderKeyTypeIcon()}
        </Avatar>

        <Box className={styles.infoContainer}>
          <Typography variant="caption" className={styles.label}>
            {KEY_LABELS[entry?.type] || 'Chave'}
          </Typography>
          <Tooltip title={formattedKey}>
            <Typography className={styles.content}>{formattedKey}</Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box className={styles.infoContainer}>
        <Typography variant="caption" className={styles.label}>
          Data e hora
        </Typography>
        <Typography className={styles.content}>
          {entry?.createdAt || '-'}
        </Typography>
      </Box>
      <Box className={styles.infoContainer}>
        <Typography variant="caption" className={styles.label}>
          Criado por
        </Typography>
        <Typography className={styles.content}>
          {entry?.createdBy || '-'}
        </Typography>
      </Box>
      <Box className={styles.infoContainer}>
        <Typography variant="caption" className={styles.label}>
          Status
        </Typography>
        {renderStatus()}
      </Box>
      <Box>
        <Buttons.IconButton
          icon={copyIcon}
          onClick={onCopyKey}
          tooltipTitle="Copiar chave"
        />
        <Buttons.IconButton
          tooltipTitle="Excluir chave"
          icon={<DeleteOutlineRounded color="inherit" />}
          onClick={() => onDelete(entry)}
        />
      </Box>
    </Card>
  );
}
