import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

export function RoundedButton({
  icon = null,
  title = '',
  options = [],
  disabled = false,
  loading = false,
  disabledTooltipText = '',
  children,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Tooltip arrow title={disabled ? disabledTooltipText : ''}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          startIcon={icon}
          onClick={event => setAnchorEl(event.currentTarget)}
          disabled={disabled || loading}
          loading={loading}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50px',
            textTransform: 'none',
            opacity: disabled ? 0.3 : 1,
          }}
        >
          {loading && (
            <CircularProgress size={16} style={{ position: 'absolute' }} />
          )}
          <Typography
            style={{
              visibility: loading ? 'hidden' : 'visible',
            }}
          >
            {capitalizeFirstLetter(title)}
          </Typography>
        </Button>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ style: { borderRadius: '0.5rem' } }}
      >
        {options?.map(option => (
          <MenuItem onClick={option?.onClick} disabled={option?.disabled}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {option?.icon}
              {option?.title}
            </Box>
          </MenuItem>
        ))}

        {children}
      </Menu>
    </>
  );
}
