/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../services/api';

const initialState = {
  isActivePlanPointOnlineLoading: false,
  errorOnActivePlanPointOnline: {
    hasError: false,
    message: '',
  },

  stepToActivePlanModal: 'one',
  emailNfe: '',
};

const activePlanPointOnline = createAsyncThunk(
  'activePlanPointOnline/post',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const requestBody = {
      chargeEmail: payload.chargeEmail,
      accessEmail: payload.accessEmail,
    };

    return api
      .post(`/pontoGO/registerCompany/${currentCompany.code}`, requestBody)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const pointOnlineSlice = createSlice({
  name: 'pointOnline',
  initialState,
  reducers: {
    cleanup: state => {
      Object.keys(initialState).forEach(item => {
        state[item] = initialState[item];
      });
    },
    setAccessEmail: (state, { payload }) => {
      state.accessEmail = payload;
    },
    setChargeEmail: (state, { payload }) => {
      state.chargeEmail = payload;
    },
    setStepToActivePlanModal: (state, { payload }) => {
      state.stepToActivePlanModal = payload;
    },
    setResetErrorOnActivePlanPointOnline: (state, { payload }) => {
      state.errorOnActivePlanPointOnline = payload;
    },
    setEmailNfe: (state, { payload }) => {
      state.emailNfe = payload;
    },
  },
  extraReducers: {
    [activePlanPointOnline.pending]: state => {
      state.isActivePlanPointOnlineLoading = true;
      state.errorOnActivePlanPointOnline =
        initialState.errorOnActivePlanPointOnline;
    },
    [activePlanPointOnline.fulfilled]: (state, { payload }) => {
      state.isActivePlanPointOnlineLoading = false;
      state.stepToActivePlanModal = 'success';
    },
    [activePlanPointOnline.rejected]: (state, { payload }) => {
      state.isActivePlanPointOnlineLoading = false;
      if (!payload) {
        state.errorOnActivePlanPointOnline = {
          hasError: true,
          message:
            'Houve um erro ao ativar o plano. Por favor, tente novamente mais tarde.',
        };
      } else {
        state.errorOnActivePlanPointOnline = {
          hasError: true,
          message: payload.error,
        };
      }
    },
  },
});

const pointOnlineReducer = pointOnlineSlice.reducer;
const ActionsPointOnline = {
  ...pointOnlineSlice.actions,
  activePlanPointOnline,
};

export { pointOnlineReducer, ActionsPointOnline };
