import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import ConfirmCommonPixKeyModal from './ConfirmCommonKeyModal';
import ConfirmUniquePixKeyModal from './ConfirmUniqueKeyModal';
import DeletePixKeyModal from './DeletePixKeyModal';

export default function PixKeysModals() {
  return (
    <>
      <ModalDef
        id="confirm-common-key-modal"
        component={ConfirmCommonPixKeyModal}
      />
      <ModalDef
        id="confirm-unique-key-modal"
        component={ConfirmUniquePixKeyModal}
      />
      <ModalDef id="delete-key-modal" component={DeletePixKeyModal} />
    </>
  );
}
