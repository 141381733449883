import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Typography } from '@material-ui/core';

import { ReactComponent as PersonIcon } from '../../../assets/personIcon.svg';

import styles from './styles.module.css';
import { isMobile } from '../../../utils/breakpoints';
import capitalize from '../../../utils/textCapitalize';

export default function Header({ loading, headerData }) {
	const { name, dateCreationEmployee, stocking, locatedAt } = headerData;

	return (
		<div classname={styles.headerContainer}>
			<h1>
				Dados do segurado
			</h1>

			<div className={styles.informations}>
				<div className={styles.avatar}>
					<PersonIcon />
					<Typography className={styles.responsiveInformationsTitle}>
						{capitalize(name)}
					</Typography>
				</div>

				<div className={styles.informationsContainer}>
					{loading ? (
						<>
							<Skeleton 
								width={220}
								height={15}
								color="#202020" 
								highlightColor="#444" 
							/>
							<Skeleton 
								width={280}
								height={15}
								color="#202020" 
								highlightColor="#444" 
							/>
							<Skeleton 
								width={280}
								height={15}
								color="#202020" 
								highlightColor="#444" 
							/>
							<Skeleton 
								width={280}
								height={15}
								color="#202020" 
								highlightColor="#444" 
							/>
						</>
					) : (
						<>
							<Typography className={styles.informationsTitle}>
								{capitalize(name)}
							</Typography>
							
							<div>
								<div className={styles.informationsGrid}>
									<span>
										{isMobile ? 'C. de Custo' : 'Centro de Custo'}
									</span>
									<p>{locatedAt || 'Não cadastrado'}</p>
								</div>

								<div className={styles.informationsGrid}>
									<span>Setor</span>
									<p>{stocking || 'Não cadastrado'}</p>
								</div>

								<div className={styles.informationsGrid}>
									<span>
										{isMobile ? 'D. de cadastro' : 'Data de cadastro'}
									</span>
									<p>
										{dateCreationEmployee ? 
										`${dateCreationEmployee.substring(0, 10)}` 
										: ''}
									</p>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
