import React from 'react';
import {
  Button,
  Box,
  Typography,
  Dialog,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
} from '@material-ui/core';

import styles from './styles.module.css';
import brazilianCurrencyFormatter from '../../../../../../utils/brazilianCurrencyFormatter';

function EditModal({
  open,
  onCloseDialog,
  title,
  text,
  closeButtonText,
  image,
  nextButton,
  onNextButtonClicked,
  nextButtonText,
  outlinedButton,
  outlinedButtonClicked,
  onClose,
  titleMarginTop,
  titleMarginBottom,
  loading,
  handleEditComment,
  handleSelectedProposeAmount,
  handleSelectedProposeInstallmentNumber,
  editComment,
  selectedPropose,
  disableNextButton,
  changedEditComment,
}) {
  const MIN_REQUESTED_AMOUNT = 500;
  const MAX_REQUESTED_AMOUNT = 100000;

  const errorCurrencyValue = currency => {
    if (currency < parseFloat(MIN_REQUESTED_AMOUNT) && currency !== 0) {
      return (
        <Typography className={styles.currencyError}>
          {`Digite um valor à partir de ${brazilianCurrencyFormatter(
            MIN_REQUESTED_AMOUNT,
          )}`}
        </Typography>
      );
    }

    if (currency > parseFloat(MAX_REQUESTED_AMOUNT)) {
      return (
        <Typography className={styles.currencyError}>
          {`Digite um valor no máximo até ${brazilianCurrencyFormatter(
            MAX_REQUESTED_AMOUNT,
          )}`}
        </Typography>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <div className={styles.container}>
        <Box mt={titleMarginTop} mb={titleMarginBottom}>
          <Typography className={styles.title}>{title}</Typography>
        </Box>

        <Typography
          className={styles.text}
          style={{ display: text ? '' : 'none' }}
        >
          {text}
        </Typography>

        <FormControl>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
          >
            <Grid item sm={6}>
              <TextField
                variant="outlined"
                placeholder="Valor solicitado"
                value={brazilianCurrencyFormatter(
                  selectedPropose?.principalAmount || 0,
                )}
                onChange={handleSelectedProposeAmount}
                inputProps={{
                  maxLength: 13,
                  minLength: 9,
                }}
                disabled={loading}
                required
                fullWidth
                error={
                  !selectedPropose?.principalAmount ||
                  selectedPropose?.principalAmount < MIN_REQUESTED_AMOUNT ||
                  selectedPropose?.principalAmount > MAX_REQUESTED_AMOUNT
                }
                label="Valor solicitado"
              />
              {errorCurrencyValue(selectedPropose?.principalAmount)}
            </Grid>

            <Grid item sm={6}>
              <TextField
                type="number"
                className={styles.editTxField}
                onChange={handleSelectedProposeInstallmentNumber}
                value={selectedPropose?.installmentsNumber}
                placeholder="Quant. parcelas"
                label="Quant. parcelas"
                variant="outlined"
                required
                error={!selectedPropose?.installmentsNumber}
              />
            </Grid>
          </Grid>

          <TextField
            type="text"
            className={styles.editTxField}
            onChange={handleEditComment}
            value={editComment}
            placeholder="Comentário"
            label="Comentário"
            helperText="Favor preencher o motivo da edição"
            variant="outlined"
            multiline
            required
            error={changedEditComment && !editComment}
            maxRows={5}
            inputProps={{ maxLength: 150 }}
          />
        </FormControl>

        <div
          style={{ marginTop: text ? '' : '30px' }}
          className={styles.buttonsGroup}
        >
          <Button
            disabled={loading}
            onClick={outlinedButton ? outlinedButtonClicked : onCloseDialog}
            className={
              outlinedButton ? styles.outlinedButton : styles.closeButton
            }
          >
            {closeButtonText || 'Fechar'}
          </Button>

          {nextButton && (
            <Button
              onClick={() => onNextButtonClicked(selectedPropose)}
              className={styles.nextButton}
              disabled={disableNextButton}
            >
              {loading ? (
                <CircularProgress className={styles.loader} />
              ) : (
                <Typography className={styles.confirmButtonText}>
                  {nextButtonText}
                </Typography>
              )}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default EditModal;
