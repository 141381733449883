import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';

import { AccessActions as Actions } from '../../../../../redux/store/AccessSettings';

import { SettingsContext } from '../../../contexts';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

const SUCCESS_MESSAGE =
  'Alterações salvas com sucesso! Em 48hs as alterações serão aplicadas.';

export default function useAllowedIp({ id }) {
  const dispatch = useDispatch();
  const { error, success } = handleSnackbarStack();
  const { handleExpand, isAdmin } = useContext(SettingsContext);

  const [selected, setSelected] = useState(null);

  const {
    isSending,
    isLoading,
    changeOnIp,
    currentConfig: {
      isIpRestricted,
      ipRestrictionScheduled,
      companyAllowedIps,
    },
  } = useSelector(state => state.access);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { isIpRestricted },
  });

  const onSubmit = async form => {
    const actionResult = await dispatch(Actions.setAllowedIps(form));

    try {
      unwrapResult(actionResult);
      success(SUCCESS_MESSAGE);
      reset();
      handleExpand(false)();
    } catch (err) {
      error(err.message);
    }
  };

  const createIp = async form => {
    await dispatch(Actions.addIp({ ...form, status: 'TO_BE_CREATED' }));
    NiceModal.remove('ip-create-modal');
  };

  const deleteIp = () => {
    dispatch(Actions.setDeletedIps(selected.id));
    NiceModal.remove('ip-delete-modal');
  };

  const onOpenDetails = () => {
    NiceModal.show('ip-details-modal', { selected });
  };

  const onOpenDelete = () => {
    NiceModal.show('ip-delete-modal', { selected, onDelete: deleteIp });
  };

  const onOpenCreate = () => {
    NiceModal.show('ip-create-modal', { onCreate: createIp });
  };

  const handlePagination = (page, perPage) => {
    dispatch(Actions.setPagination({ page, perPage }));
  };

  useEffect(() => {
    reset({ isIpRestricted });
  }, [isIpRestricted]);

  return {
    data: companyAllowedIps,
    handlePagination,
    control,
    onSubmit: () => handleSubmit(onSubmit),
    onOpenDetails,
    onOpenDelete,
    onOpenCreate,
    selected,
    setSelected,
    isValid: isValid && isAdmin && changeOnIp,
    isSending,
    isLoading,
    restricted: isIpRestricted,
    hasSchedule: ipRestrictionScheduled,
    toggle: handleExpand(id),
  };
}
