import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ActionsManualPayroll } from '../../../../../../../../redux/store/Payroll/ManualPayroll';

import { insertBankDataYupSchema } from '../Helpers/yupSchema';

export function useInsertBankData() {
  const dispatch = useDispatch();
  const {
    banksList,
    isOpenInsertBankDataDialog,
    selectedEmployeeToInsertBankData,
    employeeBankData,
  } = useSelector(state => state.payroll.manualPayroll);

  function resetForm() {
    reset(initialValuesForm);
  }

  function handleOpenInsertBankDataDialog() {
    dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDialog(true));
  }

  function handleCloseInsertBankDataDialog() {
    dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDialog(false));
    resetForm();
  }

  const initialValuesForm = {
    bank: '',
    agency: '',
    agencyDigit: '',
    account: '',
    accountDigit: '',
    accountType: '',
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialValuesForm,
    resolver: yupResolver(insertBankDataYupSchema),
  });

  function onSubmit(data) {
    dispatch(
      ActionsManualPayroll.updateEmployeeBankData({
        id: selectedEmployeeToInsertBankData.id,
        employeeType: selectedEmployeeToInsertBankData.employeeType,
        account: data.account,
        accountDigit: data.accountDigit,
        agency: data.agency,
        agencyDigit: data.agencyDigit,
        bank: data.bank.code,
        accountType: data.accountType,
      }),
    );
  }

  useEffect(() => {
    if (employeeBankData.successOn.hasSuccess === true) {
      dispatch(ActionsManualPayroll.searchEmployees());
      dispatch(ActionsManualPayroll.searchEmployeesPending());
      dispatch(ActionsManualPayroll.searchEmployeesReproved());
      dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDialog(false));
      dispatch(ActionsManualPayroll.setSelectedEmployeeToInsertBankData({}));
    }
  }, [employeeBankData.successOn.hasSuccess]);

  return {
    banksList,
    insertBankDataDialog: {
      isOpen: isOpenInsertBankDataDialog,
      handleOpen: handleOpenInsertBankDataDialog,
      handleClose: handleCloseInsertBankDataDialog,
    },
    insertBankDataForm: {
      onSubmit,
      handleSubmit,
      reset,
      control,
      errors,
    },
    employeeName: selectedEmployeeToInsertBankData.name,
    employeeBankData,
  };
}
