import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  List,
  Typography
} from '@material-ui/core';

import styles from './styles.module.css';

function FakeInformations() {
  return (
    <div className={styles.cardsContainer}>
      <div className={styles.descriptionContainer}>
        <Skeleton
          height={27}
          width={380}
          color="#202020"
          highlightColor="#444"
          className={styles.descriptionTitle}
        />
        <Typography className={styles.descriptionText}>
          <List>
            <Skeleton
              height={17}
              width={350}
              color="#202020"
              highlightColor="#444"
            />
          </List>
          <List>
            <Skeleton
              height={17}
              width={350}
              color="#202020"
              highlightColor="#444"
            />
          </List>
          <List>
            <Skeleton
              height={15}
              width={350}
              color="#202020"
              highlightColor="#444"
            />
          </List>
          <List>
            <Skeleton
              height={15}
              width={350}
              color="#202020"
              highlightColor="#444"
            />
          </List>

          <div className={styles.divider} />
          <span>
              <Skeleton
                height={12}
                width={350}
                color="#202020"
                highlightColor="#444"
              />
            <br className={styles.breakLine} />
              <Skeleton
                height={12}
                width={310}
                color="#202020"
                highlightColor="#444"
              />
          </span>
        </Typography>
      </div>

      <div className={styles.contractTypeContainer}>
        <Typography className={styles.typeTitle}>
          <Skeleton
            height={23}
            width={150}
            color="#202020"
            highlightColor="#444"
          />
        </Typography>

        <Typography className={styles.typeSubtitle}>
          <Skeleton
            height={18}
            width={150}
            color="#202020"
            highlightColor="#444"
          />
        </Typography>

        <Typography className={styles.optionsTitle}>
          <Skeleton
            height={16}
            width={150}
            color="#202020"
            highlightColor="#444"
          />
        </Typography>

        <Typography className={styles.fontItems}>
          <List className={styles.listItems}>
            <Skeleton
              height={16}
              width={150}
              color="#202020"
              highlightColor="#444"
            />
          </List>
          <List className={styles.listItems}>
            <Skeleton
              height={16}
              width={150}
              color="#202020"
              highlightColor="#444"
            />
          </List>
          <List className={styles.listItems}>
            <Skeleton
              height={16}
              width={150}
              color="#202020"
              highlightColor="#444"
            />
          </List>
          <Typography className={styles.fontItems}>
            <List className={styles.listSubitems}>
              <Skeleton
                height={16}
                width={150}
                color="#202020"
                highlightColor="#444"
              />
            </List>
            <List className={styles.listSubitems}>
              <Skeleton
                height={16}
                width={150}
                color="#202020"
                highlightColor="#444"
              />
            </List>
            <List className={styles.listSubitems}>
              <Skeleton
                height={16}
                width={150}
                color="#202020"
                highlightColor="#444"
              />
            </List>
          </Typography>
        </Typography>

        <Typography className={styles.fontFooter}>
          <Skeleton
            height={13}
            width={150}
            color="#202020"
            highlightColor="#444"
          />
        </Typography>
      </div>
    </div>
  );
}

export default FakeInformations;
