import React from 'react';
import styles from '../styles.module.css';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

function EnhancedTableHead({ tableHeadData }) {
  return (
    <TableHead>
      <TableRow>
        {tableHeadData.map((item) => (
          <TableCell key={item.name} className={styles.tableHeaderLabel}>{item}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
