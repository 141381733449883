import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

import FakeInfo from './FakeInfo';

import { isMobile } from '../../utils/breakpoints';

import styles from './styles.module.css';

export default function FormProfile({
  name,
  avatar,
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  thirdLabel,
  thirdValue,
  situation,
  marginTop,
  marginBottom,
  loading,
  hidden,
  onClickSituation,
}) {
  function renderChipSituation(value) {
    switch (value) {
      case 'Ativo':
        return (
          <div className={styles.blueChip}>
            <CheckCircleRoundedIcon className={styles.chipIcon} />
            <Typography>Ativo</Typography>
            <ArrowDropDownRoundedIcon className={styles.arrowIcon} />
          </div>
        );
      case 'Demitido':
        return (
          <div className={styles.redChip}>
            <RemoveCircleRoundedIcon className={styles.chipIcon} />
            <Typography>Demitido</Typography>
            <ArrowDropDownRoundedIcon className={styles.arrowIcon} />
          </div>
        );
      case 'Férias':
        return (
          <div className={styles.greenChip}>
            <BeachAccessIcon className={styles.chipIcon} />
            <Typography>Férias</Typography>
            <ArrowDropDownRoundedIcon className={styles.arrowIcon} />
          </div>
        );
      case 'Licença INSS':
        return (
          <div className={styles.greenChip}>
            <DescriptionIcon className={styles.chipIcon} />
            <Typography>Licença INSS</Typography>
            <ArrowDropDownRoundedIcon className={styles.arrowIcon} />
          </div>
        );
      case 'Licença não remunerada':
        return (
          <div className={styles.greenChip}>
            <DescriptionIcon className={styles.chipIcon} />
            <Typography>Licença não remunerada</Typography>
            <ArrowDropDownRoundedIcon className={styles.arrowIcon} />
          </div>
        );
      // no default
    }
  }

  return (
    <Box mt={marginTop} mb={marginBottom}>
      <Grid
        container
        alignItems={isMobile ? 'flex-start' : 'center'}
        direction={isMobile ? 'column' : 'row'}
        wrap="nowrap"
        className={hidden ? styles.hidden : styles.container}
      >
        <Grid item>
          <div className={styles.avatar}>{avatar}</div>
        </Grid>

        <Grid item>
          {loading ? (
            <div>
              <FakeInfo />
            </div>
          ) : (
            <Grid container direction="column">
              <Grid item>
                <Box mb={isMobile ? -6 : 2}>
                  <Grid
                    container
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    direction={isMobile ? 'column' : 'row'}
                    className={styles.nameContainer}
                  >
                    <Grid item>
                      <Typography className={styles.name}>{name}</Typography>
                    </Grid>

                    <Grid item onClick={onClickSituation}>
                      <Box ml={isMobile ? 0 : 4}>
                        {renderChipSituation(situation)}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <Box mr={isMobile ? 3 : 6}>
                      <Typography className={styles.label}>
                        {firstLabel}
                      </Typography>
                      <Typography className={styles.value}>
                        {firstValue}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box mr={isMobile ? 3 : 6}>
                      <Typography className={styles.label}>
                        {secondLabel}
                      </Typography>

                      <Typography className={styles.value}>
                        {secondValue}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box>
                      <Typography className={styles.label}>
                        {thirdLabel}
                      </Typography>

                      <Typography className={styles.value}>
                        {thirdValue}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
