import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

function FakeExtract() {
  return (
    <>
      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={80}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div>
        <div className={styles.informations}>
          <div>
            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={80}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={110}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div>
            <Skeleton
              height={30}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.divider} />
      </div>

      <div>
        <div className={styles.informations}>
          <div>
            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={80}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={110}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div>
            <Skeleton
              height={30}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.divider} />
      </div>

      <div>
        <div className={styles.informations}>
          <div>
            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={80}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={110}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div>
            <Skeleton
              height={30}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.divider} />
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={80}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>

      <div>
        <div className={styles.informations}>
          <div>
            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={80}
                color="#202020"
                highlightColor="#444"
              />
            </div>

            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={110}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div>
            <Skeleton
              height={30}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.divider} />
      </div>

      <div>
        <div className={styles.informations}>
          <div>
            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={80}
                color="#202020"
                highlightColor="#444"
              />
            </div>
            
            <div style={{ position: 'relative', bottom: 1 }}>
              <Skeleton
                height={18}
                width={110}
                color="#202020"
                highlightColor="#444"
              />
            </div>
          </div>

          <div>
            <Skeleton
              height={30}
              width={90}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
        <div className={styles.divider} />
      </div>

      <div className={styles.extractItem}>
        <div className={styles.extractItemHeader}>
          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={80}
              color="#202020"
              highlightColor="#444"
            />
          </div>

          <div style={{ margin: 10 }}>
            <Skeleton
              height={18}
              width={110}
              color="#202020"
              highlightColor="#444"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FakeExtract;
