import React from 'react';
import { IMaskMixin } from 'react-imask';

import { TextField } from '@material-ui/core';

const IMaskComponent = IMaskMixin(({ ...props }) => (
  <TextField
    size="small"
    variant="outlined"
    fullWidth
    InputProps={{ style: { borderRadius: '0.5rem' } }}
    {...props}
  />
));

export function MaskedField({
  name = '',
  label = '',
  disabled = false,
  value = '',
  onChange = () => {},
  mask,
  ...otherProps
}) {
  return (
    <IMaskComponent
      name={name}
      label={label}
      disabled={disabled}
      mask={mask}
      value={value}
      onAccept={e => {
        onChange(e);
      }}
      {...otherProps}
    />
  );
}
