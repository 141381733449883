import React from 'react';

import { Tooltip, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Skeleton from 'react-loading-skeleton';
import styles from '../styles.module.css';
import currencyFormatter from '../../../utils/currencyFormatter';
import FakeCommonCard from './FakeCards/FakeCommonCard';
import FakeCardTip from './FakeCards/FakeCardTip';
import { isMobile } from '../../../utils/breakpoints';

import { ReactComponent as InactiveIcon } from '../../../assets/inactive-icon.svg';
import PlanSituation from './PlanSituation';
import convertBrazilianDate from '../../../utils/convertBrazilianDate';

function Resume({
  dataPlans,
  loadingData,
  expired,
  handleOpenInactiveDebitPaymentModal,
  handleGoToPayments,
  mainInformations,
  setOpenModal,
  setOpenHirePlanModal,
  finalCurrencyDate,
}) {
  const COLORS = {
    GREEN: '#168243',
    YELLOW: '#f2c94c',
    RED: '#eb5757',
  };

  const {
    plan,
    signatureAmount,
    franchise,
    totalFeeAmount,
    totalExpressPaymentFeeAmount,
    consumedFranchise,
    firstSignature,
    zeroPlan,
  } = dataPlans;

  const { active } = mainInformations;

  function getColorFranchise(consumedFranchise) {
    const MIN_FRANCHISE = 0.35 * franchise;
    const MAX_FRANCHISE = 0.7 * franchise;

    const franchises = {
      [consumedFranchise <= MIN_FRANCHISE]: COLORS.GREEN,
      [consumedFranchise >= MIN_FRANCHISE &&
      consumedFranchise <= MAX_FRANCHISE]: COLORS.YELLOW,
      [consumedFranchise >= MAX_FRANCHISE]: COLORS.RED,
    };

    return franchises.true;
  }

  function handleOpenPlanDetailsModal() {
    if (plan === '' || !plan) {
      setOpenHirePlanModal(true);
    } else {
      setOpenModal(true);
    }
  }

  function cardFeesStyle() {
    if (firstSignature) {
      return styles.cardFadedFees;
    }
    return styles.card;
  }

  function cardFranchiseStyle() {
    if (firstSignature || expired) {
      return styles.cardFranchiseExpired;
    }
    return styles.card;
  }

  return (
    <>
      <div className={styles.resume}>
        <Typography
          className={
            firstSignature || (zeroPlan && !active)
              ? styles.textBiggerInactive
              : styles.textBigger
          }
        >
          Resumo de consumo
        </Typography>

        <div className={styles.cardsContainer}>
          {loadingData ? (
            <FakeCommonCard />
          ) : (
            <div onClick={handleOpenPlanDetailsModal} className={styles.card}>
              <>
                <Typography
                  className={
                    expired ? styles.cardTitleExpired : styles.cardTitle
                  }
                >
                  {plan}
                </Typography>

                <div>
                  {expired ? (
                    <Typography className={styles.label}>
                      Valor mensal
                    </Typography>
                  ) : (
                    <Typography className={styles.label}>Valor</Typography>
                  )}

                  <div className={styles.containerInactive}>
                    <Typography
                      className={expired ? styles.valueExpired : styles.value}
                    >
                      {currencyFormatter(signatureAmount)}
                    </Typography>

                    <div
                      style={{
                        display:
                          (expired === false || expired === null) && 'none',
                      }}
                      className={styles.inactiveIcon}
                    >
                      <InactiveIcon />
                      <p>Inativo</p>
                    </div>

                    <section style={{ display: expired && 'none' }}>
                      <Typography className={styles.expireLabel}>
                        Vencimento
                      </Typography>
                      <p>
                        {finalCurrencyDate
                          ? convertBrazilianDate(finalCurrencyDate)
                          : '-'}
                      </p>
                    </section>
                  </div>
                </div>
              </>
            </div>
          )}

          <div className={cardFranchiseStyle()}>
            <div className={styles.cardHeader}>
              <Tooltip
                title="Mensalmente você tem direiro a franquia de
                9 funcionários grátis, e aqui, você acompanha
                quanto já usou dessa franquia."
                placement="top"
                arrow
                enterTouchDelay
              >
                {loadingData ? (
                  <Skeleton
                    circle
                    height={16}
                    width={16}
                    color="#202020"
                    highlightColor="#444"
                  />
                ) : (
                  <ErrorOutlineIcon className={styles.infoIcon} />
                )}
              </Tooltip>
            </div>

            <div className={styles.cardBody}>
              {loadingData ? (
                <Skeleton
                  height={50}
                  width={76}
                  color="#202020"
                  highlightColor="#444"
                />
              ) : (
                <Typography className={styles.cardTitle}>
                  Franquia
                  <br /> usada no
                  <br /> período
                </Typography>
              )}

              <Typography
                className={
                  firstSignature || expired
                    ? styles.cardStatusExpired
                    : styles.cardStatus
                }
              >
                <span style={{ color: getColorFranchise(consumedFranchise) }}>
                  {loadingData ? (
                    <Skeleton
                      height={35}
                      width={30}
                      color="#202020"
                      highlightColor="#444"
                    />
                  ) : (
                    consumedFranchise
                  )}
                </span>

                {loadingData ? (
                  <span style={{ marginTop: '5px' }}>
                    <Skeleton
                      height={15}
                      width={25}
                      color="#202020"
                      highlightColor="#444"
                    />
                  </span>
                ) : (
                  <span>/{franchise}</span>
                )}
              </Typography>
            </div>
          </div>

          {loadingData ? (
            <FakeCardTip />
          ) : (
            <div className={cardFeesStyle()}>
              <div className={styles.cardHeader}>
                <Tooltip
                  title="Tarifa cobrada por funcionário excedente a
                franquia. Essa tarifa é cobrada apenas uma
                vez dentro do período de vigência do plano."
                  placement="top"
                  arrow
                  enterTouchDelay
                  disableHoverListener={firstSignature && true}
                >
                  <ErrorOutlineIcon className={styles.infoIcon} />
                </Tooltip>
              </div>

              <div className={styles.fees}>
                <Typography className={styles.label}>
                  Total da tarifa
                  <br /> de pagamento
                  <br /> por funcionário
                </Typography>
                <Typography className={styles.value}>
                  {totalFeeAmount === null
                    ? 'R$ 0,00'
                    : currencyFormatter(totalFeeAmount)}
                </Typography>
              </div>
            </div>
          )}

          {loadingData ? (
            <FakeCardTip />
          ) : firstSignature && isMobile ? (
            ''
          ) : (
            <div className={cardFeesStyle()}>
              <div className={styles.cardHeader}>
                <Tooltip
                  title="Tarifa cobrada por cada liberação imediata executada.
                  Calculada com base na quantidade de funcionários
                  contidos na folha de pagamento."
                  placement="top"
                  arrow
                  enterTouchDelay
                  disableHoverListener={firstSignature && true}
                >
                  <ErrorOutlineIcon className={styles.infoIcon} />
                </Tooltip>
              </div>
              <div className={styles.expressPayment}>
                {isMobile ? (
                  <Typography className={styles.label}>
                    Total da tarifa
                    <br /> de liberacão
                    <br /> imediata
                  </Typography>
                ) : (
                  <Typography className={styles.label}>
                    Total da tarifa de <br /> liberacão imediata
                  </Typography>
                )}
                <Typography className={styles.value}>
                  {totalExpressPaymentFeeAmount === null
                    ? 'R$ 0,00'
                    : currencyFormatter(totalExpressPaymentFeeAmount)}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>

      <PlanSituation
        onConfirmButtonClicked={
          zeroPlan ? handleGoToPayments : handleOpenInactiveDebitPaymentModal
        }
        zeroPlan={zeroPlan}
        invisible={
          !isMobile || (isMobile && expired) || !(firstSignature && isMobile)
        }
      />
    </>
  );
}

export default Resume;
