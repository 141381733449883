import React from 'react';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useSnackbar } from '../../context/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const iconSource = {
  success: { success: <CheckCircleOutlinedIcon fontSize="inherit" /> },
  error: { error: <ErrorOutlineIcon fontSize="inherit" /> },
  warning: { warning: <ReportProblemOutlinedIcon fontSize="inherit" /> },
  info: { info: <InfoOutlinedIcon fontSize="inherit" /> },
};

function NullNode() {
  return null;
}

function CustomSnackbar() {
  const {
    snackbar: {
      open,
      onClose,
      autoHideDuration,
      message,
      severity,
      width,
      background,
    },
  } = useSnackbar();

  return (
    <Snackbar
      variant="outlined"
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      <Alert
        severity={severity}
        onClose={onClose}
        iconMapping={iconSource[severity] || { success: <NullNode /> }}
        style={{ width, background, display: 'flex' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
export default CustomSnackbar;
