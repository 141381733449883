import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NiceModal, { ModalDef } from '@ebay/nice-modal-react';
import styles from './styles.module.css';
import ProgressToolBar from '../../../../../../../components/ProgressToolBar';
import { uploadEmployeeMarginsFile } from '../../utils/services';
import { usePusher } from '../../../../../../../hooks/usePusher';
import ConfirmModal from '../Modal/ConfirmModal';
import ErrorModal from '../Modal/ErrorModal';
import { handleSnackbarStack } from '../../../../../../../utils/handleSnackbarStack';

function FileUpload() {
  const history = useHistory();

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');

  const [isLoading, toggleLoading] = useReducer(old => !old, false);

  const [progress, setProgress] = useState(0);

  const { currentCompany } = useSelector(state => state.companies);

  const [listener] = usePusher(currentCompany?.code);

  const onDrop = useCallback(file => {
    setFile(file);
    setFileName(file[0].name);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    isDragActive,
  } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
        '.xlsm',
      ],
    },
    multiple: false,
    disabled: isLoading || fileName.length > 0,
    onDrop,
  });

  const onSubmit = useCallback(async () => {
    toggleLoading();

    try {
      const formData = new FormData();
      formData.append('file', file[0]);

      await uploadEmployeeMarginsFile(formData);
    } catch {
      handleSnackbarStack().error(
        'Não foi possível prosseguir com a solicitação',
      );
    }

    toggleLoading();
  }, [file]);

  const goBack = () => {
    history.goBack();
  };

  const onRemove = () => {
    setFile(undefined);
    setFileName('');
  };

  useEffect(() => {
    listener('EMPLOYEE_CONSIGNED_IMPORT_PROCESSING', ({ percentage }) => {
      setProgress(percentage);
    });
  }, []);

  useEffect(() => {
    listener(
      'EMPLOYEE_CONSIGNED_IMPORT_FINISHED',
      ({ processId: id, status, ...data }) => {
        if (status === 'PROCESSED_EMPLOYEE_CONSIGNED_SUCCESS') {
          NiceModal.show('confirm-file-process', { processId: id }).catch(
            () => {
              setFile(undefined);
              setFileName('');
              setProgress(0);
            },
          );
        } else {
          NiceModal.show('error-file-process', data).then(() => {
            setFile(undefined);
            setFileName('');
            setProgress(0);
          });
        }
      },
    );
  }, []);

  return (
    <Box>
      <Typography className={styles.title}>
        2. Envie o arquivo preenchido
      </Typography>
      <Box className={styles['upload-backdrop']}>
        <Box
          className={styles['upload-progress']}
          style={{ width: `${progress}%` }}
        />
        <Box className={styles['upload-box']} {...getRootProps()}>
          <input {...getInputProps()} />
          {!file && (
            <>
              <CloudUploadIcon />
              <Typography>
                {isDragReject
                  ? 'Formato não aceito'
                  : isDragActive
                  ? 'Solte o arquivo'
                  : 'Enviar um arquivo'}
              </Typography>
            </>
          )}
          {file && (
            <Box className={styles['selected-file']}>
              <Box className={styles['file-info']}>
                <FileCopyIcon className={styles['file-icon']} />
                <Typography className={styles['file-name']}>
                  {fileName}
                </Typography>
              </Box>
              {!isLoading && (
                <Button variant="outlined" size="small" onClick={onRemove}>
                  Remover
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {fileName.length > 0 && isLoading && (
        <Typography
          className={styles['upload-description']}
          data-placement="left"
        >
          Enquanto verificamos o arquivo você pode continuar seu trabalho e
          acompanhar o andamento pelo indicador na parte superior.
        </Typography>
      )}
      {!fileName && (
        <Typography className={styles['upload-description']}>
          Você pode arrastar ou clicar na caixa para selecionar o arquivo.
        </Typography>
      )}
      <ProgressToolBar
        nextButtonText="Processar"
        onBackClicked={goBack}
        onNextClicked={onSubmit}
        disabled={fileName === ''}
        loading={isLoading}
      />
      <ModalDef id="confirm-file-process" component={ConfirmModal} />
      <ModalDef id="error-file-process" component={ErrorModal} />
    </Box>
  );
}

export default FileUpload;
