import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchApprovedEmployeesQuery } from '../../../../../../../redux/store/Employees/services/approvedEmployees.api';

export function useApprovedEmployees() {
  const locationsOptions = useSelector(
    state => state.employees.employeesGeneral.locations,
  );
  const companyCode = useSelector(state => state.companies.currentCompany.code);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState('cpf');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [filter, setFilter] = useState({
    name: '',
    cpf: '',
    registration: '',
    status: '',
    location: '',
  });

  const {
    data,
    isFetching,
    isLoading,
    isError,
  } = useSearchApprovedEmployeesQuery({
    params: {
      page,
      size,
      sort: `${orderBy},${orderDirection}`,
      ...filter,
    },
    companyCode,
  });

  const filterDefaultValues = {
    nameOrCpf: '',
    registration: '',
    status: 'all',
    location: '',
  };

  const statusOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Ativos', value: 'Ativo' },
    { label: 'Demitidos', value: 'Demitido' },
    { label: 'Férias', value: 'Férias' },
    { label: 'Licença INSS', value: 'Licença INSS' },
    {
      label: 'Licença não remunerada',
      value: 'Licença não remunerada',
    },
  ];

  function handleFiltersChange(data) {
    const isCpf =
      data?.nameOrCpf !== '' && !isNaN(Number(data?.nameOrCpf?.[0]));

    const dataParsed = {
      name: isCpf ? '' : data?.nameOrCpf,
      cpf: isCpf ? data?.nameOrCpf : '',
      registration: data?.registration ?? '',
      status: data?.status === 'all' ? '' : data?.status,
      location: data?.location ?? '',
    };

    setFilter(dataParsed);
  }

  function handlePagination(
    currentPage,
    currentSize,
    currentOrderBy,
    currentOrderDirection,
  ) {
    setPage(currentPage);
    setSize(currentSize);
    setOrderBy(currentOrderBy);
    setOrderDirection(currentOrderDirection);
  }

  return {
    filter: {
      defaultValues: filterDefaultValues,
      handleRequests: handleFiltersChange,
    },
    meta: {
      locationsOptions,
      statusOptions,
    },
    table: {
      data: data?.content,
      length: data?.totalSize,
      isLoading: isLoading || isFetching,
      hasError: isError,
      requests: handlePagination,
    },
  };
}
