import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  Button,
  Typography,
  Card,
  CardContent,
  Chip,
  Box,
  Tooltip,
  IconButton,
  Grid,
  Snackbar,
} from '@material-ui/core';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Alert } from '@material-ui/lab';
import { QRCodeCanvas } from 'qrcode.react';
import Barcode from 'react-barcode';
import somapayLogoAnimation from '../../../../lotties/somapayLogo.json';
import style from './styles.module.css';

import { handleStatus } from '../../Utils/handleStatus';
import TableRechargeDetails from './TableRechargeDetails';
import TablePaginationActions from '../../../OnlinePointCharges/UI/Table/TablePaginationActions';
import styles from './TableRechargeDetails/styles';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: somapayLogoAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const RechargeDetails = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [loading, setLoading] = useState(false);
  const [changedFilter, setChangedFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);

  function currentMoneyLocal(value) {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  }

  const {
    id,
    priceInCents,
    type,
    url,
    endDate,
    status,
    createdAt,
    barCode,
    digitableLineNumber,
    charges,
  } = JSON.parse(sessionStorage.getItem('rechargeDataBeevale'));

  const [copyPasteCode, setCopyPasteCode] = useState('');
  const [modalSuccess, setModalSuccess] = useState('');
  const [modalSuccessMessage, setModalSuccessMessage] = useState('');

  const QRCode = ({ data }) => (
    <QRCodeCanvas value={data} className={style.qrCode} />
  );

  const CustomBarcode = ({ data }) => <Barcode value={data} />;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function handleChangePage(event, newPage) {
    setCurrentPage(newPage);
    setChangedFilter(true);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Typography style={styles.titleStyle}>Visualizar Detalhes</Typography>
      </div>

      <div className={style.twoCardsDetails}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <div className={style.containerDetailsStatus}>
              <Typography style={styles.biggerTextStyle}>
                Detalhes da recarga
              </Typography>
              <Chip
                label={handleStatus(status).label}
                style={{
                  backgroundColor: handleStatus(status).color,
                  color: '#FFFFFF',
                }}
              />
            </div>

            <div className={style.nsuTypeName}>
              <div className={style.containerNsu}>
                <p className={style.detailTitle}>NSU</p>
                <p className={style.detailsValues}>{id}</p>
              </div>
              <div className={style.containerAccount}>
                <p className={style.detailTitle}>Total</p>
                <p className={style.paymentValues}>
                  {currentMoneyLocal(priceInCents)}
                </p>
              </div>
              <div className={style.containerPaymentType}>
                <p className={style.detailTitle}>Tipo</p>
                <p className={style.detailsValues}>{type}</p>
              </div>
              <div className={style.containerPaymentType}>
                <p className={style.detailTitle}>Vencimento para pagamento</p>
                <p className={style.detailsValues}>
                  {moment(endDate)?.format('DD/MM/YYYY')}
                </p>
              </div>
              <div className={style.containerPaymentType}>
                <p className={style.detailTitle}>Criado em</p>
                <p className={style.detailsValues}>
                  {moment(createdAt)?.format('DD/MM/YYYY')} às{' '}
                  {moment(createdAt).format('LT')}
                </p>
              </div>
            </div>
            {type === 'Pix' &&
              url !== null &&
              status !== 'FULFILLED' &&
              status !== 'PAID' && (
                <div>
                  <Box mb={1.5} />
                  <Tooltip title="Copiar código pix" placement="top">
                    <CopyToClipboard text={url}>
                      <Button
                        className={style.nsuNumberCopy}
                        onClick={() => {
                          setModalSuccessMessage(
                            'Código pix copiado com sucesso!',
                          );
                          setModalSuccess(true);
                        }}
                      >
                        <IconButton>
                          <FileCopyOutlinedIcon className={style.copyNsuIcon} />
                        </IconButton>
                        Pague com o código pix
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>

                  <Grid item md={12} lg={12} className={style.qrCodeGrid}>
                    <QRCode data={url} className={style.qrCode} />
                  </Grid>
                </div>
              )}
            {type === 'Boleto bancário' &&
              barCode !== null &&
              digitableLineNumber !== null &&
              status !== 'FULFILLED' &&
              status !== 'PAID' && (
                <div>
                  <Box mb={1.5} />
                  <Tooltip title="Copiar linha digitável" placement="top">
                    <CopyToClipboard text={digitableLineNumber}>
                      <Button
                        className={style.nsuNumberCopy}
                        onClick={() => {
                          setModalSuccessMessage(
                            'Linha digitável copiada com sucesso!',
                          );
                          setModalSuccess(true);
                        }}
                      >
                        <IconButton>
                          <FileCopyOutlinedIcon className={style.copyNsuIcon} />
                        </IconButton>
                        Copiar linha digitável do boleto
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                  <Box mb={1.5} />
                  <CustomBarcode data={barCode} />
                </div>
              )}
          </CardContent>
        </Card>

        <Box mb={3} />

        <TableRechargeDetails
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          loading={loading}
          changedFilter={changedFilter}
          currentPage={currentPage}
          pageSize={pageSize}
          totalSize={totalSize}
          currentPageSize={currentPageSize}
          rows={charges}
          getComparator={getComparator(order, orderBy)}
          stableSort={stableSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          TablePaginationActions={TablePaginationActions}
          handleOpenModalDeleteEmployee={() => {}}
          previewSelected={() => {}}
          setPreviewSelected={() => {}}
        />
      </div>
      <Snackbar
        open={modalSuccess}
        autoHideDuration={3000}
        onClose={() => setModalSuccess(false)}
      >
        <Alert onClose={() => setModalSuccess(false)} severity="success">
          {modalSuccessMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RechargeDetails;
