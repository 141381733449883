import React, { useState, useEffect } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

import EmployeesTable from '../EmployeesTable';
import ResultNotFound from '../../../../components/ResultNotFound';
import { api } from '../../../../services/api';
import verifyNumberInString from '../../../../utils/verifyNumberInString';
import { situationOfEmployees } from '../../../../mocks/situationOfEmployees';
import ErrorModal from '../../../../components/PrimaryModal';
import formatCPF from '../../../../utils/formatCPF';



function EmployeesListModal({
  open,
  onClose,
  selected,
  setSelected,
  setOpenEmployeesListModal,
  ignoredEmployeesBeeVale,
  isItemDeleted,
}) {

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [employees, setEmployees] = useState([]);
  const [ignoredEmployees, setIgnoredEmployees] = useState([]);
  // const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [activeButton, setActiveButton] = useState(6);
  const [searchCostAndCenter, setSearchCostAndCenter] = useState('');
  const [searchSituation, setSearchSituation] = useState('');
  const [costAndCenter, setCostAndCenter] = useState([]);
  const [cpfOrName, setCpfOrName] = useState('');
  const [openFiredEmployeesModal, setOpenFiredEmployeesModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openModalError, setOpenModalError] = useState(false);
  const [channelData, setChannelData] = useState({});
  const [modalEmployeeLoading, setModalEmployeeLoading] = useState(false);
  const [modalPis, setModalPis] = useState(false);
  const [pisValue, setPisValue] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [savePisButtonLoading, setSavePisButtonLoading] = useState(false);
  const [snackbarPis, setSnackbarPis] = useState(false);
  const [journeyRules, setJourneyRules] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [journeyRulesSelected, setJourneyRulesSelected] = useState('');
  const [shiftSelected, setShiftSelected] = useState('');
  const [jobTitleSelected, setJobTitleSelected] = useState('');

  useEffect(() => {
    getEmployees();
  }, [
    page,
    rowsPerPage,
    cpfOrName,
    searchSituation,
    searchCostAndCenter,
    isItemDeleted,
  ]);

  function getEmployees() {
    const isNumber = verifyNumberInString(cpfOrName);
    const requestOptions = {
      params: {
        page,
        size: rowsPerPage,
        cpf: isNumber ? cpfOrName : '',
        name: !isNumber ? cpfOrName : '',
        status: searchSituation,
        location: searchCostAndCenter,
      },
    };

    setIsLoading(true);

    api
      .get(`/employee/search/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setEmployees(data.content);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleClearFilters() {
    setCpfOrName('');
    setSearchCostAndCenter('');
    setSearchSituation('');
    setActiveButton(6);
  }

  function handleChangeCpfOrName(event) {
    setCpfOrName(event.target.value);
  }

  function onCancelClicked() {
    setOpenEmployeesListModal(false);
  }

  function includeEmployees(selected) {
    if (selected.find(item => item.employeeStatus === 'Demitido')) {
      setOpenFiredEmployeesModal(true);
      return;
    }

    verifyIncludeEmployees();
  }

  function verifyIncludeEmployees() {
    setIsLoadingButton(true);

    const employeesSelecteds = selected.map(item => {
      return item?.cpf.toString().replace(/\.|-/gm, '');
    });

    api
      .post(
        `/beeValeBenefits/employee/create/${currentCompanyCode}`,
        employeesSelecteds
      )
      .then(() => {
        onCancelClicked()
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setOpenModalError(true);
          setErrorMessage(errors);
        },
      )
      .finally(() => {
        setIsLoadingButton(false);
      });
  }

  function handleFilterSituation(valueActiveButton, situationSearch) {
    setActiveButton(valueActiveButton);
    setSearchSituation(situationSearch);
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <Grid
          container
          direction="column"
          className={styles.employeesListModalContainer}
        >
          <Grid item md={12} lg={12}>
          <div className={styles.headerFilters}>
            {situationOfEmployees.map(item => {
              if (item.text !== 'Demitidos') {
                return (
                  <Button
                    onClick={() =>
                      handleFilterSituation(item.value, item.search)
                    }
                    className={
                      activeButton === item.value
                        ? styles.buttonFilterActive
                        : styles.buttonFilter
                    }
                  >
                    {item.text}
                  </Button>
                );
              }
            })}
          </div>
            <TextField
              value={cpfOrName}
              onChange={handleChangeCpfOrName}
              className={styles.employeesListModalSearchInput}
              variant="outlined"
              placeholder="Pesquise por nome ou CPF"
              onFocus={() =>
                setTimeout(() => setTextFieldFocused('cpfOrName'), 200)
              }
              onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
              InputProps={{
                style: {
                  height: 40,
                  borderRadius: 6,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={styles.iconColor} />
                  </InputAdornment>
                ),
                endAdornment: cpfOrName?.length > 0 &&
                  textFieldFocused === 'cpfOrName' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => setCpfOrName('')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          {
            employees.length > 0 ? (
              <EmployeesTable
                employees={employees}
                isLoading={isLoading}
                totalSize={totalSize}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selected={selected}
                setSelected={setSelected}
                ignoredEmployees={ignoredEmployeesBeeVale}
                setIgnoredEmployees={setIgnoredEmployees}
              />
            ) : (
              <ResultNotFound
                text="Nenhum Resultado Encontrado"
                alternativeText="Verifique os dados pesquisados e tente novamente."
                buttonText="Limpar Filtros"
                onClick={handleClearFilters}
              />
            )
          }
          <Grid
            container
            justify={
              selected.length > 0 && !isLoading
                ? 'space-between'
                : 'flex-end'
            }
            alignItems="center"
          >
            {selected.length > 0 && !isLoading && (
                <Grid item className={styles.employeesListSelecteds}>
                  <div className={styles.employeesListSelectedsCount}>
                    {selected.length}
                  </div>

                  <Typography className={styles.employeesListSelectedsText}>
                    {selected.length > 1 ? 'Selecionados' : 'Selecionado'}
                  </Typography>
                </Grid>
            )}

            <Grid item>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={onCancelClicked}
                    className={styles.employeesListModalCloseButton}
                  >
                    Cancelar
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    onClick={() => includeEmployees(selected)}
                    className={styles.employeesListModalConfirmButton}
                    disabled={
                      !(
                        !isLoadingButton &&
                        selected.length > 0
                      )
                    }
                  >
                    {isLoadingButton ? (
                      <CircularProgress
                        size={16}
                        className={styles.circularProgress}
                      />
                    ) : (
                      'Incluir'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <ErrorModal
        title={
          errorMessage !== null && errorMessage[0].errorsDescription !== null
            ? 'Atenção!'
            : 'Tivemos um problema no cadastro'
        }
        text={
          errorMessage !== null &&
          errorMessage[0].errorDetail !== null ? (
            <span>
              Você selecionou funcionários que estão com dados que precisam ser
              revistos para ser cadastrado no beeVale.
              <br />
              <br />

              {/* {errorMessage[0].errorsDescription.map(item => ( */}
                <Typography className={styles.errorListLabel}>
                  • {errorMessage[0].errorDetail}
                </Typography>
              {/* ))} */}
            </span>
          ) : (
            <span>Tente novamente!</span>
          )
        }
        confirmButtonText="Ok"
        cancelButtonText="Fechar"
        onConfirmClicked={() => setOpenModalError(false)}
        onCancelButtonClicked={() => setOpenModalError(false)}
        open={openModalError}
      />
    </>
  )

}

export default EmployeesListModal