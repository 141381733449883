import React, { useContext } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';

import { SettingsContext } from '../../contexts';

import { useStyles } from '../../styles';

export default function SettingMenu({ id, title, subtitle, alert, children }) {
  const styles = useStyles();
  const { expanded, handleExpand } = useContext(SettingsContext);

  return (
    <Accordion
      expanded={expanded === id}
      onChange={handleExpand(id)}
      className={styles.accordion}
    >
      <AccordionSummary
        className={styles.accordionSummary}
        expandIcon={<ExpandMoreOutlined />}
        classes={{ content: styles.accordionSummaryWrapper }}
      >
        <Typography variant="h2" className={styles.accordionTitle}>
          {title}
        </Typography>
        <Typography variant="caption" className={styles.accordionSubtitle}>
          {subtitle}
        </Typography>
        {!!alert && (
          <Typography variant="caption" className={styles.accordionAlert}>
            {alert}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={styles.details}>{children}</AccordionDetails>
    </Accordion>
  );
}
