import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input';

import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Snackbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import MuiAlert from '@material-ui/lab/Alert';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import BottomSheet from './UI/BottomSheet';
import ApplyValuesModal from '../IncludeCollaborators/UI/ApplyValueModal';
import FakeList from '../../../components/FakeList';

import styles from './styles.module.css';
import textCapitalize from '../../../utils/textCapitalize';
import { isMobile } from '../../../utils/breakpoints';
import { api } from '../../../services/api';
import { ActionsManualPayroll } from '../../../redux/store/Payroll/ManualPayroll';
import { ChipStatus } from '../../../components/ChipStatus';

import { useEmployeesAprovedSituations } from './UI/SituationEmployeesTabs/Components/EmployeesAproved/Hooks/useEmployeesAprovedSituations';
import formatCPF from '../../../utils/formatCPF';

export default function SetEmployeesValues() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    handleEmployeeSituation,
    handleEmployeeSituationIcon,
  } = useEmployeesAprovedSituations();

  const selectedEmployees = JSON.parse(
    sessionStorage.getItem('selectedEmployees'),
  );
  const paymentDescription = JSON.parse(
    sessionStorage.getItem('paymentDescription'),
  );
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');

  const [nameOrCpf, setNameOrCpf] = useState('');
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [openApplyValuesModal, setOpenApplyValuesModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selecteds, setSelecteds] = useState(selectedEmployees || []);
  const [loading, setLoading] = useState(false);
  const EMPTY_TABLE = 0;

  const inputValueModal = useSelector(
    state => state.applyValueInputs.inputValue,
  );

  const { accountSelected } = useSelector(state => state.account);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (inputValueModal) {
      selectedEmployees.map((item, index) => {
        let auxList = [...selectedEmployees];

        auxList[index].amount = inputValueModal;

        sessionStorage.setItem(
          'selectedEmployees',
          JSON.stringify(auxList.flat(Infinity)),
        );
        setSelecteds(auxList.flat(Infinity));
      });
    }
  }, [inputValueModal]);

  const handleContinue = () => {
    if (selectedEmployees?.length === EMPTY_TABLE) {
      sessionStorage.setItem(
        'useTopBar',
        JSON.stringify({
          name: 'Pagamento manual',
          step: 2,
          route: '/payroll/manual-payroll',
        }),
      );

      history.push('/payroll/manual-payroll/include-collaborators');
      return;
    }

    sendData();
  };

  function formatEmployeesList(employee) {
    const formattedEmployee = {
      name: employee.name,
      cpf: employee.cpf,
      amount: employee.amount,
    };

    if (employee.hasBankData === true) {
      formattedEmployee.account = employee?.account ?? null;
      formattedEmployee.accountDigit = employee?.accountDigit ?? null;
      formattedEmployee.accountType = employee?.accountType ?? null;
      formattedEmployee.agency = employee?.agency ?? null;
      formattedEmployee.agencyDigit = employee?.agencyDigit ?? null;
      formattedEmployee.bank = employee?.bank ?? null;
      formattedEmployee.registration = employee?.registration ?? null;
    } else {
      const company = employee?.companies[0] || {};
      formattedEmployee.location = company?.location ?? null;
      formattedEmployee.registration = company?.registration ?? null;
      formattedEmployee.employeeStatus = company?.status ?? null;
      formattedEmployee.employeeStatusDescription =
        company?.statusDescription ?? null;
    }

    return formattedEmployee;
  }

  const sendData = () => {
    const employeesListFormatted = selectedEmployees?.map(formatEmployeesList);

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
    };

    const requestBody = {
      fileEncodedKey: null,
      scheduleDate: moment(paymentDescription?.date).format(
        'YYYY-MM-DDTHH:mm:ss',
      ),
      paymentType: paymentDescription?.paymentType,
      payments: employeesListFormatted,
      depositAccountOrigin: accountSelected?.origin,
    };

    api
      .post(
        `employeesPayments/drafts/${currentCompanyCode}`,
        requestBody,
        requestOptions,
      )
      .then(({ data }) => {
        sessionStorage.setItem(
          'useTopBar',
          JSON.stringify({
            name: 'Pagamento manual',
            step: 4,
            route: history.location.pathname,
          }),
        );

        sessionStorage.setItem('importFileData', JSON.stringify(data));
        history.push('/payroll/menu/info-payroll/register-batch/data-review');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setSeverity('error');
          setOpenSnackbar(true);
          setAlertMessage(errors[0]?.errorDetail);
        },
      );
  };

  const onChangeNameOrCpf = event => {
    setNameOrCpf(event.target.value);
  };

  const setShowSuccessAlert = () => {
    if (isMobile) {
      setSeverity('success');
      setAlertMessage('Valor aplicado a todos com sucesso');
      setOpenSnackbar(true);
    }

    setOpenApplyValuesModal(false);
  };

  const onChangeCurrentTextFild = (event, index, value) => {
    let auxList = [...selectedEmployees];
    let amount = value;

    dispatch({
      type: 'SET_VALUE_INPUTS',
      inputValue: '',
    });

    auxList[index].amount = amount;

    sessionStorage.setItem(
      'selectedEmployees',
      JSON.stringify(auxList.flat(Infinity)),
    );
  };

  const handleRemoveItem = index => {
    const auxArray = selectedEmployees;

    auxArray.splice(index, 1);

    setSelecteds([...auxArray]);
    sessionStorage.setItem('selectedEmployees', JSON.stringify(auxArray));
    dispatch(ActionsManualPayroll.setSelectedRowsEmployees(auxArray));
  };

  const handleClearFilters = () => {
    setNameOrCpf('');
  };

  return (
    <div>
      <div className={styles.filters}>
        <Button
          className={styles.filtersButton}
          onClick={() => setOpenBottomSheet(true)}
          disabled={selectedEmployees?.length === EMPTY_TABLE}
        >
          <MenuRoundedIcon className={styles.filtersButtonIcon} />
          Opções
        </Button>

        <TextField
          className={styles.nameOrCpf}
          onChange={onChangeNameOrCpf}
          value={nameOrCpf}
          placeholder="Pesquise por nome ou CPF"
          variant="outlined"
          disabled={!selectedEmployees}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: nameOrCpf?.length > 0 && (
              <IconButton
                aria-label="toggle password visibility"
                className={styles.endAdornment}
                onClick={handleClearFilters}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            ),
          }}
        />
      </div>

      {selecteds
        ?.filter(item => {
          if (!nameOrCpf) return true;
          if (
            item.cpf.includes(nameOrCpf) ||
            item.name.includes(nameOrCpf) ||
            item.cpf
              .replace('.', '')
              .replace('.', '')
              .includes(nameOrCpf) ||
            item.name.toLowerCase().includes(nameOrCpf)
          ) {
            return true;
          }
        })
        ?.map((item, index) =>
          loadingFilter ? (
            <FakeList />
          ) : (
            <div className={styles.card} key={item?.cpf}>
              <div>
                <Typography className={styles.text}>
                  {textCapitalize(item?.name)}
                </Typography>

                <Typography className={styles.text}>
                  {formatCPF(item?.cpf)}
                </Typography>

                {!item?.hasBankData && (
                  <ChipStatus
                    icon={handleEmployeeSituationIcon(
                      item?.companies[0]?.status,
                    )}
                    status={handleEmployeeSituation(item?.companies[0]?.status)}
                    label={item?.companies[0]?.statusDescription}
                    size="medium"
                  />
                )}
              </div>

              <div>
                <IconButton
                  className={styles.removeIconButton}
                  onClick={() => handleRemoveItem(index)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </div>

              <CurrencyInput
                onChange={(event, value) =>
                  onChangeCurrentTextFild(event, index, value)
                }
                value={item.amount}
                className={styles.inputValue}
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
              />
            </div>
          ),
        )}

      <BottomSheet
        setOpenBottomSheet={setOpenBottomSheet}
        openBottomSheet={openBottomSheet}
        setOpenApplyValuesModal={setOpenApplyValuesModal}
        setAlertMessage={setAlertMessage}
        setOpenSnackbar={setOpenSnackbar}
        setSeverity={setSeverity}
        setSelecteds={setSelecteds}
      />

      <div
        style={openBottomSheet ? { display: 'none' } : {}}
        className={styles.footerBottom}
      >
        <Button
          className={
            selectedEmployees?.length === EMPTY_TABLE
              ? styles.continueButtonBottomFixed
              : styles.continueButtonBottom
          }
          onClick={handleContinue}
        >
          {loading ? (
            <CircularProgress className={styles.circularProgress} />
          ) : selectedEmployees?.length > EMPTY_TABLE ? (
            'Continuar'
          ) : (
            'Inserir novos colaboradores'
          )}
        </Button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        style={{ marginBottom: 75 }}
      >
        <Alert severity={severity} onClose={() => setOpenSnackbar(false)}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <ApplyValuesModal
        open={openApplyValuesModal}
        onCloseDialog={() => setOpenApplyValuesModal(false)}
        setShowSuccessAlert={setShowSuccessAlert}
      />
    </div>
  );
}
