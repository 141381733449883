import { useEffect } from 'react';

export function useChipStatus(status = 'default') {
  const statusEnums = {
    primary: { chipColor: '#FFFFFF', fontColor: '#52197F' },
    default: { chipColor: '#EFECF0', fontColor: '#2E2E2E' },
    secondary: { chipColor: '#FAE9E8', fontColor: '#E54217' },
    error: { chipColor: '#FAE9E8', fontColor: '#CA2317' },
    info: { chipColor: '#EAEEF8', fontColor: '#2D54B9' },
    success: { chipColor: '#E8F2F2', fontColor: '#19797F' },
    warning: { chipColor: '#FCF5E8', fontColor: '#E39F17' },
  };

  return statusEnums[status] ?? statusEnums.default;
}
