import React from 'react';
import NiceModal, { useModal, ModalDef } from '@ebay/nice-modal-react';
import { Controller, useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Buttons from '../../../../../../components/Buttons';
import Form from '../../../../../../components/Form';

import useStyles from './styles';

const EditDateModal = NiceModal.create(({ onEdit }) => {
  const modal = useModal();
  const styles = useStyles();
  const minDate = new Date();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onClose = () => {
    modal.remove();
  };

  const handleEdit = (form, event) => {
    event.preventDefault();
    onEdit(form);
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ className: styles.root }}
    >
      <Form.FormBase onSubmit={() => handleSubmit(handleEdit)}>
        <DialogTitle className={styles.title}>
          Alterar data de vencimento
        </DialogTitle>
        <DialogContent className={styles.content}>
          <Controller
            name="dueDate"
            control={control}
            render={({ field }) => (
              <Form.DatePicker
                minDate={minDate}
                size="medium"
                label="Insira a nova data"
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Buttons.SecondaryButton title="Cancelar" onClick={onClose} />
          <Buttons.PrimaryButton
            title="Salvar"
            size="medium"
            type="submit"
            disabled={!isValid}
          />
        </DialogActions>
      </Form.FormBase>
    </Dialog>
  );
});

export default function BillModal() {
  return <ModalDef id="edit-bill-due-date" component={EditDateModal} />;
}
