import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './styles.module.css';
import { ReactComponent as Icon } from '../../../../../../assets/report.svg';

function SimpleExpansionPanel({
    title,
    employees
}) {
  return (
    <div>
      <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={styles.containerHeader}
        >
            <div style={{ display: 'flex' }}>
              <Icon />
              <Typography className={styles.number}>{employees.length}</Typography>
              <Typography className={styles.title}>{title}</Typography>
            </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <List>
                {employees.map((item) => (
                    <ListItem>
                    <ListItemText
                      primary={item}
                      className={styles.listItemText}
                    />
                  </ListItem>
                ))}
            </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SimpleExpansionPanel;
