import React from 'react';
import styles from './styles.module.css';
import {
  Button,
  CircularProgress,
  Typography,
  Dialog,
} from '@material-ui/core';

function ConfirmImmediateReleaseModal({
  open,
  onCloseDialog,
  title,
  titleDescription,
  onConfirm,
  loadingButton,
  ErrorMessage
}) {
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
    >
      <div className={styles.containerDialog}>
        <div className={styles.header}>
          <Typography className={styles.title}>{title}</Typography>
          <Typography className={styles.titleDescription}>{titleDescription}</Typography>
          <Typography className={styles.errorMessage}>{ErrorMessage}</Typography>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={onCloseDialog}
          >
            Cancelar
          </Button>
          <Button
            className={styles.confirmButton}
            onClick={onConfirm}
            >
              {
                loadingButton ?
                <CircularProgress className={styles.circularLoadingStyle} /> :
                'Confirmar'
              }
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ConfirmImmediateReleaseModal;
