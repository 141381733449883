import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './styles.module.css';
import formatCPF from '../../../../../utils/formatCPF';
import formatCnpj from '../../../../../utils/formatCnpj';
import formatAccountAndAgencyNumber from '../../../../../utils/formatAccountAndAgencyNumber';
import formatDigitValue from '../../../../../utils/formatDigitValue';
import { api } from '../../../../../services/api';
import { isMobile } from '../../../../../utils/breakpoints';

function ModalRemoveAccount({ open, onClose, handleConfirm }) {
  const [bank, setBank] = useState('');
  const [banksList, setBanksList] = useState([]);
  const [errorBank, setErrorBank] = useState(false);
  const [errorBankMessage, setErrorBankMessage] = useState('');
  const [agency, setAgency] = useState('');
  const [errorAgency, setErrorAgency] = useState(false);
  const [errorAgencyMessage, setErrorAgencyMessage] = useState('');
  const [account, setAccount] = useState('');
  const [errorAccount, setErrorAccount] = useState(false);
  const [errorAccountMessage, setErrorAccountMessage] = useState('');
  const [cpfCnpj, setCpfOrCnpnj] = useState('');
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [errorCpfCnpjMessage, setErrorCpfCnpjMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [checkDigit, setCheckDigit] = useState('');
  const [errorCheckDigit, setErrorCheckDigit] = useState(false);
  const [errorCheckDigitMessage, setErrorCheckDigitMessage] = useState('');

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCode = companyCodeValueStorage || companies[0].code;

  useEffect(() => getBanksList(), []);

  const validateInputs = () => {
    if (bank && agency && account && cpfCnpj && checkDigit) {
      registerAccount();
    } else if (!bank) {
      setErrorBank(true);
      setErrorAccount(false);
      setErrorCpfCnpj(false);
      setErrorAgency(false);
      setErrorBankMessage('Selecione um banco');
      setErrorAgencyMessage('');
      setErrorAccountMessage('');
      setErrorCpfCnpjMessage('');
    } else if (!agency) {
      setErrorAgency(true);
      setErrorAccount(false);
      setErrorCpfCnpj(false);
      setErrorBank(false);
      setErrorAgencyMessage('Informe a agência');
      setErrorAccountMessage('');
      setErrorCpfCnpjMessage('');
      setErrorBankMessage('');
    } else if (!account) {
      setErrorAccount(true);
      setErrorAgency(false);
      setErrorCpfCnpj(false);
      setErrorBank(false);
      setErrorAccountMessage('Informe o número da conta');
      setErrorAgencyMessage('');
      setErrorCpfCnpjMessage('');
      setErrorBankMessage('');
    } else if (!cpfCnpj) {
      setErrorCpfCnpj(true);
      setErrorAccount(false);
      setErrorAgency(false);
      setErrorBank(false);
      setErrorCpfCnpjMessage('Informe o cpf ou CNPJ');
      setErrorAccountMessage('');
      setErrorAgencyMessage('');
      setErrorBankMessage('');
    } else if (!checkDigit) {
      setErrorCheckDigit(true);
      setErrorCpfCnpj(false);
      setErrorAccount(false);
      setErrorAgency(false);
      setErrorBank(false);
      setErrorCheckDigitMessage('Informe o dígito verificador');
      setErrorCpfCnpjMessage('');
      setErrorAccountMessage('');
      setErrorAgencyMessage('');
      setErrorBankMessage('');
    }
  };

  const cleanAllInputs = () => {
    setCpfOrCnpnj('');
    setAccount('');
    setAgency('');
    setName('');
    setBank('');
    setCheckDigit('');
    setErrorAgency(false);
    setErrorAccount(false);
    setErrorCpfCnpj(false);
    setErrorBank(false);
    setErrorCheckDigit(false);
    setErrorAgencyMessage('');
    setErrorAccountMessage('');
    setErrorCpfCnpjMessage('');
    setErrorBankMessage('');
    setErrorCheckDigitMessage('');
  };

  const closeModal = () => {
    cleanAllInputs();
    onClose();
  };

  const getBanksList = () => {
    api
      .get('/accountCashIn/bank/findAll')
      .then(({ data }) => setBanksList(data));
  };

  const registerAccount = () => {
    setLoading(true);
    const requestBody = {
      bankCode: bank.code,
      agency,
      account,
      checkDigit,
      cpfCnpj,
      name,
    };
    api
      .post(`/accountCashIn/create/${companyCode}`, requestBody)
      .then(registerAccountThen)
      .catch(({ response: { data: { errors } } }) =>
        registerAccountCatch(errors),
      )
      .finally(() => setLoading(false));
  };

  const registerAccountThen = () => {
    handleConfirm();
    cleanAllInputs();
  };

  const registerAccountCatch = errors => {
    setErrorAccount(true);
    setErrorAccountMessage(errors[0].errorDetail);
  };

  const maskedAgencyNumber = value => {
    value = value.replace(/\D/g, '');
    return value;
  };

  const onChangeCpfCnpj = value => {
    const number = value.replace(/\D/g, '');
    const maskedCpfCnpj =
      number.length <= 11 ? formatCPF(number) : formatCnpj(number);
    setCpfOrCnpnj(maskedCpfCnpj);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.dialog}>
        <div>
          <Typography className={styles.biggerText}>
            Incluir nova conta bancária
          </Typography>
          <Typography className={styles.smallText}>
            Preencha os dados abaixo:
          </Typography>
        </div>
        <div>
          <form className={styles.form}>
            <div className={styles.formGroup}>
              <Typography className={styles.label}>Banco</Typography>
              <Autocomplete
                value={bank}
                onChange={(event, value) => setBank(value)}
                className={errorBank ? styles.selectError : styles.textField}
                options={banksList}
                autoHighlight
                getOptionLabel={option => option.name}
                renderOption={option => (
                  <>
                    {option.name} - {option.code}
                  </>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    placeholder="Selecione um banco"
                  />
                )}
              />
              {errorBank && (
                <FormHelperText className={styles.helperText}>
                  {errorBankMessage}
                </FormHelperText>
              )}
            </div>
            <div className={styles.formGroup}>
              <Typography className={styles.label}>Agência</Typography>
              <TextField
                variant="outlined"
                placeholder="Digite aqui"
                className={styles.textField}
                error={errorAgency}
                helperText={errorAgencyMessage}
                value={maskedAgencyNumber(agency)}
                onChange={({ target: { value } }) => setAgency(value)}
                inputProps={{ maxLength: 4 }}
              />
            </div>
            <div className={styles.formGroup}>
              <Typography className={styles.label}>Conta</Typography>
              <TextField
                variant="outlined"
                placeholder="Digite aqui"
                className={styles.textField}
                error={errorAccount}
                helperText={errorAccountMessage}
                value={formatAccountAndAgencyNumber(account)}
                onChange={({ target: { value } }) =>
                  setAccount(formatAccountAndAgencyNumber(value))
                }
                inputProps={{ maxLength: 13 }}
              />
            </div>
            <div className={styles.formGroup}>
              <Typography className={styles.label}>
                Dígito verificador
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Digite aqui"
                className={styles.digit}
                error={errorCheckDigit}
                helperText={errorCheckDigitMessage}
                value={formatDigitValue(checkDigit)}
                onChange={({ target: { value } }) =>
                  setCheckDigit(formatDigitValue(value))
                }
                inputProps={{ maxLength: 2 }}
              />
            </div>
            <div className={styles.formGroup}>
              <Typography className={styles.label}>CPF / CNPJ</Typography>
              <TextField
                variant="outlined"
                placeholder="Digite aqui"
                className={styles.textField}
                error={errorCpfCnpj}
                helperText={errorCpfCnpjMessage}
                value={cpfCnpj}
                onChange={({ target: { value } }) => onChangeCpfCnpj(value)}
                inputProps={{ maxLength: 18 }}
              />
            </div>
          </form>
          <div className={styles.formName}>
            <Typography className={styles.label}>Nome (opcional)</Typography>
            <TextField
              variant="outlined"
              placeholder="Digite aqui"
              style={isMobile ? { width: '100%' } : { width: 550 }}
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
          </div>
        </div>
        <div className={styles.buttonsGroup}>
          <Button className={styles.cancelButton} onClick={closeModal}>
            Cancelar
          </Button>
          <Button className={styles.confirmButton} onClick={validateInputs}>
            {loading ? (
              <CircularProgress
                className={styles.spinner}
                size={styles.spinner}
              />
            ) : (
              'Incluir'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalRemoveAccount;
