import React from 'react';
import { Avatar, useTheme } from '@material-ui/core';

function AvatarIcon({ variant, style = {}, children }) {
  const theme = useTheme();

  return (
    <Avatar
      style={{
        backgroundColor: theme.palette.primary?.main10,
        color: theme.palette.primary?.main,
        borderRadius: variant === 'rounded' && '0.375rem',
        ...style,
      }}
    >
      {children}
    </Avatar>
  );
}

export { AvatarIcon };
