import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import Form from '../../Form';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

export default function useSelectableTable({
  items,
  selectedItems,
  identifier,
  tableConfig,
  dispatch,
  selectionLimit,
  disableSelection,
  selectObject,
  canSelectAll,
}) {
  const ERROR_MESSAGE = `Você selecionou o máximo de itens permitidos (${selectionLimit}).`;
  const [internalSelected, setSelected] = useState(selectedItems || []);
  const { error } = handleSnackbarStack();

  const currentEntry = entry => (selectObject ? entry : entry[identifier]);

  const disableLabelCheckbox = items?.length === 0;

  const disableCheckbox = row => disableLabelCheckbox || disableSelection(row);

  const isChecked = entry =>
    _.find(internalSelected, object => _.isEqual(object, currentEntry(entry)));

  const areAllItemsSelected = useMemo(
    () => items.length > 0 && items.every(entry => isChecked(entry)),
    [internalSelected],
  );

  const isIndeterminate =
    internalSelected?.length > 0 && internalSelected?.length < items.length;

  const hasMaxSelection = useMemo(() => {
    return internalSelected?.length >= selectionLimit;
  }, [internalSelected, selectionLimit]);

  const handleSelection = entry => {
    const newEntry = currentEntry(entry);
    let newSelected = [];

    const selectedIndex = _.findIndex(internalSelected, object =>
      _.isEqual(object, newEntry),
    );

    if (selectedIndex === -1) {
      if (hasMaxSelection) {
        return;
      }

      newSelected = [...internalSelected, newEntry];
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(internalSelected?.slice(1));
    } else if (selectedIndex === internalSelected?.length - 1) {
      newSelected = newSelected?.concat(internalSelected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        internalSelected?.slice(0, selectedIndex),
        internalSelected?.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectionAll = event => {
    // se tiver marcando (nem vazio nem indeterminate)

    if (event.target.checked) {
      const formattedItems = items
        .filter(item => !disableSelection(item))
        .map(item => currentEntry(item));

      let allItems = [...internalSelected, ...formattedItems];

      // se puder selecionar todos, adiciona à lista atual
      if (canSelectAll) {
        const uniqueItems = _.uniqWith(allItems, (a, b) => _.isEqual(a, b));

        setSelected(uniqueItems);
        return;
      }

      const uniqueItems = allItems.slice(0, selectionLimit);

      // se não, adiciona de acordo com o limite máximo
      setSelected(_.uniqWith(uniqueItems, (a, b) => _.isEqual(a, b)));
      return;
    }

    // se tiver desmarcando (vazio ou indeterminate)

    // se todos os itens da lista atual estiverem selecionados
    if (areAllItemsSelected) {
      // se não tiver itens selecionados em outras listas, zera tudo
      if (internalSelected?.length === items?.length) {
        setSelected([]);
        return;
      }

      // se tiver itens selecionados em outras listas, remove todos da lista atual
      const otherItems = internalSelected?.filter(
        item =>
          !items.some(entry => JSON.stringify(entry) === JSON.stringify(item)),
      );

      setSelected(otherItems);
    }
  };

  const selectableConfig = (selectable, isLoading) => {
    if (!selectable) return tableConfig;

    const newConfig = tableConfig;

    newConfig.unshift({
      key: 'select',
      type: 'select',
      hasOrder: false,
      label: (row, isMobile) => (
        <Form.Checkbox
          label={isMobile ? 'Selecionar todos' : ''}
          disabled={isLoading || disableLabelCheckbox}
          checked={areAllItemsSelected}
          indeterminate={isIndeterminate}
          onChange={event => handleSelectionAll(event, row)}
          style={{ color: isMobile ? 'var(--white)' : 'default' }}
        />
      ),
      render: row => (
        <Form.Checkbox
          checked={isChecked(row)}
          disabled={isLoading || disableCheckbox(row)}
          onChange={() => handleSelection(row)}
        />
      ),
    });

    return newConfig;
  };

  useEffect(() => {
    dispatch(internalSelected);
  }, [dispatch, internalSelected]);

  useEffect(() => {
    if (hasMaxSelection && !canSelectAll) error(ERROR_MESSAGE);
  }, [hasMaxSelection, canSelectAll]);

  return {
    selectableConfig,
    selectedCount: internalSelected?.length,
  };
}
