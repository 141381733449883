import React from 'react';
import Header from './UI/Header/Header';
import DownloadStep from './UI/DownloadStep/DownloadStep';
import FileUpload from './UI/FileUpload/FileUpload';

function ConsignedMarginsBatchInsertion() {
  return (
    <>
      <Header />
      <DownloadStep />
      <FileUpload />
    </>
  );
}

export default ConsignedMarginsBatchInsertion;
