import React from 'react';
import { Box, CircularProgress, Slide } from '@material-ui/core';
import CardCases from './CardCases';

export function CardList({
  data = [],
  config = [],
  length = 0,
  isLoading = false,
  hasError = false,
  hasMore = false,
  onFetchMoreCards = () => {},
}) {
  return hasError ? (
    <CardCases.Error />
  ) : isLoading && data.length === 0 ? (
    <CardCases.Loading config={config} />
  ) : data.length === 0 ? (
    <CardCases.Empty />
  ) : (
    <>
      <CardCases.Render
        data={data}
        config={config}
        length={length}
        hasMore={hasMore}
        onFetchMoreCards={onFetchMoreCards}
      />
      <Slide direction="up" in={isLoading} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 32,
          }}
        >
          <CircularProgress />
        </Box>
      </Slide>
    </>
  );
}
