import React from 'react';
import { Box } from '@material-ui/core';

import PrimaryModal from '../../../../../components/PrimaryModal';
import CardTools from '../../../../../components/CardTools';

function BankDataModal({
  openModal,
  setOpenModal,
  title,
  socialReason,
  bankName,
  agency,
  accountNumber,
  cnpj,
}) {
  function handleClose() {
    setOpenModal(false);
  }

  return (
    <PrimaryModal
      open={openModal}
      onCloseDialog={handleClose}
      title={title}
      purpleButton
      onConfirmClicked={handleClose}
      confirmButtonText="Ok"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <CardTools.CardInfo title="Razão Social" description={socialReason} />

        <CardTools.CardInfo title="Banco" description={bankName} />

        <CardTools.CardInfo title="Agência" description={agency} />

        <CardTools.CardInfo title="Conta" description={accountNumber} />

        <CardTools.CardInfo title="CNPJ" description={cnpj} />
      </Box>
    </PrimaryModal>
  );
}

export default BankDataModal;
