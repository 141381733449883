import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as FinishedRegisterSVG } from '../../../assets/finishedRegister.svg';
import InfoGrid from './UI/InfoGrid';
import styles from './styles';

const isMobile = window.innerWidth <= 500 || window.innerHeight <= 500;

const EmployeesBatchRegistrationEnd = () => {
  const history = useHistory();
  const onButtonClick = () => {
    history.push('/employees');
  };
  const { linked, created } = useSelector(
    state => state.excelFileDataReducer
  );

  return (
    <div>
      <Typography style={styles.titleStyle}>
        Cadastrar via planilha do Excel
      </Typography>
      <div style={isMobile ? styles.mobileContentStyle : styles.contentStyle}>
        <FinishedRegisterSVG />
        <Typography style={styles.biggerTextStyle}>
          Funcionários cadastrados com sucesso
        </Typography>
        <Typography style={styles.smallerTextStyle}>
          Comunique aos funcionários que seus cartões já estão a caminho!
        </Typography>
        <div style={{ padding: 15 }}>
          <InfoGrid newAccounts={created} boundAccounts={linked} />
        </div>
        <Button
          variant="outlined"
          style={styles.buttonColor}
          onClick={() => onButtonClick()}
        >
          Ir para gerenciar colaboradores
        </Button>
      </div>
    </div>
  );
};

export default EmployeesBatchRegistrationEnd;
