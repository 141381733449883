import React from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import styles from './styles.module.css';

export default function InfoGrid({ hidden, isLoading, INSURANCE_IMPORT_DATA }) {
  return (
    <div className={hidden ? styles.hidden : styles.infoGridContainer}>
      <h3 className={styles.infoGridTitle}>
        As informações abaixo estão corretas?
      </h3>

      <div className={styles.grid}>
        <span>Nome do arquivo</span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>{INSURANCE_IMPORT_DATA.name}</p>
        )}
      </div>

      <div className={styles.grid}>
        <span>Data de cadastro</span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>{moment().format('DD/MM/YYYY') || '-'}</p>
        )}
      </div>

      <div className={styles.grid}>
        <span>Tipo de seguro</span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>{INSURANCE_IMPORT_DATA.insuranceType}</p>
        )}
      </div>

      <div className={styles.grid}>
        <span>Regime</span>

        {isLoading ? (
          <Skeleton
            height={24}
            width={140}
            color="#202020"
            highlightColor="#444"
            className={styles.skeleton}
          />
        ) : (
          <p>{INSURANCE_IMPORT_DATA.contractType}</p>
        )}
      </div>
    </div>
  );
}
