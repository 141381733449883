import { TableFooter, TablePagination } from '@material-ui/core';
import React from 'react';
import { PaginationComponent } from './Components/PaginationComponent';

export function EnhancedTableFooter({
  dataLength = 5,
  page = 0,
  rowsPerPageOptions = [5, 10, 50],
  rowsPerPage = 5,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
}) {
  return (
    <TableFooter
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        position: 'sticky',
        left: 0,
      }}
    >
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={dataLength}
        rowsPerPage={rowsPerPage}
        page={page}
        component="div"
        labelRowsPerPage="Exibindo"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${dataLength}`}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        ActionsComponent={PaginationComponent}
      />
    </TableFooter>
  );
}
