import React from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import DescriptionIcon from '@material-ui/icons/Description';

export function useEmployeesAprovedSituations() {
  function handleEmployeeSituation(situation) {
    const situationEnums = {
      ACTIVE: 'info',
      RESIGN: 'error',
      INSS_LICENSE: 'success',
      UNPAID_LICENSE: 'success',
      HOLIDAYS: 'success',
    };
    return situationEnums[situation];
  }

  function handleEmployeeSituationIcon(situation) {
    const situationEnums = {
      ACTIVE: <CheckCircleRoundedIcon style={{ color: '#2D54B9' }} />,
      RESIGN: <RemoveCircleRoundedIcon style={{ color: '#CA2317' }} />,
      INSS_LICENSE: <DescriptionIcon style={{ color: '#19797F' }} />,
      UNPAID_LICENSE: <DescriptionIcon style={{ color: '#19797F' }} />,
      HOLIDAYS: <BeachAccessIcon style={{ color: '#19797F' }} />,
    };
    return situationEnums[situation];
  }

  return {
    handleEmployeeSituation,
    handleEmployeeSituationIcon,
  };
}
