import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Menu, MenuItem } from '@material-ui/core';

import Title from '../../../../../components/Title';
import AlreadyUploadModal from '../../../../../components/PrimaryModal';

import { pusherStatus } from '../../../../../enums/pusher';

import styles from '../../styles.module.css';

import { ReactComponent as SelectBoxIcon } from '../../../../../assets/addIconWhite.svg';
import { ReactComponent as FilterIcon } from '../../../../../assets/filter-icon.svg';

export default function Header({ setOpenDrawer }) {
  const history = useHistory();
  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const UPLOAD_DATA_INSURANCE = JSON.parse(
    localStorage.getItem(`uploadDataInsurance-${COMPANY_CODE}`),
  );

  const { PROCESSING } = pusherStatus;

  function addNewInsured(event) {
    setAnchorEl(event.currentTarget);
  }

  function goToSingleRegister() {
    history.push(`/insurance-management/insurance-single`);
  }

  function goToBatchRegister() {
    history.push(`/insurance-management/batch-registration/menu`);
  }

  const goToProcess = () => {
    history.push('/insurance-management/batch-registration/menu/validate');
  };

  function closeMenu() {
    setAnchorEl(null);
  }

  function checkUploadInProgress() {
    if (
      UPLOAD_DATA_INSURANCE &&
      UPLOAD_DATA_INSURANCE?.status === PROCESSING &&
      UPLOAD_DATA_INSURANCE?.companyCode === COMPANY_CODE
    ) {
      setAlreadyUploadModal(true);
    } else {
      goToBatchRegister();
    }
  }

  const onCancelAlreadyModal = () => {
    setAlreadyUploadModal(false);
    closeMenu();
  };

  return (
    <>
      <div className={styles.header}>
        <Title className={styles.title}>Seguros</Title>

        <Button
          className={styles.filterButton}
          onClick={() => setOpenDrawer(true)}
        >
          <FilterIcon className={styles.selectBoxItem} />
          Filtrar
        </Button>

        <Button onClick={addNewInsured} className={styles.buttonHeader}>
          <SelectBoxIcon className={styles.selectBoxItem} />
          Adicionar segurados
        </Button>
      </div>

      <div className={styles.menuList}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          className={styles.menuListArea}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem onClick={goToSingleRegister}>
            <span>Cadastrar individualmente</span>
          </MenuItem>
          <MenuItem onClick={checkUploadInProgress}>
            <span>Cadastrar a partir de um arquivo</span>
          </MenuItem>
        </Menu>
      </div>

      <AlreadyUploadModal
        open={alreadyUploadModal}
        onConfirmClicked={goToProcess}
        onCancelButtonClicked={onCancelAlreadyModal}
        confirmButtonText="Sim"
        cancelButtonText="Não"
        title="Já existe um arquivo em processamento. Deseja acompanhar seu status?"
        text="Você só poderá iniciar um novo cadastro, quando o que está em andamento for concluído."
      />
    </>
  );
}
