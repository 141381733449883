import moment from 'moment';
import * as yup from 'yup';

export default yup.object().shape({
  initialMovementHour: yup
    .string()
    .required()
    .test('is-start-min', 'O horário mínimo permitido é 06:00', value =>
      moment(value, 'HH:mm').isSameOrAfter(moment('06:00', 'HH:mm')),
    )
    .test('is-start-max', 'O horário máximo permitido é 22:00', value =>
      moment(value, 'HH:mm').isSameOrBefore(moment('22:00', 'HH:mm')),
    )
    .test(
      'is-start-full',
      'Por favor, selecione um horário fechado (ex.: 18:00, 19:00)',
      value => moment(value, 'HH:mm').minutes() === 0,
    ),
  finalMovementHour: yup
    .string()
    .required()
    .test(
      'is-greater',
      'O horário final deve ser posterior ao inicial',
      function(value) {
        const { initialMovementHour } = this.parent;

        if (initialMovementHour) {
          return moment(value, 'HH:mm').isAfter(
            moment(initialMovementHour, 'HH:mm'),
          );
        }
      },
    )
    .test('is-end-min', 'O horário mínimo permitido é 06:00', value =>
      moment(value, 'HH:mm').isSameOrAfter(moment('06:00', 'HH:mm')),
    )
    .test('is-end-max', 'O horário máximo permitido é 22:00', value =>
      moment(value, 'HH:mm').isSameOrBefore(moment('22:00', 'HH:mm')),
    )
    .test(
      'is-end-full',
      'Por favor, selecione um horário fechado (ex.: 18:00, 19:00)',
      value => moment(value, 'HH:mm').minutes() === 0,
    ),
});
