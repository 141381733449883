import { api } from '../../../../services/api';

export default class ImportPayrollServices {
  static getListOfTypes(objectOptions) {
    api
      .get('/employeesPayments/payments/types', objectOptions.requestOptions)
      .then(({ data }) => {
        objectOptions.setStates(data);
      });
  }
}
