import * as yup from 'yup';
import moment from 'moment';

export default yup.object().shape({
  keyType: yup.string().required(),
  key: yup.string().required(),
  value: yup
    .number()
    .required()
    .min(0.01),
  paymentDate: yup
    .date()
    .min(
      moment().startOf('day'),
      `A data deve ser igual ou posterior à data atual.`,
    )
    .required(),
  description: yup.string().test({
    name: 'description-length',
    test: val => (val ? val.toString().length <= 140 : true),
    message: 'Máximo de 140 caracteres',
  }),
});
