import React from 'react';

import ManualPaymentCardIcon from '@material-ui/icons/ListRounded';
import PaymentCardIcon from '@material-ui/icons/DescriptionRounded';

import styles from './styles.module.css';

export const actions = [
  {
    icon: <ManualPaymentCardIcon className={styles.iconColor}/>,
    name: 'Pagamento manual',
    onClick: '/payroll/manual-payroll',
    value: 'MANUAL_PAYMENT'
  },
  {
    icon: <PaymentCardIcon className={styles.iconColor} />,
    name: 'Pagamento via arquivo',
    onClick: '/payroll/menu',
    value: 'FILE_PAYMENT'
  },
]