import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PusherJs } from '../../../../../utils/pusherv2';
import { pusherEvents, pusherStatus } from '../../../../../enums/pusher';
import { ActionsEmployeeDetails } from '../../../../../redux/store/Employees/EmployeeDetails';

export function usePaymentHistory() {
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.companies);
  const { downloadMultipleReport } = useSelector(
    state => state.employees.employeeDetails.paymentHistory,
  );

  const channel = useMemo(() => new PusherJs(), []);

  const pageSize = sessionStorage.getItem('TablePageSize') || 5;

  useEffect(() => {
    if (downloadMultipleReport?.successOnSendList) {
      channel.listen(
        currentCompany?.code,
        pusherEvents.RECEIPT_PAYMENT,
        async pusherData => {
          if (pusherData.status === pusherStatus.PROCESSING) {
            dispatch(
              ActionsEmployeeDetails.setDownloadMultipleFilesPercentage(
                pusherData.percentage,
              ),
            );
          }

          if (pusherData.status === pusherStatus.PROCESSED) {
            dispatch(
              ActionsEmployeeDetails.getDownloadMultiplePaymentReport(
                pusherData.data,
              ),
            );

            dispatch(
              ActionsEmployeeDetails.setDownloadMultipleFilesPercentage(null),
            );

            dispatch(
              ActionsEmployeeDetails.resetDownloadMultipleReportSuccessOnSendList(),
            );

            channel.pop();
          }
        },
      );
    }
  }, [downloadMultipleReport?.successOnSendList]);

  useEffect(() => {
    dispatch(ActionsEmployeeDetails.setPaymentHistoryPage(0));
    dispatch(ActionsEmployeeDetails.setPaymentHistoryPerPage(pageSize));
    dispatch(ActionsEmployeeDetails.searchPaymentHistory());

    return () => {
      dispatch(ActionsEmployeeDetails.setPaymentHistoryPage(0));
      dispatch(ActionsEmployeeDetails.setPaymentHistoryPerPage(pageSize));
      dispatch(
        ActionsEmployeeDetails.resetDownloadMultipleReportSelectedReports(),
      );
    };
  }, []);
}
