const styles = {
  iconStyle: {
    fontSize: '40px',
    lineHeight: '21px',
    color: '#1B181C',
    opacity: 0.24,
  },
  textStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '36px',
    textAlign: 'center',
    color: '#9E9E9E',
  },
  smallTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    color: '#9E9E9E',
    letterSpacing: '0.4px',
  },
  centerText: {
    textAlign: 'center',
    height: '8em',
    width: '8em',
  },
};

export default styles;
