import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Form from '../../../../../components/Form';
import Title from '../../../../../components/Title';
import BottomBar from '../../../../../components/BottomBar';
import Buttons from '../../../../../components/Buttons';
import FaceAuthModal from '../FaceAuthModal';

import useNewUserPage from './hooks/useNewUserPage';

import { isMobile } from '../../../../../utils/breakpoints';

import { useStyles } from '../../styles';

export default function NewUserPage() {
  const styles = useStyles();
  const history = useHistory();

  const {
    control,
    onContinue,
    errors,
    userOptions,
    isValid,
    isLoading,
    isSending,
  } = useNewUserPage();

  const goBack = () => history.goBack();

  return (
    <>
      <Title>Novo utilizador</Title>
      <Typography variant="h2" className={styles.subtitle}>
        Preencha os campos abaixo para cadastrar uma nova configuração.
      </Typography>

      <Form.FormBase>
        <Box className={styles.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Form.TextField
                    label="Nome*"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="cpf"
                render={({ field }) => (
                  <Form.MaskedField
                    mask="000.000.000-00"
                    label="CPF*"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    error={!!errors?.cpf}
                    helperText={errors?.cpf?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Form.TextField
                    label="E-mail*"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Form.MaskedField
                    mask="(00) 00000-0000"
                    label="Celular*"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    error={!!errors?.phone}
                    helperText={errors?.phone?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Form.RadioCardOptions
                    size="medium"
                    label="Qual é o nível de acesso desse usuário?"
                    row={!isMobile}
                    options={userOptions}
                    {...field}
                    error={!!errors?.role}
                    helperText={errors?.role?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <BottomBar>
            <Buttons.PrimaryButton
              variant="outlined"
              color="primary"
              title="Cancelar"
              onClick={goBack}
            />
            <Buttons.PrimaryButton
              color="primary"
              title="Continuar"
              type="submit"
              disabled={!isValid}
              loading={isLoading || isSending}
              onClick={onContinue}
            />
          </BottomBar>
        </Box>
      </Form.FormBase>
      <FaceAuthModal />
    </>
  );
}
