import React from 'react';

import {
  Button,
  Dialog,
  Typography,
	FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '../Checkbox/PurpleCheckbox';

import styles from './styles.module.css';

const useStyles = makeStyles(() => ({
	paper: { width: "341px" },
}));

export default function CheckModal({
	open,
	onCloseDialog,
	lifeInsurance,
	healthInsurance,
	dentalInsurance,
	handleChangeLifeInsurance,
	handleChangeHealthInsurance,
	handleChangeDentalInsurance,
	onCancel,
	onConfirm
}) {
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={onCloseDialog}
			fullWidth
			maxWidth='xs'
			classes={{paper: classes.paper}}
		> 
			<div className={styles.container}>
				<Typography className={styles.title}>
					Adicionar seguro
				</Typography>

				<div className={styles.optionsContainer}>
					<div>
						<Typography className={styles.optionLabel}>
							Vida
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={lifeInsurance}
									onChange={handleChangeLifeInsurance}
								/>
							}
						/>
					</div>

					<div>
						<Typography className={styles.optionLabel}>
							Saúde
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={healthInsurance}
									onChange={handleChangeHealthInsurance}
								/>
							}
						/>
					</div>

					<div>
						<Typography className={styles.optionLabel}>
							Odontológico
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={dentalInsurance}
									onChange={handleChangeDentalInsurance}
								/>
							}
						/>
					</div>
				</div>

				<div className={styles.buttonContainer}>
					<Button className={styles.cancelButton} onClick={onCancel}>
						Cancelar
					</Button>
					<Button className={styles.addContainer} onClick={onConfirm}>
						Adicionar
					</Button>
				</div>
			</div>
		</Dialog>
	)
}
