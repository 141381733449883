import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import ProgressToolBar from '../../../components/ProgressToolBar';
import { ReactComponent as LotRegistration } from '../../../assets/lotRegistration.svg';
import styles from './styles';

const isMobile = window.innerHeight <= 500 || window.innerWidth <= 500;

const EmployeesBatchRegistrationMenu = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const onBackClicked = () => {
    history.push('/employees');
  };

  const onNextClicked = () => {
    history.push(`${url}/menu`);
  };

  return (
    <div>
      <Typography style={styles.titleStyle}>
        Cadastrar a partir de um arquivo
      </Typography>
      <div style={isMobile ? styles.mobileContentStyle : styles.contentStyle}>
        <LotRegistration
          style={isMobile ? styles.mobileContainerWidth : styles.ContainerWidth}
        />
        <Typography style={styles.biggerTextStyle}>
          Ao concluir o cadastro de funcionários,
        </Typography>
        <Typography style={styles.biggerTextStyleTwo}>
          os cartões serão solicitados automaticamente.
        </Typography>
        <Typography style={styles.smallerTextStyle}>
          Não se preocupe. Criaremos cartões apenas para quem não tem ainda.
        </Typography>
      </div>

      <ProgressToolBar
        currentStep={0}
        onBackClicked={onBackClicked}
        onNextClicked={onNextClicked}
      />
    </div>
  );
};

export default EmployeesBatchRegistrationMenu;
