import React from 'react';

import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Hidden,
} from '@material-ui/core';

import styles from './styles.module.css';

function Filters({
  filterPaymentType,
  onChangePaymentType,
  handleChangeSituation,
  situation,
}) {
  return (
    <div variant="outlined" className={styles.filters}>
      <FormControl variant="outlined" className={styles.inputType}>
        <InputLabel id="outlined-type" className={styles.labelType}>
          Tipo de pagamento
        </InputLabel>
        <Select
          labelId="outlined-type"
          id="outlined-type"
          value={filterPaymentType}
          onChange={onChangePaymentType}
          label="Tipo"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="PIX">Pix</MenuItem>
          <MenuItem value="BANK_SLIP">Boleto bancário</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={styles.inputSituation}>
        <InputLabel id="outlined-situation">Situação</InputLabel>
        <Select
          labelId="outlined-situation"
          id="outlined-situation"
          label="Situação"
          onChange={handleChangeSituation}
          value={situation}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="CREATE">Criado</MenuItem>
          <MenuItem value="PENDING">Pendente</MenuItem>
          <MenuItem value="FAILED">Falha</MenuItem>
          <MenuItem value="DRAFT">Rascunho</MenuItem>
          <MenuItem value="PAID">Pago</MenuItem>
          <MenuItem value="FULFILLED">Pagamento realizado</MenuItem>
          <MenuItem value="ERROR">Erro</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default Filters;
