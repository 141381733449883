import React, { useState } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import style from './style.module.css';
import CurrencyInput from 'react-currency-input';

function ApplyValueModal({ open, onCloseDialog, setShowSuccessAlert }) {
  const dispatch = useDispatch();
  const [inputValueModal, setInputValueModal] = useState('');

  const onChangeCurrencyTextField = value => {
    setInputValueModal(value);
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} maxWidth="77vw">
      <div className={style.dialog}>
        <div>
          <Typography className={style.biggerText}>
            Aplicar mesmo valor
          </Typography>
          <Typography className={style.smallText}>
            Esta alteração será aplicada a todos da lista
          </Typography>
        </div>
        <div>
          <CurrencyInput
            onChange={(event, value) => {
              onChangeCurrencyTextField(value);
            }}
            onFocus={() => setInputValueModal('0')}
            value={inputValueModal}
            className={style.inputValue}
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
            label
          />
        </div>
        <div className={style.modalFooter}>
          <Button className={style.cancelButton} onClick={onCloseDialog}>
            Cancelar
          </Button>
          <Button
            className={style.applyButton}
            onClick={() => {
              dispatch({
                type: 'SET_VALUE_INPUTS',
                inputValue: inputValueModal,
              });
              setShowSuccessAlert();
            }}
          >
            Aplicar
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ApplyValueModal;
