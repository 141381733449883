import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { Typography, IconButton } from '@material-ui/core';

import styles from './styles.module.css';

function FakeItemActivities() {
  return (
    <div className={styles.activitiesItemExpired}>
      <div className={styles.content}>
        <Typography className={styles.subTitle}>
          <Skeleton
            className={styles.skeleton}
            color="#202020"
            highlightColor="#444"
          />
        </Typography>
      </div>
      <div className={styles.content}>
        <Typography className={styles.subTitle}>
          <Skeleton
            className={styles.skeleton}
            color="#202020"
            highlightColor="#444"
          />
        </Typography>
      </div>
    </div>
  );
}

export default FakeItemActivities;
