import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '2rem',
    },
    title: {
      padding: 0,
      fontWeight: 700,
      fontSize: '1.25rem',
      marginBottom: '0.75rem',
      color: '#1A1A1A',
    },
    valueText: {
      color: theme.palette.primary.main,
    },
    content: {
      padding: 0,
      marginBottom: '2rem',
    },
    contentText: {
      color: '#4D4D4D',
    },
    textArea: {
      marginTop: '1.5rem',
    },
    actions: {
      padding: 0,
    },
    approvalError: {
      margin: '2rem 0',
    },
    lockedIcon: {
      marginBottom: '2rem',
    },
    pinField: {
      marginBottom: '1rem',
    },
    resendButton: {
      '&.Mui-disabled': {
        color: theme.palette.primary.light,
      },
    },
    successIcon: {
      width: '100%',
      marginBottom: '2rem',
    },
    successActions: {
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
