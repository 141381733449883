import React from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

export function EnhancedTableHead({
  tableConfig = [],
  orderBy = '',
  orderDirection = 'asc',
  handleSortChange = () => {},
}) {
  return (
    <TableHead>
      <TableRow>
        {tableConfig.some(item => item?.type === 'select') && (
          <TableCell>
            {tableConfig.find(item => item?.type === 'select')?.label()}
          </TableCell>
        )}

        {tableConfig
          .filter(value => value?.type === 'field')
          ?.map(field =>
            field?.hasOrder ? (
              <TableCell key={field.key}>
                <TableSortLabel
                  active={orderBy === field?.key}
                  direction={orderBy === field?.key ? orderDirection : 'asc'}
                  onClick={handleSortChange(field?.key)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    {field?.label}
                    {field?.customLabel ? field.customLabel() : null}
                  </Box>
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell key={field.key}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                >
                  {field?.label}
                  {field?.customLabel ? field.customLabel() : null}
                </Box>
              </TableCell>
            ),
          )}

        {tableConfig.some(value => value?.type === 'action') && (
          <TableCell>Ações</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
