/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { api } from '../../../../services/api';
import { PaginationConstructor } from '../../helpers/PaginationConstructor';

const registerFilesGeneralBase = new PaginationConstructor({
  extraStates: {
    registerFilesGeneral: {
      filesList: [],
      isLoading: false,
      errorOn: {
        hasError: false,
        message: '',
      },
    },
    filter: {
      cpf: '',
      initialDate: null,
      finalDate: null,
    },
  },
});

const searchEmployeesRegisterFiles = createAsyncThunk(
  'employeesRegisterFiles/search',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      filter: { cpf, initialDate, finalDate },
      page,
      perPage,
    } = getState().employees.employeesRegisterFiles.general;
    const isInitialDateValid = initialDate && initialDate !== 'Data inválida';
    const isFinalDateValid = finalDate && finalDate !== 'Data inválida';

    const requestConfig = {
      params: {
        page,
        size: perPage,
      },
    };

    const requestBody = {
      cpf: cpf ?? '',
      initialDate: isInitialDateValid
        ? moment(initialDate).format('YYYY-MM-DD')
        : moment()
            .add(-1, 'years')
            .format('YYYY-MM-DD'),
      finalDate: isFinalDateValid
        ? moment(finalDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      companyCode: currentCompany.code,
    };

    return api
      .post('files/employees', requestBody, requestConfig)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const employeesRegisterFilesSlice = createSlice({
  name: 'employeesRegisterFilesGeneral',
  initialState: registerFilesGeneralBase.initialState,
  reducers: registerFilesGeneralBase.methods,
  extraReducers: {
    [searchEmployeesRegisterFiles.pending]: state => {
      state.registerFilesGeneral.isLoading = true;
      state.registerFilesGeneral.errorOn = {
        hasError: false,
        message: '',
      };
    },
    [searchEmployeesRegisterFiles.fulfilled]: (state, { payload }) => {
      state.registerFilesGeneral.isLoading = false;
      state.registerFilesGeneral.filesList = payload.data.content;
      state.length = payload.data.totalSize;
    },

    [searchEmployeesRegisterFiles.rejected]: (state, { payload }) => {
      state.registerFilesGeneral.isLoading = false;
      state.registerFilesGeneral.errorOn.hasError = true;

      if (!payload) {
        state.registerFilesGeneral.errorOn.message =
          'Houve um erro ao buscar os arquivos de cadastro';
      } else state.registerFilesGeneral.errorOn.message = payload;
    },
  },
});

const employeesRegisterFilesGeneralReducer =
  employeesRegisterFilesSlice.reducer;
const ActionsEmployeesRegisterFilesGeneral = {
  ...employeesRegisterFilesSlice.actions,
  searchEmployeesRegisterFiles,
};

export {
  employeesRegisterFilesGeneralReducer,
  ActionsEmployeesRegisterFilesGeneral,
};
