export const statusMap = {
  CANCELED: {
    label: 'Cancelada',
    color: 'rgba(202, 35, 23, 1)',
    background: 'rgba(202, 35, 23, 0.1)',
  },
  EFFECTED: {
    label: 'Efetuada',
    color: 'rgba(25, 121, 127, 1)',
    background: 'rgba(25, 121, 127, 0.1)',
  },
  ERROR: {
    label: 'Erro',
    color: 'rgba(202, 35, 23, 1)',
    background: 'rgba(202, 35, 23, 0.1)',
  },
  INSUFFICIENT_BALANCE_ERROR: {
    label: 'Saldo insuficiente',
    color: 'rgba(227, 159, 23, 1)',
    background: 'rgba(227, 159, 23, 0.1)',
  },
  PROCESSING: {
    label: 'Processando',
    color: 'rgba(227, 159, 23, 1)',
    background: 'rgba(227, 159, 23, 0.1)',
  },
  WAITING_APPROVE: {
    label: 'Esperando aprovação',
    color: 'rgba(227, 159, 23, 1)',
    background: 'rgba(227, 159, 23, 0.1)',
  },
};

export const requestTypeOptions = [
  { label: 'Selecione...', value: '' },
  { label: 'Pix', value: 'PIX' },
  { label: 'Boleto', value: 'BILL_PAYMENT' },
  { label: 'Transferência Somapay', value: 'INTERNAL_TRANSFER' },
];
