import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';

import formatCnpj from '../../../../../../utils/formatCnpj';
import formatCPF from '../../../../../../utils/formatCPF';

import { useStyles } from '../styles';

export default function DrawerReceiverTab({ selected }) {
  const styles = useStyles();

  const renderReceiverDocument =
    selected?.receiverDocument.length === 14
      ? formatCnpj(selected.receiverDocument)
      : formatCPF(selected.receiverDocument);

  const renderDateTime = date =>
    date ? moment(date).format('DD/MM/YYYY HH:mm') : '-';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" className={styles.receiverTitle}>
          Dados do recebedor
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={styles.receiverLabel} variant="h3">
          Nome do recebedor
        </Typography>
        <Typography className={styles.receiverData} variant="caption">
          {selected?.receiverName || '-'}
        </Typography>
      </Grid>
      {selected?.receiverPixKey ? (
        <Grid item xs={12}>
          <Typography className={styles.receiverLabel} variant="h3">
            Chave Pix
          </Typography>
          <Typography className={styles.receiverData} variant="caption">
            {selected?.receiverPixKey}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography className={styles.receiverLabel} variant="h3">
              Agência
            </Typography>
            <Typography className={styles.receiverData} variant="caption">
              {selected?.receiverAgency || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.receiverLabel} variant="h3">
              Conta
            </Typography>
            <Typography className={styles.receiverData} variant="caption">
              {selected?.receiverAccount || '-'}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography className={styles.receiverLabel} variant="h3">
          Instituição bancária
        </Typography>
        <Typography className={styles.receiverData} variant="caption">
          {selected?.receiverBankName || '-'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={styles.receiverLabel} variant="h3">
          CPF/CNPJ
        </Typography>
        <Typography className={styles.receiverData} variant="caption">
          {renderReceiverDocument}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={styles.receiverLabel} variant="h3">
          Data de pagamento
        </Typography>
        <Typography className={styles.receiverData} variant="caption">
          {renderDateTime(selected?.releaseDate)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={styles.receiverLabel} variant="h3">
          Descrição
        </Typography>
        <Typography className={styles.receiverData} variant="caption">
          {selected?.description || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
}
