import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  rootProgressBar: {
    width: '100%',
    position: 'relative',
    bottom: '159px'
  },
});

export default function ProgressBar({ progress }) {
  const classes = useStyles();

  return (
    <div className={classes.rootProgressBar}>
      <LinearProgress
        style={{
          height: 127,
          opacity: .3,
          borderRadius: 12
        }}
        variant="determinate"
        value={progress}
      />
    </div>
  );
}
