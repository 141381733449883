import React from 'react';
import {
  AccessTime,
  Cancel,
  CheckCircle,
  Description,
} from '@material-ui/icons';

export function useStatisticsComponentsData(quantities = {}) {
  function handleCalculatePercentage(value) {
    return Math.floor((value * 100) / quantities?.totalLines);
  }

  return [
    {
      icon: (
        <Description
          style={{
            color: '#4D4D4D',
            fontSize: '2rem',
            position: 'absolute',
          }}
        />
      ),
      title: 'Total',
      numberValue: quantities?.totalLines,
      numberColor: '#FFFFFF',
      numberBoxColor: '#4D4D4D',
      numberLabel: 'funcionários',
      circularProgressValue: handleCalculatePercentage(quantities?.totalLines),
      circularProgressColor: '#4D4D4D',
    },
    {
      icon: (
        <CheckCircle
          style={{ color: '#19797F', fontSize: '2rem', position: 'absolute' }}
        />
      ),
      title: 'Criados',
      numberValue: quantities?.created,
      numberColor: '#19797F',
      numberBoxColor: '#E8F2F2',
      numberLabel: 'funcionários',
      circularProgressValue: handleCalculatePercentage(quantities?.created),
      circularProgressColor: '#19797F',
    },
    {
      icon: (
        <AccessTime
          style={{ color: '#F19207', fontSize: '2rem', position: 'absolute' }}
        />
      ),
      title: 'Pendentes',
      numberValue: quantities?.pending,
      numberColor: '#F19207',
      numberBoxColor: '#F192071A',
      numberLabel: 'funcionários',
      circularProgressValue: handleCalculatePercentage(quantities?.pending),
      circularProgressColor: '#F19207',
    },
    {
      icon: (
        <Cancel
          style={{ color: '#CA2317', fontSize: '2rem', position: 'absolute' }}
        />
      ),
      title: 'Reprovados',
      numberValue: quantities?.reproved,
      numberColor: '#CA2317',
      numberBoxColor: '#FAE9E8',
      numberLabel: 'funcionários',
      circularProgressValue: handleCalculatePercentage(quantities?.reproved),
      circularProgressColor: '#CA2317',
    },
  ];
}
