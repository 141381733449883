import React from 'react';
import { Button, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import styles from './styles.module.css';

import {
  RenderAuthorizerDetailsCollapse,
  RenderPersonalDepartmentDetailsCollapse,
  RenderFinancialCollapse,
} from './CollapseDetails';

function ContentDrawer() {
  const [checked, setChecked] = React.useState(false);
  const [
    checkedPersonalDepartment,
    setCheckedPersonalDepartment,
  ] = React.useState(false);
  const [financialChecked, setFinancialChecked] = React.useState(false);

  const LABEL_DETAILS = checked ? 'Ver menos' : 'Ver mais';
  const LABEL_PD = checkedPersonalDepartment ? 'Ver menos' : 'Ver mais';
  const LABEL_FINANCIAL = financialChecked ? 'Ver menos' : 'Ver mais';

  function handleOpenDetails() {
    setChecked(prev => !prev);
  }

  function handleOpenDetailsPersonalDepartment() {
    setCheckedPersonalDepartment(prev => !prev);
  }

  function handleOpenDetailsFinancial() {
    setFinancialChecked(prev => !prev);
  }

  return (
    <Grid container direction="column">
      <Grid item container direction="row" className={styles.profileContainer}>
        <Grid item className={styles.profileTitleContainer}>
          <p>Administrador</p>
        </Grid>
        <Grid item>
          <p className={styles.text}>
            Acesso a todas as funcionalidades da plataforma, exceto autorização
            de pagamento.
          </p>
        </Grid>
      </Grid>

      <Grid item container direction="row" className={styles.profileContainer}>
        <Grid item className={styles.profileTitleContainer}>
          <p>Autorizador</p>
        </Grid>
        <Grid item>
          <p className={styles.text}>
            Acesso a funcão de autorizar
            <br /> pagamentos e liberação imediata.
          </p>
        </Grid>

        <Grid item container direction="column">
          <RenderAuthorizerDetailsCollapse checked={checked} />
          <Grid item>
            <Button
              startIcon={
                checked ? (
                  <RemoveCircleOutlineIcon className={styles.addIcon} />
                ) : (
                  <AddCircleOutlineIcon className={styles.addIcon} />
                )
              }
              className={styles.moreButton}
              onClick={handleOpenDetails}
            >
              <p>{LABEL_DETAILS}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container direction="row" className={styles.profileContainer}>
        <Grid item className={styles.profileTitleContainer}>
          <p>Departamento pessoal</p>
        </Grid>
        <Grid item>
          <p className={styles.text}>
            Acesso a gestão de pagamentos de
            <br /> folha e gestão de funcionários.
          </p>
        </Grid>
        <Grid item column direction="row">
          <RenderPersonalDepartmentDetailsCollapse
            checkedPersonalDepartment={checkedPersonalDepartment}
          />
          <Grid item>
            <Button
              startIcon={
                checkedPersonalDepartment ? (
                  <RemoveCircleOutlineIcon className={styles.addIcon} />
                ) : (
                  <AddCircleOutlineIcon className={styles.addIcon} />
                )
              }
              className={styles.moreButton}
              onClick={handleOpenDetailsPersonalDepartment}
            >
              <p>{LABEL_PD}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container direction="row" className={styles.profileContainer}>
        <Grid item className={styles.profileTitleContainer}>
          <p>Financeiro</p>
        </Grid>
        <Grid item>
          <p className={styles.text}>
            Acesso a gestão de pagamentos e gerenciamento financeiro (depósitos
            e extratos).
          </p>
        </Grid>
        <Grid item column direction="column">
          <RenderFinancialCollapse financialChecked={financialChecked} />
          <Grid item>
            <Button
              startIcon={
                financialChecked ? (
                  <RemoveCircleOutlineIcon className={styles.addIcon} />
                ) : (
                  <AddCircleOutlineIcon className={styles.addIcon} />
                )
              }
              className={styles.moreButton}
              onClick={handleOpenDetailsFinancial}
            >
              <p>{LABEL_FINANCIAL}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContentDrawer;
