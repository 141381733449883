import { Render } from './Render';
import { Loading } from './Loading';
import { Empty } from './Empty';
import { Error } from './Error';

export default {
  Render,
  Loading,
  Empty,
  Error,
};
