import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Menu,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import moment from 'moment';
import GeneralInfo from './GeneralInfo';
import Table from './Table';
import FakeTable from './FakeTable';
import LogoLoading from '../../../../components/LogoLoading';
import Error from './Error';
import BottomBar from './BottomBar';
import PercentageCircularProgress from '../../../../components/PercentageCircularProgress';
import ErrorDialog from '../../../../components/ErrorDialog';

import { api } from '../../../../services/api';
import { pusherChannels } from '../../../../enums/pusher';
import { pusher } from '../../../../utils/pusher';

import styles from './styles.module.css';
import convertBytesToBlobDowload from '../../../../utils/convertBytesToBlobDowload';
import convertBytesToBlobDowloadXls from '../../../../utils/convertBytesToBlobDowloadXls';
import { ReactComponent as DownloadIcon } from '../../../../assets/fileDownloadWhite.svg';

export default () => {
  const history = useHistory();
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const {
    CONSOLIDATED_VALUES_EMPLOYEES_REPORT_FINISHED,
    CONSOLIDATED_VALUES_EMPLOYEES_REPORT_PROCESSING,
    CONSOLIDATED_VALUES_LOANS_REPORT_FINISHED,
    CONSOLIDATED_VALUES_LOANS_REPORT_PROCESSING,
  } = pusherChannels;
  const [companyCompetence, setCompanyCompetence] = useState('');
  const [employeesList, setEmployeesList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [employeesListLoaded, setEmployeesListLoaded] = useState(false);
  const [employeesListLoading, setEmployeesListLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [cpfOrNameValue, setCpfOrNameValue] = useState('');
  const [status, setStatus] = useState('Todos');
  const [situationOptions, setSituationOptions] = useState([]);
  const [downloadButtonAnchor, setDownloadButtonAnchor] = useState('');
  const [extractDownloading, setExtractDownloading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [handleImportErrorModal, setHandleImportErrorModal] = useState(false);
  const [importErrorDescription, setImportErrorDescription] = useState('');

  const handleCpfOrNameInput = ({ target }) => {
    setCpfOrNameValue(target.value);
  };

  const handleChangeStatus = ({ target }) => {
    setStatus(target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const sort = tableHeadId => {
    if (orderBy === tableHeadId) {
      setOrder(prevState => (prevState === 'asc' ? 'desc' : 'asc'));
    }
    setOrderBy(tableHeadId);
  };

  const handleGoToDetails = (id, cpf, name, competence) => {
    history.push(`/requesting-employees/${id}`, {
      id,
      cpf,
      name,
      competence,
    });
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleDownloadOptions = event => {
    setDownloadButtonAnchor(event.currentTarget);
  };

  const handleDownloadTicket = () => {
    setDownloadButtonAnchor('');
    const requestOptions = {
      params: {
        competence: companyCompetence,
      },
    };
    api
      .get(`consolidatedValues/bankTicket/${companyCode}`, requestOptions)
      .then(data => {
        convertBytesToBlobDowload(data, 'Boleto.pdf');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setHandleImportErrorModal(true);
          setImportErrorDescription(errors[0].errorDetail);
        },
      );
  };

  const handleDownloadExtractEmployees = () => {
    setDownloadButtonAnchor('');
    setExtractDownloading(true);
    const requestOptionsFirstRoute = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      params: {
        competence: companyCompetence,
      },
      responseType: 'blob',
    };
    const requestOptionsSecondRoute = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      responseType: 'blob',
    };

    const sendRequest = new Promise((resolve, reject) => {
      api
        .get(
          `/consolidatedValues/employee/asyncReport/${companyCode}`,
          requestOptionsFirstRoute,
        )
        .then(({ data }) => {
          resolve(data);
        });
    });

    const processingRequest = new Promise((resolve, reject) => {
      sendRequest.then(() => {
        const channel = pusher.subscribe(companyCode);
        channel.bind(CONSOLIDATED_VALUES_EMPLOYEES_REPORT_PROCESSING, function(
          data,
        ) {
          setPercentage(data?.percentage);
        });
        channel.bind(
          CONSOLIDATED_VALUES_EMPLOYEES_REPORT_FINISHED,
          async function(data) {
            setPercentage(100);
            resolve(data);
          },
        );
      });
    });

    const doneRequest = new Promise((resolve, reject) => {
      processingRequest.then(data => {
        api
          .get(
            `/anticipation/report/byId/${data?.data?.reportId}`,
            requestOptionsSecondRoute,
          )
          .then(({ data }) => {
            resolve(data);
          });
      });
    });

    Promise.all([sendRequest, processingRequest, doneRequest]).then(result => {
      convertBytesToBlobDowloadXls(
        result[2],
        'Planilha de funcionários solicitantes',
      );
      setExtractDownloading(false);
      setPercentage(0);
    });
  };

  const handleDownloadExtractLoans = () => {
    setDownloadButtonAnchor('');
    setExtractDownloading(true);
    const requestOptionsFirstRoute = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      params: {
        competence: companyCompetence,
      },
      responseType: 'blob',
    };
    const requestOptionsSecondRoute = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      responseType: 'blob',
    };

    const sendRequest = new Promise((resolve, reject) => {
      api
        .get(
          `/consolidatedValues/loans/asyncReport/${companyCode}`,
          requestOptionsFirstRoute,
        )
        .then(({ data }) => {
          resolve(data);
        });
    });

    const processingRequest = new Promise((resolve, reject) => {
      sendRequest.then(() => {
        const channel = pusher.subscribe(companyCode);
        channel.bind(CONSOLIDATED_VALUES_LOANS_REPORT_PROCESSING, function(
          data,
        ) {
          setPercentage(data?.percentage);
        });
        channel.bind(CONSOLIDATED_VALUES_LOANS_REPORT_FINISHED, async function(
          data,
        ) {
          setPercentage(100);
          resolve(data);
        });
      });
    });

    const doneRequest = new Promise((resolve, reject) => {
      processingRequest.then(data => {
        api
          .get(
            `/anticipation/report/byId/${data?.data?.reportId}`,
            requestOptionsSecondRoute,
          )
          .then(({ data }) => {
            resolve(data);
          });
      });
    });

    Promise.all([sendRequest, processingRequest, doneRequest]).then(result => {
      convertBytesToBlobDowloadXls(result[2], 'Planilha de empréstimos');
      setExtractDownloading(false);
      setPercentage(0);
    });
  };

  const getEmployeesList = () => {
    const { id, competence } = history.location.state;
    setEmployeesListLoading(true);
    const requestOptions =
      status !== 'Todos'
        ? {
            params: {
              page: currentPage,
              size: pageSize,
              sort: `${orderBy},${order}`,
              cpfOrName: cpfOrNameValue,
              situation: status,
            },
          }
        : {
            params: {
              page: currentPage,
              size: pageSize,
              sort: `${orderBy},${order}`,
              cpfOrName: cpfOrNameValue,
            },
          };
    api
      .get(`consolidatedValues/employee/loan/${id}`, requestOptions)
      .then(({ data }) => {
        setError(false);
        setEmployeesList(data.content);
        setCount(data.totalSize);
        setCompanyCompetence(competence);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0].errorReason === 'EMPLOYEES_NOT_FOUND') {
            setErrorData({
              type: 'EMPLOYEES_NOT_FOUND',
              title: 'Nada encontrado',
              text: '',
            });
            setError(true);
            return;
          }
          setGeneralError(true);
        },
      )
      .finally(() => {
        if (!employeesListLoaded) {
          setEmployeesListLoaded(true);
        }
        setEmployeesListLoading(false);
      });
  };

  const getLoanSituations = () => {
    api
      .get('consolidatedValues/situations')
      .then(({ data }) => {
        setSituationOptions(data);
      })
      .catch(error => {})
      .finally(() => {});
  };

  useEffect(() => {
    getEmployeesList();
  }, [currentPage, pageSize, order, orderBy, cpfOrNameValue, status]);

  useEffect(() => {
    getLoanSituations();
  }, []);

  useEffect(() => {
    if (!employeesListLoaded) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [employeesListLoaded]);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.actionBarContainer}>
          <Typography className={styles.pageTitle}>
            Funcionários solicitantes
          </Typography>
          <div>
            <Button
              ref={null}
              variant="contained"
              className={styles.downloadTicket}
              startIcon={extractDownloading ? null : <DownloadIcon />}
              onClick={handleDownloadOptions}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              {extractDownloading ? (
                <PercentageCircularProgress value={percentage} />
              ) : (
                <Typography>Baixar</Typography>
              )}
            </Button>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={downloadButtonAnchor}
              open={Boolean(downloadButtonAnchor)}
              onClose={() => setDownloadButtonAnchor('')}
              className={styles.menu}
            >
              {/* Remover comentário apenas quando a funcionalidade estiver funcionando */}
              {/* <MenuItem onClick={handleDownloadTicket}>
                Boleto de empréstimos
              </MenuItem> */}
              <MenuItem onClick={handleDownloadExtractEmployees}>
                Planilha de funcionários solicitantes
              </MenuItem>
              <MenuItem onClick={handleDownloadExtractLoans}>
                Planilha de empréstimos solicitados
              </MenuItem>
            </Menu>
          </div>
        </div>
        {pageLoading ? (
          <LogoLoading />
        ) : (
          <div>
            {generalError ? (
              <div>
                <Error />
              </div>
            ) : (
              <div>
                <GeneralInfo
                  competence={moment(companyCompetence).format('MM/YYYY')}
                />
                <div className={styles.filtersContainer}>
                  <TextField
                    className={styles.searchByCpfTextField}
                    onChange={handleCpfOrNameInput}
                    value={cpfOrNameValue || ''}
                    placeholder="Digite o nome ou CPF"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={styles.searchIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    className={styles.formControl}
                  >
                    <InputLabel id="select-situation">Situação</InputLabel>
                    <Select
                      labelId="select-situation"
                      id="select-outlined"
                      value={status}
                      onChange={handleChangeStatus}
                      label="Situação"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      <MenuItem value="1">À pagar</MenuItem>
                      <MenuItem value="2">Pago parcial</MenuItem>
                      <MenuItem value="3">Liquidado</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {employeesListLoading ? (
                  <FakeTable
                    count={count}
                    pageSize={pageSize}
                    currentPage={currentPage}
                  />
                ) : error ? (
                  <Error type={errorData.type} title={errorData.title} text />
                ) : (
                  <Table
                    bodyData={employeesList}
                    count={count}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleGoToDetails={handleGoToDetails}
                    sort={sort}
                    order={order}
                    orderBy={orderBy}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <BottomBar handleGoBack={handleGoBack} hidden={pageLoading} />
      <ErrorDialog
        open={handleImportErrorModal}
        onCloseDialog={() => setHandleImportErrorModal(false)}
        title="Erro! Não foi possível baixar o boleto."
        text={importErrorDescription}
      />
    </div>
  );
};
