import React from 'react';
import { Button, Dialog, Grid, Typography, Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import CustomSnackbar from '../../../../../components/Snackbar';
import SituationEmployeesTabs from './Components/SituationEmployeesTabs';

import { useEmployeesList } from './Hooks/useEmployeesList';

import styles from '../styles.module.css';

function EmployeesListModal({
  open,
  onClose,
  onCancelClicked,
  onConfirmClicked,
}) {
  const {
    employeesResignCountSelecteds,
    previewSelected,
    employeeBankData,
    handleCloseSuccessSnackbar,
  } = useEmployeesList();

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <Grid
          container
          direction="column"
          className={styles.employeesListModalContainer}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', marginBottom: '1.5rem' }}
          >
            Incluir colaboradores
          </Typography>

          <SituationEmployeesTabs />

          <Grid
            container
            justifyContent={
              previewSelected?.length > 0 ? 'space-between' : 'flex-end'
            }
            alignItems="center"
            style={{ marginTop: '2rem' }}
          >
            {previewSelected?.length > 0 && (
              <Box display="flex" alignItems="center" sx={{ gap: '1rem' }}>
                <Grid item className={styles.employeesListSelecteds}>
                  <div className={styles.employeesListSelectedsCount}>
                    {previewSelected?.length}
                  </div>

                  <Typography className={styles.employeesListSelectedsText}>
                    {previewSelected?.length > 1
                      ? 'Selecionados'
                      : 'Selecionado'}
                  </Typography>
                </Grid>

                {employeesResignCountSelecteds.length > 0 && (
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <InfoIcon className={styles.iconInfo} />
                      <Typography
                        variant="subtitle2"
                        className={styles.inforResignColor}
                      >
                        {employeesResignCountSelecteds.length > 1
                          ? `Você selecionou ${employeesResignCountSelecteds.length} colaboradores demitidos`
                          : `Você selecionou ${employeesResignCountSelecteds.length} colaborador demitido`}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={onCancelClicked}
                className={styles.employeesListModalCloseButton}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => onConfirmClicked(previewSelected)}
                className={styles.employeesListModalConfirmButton}
                disabled={!previewSelected?.length > 0}
              >
                Incluir
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      <CustomSnackbar
        severity="success"
        open={employeeBankData.successOn.hasSuccess}
        close={handleCloseSuccessSnackbar}
        message={employeeBankData.successOn.message}
      />
    </>
  );
}

export default EmployeesListModal;
