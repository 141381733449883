/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Button,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import MuiAlert from '@material-ui/lab/Alert';

import CustomDatePicker from '../../../components/DatePicker';
import ApplyValueModal from '../../../components/ApplyValueModal';
import PrimaryModal from '../../../components/PrimaryModal';
import SkippedInvalidModal from '../../../components/PrimaryModal';

import ProgressItem from './UI/ProgressItem';
import EmployeesListModal from './UI/EmployeesListModal';
import Table from './UI/Table';
import BottomBar from './UI/BottomBar';

import styles from './styles.module.css';

import { isMobile } from '../../../utils/breakpoints';

import { api } from '../../../services/api';

import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';
import processErrorMessage from '../../../utils/processErrorMessage';
import { ActionsManualPayroll } from '../../../redux/store/Payroll/ManualPayroll';

export default function ManualPayroll() {
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const manualPaymentEmployees = JSON.parse(
    sessionStorage.getItem('manualPaymentEmployees'),
  );
  const paymentInfo = JSON.parse(sessionStorage.getItem('paymentInfo'));
  const zeroProduct = JSON.parse(sessionStorage.getItem('zeroProduct'));

  const history = useHistory();
  const dispatch = useDispatch();

  const today = moment();
  const tomorrowFirstHour = moment(today)
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [date, setDate] = useState(tomorrowFirstHour || '');
  const availablePaymentHours = [
    { value: '21:00:00', description: '21:00' },
    { value: '20:00:00', description: '20:00' },
    { value: '19:00:00', description: '19:00' },
    { value: '18:00:00', description: '18:00' },
    { value: '17:00:00', description: '17:00' },
    { value: '16:00:00', description: '16:00' },
    { value: '15:00:00', description: '15:00' },
    { value: '14:00:00', description: '14:00' },
    { value: '13:00:00', description: '13:00' },
    { value: '12:00:00', description: '12:00' },
    { value: '11:00:00', description: '11:00' },
    { value: '10:00:00', description: '10:00' },
    { value: '09:00:00', description: '09:00' },
    { value: '08:00:00', description: '08:00' },
    { value: '07:00:00', description: '07:00' },
    { value: '06:00:00', description: '06:00' },
    { value: '05:00:00', description: '05:00' },
    { value: '04:00:00', description: '04:00' },
  ];
  const [
    selectedAvailablePaymentHour,
    setSelectedAvailablePaymentHour,
  ] = useState(
    moment(today)
      .add(1, 'day')
      .format('HH:00:00'),
  );
  const [paymentType, setPaymentType] = useState(
    paymentInfo ? paymentInfo.paymentType : '',
  );
  const [paymentTypeDescription, setPaymentTypeDescription] = useState(
    'Salário',
  );

  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [openEmployeesListModal, setOpenEmployeesListModal] = useState(false);
  const [openApplyValueModal, setOpenApplyValueModal] = useState(false);
  const [openFiredEmployeesModal, setOpenFiredEmployeesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSkippedInvalidModal, setOpenSkippedInvalidModal] = useState(false);
  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);

  const [types, setTypes] = useState([]);
  const [selected, setSelected] = useState(manualPaymentEmployees || []);

  const [
    selectedEmployeesToConfirmInclude,
    setSelectedEmployeesToConfirmInclude,
  ] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState(
    !openEmployeesListModal ? manualPaymentEmployees : [],
  );
  const [resignedEmployees, setResignedEmployees] = useState([]);
  const [
    confirmResignedEmployeesModal,
    setConfirmResignedEmployeesModal,
  ] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  const validateDateInfo = useValidationForUnformattedDate(date);

  const [isBlocking, setIsBlocking] = useState(true);

  const { accountSelected } = useSelector(state => state.account);

  const PAYMENT_TYPE_IS_RECISION = '2';

  const SHOW_BOTTOM_BAR = Boolean(
    date &&
      selectedAvailablePaymentHour &&
      paymentType &&
      manualPaymentEmployees?.length > 0 &&
      manualPaymentEmployees?.find(
        item => typeof item.amount === 'number' && item.amount > 0,
      ),
  );

  const DATE_IS_NOT_RECISION =
    paymentType !== PAYMENT_TYPE_IS_RECISION &&
    moment(date).format('YYYY-MM-DD') <
      moment()
        .add(1, 'day')
        .format('YYYY-MM-DD');

  const DATE_IS_RECISION =
    paymentType === PAYMENT_TYPE_IS_RECISION &&
    moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

  useEffect(() => {
    sessionStorage.setItem(
      'paymentInfo',
      JSON.stringify({
        date,
        paymentHour: selectedAvailablePaymentHour,
        paymentType,
      }),
    );
  }, [date, selectedAvailablePaymentHour, paymentType]);

  useEffect(() => {
    if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      if (moment().format('HH:00:00') < '21:00:00') {
        setSelectedAvailablePaymentHour(
          moment()
            .add(1, 'hour')
            .format('HH:00:00'),
        );
      } else {
        setSelectedAvailablePaymentHour('');
      }
    } else if (moment().format('HH:00:00') < '21:00:00') {
      setSelectedAvailablePaymentHour(moment().format('HH:00:00'));
    } else {
      setSelectedAvailablePaymentHour('04:00:00');
    }
  }, [date]);

  useEffect(() => {
    sessionStorage.removeItem('loadingPayment');
    sessionStorage.removeItem('selectedEmployees');

    getPaymentTypes();
  }, []);

  function getPaymentTypes() {
    api
      .get('/employeesPayments/payments/types')
      .then(({ data }) => setTypes(data));
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function onBackClick() {
    history.push('/payroll');
  }

  function onNextClick() {
    const paymentData = {
      paymentTypeDescription,
      paymentType,
      date,
      paymentHour: selectedAvailablePaymentHour,
    };

    if (isMobile) {
      sessionStorage.setItem(
        'useTopBar',
        JSON.stringify({
          name: 'Pagamento manual',
          step: 2,
          route: history.location.pathname,
        }),
      );

      history.push('/payroll/manual-payroll/include-collaborators');
      setIsBlocking(false);
    } else {
      sendEmployeesListForBackEnd();
    }

    sessionStorage.setItem('paymentDescription', JSON.stringify(paymentData));
  }

  function onChangeDate(value) {
    setDate(value);
  }

  const onOpenEmployeesModal = open => {
    dispatch(ActionsManualPayroll.setPreviewSelected(selectedEmployees));
    setOpenEmployeesListModal(open);
  };

  function onChangePaymentType(value) {
    setPaymentType(value);
    setPaymentTypeDescription(
      types.filter(item => item.code === value)[0].description,
    );
  }

  function onShowSuccessAlert() {
    setOpenApplyValueModal(false);
    setSnackbarMessage('Valor aplicado a todos com sucesso.');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  }

  function onShowErrorAlert(errorMessage) {
    setSnackbarMessage(processErrorMessage(errorMessage));
    setSnackbarSeverity('error');
    setOpenSnackbar(true);
  }

  function includeEmployees(employeesSelected) {
    const isEmployeeResigned = employeesSelected?.some(
      item => !item.hasBankData && item?.status === 'RESIGN',
    );

    setSelectedEmployeesToConfirmInclude(employeesSelected);

    if (isEmployeeResigned) {
      setOpenFiredEmployeesModal(true);
      return;
    }

    confirmIncludeEmployees(employeesSelected);
  }

  function confirmIncludeEmployees(employeesSelected) {
    const employeesAlreadySelected =
      JSON.parse(sessionStorage.getItem('manualPaymentEmployees')) ?? [];
    const employeesToPay =
      employeesAlreadySelected?.length === 0
        ? employeesSelected
        : employeesSelected?.map(employee => {
            const existentEmployee = employeesAlreadySelected?.find(
              selected => employee?.cpf === selected?.cpf,
            );
            if (existentEmployee !== undefined) {
              return {
                ...existentEmployee,
                amount: existentEmployee?.amount,
              };
            }
            return employee;
          });

    sessionStorage.setItem(
      'manualPaymentEmployees',
      JSON.stringify(employeesToPay),
    );

    setSelectedEmployees(employeesToPay);
    setIsSelectAllClicked(false);
    setOpenFiredEmployeesModal(false);
    setOpenEmployeesListModal(false);
  }

  function sendEmployeesListForBackEnd() {
    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
    };
    const requestBody = {
      fileEncodedKey: null,
      scheduleDate: `${moment(date).format(
        'YYYY-MM-DD',
      )}T${selectedAvailablePaymentHour}`,
      paymentType,
      payments: manualPaymentEmployees,
      depositAccountOrigin: accountSelected?.origin,
    };
    setLoading(true);

    api
      .post(
        `employeesPayments/drafts/${currentCompanyCode}`,
        requestBody,
        requestOptions,
      )
      .then(({ data }) => {
        if (data.skippedInvalidDeposits) {
          setIsBlocking(false);
          setOpenSkippedInvalidModal(true);
          setPaymentData(data);
          return;
        }

        goToPaymentReview(data);
      })
      .catch(error => onShowErrorAlert(error.response?.data))
      .finally(() => setLoading(false));
  }

  function goToPaymentReview(data) {
    setIsBlocking(false);

    if (openSkippedInvalidModal) {
      sessionStorage.setItem('importFileData', JSON.stringify(paymentData));
    } else {
      sessionStorage.setItem('importFileData', JSON.stringify(data));
    }

    dispatch({
      type: 'SET_VALUE_INPUTS',
      inputValue: '',
    });

    history.push(
      '/payroll/menu/info-payroll/register-batch/data-review',
      resignedEmployees,
    );
  }

  function onCancelClicked() {
    setIsSelectAllClicked(false);
    setOpenEmployeesListModal(false);
    dispatch(ActionsManualPayroll.setSelectedEmployeeToInsertBankData({}));
    dispatch(ActionsManualPayroll.setPreviewSelected(selectedEmployees));
  }

  function validateResignedEmployees() {
    if (
      selectedEmployees?.some(
        employee => employee.employeeStatusDescription === 'Demitido',
      )
    ) {
      setResignedEmployees(
        selectedEmployees
          ?.map(
            employee =>
              employee?.employeeStatusDescription === 'Demitido' && {
                cpf: employee?.cpf,
                name: employee?.name,
              },
          )
          .filter(value => value !== false),
      );
      setConfirmResignedEmployeesModal(true);
      return;
    }

    onNextClick();
  }

  function renderResignedList() {
    return (
      <Box flexDirection="column">
        <Typography className={styles.resignedEmployeesModalTitle}>
          Funcionários demitidos:
        </Typography>
        <List>
          {resignedEmployees?.map(employees => (
            <>
              <ListItem>
                <ListItemText>
                  <div>
                    •{' '}
                    <span className={styles.resignedEmployeesName}>
                      {employees?.name}
                    </span>{' '}
                    -{' '}
                    <span className={styles.resignedEmployeesCpf}>
                      {employees?.cpf}
                    </span>
                  </div>
                </ListItemText>
              </ListItem>
            </>
          ))}
        </List>
      </Box>
    );
  }

  if (isMobile) {
    return (
      <div className={styles.contentStyle}>
        <FormControl variant="outlined" className={styles.textFieldStyle}>
          <InputLabel id="payment-type-select-box-label">
            Tipo de pagamento*
          </InputLabel>

          <Select
            labelId="payment-type-select-box-label"
            id="payment-type-select-box"
            label="Tipo de Pagamento*"
            value={paymentType}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            onChange={({ target: { value } }) => onChangePaymentType(value)}
          >
            {types?.map((type, index) => (
              <MenuItem key={type.description} value={type.code}>
                {type.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <br />

        <FormControl className={styles.textFieldStyle}>
          <InputLabel
            id="select-date-label"
            style={date ? { display: 'none' } : {}}
            className={date ? styles.disabledInputLabel : styles.inputLabel}
          >
            Data do pagamento*
          </InputLabel>

          <CustomDatePicker
            labelId="select-date-label"
            id="select-date"
            value={date}
            label={date && 'Data do pagamento*'}
            onChange={onChangeDate}
            minDate={
              paymentType !== PAYMENT_TYPE_IS_RECISION && !zeroProduct
                ? moment().add(1, 'day')
                : moment()
            }
            error={validateDateInfo}
            disableCalendarIcon={validateDateInfo}
          />
        </FormControl>

        <br />

        <FormControl variant="outlined" className={styles.textFieldStyle}>
          <InputLabel id="payment-hour-select-box-label">
            Hora do pagamento*
          </InputLabel>

          <Select
            labelId="payment-hour-select-box-label"
            id="payment-hour-select-box"
            label="Hora do Pagamento*"
            value={selectedAvailablePaymentHour}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            onChange={({ target: { value } }) =>
              setSelectedAvailablePaymentHour(value)
            }
          >
            {moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
              ? availablePaymentHours?.map(hour => {
                  if (
                    hour.value > moment().format('HH:00:00') &&
                    hour.value <= '21:00:00'
                  ) {
                    return (
                      <MenuItem key={hour.description} value={hour.value}>
                        {hour.description}
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem
                      disabled
                      key={hour.description}
                      value={hour.value}
                    >
                      {hour.description}
                    </MenuItem>
                  );
                })
              : availablePaymentHours?.map(hour => (
                  <MenuItem key={hour.description} value={hour.value}>
                    {hour.description}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        <Button
          className={
            !date ||
            !selectedAvailablePaymentHour ||
            !paymentType ||
            moment(date).format('DD/MM/YYYY') === 'Data inválida' ||
            DATE_IS_NOT_RECISION ||
            DATE_IS_RECISION
              ? styles.disabledStartButton
              : styles.startButton
          }
          onClick={onNextClick}
          disabled={
            !date ||
            !selectedAvailablePaymentHour ||
            !paymentType ||
            moment(date).format('DD/MM/YYYY') === 'Data inválida' ||
            DATE_IS_NOT_RECISION ||
            DATE_IS_RECISION
          }
        >
          Continuar
        </Button>
      </div>
    );
  }

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={styles.manualPaymentContainer}
      >
        <Grid container xs={12} md={12} lg={12} alignItems="center">
          <Grid item md={4} lg={4}>
            <Typography className={styles.titleStyle}>
              Pagamento manual
            </Typography>
          </Grid>

          <Grid item md={8} lg={8}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                {date && paymentType && selectedAvailablePaymentHour ? (
                  <ProgressItem
                    title="Tipo e data"
                    icon={<CheckRoundedIcon className={styles.progressIcon} />}
                  />
                ) : (
                  <ProgressItem
                    title="Tipo e data"
                    icon={<EventRoundedIcon className={styles.progressIcon} />}
                  />
                )}
              </Grid>

              <Grid
                className={
                  date && selectedAvailablePaymentHour && paymentType
                    ? styles.horizontalLine
                    : styles.inactiveHorizontalLine
                }
              />

              <Grid item>
                <ProgressItem
                  title={
                    <span>
                      Funcionários e<br /> valores
                    </span>
                  }
                  icon={
                    <PersonAddRoundedIcon className={styles.progressIcon} />
                  }
                  status={
                    date &&
                    selectedAvailablePaymentHour &&
                    paymentType &&
                    manualPaymentEmployees &&
                    manualPaymentEmployees.length > 0
                      ? 'active'
                      : 'inactive'
                  }
                />
              </Grid>

              <Grid className={styles.inactiveHorizontalLine} />

              <Grid item>
                <Box mr={6}>
                  <ProgressItem
                    title={
                      <span>
                        Confirmar <br /> e agendar
                      </span>
                    }
                    icon={
                      <CheckBoxRoundedIcon className={styles.progressIcon} />
                    }
                    status="inactive"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className={styles.divider} />

        <Box mb={0.5} />

        <Grid container spacing={2} alignItems="center">
          <Grid item md={3}>
            <FormControl
              variant="outlined"
              className={styles.textFieldStyle}
              size="small"
            >
              <InputLabel id="payment-type-select-box-label">
                Tipo de pagamento*
              </InputLabel>

              <Select
                labelId="payment-type-select-box-label"
                label="Tipo de Pagamento*"
                value={paymentType}
                onChange={({ target: { value } }) => onChangePaymentType(value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {types?.map(type => (
                  <MenuItem key={type.description} value={type.code}>
                    {type.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <FormControl className={styles.textFieldStyle}>
              <CustomDatePicker
                value={date}
                label={date ? 'Data do pagamento*' : null}
                placeholder="Data do pagamento*"
                onChange={onChangeDate}
                minDate={
                  paymentType !== PAYMENT_TYPE_IS_RECISION && !zeroProduct
                    ? moment().add(1, 'day')
                    : moment()
                }
                error={validateDateInfo}
                disableCalendarIcon={validateDateInfo}
                height={2}
              />
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <FormControl
              variant="outlined"
              className={styles.textFieldStyle}
              size="small"
            >
              <InputLabel id="payment-hour-select-box-label">
                Hora do pagamento*
              </InputLabel>

              <Select
                labelId="payment-hour-select-box-label"
                label="Hora do Pagamento*"
                value={selectedAvailablePaymentHour}
                onChange={({ target: { value } }) =>
                  setSelectedAvailablePaymentHour(value)
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {moment(date).format('YYYY-MM-DD') ===
                moment().format('YYYY-MM-DD')
                  ? availablePaymentHours?.map(hour => {
                      if (
                        hour.value > moment().format('HH:00:00') &&
                        hour.value <= '21:00:00'
                      ) {
                        return (
                          <MenuItem key={hour.description} value={hour.value}>
                            {hour.description}
                          </MenuItem>
                        );
                      }

                      return (
                        <MenuItem
                          disabled
                          key={hour.description}
                          value={hour.value}
                        >
                          {hour.description}
                        </MenuItem>
                      );
                    })
                  : availablePaymentHours?.map(hour => (
                      <MenuItem key={hour.description} value={hour.value}>
                        {hour.description}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <Button
              className={styles.includeCollaboratorsButton}
              onClick={() => onOpenEmployeesModal(true)}
              disabled={
                !date ||
                !selectedAvailablePaymentHour ||
                !paymentType ||
                !manualPaymentEmployees ||
                manualPaymentEmployees?.length === 0
              }
            >
              <AddRoundedIcon
                className={styles.includeCollaboratorsButtonIcon}
              />
              Incluir colaboradores
            </Button>
          </Grid>

          <Grid item md={3}>
            <Button
              className={styles.applyValuesButton}
              onClick={() => setOpenApplyValueModal(true)}
              disabled={
                !manualPaymentEmployees || manualPaymentEmployees?.length === 0
              }
            >
              Aplicar mesmo valor
            </Button>
          </Grid>
        </Grid>

        {manualPaymentEmployees?.length > 0 ? (
          <Table
            manualPaymentEmployees={manualPaymentEmployees}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            SHOW_BOTTOM_BAR={SHOW_BOTTOM_BAR}
            loading={loading}
          />
        ) : (
          <Grid
            container
            className={styles.includeEmptyArea}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              {!date || !selectedAvailablePaymentHour || !paymentType ? (
                <Tooltip title="Os campos Tipo de pagamento, Data do pagamento e Hora do pagamento devem estar todos preenchidos">
                  <div>
                    <Button
                      className={styles.disabledIncludeEmptyAreaButton}
                      disabled
                    >
                      Incluir colaboradores
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  className={styles.includeEmptyAreaButton}
                  onClick={() => onOpenEmployeesModal(true)}
                >
                  Incluir colaboradores
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        <Box mb={8} />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <ApplyValueModal
          open={openApplyValueModal}
          onCloseDialog={() => setOpenApplyValueModal(false)}
          setShowSuccessAlert={onShowSuccessAlert}
        />

        <EmployeesListModal
          open={openEmployeesListModal}
          onCancelClicked={onCancelClicked}
          onConfirmClicked={includeEmployees}
        />

        <PrimaryModal
          title={
            <Typography variant="h6" className={styles.fontBold}>
              Você selecionou funcionários{' '}
              <span className={styles.contrastResignLabel}>demitidos.</span>{' '}
              Quer continar com o pagamento?{' '}
            </Typography>
          }
          open={openFiredEmployeesModal}
          confirmButtonText="Sim, continuar"
          cancelButtonText="Não"
          onCancelButtonClicked={() => setOpenFiredEmployeesModal(false)}
          onConfirmClicked={() =>
            confirmIncludeEmployees(selectedEmployeesToConfirmInclude)
          }
        />

        <SkippedInvalidModal
          open={openSkippedInvalidModal}
          title="Atenção!"
          text="Alguns funcionários estão com os valores zerados e
          não serão pagos. Quer continuar mesmo assim com o pagamento?"
          confirmButtonText="Sim, continuar"
          cancelButtonText="Não"
          onCancelButtonClicked={() => setOpenSkippedInvalidModal(false)}
          onConfirmClicked={goToPaymentReview}
        />

        <Prompt
          when={isBlocking}
          message="Você tem certeza que deseja cancelar a criação desta folha?"
        />

        <PrimaryModal
          open={confirmResignedEmployeesModal}
          onCloseDialog={() => setConfirmResignedEmployeesModal(false)}
          onCancelButtonClicked={() => setConfirmResignedEmployeesModal(false)}
          onConfirmClicked={onNextClick}
          title="Ops! Foram encontrados alguns funcionários demitidos"
          text="Por favor, verifique se os funcionários listados abaixo devem estar inclusos na folha de pagamento"
          children={renderResignedList()}
          confirmButtonText="Continuar"
          cancelButtonText="Cancelar"
        />

        <BottomBar
          SHOW_BOTTOM_BAR={SHOW_BOTTOM_BAR}
          onBackClick={onBackClick}
          onNextClick={validateResignedEmployees}
          loading={loading}
          date={date}
          paymentHour={selectedAvailablePaymentHour}
        />
      </Grid>
    </>
  );
}
