import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../services/api';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

const initialState = {
  accounts: [],
  accountIndexSelected: 0,
  accountIndexSelectedToConfirm: 0,
  accountSelectedToConfirm: {},
  accountSelected: {},
  isAccountLoading: false,
  isRevenueLoading: false,
  hasToUpdateIncomeInfo: false,
  wasUpdated: false,
  isOpenModalToSwitchAccount: false,
  errorOnAccounts: {
    hasError: false,
    message: '',
  },
};

const { error } = handleSnackbarStack();

const getAccounts = createAsyncThunk(
  'accounts/get',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    return api
      .get(`companies/${currentCompany.code}/deposit-accounts`)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setRevenueData = createAsyncThunk(
  'accounts/setRevenueData',
  async (payload, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    return api
      .patch(`companies/${currentCompany.code}/patrimony-revenue`, {
        ...payload,
      })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    cleanup: state => {
      Object.keys(initialState).forEach(item => {
        state[item] = initialState[item];
      });
    },
    setAccountIndexSelectedToConfirm: (state, { payload }) => {
      state.accountIndexSelectedToConfirm = payload;
    },
    setAccountSelectedToConfirm: (state, { payload }) => {
      state.accountSelectedToConfirm = payload;
    },
    setAccountSelected: (state, { payload }) => {
      state.accountSelected = payload;
    },
    setAccountIndexSelected: (state, { payload }) => {
      state.accountIndexSelected = payload;
    },
    setIsOpenModalToSwitchAccount: (state, { payload }) => {
      state.isOpenModalToSwitchAccount = payload;
    },
    setUpdatableIncome: (state, { payload }) => {
      state.hasToUpdateIncomeInfo = payload;
    },
  },
  extraReducers: {
    [getAccounts.pending]: state => {
      state.isAccountLoading = true;
      state.wasUpdated = false;
      state.errorOnAccounts = initialState.errorOnAccounts;
    },
    [getAccounts.fulfilled]: (state, { payload }) => {
      state.isAccountLoading = false;
      state.accounts = payload.data;
    },
    [getAccounts.rejected]: (state, { payload }) => {
      state.isAccountLoading = false;
      if (!payload) {
        state.errorOnAccounts = {
          hasError: true,
          message: 'Houve um erro ao buscar a conta da empresa',
        };
      } else {
        state.errorOnAccounts = {
          hasError: true,
          message: payload.error,
        };
      }
    },
    [setRevenueData.pending]: state => {
      state.isRevenueLoading = true;
      state.wasUpdated = false;
      state.errorOnAccounts = initialState.errorOnAccounts;
    },
    [setRevenueData.fulfilled]: state => {
      state.isRevenueLoading = false;
      state.wasUpdated = true;
    },
    [setRevenueData.rejected]: (state, { payload }) => {
      state.isRevenueLoading = false;
      state.wasUpdated = false;
      if (!payload) {
        error(
          'Houve um erro ao registrar as informações de renda e patrimônio.',
        );
        state.errorOnAccounts = {
          hasError: true,
          message:
            'Houve um erro ao registrar as informações de renda e patrimônio.',
        };
      } else {
        error(payload.error);
        state.errorOnAccounts = {
          hasError: true,
          message: payload.error,
        };
      }
    },
  },
});

const accountReducer = accountSlice.reducer;
const ActionsAccount = { ...accountSlice.actions, getAccounts, setRevenueData };

export { accountReducer, ActionsAccount };
