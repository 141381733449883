import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles.module.css';

function Info({ text, icon, warning }) {
  return (
    <div className={warning ? styles.containerWarning : styles.container}>
      <div className={styles.icon}>{icon}</div>
      <Typography className={styles.text}>{text}</Typography>
    </div>
  );
}

export default Info;
