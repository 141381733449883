import React from 'react';

import styles from './styles.module.css';

import { Grid, Typography } from '@material-ui/core';

export default function ProgressItem({
  icon,
  title,
  status = 'active'
}) {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid className={status === 'active' ? styles.activeProgressItem : styles.inactiveProgressItem}>
        {icon}
      </Grid>

      <Typography className={styles.progressItemTitle}>
        {title}
      </Typography>
    </Grid>
  )
}
