import { alpha, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: '60dvh',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
    },

    textContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2),

      '& h2': {
        fontSize: '1.125rem',
        fontWeight: 700,
      },

      '& p': {
        fontSize: '.875rem',
        fontWeight: 400,
        color: theme.palette.text.secondary,

        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
    },

    buttonContainer: {
      margin: `${theme.spacing(3)}px auto`,
      width: 'fit-content',

      '& button': {
        textTransform: 'uppercase',
      },
    },
  }),
);
