const initialState = {
  data: {},
};

export default function employeeSuccessfully(state = initialState, action) {
  switch (action.type) {
    case 'SET_EMPLOYEES_RESULT':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
