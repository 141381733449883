import React from 'react';
import { TableBody } from '@material-ui/core';
import TableCases from '../../TableCases';

export function EnhancedTableBody({
  data = [],
  tableConfig = [],
  rowsPerPage = 5,
  isLoading = false,
  hasError = false,
  dataLength = 0,
}) {
  return (
    <TableBody>
      {isLoading ? (
        <TableCases.Loading config={tableConfig} rowsPerPage={rowsPerPage} />
      ) : hasError ? (
        <TableCases.Error config={tableConfig} />
      ) : dataLength === 0 ? (
        <TableCases.Empty config={tableConfig} />
      ) : (
        <TableCases.Render config={tableConfig} data={data} />
      )}
    </TableBody>
  );
}
