import React, { useState, useEffect } from 'react';
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Tooltip,
  Paper,
  IconButton,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Search from '@material-ui/icons/Search';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input';
import styles from './styles.module.css';
import { ReactComponent as RemoveItem } from '../../../../../assets/removeItem.svg';
import CollaboratorsService from '../../utils/services';
import TablePaginationActions from './TablePaginationActions';
import textCapitalize from '../../../../../utils/textCapitalize';

export default function SimpleTable({ tableState, setEmptyList }) {
  const authToken = sessionStorage.getItem('@ContaSoma-Token');

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCodeValue = companyCodeValueStorage || companies[0].code;

  const inputValueModal = useSelector(
    state => state.applyValueInputs.inputValue,
  );
  const [searchEmployee, setSearchEmployee] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const qtdChunks = 10;

  const json = sessionStorage.getItem('selectedEmployees');
  const list = JSON.parse(json);

  const dispatch = useDispatch();

  const [stateList, setStateList] = useState(
    list ? _.chunk(list, qtdChunks) : null,
  );

  useEffect(() => {
    setStateList(list ? _.chunk(list, qtdChunks) : null);
  }, [json]);

  const requestCollaborators = () => {
    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
      params: {
        page: currentPage,
        size: qtdChunks,
      },
    };

    CollaboratorsService.getCollaborators({
      requestOptions,
      companyCode: companyCodeValue,
      setStates: (listOneData, totalSize) => {},
    });
  };

  useEffect(() => {
    const json = sessionStorage.getItem('selectedEmployees');
    const list = JSON.parse(json);

    if (inputValueModal && inputValueModal !== 'modified') {
      let newListWithValueModal = [];

      list.forEach(item =>
        newListWithValueModal.push({
          ...item,
          amount: inputValueModal === '0' ? '' : inputValueModal,
        }),
      );

      setStateList(list ? _.chunk(newListWithValueModal, qtdChunks) : null);

      if (list) {
        sessionStorage.setItem(
          'selectedEmployees',
          JSON.stringify(newListWithValueModal),
        );
      }

      dispatch({
        type: 'SET_VALUE_INPUTS',
        inputValue: 'modified',
      });
    } else if (inputValueModal !== 'modified') {
      setStateList(list ? _.chunk(list, qtdChunks) : null);

      if (list) {
        sessionStorage.setItem('selectedEmployees', JSON.stringify(list));
      }
    }

    requestCollaborators();
  }, [currentPage, tableState, inputValueModal]);

  const onChangeCurrentTextFild = (amount, index) => {
    let auxList = [...stateList];

    auxList[currentPage][index].amount = amount === 0 ? '' : amount;

    sessionStorage.setItem(
      'selectedEmployees',
      JSON.stringify(auxList.flat(Infinity)),
    );

    setStateList(auxList);
  };

  const removeItemFromTable = indexToRemove => {
    const auxArray = _.chunk(list, qtdChunks);

    auxArray[currentPage].splice(indexToRemove, 1);

    sessionStorage.setItem(
      'selectedEmployees',
      JSON.stringify(auxArray.flat(Infinity)),
    );

    setStateList(auxArray);
  };

  const searchOnArray = searchInput => {
    setSearchEmployee(searchInput);

    if (!searchInput) {
      setStateList(_.chunk(list, qtdChunks));
    } else {
      let searchedArray = [];

      searchedArray = _.chunk(
        list.filter(o =>
          o.name.toLowerCase().includes(searchInput.toLowerCase()),
        ),
        qtdChunks,
      );

      setStateList(searchedArray);
    }
  };

  return (
    <>
      <TextField
        className={styles.searchBackground}
        placeholder="Pesquisar por nome ou CPF"
        fullWidth
        onChange={event => {
          searchOnArray(event.target.value);
        }}
        value={searchEmployee}
        variant="outlined"
        InputProps={{
          styles: {
            notchedOutline: styles.inputSearch,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.iconColor} />
            </InputAdornment>
          ),
          endAdornment: searchEmployee?.length > 0 && (
            <IconButton
              className={styles.endAdornment}
              onClick={() => {
                setStateList(_.chunk(list, qtdChunks));
                setSearchEmployee('');
              }}
            >
              <CloseRoundedIcon className={styles.endAdornmentIcon} />
            </IconButton>
          ),
        }}
      />
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableTitle}>CPF</TableCell>
              <TableCell className={styles.tableTitle}>Nome</TableCell>
              <TableCell className={styles.tableTitle} align="center">
                Status
              </TableCell>
              <TableCell className={styles.tableTitle}>Valor</TableCell>
              <TableCell className={styles.tableTitle} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stateList &&
              stateList[currentPage] &&
              stateList[currentPage].map((item, index) => (
                <TableRow key={item.cpf}>
                  <TableCell className={styles.tableContent}>
                    {item.cpf}
                  </TableCell>
                  <TableCell className={styles.tableContent}>
                    {textCapitalize(item.name)}
                  </TableCell>
                  <TableCell
                    className={styles.tableContent}
                    style={{ textAlign: 'center' }}
                  >
                    {item.employeeStatus === 'Ativo' ? (
                      <div>Ativo</div>
                    ) : (
                      <div className={styles.offLabel}>DEMITIDO(A)</div>
                    )}
                  </TableCell>
                  <TableCell className={styles.tableContent}>
                    <CurrencyInput
                      value={item.amount}
                      onChange={(event, value) => {
                        onChangeCurrentTextFild(value, index);
                      }}
                      className={styles.inputValue}
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix="R$ "
                    />
                  </TableCell>
                  <TableCell className={styles.tableContent} align="center">
                    <Tooltip title="Remover">
                      <IconButton
                        onClick={() => {
                          removeItemFromTable(index);
                        }}
                      >
                        <RemoveItem className={styles.iconRemove} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={list ? list.length : 0}
                rowsPerPageOptions={[qtdChunks]}
                rowsPerPage={qtdChunks}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={(event, newPage) => {
                  setCurrentPage(newPage);
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
