import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Typography,
  Switch,
  Grid,
  AccordionActions,
  Menu,
  MenuItem,
} from '@material-ui/core';

import Form from '../../../../components/Form';
import { Table } from '../../../../components/Table';
import Buttons from '../../../../components/Buttons';
import IpDetailsModals from '../Modals';
import SettingMenu from '../SettingMenu';

import useTableConfig from './hooks/useTableConfig';
import useAllowedIp from './hooks/useAllowedIp';

import { useStyles } from '../../styles';

const ID = 'ip-config';

export default function AllowedIpConfig() {
  const styles = useStyles();

  const [anchor, setAnchor] = useState(null);

  const {
    isLoading,
    isSending,
    isValid,
    control,
    onSubmit,
    data,
    handlePagination,
    onOpenCreate,
    onOpenDetails,
    onOpenDelete,
    selected,
    setSelected,
    restricted,
    hasSchedule,
    toggle,
  } = useAllowedIp({ id: ID });

  const renderMenuOptions = (row, event) => {
    setAnchor(event.currentTarget);
    setSelected(row);
  };

  const handleOpenDetails = () => {
    onOpenDetails();
    setAnchor(null);
  };

  const handleOpenDelete = () => {
    onOpenDelete();
    setAnchor(null);
  };

  const config = useTableConfig({ renderMenuOptions });

  const subtitle = `A verificação de IP está ${
    restricted ? 'ativada' : 'desativada'
  }`;

  return (
    <>
      <SettingMenu
        id={ID}
        title="Verificar IP"
        subtitle={subtitle}
        alert={!!hasSchedule && 'Alteração agendada'}
      >
        <Form.FormBase className={styles.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.ipTitleContainer}>
              <Typography
                id="ip-title"
                variant="h2"
                className={styles.accordionTitle}
              >
                Verificação de IP
              </Typography>

              <Controller
                control={control}
                name="isIpRestricted"
                render={({ field }) => (
                  <Switch
                    aria-labelledby="ip-title"
                    size="medium"
                    {...field}
                    checked={!!field.value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Buttons.PrimaryButton
                variant="outlined"
                color="primary"
                title="Adicionar IP"
                size="medium"
                type="button"
                onClick={onOpenCreate}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="p"
                className={styles.accordionSubtitle}
              >
                * Somente IPs com status de &quot;Acesso liberado&quot; poderão
                acessar a Plataforma Empresa.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Table
                data={data}
                isHandleLocalData
                requests={handlePagination}
                tableConfig={config}
                isLoading={isLoading}
                length={data?.length}
              />
            </Grid>
          </Grid>
          <AccordionActions className={styles.accordionActions}>
            <Buttons.SecondaryButton
              title="Cancelar"
              size="medium"
              onClick={toggle}
            />
            <Buttons.PrimaryButton
              title="Salvar"
              size="medium"
              type="submit"
              disabled={!isValid}
              loading={isSending}
            />
          </AccordionActions>
        </Form.FormBase>
      </SettingMenu>
      <Menu
        keepMounted
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={5}
      >
        <MenuItem onClick={handleOpenDetails}>Exibir detalhes</MenuItem>
        <MenuItem
          onClick={handleOpenDelete}
          className={styles.deletePermission}
          disabled={!!selected && selected?.status !== 'ACTIVE'}
        >
          Excluir permissão
        </MenuItem>
      </Menu>
      <IpDetailsModals />
    </>
  );
}
