import React from 'react';
import { TextField as Text } from '@material-ui/core';

export function TextField({
  fullWidth = true,
  name = '',
  label = '',
  size = 'small',
  variant = 'outlined',
  disabled = false,
  value = '',
  onChange = () => {},
  ...otherProps
}) {
  return (
    <Text
      fullWidth={fullWidth}
      name={name}
      label={label}
      size={size}
      variant={variant}
      disabled={disabled}
      value={value}
      onChange={event => onChange(event.target.value)}
      InputProps={{ style: { borderRadius: '0.5rem' } }}
      {...otherProps}
    />
  );
}
