import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import CardTools from '../../CardTools';
import { formatToNumber } from '../../../utils/formatToNumber';

export function LinearWithMiddleSpace({
  height = '0.5rem',
  isLoading = false,
  firstColor = 'primary',
  secondColor = 'secondary',
  totalValue = 0,
  firstValue = 0,
  secondValue = 0,
  title = 'Title label',
  firstValueLabel = 'Label A',
  secondValueLabel = 'Label B',
}) {
  const theme = useTheme();

  const firstPart = Math.round(
    (100 * parseFloat(formatToNumber(firstValue))) /
      parseFloat(formatToNumber(totalValue)),
  );

  const secondPart = 100 - firstPart;

  return (
    <Box
      sx={{
        minWidth: '250px',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <CardTools.CardInfo
        orientation="row"
        title={title}
        titleStyle={{ whiteSpace: 'nowrap' }}
        description={isLoading ? '-' : totalValue}
        descriptionStyle={{ fontSize: '1rem', fontWeight: 700 }}
      />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '0.25rem',
        }}
      >
        <Box
          sx={{
            width: `${isLoading ? 100 : firstPart}%`,
            height,
            backgroundColor: isLoading
              ? theme.palette?.grey?.[400]
              : theme.palette?.[firstColor]?.main,
            borderRadius: '2px',
          }}
        />
        <Box
          sx={{
            width: `${isLoading ? 0 : secondPart}%`,
            height,
            backgroundColor: theme.palette?.[secondColor]?.main,
            borderRadius: '2px',
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
        <CardTools.CardInfo
          orientation="row"
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <FiberManualRecord
                style={{
                  color: theme.palette?.[firstColor]?.main,
                  fontSize: '0.6rem',
                }}
              />

              {firstValueLabel}
            </Box>
          }
          titleStyle={{ whiteSpace: 'nowrap' }}
          description={isLoading ? '-' : firstValue}
        />

        <CardTools.CardInfo
          orientation="row"
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <FiberManualRecord
                style={{
                  color: theme.palette?.[secondColor]?.main,
                  fontSize: '0.6rem',
                }}
              />

              {secondValueLabel}
            </Box>
          }
          titleStyle={{ whiteSpace: 'nowrap' }}
          description={isLoading ? '-' : secondValue || '-'}
        />
      </Box>
    </Box>
  );
}
