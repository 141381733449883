import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isAuthenticated, logout } from '../services/auth';
import { clearStore } from '../redux/store/ClearStore';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

export const SignOutRoute = ({ ...rest }) => {
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={props => {
        logout();
        dispatch(clearStore());
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
