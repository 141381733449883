import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useHistory } from 'react-router-dom';
import Modals from '../../../../../../../components/Modals';

export const AlreadyUploadRegisterFile = NiceModal.create(() => {
  const history = useHistory();
  const modal = useModal();

  return (
    <Modals.DefaultModal
      open={modal.visible}
      onClose={modal.hide}
      title="Arquivo de cadastro em andamento"
      description="Já existe um arquivo de cadastro em processamento, você só poderá iniciar um novo cadastro quando o que está em andamento for concluído. Deseja acompanhar seu status?"
      firstButtonText="Acompanhar"
      firstButtonOnClick={() =>
        history.push('/employees/register-batch/menu/select-company/excel-form')
      }
      secondButtonText="Fechar"
      secondButtonOnClick={modal.hide}
    />
  );
});
