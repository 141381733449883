import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

export default function FakeList() {
    return (
        <>
            <div className={styles.card}>
                <Skeleton
                    height={24}
                    width={310}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                />
            </div>

            <div className={styles.card}>
                <Skeleton
                    height={24}
                    width={310}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                />
            </div>

            <div className={styles.card}>
                <Skeleton
                    height={24}
                    width={310}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                />
            </div>

            <div className={styles.card}>
                <Skeleton
                    height={24}
                    width={310}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                />
            </div>

            <div className={styles.card}>
                <Skeleton
                    height={24}
                    width={310}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                    style={{ marginBottom: 10 }}
                />
                <Skeleton
                    height={24}
                    width={240}
                    color="#202020"
                    highlightColor="#444"
                />
            </div>
        </>
    )
}
