import { useState } from 'react';

export function useErrorCardsList() {
  const [currentAccordion, setCurrentAccordion] = useState('all');

  function handleExpandCurrentAccordion(accordionId) {
    currentAccordion === accordionId
      ? setCurrentAccordion('')
      : setCurrentAccordion(accordionId);
  }

  return {
    accordionsObject: {
      currentAccordion,
      handleExpandCurrentAccordion,
    },
  };
}
