import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';

export default function Form({ editUser }) {
  return (
    <Grid
      className={styles.formContainer}
      container
      direction="column"
      justify="flex-start"
    >
      <Grid item>
        <Box mb={2}>
          <Skeleton
            width="100%"
            height={26}
            color="#202020"
            highlightColor="#444"
          />
        </Box>
      </Grid>

      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        wrap="nowrap"
        justify={isMobile ? 'flex-start' : 'space-between'}
      >
        <Grid item className={styles.cpfField}>
          <Box mb={2}>
            <Skeleton
              width="100%"
              height={26}
              color="#202020"
              highlightColor="#444"
            />
          </Box>
        </Grid>

        <Grid item className={styles.emailField}>
          <Box mb={2}>
            <Skeleton
              width="100%"
              height={26}
              color="#202020"
              highlightColor="#444"
            />
          </Box>
        </Grid>

        <Grid item className={styles.phoneField}>
          <Box mb={2}>
            <Skeleton
              width="100%"
              height={26}
              color="#202020"
              highlightColor="#444"
            />
          </Box>
        </Grid>
      </Grid>

      <Divider className={styles.divider} />

      <Grid item>
        <Typography className={styles.textUserProfile}>
          Qual é o nível de acesso desse usuário?
        </Typography>
      </Grid>

      <Grid
        style={{
          width: !isMobile && '70%',
          marginLeft: !isMobile && '-10px',
        }}
        className={styles.checkboxContainer}
        container
        direction={isMobile ? 'column' : 'row'}
        wrap="nowrap"
        justify="flex-start"
        alignItems="center"
      >
        <Box mb={1}>
          <Skeleton
            width={500}
            height={22}
            color="#202020"
            highlightColor="#444"
          />
        </Box>
      </Grid>

      {editUser && (
        <Grid item>
          <Divider className={styles.divider} />

          <Typography className={styles.smallLabel}>Criação</Typography>

          <>
            <Box mb={1}>
              <Skeleton
                width={150}
                height={22}
                color="#202020"
                highlightColor="#444"
              />
            </Box>

            <Box>
              <Skeleton
                width={150}
                height={22}
                color="#202020"
                highlightColor="#444"
              />
            </Box>
          </>
        </Grid>
      )}
    </Grid>
  );
}
