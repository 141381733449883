const initialState = {
  company: JSON.parse(sessionStorage.getItem('companies'))
    ? JSON.parse(sessionStorage.getItem('companies'))[0].code
    : '',
  name: JSON.parse(sessionStorage.getItem('companies'))
    ? JSON.parse(sessionStorage.getItem('companies'))[0].name
    : '',
};

export default function currentCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case 'CURRENT_COMPANY':
      return {
        ...state,
        company: action.company,
      };
    case 'CURRENT_COMPANY_NAME':
      return {
        ...state,
        name: action.name,
      };
    default:
      return state;
  }
}
