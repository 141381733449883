import React from 'react';
import { MoreVertOutlined } from '@material-ui/icons';

import { ChipStatus } from '../../../../../components/ChipStatus';

import formatCPF from '../../../../../utils/formatCPF';

const statusEnum = [
  { value: 'ACTIVE', label: 'Acesso liberado', status: 'success' },
  { value: 'TO_BE_CREATED', label: 'Processando acesso', status: 'warning' },
  { value: 'TO_BE_DELETED', label: 'Acesso removido', status: 'error' },
];

export default function useTableConfig({ renderMenuOptions }) {
  const renderStatus = row => {
    if (!row?.status) return '-';

    const variation = statusEnum.find(status => status.value === row.status);

    return <ChipStatus variant="outlined" {...variation} />;
  };

  return [
    {
      key: 'name',
      type: 'field',
      align: 'left',
      label: 'Cadastrado por',
      render: row => (row?.createdBy ? row?.createdBy?.name : '-'),
    },
    {
      key: 'cpf',
      type: 'field',
      align: 'left',
      label: 'CPF',
      render: row =>
        row?.createdBy?.cpf ? formatCPF(row?.createdBy?.cpf) : '-',
    },
    {
      key: 'ip',
      type: 'field',
      align: 'left',
      label: 'IP da máquina',
      render: row => (row?.ip ? row.ip : '-'),
    },
    {
      key: 'status',
      type: 'field',
      align: 'center',
      label: 'Status',
      render: row => renderStatus(row),
    },
    {
      type: 'action',
      align: 'center',
      label: 'Ações',
      icon: <MoreVertOutlined color="primary" />,
      onClick: (row, event) => renderMenuOptions(row, event),
    },
  ];
}
