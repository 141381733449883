import { api } from '../../../../services/api';

export default class ValidadeCpf {
  static getCpf({ companyCodeValue, cpfValue, requestOptions, setStates }) {
    api
      .post(
        `insurance/v2/cpf/${cpfValue}/${companyCodeValue}/validate`,
        {},
        requestOptions
      )
      .then(data => {
        const dataFromRequest = data.data;
        setStates(dataFromRequest);
      })
      .catch(error => {
        return error;
      });
  }
}
