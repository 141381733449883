import React from 'react';
import { Box, Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import styles from './styles.module.css';

export default function InfoGrid({
  nsu,
  scheduleDate,
  paymentType,
  fileName,
  employeesCount,
  totalValue,
  hidden,
  depositAccount,
  hasMoreThanOneDepositAccount,
}) {
  const { success } = handleSnackbarStack();

  const onCopyNsu = () => {
    navigator.clipboard.writeText(nsu);
    success('Código NSU copiado para a área de transferência!');
  };

  return (
    <Grid
      container
      direction="column"
      className={hidden ? styles.hidden : styles.gridContainer}
    >
      <Grid item>
        <Typography className={styles.gridTitle}>
          Detalhes do agendamento
        </Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={5} alignItems="center">
          <Grid item className={styles.gridLabel}>
            <Grid container alignItems="center" className={styles.nsuContainer}>
              <Grid item>
                <Typography className={styles.gridLabel}>NSU</Typography>
                <Typography className={styles.gridValue}>{nsu}</Typography>
              </Grid>

              <Grid item>
                <Box ml={2}>
                  <Tooltip title="Copiar NSU">
                    <IconButton onClick={onCopyNsu}>
                      <FileCopyOutlinedIcon className={styles.copyNsuIcon} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography className={styles.gridLabel}>
              Data e hora do agendamento
            </Typography>
            <Typography className={styles.gridValue}>{scheduleDate}</Typography>
          </Grid>

          {hasMoreThanOneDepositAccount && (
            <Grid item>
              <Typography className={styles.gridLabel}>Conta</Typography>
              <Typography className={styles.gridValue}>
                {depositAccount}
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Typography className={styles.gridLabel}>Tipo</Typography>
            <Typography className={styles.gridValue}>{paymentType}</Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.gridLabel}>
              Nome do arquivo
            </Typography>
            <Typography className={styles.gridValue}>{fileName}</Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.gridLabel}>Colaboradores</Typography>
            <Typography className={styles.gridValue}>
              {employeesCount}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.gridLabel}>Valor total</Typography>
            <Typography className={styles.gridValue}>{totalValue}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
