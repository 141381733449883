import React from 'react';
import { Dialog, Grid, Typography } from '@material-ui/core';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ReactComponent as SuccessIcon } from '../../../../../../assets/successAnimation.svg';
import Buttons from '../../../../../../components/Buttons';

import { useStyles } from './styles';

const RevenueConfirmModal = NiceModal.create(() => {
  const styles = useStyles();
  const modal = useModal();

  return (
    <Dialog open={modal.visible} onClose={modal.hide} maxWidth="sm">
      <Grid container className={styles.root}>
        <Grid item xs={12}>
          <SuccessIcon />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.title}>
            Obrigado!
          </Typography>
          <Typography variant="body1" className={styles.subtitle}>
            Agradecemos por tirar um tempo para responder essas perguntas.
          </Typography>
          <Buttons.PrimaryButton
            className={styles.button}
            color="primary"
            title="Finalizar"
            onClick={modal.hide}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
});

export default RevenueConfirmModal;
