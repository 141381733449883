import React from 'react';
import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Chip
} from '@material-ui/core';
import { useStyles, theme } from './styles';

import { ReactComponent as DeleteIcon } from '../../../../../assets/removeItem.svg';
import { ReactComponent as EyesIcon } from '../../../../../assets/eyesIcon.svg';
import textCapitalize from '../../../../../utils/textCapitalize';
import EnhancedTableHead from '../EnhancedTableHead';
import { FakeTable } from '../../../../OnlinePointEmployees/UI/Table/UI';
import { handleStatus } from '../../../Utils/handleStatus';
import moment from 'moment';

const TableRechargeList = ({
  loading,
  pageSize,
  totalSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  handleRequestSort,
  rows,
  stableSort,
  getComparator,
  TablePaginationActions,
  handleOpenModalAvaliable,
  onDetailsClicked
}) => {

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody>
            {!loading ? (
              stableSort(rows, getComparator).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    
                    <TableCell align="left">
                      {row?.id}
                    </TableCell>
                    <TableCell align="left">
                      {row.type}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.endDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="left">
                      {row.priceInCents
                        ? row.priceInCents.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'BRL',
                        })
                      : '(Em branco)'}
                      
                    </TableCell>
                    <TableCell align="center">
                      <Chip label={handleStatus(row.status).label} style={{backgroundColor: handleStatus(row.status).color, color: '#FFFFFF' }} />
                    </TableCell>
                    <TableCell align="center" className={classes.defaultCell}>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <IconButton
                          onClick={() => onDetailsClicked(row)}
                        >
                          <Tooltip title="Visualizar detalhes">
                            <EyesIcon />
                          </Tooltip>
                        </IconButton>

                        {row?.status === 'ACTIVE' ? (
                          <IconButton
                            onClick={() =>
                              handleOpenModalAvaliable(row)
                            }
                          >
                            <Tooltip title="Desabilitar colaborador do beeVale">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <FakeTable />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  )
}

export default TableRechargeList