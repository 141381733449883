import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import ColorStatus from './ColorStatus';
import Title from '../../../../components/Title';

import styles from '../styles.module.css';

function TedDetails({
  amountFormatted,
  creationUserName,
  notes,
  receipt,
  destination,
  depositProcessedOn,
  originName,
  scheduleDateFormatted,
  origin,
  ted,
  formatedCreationDate,
}) {
  function currentStatus(status) {
    switch (status) {
      case 'Verificação em processamento':
        return (
          <Grid container className={styles.blueStatus}>
            <UpdateRoundedIcon className={styles.statusIcon} />
            <Typography className={styles.statusName}>
              Verificação em processamento
            </Typography>
          </Grid>
        );
      case 'Transferência não Confirmada':
        return (
          <Grid container className={styles.redStatus}>
            <SyncProblemRoundedIcon className={styles.statusIcon} />
            <Typography className={styles.statusName}>
              Transferência não confirmada
            </Typography>
          </Grid>
        );
      case 'Transferência Confirmada':
        return (
          <Grid container className={styles.greenStatus}>
            <CheckRoundedIcon className={styles.statusIcon} />
            <Typography className={styles.statusName}>
              Transferência Confirmada
            </Typography>
          </Grid>
        );
    }
  }

  return (
    <>
      <Title>Detalhes da TED</Title>

      <ColorStatus status={ted.status} />

      <Grid
        container
        direction="column"
        wrap="wrap"
        className={styles.dataContainer}
      >
        <Grid item>
          <Grid container justify="space-between" wrap="nowrap">
            <Grid item>
              <Typography className={styles.mediumText}>
                Informações da TED
              </Typography>
            </Grid>

            <Grid item>{currentStatus(ted?.status)}</Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Typography className={styles.label}>Valor</Typography>
              <Typography className={styles.defaultText}>
                {amountFormatted}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={styles.label}>Data de depósito</Typography>
              <Typography className={styles.defaultText}>
                {scheduleDateFormatted}
              </Typography>
            </Grid>

            <Grid item>
              {receipt && (
                <div>
                  <Typography className={styles.label}>
                    3 Últimos Dígitos
                  </Typography>
                  <Typography className={styles.defaultText}>
                    {receipt || '-'}
                  </Typography>
                </div>
              )}
            </Grid>

            <Grid item>
              {creationUserName && (
                <div>
                  <Typography className={styles.label}>Criação</Typography>
                  <Typography className={styles.defaultText}>
                    {creationUserName || '-'}
                  </Typography>
                </div>
              )}
            </Grid>

            <Grid item>
              <>
                <Typography className={styles.label}>
                  Data de criação
                </Typography>
                <Typography className={styles.defaultText}>
                  {formatedCreationDate || '-'}
                </Typography>
              </>
            </Grid>

            <Grid item>
              <>
                <Typography className={styles.label}>
                  Data de confirmação
                </Typography>
                <Typography className={styles.defaultText}>
                  {depositProcessedOn || '-'}
                </Typography>
              </>
            </Grid>

            <Grid item md={12} lg={12}>
              <Typography className={styles.label}>Observação</Typography>
              <Typography className={styles.defaultText}>
                {notes || 'Nenhuma'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="space-between" className={styles.dataContainer}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography className={styles.mediumText}>
                Conta origem
              </Typography>
            </Grid>

            <Grid item>
              <Grid container spacing={3} className={styles.dataGridContainer}>
                <Grid item>
                  <Typography className={styles.label}>Banco</Typography>
                  <Typography className={styles.defaultText}>
                    {origin?.bankName}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={styles.label}>Agência</Typography>
                  <Typography className={styles.defaultText}>
                    {origin?.agency}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={styles.label}>Conta</Typography>
                  <Typography className={styles.defaultText}>
                    {origin?.account}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography className={styles.label}>CPF</Typography>
                  <Typography className={styles.defaultText}>
                    {origin?.cpfCnpj}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={styles.label}>Titular</Typography>
                  <Typography className={styles.defaultText}>
                    {originName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={styles.verticalDivider} />

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography className={styles.mediumText}>
                Conta destino
              </Typography>
            </Grid>

            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography className={styles.label}>Banco</Typography>
                  <Typography className={styles.defaultText}>
                    {destination?.bankName}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={styles.label}>Agência</Typography>
                  <Typography className={styles.defaultText}>
                    {destination?.agency}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={styles.label}>Conta</Typography>
                  <Typography className={styles.defaultText}>
                    {destination?.account}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={styles.label}>CNPJ</Typography>
                  <Typography className={styles.defaultText}>
                    {destination?.cpfCnpj}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Box mt={2}>
                <Typography className={styles.label}>Razão social</Typography>
                <Typography className={styles.defaultText}>
                  {destination?.name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default TedDetails;
