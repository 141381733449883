import { api } from '../../../../services/api';

export default class ValidadeCpf {
  static getCpf({
    companyCodeValue,
    cpfValue,
    requestOptions,
    setStates,
    setErrorMessageCPF,
  }) {
    api
      .post(
        `employees/cpf/${cpfValue}/${companyCodeValue}/validate`,
        {},
        requestOptions,
      )
      .then(data => {
        const dataFromRequest = data.data;
        setStates(dataFromRequest);
      })
      .catch(error => {
        return setErrorMessageCPF(
          error?.response?.data?.errors[0]?.errorDetail,
        );
      });
  }
}
