import React from 'react';
import { Paper } from '@material-ui/core';
import { useErrorCardsList } from './Hooks/useErrorsCardsList';
import { ErrorCardHarder } from './Components/ErrorCardHeader';
import { ErrorCardList } from './Components/ErrorCardList';

export function ErrorsCard({
  generalData = {},
  errorsList = [],
  isFileXls = true,
  handleEditEmployeeBankData = () => {},
}) {
  const { accordionsObject } = useErrorCardsList();

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        padding: '2rem',
        marginBottom: '4rem',
        borderRadius: '0.5rem',
      }}
    >
      <ErrorCardHarder
        generalData={generalData}
        accordionsObject={accordionsObject}
      />

      <ErrorCardList
        errorsList={errorsList}
        accordionsObject={accordionsObject}
        isFileXls={isFileXls}
        handleEditEmployeeBankData={handleEditEmployeeBankData}
      />
    </Paper>
  );
}
