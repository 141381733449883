import React, { useMemo, useReducer } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Check } from '@material-ui/icons';
import styles from './styles.module.css';
import { useConsignedMarginBatchConfirm } from '../../utils/hooks';
import brazilianCurrencyFormatter from '../../../../../../../utils/brazilianCurrencyFormatter';
import formatCPF from '../../../../../../../utils/formatCPF';

function SuccessAccordion() {
  const { data } = useConsignedMarginBatchConfirm();

  const [opened, toggleOpen] = useReducer(dirt => !dirt, true);

  const list = useMemo(() => {
    if (!data) return [];

    return data.lines.filter(line => !line.withProcessingErrors);
  }, [data]);

  if (data && data.validationSuccessLines === 0) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header} data-show="true" onClick={toggleOpen}>
        <Box className={styles.icon}>
          <Check />
        </Box>
        <Box>
          <Typography className={styles.title}>
            <b>{data ? data.validationSuccessLines : 0} colaborador(es)</b>{' '}
            identificado(s) com sucesso
          </Typography>
          <Typography className={styles.subtitle}>
            Esses colaboradores estão aptos para serem cadastrados.
          </Typography>
        </Box>
      </Box>
      {opened && (
        <Box className={styles.content}>
          <Grid container className={styles.table}>
            <Grid item xs={4}>
              <Typography className={styles.tableTitle}>CPF</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.tableTitle}>Salário</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.tableTitle}>Margem</Typography>
            </Grid>

            {list.map(line => (
              <React.Fragment key={line.lineNumber}>
                <Grid item xs={4}>
                  <Typography className={styles.tableRow}>
                    {line.cpf ? formatCPF(line.cpf) : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={styles.tableRow}>
                    {brazilianCurrencyFormatter(line.salary || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={styles.tableRow}>
                    {brazilianCurrencyFormatter(line.assignableMargin || 0)}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default SuccessAccordion;
