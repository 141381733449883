import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsManualPayroll as Actions } from '../../../../../../redux/store/Payroll/ManualPayroll';

export const useEmployeesList = () => {
  const dispatch = useDispatch();
  const {
    employeesAproved,
    selectedRowsEmployees,
    previewSelected,
    employeeBankData,
  } = useSelector(state => state.payroll.manualPayroll);

  const employeesResignCountSelecteds = selectedRowsEmployees.filter(
    employeesResign =>
      employeesResign?.companies &&
      employeesResign?.companies[0]?.status === 'RESIGN',
  );

  const handleCloseSuccessSnackbar = () => {
    dispatch(Actions.resetSuccessStates());
  };

  useEffect(() => {
    dispatch(Actions.getCostAndCenter());
    dispatch(Actions.getBanksList());
    dispatch(Actions.getEmployeesCount());
    dispatch(Actions.setPreviewSelected(selectedRowsEmployees));
  }, []);

  return {
    employeesAproved,
    previewSelected,
    employeesResignCountSelecteds,
    employeeBankData,
    handleCloseSuccessSnackbar,
  };
};
