import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import moment from 'moment';
import styles from './styles.module.css';
import EmployeesRechargeListModal from './EmployeesListModal';
import TableInputMoney from './TableInputMoney';
import BottomBar from '../../../Payroll/ManualPayroll/UI/BottomBar';
import SkippedInvalidModal from '../../../../components/PrimaryModal';
import { api } from '../../../../services/api';
import { useValidationForUnformattedDate } from '../../../../hooks/useValidationForUnformattedDate';
import CustomDatePicker from '../../../../components/DatePicker';
import ErrorModal from '../../../../components/PrimaryModal';

export default function CreateRecharge() {
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const [paymentType, setPaymentType] = useState('');
  const [openEmployeesListModal, setOpenEmployeesListModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [walletTypes, setWalletTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openSkippedInvalidModal, setOpenSkippedInvalidModal] = useState(false);

  const today = moment();
  const tomorrowFirstHour = moment(today)
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [date, setDate] = useState(tomorrowFirstHour || '');
  const validateDateInfo = useValidationForUnformattedDate(date);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const createRechargesBeevale = JSON.parse(
    sessionStorage.getItem('createRechargesBeevale'),
  );

  const [selectedEmployees, setSelectedEmployees] = useState(
    !openEmployeesListModal ? createRechargesBeevale : [],
  );

  const SHOW_BOTTOM_BAR = Boolean(
    date &&
      paymentType &&
      createRechargesBeevale?.length > 0 &&
      createRechargesBeevale?.find(
        item => typeof item.amount === 'number' && item.amount > 0,
      ) &&
      createRechargesBeevale?.find(
        item => typeof item.walletType === 'string' && item.walletType,
      ),
  );

  const types = [
    { code: 'PIX', description: 'Pix' },
    { code: 'BANK_SLIP', description: 'Boleto bancário' },
  ];

  const onOpenEmployeesModal = open => {
    setOpenEmployeesListModal(open);
  };

  function onCancelClicked() {
    setOpenEmployeesListModal(false);
  }

  useEffect(() => {
    getWalletTypes();
  }, []);

  function getWalletTypes() {
    api
      .get(`/beeValeBenefits/walletTypes/${currentCompanyCode}`)
      .then(({ data }) => setWalletTypes(data));
  }

  function handleCreateRecharge() {
    setLoading(true);
    let existError = false;
    selectedEmployees.map((item, index) => {
      if (!item.walletType || item.amount <= 0) {
        setLoading(false);
        setOpenSkippedInvalidModal(true);
        existError = true;
      }
    });

    if (!existError) {
      api
        .post(
          `/beeValeBenefits/recharge/create/${currentCompanyCode}`,
          selectedEmployees,
          {
            params: {
              type: paymentType,
              endDate: moment(date).format('YYYY-MM-DD'),
            },
          },
        )
        .then(({ data }) => {
          history.push('/beevaleBenefits/recharge');
        })
        .catch(
          ({
            response: {
              data: { errors },
            },
          }) => {
            setIsErrorModalOpen(true);
            setErrorModalTitle('Houve um erro ao criar a recarga');
            setErrorModalMessage(
              errors?.[0]?.errorDetail ??
                'Por favor, tente novamente mais tarde ou entre em contato com o nosso atendimento no menu ao lado.',
            );
          },
        )
        .finally(() => setLoading(false));
    }
  }

  function includeEmployees(employeesSelected) {
    const employeesAlreadySelected =
      JSON.parse(sessionStorage.getItem('createRechargesBeevale')) ?? [];
    const employeesToPay =
      employeesAlreadySelected?.length === 0
        ? employeesSelected
        : employeesSelected?.map(employee => {
            const existentEmployee = employeesAlreadySelected?.find(
              selected => employee?.cpf === selected?.cpf,
            );
            if (existentEmployee !== undefined) {
              return {
                ...existentEmployee,
                amount: existentEmployee?.amount,
                walletType: existentEmployee?.walletType,
              };
            }
            return employee;
          });

    sessionStorage.setItem(
      'createRechargesBeevale',
      JSON.stringify(employeesToPay),
    );

    setSelectedEmployees(employeesToPay);
    setOpenEmployeesListModal(false);
  }

  function onChangeDate(value) {
    setDate(value);
  }

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={styles.manualPaymentContainer}
      >
        <Grid container xs={12} md={12} lg={12} alignItems="center">
          <Grid item md={4} lg={4}>
            <Typography className={styles.titleStyle}>Criar recarga</Typography>
          </Grid>
        </Grid>

        <div className={styles.divider} />
        <Box mb={0.5} />

        <Grid container spacing={2} alignItems="center">
          <Grid item md={3}>
            <FormControl
              variant="outlined"
              className={styles.textFieldStyle}
              size="small"
            >
              <InputLabel id="payment-type-select-box-label">
                Tipo de pagamento*
              </InputLabel>

              <Select
                labelId="payment-type-select-box-label"
                label="Tipo de Pagamento*"
                value={paymentType}
                onChange={({ target: { value } }) => setPaymentType(value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {types?.map(type => (
                  <MenuItem key={type.description} value={type.code}>
                    {type.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <FormControl className={styles.textFieldStyle}>
              <CustomDatePicker
                value={date}
                label={date ? 'Vencimento do pagamento*' : null}
                placeholder="Vencimento do pagamento*"
                onChange={onChangeDate}
                minDate={
                  paymentType !== 'BANK_SLIP'
                    ? moment().add(5, 'day')
                    : moment()
                }
                error={validateDateInfo}
                disableCalendarIcon={validateDateInfo}
                height={2}
              />
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <Button
              className={styles.includeCollaboratorsButton}
              onClick={() => onOpenEmployeesModal(true)}
              disabled={!paymentType}
            >
              <AddRoundedIcon
                className={styles.includeCollaboratorsButtonIcon}
              />
              Incluir colaboradores
            </Button>
          </Grid>
        </Grid>

        <EmployeesRechargeListModal
          open={openEmployeesListModal}
          onCancelClicked={onCancelClicked}
          onConfirmClicked={includeEmployees}
        />

        {createRechargesBeevale?.length > 0 ? (
          <TableInputMoney
            createRechargesBeevale={createRechargesBeevale}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            SHOW_BOTTOM_BAR={SHOW_BOTTOM_BAR}
            loading={loading}
            walletTypes={walletTypes}
          />
        ) : (
          <Grid
            container
            className={styles.includeEmptyArea}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              {!paymentType ? (
                <Tooltip title="Os campos Tipo de pagamento e Data do pagamento devem estar todos preenchidos">
                  <div>
                    <Button
                      className={styles.disabledIncludeEmptyAreaButton}
                      disabled
                    >
                      Incluir colaboradores
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  className={styles.includeEmptyAreaButton}
                  onClick={() => onOpenEmployeesModal(true)}
                >
                  Incluir colaboradores
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        <SkippedInvalidModal
          open={openSkippedInvalidModal}
          title="Atenção!"
          text="Alguns funcionários estão com os valores zerados ou tipo de recarga não escolhida revise as informações e tente novamente!"
          confirmButtonText="Ok, entendi!"
          onConfirmClicked={() => setOpenSkippedInvalidModal(false)}
        />

        <BottomBar
          SHOW_BOTTOM_BAR={SHOW_BOTTOM_BAR}
          onBackClick={() => {}}
          onNextClick={handleCreateRecharge}
          loading={loading}
          date={date}
          paymentHour
        />

        <ErrorModal
          open={isErrorModalOpen}
          onConfirmClicked={() => {
            setIsErrorModalOpen(false);
          }}
          title={errorModalTitle}
          text={errorModalMessage}
          confirmButtonText="Entendi"
        />
      </Grid>
    </>
  );
}
