import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Filters from '../Filters';
import Table from '../Table';
import InsuranceList from '../InsurancesList';
import EmptyTableView from '../EmptyTableView';

import styles from '../../styles.module.css';
import { isMobile } from '../../../../../utils/breakpoints';

export default function InsuranceTab({
  clearSelectedInsurances,
  employeeData,
  loadingTable,
  setSelected,
  selected,
  handleContinue,
  setOpenDrawer,
  openDrawer,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalSize,
  currentPageSize,
  hasNotAssureds,
  showSalaryTextField,
  setShowSalaryTextField,
  currentCompanyCode,
  setEmployeeData,
  openSnackbarOnSalaryUpdate,
  openSnackbarErrorOnSalaryUpdate,
}) {
  const { url } = useRouteMatch();
  const history = useHistory();

  if (hasNotAssureds) {
    return (
      <EmptyTableView
        onClick={() => history.push(`${url}/insurance-single`)}
        hiddenButton={isMobile}
      />
    );
  }

  return (
    <div
      className={
        selected.length > 0 ? styles.containerMargin : styles.defaultContainer
      }
    >
      <>
        <Filters
          clearSelectedInsurances={clearSelectedInsurances}
          setOpenDrawer={setOpenDrawer}
        />

        {isMobile ? (
          <InsuranceList
            employeeData={employeeData}
            setSelected={setSelected}
            selected={selected}
            handleContinue={handleContinue}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
            loadingTable={loadingTable}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            currentPageSize={currentPageSize}
          />
        ) : (
          <Table
            employeeData={employeeData}
            setEmployeeData={setEmployeeData}
            loadingTable={loadingTable}
            setSelected={setSelected}
            selected={selected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalSize={totalSize}
            showSalaryTextField={showSalaryTextField}
            setShowSalaryTextField={setShowSalaryTextField}
            currentCompanyCode={currentCompanyCode}
            openSnackbarOnSalaryUpdate={openSnackbarOnSalaryUpdate}
            openSnackbarErrorOnSalaryUpdate={openSnackbarErrorOnSalaryUpdate}
          />
        )}
      </>
    </div>
  );
}
