import React from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Grid,
  Typography,
  Button,
  Snackbar,
  Box,
  Tooltip,
} from '@material-ui/core';

import {ReactComponent as ContentCopy} from '../../../../assets/contentCopy.svg';

import MuiAlert from '@material-ui/lab/Alert';

import styles from './styles.module.css';

import brazilianCurrencyFormatter2 from '../../../../utils/brazilianCurrencyFormatter2';

export default function Card({
  result,
  modalSucessCopyPix,
  setModalSucessCopyPix,
}) {
  const {
    expirationDate,
    base64Qrcode,
    copyPasteCode,
    pixValue,
    notes,
  } = result;

  const COMPANIES = JSON.parse(sessionStorage.getItem('companies'));
  const COMPANY_INDEX = JSON.parse(
    sessionStorage.getItem('currentCompanyIndex'),
  );
  const COMPANY_NAME = COMPANIES[COMPANY_INDEX]?.name
    ? COMPANIES[COMPANY_INDEX].name
    : COMPANIES[0].name;

  const QRCode = ({ data }) => (
    <img src={`data:image/jpeg;base64,${data}`} alt="qrcode" className={styles.qrCode}  />
  );

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Grid className={styles.card} container direction='row'>
      
      <div className={styles.wrapper}>
        <div className={styles.cardContentContainer} style={{minWidth: 240}}>
          <Typography style={{  marginTop: 6}} className={styles.bigText}>Informações do Pix:</Typography>
          <Typography className={styles.label}>Valor</Typography>
          <Typography className={styles.highlightedText}>
            {brazilianCurrencyFormatter2(pixValue)}
          </Typography>
          
          <div className={styles.rowContainer} style={{marginTop: 12}}>
            <div>
              <Typography style={{ marginBottom: 12}}  className={styles.label}>Empresa</Typography>
              <Typography className={styles.defaultText}>{COMPANY_NAME}</Typography>
            </div>
            <div>
              <Typography  style={{ marginBottom: 12}}  className={styles.label}>Validade</Typography>
              <Typography className={styles.defaultText}>
                {moment(expirationDate).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        </div>

        <div className={styles.divider}/>

        <div className={styles.cardContentContainer} style={{maxWidth: 480}}>
          <div className={styles.rowContainer}>
            <div>
              <Box style={{textAlign:'center', width:"fit-content"}}>
                  <Grid style={{width:'fit-content', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                    <div className={styles.moldura}></div>
                    <QRCode data={base64Qrcode} className={styles.qrCode} />
                  </Grid>
                  <Grid>
                    <Tooltip title="Copiar código pix" placement="top">
                      <CopyToClipboard text={copyPasteCode}>
                        <Button
                          onClick={() => setModalSucessCopyPix(true)}
                          variant='contained'
                          disableElevation
                          disableRipple
                          style={{
                            backgroundColor:'#F6F3F9',
                            padding: 6,
                            paddingInline:10,
                            textTransform:'none',
                            borderRadius: 6
                          }}
                          startIcon={
                            <Box 
                            className={styles.iconContainer}
                          >
                            <ContentCopy/>
                          </Box>
                        }
                      >
                        <Typography className={styles.copyButtonText}>Copiar código</Typography>
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </Grid>
              </Box>
            </div>
            <div style={{ display:"flex", flexDirection:'column', gap: 12}}>
              <Typography className={styles.defaultText}>Instruções</Typography>
              <Typography className={styles.subtitleText}>1 - Abra o aplicativo de sua preferência que você irá realizar o pagamento.</Typography>
              <Typography className={styles.subtitleText}>2 - Escanceie a imagem ou copie o código.</Typography>
            </div>
          </div>
        </div>  
      </div>

      <Snackbar
        open={modalSucessCopyPix}
        autoHideDuration={3000}
        onClose={() => setModalSucessCopyPix(false)}
      >
        <Alert onClose={() => setModalSucessCopyPix(false)} severity="success">
          Código pix copiado com sucesso
        </Alert>
      </Snackbar>      
    </Grid>
  );
}
