import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Header from './Header';
import Filters from './Filters';
import Table from './Table/Table';
import MobileList from './MobileList';
import DrawerContent from './DrawerContent';
import Chips from './Chips';

import LogoLoading from '../../../components/LogoLoading';
import FilterDrawer from '../../../components/FilterDrawer';
import AlreadyFileUploadModal from '../../../components/PrimaryModal';
import RemovePaycheckModal from '../../../components/PrimaryModal';
import ResultNotFound from '../../../components/ResultNotFound';
import CustomSnackbar from '../../../components/Snackbar';

import { ReactComponent as FileIcon } from '../../../assets/file-icon.svg';

import { isMobile } from '../../../utils/breakpoints';

import { api } from '../../../services/api';
import TablePaginationActions from './Table/TablePaginationActions';
import FakeRowsTable from '../Review/Table/FakeTable';

export default function PaycheckManagement() {
  const [competence, setCompetence] = useState('');
  const [paycheckType, setPaycheckType] = useState('Todos');
  const [idSearch, setIdSearch] = useState('');

  const [loading, setLoading] = useState(true);
  const [loadingRemovePaycheck, setLoadingRemovePaycheck] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  
  const [openDrawer, setOpenDrawer] = useState(false);
  const [changedFilter, setChangedFilter] = useState(false);
  const [openAlreadyFileUploadModal, setOpenAlreadyFileUploadModal] = useState(
    false,
  );

	const [totalSize, setTotalSize] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

  const [paychecksList, setPaychecksList] = useState([]);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarDescription, setSnackbarDescription] = useState("");

  const [openRemovePaycheckModal, setOpenRemovePaycheckModal] = useState(false);
  const [selectedPaycheck, setSelectedPaycheck] = useState(null);

  const history = useHistory();

  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const UPLOAD_DATA_PAYCHECK = JSON.parse(
    localStorage.getItem(`uploadDataPaycheck-${CURRENT_COMPANY_CODE}`),
  );
  const EMPTY_LIST = paychecksList?.length === 0 && changedFilter;
  const WHITOUT_PAYCHECK = paychecksList?.length === 0 && !changedFilter;

	const handlePageChange = (_, newPage) => setCurrentPage(newPage);

	const handleRowsPerPageChange = event => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	}

  function getPaychecks() {
    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize,
        id: idSearch,
        type: paycheckType === 'Todos' ? '' : paycheckType,
        competencyDate: competence
          ? `${moment(competence).format('YYYY-MM')}-01`
          : '',
      },
    };

    setLoadingFilter(true);

    api.get(`/paychecks/${CURRENT_COMPANY_CODE}`, requestOptions)
      .then(({ data }) => {
        setPaychecksList(data.content);
        setTotalSize(data.totalSize);
      })
      .finally(() => {
        setLoading(false);
        setLoadingFilter(false);
      });
  }

  function goToImportPaycheck() {
    if (UPLOAD_DATA_PAYCHECK?.status === 'PROCESSING') {
      setOpenAlreadyFileUploadModal(true);

      return;
    }

    history.push('/paycheck/paycheck-menu/');
  }

  function goToAlreadyProcess() {
    history.push('/paycheck/paycheck-import');
  }

  function applyFilter() {
    setOpenDrawer(false);
  }

  function cleanFilters() {
    setCompetence('');
    setIdSearch('');
    setPaycheckType('Todos');
    setCurrentPage(0);
  }

  function goToReview(file) {
    if(file?.id && file?.canToReview)
      history.push(`/paycheck/paycheck-data-review/${file?.id}`);
  }

  function removePaycheck(file) {
    if(file?.processId && file?.canToRemove) {
      loadingRemovePaycheckOn();
    
      api.put(`/files/paychecks/cancel/${file.processId}`)
      .then(() => {
        handleCloseRemovePaycheckModal();
        openSuccessSnackbar("Arquivo removido com sucesso!");
        getPaychecks();
      })
      .catch(error => openErrorSnackbar(error.response?.data?.errorDetail))
      .finally(() => loadingRemovePaycheckOff());
    }
  }

  function goToDetails(row) {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Detalhes da importação',
        step: 0,
        route: history.location.pathname,
      }),
    );

    history.push(`paycheck/details/${row?.id}`);
  }

  const loadingRemovePaycheckOn = () => setLoadingRemovePaycheck(true);
  const loadingRemovePaycheckOff = () => setLoadingRemovePaycheck(false);

  const openSnackbar = () => setShowSnackbar(true);
  const closeSnackbar = () => setShowSnackbar(false);

  const removePaycheckModalOn = () => setOpenRemovePaycheckModal(true);
  const removePaycheckModalOff = () => setOpenRemovePaycheckModal(false);

  const openSuccessSnackbar = description => {
    openSnackbar();
    setSnackBarSeverity("success");
    setSnackbarDescription(description);
  }

  const openErrorSnackbar = description => {
    openSnackbar();
    setSnackBarSeverity("error");
    setSnackbarDescription(description);
  }

  const handleRemovePaycheck = file => {
    removePaycheckModalOn();
    setSelectedPaycheck(file);
  }

  const handleCloseRemovePaycheckModal = () => {
    removePaycheckModalOff();
    setSelectedPaycheck(null);
  }

  useEffect(() => {
    getPaychecks();
  }, [currentPage, pageSize, competence, paycheckType]);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
				if(currentPage > 0)
					setCurrentPage(0)
				else
          getPaychecks();
			}, 500);

      return () => clearTimeout(delay);
    }
  }, [idSearch]);

  function renderPaycheckList() {
    if (isMobile) {
      return (
        <MobileList
          goToDetails={goToDetails}
          goToReview={goToReview}
          paychecksList={paychecksList}
          loadingFilter={loadingFilter}
        />
      );
    }
    return (
      <Table
        data={paychecksList}
        loading={loadingFilter}
        totalSize={totalSize}
        pageSize={pageSize}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        TablePaginationActions={TablePaginationActions}
        loadingRemovePaycheck={loadingRemovePaycheck}
        selectedPaycheck={selectedPaycheck}
        goToDetails={goToDetails}
        goToReview={goToReview}
        handleRemovePaycheck={handleRemovePaycheck}
      />
    );
  }

  function resultNotFound() {
    return (
      <ResultNotFound
        text="Nenhum resultado encontrado"
        buttonText="Limpar filtros"
        onClick={cleanFilters}
      />
    );
  }

  if (loading && !changedFilter) {
    return <LogoLoading />;
  }

  return (
    <>
      <Header
        goToImportPaycheck={goToImportPaycheck}
        setOpenDrawer={setOpenDrawer}
      />

      <Filters
        competence={competence}
        paycheckType={paycheckType}
        setCompetence={setCompetence}
        setPaycheckType={setPaycheckType}
        idSearch={idSearch}
        setIdSearch={setIdSearch}
        setChangedFilter={setChangedFilter}
        WHITOUT_PAYCHECK={WHITOUT_PAYCHECK}
      />

      <Chips
        paycheckType={paycheckType}
        setPaycheckType={setPaycheckType}
        competence={competence}
        setCompetence={setCompetence}
      />

      {WHITOUT_PAYCHECK ? (
        <ResultNotFound
          icon={<FileIcon />}
          text="A lista com os arquivos de contra cheques importados aparecerá aqui"
          alternativeText={
            !isMobile &&
            'Clique em “Importar contra cheques” para importar seu primeiro arquivo.'
          }
          buttonText={!isMobile && 'Importar contra cheques'}
          onClick={goToImportPaycheck}
        />
      ) : EMPTY_LIST ? (
        resultNotFound()
      ) : (
        renderPaycheckList()
      )}

      <FilterDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        content={
          <DrawerContent
            competence={competence}
            paycheckType={paycheckType}
            setCompetence={setCompetence}
            setPaycheckType={setPaycheckType}
          />
        }
        disabledButton={!competence && !paycheckType}
        applyFilter={applyFilter}
        cleanFilter={cleanFilters}
      />

      <AlreadyFileUploadModal
        open={openAlreadyFileUploadModal}
        title="Já existe um arquivo em processamento. Deseja acompanhar seu status?"
        text="Você só poderá iniciar uma nova impotação, quando o que está em andamento for concluído."
        cancelButtonText="Não"
        confirmButtonText="Sim"
        onCancelButtonClicked={() => setOpenAlreadyFileUploadModal(false)}
        onConfirmClicked={goToAlreadyProcess}
      />

      <RemovePaycheckModal
        title="Remover arquivo confirmado?"
        text="Após a remoção, essa operação não poderá ser desfeita."
        open={openRemovePaycheckModal}
        cancelButtonText="Não"
        disabledCancel={loadingRemovePaycheck}
        confirmButtonText="Sim"
        disabledConfirm={loadingRemovePaycheck}
        onCancelButtonClicked={handleCloseRemovePaycheckModal}
        onConfirmClicked={() => removePaycheck(selectedPaycheck)}
        loading={loadingRemovePaycheck}
      />
      
      <CustomSnackbar
        open={showSnackbar}
        severity={snackBarSeverity}
        message={snackBarDescription}
        close={() => closeSnackbar()}
      />
    </>
  );
}
