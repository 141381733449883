import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

import { isMobile } from '../../../utils/breakpoints';
import formatOnlyNumbers from '../../../utils/formatOnlyNumbers';

export default function Filters({
  competence,
  paycheckType,
  setCompetence,
  setPaycheckType,
  idSearch,
  setIdSearch,
  setChangedFilter,
  WHITOUT_PAYCHECK
}) {
  const [textFieldFocused, setTextFieldFocused] = useState('');

  function onChangeCompetence(value) {
    setCompetence(value);
    setChangedFilter(true);
  };

  function onChangePayCheckType(event) {
    setPaycheckType(event.target.value);
    setChangedFilter(true);
  };

  function onChangeId(event) {
    setIdSearch(event.target.value);
    setChangedFilter(true);
  };

  return (
    <div className={WHITOUT_PAYCHECK ? styles.hidden : styles.filtersContainer}>
      <FormControl className={styles.textFieldId}>
        <TextField
          className={styles.identifierField}
          onChange={onChangeId}
          placeholder={isMobile ? "Identificador" : "Pesquise por identificador "}
          fullWidth
          variant="outlined"
          value={idSearch ? formatOnlyNumbers(idSearch) : ''}
          onFocus={() => setTimeout(() => setTextFieldFocused('idSearch'), 200)}
          onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.iconColor} />
              </InputAdornment>
            ),
            endAdornment: (idSearch?.length > 0 && textFieldFocused === 'idSearch') && (
              <IconButton
                className={styles.endAdornment}
                onClick={() => setIdSearch('')}
              >
                <CloseRoundedIcon className={styles.endAdornmentIcon} />
              </IconButton>
            )
          }}
        />
      </FormControl>

      <FormControl
        variant="outlined"
        className={styles.textField}
      >
        <InputLabel id="payment-type-select">
          Tipo
        </InputLabel>
        <Select
          labelId="payment-type-select"
          label="Tipo"
          onChange={onChangePayCheckType}
          value={paycheckType}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value='Todos'>
            Todos
          </MenuItem>
          <MenuItem value="SALARY">
            Salário
          </MenuItem>
          <MenuItem value="VACATION">
            Férias
          </MenuItem>
          <MenuItem value="THIRTEENTH_SALARY">
            13º Salário
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        className={styles.textField}
      >
        <InputLabel
          id="select-date-label"
          style={competence ? { display: 'none' } : {}}
          className={
            competence ? styles.disabledInputLabel : styles.inputLabel
          }
        >
          Competência
        </InputLabel>
        <MuiPickersUtilsProvider
          utils={(DateFnsUtils, MomentUtils)}
          locale={'pt-br'}
        >
          <KeyboardDatePicker
            openTo="year"
            views={['year', 'month']}
            label={competence ? 'Competência' : null}
            value={competence ? competence : null}
            onChange={onChangeCompetence}
            inputVariant="outlined"
            keyboardIcon={<EventIcon className={styles.iconColor} />}
            InputAdornmentProps={{ position: 'end' }}
            helperText={false}
            error={false}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </div>
  )
}
