import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionsManualPayroll as Actions } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

export const useEmployeesPending = () => {
  const dispatch = useDispatch();
  const { employeesPending, previewSelected } = useSelector(
    state => state.payroll.manualPayroll,
  );

  const handlePaginationRequests = (page, perPage) => {
    dispatch(Actions.setPendingPagination({ page, perPage }));
  };

  const disableSelection = entry => !entry?.hasBankData;

  const handleSelection = useCallback(
    employees => {
      dispatch(Actions.setPreviewSelected(employees));
    },
    [dispatch],
  );

  return {
    handleSelection,
    disableSelection,
    table: {
      data: employeesPending?.data,
      selectedItems: previewSelected,
      length: employeesPending.currentLength,
      isLoading: employeesPending.isLoading,
      hasError: employeesPending.errorOn.hasError,
      requests: handlePaginationRequests,
    },
  };
};
