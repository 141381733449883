import React from 'react';

import { Box, Typography } from '@material-ui/core';

import {
  CheckCircleRounded,
  RemoveCircleRounded,
  BeachAccess,
  Description,
  FiberManualRecord,
} from '@material-ui/icons';

import { ChipStatus } from '../../../../../../../../../../components/ChipStatus';

export const useTableConfig = account => {
  const situationEnums = {
    ACTIVE: 'info',
    RESIGN: 'error',
    INSS_LICENSE: 'success',
    UNPAID_LICENSE: 'success',
    HOLIDAYS: 'success',
  };

  const iconEnums = {
    ACTIVE: <CheckCircleRounded style={{ color: '#2D54B9' }} />,
    RESIGN: <RemoveCircleRounded style={{ color: '#CA2317' }} />,
    INSS_LICENSE: <Description style={{ color: '#19797F' }} />,
    UNPAID_LICENSE: <Description style={{ color: '#19797F' }} />,
    HOLIDAYS: <BeachAccess style={{ color: '#19797F' }} />,
  };

  const labelEnums = {
    ACTIVE: 'Ativo',
    RESIGN: 'Demitido',
    INSS_LICENSE: 'Licença INSS',
    UNPAID_LICENSE: 'Licença não remunerada',
    HOLIDAYS: 'Férias',
  };

  const handleSituation = situation => situationEnums[situation];

  const handleSituationIcon = situation => iconEnums[situation];

  const handleSituationLabel = situation => labelEnums[situation];

  const getAccountMapping = entry =>
    account.origin === 'FACIL_SCD'
      ? entry.existScdAccount
      : entry.existIpAccount;

  const renderAccount = mapping => {
    return (
      <Box
        sx={{
          color: mapping ? '#19797F' : '#E54217',

          display: 'flex',

          flexFlow: 'row nowrap',

          alignItems: 'center',

          gap: '0.2rem',
        }}
      >
        <FiberManualRecord style={{ fontSize: '0.6rem' }} />
        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
          {mapping ? 'Sim' : 'Não'}
        </Typography>
      </Box>
    );
  };

  return [
    {
      key: 'register',
      type: 'field',
      label: 'Matrícula',
      align: 'left',
      render: row => row.registration ?? '-',
    },
    { key: 'name', type: 'field', label: 'Nome', align: 'left' },
    { key: 'cpf', type: 'field', label: 'CPF', align: 'left' },
    {
      key: 'location',
      type: 'field',
      label: 'Centro de custo',
      align: 'left',
      render: row => row.location ?? 'Não cadastrado',
    },
    {
      key: 'existScdAccount',
      type: 'field',
      label: 'Possui conta ativa?',
      align: 'left',
      render: row => renderAccount(getAccountMapping(row)),
    },
    {
      key: 'situation',
      type: 'field',
      label: 'Situação',
      align: 'left',
      render: row => (
        <ChipStatus
          icon={handleSituationIcon(row.status)}
          status={handleSituation(row.status)}
          label={handleSituationLabel(row.status)}
          size="small"
        />
      ),
    },
  ];
};
