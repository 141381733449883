import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Box, Grid, Typography } from '@material-ui/core';

import currencyFormatter from '../../../../utils/currencyFormatter';

import styles from './styles.module.css';
import { isMobile, isTablet } from '../../../../utils/breakpoints';

export default function ValuesCard({
  totalAmount,
  paymentsAmount,
  feesAmount,
  loadingRegenerate,
  hasMoreThanOneDepositAccount,
}) {
  return (
    <Grid container direction="column" className={styles.cardContainer}>
      <Grid item>
        <Typography className={styles.mediumText}>Valor da Folha</Typography>
      </Grid>

      <Grid item>
        <Box mt={3} mb={3}>
          <Typography className={styles.label}>Total</Typography>

          {loadingRegenerate ? (
            <Typography className={styles.valueText}>
              <Skeleton width={100} color="#202020" highlightColor="#444" />
            </Typography>
          ) : (
            <Typography className={styles.valueText}>
              {currencyFormatter(totalAmount)}
            </Typography>
          )}
        </Box>
      </Grid>

      <Grid
        container
        direction={
          isMobile || isTablet || !hasMoreThanOneDepositAccount
            ? 'row'
            : 'column'
        }
        spacing={2}
      >
        <Grid item>
          <Typography className={styles.label}>Folha</Typography>

          {loadingRegenerate ? (
            <Typography className={styles.text}>
              <Skeleton width={100} color="#202020" highlightColor="#444" />
            </Typography>
          ) : (
            <Typography className={styles.text}>
              {currencyFormatter(paymentsAmount)}
            </Typography>
          )}
        </Grid>

        <Grid item>
          <Typography className={styles.label}>Tarifa</Typography>

          {loadingRegenerate ? (
            <Typography className={styles.text}>
              <Skeleton width={100} color="#202020" highlightColor="#444" />
            </Typography>
          ) : (
            <Typography className={styles.text}>
              {currencyFormatter(feesAmount)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
