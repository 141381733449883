import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    background: '#219653',
    width: 341,
    height: 57,
    margin: '0 auto',
    zIndex: 1000,
    borderRadius: 10,
    position: 'fixed',
    bottom: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '90%'
  },
  paper: {
    color: '#ffffff'
  }
}));

export default function AlertError(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          style={{ transformOrigin: '0 0 0', }}
        >
          <div className={classes.paper}>
            {props.message}
          </div>
        </div>
      </div>
    </div>
  );
}
