import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Header from './Header';
import Card from './Card';
import Footer from '../../../../components/AnimatedBottomBar';

export default function QRCode() {
  const location = useLocation();
  const history = useHistory();

  const [modalSucessCopyPix, setModalSucessCopyPix] = useState(false);

  function goBack() {
    history.push('/cash-in');
  }

  return (
    <>
      <Header />
      <Card
        result={location.state}
        modalSucessCopyPix={modalSucessCopyPix}
        setModalSucessCopyPix={setModalSucessCopyPix}
      />
      <Footer onCancel={goBack} hiddenNextButton />
    </>
  );
}
