import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, Checkbox, Dialog, FormControlLabel } from '@material-ui/core';
import { CancelOutlined } from '@material-ui/icons';
import { ReactComponent as SomapayScdPresentation } from '../../../../../assets/somapay-scd-presentation.svg';
import Buttons from '../../../../../components/Buttons';
import { api } from '../../../../../services/api';

export const SomapayScdPresentationModal = NiceModal.create(() => {
  const modal = useModal();
  const [checked, setChecked] = useState(false);

  return (
    <Dialog
      maxWidth="lg"
      open={modal.visible}
      PaperProps={{
        style: { backgroundColor: 'transparent', boxShadow: 'none' },
      }}
    >
      <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
        <Buttons.IconButton
          tooltipTitle="Fechar"
          icon={<CancelOutlined style={{ color: 'white' }} />}
          onClick={() => {
            modal.hide();

            if (checked) {
              api.post('/popup/scd-advertising/never-display').finally(() => {
                localStorage.setItem('showScdPopup', false);
              });
            }
          }}
        />
      </Box>

      <SomapayScdPresentation />

      <Box sx={{ position: 'absolute', right: 30, bottom: 45 }}>
        <Buttons.SecondaryButton
          variant="contained"
          title="Clique e saiba mais"
          onClick={() =>
            window.open(
              'https://materiais.somapay.com.br/lp-comunicacao-migracao-scd',
              '_blank',
            )
          }
        />
      </Box>

      <Box sx={{ position: 'absolute', right: 10, bottom: 5 }}>
        <FormControlLabel
          label="Não mostrar novamente"
          control={
            <Checkbox
              value={checked}
              onClick={() => setChecked(prevState => !prevState)}
              style={{ color: 'white' }}
            />
          }
          style={{ color: 'white' }}
        />
      </Box>
    </Dialog>
  );
});
