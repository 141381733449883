import { Box } from '@material-ui/core';
import React from 'react';

export function InfoCard({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1.25rem',
        borderRadius: '4px',
        boxShadow: '0px 4px 10px #3b35411f',
      }}
    >
      {children}
    </Box>
  );
}
