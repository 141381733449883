import { Button, CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

import styles from './styles.module.css';

function BottomButtons({
  isHidden = true,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  loading,
  disabledConfirmButton,
}) {
  return (
    <Grid
      style={{ display: isHidden && 'none' }}
      className={styles.containerButtons}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={6}>
        <Button className={styles.cancelButton} onClick={onCancel}>
          {cancelText}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          className={styles.confirmButton}
          onClick={onConfirm}
          disabled={disabledConfirmButton}
        >
          {loading ? (
            <CircularProgress size={18} className={styles.circularProgress} />
          ) : (
            <span>{confirmText}</span>
          )}
        </Button>
      </Grid>
    </Grid>
  );
}

export default BottomButtons;
