import React, { memo } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import PrimaryModal from '../../../../../components/PrimaryModal';
import CardTools from '../../../../../components/CardTools';
import Form from '../../../../../components/Form';
import { useAddBankDataModal } from './Hooks/useAddBankDataModal';
import Buttons from '../../../../../components/Buttons';

export function AddBankDataModal({
  employeeName = '',
  employeeCpfOrRegistration = '',
  defaultValues,
  handleCloseModal = () => {},
  handleBankFormSubmit = () => {},
  ...primaryModalProps
}) {
  const {
    formObject: { control, handleSubmit, isAbleToSubmit, handleClose },
    selectOptionsObject: { bankOptions, accountTypeOptions },
    requestStatusObject: { isLoading, errorOn },
  } = useAddBankDataModal({
    defaultValues,
    handleCloseModal,
  });

  return (
    <PrimaryModal {...primaryModalProps}>
      <Form.FormBase onSubmit={handleSubmit(handleBankFormSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <CardTools.CardInfo title="Nome" description={employeeName} />
            <CardTools.CardInfo
              title="CPF"
              description={employeeCpfOrRegistration}
            />
          </Box>

          <Divider orientation="horizontal" />

          <Grid container direction={{ sm: 'column', md: 'row' }} spacing={2}>
            <Grid item sm={12} md={12}>
              <Controller
                name="bank"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.AutocompleteField
                    label="Banco*"
                    options={bankOptions}
                    getOptionLabel={e => e?.name}
                    getOptionValue={e => e?.code}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <Controller
                name="accountType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.SelectField
                    label="Tipo de conta*"
                    options={accountTypeOptions}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="agency"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Agência*"
                    precision={5}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="agencyDigit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Dígito da agência"
                    precision={2}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="account"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Número da conta*"
                    precision={12}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="accountDigit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Dígito da conta*"
                    precision={2}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          {errorOn?.hasError && (
            <Alert severity="error">{errorOn?.message}</Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '1rem' }}>
            <Buttons.SecondaryButton title="Cancelar" onClick={handleClose} />

            <Buttons.PrimaryButton
              disabled={!isAbleToSubmit}
              type="submit"
              title="Confirmar"
              loading={isLoading}
            />
          </Box>
        </Box>
      </Form.FormBase>
    </PrimaryModal>
  );
}
