import React from 'react';
import { Box, Collapse, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { ChipStatus } from '../../../../../../../../../components/ChipStatus';
import Buttons from '../../../../../../../../../components/Buttons';

export function ReprovedDefinitive({
  sectionOpen = '',
  handleOpenSection = () => {},
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <ChipStatus status="primary" label="Reprovado definitivo" />

      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '13px',
        }}
      >
        Este colaborador não atende a todos os critérios estabelecidos pelo
        Banco Central para criação de uma conta de pagamento do sistema
        financeiro nacional.
      </Typography>

      <Buttons.SecondaryButton
        color="primary"
        icon={
          sectionOpen === 'section2' ? (
            <RemoveCircleOutlineIcon color="primary" />
          ) : (
            <AddCircleOutlineIcon color="primary" />
          )
        }
        title={sectionOpen === 'section2' ? 'Ver menos' : 'Ver mais'}
        onClick={() =>
          handleOpenSection(sectionOpen === 'section2' ? '' : 'section2')
        }
        style={{ width: 'fit-content' }}
      />

      <Collapse in={sectionOpen === 'section2'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography
            style={{
              fontSize: '13px',
            }}
          >
            Caso o mesmo tenha uma conta bancária em alguma outra instituição
            financeira, você pode informar os dados desta conta que realizaremos
            o pagamento na conta informada.
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}
