import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import { IconButton, Typography } from '@material-ui/core';

import styles from '../styles.module.css';
import { ActionsManualPayroll } from '../../../../redux/store/Payroll/ManualPayroll';

export default function BottomSheet({
  openBottomSheet,
  setOpenBottomSheet,
  setOpenApplyValuesModal,
  setAlertMessage,
  setOpenSnackbar,
  setSeverity,
  setSelecteds,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  function applyValues() {
    setOpenApplyValuesModal(true);
    setOpenBottomSheet(false);
  }

  function includeMore() {
    goBackStep();
    setOpenBottomSheet(false);

    history.push('/payroll/manual-payroll/include-collaborators/');
  }

  function removeAll() {
    setOpenBottomSheet(false);
    setAlertMessage('Todos os colobaradores forão excluídos deste pagamento');
    setSeverity('success');
    setOpenSnackbar(true);
    sessionStorage.setItem('selectedEmployees', JSON.stringify([]));
    setSelecteds([]);
    dispatch(ActionsManualPayroll.setSelectedRowsEmployees([]));
  }

  function goBackStep() {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Pagamento manual',
        step: 2,
        route: '/payroll/manual-payroll',
      }),
    );
  }

  const renderOptions = () => {
    return (
      <div className={styles.centered}>
        <div className={styles.bottomSheetBlock} onClick={applyValues}>
          <IconButton className={styles.bottomSheetBlockIconButton}>
            <MonetizationOnRoundedIcon />
          </IconButton>
          <Typography className={styles.bottomSheetBlockTitle}>
            Aplicar <br /> mesmo valor
          </Typography>
        </div>

        <div className={styles.bottomSheetBlock} onClick={includeMore}>
          <IconButton className={styles.bottomSheetBlockIconButton}>
            <PersonAddRoundedIcon />
          </IconButton>
          <Typography className={styles.bottomSheetBlockTitle}>
            Incluir <br /> colaborador
          </Typography>
        </div>

        <div className={styles.bottomSheetBlock} onClick={removeAll}>
          <IconButton className={styles.bottomSheetBlockIconButton}>
            <DeleteForeverRoundedIcon />
          </IconButton>
          <Typography className={styles.bottomSheetBlockTitle}>
            Excluir <br /> todos
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <SwipeableBottomSheet
      overflowHeight={41}
      open={openBottomSheet}
      bodyStyle={{
        borderRadius: '12px 12px 0px 0px',
        background: '#FFFFFF',
        boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.12)',
        height: '150px',
        display: openBottomSheet ? 'initial' : 'none',
      }}
      onChange={() => setOpenBottomSheet(!openBottomSheet)}
    >
      <div
        className={
          openBottomSheet
            ? styles.bottomSheetContainer
            : styles.bottomSheetInactiveContainer
        }
      >
        {openBottomSheet ? (
          <div className={styles.bottomSheetContainer}>{renderOptions()}</div>
        ) : (
          <div className={styles.bottomSheetDivider} />
        )}
      </div>
    </SwipeableBottomSheet>
  );
}
