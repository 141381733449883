import React from 'react';

import { Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function GeneralInfo({ competence }) {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.valuesLineUp}>
          <div className={styles.valuesGroup}>
            <Typography className={styles.cardTitles}>Competência:</Typography>
            <Typography className={styles.cardSubtitles}>
              {competence}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
