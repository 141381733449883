import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Grid, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import Header from '../../../components/FormHeader';
import Profile from '../../../components/FormProfile';
import Footer from '../../../components/AnimatedBottomBar';
import ChangeSituationModal from '../../../components/AlterSituationDialog';
import ErrorModal from '../../../components/PrimaryModal';
import ResponsiveBottomBar from '../../../components/ResponsiveBottomBar';
import BottomBarActionsTable from './Components/BottomBarActionsTable';
import EditData from './EditData';

import { isMobile } from '../../../utils/breakpoints';
import textCapitalize from '../../../utils/textCapitalize';
import convertFormErrorName from '../../../utils/convertFormErrorName';
import { isNumeric } from '../../../utils/validateIsNumeric';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import useEmployeesConstants from '../hooks/useConstants';

import { api } from '../../../services/api';
import { ActionsEmployeeDetails } from '../../../redux/store/Employees/EmployeeDetails';

import styles from './styles.module.css';

export default function EmployeesRegister({
  match: {
    params: { action },
  },
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, success } = handleSnackbarStack();

  const {
    CURRENT_COMPANY_CODE,
    CURRENT_COMPANY_INDEX,
    EMPLOYEE_DETAILS,
    EMPLOYEE_REGISTRATION_DATA,
    ALL_TABS_ARRAY,
    ONBOARDING_ENABLED,
    COMPANIES,
  } = useEmployeesConstants();

  const { downloadMultipleReport } = useSelector(
    state => state.employees.employeeDetails.paymentHistory,
  );

  const [editMode, setEditMode] = useState(false);
  const [onSubmitForm, setOnSubmitForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [activeTransferSwitch, setActiveTransferSwitch] = useState(false);
  const [isOpenChangeSituationModal, setIsOpenChangeSituationModal] = useState(
    false,
  );
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const [profileData, setProfileData] = useState({});
  const [personalDataForm, setPersonalDataForm] = useState({});
  const [professionalDataForm, setProfessionalDataForm] = useState({});
  const [bankDataForm, setBankDataForm] = useState([]);
  const [addressDataForm, setAddressDataForm] = useState([]);
  const [contactDataForm, setContactDataForm] = useState({});
  const [expandedTabs, setExpandedTabs] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [checkedTabs, setCheckedTabs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [stockings, setStockings] = useState([]);
  const [isRegistrationDataEditable, setIsRegistrationDataEditable] = useState(
    true,
  );

  const [statusValue, setStatusValue] = useState('');
  const [textFieldFocused, setTextFieldFocused] = useState('');
  const [terminationDate, setTerminationDate] = useState('');
  const [category, setCategory] = useState(null);

  const COMPANY_SCHEDULED_TRANSFEER = COMPANIES[CURRENT_COMPANY_INDEX]
    ? COMPANIES[CURRENT_COMPANY_INDEX].scheduledTransfer
    : COMPANIES[0].scheduledTransfer;

  const COMPANY_NAME = COMPANIES[CURRENT_COMPANY_INDEX || 0]?.name;

  const IS_REGISTER_VIEW = Boolean(action === 'register');
  const IS_FIRED_EMPLOYEE = professionalDataForm?.employeeStatus === 'Demitido';

  const HIDDEN_FOOTER = checkedTabs.includes(
    'personalData' && 'professionalData' && 'bankData' && 'addressData',
  );

  const handleCancel = () => {
    if (IS_REGISTER_VIEW) {
      history.push('/employees/register-single');
    } else {
      if (!editMode) {
        history.push('/employees');
        return;
      }

      setEditMode(false);
      setExpandedTabs([]);
      getEmployeeDetails();
    }
  };

  const getStatusList = () => {
    api.get('/employees/status').then(({ data }) => setStatusList(data));
  };

  const handleRegister = () => {
    const body = {
      category: category || null,

      personalData: {
        name: personalDataForm?.name,
        birthdate:
          personalDataForm?.birthdate &&
          moment(personalDataForm?.birthdate).format('YYYY-MM-DD') !==
            'Data inválida' &&
          moment(personalDataForm?.birthdate).format('YYYY-MM-DD') !==
            moment().format('YYYY-MM-DD')
            ? moment(personalDataForm?.birthdate).format('YYYY-MM-DD')
            : null,
        gender: personalDataForm?.gender || null,
        rg: personalDataForm?.rg,
        rgIssuingDate:
          moment(personalDataForm?.rgIssuingDate).format('YYYY-MM-DD') !==
          'Data inválida'
            ? moment(personalDataForm?.rgIssuingDate).format('YYYY-MM-DD')
            : null,
        rgIssuingAgency: personalDataForm?.rgIssuingAgency,
        rgIssuingState: personalDataForm?.rgIssuingState || null,
        motherName: personalDataForm?.motherName,
        maritalStatus: personalDataForm?.maritalStatus || null,
        cpf: personalDataForm?.cpf || EMPLOYEE_REGISTRATION_DATA?.cpf,
      },

      professionalData: {
        companyName: professionalDataForm?.companyName || COMPANY_NAME,
        typeContract: professionalDataForm?.typeContract,
        employeeRegistration: professionalDataForm?.employeeRegistration,
        admissionDate: moment(professionalDataForm?.admissionDate).format(
          'YYYY-MM-DD',
        ),
        stocking:
          stockings.filter(
            item => item.description === professionalDataForm.stocking,
          )[0]?.code || null,
        locatedAt:
          locations.filter(
            item => item.description === professionalDataForm.locatedAt,
          )[0]?.code || null,
        resignationDate: professionalDataForm?.resignationDate
          ? professionalDataForm?.resignationDate
          : null,
      },

      bankData:
        COMPANY_SCHEDULED_TRANSFEER && activeTransferSwitch
          ? {
              transfeerPercent: parseInt(
                bankDataForm?.transfeerPercent.replace('%', ''),
              ),
              bankCode: bankDataForm?.bankCode,
              accountType: bankDataForm?.accountType,
              agency: parseInt(bankDataForm?.agency.replace('-', '')),
              account: parseInt(bankDataForm?.account.split('-')[0]),
              checkDigit: bankDataForm?.checkDigit.replace('-', ''),
              active: activeTransferSwitch,
            }
          : null,

      addresses: addressDataForm
        .map(item => ({
          street:
            item?.street &&
            (item?.street.replace('-', '')
              ? item?.street.replace('-', '')
              : null),
          number: item?.number,
          complement: item?.complement,
          zipCode: item?.zipCode,
          neighborhood:
            item?.neighborhood &&
            (item?.neighborhood.replace('-', '')
              ? item?.neighborhood.replace('-', '')
              : null),
          city:
            item?.city &&
            (item?.city.replace('-', '') ? item?.city.replace('-', '') : null),
          state:
            item?.state &&
            (item?.state.replace('-', '')
              ? item?.state.replace('-', '')
              : null),
        }))
        .filter(
          address =>
            Object.entries(address).filter(([key, value]) => !!value)?.length,
        ),

      contacts: {
        email: !IS_REGISTER_VIEW ? contactDataForm?.email : null,
        phones:
          !ONBOARDING_ENABLED ||
          (!IS_REGISTER_VIEW && ONBOARDING_ENABLED && contactDataForm?.phones)
            ? contactDataForm?.phones
            : [],
      },
    };

    setRequestLoading(true);

    if (IS_REGISTER_VIEW) {
      requestPost(body);
    } else {
      body.professionalData = {
        ...body.professionalData,
        clientCompanyId: professionalDataForm?.clientCompanyId,
      };
      requestUpdate(body);
    }
  };

  const requestPost = body => {
    api
      .post(`/employees/create/${CURRENT_COMPANY_CODE}`, body)
      .then(({ data }) => {
        const obj = {
          name: personalDataForm.name || '',
          genre: personalDataForm.genre,
        };

        sessionStorage.setItem(
          'useTopBar',
          JSON.stringify({
            name: 'Cadastro concluído!',
            step: 'lastStep',
            route: '/employees',
          }),
        );

        sessionStorage.setItem('nameEmployee', JSON.stringify(obj));
        sessionStorage.setItem(
          'uploadEmployeesConfirmationData',
          JSON.stringify(data),
        );

        history.push('/employees/register-single/form/success');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrors(errors);
          setIsOpenErrorModal(true);
        },
      )
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const requestUpdate = body => {
    const encodedKey =
      EMPLOYEE_DETAILS?.encodedKey || EMPLOYEE_REGISTRATION_DATA?.employeeCode;

    api
      .put(`/employees/${encodedKey}/${CURRENT_COMPANY_CODE}`, body)
      .then(() => {
        success('Dados atualizados com sucesso.');
        getEmployeeDetails();
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrors(errors);
          setIsOpenErrorModal(true);
        },
      )
      .finally(() => {
        setRequestLoading(false);
        setEditMode(false);
      });
  };

  const handleExpandedTab = name => {
    const auxArray = [...expandedTabs];
    const index = auxArray.indexOf(name);
    const findName = expandedTabs.find(item => item === name);

    if (findName) {
      auxArray.splice(index, 1);
      setExpandedTabs([...auxArray]);
    } else {
      setExpandedTabs([...expandedTabs, name]);
    }
  };

  const getEmployeeDetails = () => {
    if (!EMPLOYEE_DETAILS && !EMPLOYEE_REGISTRATION_DATA) return;

    const encodedKey =
      EMPLOYEE_DETAILS?.encodedKey || EMPLOYEE_REGISTRATION_DATA?.employeeCode;

    if (!encodedKey) return;

    setLoading(true);

    api
      .get(`employees/${encodedKey}/${CURRENT_COMPANY_CODE}`)
      .then(({ data }) => {
        const personalData = data?.personalData;
        const professionalData = data?.professionalData || {};
        const bankData = data?.bankData;
        const addressData = data?.addresses;
        const contactData = !IS_REGISTER_VIEW && data?.contacts;

        const {
          name,
          cpf,
          birthdate,
          gender,
          rg,
          rgIssuingDate,
          rgIssuingState,
          rgIssuingAgency,
          maritalStatus,
          motherName,
        } = personalData;

        const {
          companyName,
          employeeRegistration,
          stocking,
          locatedAt,
          employeeStatus,
          admissionDate,
          resignationDate,
          typeContract,
          dateCreationEmployee,
          clientCompanyId,
        } = professionalData;

        const {
          account,
          checkDigit,
          accountType,
          active,
          agency,
          bankCode,
        } = bankData;

        const transfeerPercent = bankData?.transfeerPercent
          ? `${bankData?.transfeerPercent.toString()}%`
          : null;

        setCategory(data.category);

        setProfileData({
          name,
          locatedAt,
          stocking,
          dateCreationEmployee,
        });

        setPersonalDataForm({
          name,
          cpf,
          birthdate,
          gender,
          rg,
          rgIssuingDate,
          rgIssuingState,
          rgIssuingAgency: rgIssuingAgency === '-' ? null : rgIssuingAgency,
          maritalStatus,
          motherName,
        });

        setProfessionalDataForm({
          companyName,
          employeeRegistration,
          stocking,
          locatedAt,
          employeeStatus,
          admissionDate,
          resignationDate,
          typeContract,
          dateCreationEmployee,
          clientCompanyId,
        });

        setIsRegistrationDataEditable(data?.editable);

        setBankDataForm({
          account,
          checkDigit,
          accountType,
          active,
          agency,
          bankCode,
          transfeerPercent,
        });

        setStatusValue(employeeStatus);
        setActiveTransferSwitch(active);

        setAddressDataForm(
          addressData.length > 0
            ? addressData.map(item => ({
                street: item.street,
                number: item.number,
                complement: item.complement,
                zipCode: item.zipCode,
                neighborhood: item.neighborhood,
                city: item.city,
                state: item.state,
              }))
            : [
                {
                  street: null,
                  number: null,
                  complement: null,
                  zipCode: null,
                  neighborhood: null,
                  city: null,
                  state: null,
                },
              ],
        );

        !IS_REGISTER_VIEW &&
          setContactDataForm({
            email: contactData.email,
            phones: contactData?.phones?.map(item => ({
              type: item.type,
              number: item.number,
              isMain: item.isMain,
            })),
          });
      })
      .catch(() => {
        setIsRegistrationDataEditable(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLocations = () => {
    api
      .get(`companies/${CURRENT_COMPANY_CODE}/locations`)
      .then(({ data }) => setLocations(data));
  };

  const getStockings = () => {
    api
      .get(`companies/${CURRENT_COMPANY_CODE}/stockings`)
      .then(({ data }) => setStockings(data));
  };

  const validatePersonalFormProgress = () => {
    const {
      name,
      birthdate,
      rg,
      rgIssuingAgency,
      rgIssuingDate,
      rgIssuingState,
      motherName,
    } = personalDataForm;

    if (
      name &&
      rg &&
      rgIssuingAgency &&
      rgIssuingDate &&
      rgIssuingState &&
      (ONBOARDING_ENABLED ? true : motherName) &&
      (ONBOARDING_ENABLED ? true : birthdate)
    ) {
      return true;
    }
    return false;
  };

  const validateProfessionalFormProgress = () => {
    const {
      typeContract,
      employeeRegistration,
      admissionDate,
    } = professionalDataForm;

    if (typeContract && employeeRegistration && admissionDate) return true;
    return false;
  };

  const validateBankFormProgress = () => {
    const {
      account,
      checkDigit,
      accountType,
      agency,
      bankCode,
      transfeerPercent,
    } = bankDataForm;

    if (
      account &&
      checkDigit &&
      (isNumeric(checkDigit) || checkDigit === 'x' || checkDigit === 'xx') &&
      accountType &&
      agency &&
      bankCode &&
      transfeerPercent
    ) {
      return true;
    }
    return false;
  };

  const validateAddressFormProgress = () => {
    if (addressDataForm[0]) {
      const {
        city,
        neighborhood,
        number,
        state,
        street,
        zipCode,
      } = addressDataForm[0];

      if (
        (ONBOARDING_ENABLED ? true : city) &&
        (ONBOARDING_ENABLED ? true : neighborhood) &&
        (ONBOARDING_ENABLED ? true : number) &&
        (ONBOARDING_ENABLED ? true : state) &&
        (ONBOARDING_ENABLED ? true : street) &&
        (ONBOARDING_ENABLED ? true : zipCode)
      ) {
        return true;
      }
      return false;
    }
  };

  const handleSubmit = () => {
    setOnSubmitForm(true);
    validateFields();
  };

  const validateFields = () => {
    if (IS_REGISTER_VIEW) {
      handleRegister();
      return;
    }

    const {
      name,
      birthdate,
      rg,
      rgIssuingDate,
      rgIssuingState,
      rgIssuingAgency,
      motherName,
    } = personalDataForm;

    const {
      employeeRegistration,
      employeeStatus,
      admissionDate,
    } = professionalDataForm;

    const {
      account,
      checkDigit,
      accountType,
      agency,
      bankCode,
      transfeerPercent,
    } = bankDataForm;

    const {
      street,
      number: addressNumber,
      zipCode,
      neighborhood,
      city,
      state,
    } = addressDataForm[0];

    const TEST_PERSONAL_DATA = Boolean(
      name &&
        rg &&
        rgIssuingDate &&
        rgIssuingState &&
        rgIssuingAgency &&
        (ONBOARDING_ENABLED ? true : motherName) &&
        (ONBOARDING_ENABLED ? true : birthdate),
    );

    const TEST_PROFESSIONAL_DATA = Boolean(
      employeeRegistration && employeeStatus && admissionDate,
    );

    const TEST_BANK_DATA = Boolean(
      account &&
        checkDigit &&
        checkDigit.length > 0 &&
        accountType &&
        agency &&
        bankCode &&
        transfeerPercent,
    );

    const TEST_ADDRESS_DATA = Boolean(
      (ONBOARDING_ENABLED ? true : street) &&
        (ONBOARDING_ENABLED ? true : addressNumber) &&
        (ONBOARDING_ENABLED ? true : zipCode) &&
        (ONBOARDING_ENABLED ? true : neighborhood) &&
        (ONBOARDING_ENABLED ? true : city) &&
        (ONBOARDING_ENABLED ? true : state),
    );

    if (
      !TEST_PERSONAL_DATA ||
      !TEST_PROFESSIONAL_DATA ||
      (!TEST_BANK_DATA && activeTransferSwitch) ||
      !TEST_ADDRESS_DATA
    ) {
      showErrorAlert();
      return;
    }

    handleRegister();
  };

  const showErrorAlert = () => {
    error('Existem campos não preenchidos!');
  };

  const onChangeTerminationDate = value => {
    setTerminationDate(value);
  };

  const handleChangeSituation = () => {
    const selected = statusList.filter(
      ({ description }) => description === statusValue,
    );

    setIsLoadingModal(true);

    const body = {
      statusCode: selected[0].code,
      resignationDate: terminationDate
        ? moment(terminationDate).format('YYYY-MM-DD')
        : '',
      clientCompanyId: professionalDataForm?.clientCompanyId,
    };

    api
      .put(
        `/employees/${EMPLOYEE_DETAILS.encodedKey}/${CURRENT_COMPANY_CODE}/status`,
        body,
      )
      .then(() => {
        setIsOpenChangeSituationModal(false);
        getEmployeeDetails();
        success('Situação alterada com sucesso!');
      })
      .finally(() => setIsLoadingModal(false));
  };

  const CHECK_PERSONAL_FORM = validatePersonalFormProgress();
  const CHECK_PROFESSIONAL_FORM = validateProfessionalFormProgress();
  const CHECK_BANK_FORM = validateBankFormProgress();
  const CHECK_ADDRESS_FORM = validateAddressFormProgress();

  useEffect(() => {
    if (IS_REGISTER_VIEW) {
      setExpandedTabs(['personalData']);
      return;
    }

    setExpandedTabs(editMode ? [...ALL_TABS_ARRAY] : []);
  }, [editMode]);

  useEffect(() => {
    getEmployeeDetails();
    getStatusList();
    getLocations();
    getStockings();
  }, []);

  return (
    <div>
      <Header
        title={
          IS_REGISTER_VIEW
            ? 'Cadastro individual de colaborador'
            : !isMobile && 'Visualizar dados do funcionário'
        }
        firstButtonText="Alterar situação"
        secondButtonText="Editar dados"
        onClickFirstButton={() => setIsOpenChangeSituationModal(true)}
        onClickSecondButton={() => setEditMode(true)}
        disabledFirstButton={editMode || loading}
        disabledSecondButton={editMode || IS_FIRED_EMPLOYEE || loading}
        hiddenButtons={IS_REGISTER_VIEW}
        alternativeTitle={
          IS_REGISTER_VIEW && 'Campos com (*) são de preenchimento obrigatório.'
        }
        hidden={isMobile}
      />

      <Profile
        marginTop={3}
        marginBottom={2}
        loading={loading}
        hidden={IS_REGISTER_VIEW}
        name={textCapitalize(profileData?.name)}
        situation={professionalDataForm?.employeeStatus}
        avatar={<PersonIcon className={styles.personIcon} />}
        firstLabel={isMobile ? 'C. de Custo' : 'Centro de Custo'}
        firstValue={
          profileData?.locatedAt ? profileData?.locatedAt : 'Não cadastrado'
        }
        secondLabel="Setor"
        secondValue={
          profileData?.stocking ? profileData?.stocking : 'Não cadastrado'
        }
        thirdLabel={isMobile ? 'D. de cadastro' : 'Data de cadastro'}
        thirdValue={
          profileData?.dateCreationEmployee
            ? isMobile
              ? moment(profileData?.dateCreationEmployee).format('DD/MM/YYYY')
              : `${moment(profileData?.dateCreationEmployee).format(
                  'DD/MM/YYYY',
                )} às ${moment(profileData?.dateCreationEmployee).format('LT')}`
            : 'Não cadastrado'
        }
        onClickSituation={() => isMobile && setIsOpenChangeSituationModal(true)}
      />

      {isMobile && !IS_REGISTER_VIEW && (
        <>
          <div className={styles.divider} />

          <Grid container justify="flex-end">
            <Grid item>
              <Button
                className={styles.responsiveEditButton}
                onClick={() => setEditMode(true)}
              >
                <EditOutlinedIcon className={styles.responsiveEditButtonIcon} />
                Editar dados
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <EditData
        editMode={editMode}
        loading={loading}
        requestLoading={requestLoading}
        personalDataForm={personalDataForm}
        setPersonalDataForm={setPersonalDataForm}
        professionalDataForm={professionalDataForm}
        setProfessionalDataForm={setProfessionalDataForm}
        bankDataForm={bankDataForm}
        setBankDataForm={setBankDataForm}
        addressDataForm={addressDataForm}
        setAddressDataForm={setAddressDataForm}
        contactDataForm={contactDataForm}
        setContactDataForm={setContactDataForm}
        setOnSubmitForm={setOnSubmitForm}
        onSubmitForm={onSubmitForm}
        handleExpandedTab={handleExpandedTab}
        expandedTabs={expandedTabs}
        textFieldFocused={textFieldFocused}
        setTextFieldFocused={setTextFieldFocused}
        CHECK_PERSONAL_FORM={CHECK_PERSONAL_FORM}
        CHECK_PROFESSIONAL_FORM={CHECK_PROFESSIONAL_FORM}
        CHECK_BANK_FORM={CHECK_BANK_FORM}
        CHECK_ADDRESS_FORM={CHECK_ADDRESS_FORM}
        IS_REGISTER_VIEW={IS_REGISTER_VIEW}
        activeTransferSwitch={activeTransferSwitch}
        setActiveTransferSwitch={setActiveTransferSwitch}
        setExpandedTabs={setExpandedTabs}
        COMPANY_NAME={COMPANY_NAME}
        setCheckedTabs={setCheckedTabs}
        checkedTabs={checkedTabs}
        locations={locations}
        stockings={stockings}
        onboardEnabled={ONBOARDING_ENABLED}
        COMPANY_SCHEDULED_TRANSFEER={COMPANY_SCHEDULED_TRANSFEER}
        isRegistrationDataEditable={isRegistrationDataEditable}
      />

      <ChangeSituationModal
        circularLoading={isLoadingModal}
        statusList={statusList}
        date={terminationDate}
        onChangeDate={onChangeTerminationDate}
        statusValue={statusValue}
        setStatusValue={setStatusValue}
        open={isOpenChangeSituationModal}
        onCloseDialog={() => setIsOpenChangeSituationModal(false)}
        onConfirmClicked={handleChangeSituation}
        status={professionalDataForm.employeeStatus}
        title="Alterar situação do funcionário"
        confirmText="Alterar situação"
        cancelText="Cancelar"
      />

      <ErrorModal
        title={
          errors[0]?.type === null
            ? 'Tivemos um problema no cadastro'
            : 'Alguns dados já estão em uso.'
        }
        text={
          errors[0]?.type === null ? (
            errors[0]?.error
          ) : (
            <span>
              Para seguir com o cadastro é necessário que os dados abaixo sejam
              revistos:
              <br />
              <br />
              {errors.map(item => (
                <Typography className={styles.errorListLabel}>
                  • {convertFormErrorName(item)}
                </Typography>
              ))}
            </span>
          )
        }
        onConfirmClicked={() => setIsOpenErrorModal(false)}
        open={isOpenErrorModal}
        confirmButtonText="Entendi"
      />

      {isMobile && editMode && (
        <ResponsiveBottomBar
          cancelButtonText="Cancelar"
          confirmButtonText="Salvar"
          hidden={false}
          onCancel={handleCancel}
          onConfirm={handleSubmit}
          loading={requestLoading}
        />
      )}

      {isMobile && !editMode && IS_REGISTER_VIEW && (
        <ResponsiveBottomBar
          cancelButtonText="Cancelar"
          confirmButtonText="Salvar"
          hidden={false}
          onCancel={handleCancel}
          onConfirm={handleSubmit}
          loading={requestLoading}
        />
      )}

      <BottomBarActionsTable
        selected={downloadMultipleReport?.selectedReports}
        onCancelClick={() => {
          dispatch(ActionsEmployeeDetails.setPaymentHistorySelectedReports([]));
        }}
        onConfirmClick={() => {
          dispatch(ActionsEmployeeDetails.postSendMultiplePaymentNsu());
        }}
        confirmText={
          downloadMultipleReport?.percentage
            ? `${downloadMultipleReport?.percentage}%`
            : 'Baixar comprovantes'
        }
        multipleRowsSelected={
          downloadMultipleReport?.selectedReports?.length > 1
        }
      />

      <Footer
        confirmButtonText="Salvar"
        cancelButtonText={IS_REGISTER_VIEW || !editMode ? 'Voltar' : 'Cancelar'}
        hidden={(IS_REGISTER_VIEW && !HIDDEN_FOOTER) || isMobile}
        oragenNextButton={IS_REGISTER_VIEW}
        onCancel={handleCancel}
        onConfirm={handleSubmit}
        loading={requestLoading}
        hiddenNextButton={!IS_REGISTER_VIEW && !editMode}
      />
    </div>
  );
}
