import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Box, CircularProgress, InputAdornment } from '@material-ui/core';
import { CloseOutlined, SearchOutlined } from '@material-ui/icons';

import { Header } from '../../components/Header';
import Form from '../../components/Form';
import Buttons from '../../components/Buttons';
import HelpTopic from './components/HelpTopic';

import useAcademy from './hooks/useAcademy';

import { useStyles } from './styles';
import AcademySearchResults from './components/SearchResults';

export default function HelpCenterPage() {
  const styles = useStyles();

  const {
    onSearch,
    topics,
    isLoading,
    isSearching,
    onGetTopics,
    hasSuccessfulSearch,
    form: { control, getValues, setValue },
  } = useAcademy();

  const resetSearch = () => {
    setValue('keyWord', '');
    onGetTopics();
  };

  const renderLoading = (
    <Box className={styles.root}>
      <Box className={styles.spinner}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  );

  useEffect(() => {
    onGetTopics();
  }, []);

  return (
    <>
      <Header title="Conta com a gente" />
      <Form.FormBase>
        <Controller
          control={control}
          name="keyWord"
          render={({ field }) => (
            <Form.TextField
              className={styles.search}
              label="Pesquise nas dúvidas frequentes"
              size="medium"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
                endAdornment: !!getValues('keyWord') && (
                  <InputAdornment position="end">
                    <Buttons.IconButton
                      onClick={resetSearch}
                      aria-label="Limpar consulta"
                      tooltipTitle="Limpar consulta"
                      icon={<CloseOutlined />}
                    />
                  </InputAdornment>
                ),
              }}
              {...field}
              onChange={e => {
                field.onChange(e);
                onSearch();
              }}
            />
          )}
        />
      </Form.FormBase>
      {isLoading || isSearching ? (
        renderLoading
      ) : hasSuccessfulSearch ? (
        <AcademySearchResults />
      ) : (
        <Box className={styles.root}>
          {topics.length > 0 &&
            topics.map(topic => <HelpTopic topic={topic} />)}
        </Box>
      )}
    </>
  );
}
