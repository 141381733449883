import React from 'react';
import { Box } from '@material-ui/core';
import CardTools from '../../../../../../../../../components/CardTools';
import { ErrorReasonCardItem } from '../ErrorReasonCardItem';
import AccordionTools from '../../../../../../../../../components/AccordionTools';

export function ErrorDataContainerAccordion({
  IdentificationField = () => {},
  error = {},
  errorListIndex = 0,
  accordionsObject = {},
  handleEditEmployeeBankData = () => {},
}) {
  return (
    <AccordionTools.EnhancedAccordion
      header={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
          <CardTools.CardInfo title="Linha" description={error?.lineNumber} />

          {IdentificationField}
        </Box>
      }
      isExpanded={
        accordionsObject.currentAccordion === `accordion${errorListIndex}` ||
        accordionsObject.currentAccordion === 'all'
      }
      handleExpand={() =>
        accordionsObject.handleExpandCurrentAccordion(
          `accordion${errorListIndex}`,
        )
      }
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {error?.errors.map((errorData, errorsIndex) => (
          <AccordionTools.AccordionItemWrapper>
            <ErrorReasonCardItem
              error={error}
              errorData={errorData}
              errorsIndex={errorsIndex}
              handleEditEmployeeBankData={handleEditEmployeeBankData}
            />
          </AccordionTools.AccordionItemWrapper>
        ))}
      </Box>
    </AccordionTools.EnhancedAccordion>
  );
}
