import React from 'react';

import {
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as FilterIcon } from '../../../../assets/filter-icon.svg';
import { ReactComponent as BankIcon } from '../../../../assets/bank-icon.svg';

import style from '../styles.module.css';
import { styles } from '../styles';

import Title from '../../../../components/Title';

import { isMobile } from '../../../../utils/breakpoints';

export default function Header({
  setOpenDrawer,
  handleClickDataBank,
  handleClick,
  handleClose,
  anchorRef,
  anchorEl,
  classes,
  history,
  url,
  changedFilter,
  cashInValues,
  hasTedCashIn,
  disabled,
}) {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      className={style.headerCashin}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Title>Depósitos</Title>
        </Grid>

        <Button
          startIcon={<FilterIcon />}
          className={!isMobile ? style.hidden : style.filterButton}
          onClick={() => setOpenDrawer(true)}
          disabled={cashInValues.length === 0 && !changedFilter}
        >
          Filtrar
        </Button>
      </Grid>

      <Grid item className={style.dataBankContainer}>
        <Button
          startIcon={<BankIcon />}
          color="primary"
          variant="text"
          className={style.dataBankButton}
          onClick={handleClickDataBank}
          disabled={disabled}
        >
          <p>Dados bancários</p>
        </Button>
      </Grid>

      <Hidden only="xs">
        <div style={styles.buttonPadding}>
          <Button
            ref={anchorRef}
            variant="contained"
            color="primary"
            style={styles.buttonStyle}
            startIcon={<AddIcon />}
            onClick={handleClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
            disabled={disabled}
          >
            <Typography style={styles.buttonTextStyle} display="inline">
              Novo depósito
            </Typography>
          </Button>
        </div>

        <div style={styles.menuList}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menuList}
          >
            <MenuItem onClick={() => history.push(`${url}/new/billing`)}>
              <span className={classes.menuListItem}>Depósito via boleto</span>
            </MenuItem>

            {hasTedCashIn && (
              <MenuItem
                onClick={() =>
                  history.push(`${url}/inform-transfer-Deposit`, {
                    byScreen: true,
                  })
                }
              >
                <span className={classes.menuListItem}>
                  Depósito via transferência
                </span>
              </MenuItem>
            )}

            <MenuItem onClick={() => history.push('/cash-in/pix')}>
              <span className={classes.menuListItem}>Depósito via Pix</span>
            </MenuItem>
          </Menu>
        </div>
      </Hidden>
    </Grid>
  );
}
