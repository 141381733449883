import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
} from '@material-ui/core';

import ProgressToolBar from '../../../components/ProgressToolBar';
import styles from './styles';
import { ReactComponent as FileAndDollarIcon } from '../../../assets/FileAndDollarIcon.svg';

const PayrollInfo = () => {
  const history = useHistory();

  const onBackClicked = () => {
    history.push('/payroll/menu');
  };

  const onNextClicked = () => {
    history.push('/payroll/menu/info-payroll/register-batch');
  };

  return (
    <div style={styles.rootView}>
      <Typography style={styles.titleStyle}>
        Folha de Pagamento via Excel
      </Typography>
      <div style={styles.contentStyle}>
        <FileAndDollarIcon style={styles.logoStyle} />
        <Typography style={styles.biggerTextStyle}>
          Realize vários pagamentos utilizando uma única planilha.
        </Typography>
        <Typography style={styles.smallerTextStyle}>
          Antes de continuar, consulte as informações sobre prazos para cada
          tipo de pagamento.
        </Typography>
        <table style={styles.table}>
          <thead>
            <tr style={styles.tr}>
              <th style={styles.th}>Tipo de Pagamento</th>
              <th style={styles.th}>Prazo para efetivar pagamento</th>
            </tr>
          </thead>
          <tbody>
            <tr style={styles.tr}>
              <td style={styles.td}>Rescisão de contrato de trabalho</td>
              <td style={styles.td}>Imediatamente após a aprovação.</td>
            </tr>
            <tr style={styles.tr}>
              <td style={styles.td}>Salário, férias, outros.</td>
              <td style={styles.td}>
                A partir do próximo dia útil após aprovação do pagamento.
              </td>
            </tr>
          </tbody>
        </table>
        <Typography style={styles.smallerTextStyleRed}>
          Os prazos acima estão condicionados à disponibilidade do saldo no dia
          agendado.
        </Typography>
      </div>

      <ProgressToolBar
        currentStep={1}
        nextButtonText="Iniciar"
        numberOfSteps={5}
        onBackClicked={onBackClicked}
        onNextClicked={onNextClicked}
      />
    </div>
  );
};

export default PayrollInfo;
