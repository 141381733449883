import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as AlertIcon } from '../../../../assets/icon_table_schedule.svg';
import { ReactComponent as AuthorizedIcon } from '../../../../assets/icon_table_how_to_reg.svg';
import { ReactComponent as ExpressPaymentIcon } from '../../../../assets/expressPaymentIcon.svg';
import { ReactComponent as InsufficientFunds } from '../../../../assets/icon_table_money_off.svg';
import { ReactComponent as PaidOut } from '../../../../assets/paidOut.svg';
import { ReactComponent as PaymentIcon } from '../../../../assets/expressPayment3.svg';
import { ReactComponent as Canceled } from '../../../../assets/icon_table_highlight_off.svg';
import { ReactComponent as ExpiredPayrollIcon } from '../../../../assets/icon_table_update.svg';
import { ReactComponent as IncompletePaymentIcon } from '../../../../assets/uncompletePayment.svg';
import { ReactComponent as AssignmentIcon } from '../../../../assets/icon_table_assignment_late.svg';
import { styles } from './styles';

export default status => {
  switch (status) {
    case 'Autorizada':
      return (
        <div style={styles.situationCell}>
          <AuthorizedIcon />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
              Autorizado
            </span>
          </div>
        </div>
      );
    case 'Autorizada Imediata':
      return (
        <div style={styles.situationCell}>
          <ExpressPaymentIcon />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
              Autorizado imediato
            </span>
          </div>
        </div>
      );
    case 'Aguardando Autorização':
      return (
        <div style={styles.situationCell}>
          <AlertIcon />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
              Aguardando autorização
            </span>
          </div>
        </div>
      );
    case 'Saldo Insuficiente':
      return (
        <div style={styles.situationCell}>
          <InsufficientFunds />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
              Saldo insuficiente
            </span>
          </div>
        </div>
      );
    case 'Paga':
      return (
        <div style={styles.situationCell}>
          <PaidOut />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
              Pago
            </span>
          </div>
        </div>
      );
    case 'Paga Imediata':
      return (
        <div style={styles.situationCell}>
            <Tooltip title="Pagamento via liberação imediata">
              <PaymentIcon />
            </Tooltip>
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
            Pagamento imediato
            </span>
          </div>
        </div>
      );
    case 'Cancelada':
      return (
        <div style={styles.situationCell}>
          <Canceled />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A 90%',
                fontFamily: 'DM Sans',
              }}
            >
              Cancelado
            </span>
          </div>
        </div>
      );
    case 'Expirada':
      return (
        <div style={styles.situationCell}>
          <ExpiredPayrollIcon />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
                fontFamily: 'DM Sans',
              }}
            >
              Expirado
            </span>
          </div>
        </div>
      );
    case 'Pagamento Incompleto':
      return (
        <div style={styles.situationCell}>
          <IncompletePaymentIcon />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
              }}
            >
              Pagamento incompleto
            </span>
          </div>
        </div>
      );
    case 'Pagamento não Realizado':
      return (
        <div style={styles.situationCell}>
          <AssignmentIcon />
          <div style={styles.icon}>
            <span
              style={{
                color: '#1A1A1A',
              }}
            >
              Pagamento não realizado
            </span>
          </div>
        </div>
      );


    default:
      return status;
  }
};
