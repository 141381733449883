import { useCallback, useEffect, useRef } from 'react';

export function useEffectEnhanced(
  handler = () => {},
  dependencies = [],
  jumpFirstRender = false,
  delay = 1000,
) {
  const isFirstRender = useRef(true);
  const callback = useCallback(() => {
    if (jumpFirstRender) {
      if (!isFirstRender.current) {
        handler();
      }
    } else {
      handler();
    }
  }, []);

  useEffect(() => {
    let valuesTimeout;

    if (jumpFirstRender) {
      if (!isFirstRender.current) {
        valuesTimeout = setTimeout(callback, delay);
      }
    } else {
      valuesTimeout = setTimeout(callback, delay);
    }

    return () => {
      clearTimeout(valuesTimeout);
    };
  }, dependencies);

  useEffect(() => {
    isFirstRender.current = false;

    return () => {
      isFirstRender.current = true;
    };
  }, []);
}
