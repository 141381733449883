export function formatToNumber(value) {
  if (typeof value === 'number') {
    return value;
  }

  value = value.replace('.', '');
  value = value.replace(',', '.');
  value = Number(value.replace(/[^0-9.-]+/g, '')) || 0;
  return value;
}
