import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from '../../styles.module.css';

function FakeCardTip() {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <Skeleton
            circle={true}
            height={16}
            width={16}
            color="#202020"
            highlightColor="#444"
          />
        </div>
        <div style={{marginTop: '-18px'}}>
          <Skeleton
            height={25}
            width={136}
            color="#202020"
            highlightColor="#444"
            style={{marginBottom: '20px'}}
          />
          <Skeleton
            height={25}
            width={130}
            color="#202020"
            highlightColor="#444"
          />
        </div>
      </div>
    </>
  );
}

export default FakeCardTip;