import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(_ =>
  createStyles({
    root: {
      marginTop: '1.25rem',
      padding: '1.25rem',
      backgroundColor: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
    },
    search: {
      backgroundColor: 'white',
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    buttonsContainer: {
      padding: '1rem',
      display: 'flex',
      flexFlow: 'row wrap',
      gap: '1rem',
    },
    spinner: {
      display: 'flex',
      justifyContent: 'center',
    },
    accordion: {
      margin: '1rem 0',
    },
    details: {
      '&:.MuiAccordionDetails-root': {
        padding: '0.75rem',
      },
    },
    emptyText: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    subtopicSpinner: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
    },
    flowRoot: {
      marginBottom: '4rem',
    },
    flowText: {
      fontWeight: 500,
      fontSize: '1rem',
      margin: '1.25rem 0',
    },
    emptyFlowText: {
      fontSize: '1rem',
      fontWeight: 400,
      margin: '1.25rem 0',
    },
    stepContainer: {
      padding: '1rem',
    },
    flowImage: {
      maxWidth: '100%',
      margin: '.5rem 0',
      padding: '0 1rem',
    },
    footer: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      right: 0,
      zIndex: 2,
      marginLeft: '-2rem',
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: '#FFF',
      padding: '1rem 2rem',
      boxShadow: '0px -2px 6px 0px rgba(0, 0, 0, 0.12)',
    },
  }),
);
