import React from 'react';
import { Button, Box, Typography, Dialog } from '@material-ui/core';

import Lottie from 'react-lottie';
import styles from './styles.module.css';

import checkAnimation from '../../lotties/check.json';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function SuccessModal({
  open,
  onCloseDialog,
  title,
  text,
  closeButtonText,
  image,
  nextButton,
  onNextButtonClicked,
  nextButtonText,
  outlinedButton,
  outlinedButtonClicked,
  onClose,
  titleMarginTop,
}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <div className={styles.container}>
        <div className={styles.image}>
          {image || <Lottie options={animationOptions} />}
        </div>

        <Box mt={titleMarginTop}>
          <Typography className={styles.title}>{title}</Typography>
        </Box>

        <Typography
          className={styles.text}
          style={{ display: text ? '' : 'none' }}
        >
          {text}
        </Typography>

        <div
          style={{ marginTop: text ? '' : '30px' }}
          className={styles.buttonsGroup}
        >
          <Button
            onClick={outlinedButton ? outlinedButtonClicked : onCloseDialog}
            className={
              outlinedButton ? styles.outlinedButton : styles.closeButton
            }
          >
            {closeButtonText || 'Fechar'}
          </Button>

          {nextButton && (
            <Button onClick={onNextButtonClicked} className={styles.nextButton}>
              {nextButtonText}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default SuccessModal;
