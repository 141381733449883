import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Typography,
  Paper,
  Radio,
  Button,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import FakeMenu from './FakeMenu';

import ProgressToolBar from '../../../components/ProgressToolBar';
import ComingSoon from '../../../components/CommingSoon';

import { api } from '../../../services/api';
import styles from './styles';
import { isMobile } from '../../../utils/breakpoints';

const EmployeesBatchRegistrationMenu = () => {
  const [option, setOption] = useState('');
  const history = useHistory();
  const { url } = useRouteMatch();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabledStatus, setDisabledStatus] = useState(true);

  const authToken = sessionStorage.getItem('@ContaSoma-Token');

  const onBackClicked = () => {
    history.push('/employees');
  };

  const onNextClicked = () => {
    const registerOption = options[option];

    localStorage.setItem('registerMenuData', JSON.stringify(registerOption));

    history.push(`${url}/select-company/excel-form`);
  };

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
    };

    api
      .get('/files/employees/import/types', requestOptions)
      .then(({ data }) => {
        setOptions(data);
        setLoading(false);
      });
  }, []);

  const setOptionAndDisabled = index => {
    setOption(index);
    setDisabledStatus(false);
  };

  const renderOptions = () => {
    return options.map((element, index) => (
      <div style={styles.card}>
        <Paper style={option === index ? styles.borderCard : styles.notBorderCard}>
          <Button
            style={styles.buttonStyle}
            onClick={() => setOptionAndDisabled(index)}
          >
            <Radio
              checked={option === index}
              style={styles.radioButtonStyle}
            />
            <ListItem>
              <ListItemText
                primary={
                  <Typography style={styles.primaryTextSTyle}>
                    {element.name}
                  </Typography>
                }
                secondary={
                  <Typography style={styles.secondaryTextStyle}>
                    {element.description}
                  </Typography>
                }
              />
            </ListItem>
          </Button>
        </Paper>
      </div>
    ));
  };

  return (
    isMobile ? (
      <ComingSoon />
    ) : (
      <div>
        <Typography style={styles.titleStyle}>
          Cadastrar a partir de um arquivo
        </Typography>
        <Typography style={styles.subTitle}>
          Escolha o tipo de arquivo
        </Typography>

        <div style={styles.contentStyle}>
          {loading ? <FakeMenu /> : renderOptions()}
        </div>

        <ProgressToolBar
          onBackClicked={onBackClicked}
          onNextClicked={onNextClicked}
          nextButtonText="Continuar"
          disabled={disabledStatus}
        />
      </div>
    )
  );
};

export default EmployeesBatchRegistrationMenu;
