import React from 'react';
import { Box } from '@material-ui/core';
import { CancelOutlined, Search } from '@material-ui/icons';
import Buttons from '../../../../../Buttons';

export function FilterBottom({
  isClearFieldsDisabled = true,
  onClearFields = () => {},
  onSearchButtonClicked = () => {},
}) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        py: '1.5rem',
        px: '0.5rem',
        boxShadow: '0px 2px 6px 6px rgba(59, 53, 65, 0.2)',
      }}
    >
      <Buttons.SecondaryButton
        size="large"
        variant="outlined"
        icon={
          <CancelOutlined
            color={isClearFieldsDisabled ? 'disabled' : 'secondary'}
          />
        }
        title="Limpar filtros"
        disabled={isClearFieldsDisabled}
        onClick={onClearFields}
        style={{ width: '100%' }}
      />

      <Buttons.PrimaryButton
        size="large"
        icon={<Search />}
        title="Pesquisar"
        onClick={onSearchButtonClicked}
        style={{ width: '100%' }}
      />
    </Box>
  );
}
