import React from 'react';
import styles from './styles.module.css';
import {
  Button,
  Dialog,
} from '@material-ui/core';

function CompanyHoldBalanceReserveModal({
  open,
  onClose,
  onCancelButtonClicked,
  onConfirmButtonClicked
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>Pagar via débito em conta</h2>
          <p className={styles.text}>
            Você já possui saldo reservado para o pagamento da assinatura.
            Clique em <span>PAGAR ASSINATURA</span> para executar o pagamento.
          </p>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={onCancelButtonClicked}
          >
            CANCELAR
          </Button>

          <Button
            className={styles.confirmButton}
            onClick={onConfirmButtonClicked}
          >
            <span>PAGAR ASSINATURA</span>
          </Button>
        </div>

      </div>
    </Dialog>
  );
}

export default CompanyHoldBalanceReserveModal;
