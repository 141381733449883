import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import GeneralInfo from './GeneralInfo';
import Table from './Table';
import LogoLoading from '../../../../components/LogoLoading';
import Error from './Error';
import BottomBar from './BottomBar';
import FakeTable from './FakeTable';

import { api } from '../../../../services/api';

import styles from './styles.module.css';

export default () => {
  const history = useHistory();
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const [employeeData, setEmployeeData] = useState({});
  const [loansList, setLoansList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [employeesTableLoaded, setEmployeesTableLoaded] = useState(false);
  const [employeesTableLoading, setEmployeesTableLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState(false);
  const [extractDownloading, setExtractDownloading] = useState(false);

  const sort = tableHeadId => {
    if (orderBy === tableHeadId) {
      setOrder(prevState => (prevState === 'asc' ? 'desc' : 'asc'));
    }
    setOrderBy(tableHeadId);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const getLoansList = () => {
    setEmployeesTableLoading(true);
    const { id, cpf, name } = history.location.state;
    const requestOptions = {
      params: {
        sort: `${orderBy},${order}`,
      },
    };
    api
      .get(`consolidatedValues/loans/${id}`, requestOptions)
      .then(({ data }) => {
        setLoansList(data.content);
        setEmployeeData({ cpf, name });
      })
      .catch(error => {
        setError(true);
      })
      .finally(() => {
        if (!employeesTableLoaded) {
          setEmployeesTableLoaded(true);
        }
        setEmployeesTableLoading(false);
      });
  };

  useEffect(() => {
    getLoansList();
  }, [order, orderBy]);

  useEffect(() => {
    !employeesTableLoaded ? setPageLoading(true) : setPageLoading(false);
  }, [employeesTableLoaded]);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.actionBarContainer}>
          <Typography className={styles.pageTitle}>Empréstimos</Typography>
        </div>
        <div>
          {pageLoading ? (
            <LogoLoading />
          ) : (
            <div>
              {error ? (
                <div>
                  <Error />
                </div>
              ) : (
                <>
                  <GeneralInfo
                    cpf={employeeData.cpf}
                    name={employeeData.name}
                  />
                  {employeesTableLoading ? (
                    <FakeTable />
                  ) : (
                    <Table
                      bodyData={loansList}
                      currentPage={currentPage}
                      pageSize={loansList.length}
                      sort={sort}
                      order={order}
                      orderBy={orderBy}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <BottomBar handleGoBack={handleGoBack} hidden={pageLoading} />
    </div>
  );
};
