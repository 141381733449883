import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  Typography,
  MuiThemeProvider,
} from '@material-ui/core';

import Form from '../../../components/Form';

import { ResetPasswordActions as Actions } from '../../../redux/store/ResetPassword';

import { ReactComponent as LogoEmpresa } from '../../../assets/somapay.svg';
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import styles from '../EmailAndCpf/styles.module.css';

const validations = yup.object().shape({
  token: yup.string().required(),
});

export default function ResetPasswordSMS() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = handleSnackbarStack();

  const { isLoading, mobilePhone } = useSelector(state => state.resetPassword);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    initialValues: {
      code: '',
    },
    resolver: yupResolver(validations),
  });

  const sendSMS = async ({ token }) => {
    const actionResult = await dispatch(Actions.confirmSmsToken({ token }));

    try {
      unwrapResult(actionResult);
      history.push('/reset-password/create-password');
    } catch (err) {
      error(err);
    }
  };

  const goBackStep = () => {
    history.push('/reset-password/email-and-cpf');
  };

  return (
    <div className={styles.pageContainer}>
      <MuiThemeProvider>
        <Container>
          <CssBaseline />
          <div className={styles.paper}>
            <IconButton className={styles.arrowBack} onClick={goBackStep}>
              <ArrowBack />
            </IconButton>
            <div>
              <LogoEmpresa width={240} height={45} />
            </div>
            <form className={styles.form} onSubmit={handleSubmit(sendSMS)}>
              <div className={styles.content}>
                <Typography className={styles.textBigger}>
                  Recuperar Acesso
                </Typography>
                <Typography className={styles.textSmall}>
                  Como medida adicional de segurança, enviamos um código para o
                  número de celular associado ao seu acesso. Você precisará
                  deste código para as próximas etapas.
                </Typography>

                <Typography className={styles.textSmall}>
                  Insira o código enviado via SMS para o número {mobilePhone}.
                </Typography>
                <Controller
                  control={control}
                  name="token"
                  render={({ field }) => (
                    <Form.TextField
                      size="medium"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Informe o código SMS"
                      className={styles.input}
                      error={!!errors?.token}
                      helperText={errors?.token?.message}
                      {...field}
                      disabled={isLoading}
                    />
                  )}
                />
              </div>
              <div className={styles.submitArea}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                  disabled={!isValid}
                  type="submit"
                >
                  {isLoading ? (
                    <div className={styles.circularProgress}>
                      <CircularProgress
                        className={styles.spinner}
                        size={styles.spinner}
                      />
                    </div>
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </Container>
      </MuiThemeProvider>
    </div>
  );
}
